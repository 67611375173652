import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import './vendorForm.less';
import Loader from "../common/components/loader/loader";
import history from "../history";
import FormHeader from './formHeader';
import ACTION from "../common/action_constant";
import ACTIONS from '../common/action_constant';
import translogo from "../../assets/images/trans-logo.svg";
import jpglogo from "../../assets/images/trans-logo.jpg"
import Save from "../../assets/images/save.svg";
import Accept from "../../assets/images/accept.svg";
import Reject from "../../assets/images/reject.svg";
import { getCookie } from "../../utils/cookies";
import MSGPopUp from "../common/components/msg-popup/msg-popup";
import Refresh from "../../assets/images/refresh-button.svg";
import View from "../../assets/images/view.svg";
import StarRatings from "react-star-ratings";
import validate from "../../utils/validate";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { DEEPAK_COMPANY_ID, ABFRL_COMPANY_IDS, TTBIL_COMPANY_IDS, CLUSTERS, AWL_COMPANY_IDS, VEHICLE_BODY_TYPE, STATES, years, FUEL_TYPE, CARGILL_COMPANY_IDS, ACTION_REQUIRED_OPTIONS,getUserId, isABFRL,isAWL, isDPL, SAP_DEEPAK_PHENOLICS } from '../../utils/common';
import InputText from "../common/components/input-text/input-text";
import Select from "react-select";
import { getYear } from 'date-fns';
import { data } from 'jquery';
import TopPanel from '../components/header-panel/header-panel';
import cancelBtn from '../../assets/images/cancel.svg'
import cancel from '../../assets/images/NewCancel.svg'
import addBtn from '../../assets/images/add.svg'
import { SingleDatePicker } from 'react-dates';
import Switch from 'react-switch';
import BankDetailsPopup from './bankDetailsPopup';
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import SelectT1 from '../components/common/Select/SelectT1';
import { ToastContainer, toast } from 'react-toastify';
import CloseModal from './CloseModal';
import TaxCodeDetails from './TaxCodeDetails/TaxCodeDetails';
// import { getSecureRandomKey } from '../../utils';

// import { svg2pdf } from 'svg2pdf.js';

const PAYMENT_MANNER = [
   { id: 1, name: "RTGS" },
   { id: 2, name: "NEFT" },
   { id: 3, name: "LC" },
   { id: 4, name: "BG" },
];

const PERIODIC_MANNER = [
   { id: 1, name: "Monthly" },
   { id: 2, name: "Quarterly" },
   { id: 3, name: "Others", value: '' },
];

const PERIODIC_TERMS = [
   { id: 1, name: "30 Days" },
   { id: 2, name: "45 Days" },
   { id: 3, name: "Others", value: '' },
];

const TURNOVER_DETAILS = [
   { turnoverValue: '', turnover_uom: '', turnover_year: (new Date().getFullYear()) - 1 },
   { turnoverValue: '', turnover_uom: '', turnover_year: (new Date().getFullYear()) - 2 },
   { turnoverValue: '', turnover_uom: '', turnover_year: (new Date().getFullYear()) - 3 },
];

const CASHMANAGEMENT = [
   { label: 'A1 - A/P DOMESTIC', value: 'A1 - A/P DOMESTIC' },
   { label: 'A3 - A/P INTERCOMPANY', value: 'A3 - A/P INTERCOMPANY' },
   { label: 'A2 - A/P FOREIGN', value: 'A2 - A/P FOREIGN' },
]

const PAYMENT_METHOD = [
   { label: 'B - BANK DRAFT', value: 'B - BANK DRAFT' },
   { label: 'C - CORPORATE CHECK', value: 'C - CORPORATE CHECK' },
   { label: 'E - NEFT', value: 'E - NEFT' },
   { label: 'W - WIRE TRANSFER', value: 'W - WIRE TRANSFER' },
]

const ACCOUNT_MEMO = [
   { label: '101 - MICRO SUPPLIER', value: '101 - MICRO SUPPLIER' },
   { label: '102 - SMALL SUPPLIER', value: '102 - SMALL SUPPLIER' },
   { label: '103 - MEDIUM SUPPLIER', value: '103 - MEDIUM SUPPLIER' },
   { label: '201 - MICRO ENTERPRISE', value: '201 - MICRO ENTERPRISE' },
   { label: '202 - SMALL ENTERPRISE', value: '202 - SMALL ENTERPRISE' },
   { label: '203 - MEDIUM ENTERPRISE', value: '203 - MEDIUM ENTERPRISE' },
   { label: '301 - OTHERS', value: '301 - OTHERS' },
]

const WTHTTYPE = [
   { label: 'I1 INV-CONTRACTORS OTH- SEC.194C-2%', value: 'I1 INV-CONTRACTORS OTH- SEC.194C-2%' },
   { label: 'I2 INV-CONTRACTORS INDUV&HUF- SEC194C-1%', value: 'I2 INV-CONTRACTORS INDUV&HUF- SEC194C-1%' },
   { label: 'II INV-TRANSPORT SERVICE W/ PAN 0%', value: 'II INV-TRANSPORT SERVICE W/ PAN 0%' },
]


const termsAndConditions = "https://s3.us-east-2.amazonaws.com/tms-2.0-contracts/dev/GstinDetails.xlsx";

const CustomeCheckBox = (props) => {
   return (
      <div className={props.selected ? "custome-checkbox-wrap selected" : "custome-checkbox-wrap"} onClick={() => props.toggle(props.id)}>
         {props.selected && <div className="inner-circle"></div>}
      </div>
   )
}

function shouldEnableSubmitButton(
   isAccepted,
   vendorDocuments,
   vendorRatingQuestions,
   userCompanyId,
   companyType,
   is_msme_registered,
   is_gst_registered,
   allMandatoryFiles,
   SAPDPLdeclarationFilesMandatoryCheck
 ) {
   const condition1 =
     isAccepted &&
     vendorDocuments &&
     vendorDocuments.length > 0 &&
     vendorRatingQuestions.find((vendorQuestion) => vendorQuestion.vendorRating);
 
   const condition2 =
     (AWL_COMPANY_IDS.includes(userCompanyId) ||
       CARGILL_COMPANY_IDS.includes(userCompanyId) ||
       ABFRL_COMPANY_IDS.includes(userCompanyId)) &&
     (
       [
         1, 2, 11, 12, 13, 15,
         ...(AWL_COMPANY_IDS.includes(userCompanyId) && companyType === 'Proprietorship' ? [16] : []),
         ...(CARGILL_COMPANY_IDS.includes(userCompanyId) ? [17, 18] : []),
       ].every((val) => allMandatoryFiles.indexOf(val) !== -1) ||
       // Add specific files for ABFRL
       (ABFRL_COMPANY_IDS.includes(userCompanyId) &&
         [6, 11, 15, 1, 3, 4, 23, 25, 26].every((val) => allMandatoryFiles.indexOf(val) !== -1))
     );
 
   const condition3 = [1, 2, 7, 9, ...(DEEPAK_COMPANY_ID.includes(userCompanyId) && !!is_msme_registered)?[15]:[]].every((val) => allMandatoryFiles.indexOf(val) !== -1);
   const condition4 = DEEPAK_COMPANY_ID.includes(userCompanyId) && !!SAPDPLdeclarationFilesMandatoryCheck
 
   return condition1 && (condition2 || condition3);
 }
 
 





class MyDatePicker extends Component {
   render() {
      return (
         <div className="data-picker">
            <div className="label grey">{this.props.label}</div>
            <DatePicker
               dateFormat="dd/MM/yyyy"
               selected={this.props.startDate}
               onChange={this.props.handleChange.bind(this, this.props.id)}
            />
         </div>
      );
   }
}

class VendorForm extends Component {
   constructor(props) {
      super(props);
      this.printDocument = this.printDocument.bind(this);
      this.state = {

         isPage: 1,
         otp_number: '',
         limit: 10,
         offset: 0,
         approvalObj: {},
         isDownloadPDF:false,
         isShowPDF: false,
         isRejected: false,
         isResend: false,
         approvalStatus: '',
         reason_for_rejection: '',
         reason_for_resend: '',
         allServices: [],
         companyType: [],
         external_approver_docs: [],
         approver_docs: [],
         fuelTypeOptions: FUEL_TYPE,
         toggleAllServicesCheckBox: false,
         toggleAllOperational: false,
         vehicle_body_options: VEHICLE_BODY_TYPE,
         vehicle_type_options: [],
         showModal : false,
         departmentOptions: [
            {
               label: "Accounts",
               value: "Accounts",
            },
            {
               label: "Admin",
               value: "Admin"
            },
            {
               label: "HR",
               value: "HR",
            },
            {
               label: "Legal",
               value: "Legal",
            },
            {
               label: "Operations",
               value: "Operations"
            },
            {
               label: "Sales",
               value: "Sales"
            },
            {
               label: "Not Applicable",
               value: "Not Applicable"
            },
            {
               label: "Others",
               value: "Others"
            },

         ],
         designationOptions: [
            {
               label: "Director",
               value: "Director",
            },
            {
               label: "Executive",
               value: "Executive"
            },
            {
               label: "Manager",
               value: "Manager",
            },
            {
               label: "Managing Director",
               value: "Managing Director",
            },
            {
               label: "Officer",
               value: "Officer"
            },
            {
               label: "Senior Executive",
               value: "Senior Executive"
            },
            {
               label: "Senior Manager",
               value: "Senior Manager",
            },
            {
               label: "Owner",
               value: "Owner"
            },
            {
               label: "Others",
               value: "Others",
            },
         ],
         vendorType: [
            {
               label: "Transporter",
               value: "Transporter"
            },
            {
               label: "Handling Agent",
               value: "Handling Agent"
            },
            {
               label: "Labour Contractor",
               value: "Labour Contractor"
            },
            {
               label: "WareHouse Owner",
               value: "WareHouse Owner"
            },
            {
               label: "Surveyor",
               value: "Surveyor"
            },
            {
               label: "Freight Forwarder",
               value: "Freight Forwarder"
            },
            {
               label: "Insurance",
               value: "Insurance"
            },
            {
               label: "Custom House Agent",
               value: "Custom House Agent"
            },
            {
               label: "Aggregator",
               value: "Aggregator"
            },
            {
               label: "3PL",
               value: "3PL"
            },
            {
               label: "Broker",
               value: "Broker"
            },
            {
               label: "Security",
               value: "Security"
            },
         ],
         errorMsg: '',
         rating: 0,
         isAccepted: false,
         isValid: false,
         years: years,
         previous_years: [],
         action_required_options: ACTION_REQUIRED_OPTIONS,
         data: {
            is_itr_filed: false,
            is_tds_filed:false,
            company_id: this.props.match.params.companyId,
            ob_vendor_id: this.props.match.params.vendorId,
            company_logo: '',
            scopes: [],
            type: [],
            company_contact_no: "",
            services_offered: [],
            contact_no: "",
            aadhar_card_no: "",
            pan_no: '',
            gst_no: '',
            gstDetails: [],
            currency: 'INR',
            email: '',
            created_by: '',
            additional_email: [],
            company_type: '',
            company_website: '',
            registration_year: '',
            turnover_details: TURNOVER_DETAILS,
            turnover: '',
            bank_name: '',
            branch_name: '',
            account_number: '',
            account_name: '',
            rlm_approver:'',
            is_rlm_approved:false,
            rlm_approver_name:'',
            vendorAcctGroup:'',
            companyCode: '',
            reconAccount: '',
            paymentMethod: "",
            purchaseOrganisation: '',
            currencyOrder: "",
            paymentTerms: "",
            incoTerm1: "",
            sap_vendor_code:"",
            // ob_vendor_id: this.props.match.params.vendorId,
            sapDetailsErrorMsg:false,
            userList: [{
               admin_name: '',
               admin_designation: '',
               admin_email: '',
               admin_contact: '',
               admin_location: ''
            }],
            customerList: [{
               customer_name: '',
               location: '',
               service_provided: '',
               product_handled: '',
               email: ''
            }],
            regionalOfficeList: [{
               regional_office_address: '',
               regional_office_city: '',
               regional_office_number: '',
               regional_office_pincode: '',
               regional_office_state: '',
               ...(CARGILL_COMPANY_IDS.includes(this.props.match.params.companyId) ? { countryName: 'India' } : {}),
            }],
            branchOfficeList: [{
               branch_office_address: '',
               branch_office_city: '',
               branch_office_number: '',
               branch_office_pincode: '',
               branch_office_state: ''
            }],
            ...(CARGILL_COMPANY_IDS.includes(this.props.match.params.companyId) ?
               {
                  WH_Tax_Accounting: [{
                     taxWHType: '',
                     taxLiable: '',
                     taxRec: ''
                  }]
               } : {}
            )
         },
         fileNames: [{ fileObject: "", name: "" }],
         declarationFiles: [],
         bankApproverFiles: [],
         dataSecond: {
            charges: this.props.vendorForm.chargesList || [],
            ob_vendor_id: this.props.match.params.vendorId,
            operational_location_type: 'Single',
            contract_comensment_date: '',
            contract_duration: '',
            chemical_product_name:'',
            contract_duration_uom: 'Months',
            consideration_oda: '',
            termination: [
               {
                  termination_type: "with Cause",
                  notice_period: "",
                  notice_period_uom: "days"
               },
               {
                  termination_type: "without Cause",
                  notice_period: "",
                  notice_period_uom: "days"
               }
            ],
            payment_periodicity: "Monthly",
            payment_terms: "30 Days",
            invoice_discrepancy: "10 Days",
            payment_manner: [],
            operationalArea: STATES,
            serviceProvided: [],
            product_handling_capability: [],
            allServiceOffered: [],
            tankerDetails: [{
               tanker_type: '',
               tanker_size: '',
               specification: '',
               own_fleet: '',
               attached_fleet: ''
            }],
            vendorRatingQuestions: [],
            clusters: [],
            zones: [],
            states: [],
            Segments: [
               {
                  id: 1,
                  name: "FMCG",
                  is_enable: false
               },
               {
                  id: 2,
                  name: "Fashion & Retail",
                  is_enable: false
               },
               {
                  id: 3,
                  name: "Oil & Gas",
                  is_enable: false
               },
               {
                  id: 4,
                  name: "Cement",
                  is_enable: false
               },
               {
                  id: 5,
                  name: "Agri",
                  is_enable: false
               },
               {
                  id: 6,
                  name: "Beverage",
                  is_enable: false
               },
               {
                  id: 7,
                  name: "Automobile",
                  is_enable: false
               },
               {
                  id: 8,
                  name: "Engineering Goods",
                  is_enable: false
               },
               {
                  id: 9,
                  name: "Pharma",
                  is_enable: false
               },
               {
                  id: 10,
                  name: "White Goods",
                  is_enable: false
               },
               {
                  id: 11,
                  name: "Construction",
                  is_enable: false
               },
               {
                  id: 12,
                  name: "Chemicals",
                  is_enable: false
               },
               {
                  id: 13,
                  name: "Others",
                  is_enable: false
               }
            ],

            vehicle_details: []
         },
         challenge_price: {},
         counter_price: {},
         taxCodeDetailsRows: [
            {planningPoint: "", condAmount: "", taxCode: "" }
         ],
         planningOptions: [],
         taxCodeOptions: [],
         isSendToSap : false
      }
   }

   componentDidMount() {
      const { dispatch, match } = this.props;
      const cmpApprval = JSON.parse(localStorage.getItem('companyApproval'));
      if (cmpApprval && Object.keys(cmpApprval || {}).length > 0) {
         this.setState({ approvalObj: { ...cmpApprval } })
      }

      this.getBasicVendorDetails();
      this.getAllScope();
      this.getRouteDetails();
      this.getZones();
      //this.getOperationalAreas();
      this.getStateListData()
      this.getServicesProvided();
      this.getAllServicesOffered();
      this.getInventoryItem();
      this.getVendorRatingQuestions();
      this.getEstCategory();
      this.getPaymentTerms();
      this.getCountryList();
      this.getIncotermsOptions();
      this.getPlanningOptions()
      this.getTaxCodeOptions()

      let queryString1 = `?companyId=${match.params.companyId}`;
      
      dispatch({
        type: ACTION.VENDORS.GET_PAYMENT_TERMS_LIST,
        queryString: queryString1,
      });

      let queryString2 = `?companyId=${match.params.companyId}`;
      
      dispatch({
         type: ACTION.VENDORS.GET_CURRENCY_LIST,
         queryString: queryString2,
       });
   }
 
   handleAddTaxCodeDetailRow = () => {
      const newRow = {
        planningPoint: null,
        condAmount: "",
        taxCode: null,
      };
      this.setState((prevState) => ({
        taxCodeDetailsRows: [...prevState.taxCodeDetailsRows, newRow],
      }));
    };

    handleDeleteTaxCodeDetailRow = (id) => {
      const updatedRows = (this.state.taxCodeDetailsRows || []).filter(
        (row, index) => index !== id
      );
      this.setState({ taxCodeDetailsRows: updatedRows });
    };

   //  handleTaxCodeDetailInputChange = (index, field, value) => {
   //    const updatedRows = this.state.taxCodeDetailsRows.map((row, i) => {
   //      if (i === index) {
   //        return { ...row, [field]: value };
   //      }
   //      return row;
   //    });
   //    this.setState({ taxCodeDetailsRows: updatedRows });
   //  };

   handleTaxCodeDetailInputChange = (index, field, selected) => {
      const { taxCodeDetailsRows } = this.state;

      if (field === "planningPoint") {
        const isDuplicate = (taxCodeDetailsRows || []).some(
          (row, i) => i !== index && row.planningPoint === selected.value,
        );
    
        if (isDuplicate) {
          alert(
            "The selected Planning Point has already been chosen!",
          );
          return;
        }
      }
    
      const updatedRows = (taxCodeDetailsRows || []).map((row, i) => {
        if (i === index) {
         if(field == 'taxCode'){
          return { ...row, [field]: selected.value, ['condAmount'] : selected.percentage };
         }
          return { ...row, [field]: selected.value };
        }
        return row;
      });
    
      this.setState({ taxCodeDetailsRows: updatedRows });
    };    


    getPlanningOptions = () => {
      const { dispatch } = this.props;

      const onSuccess = (data) => {
      const tempPlanningOptions = (data || []).map(option => ({
         label: option,
         value: option
     }));
        this.setState({planningOptions : tempPlanningOptions})
      };

      dispatch({
        type: ACTION.VENDORS.GET_PLANNING_OPTIONS,
        onSuccess,
      });
    };

    addLabelAndValue = (arr, labelKey, valueKey) => {
      return (arr || []).map(obj => ({
          ...obj,
          label: obj[labelKey],
          value: obj[valueKey]
      }));
  }

    getTaxCodeOptions = () => {
      const { dispatch, match } = this.props;

      const queryParams = {
         ob_vendor_id : match.params.vendorId
      }

      const onSuccess = (data) => {
        const codes = data && data.tax_codes || []
        const tempTaxCodeOptions = this.addLabelAndValue(codes, 'label', 'name') || []
        this.setState({taxCodeOptions : tempTaxCodeOptions})
      };

      dispatch({
        type: ACTION.VENDORS.GET_TAXCODE_OPTIONS,
        queryParams,
        onSuccess,
      });
    };

    validateTaxCodeDetails = () => {
      const { taxCodeDetailsRows } = this.state;
      for (let i = 0; i < (taxCodeDetailsRows || []).length; i++) {
        const { planningPoint, condAmount, taxCode } = taxCodeDetailsRows[i];
  
        if (!planningPoint || !condAmount || !taxCode) {
          toast.error('Error : Missing data');
          return false;
        }
      }
  
      return true;
    };

    saveTaxCodeDetails = () => {
      const { dispatch, match } = this.props;
      const {taxCodeDetailsRows} = this.state

      const payload = {
         ob_vendor_id : match.params.vendorId,
         supplierTaxRate : taxCodeDetailsRows
      }

      const onSuccess = (data) => {
        const message = data && data.message || 'Success'
        this.getBasicVendorDetails()
        toast.success(message);
      };
      if(this.validateTaxCodeDetails()){
      dispatch({
        type: ACTION.VENDORS.SAVE_TAXCODE_DETAILS,
        payload,
        onSuccess,
      });
   }
    };

   getIncotermsOptions = () => {
      const { dispatch } = this.props;
      dispatch({type: ACTIONS.VENDORS.GET_INCO_TERMS_OPTIONS});
    }

   componentWillReceiveProps(nextProps) {

      if (nextProps.vendorForm.vendorDefaultData !== this.props.vendorForm.vendorDefaultData) {

         if (Object.keys(nextProps.vendorForm.vendorDefaultData).length > 0) {
            const isDPL = DEEPAK_COMPANY_ID.includes(this.props.match.params.companyId)
            const defaultData = nextProps.vendorForm.vendorDefaultData || {};
            let { data, dataSecond, year } = this.state;
            data['vendor_company_name'] = defaultData.vendor_company_name;
            data['contact_person_name'] = defaultData.contact_person_name;
            data['email'] = defaultData.email;
            data['created_by'] = defaultData.created_by;
            data['scopes'] = defaultData.scopes || [];
            data['additional_email'] = defaultData.additional_email || [];
            data.contact_no = defaultData.contact_no ? (typeof (defaultData.contact_no) === "string" ? defaultData.contact_no : defaultData.contact_no.phone_no) : ""
            data.company_contact_no = defaultData.company_contact_no ? defaultData.company_contact_no : data.company_contact_no
            data.company_logo = defaultData.company_logo;
            data.type = defaultData.type || [];
            data.services_offered = defaultData.services_offered || [];
            data.pan_no = defaultData.pan_no || '';
            data.aadhar_card_no = defaultData.aadhar_card_no || '';
            data.gst_no = defaultData.gst_no || '';
            data.email = defaultData.email || '';
            data.created_by = defaultData.created_by || '';
            data.company_type = defaultData.company_type || '';
            data.company_website = defaultData.company_website || '';
            data.registration_year = defaultData.registration_year || '';
            data.turnover = defaultData.turnover || '';
            data.turnover_details = defaultData.turnover_details || TURNOVER_DETAILS;
            data.bank_name = defaultData.bank_name || '';
            data.branch_name = defaultData.branch_name || '';
            data.account_name = defaultData.account_name || '';
            data.account_number = defaultData.account_number || '';
            data.IFSC_code = defaultData.IFSC_code || '';
            data.rlm_approver_name=defaultData.rlm_approver_name||'';
            data.is_rlm_approved=defaultData.is_rlm_approved;
            data.rlm_approver=defaultData.rlm_approver||'';
            data.branchOfficeList = (defaultData.branchOfficeList && defaultData.branchOfficeList.length > 0) ? defaultData.branchOfficeList : data.branchOfficeList;
            data.regionalOfficeList = (defaultData.regionalOfficeList && defaultData.regionalOfficeList.length > 0) ? defaultData.regionalOfficeList : data.regionalOfficeList;
            data.userList = (defaultData.userList && defaultData.userList.length > 0) ? defaultData.userList : [{
               admin_name: '',
               admin_designation: '',
               admin_email: (defaultData.email) ? defaultData.email : '',
               admin_contact: '',
               admin_location: ''
            }];

            data.customerList = (defaultData.customerList && defaultData.customerList.length > 0) ? defaultData.customerList : data.customerList;
            data.save_page_1 = defaultData.save_page_1 || null;
            data.vendor_type = defaultData.vendor_type || ['Transporter'];

            if (AWL_COMPANY_IDS.includes(this.props.match.params.companyId) || DEEPAK_COMPANY_ID.includes(this.props.match.params.companyId)) {
               data.is_gst_registered = defaultData.is_gst_registered || true;
               data.FCN_RCN = defaultData.FCN_RCN || '';
               data.bank_details = defaultData.bank_details || null;
               data.bank_documents = defaultData.bank_documents || [];
               data.gstDetails = (defaultData.gstin_details ? defaultData.gstin_details : defaultData.gstDetails) || [
                  { state: null, gst_percentage: null, gstin: null }
               ];

            } else {
               data.gstDetails = defaultData.gstin_details || [];
            }

            if (isDPL || AWL_COMPANY_IDS.includes(this.props.match.params.companyId) || DEEPAK_COMPANY_ID.includes(this.props.match.params.companyId) ||ABFRL_COMPANY_IDS.includes(this.props.match.params.companyId)) {
               data.head_office_pincode = defaultData.head_office_pincode || '';
               data.head_office_state = defaultData.head_office_state || '';
               data.head_office_city = defaultData.head_office_city || '';
               data.head_office_address = defaultData.head_office_address || '';
               data.head_office_number = defaultData.head_office_number || '';

               data.is_msme_registered = defaultData.is_msme_registered || false;
               data.udyam_no = defaultData.udyam_no || 'UDYAM-';
               data.msme_detail = defaultData.msme_detail || '';
               data.msme_date = defaultData.msme_date||''; 
               data.is_gst_registered=defaultData.is_gst_registered||false;
               data.is_itr_filed = defaultData.is_itr_filed || false;
               
               data.itr_filing_date = defaultData.itr_filing_date || '';
               data.itr_ack_number = defaultData.itr_ack_number || '';
               data.is_tds_filed=defaultData.is_tds_filed || false;
               data.paymentTerms=defaultData.paymentTerms ||  '';
            }
            if (ABFRL_COMPANY_IDS.includes(this.props.match.params.companyId))
            {
               data.contact_person_location = defaultData.contact_person_location || ''
            }

            if (isDPL ) {
               data.contact_person_location = defaultData.contact_person_location || '';
               data.cin_number = defaultData.cin_number || '';

               data.is_blacklisted = defaultData.is_blacklisted || '';
               data.is_dplEmployee_Related = defaultData.is_dplEmployee_Related || '';
               data.is_sisterConcern_applied = defaultData.is_sisterConcern_applied || '';
               data.vendorAcctGroup = defaultData.vendorAcctGroup ||'ZTPT';

               data.companyCode = defaultData.companyCode ||'2000';
               data.reconAccount = defaultData.reconAccount ||'5031010';
               data.paymentMethod = defaultData.paymentMethod ||'BANK';
               data.purchaseOrganisation = defaultData.purchaseOrganisation ||'2000';
               data.currencyOrder = defaultData.currencyOrder ||'';
               data.paymentTerms = defaultData.paymentTerms ||'';
               data.incoTerm1 = defaultData.incoTerm1 ||'';
               data.sap_vendor_code = defaultData.sap_vendor_code ||'';
               dataSecond.chemical_product_name = defaultData.vendor_details && defaultData.vendor_details.chemical_product_name ? defaultData.vendor_details.chemical_product_name : '';
            }


            if (CARGILL_COMPANY_IDS.includes(this.props.match.params.companyId)) {
               data.dateRequested = defaultData.dateRequested || moment(new Date()).valueOf();
               data.actionRequired = defaultData.actionRequired || '';
               data.addressBookType = defaultData.addressBookType || '';
               data.withholdTax = defaultData.withholdTax || '';
               data.stdCommunicationMethod = defaultData.stdCommunicationMethod || '';
               data.taxRetunStatusFor2years = defaultData.taxRetunStatusFor2years || '';
               data.panAadharLinkage = defaultData.panAadharLinkage || '';
               data.supplierPerson = defaultData.supplierPerson || '';
               data.bankcountryName = defaultData.bankcountryName || 'India';
               data.IBANNumber = defaultData.IBANNumber || '';
               data.swiftCode = defaultData.swiftCode || '';
               data.WH_Tax_Accounting = (defaultData.WH_Tax_Accounting && defaultData.WH_Tax_Accounting.length > 0) ? defaultData.WH_Tax_Accounting : data.WH_Tax_Accounting;
               data.accountingSortKey = defaultData.accountingSortKey || '';
               data.cashMgmtGroup = defaultData.cashMgmtGroup || '';
               data.doubleInvCheck = defaultData.doubleInvCheck || '';
               data.paymentTerms = defaultData.paymentTerms || '';
               data.paymentMethod = defaultData.paymentMethod || '';
               data.clrkInternet = defaultData.clrkInternet ? defaultData.clrkInternet : defaultData.email;
               data.accountMemo = defaultData.accountMemo || '';
               data.taxWHCountry = defaultData.taxWHCountry || 'India';
               data.groupVendorSchema = defaultData.groupVendorSchema || '';
               data.verifyGRBased = defaultData.verifyGRBased || '';
               data.verifySrvBased = defaultData.verifySrvBased || '';
               data.vendorStatus = defaultData.vendorStatus || '';
               data.gst_no = defaultData.gst_no || '';
               data.searchTerm = defaultData.searchTerm || '';
            }

            dataSecond.operationalArea = defaultData.vendor_details && defaultData.vendor_details.operationalArea && defaultData.vendor_details.operationalArea.length > 0 ? defaultData.vendor_details.operationalArea : dataSecond.operationalArea;
            dataSecond.save_page_2 = defaultData.vendor_details && defaultData.vendor_details.save_page_2 ? defaultData.vendor_details.save_page_2 : null;
            dataSecond.allServiceOffered = defaultData.vendor_details && defaultData.vendor_details.allServiceOffered ? defaultData.vendor_details.allServiceOffered : dataSecond.allServiceOffered;
            dataSecond.product_handling_capability = defaultData.vendor_details && defaultData.vendor_details.product_handling_capability ? defaultData.vendor_details.product_handling_capability : dataSecond.product_handling_capability;
            dataSecond.tankerDetails = defaultData.vendor_details && defaultData.vendor_details.tankerDetails ? defaultData.vendor_details.tankerDetails : dataSecond.tankerDetails;
            dataSecond.vendorRatingQuestions = (defaultData.vendor_details && defaultData.vendor_details.vendorRatingQuestions && defaultData.vendor_details.vendorRatingQuestions.length > 0) ? defaultData.vendor_details.vendorRatingQuestions : dataSecond.vendorRatingQuestions;

            dataSecond.charges = nextProps.vendorForm.vendorDefaultData.charges ? [...nextProps.vendorForm.vendorDefaultData.charges] : [];
            dataSecond['created_by'] = defaultData.created_by;

            dataSecond.operational_location_type = defaultData.operational_location_type || dataSecond.operational_location_type;
            dataSecond.contract_comensment_date = defaultData.vendor_details && defaultData.vendor_details.contract_comensment_date ? defaultData.vendor_details.contract_comensment_date : dataSecond.contract_comensment_date;
            dataSecond.contract_duration = defaultData.vendor_details && defaultData.vendor_details.contract_duration ? defaultData.vendor_details.contract_duration : dataSecond.contract_duration;
            dataSecond.contract_duration_uom = defaultData.vendor_details && defaultData.vendor_details.contract_duration_uom ? defaultData.vendor_details.contract_duration_uom : dataSecond.contract_duration_uom;
            dataSecond.consideration_oda = defaultData.vendor_details && defaultData.vendor_details.consideration_oda ? defaultData.vendor_details.consideration_oda : dataSecond.consideration_oda;
            dataSecond.termination = defaultData.vendor_details && defaultData.vendor_details.termination ? defaultData.vendor_details.termination : dataSecond.termination;
            dataSecond.payment_periodicity = defaultData.vendor_details && defaultData.vendor_details.payment_periodicity ? defaultData.vendor_details.payment_periodicity : dataSecond.payment_periodicity;
            dataSecond.payment_terms = defaultData.vendor_details && defaultData.vendor_details.payment_terms ? defaultData.vendor_details.payment_terms : dataSecond.payment_terms;
            dataSecond.invoice_discrepancy = defaultData.invoice_discrepancy || dataSecond.invoice_discrepancy;
            dataSecond.payment_manner = defaultData.vendor_details && defaultData.vendor_details.payment_manner ? defaultData.vendor_details.payment_manner : dataSecond.payment_manner;
            dataSecond.states = (defaultData.vendor_details && defaultData.vendor_details.states && defaultData.vendor_details.states.length > 0) ? defaultData.vendor_details.states : dataSecond.states;
            dataSecond.clusters = (defaultData.vendor_details && defaultData.vendor_details.clusters && defaultData.vendor_details.clusters.length > 0) ? defaultData.vendor_details.clusters : dataSecond.clusters;
            dataSecond.serviceProvided = (defaultData.vendor_details && defaultData.vendor_details.serviceProvided && defaultData.vendor_details.serviceProvided.length > 0) ? defaultData.vendor_details.serviceProvided : dataSecond.serviceProvided;
            dataSecond.Segments = (defaultData.vendor_details && defaultData.vendor_details.Segments && defaultData.vendor_details.Segments.length > 0) ? defaultData.vendor_details.Segments : dataSecond.Segments;
            dataSecond.vehicle_details = (defaultData.vendor_details && defaultData.vendor_details.vehicle_details && defaultData.vendor_details.vehicle_details.length > 0) ? defaultData.vendor_details.vehicle_details : dataSecond.vehicle_details;

            if (defaultData.registration_year) {
               const temp = this.state.years.filter((yr) => yr.label >= defaultData.registration_year)
               this.setState({ previous_years: temp })
            }

            this.setState({ data, dataSecond }, () => {
               this.getAllVendorModes(defaultData.modeId);
            });
         }

      }
   }

   componentWillUnmount() {
      // localStorage.setItem('companyApproval', null);
   }
   printDocument() {
      this.setState({ isShowPDF: true }, this.printPDFDocument)
   }

   printPDFDocument() {
      const { match } = this.props;
      const userCompanyId = match.params.companyId;
      const companyName = this.state.data.vendor_company_name;
      this.setState({ isDownloadPDF: true });
      const margin = 40; // Adjust margin size as needed
      const pageWidth = 595.28 - 20; // A4 Width in points
      const pdf = new jsPDF({
          // orientation: 'portrait',
          unit: 'pt',
          format: 'a4',
          marginLeft: margin,
          marginRight: margin,
          marginTop: margin,
          marginBottom: margin,
      });
      const options = { scale: 2 };
      const vendoron = document.getElementById("vendoron");
      html2canvas(vendoron, options).then((canvas) => {
          const contentDataURL = canvas.toDataURL("image/jpeg");
          pdf.addImage(
              contentDataURL,
              "JPEG",
              10,
              0,
              pageWidth + 50,
              30
          );
          // Add a timeout before processing data1
          setTimeout(() => {
              const data1 = document.getElementById("form-page-1");
              html2canvas(data1, options).then((canvas) => {
                  const contentDataURL = canvas.toDataURL("image/jpeg");
                  pdf.addImage(
                      contentDataURL,
                      "JPEG",
                      10,
                      30,
                      pageWidth,
                      pdf.internal.pageSize.getHeight() - 40
                  );
                  if (data2 || data3) {
                      pdf.addPage(); // Add a new page after adding content from the first page
                  }
              });
          }, 1000); // Adjust timeout duration as needed
      });
  
      // Save data2 as PDF if present
      const data2 = document.getElementById("form-page-2");
      if (data2) {
          setTimeout(() => {
              html2canvas(data2, options).then((canvas2) => {
                  const contentDataURL2 = canvas2.toDataURL("image/jpeg");
                  pdf.addImage(
                      contentDataURL2,
                      "JPEG",
                      10,
                      20,
                      pageWidth,
                      pdf.internal.pageSize.getHeight() - 150
                  );
                  if (data3) {
                      pdf.addPage(); // Add a new page after adding content from the second page
                  }
              });
          }, 2000); // Adjust timeout duration as needed
      }
  
      // Save data3 as PDF if present 
      const data3 = document.getElementById("form-page-3");
      if (data3) {
          setTimeout(() => {
              html2canvas(data3, options).then((canvas3) => {
                  const contentDataURL3 = canvas3.toDataURL("image/jpeg");
                  pdf.addImage(
                      contentDataURL3,
                      "JPEG",
                      10,
                      0,
                      pageWidth,
                      pdf.internal.pageSize.getHeight() - 400
                  );
  
              });
          }, 3000); // Adjust timeout duration as needed
      }
  
      // Define data4 before using it
      const data4 = document.getElementById("form-page-4");
      if (data4) {
          setTimeout(() => {
              html2canvas(data4, options).then((canvas4) => {
                  const contentDataURL3 = canvas4.toDataURL("image/jpeg");
                  pdf.addImage(
                      contentDataURL3,
                      "JPEG",
                      10,
                      450,
                      pageWidth,
                      pdf.internal.pageSize.getHeight() - 700
                  );
                  // pdf.save("dashboard.pdf"); // Save the PDF after adding content from the third page
                  const fileName = `${companyName}_VendorApproval.pdf`;
                  pdf.save(fileName);
              });
          }, 3000); // Adjust timeout duration as needed
      }
  }
  
  

   

   handleVendorChange = (id, selectedOption) => {
      this.setState({ [id]: selectedOption });
   };


   getCountryList = () => {
      const { dispatch, match } = this.props;
      dispatch({
         type: ACTION.VENDORS.GET_COUNTRY_LIST,
         params: `?companyId=${match.params.companyId}`,
         onSuccess: ((data) => {
            let temp = []
            if (data.data && data.data.length > 0) {
               data.data.map((d) => {
                  temp.push({ label: d.name, value: d.name, iso_code: d.iso_code, code: d.code })
               })
            }
            this.setState({ country_options: temp })
         })
      })
   }

   getPaymentTerms = () => {
      const { dispatch, match } = this.props;
      if (CARGILL_COMPANY_IDS.includes(this.props.match.params.companyId)) {
         dispatch({
            type: ACTION.VENDORS.GET_PAYMENT_TERMS,
            params: `?companyId=${match.params.companyId}`,
            onSuccess: ((data) => {
               let temp = []
               if (data.data && data.data.length > 0) {
                  data.data.map((d) => {
                     temp.push({ label: d.name, value: d.id })
                  })
               }
               this.setState({ payment_terms_options: temp })
            })
         })
      }
   }

   getRouteDetails = () => {
      const { dispatch, match } = this.props;
      const { limit, offset } = this.state;
      dispatch({
         type: ACTION.VENDORS.GET_VENDOR_ONBOARD_AREAS,
         params: `?vendorId=${match.params.vendorId}&companyId=${match.params.companyId}&limit=1000&offset=${offset}`
      });
   }

   handleChange = (selectOptions) => {
      let { dataSecond } = this.state;
      dataSecond.product_handling_capability = [];
      selectOptions.map((product) => {
         if (!dataSecond.product_handling_capability.includes(product.materialName)) {
            dataSecond.product_handling_capability.push(product.materialName)
         }

      })

      this.setState({ dataSecond });
   }

   getBankDetailsFromIFSCCode = () => {
      const { data } = this.state;
      const { dispatch } = this.props;
      dispatch({
         type: ACTION.VENDORS.GET_BANK_DETAILS_FROM_IFSC,
         params: data.IFSC_code,
         onSuccess: ((bankDetails) => {
            const { data } = this.state;
            if (bankDetails) {
               data.bank_name = bankDetails.BANK
               data.branch_name = bankDetails.BRANCH
            }
            this.setState({ data })
         })
      })
   }

   handleChangeForVendorType = (vendor) => {
      let { data } = this.state;
      const isSeeker = this.props.match.params.approverType === '1';
      if (!isSeeker) {
         if (!data.vendor_type.includes(vendor.value)) {
            data.vendor_type.push(vendor.value)
         } else {
            data.vendor_type.splice(data.vendor_type.findIndex(v => v === vendor.value), 1)
         }
         this.setState({ data });
      }
   }

   handleChangeForProductHandling = (product) => {
      let { dataSecond } = this.state;
      const isSeeker = this.props.match.params.approverType === '1';

      if (!isSeeker) {
         if (!dataSecond.product_handling_capability.includes(product.materialName)) {
            dataSecond.product_handling_capability.push(product.materialName)
         } else {
            dataSecond.product_handling_capability.splice(dataSecond.product_handling_capability.findIndex(v => v === product.materialName), 1)
         }
         this.setState({ dataSecond });
      }
   }

   getZones = () => {
      this.props.dispatch({
         type: ACTION.VENDORS.FORM_ZONES
      });
   }

   changeRating = (newRating) => {
      this.setState({
         rating: newRating
      });
   }

   changeVendorRating = (newRating, index) => {
      const { dataSecond } = this.state;
      const isSeeker = this.props.match.params.approverType === '1';
      if (!isSeeker) {
         dataSecond.vendorRatingQuestions[index].vendorRating = newRating;
         this.setState({ dataSecond });
      }
   }

   // getOperationalAreas = () => {
   //    const { dataSecond } = this.state;
   //    this.props.dispatch({
   //       type: ACTION.VENDORS.GET_OPERATIONAL_AREA,
   //       onSuccess: (data) => {
   //          dataSecond.operationalArea = data;
   //          this.setState({ dataSecond })
   //       }
   //    })
   // }

   getStateListData = () => {
      const { dataSecond } = this.state;
      this.props.dispatch({
         type: ACTION.VENDORS.GET_STATE_LIST_DETAILS,
         data: '999',
         onSuccess: (data) => {
            dataSecond.stateListData = data.list;

            const stateOptions = [];
            data.list.map((state) => {
               stateOptions.push({
                  label: state.name,
                  value: state.name,
                  gst_state_code: state.gst_state_string
               })
            })
            stateOptions.sort((a, b) => a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1)
            this.setState({ stateOptions })

            const clusters = [];
            (data.list).map((cluster) => {
               const temp1 = clusters.find((val) => val.cluster_name == cluster.cluster)
               if (!(temp1 && Object.keys(temp1).length)) {
                  clusters.push({
                     cluster_name: cluster.cluster,
                     cluster_id: cluster.cluster_id
                  })
               }
            });

            const zones = [];
            (data.list).map((cluster) => {
               const temp2 = zones.find((val) => val.zone_name == cluster.zone)
               if (!(temp2 && Object.keys(temp2).length)) {
                  zones.push({
                     zone_name: cluster.zone,
                     zone_id: cluster.zone_id,
                     ...cluster
                  })
               }
            });

            const states = [];
            (data.list).map((cluster) => {
               const temp3 = states.find((val) => val.state_name == cluster.name)
               if (!(temp3 && Object.keys(temp3).length)) {
                  states.push({
                     state_name: cluster.name,
                     gst_state_code: cluster.gst_state_code,
                     ...cluster
                  })
               }
            });

            dataSecond.clusters = [...clusters]
            dataSecond.zones = [...zones]
            dataSecond.states = [...states]
            this.setStates({ dataSecond })
         }
      })
   }

   getVendorRatingQuestions = () => {
      const { dataSecond } = this.state;
      const { match } = this.props;
      let param = `?companyId=${match.params.companyId}`;
      this.props.dispatch({
         type: ACTION.VENDORS.GET_ALL_QUESTIONS,
         param,
         onSuccess: (data) => {
            dataSecond.vendorRatingQuestions = data;
            this.setState({ dataSecond })
         }
      })
   }

   getServicesProvided = () => {
      const { dataSecond } = this.state;
      const { match } = this.props;
      let param = `?companyId=${match.params.companyId}`;
      this.props.dispatch({
         type: ACTION.VENDORS.GET_SERVICE_PROVIDED,
         //  param: (DEEPAK_COMPANY_ID.includes(match.params.companyId) || AWL_COMPANY_IDS.includes(match.params.companyId)) && param,
         param: param,
         onSuccess: (data) => {
            dataSecond.serviceProvided = data;
            this.setState({ dataSecond })
         }
      })
   }

   getInventoryItem = () => {
      const { match } = this.props;
      let param = `${match.params.companyId}`;
      const { dispatch } = this.props;
      dispatch({ type: ACTIONS.MASTER.INVENTORY_ITEM.GET_INVENTORY_ITEM, param });

   }

   getAllServicesOffered = () => {
      const { allServices } = this.state;
      this.props.dispatch({
         type: ACTION.VENDORS.GET_ALL_SERVICE_OFFERED,
         onSuccess: (data) => {
            this.setState({ allServices: data.services_offered })
         }
      })
   }

   getStateAndCityFromPin = (pin, index, value) => {
      const { data } = this.state;
      this.props.dispatch({
         type: ACTIONS.VENDORS.GET_STATE_AND_CITY_FROM_PIN_CODE,
         data: pin,
         onSuccess: (pincodeData) => {
            if (pincodeData && pincodeData[0].Status === 'Success') {
               if (value === 'regional') {
                  data.regionalOfficeList[index].regional_office_state = pincodeData[0].PostOffice[0].State
                  data.regionalOfficeList[index].regional_office_city = pincodeData[0].PostOffice[0].District
               }
               else if (value === 'branch') {
                  data.branchOfficeList[index].branch_office_state = pincodeData[0].PostOffice[0].State
                  data.branchOfficeList[index].branch_office_city = pincodeData[0].PostOffice[0].District
               }
               else if (value === 'customer') {
                  data.customerList[index].state = pincodeData[0].PostOffice[0].State;
                  data.customerList[index].city = pincodeData[0].PostOffice[0].District
               }
               else if (value === 'head') {
                  data.head_office_state = pincodeData[0].PostOffice[0].State;
                  data.head_office_city = pincodeData[0].PostOffice[0].District
               }
               this.setState({ data })
            }
         }
      })
   }

   getEstCategory = () => {
      const { dispatch } = this.props;
      dispatch({
         type: ACTIONS.HOME.GET_EST_CATEGORY,
         onSuccess: (data) => {
            let type = [];
            if (data.list && data.list.length > 0) {
               for (let i = 0; i < data.list.length; i++) {
                  type.push({
                     value: data.list[i].id,
                     label: data.list[i].name
                  });
               }
            }
            this.setState({ companyType: type })
         }
      })
   }

   uploadVendorImage = (e) => {
      let formData = new FormData();
      formData.append('file', e.target.files[0]);
      formData.append('ob_vendor_id', this.props.match.params.vendorId);
      const { dispatch } = this.props;

      dispatch({
         type: ACTION.VENDORS.UPLOAD_VENDOR_LOGO,
         data: formData,
         onSuccess: ((res) => {
            if (Object.keys(res).length > 0) {
               const { data } = this.state;
               data.company_logo = res.Link;
               this.setState({ data });
            }
         })
      });
   }

   getAllScope = () => {
      const { dispatch } = this.props;
      dispatch({
         type: ACTION.VENDORS.GET_ALL_SCOPE
      })
   }

   toggleMode = (id) => {
      const { data } = this.state;
      if ((data.type || []).filter((obj) => { return obj.id === id.id }).length > 0) {
         data.type.splice(data.type.findIndex(obj => obj.id === id.id), 1);
      } else {
         (data.type || []).push(id)
      }
      this.setState({
         data
      })
   }

   // toggleOperationalArea = (id) => {
   //    const { dataSecond } = this.state;
   //    const isSeeker = this.props.match.params.approverType === '1';
   //    if (!isSeeker) {
   //       (dataSecond.operationalArea || []).map((data) => {
   //          if (id === 1) {
   //             (data.details || []).map((zone) => {
   //                if (zone.id === id) {
   //                   zone.is_enable = !zone.is_enable;
   //                }
   //                else {
   //                   zone.is_enable = dataSecond.operationalArea[0].details[0].is_enable;
   //                }
   //             })
   //          } else {
   //             (data.details || []).map((zone) => {
   //                if (zone.id === id) {
   //                   zone.is_enable = !zone.is_enable;
   //                   let isPanIndia = (data.details || []).filter((obj) => { return obj.is_enable === false });
   //                   if (isPanIndia.length > 0 && (isPanIndia || []).filter((obj) => obj.name !== 'Pan India')) {
   //                      dataSecond.operationalArea[0].details[0].is_enable = false;
   //                   } else {
   //                      dataSecond.operationalArea[0].details[0].is_enable = true;
   //                   }
   //                }
   //             })
   //          }

   //       })
   //       this.setState({
   //          dataSecond
   //       })
   //    }

   // }

   toggleOperationalArea = (area, idx) => {
      const { dataSecond, toggleAllOperational } = this.state;
      const isSeeker = this.props.match.params.approverType === '1';

      let temp = toggleAllOperational

      if (!isSeeker) {
         dataSecond.operationalArea[idx].is_enable = !dataSecond.operationalArea[idx].is_enable

         const allNotEnabled = dataSecond.operationalArea && dataSecond.operationalArea.length && dataSecond.operationalArea.find((temp) => temp.is_enable === false)

         if (allNotEnabled) {
            temp = false
         } else {
            temp = true
         }

         this.setState({ dataSecond, toggleAllOperational: temp })
      }
   }

   toggleOperationalAreaAll = () => {
      const { dataSecond, toggleAllOperational } = this.state;
      const isSeeker = this.props.match.params.approverType === '1';

      let temp = toggleAllOperational

      if (!isSeeker) {
         temp = !temp
         dataSecond.operationalArea.map((data) => {
            data.is_enable = temp ? true : false
         })
         this.setState({ dataSecond, toggleAllOperational: temp })
      }
   }

   selectCluster = (id, idx) => {
      const { dataSecond } = this.state;
      if (dataSecond.clusters.find((obj) => obj.isEnabled === true && obj.cluster_name === id)) {
         dataSecond.clusters[idx].isEnabled = false
      } else if (dataSecond.clusters.find((obj) => obj.cluster_name === id)) {
         dataSecond.clusters[idx].isEnabled = true
      }
      this.setState({ dataSecond })
   }

   selectState = (id, idx) => {
      const { dataSecond } = this.state;
      if (dataSecond.states.find((obj) => obj.isEnabled === true && obj.name === id)) {
         dataSecond.states[idx].isEnabled = false
      } else if (dataSecond.states.find((obj) => obj.name === id)) {
         dataSecond.states[idx].isEnabled = true
      }
      this.setState({ dataSecond })
   }

   toggleServiceOffered = (id) => {
      const { dataSecond } = this.state;
      const isSeeker = this.props.match.params.approverType === '1';
      if (!isSeeker) {
         (dataSecond.allServiceOffered || []).forEach((data) => {
            (data.details || []).forEach((service) => {
               if (service.id === id) {
                  service.is_enable = !service.is_enable
               }
            })
         })
         this.setState({
            dataSecond
         })
      }

   }

   toggleService = (id) => {
      const { match } = this.props;
      const { data, dataSecond, allServices } = this.state;
      const isSeeker = this.props.match.params.approverType === '1';
      const userData = getCookie('user') ? JSON.parse(getCookie('user') || {}) : '';
      const userCompanyId = match.params.companyId;

      if (AWL_COMPANY_IDS.includes(userCompanyId) || CARGILL_COMPANY_IDS.includes(userCompanyId)) {
         if ((dataSecond.allServiceOffered || []).filter((obj) => { return obj.som_id === id.som_id }).length > 0) {
            dataSecond.allServiceOffered.splice(dataSecond.allServiceOffered.findIndex(obj => obj.som_id === id.som_id), 1)
         } else {
            dataSecond.allServiceOffered.push(id)
         }
         this.setState({ dataSecond })
      }

      if (DEEPAK_COMPANY_ID.includes(userCompanyId) && !isSeeker) {
         if (id.som_id === 1) {
            if ((dataSecond.allServiceOffered || []).filter((obj) => { return obj.som_id === 1 }).length > 0) {
               dataSecond.allServiceOffered = [];
            } else {
               dataSecond.allServiceOffered = JSON.parse(JSON.stringify(allServices));
            }
         } else {
            if ((dataSecond.allServiceOffered || []).filter((obj) => { return obj.som_id === id.som_id }).length > 0) {
               dataSecond.allServiceOffered.splice(dataSecond.allServiceOffered.findIndex(obj => obj.som_id === id.som_id), 1)
            } else {
               dataSecond.allServiceOffered.push(id)
            }
         }
         this.setState({
            dataSecond
         })
      } else {
         if ((data.services_offered || []).filter((obj) => { return obj.id === id.id }).length > 0) {
            data.services_offered.splice(data.services_offered.findIndex(obj => obj.id === id.id), 1)
         } else {
            data.services_offered.push(id)
         }
         this.setState({
            data
         })
      }
   }

   toggleAllServices = () => {
      const { dataSecond, toggleAllServicesCheckBox } = this.state;
      const isSeeker = this.props.match.params.approverType === '1';

      let toggle = toggleAllServicesCheckBox
      let tempData = dataSecond
      let tempAll = tempData.serviceProvided

      if (!isSeeker) {
         if (toggle) {
            // Disable operation
            toggle = false
            for (let i = 0; i < tempAll.length; i++) {
               tempAll[i].is_enable = false
               for (let j = 0; j < tempAll[i].details.length; j++) {
                  tempAll[i].details[j].is_enable = false;
               }
            }
         } else {
            // Enable Operation
            toggle = true
            for (let i = 0; i < tempAll.length; i++) {
               tempAll[i].is_enable = true
               for (let j = 0; j < tempAll[i].details.length; j++) {
                  tempAll[i].details[j].is_enable = true;
               }
            }
         }

         tempData.serviceProvided = tempAll

         this.setState({
            toggleAllServicesCheckBox: toggle,
            dataSecond: tempData
         })
      }
   }

   toggleServiceCheckbox = (serviceIdx) => {
      const { dataSecond, toggleAllServicesCheckBox } = this.state;
      const isSeeker = this.props.match.params.approverType === '1';
      let toggle = toggleAllServicesCheckBox
      const tempAll = dataSecond.serviceProvided
      const tempService = tempAll[serviceIdx]

      if (!isSeeker) {
         if (tempService.details.find(detailObj => detailObj.is_enable === false)) {
            // Enable Operation
            tempService.is_enable = true
            for (let i = 0; i < tempService.details.length; i++) {
               tempService.details[i].is_enable = true
            }
         } else {
            // Disable Operation
            toggle = false
            tempService.is_enable = false
            for (let i = 0; i < tempService.details.length; i++) {
               tempService.details[i].is_enable = false
            }
         }

         tempAll[serviceIdx] = tempService
         this.setState({ ...dataSecond, serviceProvided: tempAll })
         this.setState({ toggleAllServicesCheckBox: toggle })
      }
   }

   toggleServiceDetail = (serviceIdx, detailIdx) => {
      const { dataSecond, toggleAllServicesCheckBox } = this.state;
      const isSeeker = this.props.match.params.approverType === '1';
      let toggle = toggleAllServicesCheckBox

      const tempAll = dataSecond.serviceProvided
      const tempService = tempAll[serviceIdx]
      const tempDetail = tempService.details[detailIdx]

      if (!isSeeker) {
         tempDetail.is_enable = !tempDetail.is_enable
         if (!tempDetail.is_enable) {
            toggle = false
         }
         const allNotEnabled = tempService && tempService.details.length && tempService.details.find((temp) => temp.is_enable === false)

         if (allNotEnabled) {
            tempService.is_enable = false
         } else {
            tempService.is_enable = true
         }

         tempService.details[detailIdx] = tempDetail
         tempAll[serviceIdx] = tempService
         this.setState({ ...dataSecond, serviceProvided: tempAll })
         this.setState({ toggleAllServicesCheckBox: toggle })
      }
   }

   toggleScope = (id) => {
      const { data } = this.state;
      if ((data.scopes || []).filter((obj) => { return obj.id === id.id }).length > 0) {
         data.scopes.splice(data.scopes.findIndex(obj => obj.id === id.id), 1)
      } else {
         data.scopes.push(id)
      }
      this.setState({
         data
      })
   }

   togglePay = (id) => {
      const { dataSecond } = this.state;
      if ((dataSecond.payment_manner || []).filter((obj) => { return obj.id === id.id }).length > 0) {
         dataSecond.payment_manner.splice(dataSecond.payment_manner.findIndex(obj => obj.id === id.id), 1)
      } else {
         dataSecond.payment_manner.push(id)
      }
      this.setState({
         dataSecond
      })
   }

   getAllVendorModes = (id) => {
      const { dispatch } = this.props;
      dispatch({
         type: ACTION.VENDORS.GET_FORM_VENDOR_MODES,
         params: `?mode_type=${id || 3}`
      })
   }

   validateSecondaryMandatory = () => {
      let { dataSecond, errorMsgSec, isSecValid } = this.state;
      // let selectedOpArea = (dataSecond.operationalArea || []).map((op) => {
      //    return (
      //       (op.details || []).some(soa => soa.is_enable === true)
      //    )
      // })
      // let selectedServicesOffered = (dataSecond.allServiceOffered || []).map((obj) => {
      //    return (
      //       (obj.details || []).some(sso => sso.is_enable === true)
      //    )
      // })
      const validationError = {
         // "Operational Area": selectedOpArea.includes(true) ? false : true,
         //"Service Offered (Cargo Handling Speciality)": dataSecond.allServiceOffered.length === 0,
         //"Service Offered": selectedServicesOffered.includes(true) ? false : true,
         // "Product Handling Capability": dataSecond.product_handling_capability.length === 0,
         //"Tanker Details": (validate.isEmpty(dataSecond.tankerDetails[0].tanker_type) || validate.isEmpty(dataSecond.tankerDetails[0].tanker_size) || validate.isEmpty(dataSecond.tankerDetails[0].specification) || validate.isEmpty(dataSecond.tankerDetails[0].own_fleet) || validate.isEmpty(dataSecond.tankerDetails[0].attached_fleet)),
         "Operational Area": dataSecond.operationalArea.find((area) => area.is_enable) ? false : true,
         "Segments": dataSecond.Segments.find((segment) => segment.is_enable) ? false : true,
         "Service Offered": dataSecond.serviceProvided.find((segment) => segment.details.find((detail) => detail.is_enable)) ? false : true,
         "Owned Vehicle Details": ((AWL_COMPANY_IDS.includes(this.props.match.params.companyId)) ? ((dataSecond.vehicle_details && dataSecond.vehicle_details.length > 0) ? false : true) : false)
      }
      const validationFields = [];
      Object.keys(validationError).forEach((key) => {
         if (validationError[key]) {
            validationFields.push(key);
         }
      });

      if (validationFields.length) {
         errorMsgSec = `Please Enter Valid ${validate.join(validationFields, (item, idx) => {
            if (validationFields.length === 1) {
               return "";
            }
            if (idx > 0 && idx < validationFields.length - 1) {
               return ", ";
            } else if (idx === validationFields.length - 1) {
               return " and ";
            }
            return "";
         })}.`;
         isSecValid = true;
      } else {
         errorMsgSec = '';
         isSecValid = false;
      }
      this.setState({ errorMsgSec });
      return isSecValid;
   }

   validateNextButton = () => {
      let { data } = this.state;
      const isDPL = DEEPAK_COMPANY_ID.includes(this.props.match.params.companyId)
      const AwlGstDetails = (AWL_COMPANY_IDS.includes(this.props.match.params.companyId) && data.is_gst_registered) ? ((data.gstDetails && data.gstDetails.length > 0 && data.gstDetails.find(d => (d.gst_percentage == null || d.gstin == null || d.state == null || d.gstError))) ? true : false) : false;
      const DplGstDetails = ((isDPL) && data.is_gst_registered) ? ((data.gstDetails && data.gstDetails.length > 0) ? false : true) : false;
      const cargilGstDetails = CARGILL_COMPANY_IDS.includes(this.props.match.params.companyId) ? ((data.gstDetails && data.gstDetails.length > 0) ? false : true) : false;
      const cargilIfscCode = CARGILL_COMPANY_IDS.includes(this.props.match.params.companyId) ? validate.isEmpty(data.IFSC_code) : false ;
      const dplIfscCode = isDPL ? validate.isEmpty(data.IFSC_code) : false ;
      const cargilBankName = CARGILL_COMPANY_IDS.includes(this.props.match.params.companyId) ? validate.isEmpty(data.bank_name) : false ;
      const dplBankName = isDPL ? validate.isEmpty(data.bank_name) : false ;
      if (!data.save_page_1) {
         return false
      }
      else if (this.props.match.params.approverType === '1') {
         return true
      }
      else {
         const validationError = {
            "Company Type": validate.isEmpty(data.company_type),
            "Additional Email": this.state.additional_email_error ? true : false,
            "Registration Year": validate.isEmpty(data.registration_year),
            "PAN No.": validate.isEmpty(data.pan_no) || this.state.panError,
            "Payment Currency": validate.isEmpty(data.currency),
            "Turn Over Year": data.turnover_details.find((detail) => !detail.turnover_year),
            "Turn Over Value": data.turnover_details.find((detail) => !detail.turnoverValue),
            "Admin Details": (validate.isEmpty(data.userList[0].admin_name) || validate.isEmpty(data.userList[0].admin_location) || validate.isEmpty(data.userList[0].admin_email) || validate.isEmpty(data.userList[0].admin_designation) || validate.isEmpty(data.userList[0].admin_contact) || validate.isEmpty(data.userList[0].admin_department)),
            "Customer Details": (validate.isEmpty(data.customerList[0].customer_name) || validate.isEmpty(data.customerList[0].location) || validate.isEmpty(data.customerList[0].city) || validate.isEmpty(data.customerList[0].state) || validate.isEmpty(data.customerList[0].pin_code) || validate.isEmpty(data.customerList[0].email) || validate.isEmpty(data.customerList[0].product_handled) || validate.isEmpty(data.customerList[0].service_provided)),
            "User Email": validate.isEmpty(data.userList[0].admin_email),
            "Contact Number": validate.isEmpty(data.contact_no),
            "Aadhar Card No": (CARGILL_COMPANY_IDS.includes(this.props.match.params.companyId) ? true : (data.company_type == 'Proprietorship')) ? validate.isEmpty(data.aadhar_card_no) : false,

            "Contact Person Address": (isDPL || ABFRL_COMPANY_IDS.includes(userCompanyId)) ? validate.isEmpty(data.contact_person_location) : false,
            "Registration No.": (isDPL) ? validate.isEmpty(data.cin_number) : false,

            "Date": CARGILL_COMPANY_IDS.includes(this.props.match.params.companyId) ? !data.dateRequested : false,
            "Action Required": CARGILL_COMPANY_IDS.includes(this.props.match.params.companyId) ? validate.isEmpty(data.actionRequired) : false,
            "Type of Address Book": CARGILL_COMPANY_IDS.includes(this.props.match.params.companyId) ? validate.isEmpty(data.addressBookType) : false,
            "PAN & Aadhar Linkage ": CARGILL_COMPANY_IDS.includes(this.props.match.params.companyId) ? validate.isEmpty(data.panAadharLinkage) : false,
            "Supplier is specified Person": CARGILL_COMPANY_IDS.includes(this.props.match.params.companyId) ? validate.isEmpty(data.supplierPerson) : false,
            "Sort Key": CARGILL_COMPANY_IDS.includes(this.props.match.params.companyId) ? validate.isEmpty(data.accountingSortKey) : false,
            "Cash Management Group": CARGILL_COMPANY_IDS.includes(this.props.match.params.companyId) ? validate.isEmpty(data.cashMgmtGroup) : false,
            "Payment Terms": CARGILL_COMPANY_IDS.includes(this.props.match.params.companyId) ? validate.isEmpty(data.paymentTerms) : false,
            "Check Double Inv.": CARGILL_COMPANY_IDS.includes(this.props.match.params.companyId) ? validate.isEmpty(data.doubleInvCheck) : false,
            "Payment Method": CARGILL_COMPANY_IDS.includes(this.props.match.params.companyId) ? validate.isEmpty(data.paymentMethod) : false,
            "GR - Based Vendor Inv.Verify": CARGILL_COMPANY_IDS.includes(this.props.match.params.companyId) ? validate.isEmpty(data.verifyGRBased) : false,
            "Srv. Based Inv Verify": CARGILL_COMPANY_IDS.includes(this.props.match.params.companyId) ? validate.isEmpty(data.verifySrvBased) : false,
            // "IFSC Code": CARGILL_COMPANY_IDS.includes(this.props.match.params.companyId) ? validate.isEmpty(data.IFSC_code) : false,
            // "Bank Name": CARGILL_COMPANY_IDS.includes(this.props.match.params.companyId) ? validate.isEmpty(data.bank_name) : false,
            "IFSC Code": (cargilIfscCode || dplIfscCode),
            "Bank Name": (cargilBankName || dplBankName),
            "Bank Account No": CARGILL_COMPANY_IDS.includes(this.props.match.params.companyId) ? validate.isEmpty(data.account_number) : false,
            "Bank Country": CARGILL_COMPANY_IDS.includes(this.props.match.params.companyId) ? validate.isEmpty(data.bankcountryName) : false,
            // "Gst Details": CARGILL_COMPANY_IDS.includes(this.props.match.params.companyId) ? ((data.gstDetails && data.gstDetails.length > 0) ? false : true) : false,
            "Gst Details": (AwlGstDetails || DplGstDetails || cargilGstDetails),
            "Vendor Status": CARGILL_COMPANY_IDS.includes(this.props.match.params.companyId) ? validate.isEmpty(data.vendorStatus) : false,
            "GST No": CARGILL_COMPANY_IDS.includes(this.props.match.params.companyId) ? validate.isEmpty(data.gst_no) : false,
            // "Gst Details": ((isDPL) && data.is_gst_registered) ? ((data.gstDetails && data.gstDetails.length > 0) ? false : true) : false,
            // "Gst Details": (AWL_COMPANY_IDS.includes(this.props.match.params.companyId) && data.is_gst_registered) ? ((data.gstDetails && data.gstDetails.length > 0 && data.gstDetails.find(d => (d.gst_percentage == null || d.gstin == null || d.state == null || d.gstError))) ? true : false) : false,
            "FCM/RCM": (AWL_COMPANY_IDS.includes(this.props.match.params.companyId) && data.is_gst_registered) ? validate.isEmpty(data.FCN_RCN) : false,
            "MSME Detail": (AWL_COMPANY_IDS.includes(this.props.match.params.companyId) && data.is_msme_registered) ? validate.isEmpty(data.msme_detail) : false,
            "UDYAM Number": (AWL_COMPANY_IDS.includes(this.props.match.params.companyId) && data.is_msme_registered) ? (validate.isEmpty(data.udyam_no) || this.state.UDYAMError) : false,
             
            "itr_filing_date": (DEEPAK_COMPANY_ID.includes(this.props.match.params.companyId) && data.is_itr_filed) ? validate.isEmpty(data.itr_filing_date) : false,
            "itr_ack_number": (DEEPAK_COMPANY_ID.includes(this.props.match.params.companyId) && data.is_itr_filed) ? validate.isEmpty(data.itr_ack_number)  : false,
            "TDS Exemption": (ABFRL_COMPANY_IDS.includes(this.props.match.params.companyId) && data.is_tds_filed) ? validate.isEmpty(data.is_tds_filed)  : false,

            "Head Office Pincode": ((AWL_COMPANY_IDS.includes(this.props.match.params.companyId) || isDPL)) ? validate.isEmpty(data.head_office_pincode) : false,
            "Head Office State": (AWL_COMPANY_IDS.includes(this.props.match.params.companyId) || isDPL) ? validate.isEmpty(data.head_office_state) : false,
            "Head Office City": (AWL_COMPANY_IDS.includes(this.props.match.params.companyId) || isDPL) ? validate.isEmpty(data.head_office_city) : false,
            "Head Office Address": (AWL_COMPANY_IDS.includes(this.props.match.params.companyId) || isDPL) ? validate.isEmpty(data.head_office_address) : false,

            "Customer Email": data.customerList.find((customer) => !customer.email) ? true : false,
            "Customer Pincode": data.customerList.find((customer) => !customer.pin_code) ? true : false,
            "Customer Name": data.customerList.find((customer) => !customer.customer_name) ? true : false,
            "Customer Location": data.customerList.find((customer) => !customer.location) ? true : false,
            "Customer City": data.customerList.find((customer) => !customer.city) ? true : false,
            "Customer Service Provided": data.customerList.find((customer) => !customer.service_provided) ? true : false,
            "Customer Product Handled": data.customerList.find((customer) => !customer.product_handled) ? true : false,
            "Customer State": data.customerList.find((customer) => !customer.state) ? true : false,
            "Vendor Type": !DEEPAK_COMPANY_ID.includes(this.props.match.params.companyId) ? validate.isEmpty(data.vendor_type) : false,

            "Regional Office Address": !DEEPAK_COMPANY_ID.includes(this.props.match.params.companyId) ? (data.regionalOfficeList.find((regional) => !regional.regional_office_address) ? true : false) : false,
            "Regional Office City": !DEEPAK_COMPANY_ID.includes(this.props.match.params.companyId) ? (data.regionalOfficeList.find((regional) => !regional.regional_office_city) ? true : false) : false,
            "Regional Office Number": !DEEPAK_COMPANY_ID.includes(this.props.match.params.companyId) ? (data.regionalOfficeList.find((regional) => !regional.regional_office_number) ? true : false) : false,
            "Regional Office Pincode": !DEEPAK_COMPANY_ID.includes(this.props.match.params.companyId) ? (data.regionalOfficeList.find((regional) => !regional.regional_office_pincode) ? true : false) : false,
            "Regional Office State": !DEEPAK_COMPANY_ID.includes(this.props.match.params.companyId) ? (data.regionalOfficeList.find((regional) => !regional.regional_office_state) ? true : false) : false,
            "Country": CARGILL_COMPANY_IDS.includes(this.props.match.params.companyId) ? (data.regionalOfficeList.find((regional) => !regional.countryName) ? true : false) : false,

            "Branch Office Address": data.branchOfficeList.find((branch) => !branch.branch_office_address) ? true : false,
            "Branch Office City": data.branchOfficeList.find((branch) => !branch.branch_office_city) ? true : false,
            "Branch Office Number": data.branchOfficeList.find((branch) => !branch.branch_office_number) ? true : false,
            "Branch Office Pincode": data.branchOfficeList.find((branch) => !branch.branch_office_pincode) ? true : false,
            "Branch Office State": data.branchOfficeList.find((branch) => !branch.branch_office_state) ? true : false,

            // "Customer Email": data.customerList.find((customer) => customer.error) ? true : false,
            // "User Email": data.userList.find((user) => user.error) ? true : false,
            "Contact Verified": !this.state.verified ? true : false,

            "Blacklist selection": isDPL ? validate.isEmpty(data.is_blacklisted) : false,
            "DPL Employee Related Selection": isDPL ? validate.isEmpty(data.is_dplEmployee_Related) : false,
            "Sister Concern Apply Selection": isDPL ? validate.isEmpty(data.is_sisterConcern_applied) : false,

            // "IFSC Code": isDPL ? validate.isEmpty(data.IFSC_code) : false,
            // "Bank Name": isDPL ? validate.isEmpty(data.bank_name) : false,
            "Branch Name": isDPL ? validate.isEmpty(data.branch_name) : false,
            "Account No": isDPL ? validate.isEmpty(data.account_number) : false,
            "Account Name": isDPL ? validate.isEmpty(data.account_name) : false,
         }
         const validationFields = [];
         Object.keys(validationError).forEach((key) => {
            if (validationError[key]) {
               validationFields.push(key);
            }
         });
         let isNextValid;
         if (validationFields.length) {
            isNextValid = false;
         } else {
            isNextValid = true;
         }
         return isNextValid
      }
   }

   validateMandatory = () => {
      let { data, errorMsg, isValid } = this.state;

      const isDPL = DEEPAK_COMPANY_ID.includes(this.props.match.params.companyId)
      const isABFRL = ABFRL_COMPANY_IDS.includes(this.props.match.params.companyId)
      const AwlGstDetails = (AWL_COMPANY_IDS.includes(this.props.match.params.companyId) && data.is_gst_registered) ? ((data.gstDetails && data.gstDetails.length > 0 && data.gstDetails.find(d => (d.gst_percentage == null || d.gstin == null || d.state == null || d.gstError))) ? true : false) : false;
      const DplGstDetails = ((isDPL) && data.is_gst_registered) ? ((data.gstDetails && data.gstDetails.length > 0) ? false : true) : false;
      const cargilGstDetails = CARGILL_COMPANY_IDS.includes(this.props.match.params.companyId) ? ((data.gstDetails && data.gstDetails.length > 0) ? false : true) : false;
      const validationError = {
         "Company Type": validate.isEmpty(data.company_type),
         "Additional Email": this.state.additional_email_error ? true : false,
         "Registration Year": validate.isEmpty(data.registration_year),
         "PAN No.": validate.isEmpty(data.pan_no) || this.state.panError,
         "Payment Currency": validate.isEmpty(data.currency),
         "Turn Over Year": data.turnover_details.find((detail) => !detail.turnover_year),
         "Turn Over Value": data.turnover_details.find((detail) => !detail.turnoverValue),
         "Admin Details": (validate.isEmpty(data.userList[0].admin_name) || validate.isEmpty(data.userList[0].admin_location) || validate.isEmpty(data.userList[0].admin_email) || validate.isEmpty(data.userList[0].admin_designation) || validate.isEmpty(data.userList[0].admin_contact) || validate.isEmpty(data.userList[0].admin_department)),
         "Customer Details": (validate.isEmpty(data.customerList[0].customer_name) || validate.isEmpty(data.customerList[0].location) || validate.isEmpty(data.customerList[0].city) || validate.isEmpty(data.customerList[0].state) || validate.isEmpty(data.customerList[0].pin_code) || validate.isEmpty(data.customerList[0].email) || validate.isEmpty(data.customerList[0].product_handled) || validate.isEmpty(data.customerList[0].service_provided)),
         "User Email": validate.isEmpty(data.userList[0].admin_email),
         "Contact Number": validate.isEmpty(data.contact_no),
         "Aadhar Card No": (CARGILL_COMPANY_IDS.includes(this.props.match.params.companyId) ? true : (data.company_type == 'Proprietorship')) ? validate.isEmpty(data.aadhar_card_no) : false,

         "Contact Person Address": (isDPL) ? validate.isEmpty(data.contact_person_location) : false,

         "Date": CARGILL_COMPANY_IDS.includes(this.props.match.params.companyId) ? !data.dateRequested : false,
         "Action Required": CARGILL_COMPANY_IDS.includes(this.props.match.params.companyId) ? validate.isEmpty(data.actionRequired) : false,
         "Type of Address Book": CARGILL_COMPANY_IDS.includes(this.props.match.params.companyId) ? validate.isEmpty(data.addressBookType) : false,
         "PAN & Aadhar Linkage ": CARGILL_COMPANY_IDS.includes(this.props.match.params.companyId) ? validate.isEmpty(data.panAadharLinkage) : false,
         "Supplier is specified Person": CARGILL_COMPANY_IDS.includes(this.props.match.params.companyId) ? validate.isEmpty(data.supplierPerson) : false,
         "Sort Key": CARGILL_COMPANY_IDS.includes(this.props.match.params.companyId) ? validate.isEmpty(data.accountingSortKey) : false,
         "Cash Management Group": CARGILL_COMPANY_IDS.includes(this.props.match.params.companyId) ? validate.isEmpty(data.cashMgmtGroup) : false,
         "Payment Terms": CARGILL_COMPANY_IDS.includes(this.props.match.params.companyId) ? validate.isEmpty(data.paymentTerms) : false,
         "Check Double Inv.": CARGILL_COMPANY_IDS.includes(this.props.match.params.companyId) ? validate.isEmpty(data.doubleInvCheck) : false,
         "Payment Method": CARGILL_COMPANY_IDS.includes(this.props.match.params.companyId) ? validate.isEmpty(data.paymentMethod) : false,
         "GR - Based Vendor Inv.Verify": CARGILL_COMPANY_IDS.includes(this.props.match.params.companyId) ? validate.isEmpty(data.verifyGRBased) : false,
         "Srv. Based Inv Verify": CARGILL_COMPANY_IDS.includes(this.props.match.params.companyId) ? validate.isEmpty(data.verifySrvBased) : false,
         "IFSC Code": (CARGILL_COMPANY_IDS.includes(this.props.match.params.companyId)||(isDPL)) ? validate.isEmpty(data.IFSC_code) : false,
         "Bank Name": (CARGILL_COMPANY_IDS.includes(this.props.match.params.companyId)||(isDPL)) ? validate.isEmpty(data.bank_name) : false,
         "Bank Account No": (CARGILL_COMPANY_IDS.includes(this.props.match.params.companyId)||(isDPL)) ? validate.isEmpty(data.account_number) : false,
         "Branch Name": isDPL ? validate.isEmpty(data.branch_name) : false,
         "Account Name": isDPL ? validate.isEmpty(data.account_name) : false,
         "Bank Country": CARGILL_COMPANY_IDS.includes(this.props.match.params.companyId) ? validate.isEmpty(data.bankcountryName) : false,
         "Gst Details": (AwlGstDetails || DplGstDetails || cargilGstDetails),
         // "Gst Details": CARGILL_COMPANY_IDS.includes(this.props.match.params.companyId) ? ((data.gstDetails && data.gstDetails.length > 0) ? false : true) : false,
         "Vendor Status": CARGILL_COMPANY_IDS.includes(this.props.match.params.companyId) ? validate.isEmpty(data.vendorStatus) : false,
         "GST No": CARGILL_COMPANY_IDS.includes(this.props.match.params.companyId) ? validate.isEmpty(data.gst_no) : false,

         // "Gst Details": ((isDPL) && data.is_gst_registered) ? ((data.gstDetails && data.gstDetails.length > 0) ? false : true) : false,

         // "Gst Details": ((AWL_COMPANY_IDS.includes(this.props.match.params.companyId)||(isDPL)) && data.is_gst_registered) ? ((data.gstDetails && data.gstDetails.length > 0 && data.gstDetails.find(d => (d.gst_percentage == null || d.gstin == null || d.state == null || d.gstError))) ? true : false) : false,
         "FCM/RCM": ((AWL_COMPANY_IDS.includes(this.props.match.params.companyId)||(isDPL)) && data.is_gst_registered) ? validate.isEmpty(data.FCN_RCN) : false,
         "MSME Detail": (AWL_COMPANY_IDS.includes(this.props.match.params.companyId) && data.is_msme_registered) ? validate.isEmpty(data.msme_detail) : false,
         "UDYAM Number": (AWL_COMPANY_IDS.includes(this.props.match.params.companyId) && data.is_msme_registered) ? (validate.isEmpty(data.udyam_no) || this.state.UDYAMError) : false,
         "itr_filing_date": (DEEPAK_COMPANY_ID.includes(this.props.match.params.companyId) && data.is_itr_filed) ? (validate.isEmpty(data.itr_filing_date) || this.state.ITRDATEError) : false,
         "itr_ack_number": (DEEPAK_COMPANY_ID.includes(this.props.match.params.companyId) && data.is_itr_filed) ? (validate.isEmpty(data.itr_ack_number) || this.state.ITRACKError) : false,

         "Head Office Pincode": ((AWL_COMPANY_IDS.includes(this.props.match.params.companyId) || isDPL)) ? validate.isEmpty(data.head_office_pincode) : false,
         "Head Office State": (AWL_COMPANY_IDS.includes(this.props.match.params.companyId) || isDPL) ? validate.isEmpty(data.head_office_state) : false,
         "Head Office City": (AWL_COMPANY_IDS.includes(this.props.match.params.companyId) || isDPL) ? validate.isEmpty(data.head_office_city) : false,
         "Head Office Address": (AWL_COMPANY_IDS.includes(this.props.match.params.companyId) || isDPL) ? validate.isEmpty(data.head_office_address) : false,
         "Head Office Contact Number": (AWL_COMPANY_IDS.includes(this.props.match.params.companyId) || isDPL) ? validate.isEmpty(data.head_office_number) : false,

         "Customer Email": data.customerList.find((customer) => !customer.email) ? true : false,
         "Customer Pincode": data.customerList.find((customer) => !customer.pin_code) ? true : false,
         "Customer Name": data.customerList.find((customer) => !customer.customer_name) ? true : false,
         "Customer Location": data.customerList.find((customer) => !customer.location) ? true : false,
         "Customer City": data.customerList.find((customer) => !customer.city) ? true : false,
         "Customer Service Provided": data.customerList.find((customer) => !customer.service_provided) ? true : false,
         "Customer Product Handled": data.customerList.find((customer) => !customer.product_handled) ? true : false,
         "Customer State": data.customerList.find((customer) => !customer.state) ? true : false,

         "Vendor Type": !DEEPAK_COMPANY_ID.includes(this.props.match.params.companyId) ? validate.isEmpty(data.vendor_type) : false,

         "Regional Office Address": !(isDPL||isABFRL) ? (data.regionalOfficeList.find((regional) => !regional.regional_office_address) ? true : false) : false,
         "Regional Office City": !(isDPL||isABFRL) ? (data.regionalOfficeList.find((regional) => !regional.regional_office_city) ? true : false) : false,
         "Regional Office Number": !(isDPL||isABFRL) ? (data.regionalOfficeList.find((regional) => !regional.regional_office_number) ? true : false) : false,
         "Regional Office Pincode": !(isDPL||isABFRL) ? (data.regionalOfficeList.find((regional) => !regional.regional_office_pincode) ? true : false) : false,
         "Regional Office State": !(isDPL||isABFRL) ? (data.regionalOfficeList.find((regional) => !regional.regional_office_state) ? true : false) : false,
         "Country": CARGILL_COMPANY_IDS.includes(this.props.match.params.companyId) ? (data.regionalOfficeList.find((regional) => !regional.countryName) ? true : false) : false,

         "Branch Office Address": ((!isDPL) && data.branchOfficeList.find((branch) => !branch.branch_office_address)) ? true : false,
         "Branch Office City": ((!isDPL) && data.branchOfficeList.find((branch) => !branch.branch_office_city)) ? true : false,
         "Branch Office Number": ((!isDPL) && data.branchOfficeList.find((branch) => !branch.branch_office_number)) ? true : false,
         "Branch Office Pincode": ((!isDPL) && data.branchOfficeList.find((branch) => !branch.branch_office_pincode)) ? true : false,
         "Branch Office State": ((!isDPL) && data.branchOfficeList.find((branch) => !branch.branch_office_state)) ? true : false,

         // "Customer Email": data.customerList.find((customer) => customer.error) ? true : false,
         // "User Email": data.userList.find((user) => user.error) ? true : false,

         "Blacklist selection": isDPL ? validate.isEmpty(data.is_blacklisted) : false,
         "DPL Employee Related Selection": isDPL ? validate.isEmpty(data.is_dplEmployee_Related) : false,
         "Sister Concern Apply Selection": isDPL ? validate.isEmpty(data.is_sisterConcern_applied) : false,

      }

      const validationFields = [];
      Object.keys(validationError).forEach((key) => {
         if (validationError[key]) {
            validationFields.push(key);
         }
      });

      if (validationFields.length) {
         errorMsg = `Please Enter Valid ${validate.join(validationFields, (item, idx) => {
            if (validationFields.length === 1) {
               return "";
            }
            if (idx > 0 && idx < validationFields.length - 1) {
               return ", ";
            } else if (idx === validationFields.length - 1) {
               return " and ";
            }
            return "";
         })}.`;
         isValid = true;
      } else {
         errorMsg = '';
         isValid = false;
      }
      this.setState({ errorMsg });
      return isValid;
   }

   savePrimaryDetails = () => {
      const { data, verified } = this.state;
      const { dispatch, match } = this.props;
      if (( (AWL_COMPANY_IDS.includes(match.params.companyId)) || (CARGILL_COMPANY_IDS.includes(match.params.companyId))|| (DEEPAK_COMPANY_ID.includes(match.params.companyId)) || (ABFRL_COMPANY_IDS.includes(match.params.companyId))) ? !this.validateMandatory() : true) {
         if (!verified) {
            this.setState({ verifiedError: 'Please verify your contact number' })
         }
         else {
            delete this.state.verifiedError
            dispatch({
               type: ACTION.VENDORS.PUT_PRIMARY_VENDOR_FORM,
               data: data,
               onSuccess: (() => {
                  this.setState({ isPage: 2 })
                  this.getBasicVendorDetails();
               })
            });
         }
      }
   }

  
   saveSecondaryDetails = (type) => {
      const { data, dataSecond, isPageSaved3 } = this.state;
      const { dispatch, match } = this.props;
      if(type == 'submit' && dataSecond){
         dataSecond.isSubmit = true
      }
      if ((DEEPAK_COMPANY_ID.includes(match.params.companyId) || AWL_COMPANY_IDS.includes(match.params.companyId) || CARGILL_COMPANY_IDS.includes(match.params.companyId)) ? !this.validateSecondaryMandatory() : true) {
         dispatch({
            type: ACTION.VENDORS.POST_SECONDARY_VENDOR_FORM,
            data: dataSecond,
            ...(type == 'submit') ? { close: true } : {},
            onSuccess: (() => {
               this.setState({ isPage: 3 }); window.scrollTo(0, 0);
               this.getBasicVendorDetails();
               if (history && isPageSaved3) { history.goBack(); }

               if (type == 'submit') {
                  if (history.length > 1) {
                     dispatch({
                        type: ACTION.VENDORS.SUBMIT_PRIMARY_VENDOR_FORM,
                        data: data,
                        onSuccess: (() => {
                           this.setState({ showModal: true });
                        })
                     });
                    
                  } else {
                     window.close()
                  }
               }
            })
         });
      }
   }

   handleClose = () => {
      this.setState({ showModal: false });
      history.goBack()
    };

   uploadGST = (e) => {
      let formData = new FormData();
      const { dispatch, match } = this.props;
      const { data } = this.state;
      formData.append('file', e.target.files[0]);
      formData.append('company_id', match.params.companyId);
      formData.append('vendor_id', match.params.vendorId);
      formData.append('type', 1);
      dispatch({
         type: ACTION.VENDORS.GET_VENDOR_GST_DETAILS,
         data: formData,
         onSuccess: ((gstData) => {
            if (gstData) {
               data.gstDetails = gstData.data.gstin_details;
               this.setState({ data })
            }
         })
      })
   }

   downloadGST = () => {
      const { dispatch } = this.props;
      dispatch({
         type: ACTION.VENDORS.DOWNLOAD_VENDOR_GST_DETAILS,
         onSuccess: ((data) => {
            this.setState({
               downloadLink: data[0].Link
            });
            this.downloadBtn.click();
         })
      });
   }

   downloadVehicleDetails = () => {
      const { dispatch } = this.props;
      dispatch({
         type: ACTION.VENDORS.DOWNLOAD_VEHICLE_DETAILS,
         onSuccess: ((data) => {
            this.setState({
               downloadLink: data[0].Link
            });
            this.downloadBtn.click();
         })
      });
   }

   uploadVehicleDetails = (e) => {
      let formData = new FormData();
      const { dispatch, match } = this.props;
      const { dataSecond } = this.state;
      formData.append('file', e.target.files[0]);
      formData.append('company_id', match.params.companyId);
      formData.append('vendor_id', match.params.vendorId);
      formData.append('type', 1);
      dispatch({
         type: ACTION.VENDORS.GET_UPLOADED_VEHICLE_DETAILS,
         data: formData,
         onSuccess: ((data) => {
            if (data && data.data.vehicle_details && data.data.vehicle_details.length > 0) {
               dataSecond.vehicle_details = data.data.vehicle_details;
               this.setState({ dataSecond })
            }
         })
      })
   }

   updateSecondaryDetails = () => {
      const { dataSecond } = this.state;
      const { dispatch } = this.props;
      dispatch({
         type: ACTION.VENDORS.UPDATE_SECONDARY_VENDOR_FORM,
         data: dataSecond,
         onSuccess: (() => {
            // this.setState({})
            this.getBasicVendorDetails();
         })
      });
   }

   uploadVendorDocs = () => {
      const { fileNames } = this.state;
      const fileListData = [];
      if (fileNames.length > 1) {
         const formData = new FormData();
         for (let i = 0; i < fileNames.length; i++) {
            if (fileNames[i].name !== "") {
               formData.append("file", this.state.fileNames[i].fileObject);
               formData.append("file_name", this.state.fileNames[i].docName)
               formData.append("fileId", this.state.fileNames[i].Id)
               // fileListData.push({ "id": this.state.fileNames[i].Id });
            }
         }
         formData.append("ob_vendor_id", this.props.match.params.vendorId)
         // formData.append("fileId", JSON.stringify(fileListData));
         formData.append("seekerId", this.props.match.params.companyId);

         this.props.dispatch({
            type: ACTION.VENDORS.UPLOAD_VENDOR_DOCS,
            data: formData,
            onSuccess: (() => {
               this.setState({ fileNames: [{ fileObject: "", name: "" }] });
               this.getBasicVendorDetails();
            })
         });
      }
   }

   uploadExternalApproverDocument = () => {
      const { external_approver_docs } = this.state;
      const fileListData = [];

      const path = window.location.pathname
      const userId = path && path.split('/')[4]

      if (external_approver_docs.length > 0) {
         const formData = new FormData();
         for (let i = 0; i < external_approver_docs.length; i++) {
            if (external_approver_docs[i].name !== "") {
               formData.append("file", this.state.external_approver_docs[i].fileObject);
               fileListData.push({ "id": this.state.external_approver_docs[i].Id });
            }
         }
         formData.append("ob_vendor_id", this.props.match.params.vendorId)
         formData.append("userId", userId);
         formData.append("seekerId", this.props.match.params.companyId);

         this.props.dispatch({
            type: ACTION.VENDORS.UPLOAD_EXTERNAL_APPROVER_DOC_IN_VENDOR,
            data: formData,
            onSuccess: ((data) => {
               if (data && data.user_details) {
                  this.setState({ external_user_details: data.user_details })
               }
               this.setState({ external_approver_docs: [] });
               this.getBasicVendorDetails();
            })
         });
      }
   }
   uploadApproverDocument = () => {
      const { approver_docs } = this.state;
      const fileListData = [];
      const userId = getUserId()

      if (approver_docs.length > 0) {
         const formData = new FormData();
         for (let i = 0; i < approver_docs.length; i++) {
            if (approver_docs[i].name !== "") {
               formData.append("file", this.state.approver_docs[i].fileObject);
               fileListData.push({ "id": this.state.approver_docs[i].Id });
            }
         }
         formData.append("ob_vendor_id", this.props.match.params.vendorId)
         formData.append("userId", userId);
         formData.append("seekerId", this.props.match.params.companyId);

         this.props.dispatch({
            type: ACTION.VENDORS.UPLOAD_APPROVER_DOC_IN_VENDOR,
            data: formData,
            onSuccess: ((data) => {
               if (data && data.user_details) {
                  this.setState({ external_user_details: data.user_details })
               }
               this.setState({ approver_docs: [] });
               this.getBasicVendorDetails();
            })
         });
      }
   }

   handleRemoveNewFileUploaded = (type, fileIndex) => {
      const { fileNames } = this.state;
      if (fileNames.length > 0) {
         fileNames.splice(fileIndex, 1);
         this.setState({ fileNames });
      }
   }

   handleRemoveExternalFileUpload = (type, fileIndex) => {
      const { external_approver_docs } = this.state;
      if (external_approver_docs.length > 0) {
         external_approver_docs.splice(fileIndex, 1);
         this.setState({ external_approver_docs });
      }
   };
   handleRemoveFileUpload = (type, fileIndex) => {
      const { approver_docs } = this.state;
      if (approver_docs.length > 0) {
         approver_docs.splice(fileIndex, 1);
         this.setState({ approver_docs });
      }
   };

   checkIsArrayWithLength = (data) => {
      const C1 = data && Array.isArray(data)
      const C2 = data.length > 0

      if(C1 && C2){
         return true
      }

      return false
   }

   checkIfKeyThere = (data) => {
      const C1 = this.checkIsArrayWithLength(data);

      if (
        C1 &&
        data[0].hasOwnProperty('supplierTaxRate') &&
        this.checkIsArrayWithLength(data[0].supplierTaxRate)
      ) {
        return true;
      }

      return false;
    }
    

   getBasicVendorDetails = () => {
      const { dataSecond, taxCodeDetailsRows } = this.state;
      const { dispatch } = this.props;
      dispatch({
         type: ACTION.VENDORS.GET_VENDOR_DETAILS,
         params: `?vendorId=${this.props.match.params.vendorId}`,
         onSuccess: ((data) => {
            let type_of_vehicle = data && data[1][0].type_of_vehicle
            let vehicle_type_options = []
            type_of_vehicle.map((vehicle) => {
               vehicle_type_options.push({
                  label: vehicle,
                  value: vehicle
               })
            })
            const taxDetails = this.checkIfKeyThere(data) ? data[0].supplierTaxRate : taxCodeDetailsRows
            const sapKey = this.checkIsArrayWithLength(data) && data[0].hasOwnProperty('send_to_sap') ? data[0].send_to_sap : false
            this.setState({ 
               vehicle_type_options: vehicle_type_options,
               taxCodeDetailsRows : taxDetails,
               isSendToSap : sapKey
            })
         })
      });

   }

   uploadOperationalArea = (e, id) => {
      let formData = new FormData();
      formData.append('file', e.target.files[0]);
      formData.append('ob_vendor_id', this.props.match.params.vendorId);
      const type = (id === undefined) ? 0 : id
      formData.append('temp_type', type)

      if (this.props.match.params.hasCP === '1') {
         formData.append('is_vendor', true)
      }

      this.props.dispatch({
         type: ACTION.VENDORS.UPLOAD_VENDOR_OP_AREAS,
         data: formData,
         onSuccess: (() => {
            this.getRouteDetails()
         })
      })
   }

   downloadOperationalArea = (id) => {
      const { dispatch, match } = this.props;
      dispatch({
         type: ACTION.VENDORS.DOWNLOAD_OPERATIONAL_AREAS,
         params: `?company_id=${match.params.companyId}&ob_vendor_id=${match.params.vendorId}&temp_type=${id}`,
         onSuccess: ((data) => {
            if (data.length > 0) {
               this.setState({ downloadLink: data[0].Link }, () => {
                  this.downloadOp.click();
               });
            }
         })
      });
   }

   multipleFilesUpload = (e, id) => {
      const { dispatch } = this.props;
      const isDPL = DEEPAK_COMPANY_ID.includes(this.props.match.params.companyId)
      e.persist();
      var indexId = Math.floor(Math.random() * 100) + 1;
      let fileNames = this.state.fileNames;
      const acceptedFormats = isDPL ? ["application/pdf", "image/jpeg","image/jpg", "image/png"] : ["application/pdf"];
      for (let i = 0; i < e.target.files.length; i++) {
         const reader = new FileReader();
         const fileObj = e.target.files[i];
         if (acceptedFormats.includes(fileObj.type)) {
            reader.onload = e => {
               let fileObject = fileObj;
               let name = e.target.result;
               if (id === "pancardDocfile") {
                  fileNames.push({ "fileObject": fileObject, "name": fileObject.name, "isPanCardDoc": true, Id: 1, indexId: indexId, docName: 'PAN Card' });
                  this.setState({
                     fileNames
                  });
               }
               if (id === "bankDetailsAndCancelledChecqueDocfile") {
                  fileNames.push({ "fileObject": fileObject, "name": fileObject.name, "isBankDetailsDoc": true, Id: 2, indexId: indexId, docName: 'Bank & Cancelled Cheques' });
                  this.setState({
                     fileNames
                  });
               }
               if (id === "memorandamOfAssociationDocfile") {
                  fileNames.push({ "fileObject": fileObject, "name": fileObject.name, "isMemoAssociationDoc": true, Id: 3, indexId: indexId, docName: 'Memorandum of Association' });
                  this.setState({
                     fileNames
                  });
               }
               if (id === "articleForAssociationDocfile") {
                  fileNames.push({ "fileObject": fileObject, "name": fileObject.name, "isArticleAssociationDoc": true, Id: 4, indexId: indexId, docName: 'Article of Association' });
                  this.setState({
                     fileNames
                  });
               }
               if (id === "complianceDeclarationDocfile") {
                  fileNames.push({ "fileObject": fileObject, "name": fileObject.name, "isComplianceDecDoc": true, Id: 5, indexId: indexId, docName: 'Compliance Declaration Form' });
                  this.setState({
                     fileNames
                  });
               }
               if (id === "gstCertificatesDocfile") {
                  fileNames.push({ "fileObject": fileObject, "name": fileObject.name, "isGSTCertDoc": true, Id: 6, indexId: indexId, docName: 'GST Certificate' });
                  this.setState({
                     fileNames
                  });
               }
               if (id === "addressProof") {
                  fileNames.push({ "fileObject": fileObject, "name": fileObject.name, "isaddressProofDoc": true, Id: 7, indexId: indexId, docName: 'Address Proof' });
                  this.setState({
                     fileNames
                  });
               }
               if (id === "visitingCard") {
                  fileNames.push({ "fileObject": fileObject, "name": fileObject.name, "isvisitingCard": true, Id: 8, indexId: indexId, docName: 'Visiting Card' });
                  this.setState({
                     fileNames
                  });
               }
               if (id === "bankVerificationLetter") {
                  fileNames.push({ "fileObject": fileObject, "name": fileObject.name, "isbankVerificationLetter": true, Id: 9, indexId: indexId, docName: 'Bank Verification Letter' });
                  this.setState({
                     fileNames
                  });
               }
               if (id === "certificationOfIncorporation") {
                  fileNames.push({ "fileObject": fileObject, "name": fileObject.name, "iscertificationOfIncorporation": true, Id: 10, indexId: indexId, docName: 'Certification of Incorporation' });
                  this.setState({
                     fileNames
                  });
               }
               if (id === "carrierCertificate") {
                  fileNames.push({ "fileObject": fileObject, "name": fileObject.name, "iscarrierCertificate": true, Id: 11, indexId: indexId, docName: 'Carrier Certificate' });
                  this.setState({
                     fileNames
                  });
               }
               if (id === "tradeLicence") {
                  fileNames.push({ "fileObject": fileObject, "name": fileObject.name, "istradeLicence": true, Id: 12, indexId: indexId, docName: 'Trade Licence' });
                  this.setState({
                     fileNames
                  });
               }
               if (id === "fssiCertificate") {
                  fileNames.push({ "fileObject": fileObject, "name": fileObject.name, "isfssiCertificate": true, Id: 13, indexId: indexId, docName: 'FSSAI Certificate' });
                  this.setState({
                     fileNames
                  });
               }
               if (id === "rcBookDetailsUpload") {
                  fileNames.push({ "fileObject": fileObject, "name": fileObject.name, "isrcBookDetailsUpload": true, Id: 14, indexId: indexId, docName: 'RC Book Details upload' });
                  this.setState({
                     fileNames
                  });
               }
               if (id === "msmeCertificate") {
                  fileNames.push({ "fileObject": fileObject, "name": fileObject.name, "ismsmeCertificate": true, Id: 15, indexId: indexId, docName: 'MSME Certificate' });
                  this.setState({
                     fileNames
                  });
               }
               if (id === "itrFiled") {
                  fileNames.push({ "fileObject": fileObject, "name": fileObject.name, "isitrFiled": true, Id: 22, indexId: indexId, docName: 'ITR-FILED' });
                  this.setState({
                     fileNames
                  });
               }
               if (id === "aadharCardCode") {
                  fileNames.push({ "fileObject": fileObject, "name": fileObject.name, "isaadharCardCode": true, Id: 16, indexId: indexId, docName: 'Aadhar Card Code/Profile' });
                  this.setState({
                     fileNames
                  });
               }
               if (id === "uploadAgreement") {
                  fileNames.push({ "fileObject": fileObject, "name": fileObject.name, "isuploadAgreement": true, Id: 21, indexId: indexId, docName: 'Upload Agreement' });
                  this.setState({
                     fileNames
                  });
               }
               if (id === "letterHead") {
                  fileNames.push({ "fileObject": fileObject, "name": fileObject.name, "isletterHead": true, Id: 17, indexId: indexId, docName: 'Invoice, LetterHead or Statutory' });
                  this.setState({
                     fileNames
                  });
               }
               if (id === "udyamCertificate") {
                  fileNames.push({ "fileObject": fileObject, "name": fileObject.name, "isudyamCertificate": true, Id: 18, indexId: indexId, docName: 'UDYAM Certificate' });
                  this.setState({
                     fileNames
                  });
               }
               if (id === "E-PaymentNotification") {
                  fileNames.push({ "fileObject": fileObject, "name": fileObject.name, "isEpayNotification": true, Id: 23, indexId: indexId, docName: 'E-Payment Notification' });
                  this.setState({
                     fileNames
                  });
               }
               if (id === "ComplianceForm") {
                  fileNames.push({ "fileObject": fileObject, "name": fileObject.name, "isComplianceForm": true, Id: 24, indexId: indexId, docName: 'Compliance form - declaration' });
                  this.setState({
                     fileNames
                  });
               }
               if (id === "BalanceSheet") {
                  fileNames.push({ "fileObject": fileObject, "name": fileObject.name, "isBalanceSheet": true, Id: 25, indexId: indexId, docName: 'Balance sheet' });
                  this.setState({
                     fileNames
                  });
               }
               if (id === "carriageAct") {
                  fileNames.push({ "fileObject": fileObject, "name": fileObject.name, "isCarriageAct": true, Id: 26, indexId: indexId, docName: 'Carriage Act 2007 Certificate' });
                  this.setState({
                     fileNames
                  });
               }
               if (id === "E-IRN") {
                  fileNames.push({ "fileObject": fileObject, "name": fileObject.name, "isEirn": true, Id: 27, indexId: indexId, docName: 'E-IRN Invoice Declaration' });
                  this.setState({
                     fileNames
                  });
               }
            }
         }
         else {
            let errorMessage =
            isDPL
              ? "Only PDF, JPEG, JPG, or PNG formats are acceptable!"
              : "Only PDF format is acceptable!";
            dispatch({
               type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
               data: { type: 2, msg: errorMessage }
            });
         }
         reader.readAsDataURL(fileObj);
      }
   }

   declarationsFileUpload = (e, id) => {
      const { dispatch } = this.props;
      const isDPL = DEEPAK_COMPANY_ID.includes(this.props.match.params.companyId)
      e.persist();
      var indexId = Math.floor(Math.random() * 100) + 1;
      let declarationFiles = this.state.declarationFiles;
      const acceptedFormats = isDPL ? ["application/pdf", "image/jpeg","image/jpg", "image/png"] : ["application/pdf"];
      for (let i = 0; i < e.target.files.length; i++) {
         const reader = new FileReader();
         const fileObj = e.target.files[i];

         if (acceptedFormats.includes(fileObj.type)) {
            reader.onload = e => {
               let fileObject = fileObj;
               let name = e.target.result;
               if (id === "filedItr") {
                  declarationFiles.push({ "fileObject": fileObject, "name": fileObject.name, "isfiledITR": true, Id: 22, indexId: indexId, docName: 'ITR-FILED' });
                  this.setState({
                     declarationFiles
                  });
               }
               if (id === "ComplianceForm") {
                  declarationFiles.push({ "fileObject": fileObject, "name": fileObject.name, "isComplianceForm": true, Id: 24, indexId: indexId, docName: 'Compliance form - declaration' });
                  this.setState({
                     declarationFiles
                  });
               }
               if (id === "E-IRN") {
                  declarationFiles.push({ "fileObject": fileObject, "name": fileObject.name, "isEirn": true, Id: 27, indexId: indexId, docName: 'E-IRN Invoice Declaration' });
                  this.setState({
                     declarationFiles
                  });
               }
               if (id === "tdsTcs") {
                  declarationFiles.push({ "fileObject": fileObject, "name": fileObject.name, "istdsTcs": true, Id: 1, indexId: indexId, docName: 'TDS-TCS' });
                  this.setState({
                     declarationFiles
                  });
               }
               if (id === "notRegisteredMsme") {
                  declarationFiles.push({ "fileObject": fileObject, "name": fileObject.name, "isnotRegisteredMsme": true, Id: 2, indexId: indexId, docName: 'Not registered MSME' });
                  this.setState({
                     declarationFiles
                  });
               }
               if (id === "notRegisteredGst") {
                  declarationFiles.push({ "fileObject": fileObject, "name": fileObject.name, "isnotRegisteredGst": true, Id: 3, indexId: indexId, docName: 'Not registered GST declaration' });
                  this.setState({
                     declarationFiles
                  });
               }
               if (id === "eInvocieApplicable") {
                  declarationFiles.push({ "fileObject": fileObject, "name": fileObject.name, "iseInvocieApplicable": true, Id: 4, indexId: indexId, docName: 'E-invoice applicable above 5 crores' });
                  this.setState({
                     declarationFiles
                  });
               }
               if (id === "eInvoiceNotApplicable") {
                  declarationFiles.push({ "fileObject": fileObject, "name": fileObject.name, "iseInvoiceNotApplicable": true, Id: 5, indexId: indexId, docName: 'E-invoice not applicable below 5 crores' });
                  this.setState({
                     declarationFiles
                  });
               }
               if (id === "MSMED Form") {
                  declarationFiles.push({ "fileObject": fileObject, "name": fileObject.name, "isMSMED": true, Id: 6, indexId: indexId, docName: 'MSMED Form' });
                  this.setState({
                     declarationFiles
                  });
               }
               if (id === "Filled up Vendor profile form along with approval from respective controller") {
                  declarationFiles.push({ "fileObject": fileObject, "name": fileObject.name, "isVendorProfile": true, Id: 7, indexId: indexId, docName: 'Filled up Vendor profile form along with approval from respective controller' });
                  this.setState({
                     declarationFiles
                  });
               }
               if (id === "Annexure 1 to Cargill as buyer") {
                  declarationFiles.push({ "fileObject": fileObject, "name": fileObject.name, "isAsBuyer": true, Id: 8, indexId: indexId, docName: 'Annexure 1 to Cargill as buyer' });
                  this.setState({
                     declarationFiles
                  });
               }
               if (id === "vendorAppliaction") {
                  declarationFiles.push({ "fileObject": fileObject, "name": fileObject.name, "isvendorAppliaction": true, Id: 9, indexId: indexId, docName: 'Vendor Application' });
                  this.setState({
                     declarationFiles
                  });
               }
               if (id === "awlCarrierAgreement") {
                  declarationFiles.push({ "fileObject": fileObject, "name": fileObject.name, "isawlCarrierAgreement": true, Id: 10, indexId: indexId, docName: 'Awl Carrier Agreement' });
                  this.setState({
                     declarationFiles
                  });
               }
            }
         }
         else {
            let errorMessage =
            isDPL
              ? "Only PDF, JPEG, JPG or PNG formats are acceptable!"
              : "Only PDF format is acceptable!";
            dispatch({
               type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
               data: { type: 2, msg: errorMessage }
            });
         }

         reader.readAsDataURL(fileObj);
      }
   }

   externalApproverDocUpload = (e, id) => {
      const { dispatch } = this.props;
      e.persist();
      var indexId = Math.floor(Math.random() * 100) + 1;
      let fileNames = this.state.external_approver_docs;
      for (let i = 0; i < e.target.files.length; i++) {
         const reader = new FileReader();
         const fileObj = e.target.files[i];
         reader.onload = e => {
            let fileObject = fileObj;
            fileNames.push({ "fileObject": fileObject, "name": fileObject.name, indexId: indexId, Id: indexId });
            this.setState({
               external_approver_docs: fileNames
            });
         }
         reader.readAsDataURL(fileObj);
      }
   }

   ApproverDocUpload = (e, id) => {
      const { dispatch } = this.props;
      e.persist();
      var indexId = Math.floor(Math.random() * 100) + 1;
      let fileNames = this.state.approver_docs;
      for (let i = 0; i < e.target.files.length; i++) {
         const reader = new FileReader();
         const fileObj = e.target.files[i];
         reader.onload = e => {
            let fileObject = fileObj;
            fileNames.push({ "fileObject": fileObject, "name": fileObject.name, indexId: indexId, Id: indexId });
            this.setState({
               approver_docs: fileNames
            });
         }
         reader.readAsDataURL(fileObj);
      }
   }

   handleRemoveNewDeclarationFile = (type, fileIndex) => {
      const { declarationFiles } = this.state;
      if (declarationFiles.length > 0) {
         declarationFiles.splice(fileIndex, 1);
         this.setState({ declarationFiles });
      }
   }

   uploadDeclarationFiles = () => {
      const { declarationFiles } = this.state;
      const fileListData = [];
      if (declarationFiles.length > 0) {
         const formData = new FormData();
         for (let i = 0; i < declarationFiles.length; i++) {
            if (declarationFiles[i].name !== "") {
               formData.append("file", this.state.declarationFiles[i].fileObject);
               // fileListData.push({ "id": this.state.declarationFiles[i].Id, "file_name": this.state.declarationFiles[i].docName });
               formData.append("file_name", this.state.declarationFiles[i].docName)
               formData.append("fileId", this.state.declarationFiles[i].Id)
            }
         }
         formData.append("ob_vendor_id", this.props.match.params.vendorId)
         //  formData.append("fileId", JSON.stringify(fileListData));
         formData.append("seekerId", this.props.match.params.companyId);

         this.props.dispatch({
            type: ACTION.VENDORS.UPLOAD_VENDOR_DECLARATION_FILES,
            data: formData,
            onSuccess: (() => {
               this.setState({ declarationFiles: [{ fileObject: "", name: "" }] });
               this.getBasicVendorDetails();
            })
         });
      }
   }

   bankDocumentUpload = (e, id) => {
      const { dispatch } = this.props;
      e.persist();
      var indexId = Math.floor(Math.random() * 100) + 1;
      let bankApproverFiles = this.state.bankApproverFiles;
      for (let i = 0; i < e.target.files.length; i++) {
         const reader = new FileReader();
         const fileObj = e.target.files[i];

         // if (fileObj.type == 'application/pdf') {
         reader.onload = e => {
            let fileObject = fileObj;
            let name = e.target.result;
            if (id === "scanned_copy_of_cheque") {
               bankApproverFiles.push({ "fileObject": fileObject, "name": fileObject.name, "is_scanned_copy_of_cheque": true, Id: 1, indexId: indexId, docName: 'Scanned Copy of Cheque' });
               this.setState({
                  bankApproverFiles
               });
            }
            if (id === "scanned_copy_of_guarantee") {
               bankApproverFiles.push({ "fileObject": fileObject, "name": fileObject.name, "is_scanned_copy_of_guarantee": true, Id: 2, indexId: indexId, docName: 'Scanned Copy of Guarantee' });
               this.setState({
                  bankApproverFiles
               });
            }
            if (id === "other_docs") {
               bankApproverFiles.push({ "fileObject": fileObject, "name": fileObject.name, "is_other_docs": true, Id: 3, indexId: indexId, docName: 'Other Documents' });
               this.setState({
                  bankApproverFiles
               });
            }
         }
         // }
         // else {
         //    dispatch({
         //       type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
         //       data: { type: 2, msg: "Only Pdf format is acceptable!" }
         //    });
         // }

         reader.readAsDataURL(fileObj);
      }
   }

   documentUploadForBank = () => {
      const { bankApproverFiles } = this.state;
      if (bankApproverFiles.length > 0) {
         const formData = new FormData();
         for (let i = 0; i < bankApproverFiles.length; i++) {
            if (bankApproverFiles[i].name !== "") {
               formData.append("file", this.state.bankApproverFiles[i].fileObject);
               formData.append("file_name", this.state.bankApproverFiles[i].docName)
               formData.append("fileId", this.state.bankApproverFiles[i].Id)
            }
         }
         formData.append("ob_vendor_id", this.props.match.params.vendorId)
         formData.append("seekerId", this.props.match.params.companyId);

         this.props.dispatch({
            type: ACTION.VENDORS.BANK_APPROVER_DOCUMENT_UPLOAD,
            data: formData,
            onSuccess: (() => {
               this.setState({ bankApproverFiles: [] });
               this.getBasicVendorDetails();
            })
         });
      }
   }

   sendChallengePrice = (data, areas) => {
      const { dispatch, match, } = this.props;
      const { challenge_price } = this.state;

      let dataParam = null;

      if (!data) {
         dataParam = [];
         Object.keys(challenge_price).forEach((cpId) => {
            const rateObj = areas.find(el => el.area_id === cpId);
            const item = {
               company_id: match.params.companyId,
               ob_vendor_id: match.params.vendorId,
               rate: Object.keys(rateObj).length > 0 ? rateObj.rate : 0,
               area_id: cpId,
               challenge_price: parseFloat(challenge_price[cpId]),
            };
            dataParam.push(item);
         });
      } else {
         dataParam = [{
            company_id: match.params.companyId,
            ob_vendor_id: match.params.vendorId,
            area_id: data.area_id,
            rate: data.rate || 0,
            challenge_price: challenge_price[data.area_id],
         }];
      }

      dispatch({
         type: ACTION.VENDORS.SEND_CHALLENGE_PRICE,
         data: { areas: dataParam },
         onSuccess: (() => {
            this.setState({ challenge_price: {} });
            this.getRouteDetails();
         })
      })
   }

   challengePriceApproval = (item, isCPAccept, isCounterAccept) => {
      const { dispatch } = this.props;
      const data = {
         company_id: this.props.match.params.companyId,
         ob_vendor_id: this.props.match.params.vendorId,
         area_id: item.area_id,
         rate: item.rate,
      }
      if (isCPAccept !== undefined) {
         data.challenge_accepted = isCPAccept;
         data.challenge_price = item.challenge_price;
      }
      if (isCounterAccept !== undefined) {
         data.counter_accepted = isCounterAccept;
         data.counter_price = item.counter_price;
      }
      if (Object.keys(this.state.counter_price).length > 0 && this.state.counter_price[data.area_id]) {
         data.counter_price = this.state.counter_price[data.area_id];
      }
      dispatch({
         type: ACTION.VENDORS.SEND_CHALLENGE_PRICE,
         data: { areas: [data] },
         onSuccess: (() => {
            this.setState({ counter_price: {} });
            this.getRouteDetails();
         })
      })
   }

   sendCounterPrice = (data, areas) => {
      const { dispatch, match } = this.props;
      const { counter_price } = this.state;

      let dataParam = null;

      if (!data) {
         dataParam = [];
         Object.keys(counter_price).forEach((cpId) => {
            const rateObj = areas.find(el => el.area_id === cpId);
            const item = {
               company_id: match.params.companyId,
               ob_vendor_id: match.params.vendorId,
               rate: Object.keys(rateObj).length > 0 ? rateObj.rate : 0,
               area_id: cpId,
               counter_price: parseFloat(counter_price[cpId]),
            };
            dataParam.push(item);
         });
      } else {
         dataParam = [{
            company_id: match.params.companyId,
            ob_vendor_id: match.params.vendorId,
            area_id: data.area_id,
            rate: data.rate || 0,
            counter_price: counter_price[data.area_id],
         }];
      }

      dispatch({
         type: ACTION.VENDORS.SEND_CHALLENGE_PRICE,
         data: { areas: dataParam },
         onSuccess: (() => {
            this.setState({ counter_price: {} });
            this.getRouteDetails();
         })
      })
   }

   prevRoutes = () => {
      if (this.state.offset > 0) {
         this.setState({
            offset: this.state.offset - 1
         }, () => {
            this.getRouteDetails()
         });
      }
   }
   nextRoutes = () => {
      this.setState({
         offset: this.state.offset + 1
      }, () => {
         this.getRouteDetails()
      });
   }

   onTankerCancel = (index) => {
      const { dataSecond } = this.state;
      dataSecond.tankerDetails.splice(index, 1);
      this.setState({
         dataSecond
      })
   }

   handleAcceptTaC = () => {
      this.setState({ isAccepted: !this.state.isAccepted })
   }

   closeMsgPopup = () => {
      const { dispatch } = this.props;
      dispatch({ type: ACTION.MSG_DISPLAY.HIDE_MSG })
   }

   addUser = () => {
      let { data } = this.state;
      const userData = {
         user_name: '',
         user_designation: '',
         user_email: '',
         user_contact: '',
         user_location: ''
      }
      data.userList.push(userData);
      this.setState({ data })
   }

   onUserCancel = (index) => {
      const { data } = this.state;
      data.userList.splice(index, 1);
      this.setState({
         data
      })
   }

   selectedQualifications = (qualification, index) => {
      const { dataSecond } = this.state;
      dataSecond.vendorRatingQuestions[index].is_select = !qualification.is_select;
      this.setState({
         dataSecond
      })
   }
   addCustomers = () => {
      let { data } = this.state;
      const customerData = {
         customer_name: '',
         location: '',
         service_provided: '',
         product_handled: '',
         email: ''
      }
      data.customerList.push(customerData);
      this.setState({ data })
   }

   onCustomerCancel = (index) => {
      const { data } = this.state;
      data.customerList.splice(index, 1);
      this.setState({
         data
      })
   }


   addTanker = () => {
      let { dataSecond } = this.state;
      const tankerData = {
         tanker_type: '',
         tanker_size: '',
         specification: '',
         own_fleet: '',
         attached_fleet: ''
      }
      dataSecond.tankerDetails.push(tankerData);
      this.setState({ dataSecond })
   }

   checkHasValidKey = (data) => {
      if (
        data &&
        data.hasOwnProperty("vendorDefaultData") &&
        data.vendorDefaultData.hasOwnProperty("save_tax_details") &&
        data.vendorDefaultData.save_tax_details == true
      ) {
        return true;
      }
    
      return false;
    };

   handleApproval = (type, companyApp, action) => {
      const { dispatch, vendorForm, match } = this.props;
      const { approvalStatus, rating, comment, external_user_details } = this.state;

      if (window.location.pathname.includes('external-vendor-approver')) {
         if (external_user_details) {
            dispatch({
               type: ACTION.VENDORS.UPDATE_VENDOR_APPROVAL,
               params: {
                  ob_vendor_id: vendorForm.vendorDefaultData.ob_vendor_id,
                  company_id: external_user_details.company_id,
                  step: this.state.approvalStatus,
                  level: external_user_details.level || 0,
                  level_name: external_user_details.level_name || `${external_user_details.level}` || '',
                  is_approved: type,
                  //updateby: companyApp.updateby,
                  department_id: external_user_details.department_id || '',
                  branch_id: external_user_details.branch_id || '',
                  approved_by_user: external_user_details.approved_by_user || '',
                  reason_for_rejection: this.state.reason_for_rejection || null,
                  reason_for_resend: this.state.reason_for_resend || null,
                  // created_by: companyApp.created_by,
                  rating: rating || 0,
                  approver_remarks: comment
               },
               onSuccess: (() => {
                  this.setState({ isOpen: false, isRejected: false, isResend: false });
                  this.getBasicVendorDetails();
                  if (history.length > 1) {
                     history.goBack()
                  } else {
                     window.close()
                  }

               })
            })
         }
         else {
            dispatch({
               type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
               data: { type: 2, msg: "Please Upload Document" }
            })
         }
      }
      else if (window.location.pathname.includes('vendor-headoffice-approver')) {
         const user = JSON.parse(getCookie('user'))
         dispatch({
            type: ACTION.VENDORS.UPDATE_VENDOR_APPROVAL,
            params: {
               ob_vendor_id: companyApp.ob_vendor_id,
               company_id: user.company_id,
               step: this.state.approvalStatus,
               level: user.level || 0,
               level_name: user.level_name || `${user.level}` || '',
               is_approved: type,
               updateby: companyApp.updateby,
               department_id: user.department_id || '',
               branch_id: (AWL_COMPANY_IDS.includes(match.params.companyId) || CARGILL_COMPANY_IDS.includes(match.params.companyId)) ? user.branch_id : (user.branches && user.branches.length > 0 ? user.branches[0].branch_id : ''),
               approved_by_user: user.email || '',
               reason_for_rejection: this.state.reason_for_rejection || null,
               reason_for_resend: this.state.reason_for_resend || null,
               created_by: companyApp.created_by,
               rating: rating || 0,
               approver_remarks: comment,
               is_external_approval: (action == 'Yes') ? true : false
            },
            onSuccess: (() => {
               this.setState({ isOpen: false, isRejected: false, isResend: false, headApprovalModal: false });
               this.getBasicVendorDetails();
               if (history.length > 1) {
                  history.goBack()
               } else {
                  window.close()
               }
            })
         })
      }
      else {
         const user = JSON.parse(getCookie('user'))
         dispatch({
            type: ACTION.VENDORS.UPDATE_VENDOR_APPROVAL,
            params: {
               ob_vendor_id: companyApp.ob_vendor_id,
               company_id: user.company_id,
               step: this.state.approvalStatus,
               level: user.level || 0,
               level_name: user.level_name || `${user.level}` || '',
               is_approved: type,
               updateby: companyApp.updateby,
               department_id: user.department_id || '',
               branch_id: this.branchIdLogic(),
               approved_by_user: user.email || '',
               reason_for_rejection: this.state.reason_for_rejection || null,
               reason_for_resend: this.state.reason_for_resend || null,
               created_by: companyApp.created_by,
               rating: rating || 0,
               approver_remarks: comment
            },
            onSuccess: (() => {
               this.setState({ isOpen: false, isRejected: false, isResend: false, headApprovalModal: false });
               this.getBasicVendorDetails();
               if (history.length > 1) {
                  history.goBack()
               } else {
                  window.close()
               }
            })
         })
      }

      this.setState({ approvalStatus: null, isOpen: false, isRejected: false, isResend: false, headApprovalModal: false });
   }

   branchIdLogic=()=>{

      // (AWL_COMPANY_IDS.includes(match.params.companyId) || CARGILL_COMPANY_IDS.includes(match.params.companyId)) ? 
      // user.branch_id : 
      // (user.branches && user.branches.length > 0 ?
      //     user.branches[0].branch_id : 
      //     '')

      const { match } = this.props;
      const user = JSON.parse(getCookie('user'))
      
      if(AWL_COMPANY_IDS.includes(match.params.companyId)){
         return user.branch_id;
      }
      if(CARGILL_COMPANY_IDS.includes(match.params.companyId)){
         return user.branch_id;
      }
      if(SAP_DEEPAK_PHENOLICS.includes(match.params.companyId)){
         return user.branch_id;
      }
      if(user.branches && user.branches.length > 0){
         return user.branches[0].branch_id
      }
      return '';
   }

   addRegionalOffice = () => {
      let { data } = this.state;
      const regionalOfficeData = {
         regional_office_address: '',
         regional_office_city: '',
         regional_office_number: '',
         regional_office_pincode: '',
         regional_office_state: '',
         ...(CARGILL_COMPANY_IDS.includes(this.props.match.params.companyId) ? { countryName: 'India' } : {}),
      }
      data.regionalOfficeList.push(regionalOfficeData);
      this.setState({ data })
   }

   removeRegionalOffice = (index) => {
      let { data } = this.state;
      data.regionalOfficeList.splice(index, 1)
      this.setState({ data })
   }

   addBranchOffice = () => {
      let { data } = this.state;
      const branchOfficeData = {
         branch_office_address: '',
         branch_office_city: '',
         branch_office_number: '',
         branch_office_pincode: '',
         branch_office_state: ''
      }
      data.branchOfficeList.push(branchOfficeData);
      this.setState({ data })
   }

   removeBranchOffice = (index) => {
      let { data } = this.state;
      data.branchOfficeList.splice(index, 1)
      this.setState({ data })
   }

   addTurnOverField = () => {
      let { data } = this.state;
      data.turnover_details.push(
         { turnoverValue: '', turnover_uom: '', turnover_year: "" }
      )
      this.setState({ data })
   }

   removeTurnOverField = (index) => {
      let { data } = this.state;
      data.turnover_details.splice(index, 1)
      this.setState({ data })
   }

   // handleChangeForSegment = (segment, idx) => {
   //    let { dataSecond } = this.state;
   //    const isSeeker = this.props.match.params.approverType === '1';
   //    if (!isSeeker) {
   //       dataSecond.Segments[idx].is_enable = !dataSecond.Segments[idx].is_enable
   //       this.setState({ dataSecond })
   //    }
   // }
   handleChangeForSegment = (segment, idx) => {
  let { dataSecond } = this.state;
  const isSeeker = this.props.match.params.approverType === '1';

  if (!isSeeker) {
    if (segment === 'Select All') {
      // If "Select All" is checked, set all segments to enable
      const allSegmentsEnabled = dataSecond.Segments.every((segment) => segment.is_enable);
      dataSecond.Segments.forEach((seg) => {
        seg.is_enable = !allSegmentsEnabled;
      });
    } else {
      // If a specific segment is clicked, toggle its state
      dataSecond.Segments[idx].is_enable = !dataSecond.Segments[idx].is_enable;
    }
    
    this.setState({ dataSecond });
  }
}


   deleteUploadedDocument = (doc) => {
      const { dispatch, vendorForm } = this.props;
      const { data } = this.state;
      dispatch({
         type: ACTION.VENDORS.DELETE_UPLOADED_DOCUMENT_IN_VENDOR,
         params: `${data.ob_vendor_id}&&fileName=${doc.name}&&id=${doc.id}`,
         onSuccess: (() => {
            this.getBasicVendorDetails();
         })
      })
   }

   sendOTP = () => {
      const { dispatch, match } = this.props;
      const { data } = this.state;
      let payload = {
         contact_no: data.contact_no,
         company_id: match.params.companyId,
         ob_vendor_id: match.params.vendorId,
       }
      dispatch({
         type: ACTION.VENDORS.SEND_OTP_IN_VENDOR_FORM,
         data: payload,
         onSuccess: ((data) => {
            if (data) {
               this.setState({ otpInput: true })
            }
         })
      })
   }

   verifyOTP = () => {
      const { dispatch } = this.props;
      const { data, otp_number } = this.state;
      if (otp_number) {
         dispatch({
            type: ACTION.VENDORS.VERIFY_OTP_IN_VENDOR_FORM,
            data: { contact_no: data.contact_no, otp_number: otp_number },
            onSuccess: ((data) => {
               if (data.data) {
                  this.setState({ verified: data.data.verified })
               }
               this.setState({ otpInput: false, otp_number: null })
            })
         })
      } else {
         dispatch({
            type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
            data: { type: 1, msg: "Please Enter OTP!" }
         })
      }
   }

   addWHfield = () => {
      let { data } = this.state;
      const wHdata = {
         taxWHType: '',
         taxLiable: '',
         taxRec: ''
      }
      data.WH_Tax_Accounting.push(wHdata);
      this.setState({ data })
   }

   removeWHfield = (index) => {
      const { data } = this.state;
      data.WH_Tax_Accounting.splice(index, 1);
      this.setState({
         data
      })
   }

   handleSwitch = (checked) => {
      let { data } = this.state;
      const isSeeker = this.props.match.params.approverType === '1';
      if (!isSeeker) {
         data.is_gst_registered = checked;
         this.setState({ data });
      }
   };

   handleSwitchMSME = (checked) => {
      let { data } = this.state;
      const isSeeker = this.props.match.params.approverType === '1';
      if (!isSeeker) {
         data.is_msme_registered = checked;
         this.setState({ data });
      }
   };
   handleSwitchITR = (checked) => {
      let { data } = this.state;
      const isSeeker = this.props.match.params.approverType === '1';
      if (!isSeeker) {
         data.is_itr_filed = checked;
         this.setState({ data });
      }
   };
   handleSwitchTDS = (checked) => {
      let { data } = this.state;
      const isSeeker = this.props.match.params.approverType === '1';
      if (!isSeeker) {
         data.is_tds_filed = checked;
         this.setState({ data });
      }
   };

   closeBankDetailPopup = () => {
      this.setState({ isBankDetailsPopup: false })
   }

   handleRemoveBankUploadFile = (fileIndex) => {
      const { bankApproverFiles } = this.state;
      if (bankApproverFiles.length > 0) {
         bankApproverFiles.splice(fileIndex, 1);
         this.setState({ bankApproverFiles });
      }
   }

   addGstDetails = () => {
      const { data } = this.state;
      data.gstDetails.push({
         state: null,
         gstin: null,
         gst_percentage: null
      })
      this.setState({ data })
   }

   removeGstDetails = (idx) => {
      const { data } = this.state;
      data.gstDetails.splice(idx, 1)
      this.setState({ data })
   }

   handleSaveSAPDetails = () => {
      const { data } = this.state;
      const { dispatch, match } = this.props;
      const company_id = match.params.companyId;
    
      // Check if any of the mandatory fields are empty
      const mandatoryFields = [
        'vendorAcctGroup',
        'companyCode',
        'reconAccount',
        'paymentMethod',
        'purchaseOrganisation',
        'currencyOrder',
        'paymentTerms',
        'incoTerm1',
      ];
    
      const AllFieldsFilled = mandatoryFields.every(field => data[field] !== '');
    
      if (AllFieldsFilled) {
        const payload = {

         ob_vendor_id:data.ob_vendor_id,
          vendorAcctGroup: data.vendorAcctGroup,
          companyCode: data.companyCode,
          reconAccount: data.reconAccount,
          purchaseOrganisation: data.purchaseOrganisation,
          currencyOrder: data.currencyOrder,
          paymentTerms: data.paymentTerms,
          incoTerm1: data.incoTerm1,
          paymentMethod: data.paymentMethod,

        };

        dispatch({
         //  type: ACTION.VENDORS.POST_EXWORKS_DEATAILS,
         type: ACTION.VENDORS.SAVE_SAP_VENDOR_FORM,
          payload,
          
          
        });
    

      } else {
         // Set the error message directly (alternative approach)
         data.sapDetailsErrorMsg = true;
         this.setState({ data });
       }
    };
    onChangeIncoterm = (option) => {
      const { data } = this.state;
      data.incoTerm1 = option.short_name;
      
      this.setState({ data });
      
    };

    onChangepaymentTerms = (option) => {
      const { data } = this.state;
      data.paymentTerms = option.short_name;
      
      this.setState({ data });
     
    };
    
    SAPDPLdeclarationFilesMandatoryCheck=()=>{
      const {data,declarationFiles}=this.state;
      const hasGSTregistered = declarationFiles.some(doc => doc.Id === 3);
      const hasITRFiledDocument = declarationFiles.some(doc => doc.Id === 22);
      const hasMSMEregistered = declarationFiles.some(doc => doc.Id === 2);

      if(data.is_gst_registered===false && !hasGSTregistered){  //GST MANDATORY
         return false;
      }
      if(data.is_itr_filed===false && !hasITRFiledDocument){       //ITR MANDATORY
         return false;
      }
      if(data.is_msme_registered===false && !hasMSMEregistered){  //MSME MANDATORY
         return false;
      }
      return true
    }

    renderTaxCodeDetailsBlock = () => {
      const {match} = this.props
      const {isPage} = this.state
      const isDPL = DEEPAK_COMPANY_ID.includes(match.params.companyId)
      const C2 = (window.location.pathname.includes('vendor-approver'))
      const C3 = isPage == 3
      if(isDPL && C2 && C3 ) {
         return false
      }

      return false
    }

    checkIsArrayValid = (array) => {
      if(Array.isArray(array)){
         return true
      }
      return false
    }

    isSaveVisible = () => {
      const {planningOptions, taxCodeDetailsRows} = this.state
      const C1 = this.checkIsArrayValid(planningOptions)
      const C2 = this.checkIsArrayValid(taxCodeDetailsRows)
      
      if(C1 && C2){
      if(planningOptions.length == taxCodeDetailsRows.length){
         return true
      }
      return false
   }
      return false
    }

    handleApprovalProcess = () => {
      // const { vendorForm } = this.props;
    
      // if (this.renderTaxCodeDetailsBlock() && !this.checkHasValidKey(vendorForm)) {
      //   toast.error("Please Save the Tax Code Details");
      // } else {
      //   this.setState({ isOpen: true });
      // }
      this.setState({ isOpen: true });
    };
    

   render() {
      const { vendorForm, productList, match,vendorCurrencyDetails,incotermsOptions,vendorPaymentDetails } = this.props;
      const { 
         data, 
         isPage, 
         dataSecond, 
         fileNames, 
         approvalObj, 
         country_options, 
         isShowPDF,
         isDownloadPDF,
         taxCodeDetailsRows, 
         planningOptions, 
         taxCodeOptions,
         isSendToSap
       } = this.state;
      const incoterms =
      (incotermsOptions || []).map((data) => ({
        label: data.name,
        value: data.id,
        short_name: data.short_name,
      })) || [];
      const paymentMethod =
      (vendorPaymentDetails || []).map((data) => ({
        label: data.name,
        value: data.id,
        short_name: data.short_name,
      })) || [];
   
      let optionsEstDetails = [];
      const isDPL = DEEPAK_COMPANY_ID.includes(this.props.match.params.companyId)    
      let currentYear = (new Date().getFullYear());
      const userData = getCookie('user') ? JSON.parse(getCookie('user') || {}) : '';
      const userCompanyId = match.params.companyId;

      const isSeeker = this.props.match.params.approverType === '1';
      const hasCPAccess = userData && userData.isCP ? (this.props.match.params.approverType === '1' && userData.isCP) : false;
      const isVendorCP = this.props.match.params.hasCP === '1';
      const isPageSaved1 = vendorForm.vendorDefaultData.save_page_1;
      const isPageSaved2 = vendorForm.vendorDefaultData && vendorForm.vendorDefaultData.vendor_details && vendorForm.vendorDefaultData.vendor_details.save_page_2;
      const isPageSaved3 = vendorForm.vendorDefaultData.save_page_3;
      let approvalItem = [];
      if (vendorForm && vendorForm.vendorDefaultData && userData && userData.department_id) {
         const deptId = userData.department_id;
         approvalItem = (vendorForm.vendorDefaultData.approvals || []).filter((obj) => {
            return obj.department_id === deptId && obj.is_approved === 'APPROVED' && obj.approved_by_user === userData.email
         });
      }

      let totalCount = 0;
      // if (dataSecond && dataSecond.vehicle_details && dataSecond.vehicle_details.length > 0) {
      //    dataSecond.vehicle_details.map((vehicle) => {
      //       if (vehicle.count !== "")
      //          totalCount += parseInt(vehicle.count)
      //    })
      // }

      if (DEEPAK_COMPANY_ID.includes(userCompanyId)) {
         totalCount = 0
      }
      else {
         if (dataSecond && dataSecond.vehicle_details && dataSecond.vehicle_details.length > 0) {
            totalCount = parseInt(dataSecond.vehicle_details.length)
         }
         totalCount = totalCount - (((fileNames || []).filter(f => f.Id == 14).length) || 0)
            - ((vendorForm && vendorForm.vendorDefaultData && vendorForm.vendorDefaultData.documents && vendorForm.vendorDefaultData.documents.length > 0 && (vendorForm.vendorDefaultData.documents || []).filter(f => f.fileId == 14).length) || 0)
      }

      let allMandatoryFiles = [];

      if (vendorForm && vendorForm.vendorDefaultData && vendorForm.vendorDefaultData.documents && vendorForm.vendorDefaultData.documents.length > 0) {
         vendorForm.vendorDefaultData.documents.map((d) => { return allMandatoryFiles.push(parseInt(d.fileId)) })
      }

      if (fileNames) {
         fileNames.map((file) => { return allMandatoryFiles.push(file.Id) })
      }
      if (this.state.declarationFiles) {
         this.state.declarationFiles.map((file) => { return allMandatoryFiles.push(file.Id) })
      }
      return (
         <div className='main-container divToPrint'>
            <ToastContainer />
            <CloseModal
               show={this.state.showModal}
               message="Data Submitted Successfully"
               onClose={this.handleClose}
            />
            <div className="boarding-form" id="header-panel-top-panel">
               <div className="header">

                  <div>
                     <TopPanel newVendor/>
                  </div>
               </div>

               <div className='heading' id="vendoron">
               {!isDownloadPDF && isShowPDF && <img src={jpglogo} width="300" height="100" />}


            
                  <div className="header-title">Vendor Onboarding Form</div>
                  <div className="form-status">
                     <div className="progress-status-bar" data-html2canvas-ignore >
                        <div className="progressbar-container">
                           <ul className="progressbar">

                              <li className={(isPage === 1 || isPage === 2 || isPage === 3) ? "active" : ''}>
                                 <p style={(isPage === 1 || isPage === 2 || isPage === 3) ? { textAlign: 'center', color: 'blue' } : { textAlign: 'center', color: 'black' }}>About company</p>
                              </li>

                              <li className={(isPage === 2 || isPage === 3) ? "active" : ''}>  <p style={(isPage === 2 || isPage === 3) ? { textAlign: 'center', color: 'blue' } : { textAlign: 'center', color: 'black' }}>About Service</p></li>
                              <li className={(isPage === 3) ? "active" : ''}> <p style={(isPage === 3) ? { textAlign: 'center', color: 'blue' } : { textAlign: 'center', color: 'black' }}>Document</p></li>

                           </ul>
                        </div>
                     </div>
                  </div>
               </div>

               {this.props.loader.isLoading && <Loader />}
               {this.props.msgpopup.active && <MSGPopUp close={this.closeMsgPopup} type={this.props.msgpopup.type} msg={this.props.msgpopup.msg} />}

               {(isPage === 1 || isShowPDF) &&
                  <div className="form-page form-page-1 " id="form-page-1">
                     <div className='first-table-company'>
                        <div className="Table-header">
                           
                           
                           <div className="table-heading">Company Name & Logo</div>

                           <div className='first-table-body-company'>
                              <div className="black mb-20 wt-100p">
                                 <div className="black">Company name (Vendor Name/ Transporter/ LSP)<span className='error'>*</span></div>
                                
                                 {<input
                                    type="text"
                                    placeholder="Company name "
                                    className="ala-017"
                                    value={data.vendor_company_name}
                                    onChange={(e) => {
                                       const { data } = this.state;
                                       data.vendor_company_name = e.target.value;
                                       this.setState({ data });
                                    }}
                                 />}
                              </div>

                              <div className="upload-img">
                                 {data.company_logo && data.company_logo.length > 0 && <div className="icon"><a href={data.company_logo} target='_blank'><img className="wt-150 ht-150" src={data.company_logo} /></a></div>}
                                 {!isSeeker && <button className="button-classic" onClick={() => { this.uploadImg.click() }}> + Upload Company Logo </button>}
                                 <input type='file' style={{ display: 'none' }} ref={(e) => { this.uploadImg = e; }} onChange={this.uploadVendorImage} onClick={(e) => { e.target.value = null }} />
                              </div>
                           </div>
                        </div>
                     </div>

                     <div className='first-table-company'>
                        <div className="Table-header">
                           <div className="table-heading">Contact Details</div>
                           <div className='second-table-body-company'>
                              <div className="black mb-20 wt-100p">
                                 <div className="black">Contact Person Name<span className='error'>*</span></div>
                                 {<input
                                    type="text"
                                    placeholder="Contact Person Name"
                                    className="ala-017"
                                    value={data.contact_person_name}
                                    disabled
                                 />}
                              </div>
                              <div className="black mb-20 wt-100p">
                                 <div className="black">Email ID<span className='error'>*</span></div>
                                 {<input
                                    type="text"
                                    placeholder="email"
                                    className="ala-017"
                                    value={data.email}
                                    disabled
                                 />}
                              </div>

                              {CARGILL_COMPANY_IDS.includes(userCompanyId) &&
                                 <div className="black mb-20 wt-100p">
                                    <div className="black">Standard Communication Mtd.</div>
                                    {!isSeeker ?
                                       <input
                                          type="text"
                                          placeholder="Standard Communication Mtd"
                                          className="ala-017"
                                          value={data.stdCommunicationMethod}
                                          onChange={(e) => {
                                             const { data } = this.state;
                                             data.stdCommunicationMethod = e.target.value;
                                             this.setState({ data });
                                          }}
                                       /> :
                                       <div className='contact-details-vendor-input'>{data.stdCommunicationMethod || ''}</div>}
                                 </div>}

                              <div className="dInBlock wt-100p">
                              <div className="black">Contact Number <span className='error'>*</span></div>
                                 <div className='flex wt-100p'>
                                    {
                                       <div className='wt-100p' style={{ display: 'flex', flexDirection: "column" }}>
                                          <input
                                             type="text"
                                             placeholder="contact no"
                                             className="ala-017"
                                             value={data.contact_no}
                                             onChange={(e) => {
                                                const { data } = this.state;
                                                if (e.target.value.length <= 10) {
                                                   if (e.target.value != 0) {
                                                      const regEx = /^[+]?([1-9][0-9]*(?:[\.][0-9]*)?|0*\.0*[1-9][0-9]*)(?:[eE][+-][0-9]+)?$/;
                                                      const check = e.target.value.match(regEx);
                                                      if (!check) {
                                                         return
                                                      }
                                                   }
                                                   data.contact_no = e.target.value;
                                                }
                                                this.setState({ data });
                                             }}
                                          />

                                          {this.state.otpInput &&
                                             <input
                                                type="text"
                                                placeholder="OTP"
                                                className="contact-details-vendor-input mt-5"
                                                value={this.state.otp_number}
                                                onChange={(e) => {
                                                   if (e.target.value.length <= 6) {
                                                      if (e.target.value != 0) {
                                                         const regEx = /^[+]?([1-9][0-9]*(?:[\.][0-9]*)?|0*\.0*[1-9][0-9]*)(?:[eE][+-][0-9]+)?$/;
                                                         const check = e.target.value.match(regEx);
                                                         if (!check) {
                                                            return
                                                         }
                                                      }
                                                      this.setState({ otp_number: e.target.value });
                                                   }
                                                }}
                                             />
                                          }
                                       </div>
                                    }

                                    {
                                       !isSeeker && !window.location.pathname.includes('vendor-read') &&
                                       <div style={{ display: 'flex', flexDirection: 'column' }}>
                                          {this.state.otpInput ?
                                             <button className="button-classic" style={{ width: '150px' }} onClick={() => this.sendOTP()}>Resend SMS</button>
                                             :
                                             <button className="button-classic" style={{ width: '150px' }} onClick={() => this.sendOTP()}>Send OTP</button>
                                          }

                                          {
                                             !isSeeker && !window.location.pathname.includes('vendor-read') && this.state.otpInput &&
                                             <button className="button-classic" style={{ width: '150px', marginTop: '10px' }} onClick={() => this.verifyOTP()}>Verify OTP</button>
                                          }
                                       </div>
                                    }

                                 </div>
                              </div>
                           </div>

                           {(DEEPAK_COMPANY_ID.includes(userCompanyId)||ABFRL_COMPANY_IDS.includes(userCompanyId)) &&
                              <div className='second-table-body-company' style={{ marginTop: '-20px' }}>
                                 <div className="black mb-20 wt-100p">
                                    <div className="black">Contact Person Address<span className='error'>*</span></div>
                                    {!isSeeker ?
                                       <input
                                          type="text"
                                          placeholder="Contact Person Address"
                                          className="contact-details-vendor-input"
                                          value={data.contact_person_location}
                                          onChange={(e) => {
                                             const { data } = this.state;
                                             data.contact_person_location = e.target.value;
                                             this.setState({ data });
                                          }}
                                       /> :
                                       <div className='contact-details-vendor-input'>{data.contact_person_location || '-'}</div>}
                                 </div>
                              </div>
                           }

                        </div>
                     </div>

                     {(DEEPAK_COMPANY_ID.includes(userCompanyId) || AWL_COMPANY_IDS.includes(userCompanyId) || CARGILL_COMPANY_IDS.includes(userCompanyId) || ABFRL_COMPANY_IDS.includes(userCompanyId) ) &&
                        <Fragment>
                           <div className='company-details-table'>
                              <div className="Table-header">
                                 <div className="table-heading">Company Details</div>
                                 <div className='company-details-body'>

                                    <div className='company-details-row'>
                                       <div className="dInBlock wt-100p">
                                          <div className="black">Company Type<span className='error'>*</span></div>
                                          {!isSeeker ?
                                             <SelectT1
                                                value={(this.state.companyType || []).filter(obj => { return data.company_type === obj.label })} //{label: transporter, value: transporter}}
                                                options={(this.state.companyType || [])}
                                                onChange={(value) => {
                                                   const { data } = this.state;
                                                   data.company_type = value.label;
                                                   this.setState({ data });
                                                }}
                                                placeholder={'Type'}
                                                className='company-details-vendor-select'

                                             />
                                             : <div className="company-details-vendor-input">{data.company_type || '-'}</div>}
                                       </div>
                                       <div className="dInBlock wt-100p">
                                          <div className="black">Company Website</div>
                                          {!isSeeker ? <input
                                             type="text"
                                             placeholder="Company Website"
                                             className="company-details-vendor-input"
                                             value={data.company_website}
                                             onChange={(e) => {
                                                const { data } = this.state;
                                                data.company_website = e.target.value;
                                                this.setState({ data });
                                             }}
                                          /> : <div className="company-details-vendor-input">{data.company_website || '-'}</div>}
                                       </div>
                                       <div className="dInBlock wt-100p">
                                          <div className="black">Pan No<span className='error'>*</span></div>
                                          {!isSeeker ? <input
                                             className="company-details-vendor-input"
                                             type="text"
                                             placeholder="pan"
                                             value={data.pan_no}
                                             onChange={(e) => {
                                                const { data } = this.state;
                                                const regex = /^([a-zA-Z]{5})([0-9]{4})([a-zA-Z]{1})$/;
                                                if (e.target.value.length <= 10) {
                                                   if (regex.test(e.target.value) || e.target.value.length == 0) {
                                                      data.pan_no = e.target.value.replace(/[&\/\\#,+()$~%.@!^&()-/'":*?<>{}]/g, '').toUpperCase();
                                                      this.setState({ panError: '' })
                                                   }
                                                   else {
                                                      data.pan_no = e.target.value.replace(/[&\/\\#,+()$~%.@!^&()-/'":*?<>{}]/g, '').toUpperCase();
                                                      this.setState({ panError: 'Invalid PAN No.' })
                                                   }
                                                }
                                                this.setState({ data });
                                             }}
                                          /> : <div className="company-details-vendor-input">{data.pan_no || '-'}</div>}
                                          {this.state.panError && <p style={{ color: 'red', fontSize: '13px' }}>{this.state.panError}</p>}
                                       </div>
                                       <div className="dInBlock wt-100p">
                                          <div className='dropdown'>
                                             <div className="black">Year of Registration<span className='error'>*</span></div>
                                             {!isSeeker ?
                                                <SelectT1
                                                   value={this.state.years.filter((obj) => data.registration_year === obj.label)} //{label: transporter, value: transporter}}
                                                   options={this.state.years}
                                                   onChange={(value) => {
                                                      const { data, years } = this.state;
                                                      data.registration_year = value.label;
                                                      const temp = years.filter((yr) => yr.label >= value.label)
                                                      this.setState({ data, previous_years: temp });
                                                   }}
                                                   // placeholder={}
                                                   className="company-details-vendor-select"

                                                /> : <div className="company-details-vendor-input">{data.registration_year || '-'}</div>}
                                          </div>
                                       </div>

                                    </div>

                                    <div className='company-details-row'>
                                       <div className="dInBlock wt-100p">
                                          <div className="black">Additional Email</div>
                                          {!isSeeker ? <input
                                             type="text"
                                             placeholder="Additional Email"
                                             className="company-details-vendor-input"
                                             value={data.additional_email}
                                             onChange={(e) => {
                                                const { data } = this.state;
                                                let re = /\S+@\S+\.\S+/;
                                                let regex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
                                                if (re.test(e.target.value) || regex.test(e.target.value) || e.target.value.length === 0) {
                                                   data.additional_email = [e.target.value];
                                                   delete this.state.additional_email_error
                                                   this.setState({ data });
                                                } else {
                                                   data.additional_email = [e.target.value];
                                                   this.setState({ additional_email_error: 'Invalid Email' })
                                                   this.setState({ data });
                                                }
                                             }}
                                          /> : <div className="company-details-vendor-input" style={{ height: 'max-content' }}>{data.additional_email ? data.additional_email : '-'}</div>}
                                          {this.state.additional_email_error && <p style={{ color: 'red', fontSize: '13px' }}>{this.state.additional_email_error}</p>}
                                       </div>

                                       <div className="dInBlock wt-100p">
                                          <div className="black">{(CARGILL_COMPANY_IDS.includes(userCompanyId) ||ABFRL_COMPANY_IDS.includes(userCompanyId)) ? 'FAX' : 'Contact No.'}</div>
                                          {!isSeeker ? <input
                                             type="text"
                                             placeholder={(CARGILL_COMPANY_IDS.includes(userCompanyId) || ABFRL_COMPANY_IDS.includes(userCompanyId)) ? 'FAX' : "contact no"}
                                             className="company-details-vendor-input"
                                             value={data.company_contact_no}
                                             onChange={(e) => {
                                                const { data } = this.state;
                                                if (e.target.value.length <= 10) {
                                                   if (e.target.value != 0) {
                                                      const regEx = /^[+]?([1-9][0-9]*(?:[\.][0-9]*)?|0*\.0*[1-9][0-9]*)(?:[eE][+-][0-9]+)?$/;
                                                      const check = e.target.value.match(regEx);
                                                      if (!check) {
                                                         return
                                                      }
                                                   }
                                                   data.company_contact_no = e.target.value;
                                                }
                                                this.setState({ data });
                                             }}
                                          /> : <div className="company-details-vendor-input">{data.company_contact_no || '-'}</div>}
                                       </div>
                                       <div className="dInBlock wt-100p">
                                          <div className="black">Payment Currency<span className='error'>*</span></div>
                                          {!isSeeker ? <SelectT1
                                             value={[{ value: 1, label: 'INR' }, { value: 2, label: 'USD' }, { value: 3, label: 'Euro' }].filter((obj) => data.currency === obj.label)} //{label: transporter, value: transporter}}
                                             options={[{ value: 1, label: 'INR' }, { value: 2, label: 'USD' }, { value: 3, label: 'Euro' }]}
                                             onChange={(value) => {
                                                const { data } = this.state;
                                                data.currency = value.label;
                                                this.setState({ data });
                                             }}
                                             placeholder={'Currency'}
                                             className='company-details-vendor-select'
                                          /> : <div className="company-details-vendor-input">{data.currency || '-'}</div>}

                                       </div>

                                       {(CARGILL_COMPANY_IDS.includes(userCompanyId) ? true : (data.company_type == 'Proprietorship')) ?
                                          <div className="dInBlock wt-100p">
                                             <div className="black">Aadhar Card No.<span className='error'>*</span></div>
                                             {!isSeeker ? <input
                                                type="text"
                                                placeholder="aadhar card no"
                                                className="company-details-vendor-input"
                                                value={data.aadhar_card_no}
                                                onChange={(e) => {
                                                   const { data } = this.state;
                                                   if (e.target.value.length <= 12) {
                                                      if (e.target.value != 0) {
                                                         const regEx = /^[+]?([1-9][0-9]*(?:[\.][0-9]*)?|0*\.0*[1-9][0-9]*)(?:[eE][+-][0-9]+)?$/;
                                                         const check = e.target.value.match(regEx);
                                                         if (!check) {
                                                            return
                                                         }
                                                      }
                                                      data.aadhar_card_no = e.target.value;
                                                   }
                                                   this.setState({ data });
                                                }}
                                             /> : <div className="company-details-vendor-input">{data.aadhar_card_no || '-'}</div>}
                                          </div>
                                          :
                                          <div className="dInBlock wt-100p"></div>
                                       }
                                    </div>

                                    {DEEPAK_COMPANY_ID.includes(userCompanyId) &&
                                       <div className='company-details-row'>
                                          <div className="dInBlock wt-25p">
                                             <div className="black">Registration No.</div>
                                             {!isSeeker ? <input
                                                type="text"
                                                placeholder="Registration No."
                                                className="company-details-vendor-input"
                                                value={data.cin_number}
                                                onChange={(e) => {
                                                   const { data } = this.state;
                                                   data.cin_number = e.target.value.replace(/[&\/\\#,+()$~%.@!^&()-/'":*?<>{}]/g, '');
                                                   this.setState({ data });
                                                }}
                                             /> : <div className="company-details-vendor-input">{data.cin_number || '-'}</div>}
                                          </div>
                                       </div>
                                    }

                                    {CARGILL_COMPANY_IDS.includes(userCompanyId) &&
                                       <div className='company-details-row'>
                                          <div className="dInBlock black wt-100p singleDatePickerVendor">
                                             <div className="black">Date<span className='error'>*</span></div>
                                             {!isSeeker ?
                                                <SingleDatePicker
                                                   noBorder={true}
                                                   displayFormat={"DD/MM/YYYY"}
                                                   hideKeyboardShortcutsPanel={true}
                                                   placeholder={"Date"}
                                                   numberOfMonths={1}
                                                   date={data.dateRequested && moment(data.dateRequested) || null}
                                                   onDateChange={(from) => {
                                                      const { data } = this.state;
                                                      data.dateRequested = moment(from).valueOf() || null;
                                                      this.setState({ data });
                                                   }}
                                                   focused={this.state.focusedStart}
                                                   onFocusChange={({ focused }) => this.setState({ focusedStart: focused })}
                                                /> : <div className="company-details-vendor-input">{data.dateRequested || '-'}</div>
                                             }
                                          </div>

                                          <div className="dInBlock wt-100p">
                                             <div className='dropdown'>
                                                <div className="black">Action Required<span className='error'>*</span></div>
                                                {!isSeeker ?
                                                   <Select
                                                      value={this.state.action_required_options.filter((obj) => data.actionRequired === obj.label)}
                                                      options={this.state.action_required_options}
                                                      onChange={(value) => {
                                                         const { data } = this.state;
                                                         data.actionRequired = value.label;
                                                         this.setState({ data });
                                                      }}
                                                      placeholder={"Action Required"}
                                                      className="company-details-vendor-select"

                                                   /> : <div className="company-details-vendor-input">{data.actionRequired || '-'}</div>}
                                             </div>
                                          </div>
                                          <div className="dInBlock wt-100p">
                                             <div className='dropdown'>
                                                <div className="black">Type of Address Book<span className='error'>*</span></div>
                                                {!isSeeker ?
                                                   <Select
                                                      value={[{ label: 'V_VENDOR', value: 'V_VENDOR' }, { label: 'E_EMPLOYEE', value: 'E_EMPLOYEE' }].filter((obj) => data.addressBookType === obj.label)}
                                                      options={[{ label: 'V_VENDOR', value: 'V_VENDOR' }, { label: 'E_EMPLOYEE', value: 'E_EMPLOYEE' }]}
                                                      onChange={(value) => {
                                                         const { data } = this.state;
                                                         data.addressBookType = value.label;
                                                         this.setState({ data });
                                                      }}
                                                      placeholder={'Type of Address Book'}
                                                      className="company-details-vendor-select"

                                                   /> : <div className="company-details-vendor-input">{data.addressBookType || '-'}</div>}
                                             </div>
                                          </div>

                                          <div className="dInBlock wt-100p">
                                             <div className='dropdown'>
                                                <div className="black">With/h Tax Accounting</div>
                                                {!isSeeker ?
                                                   <Select
                                                      value={[{ label: 'YES', value: 'YES' }, { label: 'NO', value: 'NO' }, { label: 'LOWER WITH HOLDING TAX', value: 'LOWER WITH HOLDING TAX' }].filter((obj) => data.withholdTax === obj.label)}
                                                      options={[{ label: 'YES', value: 'YES' }, { label: 'NO', value: 'NO' }, { label: 'LOWER WITH HOLDING TAX', value: 'LOWER WITH HOLDING TAX' }]}
                                                      onChange={(value) => {
                                                         const { data } = this.state;
                                                         data.withholdTax = value.label;
                                                         this.setState({ data });
                                                      }}
                                                      placeholder={'With/h Tax Accounting'}
                                                      className="company-details-vendor-select"

                                                   /> : <div className="company-details-vendor-input">{data.withholdTax || '-'}</div>}
                                             </div>
                                          </div>
                                       </div>
                                    }

                                    {CARGILL_COMPANY_IDS.includes(userCompanyId) &&
                                       <div className='company-details-row'>
                                          <div className="dInBlock wt-100p">
                                             <div className='dropdown'>
                                                <div className="black">Tax Return Status For 2 Year</div>
                                                {!isSeeker ?
                                                   <Select
                                                      value={[{ label: 'YES', value: 'YES' }, { label: 'NO', value: 'NO' }].filter((obj) => data.taxRetunStatusFor2years === obj.label)}
                                                      options={[{ label: 'YES', value: 'YES' }, { label: 'NO', value: 'NO' }]}
                                                      onChange={(value) => {
                                                         const { data } = this.state;
                                                         data.taxRetunStatusFor2years = value.label;
                                                         this.setState({ data });
                                                      }}
                                                      placeholder={'Tax Return Status For 2 Year'}
                                                      className="company-details-vendor-select"

                                                   /> : <div className="company-details-vendor-input">{data.taxRetunStatusFor2years || '-'}</div>}
                                             </div>
                                          </div>
                                          <div className="dInBlock wt-100p">
                                             <div className='dropdown'>
                                                <div className="black">Pan & Aadhar Linkage<span className='error'>*</span></div>
                                                {!isSeeker ?
                                                   <Select
                                                      value={[{ label: 'YES', value: 'YES' }, { label: 'NO', value: 'NO' }].filter((obj) => data.panAadharLinkage === obj.label)}
                                                      options={[{ label: 'YES', value: 'YES' }, { label: 'NO', value: 'NO' }]}
                                                      onChange={(value) => {
                                                         const { data } = this.state;
                                                         data.panAadharLinkage = value.label;
                                                         this.setState({ data });
                                                      }}
                                                      placeholder={'Pan & Aadhar Linkage'}
                                                      className="company-details-vendor-select"

                                                   /> : <div className="company-details-vendor-input">{data.panAadharLinkage || '-'}</div>}
                                             </div>
                                          </div>

                                          <div className="dInBlock wt-100p">
                                             <div className='dropdown'>
                                                <div className="black">Supplier is specified Person<span className='error'>*</span></div>
                                                {!isSeeker ?
                                                   <Select
                                                      value={[{ label: 'YES', value: 'YES' }, { label: 'NO', value: 'NO' }].filter((obj) => data.supplierPerson === obj.label)}
                                                      options={[{ label: 'YES', value: 'YES' }, { label: 'NO', value: 'NO' }]}
                                                      onChange={(value) => {
                                                         const { data } = this.state;
                                                         data.supplierPerson = value.label;
                                                         this.setState({ data });
                                                      }}
                                                      placeholder={'Supplier is specified Person'}
                                                      className="company-details-vendor-select"

                                                   /> : <div className="company-details-vendor-input">{data.supplierPerson || '-'}</div>}
                                             </div>
                                          </div>

                                          <div className="dInBlock wt-100p">
                                             <div className="black">Status(Public/Private)<span className='error'>*</span></div>
                                             {!isSeeker ?
                                                <Select
                                                   value={[{ label: 'Public', value: 'Public' }, { label: 'Private', value: 'Private' }].filter((obj) => data.vendorStatus === obj.label)}
                                                   options={[{ label: 'Public', value: 'Public' }, { label: 'Private', value: 'Private' }]}
                                                   onChange={(value) => {
                                                      const { data } = this.state;
                                                      data.vendorStatus = value.label;
                                                      this.setState({ data });
                                                   }}
                                                   placeholder={'Status'}
                                                   className="company-details-vendor-select"

                                                /> : <div className="company-details-vendor-input">{data.supplierPerson || '-'}</div>}
                                          </div>
                                       </div>
                                    }

                                    {CARGILL_COMPANY_IDS.includes(userCompanyId) &&
                                       <div className='company-details-row'>
                                          <div className="dInBlock wt-25p">
                                             <div className="black">Search Term</div>
                                             {!isSeeker ? <input
                                                type="text"
                                                placeholder="search term"
                                                className="company-details-vendor-input"
                                                value={data.searchTerm}
                                                onChange={(e) => {
                                                   const { data } = this.state;
                                                   data.searchTerm = e.target.value;
                                                   this.setState({ data });
                                                }}
                                             /> : <div className="company-details-vendor-input">{data.searchTerm || '-'}</div>}
                                          </div>
                                       </div>
                                    }

                                    <p className='black mt-20' style={{ fontSize: '20px' }}>Previous Year Turnovers</p>

                                    <div className='turn-over-details'>
                                       {(data.turnover_details || []).map((detail, index) => {
                                          return (
                                             <div style={{ display: 'flex' }}>
                                                <div className='turn-over-details-row'>
                                                   <div className="dInBlock wt-100p">
                                                      <div className='dropdown'>
                                                         <div className="black">Year<span className='error'>*</span></div>
                                                         {!isSeeker ? <SelectT1
                                                         value={this.state.previous_years.filter((obj) => detail.turnover_year === obj.value)} //{label: transporter, value: transporter}}
                                                            options={this.state.previous_years.filter((obj) => !data.turnover_details.find((d) => d.turnover_year === obj.value))}
                                                            onChange={(value) => {
                                                               const { data } = this.state;
                                                               data.turnover_details[index].turnover_year = value.value;
                                                               this.setState({ data });
                                                            }}
                                                            // placeholder={}
                                                            className='turn-vendor-select'

                                                         /> : <div className="turn-vendor-input">{data.turnover_details[index].turnover_year || '-'}</div>}
                                                      </div>
                                                   </div>

                                                   <div className="dInBlock wt-100p">
                                                      <div className="black">Amount<span className='error'>*</span></div>
                                                      {!isSeeker ? <input
                                                         type="text"
                                                         placeholder={'Turnover '}
                                                         className="turn-vendor-input"
                                                         value={detail.turnoverValue}
                                                         onChange={(e) => {
                                                            const { data } = this.state;
                                                            if (e.target.value != 0) {
                                                               const regEx = /^[+]?([1-9][0-9]*(?:[\.][0-9]*)?|0*\.0*[1-9][0-9]*)(?:[eE][+-][0-9]+)?$/;
                                                               const check = e.target.value.match(regEx);
                                                               if (!check) {
                                                                  return
                                                               }
                                                            }
                                                            data.turnover_details[index].turnoverValue = e.target.value;
                                                            this.setState({ data });
                                                         }}
                                                      /> : <div className="turn-vendor-input">{data.turnover_details[index].turnoverValue || '-'}</div>}
                                                   </div>

                                                   <div className="dInBlock wt-100p mt-18">
                                                      <div className='dropdown' >
                                                         {/* <div className="label grey">UOM</div> */}
                                                         {!isSeeker ? <SelectT1
                                                            value={[{ value: 1, label: 'Lakhs' }, { value: 2, label: 'Crores' }].filter((obj) => detail.turnover_uom === obj.label)} //{label: transporter, value: transporter}}
                                                            options={[{ value: 1, label: 'Lakhs' }, { value: 2, label: 'Crores' }]}
                                                            onChange={(value) => {
                                                               const { data } = this.state;
                                                               data.turnover_details[index].turnover_uom = value.label;
                                                               this.setState({ data });
                                                            }}
                                                            placeholder={'UOM'}
                                                            className='turn-vendor-select'
                                                         /> : <div className="turn-vendor-input">{data.turnover_details[index].turnover_uom || '-'}</div>}
                                                      </div>
                                                   </div>
                                                </div>

                                                {!isSeeker &&
                                                   <div style={{ marginTop: '35px' }}>
                                                      <img src={addBtn} style={{ width: '20px', cursor: 'pointer' }} onClick={() => this.addTurnOverField()} />
                                                      {index !== 0 && <img src={cancelBtn} style={{ width: '20px', marginLeft: '10px', cursor: 'pointer' }} onClick={() => this.removeTurnOverField(index)} />}
                                                   </div>
                                                }
                                             </div>
                                          )
                                       })}
                                    </div>
                                 </div>
                              </div>
                           </div>

                           <div className="vendor-type-table">
                              <div className="vendor-type-header">
                                 <div className="vendor-type-heading">Vendor Type{(AWL_COMPANY_IDS.includes(this.props.match.params.companyId) || CARGILL_COMPANY_IDS.includes(userCompanyId)) && <span className='error'>*</span>}</div>
                                 <div className='vendor-table-body'>
                                    {(this.state.vendorType || []).map((vendor, idx) => {
                                       return (
                                          <div style={{ display: 'flex', width: '25%', marginTop: '10px' }}>
                                             <CustomeCheckBox
                                                id={vendor}
                                                toggle={() => this.handleChangeForVendorType(vendor)}
                                                selected={(this.state.data.vendor_type || []).includes(vendor.value)}
                                             />
                                             {vendor.label}
                                          </div>
                                       )
                                    })}
                                 </div>
                              </div>
                           </div>

                           <div className='user-details'>
                              <div className="user-table-header">
                                 <div className="user-table-heading">Admin Details</div>
                                 {(data.userList || []).map((users, index) => {
                                    return (
                                       <Fragment>
                                          <div className='user-table-body'>
                                             <div className='user-left-row'>
                                                <div className="dInBlock wt-100p">
                                                   <div className="black">{index === 0 ? 'Admin Name' : 'User Name'}{index === 0 && <span className='error'>*</span>}</div>
                                                   {!isSeeker ? <input
                                                      type="text"
                                                      placeholder={index === 0 ? "Admin Name" : "User Name"}
                                                      className="user-new-vendor-input"
                                                      value={users.admin_name ? users.admin_name : users.user_name}
                                                      onChange={(e) => {
                                                         const { data } = this.state;
                                                         if (index === 0) {
                                                            data.userList[index].admin_name = e.target.value.replace(/[&\/\\#,+()$~%.@!^&()-/'":*?<>{}0-9]/g, '');
                                                         } else {
                                                            data.userList[index].user_name = e.target.value.replace(/[&\/\\#,+()$~%.@!^&()-/'":*?<>{}0-9]/g, '');
                                                         }
                                                         this.setState({ data });
                                                      }}
                                                   /> : <div className="user-new-vendor-input" style={{ height: 'max-content' }}>{users.admin_name ? users.admin_name : (users.user_name || '-')}</div>}
                                                </div>
                                                <div className="dInBlock wt-100p" style={{ marginLeft: '10px' }}>
                                                   <div className="black">Designation{index === 0 && <span className='error'>*</span>}</div>
                                                   {!isSeeker ?
                                                      <SelectT1
                                                         value={(this.state.designationOptions || []).filter(obj => { if (users.admin_designation) { return users.admin_designation === obj.label } else { return users.user_designation === obj.label } })} //{label: transporter, value: transporter}}
                                                         options={(this.state.designationOptions || [])}
                                                         onChange={(value) => {
                                                            const { data } = this.state;
                                                            if (index === 0) {
                                                               data.userList[index].admin_designation = value.label;
                                                               if (value.label == 'Owner') {
                                                                  data.userList[index].admin_department = 'Not Applicable'
                                                               }
                                                            } else {
                                                               data.userList[index].user_designation = value.label;
                                                               if (value.label == 'Owner') {
                                                                  data.userList[index].user_department = 'Not Applicable'
                                                               }
                                                            }
                                                            this.setState({ data });
                                                         }}
                                                         placeholder={'Designation'}
                                                         className='user-details-vendor-select'
                                                         style={{ height: '50px' }}
                                                      />
                                                      : <div className="user-new-vendor-input">{users.admin_designation ? users.admin_designation : (users.user_designation || '-')}</div>}
                                                </div>
                                                <div className="dInBlock wt-100p" style={{ marginLeft: '10px' }}>
                                                   <div className="black">Department{index === 0 && <span className='error'>*</span>}</div>
                                                   {!isSeeker ?
                                                      <SelectT1
                                                         value={(this.state.departmentOptions || []).filter(obj => { if (users.admin_department) { return users.admin_department === obj.label } else { return users.user_department === obj.label } })} //{label: transporter, value: transporter}}
                                                         options={(this.state.departmentOptions || [])}
                                                         onChange={(value) => {
                                                            const { data } = this.state;
                                                            if (index === 0) {
                                                               data.userList[index].admin_department = value.label;
                                                            } else {
                                                               data.userList[index].user_department = value.label;
                                                            }
                                                            this.setState({ data });
                                                         }}
                                                         placeholder={'Department'}
                                                         className='user-details-vendor-select'
                                                         style={{ height: '50px' }}
                                                      />
                                                      : <div className="user-new-vendor-input">{users.admin_department ? users.admin_department : (users.user_department || '-')}</div>}
                                                </div>
                                                <div className="dInBlock wt-100p" style={{ marginLeft: '10px' }}>
                                                   <div className="black">Location{index === 0 && <span className='error'>*</span>}</div>
                                                   {!isSeeker ? <input
                                                      type="text"
                                                      placeholder="Location"
                                                      className="user-new-vendor-input"
                                                      value={users.admin_location ? users.admin_location : users.user_location}
                                                      onChange={(e) => {
                                                         const { data } = this.state;
                                                         if (index === 0) {
                                                            data.userList[index].admin_location = e.target.value;
                                                         } else {
                                                            data.userList[index].user_location = e.target.value;
                                                         }
                                                         this.setState({ data });
                                                      }}
                                                   /> : <div className="user-new-vendor-input" style={{ height: 'max-content' }}>{users.admin_location ? users.admin_location : (users.user_location || '-')}</div>}
                                                </div>
                                                <div className="dInBlock wt-100p" style={{ marginLeft: '10px' }}>
                                                   <div className="black">Email{index === 0 && <span className='error'>*</span>}</div>
                                                   {!isSeeker ? <input
                                                      type="text"
                                                      placeholder="Email"
                                                      className="user-new-vendor-input"
                                                      value={users.admin_email ? users.admin_email : users.user_email}
                                                      onChange={(e) => {
                                                         const { data } = this.state;
                                                         if (index === 0) {
                                                            let re = /\S+@\S+\.\S+/;
                                                            let regex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
                                                            if (re.test(e.target.value) || regex.test(e.target.value) || e.target.value.length === 0) {
                                                               data.userList[index].admin_email = e.target.value;
                                                               delete data.userList[index].error
                                                               this.setState({ data });
                                                            } else {
                                                               data.userList[index].admin_email = e.target.value;
                                                               data.userList[index].error = 'Invalid email'
                                                               this.setState({ data });
                                                            }
                                                         } else {
                                                            let re = /\S+@\S+\.\S+/;
                                                            let regex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
                                                            if (re.test(e.target.value) || regex.test(e.target.value) || e.target.value.length === 0) {
                                                               data.userList[index].user_email = e.target.value;
                                                               delete data.userList[index].error
                                                               this.setState({ data });
                                                            } else {
                                                               data.userList[index].user_email = e.target.value;
                                                               data.userList[index].error = 'Invalid email'
                                                               this.setState({ data });
                                                            }
                                                         }
                                                         this.setState({ data });
                                                      }}
                                                   /> : <div className="user-new-vendor-input" style={{ height: 'max-content' }}>{users.admin_email ? users.admin_email : (users.user_email || '-')}</div>}
                                                   {users.error && <p style={{ color: 'red', fontSize: '13px' }}>{users.error}</p>}
                                                </div>
                                                <div className="dInBlock wt-100p" style={{ marginLeft: '10px' }}>
                                                   <div className="black">Contact{index === 0 && <span className='error'>*</span>}</div>
                                                   {!isSeeker ? <input
                                                      type="text"
                                                      placeholder="Contact"
                                                      className="user-new-vendor-input"
                                                      value={index === 0 ? users.admin_contact : users.user_contact}
                                                      onChange={(e) => {
                                                         const { data } = this.state;
                                                         if (e.target.value.length <= 10) {
                                                            if (e.target.value != 0) {
                                                               const regEx = /^[+]?([1-9][0-9]*(?:[\.][0-9]*)?|0*\.0*[1-9][0-9]*)(?:[eE][+-][0-9]+)?$/;
                                                               const check = e.target.value.match(regEx);
                                                               if (!check) {
                                                                  return
                                                               }
                                                            }
                                                            data.userList[index] = {
                                                               ...data.userList[index],
                                                               ...(index === 0 ? { admin_contact: e.target.value } : { user_contact: e.target.value })
                                                            }
                                                         }
                                                         this.setState({ data });
                                                      }}
                                                   /> : <div className="user-new-vendor-input">{users.admin_contact ? users.admin_contact : (users.user_contact || '-')}</div>}
                                                </div>
                                             </div>

                                             <div style={{ display: 'flex', width: '10%', marginTop: '12px' }}>
                                                {(index + 1) === data.userList.length ?
                                                   <div className="mb-10 mt-10 fRight">
                                                      {!isSeeker && index !== 0 && <img src={cancelBtn} style={{ width: '20px', cursor: 'pointer' }} onClick={() => this.onUserCancel(index)} />}
                                                      {!isSeeker && <img src={addBtn} style={{ width: '20px', cursor: 'pointer', marginLeft: '10px' }} onClick={() => this.addUser()} />}
                                                   </div> :
                                                   <div className="mb-10 mt-10 fRight wt-20p">
                                                      {!isSeeker && index !== 0 && <img src={cancelBtn} style={{ width: '20px', cursor: 'pointer' }} onClick={() => this.onUserCancel(index)} />}
                                                   </div>}
                                             </div>

                                          </div>
                                       </Fragment>
                                    )
                                 })}

                              </div>
                           </div>

                           {(AWL_COMPANY_IDS.includes(userCompanyId) || DEEPAK_COMPANY_ID.includes(userCompanyId) || ABFRL_COMPANY_IDS.includes(userCompanyId)) &&
                              <div className='customer-details-table'>
                                 <div className="customer-table-header">
                                    <div className="customer-table-heading">Head Office Details</div>
                                    <div className='customer-details-body'>
                                       <div className='customer-details-row'>
                                          <div className="dInBlock wt-100p">
                                             <div className="black">Pincode<span className='error'>*</span></div>
                                             {!isSeeker ? <input
                                                type="text"
                                                placeholder="Pincode"
                                                className="customer-details-vendor-input"
                                                value={data.head_office_pincode}
                                                onChange={(e) => {
                                                   const { data } = this.state;
                                                   if (e.target.value.length > 6) {
                                                      return
                                                   }
                                                   else {
                                                      if (e.target.value != 0) {
                                                         const regEx = /^[+]?([1-9][0-9]*(?:[\.][0-9]*)?|0*\.0*[1-9][0-9]*)(?:[eE][+-][0-9]+)?$/;
                                                         const check = e.target.value.match(regEx);
                                                         if (!check) {
                                                            return
                                                         }
                                                      }
                                                      data.head_office_pincode = e.target.value;
                                                      this.getStateAndCityFromPin(e.target.value, null, 'head')
                                                      this.setState({ data });
                                                   }
                                                }}
                                             /> : <div className="customer-details-vendor-input">{data.head_office_pincode || '-'}</div>}
                                          </div>
                                          <div className="dInBlock wt-100p">
                                             <div className="black">State<span className='error'>*</span></div>
                                             {!isSeeker ?
                                                <SelectT1
                                                   value={(this.state.stateOptions || []).filter(obj => { return data.head_office_state === obj.label })}
                                                   options={(this.state.stateOptions || [])}
                                                   onChange={(value) => {
                                                      const { data } = this.state;
                                                      data.head_office_state = value.label;
                                                      this.setState({ data });
                                                   }}
                                                   placeholder={'state'}
                                                   className='customer-details-vendor-select'
                                                   style={{ height: '50px' }}
                                                />
                                                : <div className="customer-details-vendor-input">{data.head_office_state || '-'}</div>}
                                          </div>
                                          <div className="dInBlock wt-100p">
                                             <div className="black">City<span className='error'>*</span></div>
                                             {!isSeeker ? <input
                                                type="text"
                                                placeholder="City"
                                                className="customer-details-vendor-input"
                                                value={data.head_office_city}
                                                onChange={(e) => {
                                                   const { data } = this.state;
                                                   data.head_office_city = e.target.value.replace(/[&\/\\#,+()$~%.@!^&()-/'":*?<>{}0-9]/g, '');
                                                   this.setState({ data });
                                                }}
                                             /> : <div className="customer-details-vendor-input">{data.head_office_city || '-'}</div>}
                                          </div>
                                          <div className="dInBlock wt-100p">
                                             <div className="black">Address <span className='error'>*</span></div>
                                             {!isSeeker ? <input
                                                type="text"
                                                placeholder="Address"
                                                className="customer-details-vendor-input"
                                                value={data.head_office_address}
                                                onChange={(e) => {
                                                   const { data } = this.state;
                                                   data.head_office_address = e.target.value;
                                                   this.setState({ data });
                                                }}
                                             /> : <div className="customer-details-vendor-input" style={{ height: 'max-content' }}>{data.head_office_address || '-'}</div>}
                                          </div>
                                          <div className="dInBlock wt-100p">
                                             <div className="black">Contact Number<span className='error'>*</span></div>
                                             {!isSeeker ? <input
                                                type="text"
                                                placeholder="Contact Number"
                                                className="customer-details-vendor-input"
                                                value={data.head_office_number}
                                                onChange={(e) => {
                                                   const { data } = this.state;
                                                   if (e.target.value.length <= 10) {
                                                      if (e.target.value != 0) {
                                                         const regEx = /^[+]?([1-9][0-9]*(?:[\.][0-9]*)?|0*\.0*[1-9][0-9]*)(?:[eE][+-][0-9]+)?$/;
                                                         const check = e.target.value.match(regEx);
                                                         if (!check) {
                                                            return
                                                         }
                                                      }
                                                      data.head_office_number = e.target.value;
                                                   }
                                                   this.setState({ data });
                                                }}
                                             /> : <div className="customer-details-vendor-input">{data.head_office_number || '-'}</div>}
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           }

                           <div className="regional-office-details">
                              <div className='regional-table-header'>
                                 <div className="regional-table-heading">Regional Office Details</div>
                                 {
                                    data.regionalOfficeList.map((regional, index) => {
                                       return (
                                          <div className='regional-table-body'>
                                             <div className='regional-left-row'>
                                                <div className="dInBlock wt-100p">
                                                   <div className="black">Pincode{(AWL_COMPANY_IDS.includes(userCompanyId) || CARGILL_COMPANY_IDS.includes(userCompanyId)) && <span className='error'>*</span>}</div>
                                                   {!isSeeker ? <input
                                                      type="text"
                                                      placeholder="Pincode"
                                                      className="regional-new-vendor-input"
                                                      value={regional.regional_office_pincode}
                                                      onChange={(e) => {
                                                         const { data } = this.state;
                                                         if (e.target.value.length > 6) {
                                                            return
                                                         }
                                                         else {
                                                            if (e.target.value != 0) {
                                                               const regEx = /^[+]?([1-9][0-9]*(?:[\.][0-9]*)?|0*\.0*[1-9][0-9]*)(?:[eE][+-][0-9]+)?$/;
                                                               const check = e.target.value.match(regEx);
                                                               if (!check) {
                                                                  return
                                                               }
                                                            }
                                                            data.regionalOfficeList[index].regional_office_pincode = e.target.value;
                                                            this.getStateAndCityFromPin(e.target.value, index, 'regional')
                                                            this.setState({ data });
                                                         }
                                                      }}

                                                   /> : <div className="regional-new-vendor-input">{data.regionalOfficeList[index].regional_office_pincode || '-'}</div>}
                                                </div>
                                                <div className="dInBlock wt-100p">
                                                   <div className="black">State{(AWL_COMPANY_IDS.includes(userCompanyId) || CARGILL_COMPANY_IDS.includes(userCompanyId)) && <span className='error'>*</span>}</div>
                                                   {!isSeeker ?
                                                      <SelectT1
                                                         value={(this.state.stateOptions || []).filter(obj => { return regional.regional_office_state === obj.label })} //{label: transporter, value: transporter}}
                                                         options={(this.state.stateOptions || [])}
                                                         onChange={(value) => {
                                                            const { data } = this.state;
                                                            data.regionalOfficeList[index].regional_office_state = value.label;
                                                            this.setState({ data });
                                                         }}
                                                         placeholder={'State'}
                                                         className='regional-details-vendor-select'
                                                         style={{ height: '50px' }}
                                                      />
                                                      : <div className="regional-new-vendor-input">{data.regionalOfficeList[index].regional_office_state || '-'}</div>}
                                                </div>
                                                <div className="dInBlock wt-100p">
                                                   <div className="black">City{(AWL_COMPANY_IDS.includes(userCompanyId) || CARGILL_COMPANY_IDS.includes(userCompanyId)) && <span className='error'>*</span>}</div>
                                                   {!isSeeker ? <input
                                                      type="text"
                                                      placeholder="City"
                                                      className="regional-new-vendor-input"
                                                      value={regional.regional_office_city}
                                                      onChange={(e) => {
                                                         const { data } = this.state;
                                                         data.regionalOfficeList[index].regional_office_city = e.target.value.replace(/[&\/\\#,+()$~%.@!^&()-/'":*?<>{}0-9]/g, '');
                                                         this.setState({ data });
                                                      }}
                                                   /> : <div className="regional-new-vendor-input">{data.regionalOfficeList[index].regional_office_city || '-'}</div>}
                                                </div>
                                                <div className="dInBlock wt-100p">
                                                   <div className="black">Address {(AWL_COMPANY_IDS.includes(userCompanyId) || CARGILL_COMPANY_IDS.includes(userCompanyId)) && <span className='error'>*</span>}</div>
                                                   {!isSeeker ? <input
                                                      type="text"
                                                      placeholder="Address"
                                                      className="regional-new-vendor-input"
                                                      value={regional.regional_office_address}
                                                      onChange={(e) => {
                                                         const { data } = this.state;
                                                         data.regionalOfficeList[index].regional_office_address = e.target.value;
                                                         this.setState({ data });
                                                      }}
                                                   /> : <div className="regional-new-vendor-input" style={{ height: 'max-content' }}>{data.regionalOfficeList[index].regional_office_address || '-'}</div>}
                                                </div>
                                                <div className="dInBlock wt-100p">
                                                   <div className="black">Contact Number{(AWL_COMPANY_IDS.includes(userCompanyId) || CARGILL_COMPANY_IDS.includes(userCompanyId)) && <span className='error'>*</span>}</div>
                                                   {!isSeeker ? <input
                                                      type="text"
                                                      placeholder="Contact Number"
                                                      className="regional-new-vendor-input"
                                                      value={regional.regional_office_number}
                                                      onChange={(e) => {
                                                         const { data } = this.state;
                                                         if (e.target.value.length <= 10) {
                                                            if (e.target.value != 0) {
                                                               const regEx = /^[+]?([1-9][0-9]*(?:[\.][0-9]*)?|0*\.0*[1-9][0-9]*)(?:[eE][+-][0-9]+)?$/;
                                                               const check = e.target.value.match(regEx);
                                                               if (!check) {
                                                                  return
                                                               }
                                                            }
                                                            data.regionalOfficeList[index].regional_office_number = e.target.value;
                                                         }
                                                         this.setState({ data });
                                                      }}
                                                   /> : <div className="regional-new-vendor-input">{data.regionalOfficeList[index].regional_office_number || '-'}</div>}
                                                </div>

                                                {CARGILL_COMPANY_IDS.includes(userCompanyId) &&
                                                   <>
                                                      <div className="dInBlock wt-100p">
                                                         <div className="black">Country<span className='error'>*</span></div>
                                                         {!isSeeker ?
                                                            <Select
                                                               value={(country_options || []).filter(obj => { return regional.countryName === obj.label })}
                                                               options={country_options || []}
                                                               onChange={(value) => {
                                                                  const { data } = this.state;
                                                                  data.regionalOfficeList[index].countryName = value.label;
                                                                  this.setState({ data });
                                                               }}
                                                               placeholder={'country'}
                                                               className='company-code-new-vendor-dropdown'
                                                            />
                                                            : <div className="regional-new-vendor-input">{data.regionalOfficeList[index].countryName || '-'}</div>}
                                                      </div>
                                                   </>
                                                }
                                             </div>

                                             {!isSeeker &&
                                                <div style={{ display: 'flex', width: '10%', marginTop: '8px' }}>
                                                   <img src={addBtn} style={{ width: '20px', cursor: 'pointer' }} onClick={() => this.addRegionalOffice()} />
                                                   {index !== 0 && <img src={cancelBtn} style={{ width: '20px', marginLeft: '10px', cursor: 'pointer' }} onClick={() => this.removeRegionalOffice(index)} />}
                                                </div>
                                             }

                                          </div>
                                       )
                                    })
                                 }
                              </div>
                           </div>


                           <div className="regional-office-details">
                              <div className='regional-table-header'>
                                 <div className="regional-table-heading">Branch Office Details</div>
                                 {data.branchOfficeList.map((branch, index) => {
                                    return (
                                       <div className='regional-table-body'>
                                          <div className='regional-left-row'>
                                             <div className="dInBlock wt-100p">
                                                <div className="black">Pincode{(!isDPL) && <span className='error'>*</span>}</div>
                                                {!isSeeker ? <input
                                                   type="text"
                                                   placeholder="Pincode"
                                                   className="regional-new-vendor-input"
                                                   value={branch.branch_office_pincode}
                                                   onChange={(e) => {
                                                      const { data } = this.state;
                                                      if (e.target.value.length > 6) {
                                                         return
                                                      }
                                                      else {
                                                         if (e.target.value != 0) {
                                                            const regEx = /^[+]?([1-9][0-9]*(?:[\.][0-9]*)?|0*\.0*[1-9][0-9]*)(?:[eE][+-][0-9]+)?$/;
                                                            const check = e.target.value.match(regEx);
                                                            if (!check) {
                                                               return
                                                            }
                                                         }
                                                         data.branchOfficeList[index].branch_office_pincode = e.target.value;
                                                         this.getStateAndCityFromPin(e.target.value, index, 'branch')
                                                         this.setState({ data });
                                                      }
                                                   }}
                                                /> : <div className="regional-new-vendor-input">{branch.branch_office_pincode || '-'}</div>}
                                             </div>
                                             <div className="dInBlock wt-100p">
                                                <div className="black">State{(!isDPL) && <span className='error'>*</span>}</div>
                                                {!isSeeker ?
                                                   <SelectT1
                                                      value={(this.state.stateOptions || []).filter(obj => { return branch.branch_office_state === obj.label })} //{label: transporter, value: transporter}}
                                                      options={(this.state.stateOptions || [])}
                                                      onChange={(value) => {
                                                         const { data } = this.state;
                                                         data.branchOfficeList[index].branch_office_state = value.label;
                                                         this.setState({ data });
                                                      }}
                                                      placeholder={'State'}
                                                      className='regional-details-vendor-select'
                                                      style={{ height: '50px' }}
                                                   />
                                                   : <div className="regional-new-vendor-input">{data.branchOfficeList[index].branch_office_state || '-'}</div>}
                                             </div>
                                             <div className="dInBlock wt-100p">
                                                <div className="black">City{(!isDPL) && <span className='error'>*</span>}</div>
                                                {!isSeeker ? <input
                                                   type="text"
                                                   placeholder="City"
                                                   className="regional-new-vendor-input"
                                                   value={branch.branch_office_city}
                                                   onChange={(e) => {
                                                      const { data } = this.state;
                                                      data.branchOfficeList[index].branch_office_city = e.target.value.replace(/[&\/\\#,+()$~%.@!^&()-/'":*?<>{}0-9]/g, '');
                                                      this.setState({ data });
                                                   }}
                                                /> : <div className="regional-new-vendor-input">{branch.branch_office_city || '-'}</div>}
                                             </div>
                                             <div className="dInBlock wt-100p">
                                                <div className="black">Address{(!isDPL) && <span className='error'>*</span>}</div>
                                                {!isSeeker ? <input
                                                   type="text"
                                                   placeholder="Address"
                                                   className="regional-new-vendor-input"
                                                   value={branch.branch_office_address}
                                                   onChange={(e) => {
                                                      const { data } = this.state;
                                                      data.branchOfficeList[index].branch_office_address = e.target.value;
                                                      this.setState({ data });
                                                   }}
                                                /> : <div className="regional-new-vendor-input" style={{ height: 'max-content' }}>{branch.branch_office_address || '-'}</div>}
                                             </div>
                                             <div className="dInBlock wt-100p">
                                                <div className="black">Contact Number{(!isDPL) && <span className='error'>*</span>}</div>
                                                {!isSeeker ? <input
                                                   type="text"
                                                   placeholder="Contact Number"
                                                   className="regional-new-vendor-input"
                                                   value={branch.branch_office_number}
                                                   onChange={(e) => {
                                                      const { data } = this.state;
                                                      if (e.target.value.length <= 10) {
                                                         if (e.target.value != 0) {
                                                            const regEx = /^[+]?([1-9][0-9]*(?:[\.][0-9]*)?|0*\.0*[1-9][0-9]*)(?:[eE][+-][0-9]+)?$/;
                                                            const check = e.target.value.match(regEx);
                                                            if (!check) {
                                                               return
                                                            }
                                                         }
                                                         data.branchOfficeList[index].branch_office_number = e.target.value;
                                                      }
                                                      this.setState({ data });
                                                   }}
                                                /> : <div className="regional-new-vendor-input">{branch.branch_office_number || '-'}</div>
                                                }
                                             </div>
                                          </div>

                                          {!isSeeker &&
                                             <div style={{ display: 'flex', width: '10%', marginTop: '8px' }}>
                                                <img src={addBtn} style={{ width: '20px', cursor: 'pointer' }} onClick={() => this.addBranchOffice()} />
                                                {index !== 0 && <img src={cancelBtn} style={{ width: '20px', marginLeft: '10px', cursor: 'pointer' }} onClick={() => this.removeBranchOffice(index)} />}
                                             </div>
                                          }
                                       </div>
                                    )
                                 })}
                              </div>
                           </div>

                           <div className='customer-details-table'>
                              <div className="customer-table-header">
                                 <div className="customer-table-heading">{ABFRL_COMPANY_IDS.includes(userCompanyId)?'Reference Customer Details':'Customer Details'}</div>
                                 {(data.customerList || []).map((customer, index) => {
                                    return (
                                       <Fragment>
                                          <div className='customer-details-body'>
                                             <div className='customer-details-row'>
                                                <div className="dInBlock wt-100p">
                                                   <div className="black">Customer Name<span className='error'>*</span></div>
                                                   {!isSeeker ? <input
                                                      type="text"
                                                      placeholder="Customer Name"
                                                      className="customer-details-vendor-input"
                                                      value={customer.customer_name}
                                                      onChange={(e) => {
                                                         const { data } = this.state;
                                                         data.customerList[index].customer_name = e.target.value.replace(/[&\/\\#,+()$~%.@!^&()-/'":*?<>{}]/g, '');
                                                         this.setState({ data });
                                                      }}
                                                   /> : <div className="customer-details-vendor-input" style={{ height: 'max-content' }}>{customer.customer_name || '-'}</div>}
                                                </div>
                                                <div className="dInBlock wt-100p">
                                                   <div className="black">Pincode<span className='error'>*</span></div>
                                                   {!isSeeker ? <input
                                                      type="text"
                                                      placeholder="Pincode"
                                                      className="customer-details-vendor-input"
                                                      value={customer.pin_code}
                                                      onChange={(e) => {
                                                         const { data } = this.state;
                                                         if (e.target.value.length > 6) {
                                                            return
                                                         }
                                                         else {
                                                            if (e.target.value != 0) {
                                                               const regEx = /^[+]?([1-9][0-9]*(?:[\.][0-9]*)?|0*\.0*[1-9][0-9]*)(?:[eE][+-][0-9]+)?$/;
                                                               const check = e.target.value.match(regEx);
                                                               if (!check) {
                                                                  return
                                                               }
                                                            }
                                                            data.customerList[index].pin_code = e.target.value;
                                                            this.getStateAndCityFromPin(e.target.value, index, 'customer')
                                                            this.setState({ data });
                                                         }
                                                      }}
                                                   /> : <div className="customer-details-vendor-input">{customer.pin_code || '-'}</div>}
                                                </div>
                                                <div className="dInBlock wt-100p">
                                                   <div className="black">State<span className='error'>*</span></div>
                                                   {!isSeeker ?
                                                      <SelectT1
                                                         value={(this.state.stateOptions || []).filter(obj => { return customer.state === obj.label })} //{label: transporter, value: transporter}}
                                                         options={(this.state.stateOptions || [])}
                                                         onChange={(value) => {
                                                            const { data } = this.state;
                                                            data.customerList[index].state = value.label;
                                                            this.setState({ data });
                                                         }}
                                                         placeholder={'state'}
                                                         className='customer-details-vendor-select'
                                                         style={{ height: '50px' }}
                                                      />
                                                      : <div className="customer-details-vendor-input">{customer.state || '-'}</div>}
                                                </div>
                                                <div className="dInBlock wt-100p">
                                                   <div className="black">City<span className='error'>*</span></div>
                                                   {!isSeeker ? <input
                                                      type="text"
                                                      placeholder="City"
                                                      className="customer-details-vendor-input"
                                                      value={customer.city}
                                                      onChange={(e) => {
                                                         const { data } = this.state;
                                                         data.customerList[index].city = e.target.value.replace(/[&\/\\#,+()$~%.@!^&()-/'":*?<>{}0-9]/g, '');
                                                         this.setState({ data });
                                                      }}
                                                   /> : <div className="customer-details-vendor-input">{customer.city || '-'}</div>}
                                                </div>
                                                <div className="dInBlock wt-100p">
                                                   <div className="black">Location<span className='error'>*</span></div>
                                                   {!isSeeker ? <input
                                                      type="text"
                                                      placeholder="Location"
                                                      className="customer-details-vendor-input"
                                                      value={customer.location}
                                                      onChange={(e) => {
                                                         const { data } = this.state;
                                                         data.customerList[index].location = e.target.value;
                                                         this.setState({ data });
                                                      }}
                                                   /> : <div className="customer-details-vendor-input" style={{ height: 'max-content' }}>{customer.location || '-'}</div>}
                                                </div>
                                                <div className="dInBlock wt-100p">
                                                   <div className="black">Email<span className='error'>*</span></div>
                                                   {!isSeeker ? <input
                                                      type="text"
                                                      placeholder="Email"
                                                      className="customer-details-vendor-input"
                                                      value={customer.email}
                                                      onChange={(e) => {
                                                         const { data } = this.state;
                                                         let re = /\S+@\S+\.\S+/;
                                                         let regex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
                                                         if (re.test(e.target.value) || regex.test(e.target.value) || e.target.value.length === 0) {
                                                            data.customerList[index].email = e.target.value;
                                                            delete data.customerList[index].error
                                                            this.setState({ data });
                                                         } else {
                                                            data.customerList[index].email = e.target.value;
                                                            data.customerList[index].error = "Invalid Email"
                                                            this.setState({ data });
                                                         }
                                                      }}
                                                   /> : <div className="customer-details-vendor-input" style={{ height: 'max-content' }}>{customer.email || '-'}</div>}
                                                   {customer.error && <p style={{ color: 'red', fontSize: '13px' }}>{customer.error}</p>}
                                                </div>
                                             </div>

                                             <div className='customer-details-row2'>
                                                <div className='customer-details-left'>
                                                   <div className="dInBlock wt-100p">
                                                      <div className="black">Service Provided<span className='error'>*</span></div>
                                                      {!isSeeker ? <input
                                                         type="text"
                                                         placeholder="Service Provided"
                                                         className="customer-details-vendor-input2"
                                                         value={customer.service_provided}
                                                         onChange={(e) => {
                                                            const { data } = this.state;
                                                            data.customerList[index].service_provided = e.target.value;
                                                            this.setState({ data });
                                                         }}
                                                      /> : <div className="customer-details-vendor-input2" style={{ height: 'max-content' }}>{customer.service_provided || '-'}</div>}
                                                   </div>
                                                   <div className="dInBlock wt-100p">
                                                      <div className="black">Product Handled<span className='error'>*</span></div>
                                                      {!isSeeker ? <input
                                                         type="text"
                                                         placeholder="Product Handled"
                                                         className="customer-details-vendor-input2"
                                                         value={customer.product_handled}
                                                         onChange={(e) => {
                                                            const { data } = this.state;
                                                            data.customerList[index].product_handled = e.target.value;
                                                            this.setState({ data });
                                                         }}
                                                      /> : <div className="customer-details-vendor-input2" style={{ height: 'max-content' }}>{customer.product_handled || '-'}</div>}
                                                   </div>
                                                </div>

                                                <div style={{ display: 'flex', width: '10%', marginTop: '8px' }}>
                                                   {(index + 1) === data.customerList.length ?
                                                      <div className="mb-10 mt-10 fRight">
                                                         {!isSeeker && (index !== 0) && <img src={cancelBtn} style={{ width: '20px', cursor: 'pointer' }} onClick={() => this.onCustomerCancel(index)} />}
                                                         {!isSeeker && <img src={addBtn} style={{ width: '20px', cursor: 'pointer', marginLeft: '10px' }} onClick={() => this.addCustomers()} />}
                                                      </div> :
                                                      <div className="mb-10 mt-10 wt-20p fRight">
                                                         {!isSeeker && (index !== 0) && <img src={cancelBtn} style={{ width: '20px', cursor: 'pointer' }} onClick={() => this.onCustomerCancel(index)} />}
                                                      </div>
                                                   }
                                                </div>
                                             </div>
                                          </div>

                                       </Fragment>
                                    )
                                 })}
                              </div>
                           </div>
                           <div className="gst-details-table">
                              <div className="gst-table-header">
                                 <div style={{ display: 'flex', borderBottom: '1px solid black', width: '100%' }}>
                                    <div className="gst-table-heading" id='gstdetails'>GST Details{(CARGILL_COMPANY_IDS.includes(userCompanyId) || ((AWL_COMPANY_IDS.includes(userCompanyId) || DEEPAK_COMPANY_ID.includes(userCompanyId)) && data.is_gst_registered)) && <span className='error'>*</span>}</div>

                                    {!(AWL_COMPANY_IDS.includes(userCompanyId) || DEEPAK_COMPANY_ID.includes(userCompanyId)) &&
                                       <div className="mb-10 mt-10 fRight" style={{ width: '30%' }}>
                                          {!isSeeker && <button className="button-classic" onClick={this.downloadGST}> Download Template </button>}
                                          <a className="download-btn" style={{ display: 'none' }} ref={input => this.downloadBtn = input} href={this.state.downloadLink} download />
                                          {!isSeeker && <button className="button-classic mr-20" onClick={() => { this.uploadGSTDetials.click() }}> Upload </button>}
                                          <input type='file' style={{ display: 'none' }} ref={(e) => { this.uploadGSTDetials = e; }} onChange={(e) => { this.uploadGST(e) }} onClick={(e) => { e.target.value = null }} />
                                       </div>
                                    }

                                    {
                                       (AWL_COMPANY_IDS.includes(userCompanyId) || DEEPAK_COMPANY_ID.includes(userCompanyId)) &&
                                        window.location.pathname.includes('vendor-form') &&
                                       <div className='dInBlock mt-10' style={{ marginTop: '10px', width: '30%' }}>
                                          <div className="black">GST Number Register</div>
                                          <Switch
                                             onChange={this.handleSwitch}
                                             onColor="#528aea"
                                             checked={data.is_gst_registered}
                                             height={25}
                                             width={55}
                                          />
                                       </div>
                                    }

                                    {
                                       (AWL_COMPANY_IDS.includes(userCompanyId) || DEEPAK_COMPANY_ID.includes(userCompanyId) || ABFRL_COMPANY_IDS.includes(userCompanyId)) && data.is_gst_registered &&
                                       <div className="dInBlock" style={{ width: '40%', cursor: 'pointer', padding: '10px 0' }}>
                                          <div className="black">FCM/RCM<span className='error'>*</span></div>
                                          {!isSeeker ?
                                                <SelectT1
                                                value={[{ label: 'FCM', value: 'FCM' }, { label: 'RCM', value: 'RCM' }].filter(obj => { return data.FCN_RCN === obj.label })}
                                                options={[{ label: 'FCM', value: 'FCM' }, { label: 'RCM', value: 'RCM' }]}
                                                onChange={(value) => {
                                                   const { data } = this.state;
                                                   data.FCN_RCN = value.label;
                                                   this.setState({ data });
                                                }}
                                                placeholder={'FCM/RCM'}
                                                className='gst-details-vendor-dropdown'
                                             />
                                             : <div className="gst-details-vendor-input">{data.FCN_RCN || '-'}</div>}
                                       </div>
                                    }

                                    {CARGILL_COMPANY_IDS.includes(userCompanyId) &&
                                       <div className="dInBlock wt-100p mt-10" style={{ paddingBottom: '5px' }}>
                                          <div className="black">GST no<span className='error'>*</span></div>
                                          {!isSeeker ? <input
                                             type="text"
                                             placeholder="GST no"
                                             className="gst-details-vendor-input"
                                             value={data.gst_no}
                                             onChange={(e) => {
                                                const { data } = this.state;
                                                if (e.target.value.length <= 15) {
                                                   data.gst_no = e.target.value.replace(/[&\/\\#,+()$~%.@!^&()-/'":*?<>{}]/g, '');
                                                }
                                                this.setState({ data });
                                             }}
                                          /> : <div className="gst-details-vendor-input ">{data.gst_no || '-'}</div>}
                                       </div>}
                                 </div>

                                 {!(AWL_COMPANY_IDS.includes(userCompanyId) || DEEPAK_COMPANY_ID.includes(userCompanyId)) &&
                                    <div style={{ marginLeft: "15px" }}>
                                       <table className="wt-80p mb-25" style={{ float: 'left' }}>
                                          <thead>
                                             <th className="tCenter borderLight black bg-light">State</th>
                                             <th className="tCenter borderLight black bg-light">GSTIN</th>
                                             <th className="tCenter borderLight black bg-light">GST Percentage</th>
                                          </thead>
                                          <tbody>
                                             {(data.gstDetails || []).map((gst) => {
                                                return (
                                                   <tr>
                                                      <td className="tCenter borderLight black">{gst.state}</td>
                                                      <td className="tCenter borderLight black">{gst.gstin}</td>
                                                      <td className="tCenter borderLight black">{gst.gst_percentage}</td>
                                                   </tr>
                                                )
                                             })}
                                          </tbody>
                                       </table>
                                    </div>
                                 }

                                 {(AWL_COMPANY_IDS.includes(userCompanyId) || (DEEPAK_COMPANY_ID.includes(userCompanyId))) &&
                                    <>{
                                       data.gstDetails.map((gst, index) => {
                                          return (
                                             <div className='gst-table-body'>
                                                <div className='gst-table-left-row'>
                                                   <div className="dInBlock wt-90p">
                                                      <div className="black">State</div>
                                                      {!isSeeker ?
                                                         <SelectT1
                                                            value={(this.state.stateOptions || []).filter(obj => { return gst.state === obj.label })} //{label: transporter, value: transporter}}
                                                            options={(this.state.stateOptions || [])}
                                                            onChange={(value) => {
                                                               const { data } = this.state;
                                                               data.gstDetails[index].state = value.label;
                                                               data.gstDetails[index].gstin = value.gst_state_code + data.pan_no

                                                               if (data.gstDetails[index].gstin && data.gstDetails[index].gstin.length) {
                                                                  data.gstDetails[index].gstError = 'Please Enter Valid Gst No.'
                                                               }

                                                               this.setState({ data });
                                                            }}
                                                            placeholder={'State'}
                                                            className='gst-table-details-vendor-select'
                                                         />
                                                         : <div className="gst-table-new-vendor-input">{data.gstDetails[index].state || '-'}</div>}
                                                   </div>

                                                   <div className="dInBlock wt-90p">
                                                      <div className="black">GSTIN</div>
                                                      {!isSeeker ? <input
                                                         type="text"
                                                         placeholder="GST IN"
                                                         className="gst-table-new-vendor-input"
                                                         value={gst.gstin}
                                                         onChange={(e) => {
                                                            const { data } = this.state;
                                                            let regex = /^[0-9]{2}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[1-9A-Za-z]{1}Z[0-9A-Za-z]{1}$/;
                                                            if ((e.target.value.length >= 2) && (e.target.value.length <= 15)) {
                                                               if (regex.test(e.target.value)) {
                                                                  delete data.gstDetails[index].gstError
                                                                  data.gstDetails[index].gstin = e.target.value.replace(/[&\/\\#,+()$~%.@!^&()-/'":*?<>{}]/g, '').toUpperCase();
                                                               } else {
                                                                  data.gstDetails[index].gstError = 'Please Enter Valid Gst No.'
                                                                  data.gstDetails[index].gstin = e.target.value.replace(/[&\/\\#,+()$~%.@!^&()-/'":*?<>{}]/g, '').toUpperCase();
                                                               }
                                                            } else {
                                                               return
                                                            }
                                                            this.setState({ data });
                                                         }}
                                                      /> : <div className="gst-table-new-vendor-input">{gst.gstin || '-'}</div>}
                                                      <br />
                                                      {gst.gstError && <span className='error-color' style={{ fontSize: '14px' }}>{gst.gstError}</span>}
                                                   </div>

                                                   <div className="dInBlock wt-90p">
                                                      <div className="black">GST Percentage</div>
                                                      {!isSeeker ?
                                                         <SelectT1
                                                            value={([{ label: '5', value: 1 }, { label: '12', value: 2 }, { label: '18', value: 3 }]).filter(obj => { return gst.gst_percentage === obj.label })} //{label: transporter, value: transporter}}
                                                            options={([{ label: '5', value: 1 }, { label: '12', value: 2 }, { label: '18', value: 3 }])}
                                                            onChange={(value) => {
                                                               const { data } = this.state;
                                                               data.gstDetails[index].gst_percentage = value.label;
                                                               this.setState({ data });
                                                            }}
                                                            placeholder={'GST Percentage'}
                                                            className='gst-table-details-vendor-select'
                                                            style={{ height: '50px' }}
                                                         />
                                                         : <div className="gst-table-new-vendor-input">{data.gstDetails[index].gst_percentage || '-'}</div>}
                                                   </div>

                                                </div>

                                                {!isSeeker &&
                                                   <div style={{ display: 'flex', width: '10%', marginTop: '8px' }}>
                                                      {(index == data.gstDetails.length - 1) && <img src={addBtn} style={{ width: '20px', cursor: 'pointer' }} onClick={() => this.addGstDetails()} />}
                                                      {index !== 0 && <img src={cancelBtn} style={{ width: '20px', marginLeft: '10px', cursor: 'pointer' }} onClick={() => this.removeGstDetails(index)} />}
                                                   </div>
                                                }

                                             </div>
                                          )
                                       })
                                    }
                                    </>
                                 }
                              </div>
                           </div> 

                           {
                              (AWL_COMPANY_IDS.includes(userCompanyId) || DEEPAK_COMPANY_ID.includes(userCompanyId) || ABFRL_COMPANY_IDS.includes(userCompanyId) ) &&
                              <div className='bank-details'>
                                 <div className="bank-details-table-header">
                                    <div className="bank-details-table-heading">{ABFRL_COMPANY_IDS.includes(userCompanyId) ? "SSI/MSME Detail":"MSME Detail"}</div>
                                    <div className='bank-table-body'>
                                       <div className='dInBlock wt-100p'>
                                             <div className="black">{ABFRL_COMPANY_IDS.includes(userCompanyId) ? "SSI/MSME Certificate Register":"MSME Certificate Register"}</div>
                                          <Switch
                                             onChange={this.handleSwitchMSME}
                                             onColor="#528aea"
                                             checked={data.is_msme_registered}
                                             height={25}
                                             width={55}
                                          />
                                       </div>
                                       {data.is_msme_registered &&
                                          <div className="dInBlock wt-100p">
                                                <div className="black">{ABFRL_COMPANY_IDS.includes(userCompanyId) ? "SSI/MSME Detail Service" : "MSME Detail Service"}{(AWL_COMPANY_IDS.includes(userCompanyId) && data.is_msme_registered) && <span className='error'>*</span>}</div>
                                             {!isSeeker ?
                                                <Select
                                                   value={([{ label: 'Micro', value: 'Micro' }, { label: 'Small', value: 'Small' }, { label: 'Medium', value: 'Medium' }]).filter(obj => { return data.msme_detail === obj.label })}
                                                   
                                                   options={[{ label: 'Micro', value: 'Micro' }, { label: 'Small', value: 'Small' }, { label: 'Medium', value: 'Medium' }]}
                                                   onChange={(value) => {
                                                      const { data } = this.state;
                                                      data.msme_detail = value.label;
                                                      this.setState({ data });
                                                   }}
                                                      placeholder={ ABFRL_COMPANY_IDS.includes(userCompanyId) ? "SSI/MSME Detail Service":'MSME Details Service'}
                                                   className='bank-new-vendor-dropdown'
                                                />
                                                : <div className="bank-new-vendor-input">{data.msme_detail || '-'}</div>}
                                          </div>
                                       }

                                       {data.is_msme_registered &&
                                          <div className="dInBlock wt-100p">
                                             <div className="black">UDYAM Number{(AWL_COMPANY_IDS.includes(userCompanyId) && data.is_msme_registered) && <span className='error'>*</span>} <span style={{ paddingLeft: '20px', fontSize: '12px', color: 'gray' }}>Eg:UDYAM-AB-00-0000000</span></div>
                                             {!isSeeker ? <input
                                                type="text"
                                                placeholder="Eg:UDYAM-AB-00-0000000"
                                                className="bank-new-vendor-input"
                                                value={data.udyam_no}
                                                onChange={(e) => {
                                                   const { data } = this.state;
                                                   const regex = /^[A-Z]{5}[-][A-Z]{2}[-][0-9]{2}[-][0-9]{7}$/;
                                                   if (regex.test(e.target.value) || e.target.value.length == 0) {
                                                      data.udyam_no = e.target.value ;
                                                      this.setState({ UDYAMError: '' })
                                                   }
                                                   else {
                                                      data.udyam_no = e.target.value;
                                                      this.setState({ UDYAMError: 'Invalid UDYAM No.' })
                                                   }
                                                   this.setState({ data })
                                                }}
                                             /> : <div className="bank-new-vendor-input">{data.udyam_no || '-'}</div>}
                                             {this.state.UDYAMError && <p style={{ color: 'red', fontSize: '13px' }}>{this.state.UDYAMError}</p>}
                                          </div>
                                       }
                                       {
                                             data.is_msme_registered &&
                                             <div className="dInBlock wt-100p">
                                                   <div className="black">SSI/MSME Registered Date{ }</div>
                                                {!isSeeker ?
                                                   <input
                                                      type="text"
                                                      placeholder="Enter date DD/MM/YYYY"
                                                      className="bank-new-vendor-input"
                                                         value={data.msme_date}
                                                      onChange={(e) => {
                                                         const { data } = this.state;
                                                         const regex = /^[A-Z]{5}[-][A-Z]{2}[-][0-9]{2}[-][0-9]{7}$/;
                                                         if (regex.test(e.target.value) || e.target.value.length == 0) {
                                                            data.msme_date = e.target.value;
                                                            this.setState({ ITRDATEError: '' })
                                                         }
                                                         else {
                                                            data.msme_date = e.target.value;
                                                            // this.setState({ ITRDATEError: 'Invalid ACK No.' })
                                                         }
                                                         this.setState({ data })
                                                      }}
                                                   />
                                                      : <div className="bank-new-vendor-input">{data.msme_date || '-'}</div>}
                                                   {this.state.msme_date && <p style={{ color: 'red', fontSize: '13px' }}>{this.state.msme_date}</p>}
                                             </div>

                                       }
                                    </div>
                                 </div>
                              </div>
                           }
                              {
                              (DEEPAK_COMPANY_ID.includes(userCompanyId) || ABFRL_COMPANY_IDS.includes(userCompanyId)) &&
                              <div className='bank-details'>
                                 <div className="bank-details-table-header">
                                       <div className="bank-details-table-heading">ITR Details</div>
                                    <div className='bank-table-body'>
                                       <div className='dInBlock wt-100p'>
                                             <div className="black"> ITR Filled</div>
                                          <Switch
                                             onChange={this.handleSwitchITR}
                                             onColor="#528aea"
                                                checked={data.is_itr_filed}
                                             height={25}
                                             width={55}
                                          />
                                       </div>
                                          {data.is_itr_filed &&
                                          <div className="dInBlock wt-100p">
                                                <div className="black">Filing Date{(DEEPAK_COMPANY_ID.includes(userCompanyId) && data.is_itr_filed) && <span className='error'>*</span>}</div>
                                             {!isSeeker ?
                                                   <input
                                                      type="text"
                                                      placeholder="Enter date DD/MM/YYYY"
                                                      className="bank-new-vendor-input"
                                                      value={data.itr_filing_date}
                                                      onChange={(e) => {
                                                         const { data } = this.state;
                                                         const regex = /^[A-Z]{5}[-][A-Z]{2}[-][0-9]{2}[-][0-9]{7}$/;
                                                         if (regex.test(e.target.value) || e.target.value.length == 0) {
                                                            data.itr_filing_date = e.target.value;
                                                            this.setState({ ITRDATEError: '' })
                                                         }
                                                         else {
                                                            data.itr_filing_date = e.target.value;
                                                            // this.setState({ ITRDATEError: 'Invalid ACK No.' })
                                                         }
                                                         this.setState({ data })
                                                      }}
                                                   />
                                                   : <div className="bank-new-vendor-input">{data.itr_filing_date || '-'}</div>}
                                                {this.state.itr_filing_date && <p style={{ color: 'red', fontSize: '13px' }}>{this.state.itr_filing_date}</p>}
                                             </div>
                                          }

                                          {data.is_itr_filed &&
                                          <div className="dInBlock wt-100p">
                                                <div className="black">Acknowledgement Number{(DEEPAK_COMPANY_ID.includes(userCompanyId) && data.is_itr_filed) && <span className='error'>*</span>} <span style={{ paddingLeft: '20px', fontSize: '12px', color: 'gray' }}></span></div>
                                             {!isSeeker ? <input
                                                type="text"
                                                   placeholder="itr ack number"
                                                className="bank-new-vendor-input"
                                                   value={data.itr_ack_number}
                                                onChange={(e) => {
                                                   const { data } = this.state;
                                                   const regex = /^[A-Z]{5}[-][A-Z]{2}[-][0-9]{2}[-][0-9]{7}$/;
                                                   if (regex.test(e.target.value) || e.target.value.length == 0) {
                                                      data.itr_ack_number = e.target.value;
                                                      this.setState({ ITRACKError: '' })
                                                   }
                                                   else {
                                                      data.itr_ack_number = e.target.value;
                                                      // this.setState({ ITRACKError: 'Invalid ACK No.' })
                                                   }
                                                   this.setState({ data })
                                                }}
                                                /> : <div className="bank-new-vendor-input">{data.itr_ack_number || '-' }</div>}
                                                {this.state.ITRACKError && <p style={{ color: 'red', fontSize: '13px' }}>{this.state.ITRACKError}</p>}
                                          </div>
                                       }
                                    </div>
                                 </div>
                              </div>
                           }   
                           {
                              ( ABFRL_COMPANY_IDS.includes(userCompanyId)) &&
                              <div className='bank-details'>
                                 <div className="bank-details-table-header">
                                       <div className="bank-details-table-heading">TDS Exemption</div>
                                    <div className='bank-table-body'>
                                       <div className=' wt-100p exmptnbox'>
                                          {/* <div className="black"> ITR Filled</div> */}
                                          <Switch
                                             onChange={this.handleSwitchTDS}
                                             className='mt-20'
                                             onColor="#528aea"
                                             checked={data.is_tds_filed}
                                             height={25}
                                             width={55}
                                          />
                                          <div className='ml-250'>
                                          <div className="black ">Payment Terms (Credit Days)</div> 
                                          <input
                                             type="text"
                                             placeholder="Payment Terms"
                                             className="bank-new-vendor-input "
                                             value={data.paymentTerms}
                                             onChange={(e) => {
                                                const { data } = this.state;
                                                if (e.target.value.length <= 15) {
                                                   data.paymentTerms = e.target.value.replace(/[&\/\\#,+()$~%.@!^&()-/'":*?<>{}]/g, '');
                                                }
                                                this.setState({ data });
                                             }}
                                          />
                                          </div>
                                       </div>
                                       
                                    </div>
                                    
                                 </div>
                              </div>
                           } 

                           <div className='bank-details'>
                              <div className="bank-details-table-header">
                                 <div className="bank-details-table-heading">Bank Account Details{DEEPAK_COMPANY_ID.includes(userCompanyId) && <span className='error'>*</span>}</div>
                                 <div className='bank-table-body'>
                                    <div className="dInBlock wt-100p">
                                       <div className="black">IFSC Code{CARGILL_COMPANY_IDS.includes(userCompanyId) && <span className='error'>*</span>}</div>
                                       {!isSeeker ? <input
                                          type="text"
                                          placeholder="IFSC code"
                                          className="bank-new-vendor-input"
                                          value={data.IFSC_code}
                                          onChange={(e) => {
                                             const { data } = this.state;
                                             if (e.target.value.length > 12) {
                                                return
                                             }
                                             else {
                                                data.IFSC_code = e.target.value.replace(/[&\/\\#,+()$~%.@!^&()-/'":*?<>{}]/g, '').toUpperCase();
                                                this.setState({ data }, () => {
                                                   this.getBankDetailsFromIFSCCode()
                                                });
                                             }
                                          }}
                                       /> : <div className="bank-new-vendor-input">{data.IFSC_code || '-'}</div>}
                                    </div>
                                    <div className="dInBlock wt-100p">
                                       <div className="black">Bank Name{CARGILL_COMPANY_IDS.includes(userCompanyId) && <span className='error'>*</span>}</div>
                                       {!isSeeker ? <input
                                          type="text"
                                          placeholder="Bank Name"
                                          className="bank-new-vendor-input"
                                          value={data.bank_name}
                                          onChange={(e) => {
                                             const { data } = this.state;
                                             data.bank_name = e.target.value.replace(/[&\/\\#,+()$~%.@!^&()-/'":*?<>{}0-9]/g, '');
                                             this.setState({ data });
                                          }}
                                       /> : <div className="bank-new-vendor-input" style={{ height: 'max-content' }}>{data.bank_name || '-'}</div>}
                                    </div>
                                    <div className="dInBlock wt-100p">
                                       <div className="black">Branch Name</div>
                                       {!isSeeker ? <input
                                          type="text"
                                          placeholder="Branch Name"
                                          className="bank-new-vendor-input"
                                          value={data.branch_name}
                                          onChange={(e) => {
                                             const { data } = this.state;
                                             data.branch_name = e.target.value.replace(/[&\/\\#,+()$~%.@!^&()-/'":*?<>{}]/g, '');
                                             this.setState({ data });
                                          }}
                                       /> : <div className="bank-new-vendor-input" style={{ height: 'max-content' }}>{data.branch_name || '-'}</div>}
                                    </div>
                                    <div className="dInBlock wt-100p">
                                       <div className="black">Account No.{CARGILL_COMPANY_IDS.includes(userCompanyId) && <span className='error'>*</span>}</div>
                                       {!isSeeker ? <input
                                          type="number"
                                          placeholder="Account No."
                                          className="bank-new-vendor-input"
                                          value={data.account_number}
                                          onChange={(e) => {
                                             const { data } = this.state;
                                             if (e.target.value.length > 16) {
                                                return
                                             }
                                             else {
                                                // if (e.target.value != 0 || e.target.value == 0) {
                                                //    const regEx = /^[+]?([1-9][0-9]*(?:[\.][0-9]*)?|0*\.0*[1-9][0-9]*)(?:[eE][+-][0-9]+)?$/;
                                                //    const check = e.target.value.match(regEx);
                                                //    if (!check) {
                                                //       return
                                                //    }
                                                data.account_number = e.target.value;
                                                this.setState({ data });
                                                // }
                                             }
                                          }}
                                       /> : <div className="bank-new-vendor-input">{data.account_number || '-'}</div>}
                                    </div>
                                    <div className="dInBlock wt-100p">
                                       <div className="black">Account Name</div>
                                       {!isSeeker ? <input
                                          type="text"
                                          placeholder="Account Name"
                                          className="bank-new-vendor-input"
                                          value={data.account_name}
                                          onChange={(e) => {
                                             const { data } = this.state;
                                             data.account_name = e.target.value.replace(/[&\/\\#,+()$~%.@!^&()-/'":*?<>{}]/g, '');
                                             this.setState({ data });
                                          }}
                                       /> : <div className="bank-new-vendor-input" style={{ height: 'max-content' }}>{data.account_name || '-'}</div>}
                                    </div>

                                    {CARGILL_COMPANY_IDS.includes(userCompanyId) &&
                                       <>
                                          <div className="dInBlock wt-100p">
                                             <div className="black">Country<span className='error'>*</span></div>
                                             {!isSeeker ?
                                                <Select
                                                   value={(country_options || []).filter(obj => { return data.bankcountryName === obj.label })}
                                                   options={country_options || []}
                                                   onChange={(value) => {
                                                      const { data } = this.state;
                                                      data.bankcountryName = value.label;
                                                      this.setState({ data });
                                                   }}
                                                   placeholder={'Country'}
                                                   className='bank-new-vendor-dropdown'
                                                />
                                                : <div className="bank-new-vendor-input">{data.bankcountryName || '-'}</div>}
                                          </div>

                                          <div className="dInBlock wt-100p">
                                             <div className="black">IBAN Number</div>
                                             {!isSeeker ? <input
                                                type="text"
                                                placeholder="IBAN number"
                                                className="bank-new-vendor-input"
                                                value={data.IBANNumber}
                                                onChange={(e) => {
                                                   const { data } = this.state;
                                                   data.IBANNumber = e.target.value.replace(/[&\/\\#,+()$~%.@!^&()-/'":*?<>{}]/g, '');
                                                   this.setState({ data })
                                                }}
                                             /> : <div className="bank-new-vendor-input">{data.IBANNumber || '-'}</div>}
                                          </div>

                                          <div className="dInBlock wt-100p">
                                             <div className="black">Swift Code</div>
                                             {!isSeeker ? <input
                                                type="text"
                                                placeholder="swift code"
                                                className="bank-new-vendor-input"
                                                value={data.swiftCode}
                                                onChange={(e) => {
                                                   const { data } = this.state;
                                                   data.swiftCode = e.target.value.replace(/[&\/\\#,+()$~%.@!^&()-/'":*?<>{}]/g, '');
                                                   this.setState({ data });
                                                }
                                                }
                                             /> : <div className="bank-new-vendor-input">{data.swiftCode || '-'}</div>}
                                          </div>
                                       </>
                                    }
                                 </div>
                              </div>
                           </div>


                           {DEEPAK_COMPANY_ID.includes(userCompanyId) &&
                              <div className='bank-details'>
                                 <div className="bank-details-table-header">
                                    <div className="bank-details-table-heading"></div>
                                    <div className='bank-table-body'>
                                       <div className="dInBlock wt-100p">
                                          <div className="black" >Have the Firm ever been De-listed/Blacklisted by any PSU/Co-operative/reputed Private Sector Organization<span className='error'>*</span></div>
                                          {!isSeeker ?
                                             <SelectT1
                                                value={([{ label: 'YES', value: 'YES' }, { label: 'NO', value: 'NO' }]).filter(obj => { return data.is_blacklisted === obj.label })}
                                                options={[{ label: 'YES', value: 'YES' }, { label: 'NO', value: 'NO' }]}
                                                onChange={(value) => {
                                                   const { data } = this.state;
                                                   data.is_blacklisted = value.label;
                                                   this.setState({ data });
                                                }}
                                                placeholder={'Select'}
                                                className='bank-new-vendor-dropdown'
                                             />
                                             : <div className="bank-new-vendor-input">{data.is_blacklisted || '-'}</div>}
                                       </div>

                                       <div className="dInBlock wt-100p">
                                          <div className="black" >Any Relationship with DPL Employee<span className='error'>*</span></div>
                                          {!isSeeker ?
                                             <SelectT1
                                                value={([{ label: 'YES', value: 'YES' }, { label: 'NO', value: 'NO' }]).filter(obj => { return data.is_dplEmployee_Related === obj.label })}
                                                options={[{ label: 'YES', value: 'YES' }, { label: 'NO', value: 'NO' }]}
                                                onChange={(value) => {
                                                   const { data } = this.state;
                                                   data.is_dplEmployee_Related = value.label;
                                                   this.setState({ data });
                                                }}
                                                placeholder={'Select'}
                                                className='bank-new-vendor-dropdown'
                                             />
                                             : <div className="bank-new-vendor-input">{data.is_dplEmployee_Related || '-'}</div>}
                                       </div>

                                       <div className="dInBlock wt-100p">
                                          <div className="black">If any other sister concern has applied for this tender<span className='error'>*</span></div>
                                          {!isSeeker ?
                                             <SelectT1
                                                value={([{ label: 'YES', value: 'YES' }, { label: 'NO', value: 'NO' }]).filter(obj => { return data.is_sisterConcern_applied === obj.label })}
                                                options={[{ label: 'YES', value: 'YES' }, { label: 'NO', value: 'NO' }]}
                                                onChange={(value) => {
                                                   const { data } = this.state;
                                                   data.is_sisterConcern_applied = value.label;
                                                   this.setState({ data });
                                                }}
                                                placeholder={'Select'}
                                                className='bank-new-vendor-dropdown'
                                             />
                                             : <div className="bank-new-vendor-input">{data.is_sisterConcern_applied || '-'}</div>}
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           }

                        </Fragment>
                     }

                     {
                        CARGILL_COMPANY_IDS.includes(userCompanyId) &&
                        <Fragment>
                           <div className='company-code-data'>
                              <div className="company-code-table-header">
                                 <div className="company-code-table-heading">Company Code Data</div>
                                 <div className='company-code-table-body'>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                       <p style={{ fontSize: '18px' }}>Accounting Information</p>
                                       <div style={{ display: 'flex', marginTop: '10px' }}>
                                          <div className="dInBlock wt-100p">
                                             <div className="black">Sort Key<span className='error'>*</span></div>
                                             {!isSeeker ?
                                                <input
                                                   type="text"
                                                   placeholder="Sort key"
                                                   className="company-code-new-vendor-input"
                                                   value={data.accountingSortKey}
                                                   onChange={(e) => {
                                                      const { data } = this.state;
                                                      data.accountingSortKey = e.target.value;
                                                      this.setState({ data });
                                                   }}
                                                /> : <div className="company-code-new-vendor-input">{data.accountingSortKey || '-'}</div>}
                                          </div>
                                          <div className="dInBlock wt-100p">
                                             <div className="black">Cash Management Group<span className='error'>*</span></div>
                                             {!isSeeker ?
                                                <Select
                                                   value={(CASHMANAGEMENT || []).filter(obj => { return data.cashMgmtGroup === obj.label })}
                                                   options={CASHMANAGEMENT || []}
                                                   onChange={(value) => {
                                                      const { data } = this.state;
                                                      data.cashMgmtGroup = value.label;
                                                      this.setState({ data });
                                                   }}
                                                   placeholder={'Cash Management Group'}
                                                   className='company-code-new-vendor-dropdown'
                                                // style={{ height: '50px' }}
                                                />
                                                : <div className="company-code-new-vendor-input">{data.cashMgmtGroup || '-'}</div>}
                                          </div>
                                       </div>
                                    </div>

                                    <div style={{ display: 'flex', flexDirection: 'column', marginTop: '20px' }}>
                                       <p style={{ fontSize: '18px' }}>Payment Transition Account</p>
                                       <div style={{ display: 'flex', marginTop: '10px' }}>
                                          <div className="dInBlock wt-45p">
                                             <div className="black">Payment Terms<span className='error'>*</span></div>
                                             {!isSeeker ?
                                                <Select
                                                   value={(this.state.payment_terms_options || []).filter(obj => { return data.paymentTerms === obj.label })}
                                                   options={this.state.payment_terms_options || []}
                                                   onChange={(value) => {
                                                      const { data } = this.state;
                                                      data.paymentTerms = value.label;
                                                      this.setState({ data });
                                                   }}
                                                   placeholder={'Payment Terms'}
                                                   className='company-code-new-vendor-dropdown'
                                                   style={{ height: '50px' }}
                                                />
                                                : <div className="company-code-new-vendor-input">{data.paymentTerms || '-'}</div>}
                                          </div>
                                          <div className="dInBlock wt-25p">
                                             <div className="black">Check Double Inv.<span className='error'>*</span></div>
                                             {!isSeeker ?
                                                <input
                                                   type="text"
                                                   placeholder="Check double inv"
                                                   className="company-code-new-vendor-input"
                                                   value={data.doubleInvCheck}
                                                   onChange={(e) => {
                                                      const { data } = this.state;
                                                      data.doubleInvCheck = e.target.value;
                                                      this.setState({ data });
                                                   }}
                                                /> : <div className="company-code-new-vendor-input">{data.doubleInvCheck || '-'}</div>}
                                          </div>
                                          <div className="dInBlock wt-25p">
                                             <div className="black">Payment Method<span className='error'>*</span></div>
                                             {!isSeeker ?
                                                <Select
                                                   value={(PAYMENT_METHOD).filter(obj => { return data.paymentMethod === obj.label })}
                                                   options={(PAYMENT_METHOD || [])}
                                                   onChange={(value) => {
                                                      const { data } = this.state;
                                                      data.paymentMethod = value.label;
                                                      this.setState({ data });
                                                   }}
                                                   placeholder={'Payment Method'}
                                                   className='company-code-new-vendor-dropdown'
                                                   style={{ height: '50px' }}
                                                />
                                                : <div className="company-code-new-vendor-input">{data.paymentMethod || '-'}</div>}
                                          </div>
                                       </div>
                                    </div>

                                    <div style={{ display: 'flex', flexDirection: 'column', marginTop: '20px' }}>
                                       <p style={{ fontSize: '18px' }}>Corresponding Accounting</p>
                                       <div style={{ display: 'flex', marginTop: '10px' }}>
                                          <div className="dInBlock wt-100p">
                                             <div className="black">Clrk's Internet</div>
                                             {!isSeeker ?
                                                <input
                                                   type="text"
                                                   placeholder="Check double inv"
                                                   className="company-code-new-vendor-input"
                                                   value={data.clrkInternet}
                                                   onChange={(e) => {
                                                      const { data } = this.state;
                                                      data.clrkInternet = e.target.value;
                                                      this.setState({ data });
                                                   }}
                                                /> : <div className="company-code-new-vendor-input">{data.clrkInternet || '-'}</div>}
                                          </div>
                                          <div className="dInBlock wt-100p">
                                             <div className="black">Account Memo(MSMED)</div>
                                             {!isSeeker ?
                                                <Select
                                                   value={(ACCOUNT_MEMO || []).filter(obj => { return data.accountMemo === obj.label })}
                                                   options={(ACCOUNT_MEMO || [])}
                                                   onChange={(value) => {
                                                      const { data } = this.state;
                                                      data.accountMemo = value.label;
                                                      this.setState({ data });
                                                   }}
                                                   placeholder={'Account Memo'}
                                                   className='company-code-new-vendor-dropdown'
                                                   style={{ height: '50px' }}
                                                />
                                                : <div className="company-code-new-vendor-input">{data.accountMemo || '-'}</div>}
                                          </div>
                                       </div>
                                    </div>

                                    <div style={{ display: 'flex', flexDirection: 'column', marginTop: '20px' }}>
                                       <p style={{ fontSize: '18px' }}>Withholding Tax Accounting</p>
                                       <div style={{ display: 'flex', flexDirection: 'column', marginTop: '10px' }}>
                                          <div className="dInBlock wt-100p">
                                             <div className="black">WH Tax Country</div>
                                             {!isSeeker ?
                                                <Select
                                                   value={(country_options || []).filter(obj => { return data.taxWHCountry === obj.label })}
                                                   options={country_options || []}
                                                   onChange={(value) => {
                                                      const { data } = this.state;
                                                      data.taxWHCountry = value.label;
                                                      this.setState({ data });
                                                   }}
                                                   placeholder={'WH Tax Country'}
                                                   className='company-code-new-vendor-dropdown'
                                                />
                                                : <div className="company-code-new-vendor-input">{data.taxWHCountry || '-'}</div>}
                                          </div>

                                          {(data.withholdTax && (data.withholdTax == 'YES')) && (data.WH_Tax_Accounting || []).map((d, index) => {
                                             return (
                                                <div style={{ display: 'flex', marginTop: '20px' }}>
                                                   {/* <div className="dInBlock wt-100p">
                                                      <div className="black">Wth.t.type</div>
                                                      {!isSeeker ?
                                                         <input
                                                            type="text"
                                                            placeholder="Wth.t.type"
                                                            className="company-code-new-vendor-input"
                                                            value={d.taxWHType}
                                                            onChange={(e) => {
                                                               const { data } = this.state;
                                                               data.WH_Tax_Accounting[index].taxWHType = e.target.value;
                                                               this.setState({ data });
                                                            }}
                                                         /> : <div className="company-code-new-vendor-input">{d.taxWHType || '-'}</div>}
                                                   </div> */}

                                                   <div className="dInBlock wt-100p">
                                                      <div className="black">Wth.t.type</div>
                                                      {!isSeeker ? <Select
                                                         value={WTHTTYPE.filter(obj => { return d.taxWHType === obj.label })}
                                                         options={WTHTTYPE}
                                                         onChange={(value) => {
                                                            const { data } = this.state;
                                                            data.WH_Tax_Accounting[index].taxWHType = value.label;
                                                            this.setState({ data });
                                                         }}
                                                         placeholder={'Wth.t.type'}
                                                         className='company-code-new-vendor-dropdown'
                                                         style={{ height: '50px' }}
                                                      />
                                                         : <div className="company-code-new-vendor-input">{d.taxWHType || '-'}</div>

                                                      }
                                                   </div>

                                                   <div className="dInBlock wt-100p">
                                                      <div className="black">Liable</div>
                                                      {!isSeeker ?
                                                         <Select
                                                            value={([{ label: 'YES', value: 'YES' }, { label: 'NO', value: 'NO' }]).filter(obj => { return d.taxLiable === obj.label })}
                                                            options={[{ label: 'YES', value: 'YES' }, { label: 'NO', value: 'NO' }]}
                                                            onChange={(value) => {
                                                               const { data } = this.state;
                                                               data.WH_Tax_Accounting[index].taxLiable = value.label;
                                                               this.setState({ data });
                                                            }}
                                                            placeholder={'Liable'}
                                                            className='company-code-new-vendor-dropdown'
                                                            style={{ height: '50px' }}
                                                         />
                                                         : <div className="company-code-new-vendor-input">{d.taxLiable || '-'}</div>}
                                                   </div>

                                                   <div className="dInBlock wt-100p">
                                                      <div className="black">Wth.t.ty Rec Text</div>
                                                      {!isSeeker ?
                                                         <input
                                                            type="text"
                                                            placeholder="Wth.t.ty Rec Text"
                                                            className="company-code-new-vendor-input"
                                                            value={d.taxRec}
                                                            onChange={(e) => {
                                                               const { data } = this.state;
                                                               data.WH_Tax_Accounting[index].taxRec = e.target.value;
                                                               this.setState({ data });
                                                            }}
                                                         /> : <div className="company-code-new-vendor-input">{d.taxRec || '-'}</div>}
                                                   </div>

                                                   <div style={{ display: 'flex', width: '50%', marginTop: '12px' }}>
                                                      {(index + 1) === data.WH_Tax_Accounting.length ?
                                                         <div className="mb-10 mt-10 fRight">
                                                            {!isSeeker && index !== 0 && <img src={cancelBtn} style={{ width: '20px', cursor: 'pointer' }} onClick={() => this.removeWHfield(index)} />}
                                                            {!isSeeker && <img src={addBtn} style={{ width: '20px', cursor: 'pointer', marginLeft: '10px' }} onClick={() => this.addWHfield()} />}
                                                         </div> :
                                                         <div className="mb-10 mt-10 fRight wt-20p">
                                                            {!isSeeker && index !== 0 && <img src={cancelBtn} style={{ width: '20px', cursor: 'pointer' }} onClick={() => this.removeWHfield(index)} />}
                                                         </div>}
                                                   </div>

                                                </div>
                                             )
                                          })
                                          }
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </Fragment>
                     }

                     {
                        CARGILL_COMPANY_IDS.includes(userCompanyId) &&
                        <Fragment>
                           <div className='bank-details'>
                              <div className="bank-details-table-header">
                                 <div className="bank-details-table-heading">Purchasing Data</div>
                                 <div className='bank-table-body'>
                                    <div className="dInBlock wt-100p">
                                       <div className="black">Schema Group Vendor</div>
                                       {!isSeeker ?
                                          <input
                                             type="text"
                                             placeholder="Schema Group Vendor"
                                             className="bank-new-vendor-input"
                                             value={data.groupVendorSchema}
                                             onChange={(e) => {
                                                const { data } = this.state;
                                                data.groupVendorSchema = e.target.value;
                                                this.setState({ data });
                                             }}
                                          /> : <div className="bank-new-vendor-input">{data.groupVendorSchema || '-'}</div>}
                                    </div>
                                    <div className="dInBlock wt-100p">
                                       <div className="black">GR - Based Vendor Inv.Verify<span className='error'>*</span></div>
                                       {!isSeeker ? <input
                                          type="text"
                                          placeholder="GR - Based Vendor Inv.Verify"
                                          className="bank-new-vendor-input"
                                          value={data.verifyGRBased}
                                          onChange={(e) => {
                                             const { data } = this.state;
                                             data.verifyGRBased = e.target.value;
                                             this.setState({ data });
                                          }}
                                       /> : <div className="bank-new-vendor-input">{data.verifyGRBased || '-'}</div>}
                                    </div>
                                    <div className="dInBlock wt-100p">
                                       <div className="black">Srv. Based Inv Verify<span className='error'>*</span></div>
                                       {!isSeeker ?
                                          <input
                                             type="text"
                                             placeholder="Srv. Based Inv Verify"
                                             className="bank-new-vendor-input"
                                             value={data.verifySrvBased}
                                             onChange={(e) => {
                                                const { data } = this.state;
                                                data.verifySrvBased = e.target.value;
                                                this.setState({ data });
                                             }}
                                          /> : <div className="bank-new-vendor-input">{data.verifySrvBased || '-'}</div>}
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </Fragment>
                     }
{/* 
                     {ABFRL_COMPANY_IDS.includes(userCompanyId) &&
                        <Fragment>
                           <div>
                              {(data.additional_email || []).length > 0 && <div className="mb-10">Additional Mail</div>}
                              {
                                 (data.additional_email || []).map((mail, mId) => {
                                    return <input key={`mail-${mId}`}
                                       type="text"
                                       className="vendor-input mr-15"
                                       placeholder="mail"
                                       value={mail}
                                       disabled={true}
                                    />
                                 })
                              }
                           </div>

                           <div>
                              <div className="mb-10 onboarding-container">Key Logistics Arrangement</div>
                              <div className="headerColor mb-10">Scope</div>
                              <div className="section-item mb-10">
                                 {(vendorForm.scopesList || []).map((scope) => {
                                    return (
                                       <div className="dInBlock mr-20" key={`${scope.name}-${scope.id}`}>
                                          <CustomeCheckBox id={scope}
                                             toggle={this.toggleScope}
                                             selected={(data.scopes || []).filter((obj) => { return obj.id === scope.id }).length > 0}
                                          />
                                          <div className="title"> {scope.name} </div>
                                       </div>
                                    )
                                 })}

                              </div>
                           </div>

                           <div>
                              <div>Modes Of Transportation</div>
                              {
                                 (vendorForm.vendorModes || []).map((mode) => {
                                    return (
                                       <div className="dInBlock mr-20 mwt-120 fs-13" key={`${mode.name}-${mode.id}`}>
                                          <CustomeCheckBox id={mode}
                                             toggle={this.toggleMode}
                                             selected={(data.type || []).filter((obj) => { return obj.id === mode.id }).length > 0}
                                          />
                                          <div className="title"> {mode.name} </div>
                                       </div>
                                    )
                                 })
                              }
                           </div>

                           <div className="mb-20">
                              <div>Services Offered</div>
                              {this.state.allServices.map((service) => {
                                 return (
                                    <div className="dInBlock mr-15 mwt-80" key={`${service.name}-${service.id}`}>
                                       <CustomeCheckBox id={service}
                                          toggle={this.toggleService}
                                          selected={data.services_offered.filter((obj) => { return obj.id === service.id }).length > 0}
                                       />
                                       <div className="title fs-14"> {service.name} </div>
                                    </div>
                                 )
                              })}
                           </div>

                           <div className="mb-20">

                           </div>
                           {
                              !isSeeker &&
                              <div className="mr-20">
                                 <div className="dInBlock mr-20">My Operational Areas</div>
                                 <div className="dInBlock mr-20">
                                    <button className="button-classic" onClick={() => { this.uploadOpArea.click() }}> + Upload Excel </button>
                                    <input type='file' style={{ display: 'none' }} ref={(e) => { this.uploadOpArea = e; }} onChange={this.uploadOperationalArea} onClick={(e) => { e.target.value = null }} />
                                 </div>

                                 <div className="dInBlock">
                                    <button className="button-classic" onClick={() => { this.downloadOperationalArea(0) }}> Download Template </button>
                                    <a className="download-btn" style={{ display: 'none' }} ref={input => this.downloadOp = input} href={this.state.downloadLink} download />
                                 </div>
                              </div>
                           }
                        </Fragment>
                     } */}

                     {(DEEPAK_COMPANY_ID.includes(userCompanyId) || AWL_COMPANY_IDS.includes(userCompanyId) || CARGILL_COMPANY_IDS.includes(userCompanyId) || ABFRL_COMPANY_IDS.includes(userCompanyId)) ?
                        <div className="mb-25 mt-25 fRight" style={{ marginLeft: 'auto' }}>
                           {this.state.verifiedError && <div className='error'>{this.state.verifiedError}</div>}
                           {this.state.errorMsg && <div className='error'>{this.state.errorMsg}</div>}
                           {/* {(!isShowPDF && isSeeker) && <button className='button-classic mr-20 dlpdf' onClick={this.printDocument}>Download PDF</button>} */}
                           <button data-html2canvas-ignore className="mb-25 button-classic mr-20" onClick={() => { history.length > 1 ? history.goBack() : window.close() }}> Cancel </button>
                           {(!isSeeker) && <button data-html2canvas-ignore className="mb-25 button-classic mr-20" onClick={this.savePrimaryDetails}> Save & Next </button>}
                           {(isSeeker) && <button  data-html2canvas-ignore className="mb-25 button-classic mr-20" onClick={() => { this.setState({ isPage: 2 }); window.scrollTo(0, 0); }}> Next </button>}
                        </div> :
                        <div className="mb-25 mt-25 fRight" style={{ marginLeft: 'auto' }}>
                           {/* {this.state.errorMsg && <div className='error'>Please enter all mandatory fields</div>} */}
                           {this.state.errorMsg && <div className='error'>{this.state.errorMsg}</div>}
                           {/* {(!isShowPDF) && <button className='button-classic mr-20 dlpdf' onClick={this.printDocument}>Download PDF</button>} */}
                           {(!isShowPDF) && <button data-html2canvas-ignore className="mb-25 button-classic mr-20" onClick={() => { history.length > 1 ? history.goBack() : window.close() }}> Cancel </button>}
                           {!(hasCPAccess || isVendorCP) && (!isSeeker) && <button data-html2canvas-ignore className="mb-25 button-classic mr-20" onClick={this.savePrimaryDetails}> Save / Next </button>}
                           {(!isShowPDF) && isSeeker && <button data-html2canvas-ignore className="mb-25 button-classic mr-20" onClick={() => { this.setState({ isPage: 2 }); window.scrollTo(0, 0); }}> Next </button>}
                        </div>
                     }


                  </div>}

               {
         //       (isPage === 2 || isShowPDF) && ABFRL_COMPANY_IDS.includes(userCompanyId) &&
         //        <div className="form-page-2  " id="form-page-2">
         //          {/* <div className="header-title"> 2/3 - ONBOARDING FORM </div> */}
         //          <div><b>Adding Rates to Serviceable Location Matrix</b></div>

         //          <div>Zone:
         //             {
         //                Object.keys(vendorForm.vendorDefaultData).length > 0 &&
         //                (vendorForm.vendorDefaultData.zones || []).length > 0 &&
         //                <span>
         //                   {
         //                      (vendorForm.vendorDefaultData.zones || []).map((zone, index) => {
         //                         return <span> {zone} {(index + 1) !== (vendorForm.vendorDefaultData.zones || []).length ? ', ' : null} </span>
         //                      })
         //                   }
         //                </span>
         //             }
         //          </div>
         //          <div>
         //             <div className="dInBlock mr-20 mwt-80 VAlign">From Location:</div>
         //             {['Single', 'Multiple'].map((locationType, index) => {
         //                return (
         //                   <div className="dInBlock mr-20 mwt-80" key={`${locationType}-${index}`}>
         //                      <CustomeCheckBox id={locationType}
         //                         toggle={(id) => {
         //                            const { dataSecond } = this.state;
         //                            dataSecond.operational_location_type = id;
         //                            this.setState({ dataSecond });
         //                         }}
         //                         selected={dataSecond.operational_location_type === locationType}
         //                      />
         //                      <div className="title"> {locationType} </div>
         //                   </div>
         //                )
         //             })}
         //          </div>

         //          {(hasCPAccess || isVendorCP) &&
         //             <Fragment>
         //                <div className="mr-20">
         //                   <div className="dInBlock mr-20">
         //                      <button className="button-classic" onClick={() => { this.uploadOpArea.click() }}> Challenge Price Upload </button>
         //                      <input type='file' style={{ display: 'none' }} ref={(e) => { this.uploadOpArea = e; }} onChange={(e) => { this.uploadOperationalArea(e, 1) }} onClick={(e) => { e.target.value = null }} />
         //                   </div>

         //                   <div className="dInBlock">
         //                      <button className="button-classic" onClick={() => { this.downloadOperationalArea(1) }}> Challenge Price Download </button>
         //                      <a className="download-btn" style={{ display: 'none' }} ref={input => this.downloadOp = input} href={this.state.downloadLink} download />
         //                   </div>
         //                </div>
         //                {vendorForm.onboardDetails && vendorForm.onboardDetails.bid_counter &&
         //                   <div className="error"> Note: Bidding Process (CP and Counter flow) is allowed for {vendorForm.onboardDetails.bid_counter || 0} times. </div>}
         //             </Fragment>
         //          }

         //          <div className="op-table mb-20">
         //             <div className="error">{this.state.error}</div>
         //             <div className="scroll-table">
         //                <div className="divTable mb-20">
         //                   <div className="list-section table-header divTableHeading divTableRow">
         //                      <div className="divTableCell"> From Location </div>
         //                      <div className="divTableCell"> To Location </div>
         //                      <div className="divTableCell"> Branch </div>
         //                      <div className="divTableCell"> To State </div>
         //                      <div className="divTableCell"> To Region </div>
         //                      <div className="divTableCell"> ODA </div>
         //                      <div className="divTableCell"> TAT (days) </div>
         //                      {(hasCPAccess || isSeeker) && <div className="divTableCell"> L1 </div>}
         //                      {(hasCPAccess || isSeeker) && <div className="divTableCell"> L2 </div>}
         //                      {(hasCPAccess || isSeeker) && <div className="divTableCell"> L3 </div>}
         //                      <div className="divTableCell"> Rate/Kg </div>
         //                      <div className="divTableCell"> Counter Rate/Kg </div>
         //                   </div>
         //                   <div className="divTableBody">
         //                      {vendorForm.onboardDetails && (vendorForm.onboardDetails.areas || []).length > 0 &&
         //                         (vendorForm.onboardDetails.areas || []).map((route, idx) => {
         //                            return (
         //                               <div className="divTableRow" key={`${route.area_id}-${idx}`}>
         //                                  <div className="divTableCell"> {route.from} </div>
         //                                  <div className="divTableCell"> {route.to} </div>
         //                                  <div className="divTableCell"> {route.branch} </div>
         //                                  <div className="divTableCell"> {route.to_state} </div>
         //                                  <div className="divTableCell"> {route.to_region} </div>
         //                                  <div className="divTableCell"> {route.oda} </div>
         //                                  <div className="divTableCell"> {route.tat} </div>
         //                                  {(hasCPAccess || isSeeker) && <div className="divTableCell">
         //                                     {
         //                                        (route.vendor_routes || []).length > 0 ?
         //                                           <div>
         //                                              <p className="mb-5">{route.vendor_routes[0].rate}</p>
         //                                              <p className="vendor-name">{route.vendor_routes[0].vendor_name || '-'}</p>
         //                                           </div> : '-'
         //                                     }
         //                                  </div>}
         //                                  {(hasCPAccess || isSeeker) && <div className="divTableCell">
         //                                     {
         //                                        (route.vendor_routes || []).length > 1 ?
         //                                           <div>
         //                                              <p className="mb-5">{route.vendor_routes[1].rate}</p>
         //                                              <p className="vendor-name">{route.vendor_routes[1].vendor_name || '-'}</p>
         //                                           </div> : '-'
         //                                     }
         //                                  </div>}
         //                                  {(hasCPAccess || isSeeker) && <div className="divTableCell">
         //                                     {
         //                                        (route.vendor_routes || []).length > 2 ?
         //                                           <div>
         //                                              <p className="mb-5">{route.vendor_routes[2].rate}</p>
         //                                              <p className="vendor-name">{route.vendor_routes[2].vendor_name || '-'}</p>
         //                                           </div> : '-'
         //                                     }
         //                                  </div>}
         //                                  <div className="divTableCell">
         //                                     <div className={'dInline pr'}> {parseFloat(route.rate).toFixed(3)}
         //                                        {(route.cp_history || []).length > 0 &&
         //                                           <span className="history-icon" style={{ backgroundImage: `url(${View})` }}
         //                                              onClick={() => { this.setState({ showHistory: idx }) }} />
         //                                        }
         //                                        {this.state.showHistory === idx &&
         //                                           <div className="history-block">
         //                                              {(route.cp_history || []).map((history, hId) => {
         //                                                 return (
         //                                                    <div className="mb-10" key={`history-${hId}`}>
         //                                                       {hId === 0 &&
         //                                                          <div className="mb-20">
         //                                                             <span>Bid History</span>
         //                                                             <span className="fRight pointer" onClick={() => { this.setState({ showHistory: null }) }}>X</span>
         //                                                          </div>}
         //                                                       <b className="mr-10">{(hId + 1)}.</b>
         //                                                       <span className="mr-5"> Rate:- {history.rate}</span>
         //                                                       {history.challenge_price && <span> , CP:- {history.challenge_price}</span>}
         //                                                       {history.counter_price && <span> , Counter:- {history.counter_price}</span>}
         //                                                       {history.counter_accepted === false && <span className="error"> , Counter Rejected</span>}
         //                                                       {history.challenge_accepted === false && <span className="error">, CP Rejected</span>}
         //                                                       {history.counter_accepted === true && <span className="success"> , Counter Accepted</span>}
         //                                                       {history.challenge_accepted === true && <span className="success">, CP Accepted</span>}
         //                                                    </div>
         //                                                 )
         //                                              })}
         //                                           </div>
         //                                        }
         //                                     </div>
         //                                     {hasCPAccess && <div className="wt-160">
         //                                        {route.challenge_price && <div>CP:
         //                                           <span className={`${(route.challenge_accepted === true ? 'success' : '')} ${(route.challenge_accepted === false ? 'error' : '')}`}>
         //                                              {route.challenge_price}
         //                                           </span>
         //                                        </div>}
         //                                        {(route.counter_accepted !== true && route.challenge_accepted !== true) &&
         //                                           <Fragment>
         //                                              CP: <input
         //                                                 type="number"
         //                                                 className="dInBlock wt-100 counter-ip"
         //                                                 value={this.state.challenge_price[route.area_id] || ''}
         //                                                 onChange={(e) => {
         //                                                    const { challenge_price } = this.state;
         //                                                    challenge_price[route.area_id] = e.target.value;
         //                                                    this.setState({ challenge_price });
         //                                                 }}
         //                                              />
         //                                           </Fragment>
         //                                        }
         //                                        {this.state.challenge_price[route.area_id] &&
         //                                           <div className="VAlign app-icon" onClick={() => { this.sendChallengePrice(route) }} style={{ backgroundImage: `url(${Save})` }} />
         //                                        }
         //                                     </div>}
         //                                     {
         //                                        isVendorCP && route.challenge_price && //(!route.hasOwnProperty('counter_accepted') || route.counter_accepted === false) &&
         //                                        <div className="wt-160">
         //                                           <span className={`${(route.challenge_accepted === true ? 'success' : '')} ${(route.challenge_accepted === false ? 'error' : '')}`}>CP: {route.challenge_price} </span>
         //                                           {
         //                                              !(route.counter_accepted === true || route.challenge_accepted === true) &&
         //                                              <Fragment>
         //                                                 <div className="VAlign app-icon" onClick={() => { this.challengePriceApproval(route, true, undefined) }} style={{ backgroundImage: `url(${Accept})` }} />
         //                                                 <div className="VAlign rej-icon" onClick={() => { this.challengePriceApproval(route, false, undefined) }} style={{ backgroundImage: `url(${Reject})` }} />
         //                                              </Fragment>
         //                                           }
         //                                        </div>
         //                                     }
         //                                  </div>
         //                                  <div className="divTableCell">
         //                                     <div className={`dInBlock VAlign ${(route.counter_accepted === true ? 'success' : '')} ${(route.counter_accepted === false ? 'error' : '')}`}>{route.counter_price || ''} </div>
         //                                     {hasCPAccess && route.counter_price && !(route.counter_accepted === true || route.challenge_accepted === true) &&
         //                                        <div className="wt-160 dInBlock VAlign">
         //                                           <div className="VAlign app-icon" onClick={() => { this.challengePriceApproval(route, undefined, true) }} style={{ backgroundImage: `url(${Accept})` }} />
         //                                           <div className="VAlign rej-icon" onClick={() => { this.challengePriceApproval(route, undefined, false) }} style={{ backgroundImage: `url(${Reject})` }} />
         //                                        </div>
         //                                     }
         //                                     {isVendorCP && route.challenge_price &&
         //                                        <div className="wt-160">
         //                                           {!(route.counter_accepted === true || route.challenge_accepted === true) &&
         //                                              <Fragment>
         //                                                 <input
         //                                                    type="number"
         //                                                    placeholder="counter"
         //                                                    className="dInBlock wt-100 counter-ip"
         //                                                    value={this.state.counter_price[route.area_id] || ''}
         //                                                    onChange={(e) => {
         //                                                       const { counter_price } = this.state;
         //                                                       counter_price[route.area_id] = e.target.value;
         //                                                       this.setState({ counter_price });
         //                                                    }}
         //                                                 />
         //                                                 {
         //                                                    this.state.counter_price[route.area_id] &&
         //                                                    <div className="VAlign app-icon"
         //                                                       style={{ backgroundImage: `url(${Save})` }}
         //                                                       onClick={() => { this.sendCounterPrice(route) }}
         //                                                    />
         //                                                 }
         //                                              </Fragment>
         //                                           }
         //                                        </div>
         //                                     }

         //                                  </div>
         //                               </div>
         //                            )
         //                         })}
         //                   </div>
         //                </div>
         //             </div>

         //             <div className="tCenter">
         //                {
         //                   // (hasCPAccess || isVendorCP) && (Object.keys(this.state.challenge_price).length>0 || Object.keys(this.state.counter_price).length > 0) &&
         //                   // vendorForm.onboardDetails && (vendorForm.onboardDetails.areas || []).length > 0  &&
         //                   <div className="wt-100 mt-20 mr-25 pointer blue marginAuto"
         //                      onClick={() => {
         //                         if (isVendorCP) {
         //                            this.sendCounterPrice(null, vendorForm.onboardDetails.areas)
         //                         } else {
         //                            this.sendChallengePrice(null, vendorForm.onboardDetails.areas)
         //                         }
         //                      }}
         //                   > Save All </div>
         //                }
         //                {/*<span className="mt-20 mr-25 pointer blue" onClick={this.prevRoutes}> {'< Previous'} </span>*/}
         //                {/*<span className="mt-20 pointer blue" onClick={this.nextRoutes}> {'Next >'} </span>*/}
         //             </div>

         //          </div>

         //          <div>
         //             <div className="mb-25 mt-20">Other Charges</div>
         //             <div className="section-item">
         //                {(dataSecond.charges || []).map((charge, cIndex) => {
         //                   return (
         //                      <div className="mr-20" key={charge.charge_id}>
         //                         <div className="dInBlock wt-250 mr-20 mb-10"> {charge.name} </div>
         //                         <div className="dInBlock">
         //                            <input
         //                               className="vendor-form-input"
         //                               type="text"
         //                               value={charge.value}
         //                               onChange={(e) => {
         //                                  const { dataSecond } = this.state;
         //                                  dataSecond.charges[cIndex].value = e.target.value;
         //                                  this.setState({ dataSecond });
         //                               }}
         //                            />
         //                         </div>
         //                      </div>
         //                   )
         //                })}

         //                {/* <div className="mb-15">
         //    <div className="dInBlock wt-250 mr-20 mb-10"> Contract Commencement Date </div>
         //    <div className="dInBlock">
         //       <MyDatePicker
         //          id="contract_comensment_date"
         //          startDate={dataSecond.contract_comensment_date}
         //          handleChange={(id, date) => {
         //             const { dataSecond } = this.state;
         //             dataSecond.contract_comensment_date = date;
         //             this.setState({ dataSecond });
         //          }}
         //       />
         //    </div>
         // </div> */}

         //                {/* <div className="mb-15">
         //    <div className="dInBlock wt-250 mr-20 mb-10"> Contract Duration </div>
         //    <div className="dInBlock">
         //       <input
         //          className="vendor-form-input"
         //          type="number"
         //          value={dataSecond.contract_duration}
         //          onChange={(e) => {
         //             const { dataSecond } = this.state;
         //             dataSecond.contract_duration = e.target.value;
         //             dataSecond.contract_duration_uom = 'Months';
         //             this.setState({ dataSecond });
         //          }}
         //       />
         //       {dataSecond.contract_duration_uom}
         //    </div>
         // </div> */}

         //                <div className="mb-15">
         //                   <div className="dInBlock wt-250 mr-20 mb-10"> Consideration </div>
         //                   <div className="dInBlock">
         //                      <input
         //                         className="vendor-form-input"
         //                         type="text"
         //                         value={dataSecond.consideration_oda}
         //                         onChange={(e) => {
         //                            const { dataSecond } = this.state;
         //                            dataSecond.consideration_oda = e.target.value;
         //                            this.setState({ dataSecond });
         //                         }}
         //                      />
         //                   </div>
         //                </div>

         //                <div className="mb-15">
         //                   <div className="dInBlock wt-250 mr-20 mb-10"> Termination clause ( {dataSecond.termination[0].termination_type} ) </div>
         //                   <div className="dInBlock">
         //                      <input
         //                         className="vendor-form-input"
         //                         type="number"
         //                         value={dataSecond.termination[0].notice_period}
         //                         onChange={(e) => {
         //                            const { dataSecond } = this.state;
         //                            dataSecond.termination[0].notice_period = e.target.value;
         //                            this.setState({ dataSecond });
         //                         }}
         //                      />
         //                      {dataSecond.termination[0].notice_period_uom}
         //                   </div>
         //                </div>

         //                <div className="mb-15">
         //                   <div className="dInBlock wt-250 mr-20 mb-10"> Termination clause ( {dataSecond.termination[1].termination_type} ) </div>
         //                   <div className="dInBlock">
         //                      <input
         //                         className="vendor-form-input"
         //                         type="number"
         //                         value={dataSecond.termination[1].notice_period}
         //                         onChange={(e) => {
         //                            const { dataSecond } = this.state;
         //                            dataSecond.termination[1].notice_period = e.target.value;
         //                            this.setState({ dataSecond });
         //                         }}
         //                      />
         //                      {dataSecond.termination[1].notice_period_uom}
         //                   </div>
         //                </div>

         //                <div className="mb-15">
         //                   <div className="dInBlock wt-250 mr-20 mb-10 VAlign"> Periodicity of Payment </div>
         //                   <div className="dInBlock">
         //                      {PERIODIC_MANNER.map((period, cIndex) => {
         //                         return (<div className="dInBlock mr-20 mwt-80" key={`${period.name}-${period.id}`}>
         //                            <CustomeCheckBox id={period}
         //                               toggle={(id) => {
         //                                  const { dataSecond } = this.state;
         //                                  dataSecond.payment_periodicity = id.name;
         //                                  this.setState({ dataSecond });
         //                               }}
         //                               selected={dataSecond.payment_periodicity === period.name}
         //                            />
         //                            <div className="title"> {period.name} </div>
         //                         </div>)
         //                      })}
         //                      {dataSecond.payment_periodicity === 'Others' &&
         //                         <input
         //                            type="text"
         //                            placeholder="payment"
         //                            className="vendor-form-input VAlign"
         //                            value={dataSecond.payment_periodicity_value}
         //                            onChange={(e) => {
         //                               const { dataSecond } = this.state;
         //                               dataSecond['payment_periodicity_value'] = e.target.value;
         //                               this.setState({ dataSecond });
         //                            }}
         //                         />
         //                      }
         //                   </div>
         //                </div>

         //                <div className="mb-15">
         //                   <div className="dInBlock wt-250 mr-20 mb-10 VAlign"> Payment Terms </div>
         //                   <div className="dInBlock">

         //                      {PERIODIC_TERMS.map((period, cIndex) => {
         //                         return (<div className="dInBlock mr-20 mwt-120" key={`${period.name}-${period.id}`}>
         //                            <CustomeCheckBox id={period}
         //                               toggle={(id) => {
         //                                  const { dataSecond } = this.state;
         //                                  dataSecond.payment_terms = id.name;
         //                                  this.setState({ dataSecond });
         //                               }}
         //                               selected={dataSecond.payment_terms === period.name}
         //                            />
         //                            <div className="title"> {period.name} </div>

         //                         </div>)
         //                      })}

         //                      {dataSecond.payment_terms === 'Others' &&
         //                         <input
         //                            type="text"
         //                            placeholder="value"
         //                            className="dInBlock vendor-form-input VAlign"
         //                            value={dataSecond.payment_terms_value}
         //                            onChange={(e) => {
         //                               const { dataSecond } = this.state;
         //                               dataSecond['payment_terms_value'] = e.target.value;
         //                               this.setState({ dataSecond });
         //                            }}
         //                         />
         //                      }

         //                   </div>
         //                </div>


         //                <div className="mb-15">
         //                   <div className="dInBlock wt-250 mr-20 mb-10"> Payment Currency </div>
         //                   <div className="dInBlock">
         //                      <input
         //                         className="vendor-form-input"
         //                         type="text"
         //                         value={'INR'} //{dataSecond.payment_currency}
         //                         disabled={true}
         //                         onChange={(e) => {
         //                            const { dataSecond } = this.state;
         //                            dataSecond.payment_currency = e.target.value;
         //                            this.setState({ dataSecond });
         //                         }}
         //                      />
         //                   </div>
         //                </div>


         //                <div className="mb-15">
         //                   <div className="dInBlock wt-250 mr-20 mb-10"> Invoice Discrepancy </div>
         //                   <div className="dInBlock">
         //                      {['10 Days', 'Others'].map((invoice, iIndex) => {
         //                         return (<div className="dInBlock mr-20 mwt-120 VAlign" key={`${invoice}`}>
         //                            <CustomeCheckBox id={invoice}
         //                               toggle={(id) => {
         //                                  const { dataSecond } = this.state;
         //                                  dataSecond.invoice_discrepancy = id;
         //                                  this.setState({ dataSecond });
         //                               }}
         //                               selected={dataSecond.invoice_discrepancy === invoice}
         //                            />
         //                            <div className="title"> {invoice} </div>

         //                         </div>)
         //                      })}
         //                      {dataSecond.invoice_discrepancy === 'Others' &&
         //                         <input
         //                            type="text"
         //                            placeholder="value"
         //                            className="dInBlock vendor-form-input VAlign"
         //                            value={dataSecond.invoice_discrepancy_value}
         //                            onChange={(e) => {
         //                               const { dataSecond } = this.state;
         //                               dataSecond['invoice_discrepancy_value'] = e.target.value;
         //                               this.setState({ dataSecond });
         //                            }}
         //                         />
         //                      }
         //                   </div>
         //                </div>

         //                <div className="mb-15">
         //                   <div className="dInBlock wt-250 mr-20 mb-10 VAlign"> Manner of Payment </div>
         //                   <div className="dInBlock">
         //                      {(PAYMENT_MANNER).map((pay, cIndex) => {
         //                         return (<div className="dInBlock mr-20 mwt-80" key={`${pay.name}-${pay.id}`}>
         //                            <CustomeCheckBox id={pay}
         //                               toggle={this.togglePay}
         //                               selected={dataSecond.payment_manner.filter((obj) => { return obj.id === pay.id }).length > 0}
         //                            />
         //                            <div className="title"> {pay.name} </div>
         //                         </div>)
         //                      })}
         //                   </div>
         //                </div>

         //             </div>
         //          </div>
                  
         //       </div>
               }
               {/* {
                  <div className="form-page-2  " id="form-page-2">
                     {DEEPAK_COMPANY_ID.includes(userCompanyId) ?
                        <div className="mb-25 mt-25 fRight">
                           <button className="mb-25 button-classic mr-20" onClick={() => { this.setState({ isPage: 1 }); window.scrollTo(0, 0); }}> Previous </button>
                           <button className="mb-25 button-classic mr-20" onClick={() => { history.length > 1 ? history.goBack() : window.close() }}> Cancel </button>
                           {(!isSeeker) && <button className="mb-25 button-classic mr-20" onClick={this.saveSecondaryDetails}> Save </button>}
                           {((!isSeeker ? isPageSaved2 : true) || (isSeeker)) && <button className="mb-25 button-classic mr-20" onClick={() => { this.setState({ isPage: 3 }); window.scrollTo(0, 0); }}> Next </button>}
                        </div> :null}
                  </div>
               } */}

               {(isPage === 2 || isShowPDF) && (DEEPAK_COMPANY_ID.includes(userCompanyId) || AWL_COMPANY_IDS.includes(userCompanyId) || CARGILL_COMPANY_IDS.includes(userCompanyId) || ABFRL_COMPANY_IDS.includes(userCompanyId)) &&
                  <div className="form-page form-page-2 " id="form-page-2">
                     {/* <div className="header-title"> 2/3 - ONBOARDING FORM </div> */}

                     {/* <div className="mb-20 onboarding-container">
                           <div className="headerColor mb-20"><span>Operational Areas</span></div>
                           {
                              <div className='wt-100p'>
                                 <div className='table-container'>
                                    {(dataSecond.operationalArea || []).map((area) => {
                                       return (
                                          <Fragment>
                                             <div className='wt-12p'>
                                             <div className="fs-13 checkbox-flex mb-20" >
                                             <CustomeCheckBox id={data.id}
                                                            toggle={this.toggleOperationalArea}
                                                            selected={data.is_enable}
                                                         />    <div className='operational-area-text'>{area.name}</div>
                                                         </div>
                                            
                                                {(area.details || []).map((data) => {
                                                   return (
                                                      <div className="fs-13 checkbox-flex mb-20" key={`${data.state}-${data.id}`}>
                                                         <CustomeCheckBox id={data.id}
                                                            toggle={this.toggleOperationalArea}
                                                            selected={data.is_enable}
                                                         />
                                                         <div className="noBorder area-name"> {data.state} </div>
                                                      </div>
                                                   )
                                                })}
                                             </div>
                                          </Fragment>
                                       )
                                    })}
                                 </div>
                              </div>
                           }
                        </div> */}

                     <div className="mb-20 onboarding-container">
                        <div className="headerColor mb-20"><span>Operational Areas</span>  <span className="error">*</span></div>

                        <div className="flex" style={{ marginLeft: '15px' }} >
                           <CustomeCheckBox
                              //id={area}
                              toggle={() => this.toggleOperationalAreaAll()}
                              selected={dataSecond.operationalArea && dataSecond.operationalArea.length > 0 && !dataSecond.operationalArea.find((data) => data.is_enable === false)}
                           />
                           <div style={{ fontWeight: "bolder", fontSize: '20px' }}>Pan India</div>
                        </div>
                        {
                           <div className='wt-100p mt-15'>
                              <div className='operational_area'>
                                 {(dataSecond.operationalArea || []).sort((a, b) => (a.state < b.state) ? -1 : 0).map((area, idx) => {
                                    return (
                                       <Fragment>
                                          <div className='wt-12p'>
                                             <div className="fs-13 checkbox-flex mb-20" >
                                                <CustomeCheckBox id={area}
                                                   toggle={() => this.toggleOperationalArea(area, idx)}
                                                   selected={area.is_enable}
                                                />    <div className='operational-area-text'>{area.state}</div>
                                             </div>
                                          </div>
                                       </Fragment>
                                    )
                                 })}
                              </div>
                           </div>
                        }
                     </div>

                     <div className="mb-20 mt-20 onboarding-container">
                        <div className="headerColor mb-20">Services Offered  <span className="error">*</span></div>
                        <div style={{ display: 'flex', marginLeft: '25px' }}>
                           <CustomeCheckBox
                              //id={service}
                              selected={this.state.toggleAllServicesCheckBox || !dataSecond.serviceProvided.find((service) => service.is_enable === false)}
                              toggle={() => { this.toggleAllServices() }}
                           />
                           <div style={{ fontWeight: '800', fontSize: '20px' }}>All</div>
                        </div>
                        <div style={{ display: 'flex', width: '98%', flexWrap: 'wrap', marginLeft: '25px' }}>
                           {(dataSecond.serviceProvided || []).map((service, serviceIdx) => {
                              // if (service.company_id && service.company_id === userCompanyId) {
                              return (
                                 <div className='service-offered-container'>
                                    <div className="dInBlock" style={{ display: 'flex' }} key={`${service.name}-${service.som_id}`}>
                                       <CustomeCheckBox id={service}
                                          selected={dataSecond.serviceProvided[serviceIdx].is_enable || false}
                                          toggle={() => { this.toggleServiceCheckbox(serviceIdx) }}
                                       />
                                       <div style={{ fontWeight: '800', fontSize: '20px' }}>{service.name}</div>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: "column", marginTop: '10px', marginLeft: '5px', padding: "10px 0" }}>
                                       {(service.details || []).map((detail, detailIdx) => {
                                          return (
                                             <div className="dInBlock flex mt-10" key={`${detail.type}-${detail.som_id}`}>
                                                <CustomeCheckBox id={detail}
                                                   selected={dataSecond.serviceProvided[serviceIdx].details[detailIdx].is_enable}
                                                   toggle={() => this.toggleServiceDetail(serviceIdx, detailIdx)}
                                                />
                                                <div className="title"> {detail.type || detail.name} </div>
                                             </div>
                                          )
                                       })}
                                    </div>
                                 </div>
                              )
                              // }
                           })}
                        </div>
                     </div>

                     {/* <div className="mb-20 mt-60 onboarding-container">
                        <div className="headerColor mb-20">Services Offered</div>
                        {
                           <div className='wt-100p'>
                              <table>
                                 {(dataSecond.allServiceOffered || []).map((service) => {
                                    if (AWL_COMPANY_IDS.includes(userCompanyId) && service.company_id) {
                                       return (
                                          <Fragment>
                                             {service.name !== 'All' &&
                                                <th className='wt-20p borderLight'>
                                                   <div className='bg-light ht-65 fMiddle'>{service.name}</div>
                                                   {(service.details || []).map((load) => {
                                                      return (
                                                         <tr className=" fs-13" key={`${load.type}-${load.id}`}>
                                                            <CustomeCheckBox id={load.id}
                                                               toggle={this.toggleServiceOffered}
                                                               selected={load.is_enable}

                                                            />
                                                            <td className="noBorder fTop tLeft">
                                                               {load.name}

                                                               {load.is_enable === true && load.name === "Others" &&
                                                                  <input
                                                                     type="text"
                                                                     placeholder="Describe"
                                                                     className="vendor-input mb-20"
                                                                     //value={data.no_of_fleets_attached}
                                                                     onChange={(e) => {
                                                                        const { dataSecond } = this.state;
                                                                        (dataSecond.allServiceOffered || []).map((data) => {
                                                                           (data.details || []).map((service) => {
                                                                              if (service.id === load.id) {
                                                                                 service.others = e.target.value
                                                                              }
                                                                           })
                                                                        })
                                                                        this.setState({ dataSecond });
                                                                     }}
                                                                  />
                                                               }
                                                            </td>
                                                         </tr>
                                                      )
                                                   }
                                                   )}

                                                </th>}
                                          </Fragment>
                                       )
                                    }
                                    else if (dataSecond.allServiceOffered.filter((obj) => { return obj.som_id === service.som_id }).length > 0 && !AWL_COMPANY_IDS.includes(userCompanyId)) {
                                       return (
                                          <Fragment>
                                             {service.name !== 'All' &&
                                                <th className='wt-20p  borderLight'>
                                                   <div className='bg-light ht-65 fMiddle'>{service.name}</div>
                                                   {(service.details || []).map((load) => {
                                                      return (
                                                         <tr className="tLeft mr-20 fs-13" key={`${load.type}-${load.id}`}>
                                                            <CustomeCheckBox id={load.id}
                                                               toggle={this.toggleServiceOffered}
                                                               selected={load.is_enable}

                                                            />
                                                            <td className="noBorder fTop tLeft"> {load.type} </td>
                                                         </tr>
                                                      )
                                                   }
                                                   )}

                                                </th>}
                                          </Fragment>
                                       )
                                    }
                                 })}
                              </table>
                           </div>
                        }
                     </div> */}
                     {/* <div className="multiSelect mb-20  onboarding-container" style={{ height: 'auto' }}>
                        <div className="input-text-wrapper">
                           <div className="headerColor mb-20">Segments  <span className="error">*</span></div>
                           <div className='product-handling-capability'>
                              {(dataSecond.Segments || []).map((segment, idx) => {
                                 return (
                                    <div style={{ display: 'flex', width: '25%', marginTop: '10px' }}>
                                       <CustomeCheckBox
                                          id={segment}
                                          toggle={() => this.handleChangeForSegment(segment, idx)}
                                          selected={segment.is_enable}
                                       />
                                       {segment.name}
                                    </div>
                                 )
                              })}
                           </div>
                        </div>
                     </div> */}
                     <div className="multiSelect mb-20 onboarding-container" style={{ height: 'auto' }}>
  <div className="input-text-wrapper">
    <div className="headerColor mb-20">Segments <span className="error">*</span></div>
    <div className='product-handling-capability'>
      {/* Render the "Select All" checkbox */}
      <div style={{ display: 'flex', width: '25%', marginTop: '10px' }}>
        <CustomeCheckBox
          id="Select All"
          toggle={() => this.handleChangeForSegment('Select All')}
          selected={
            dataSecond.Segments.every((segment) => segment.is_enable) &&
            dataSecond.Segments.length > 0
          }
        />
        Select All
      </div>
      
      {/* Render individual segment checkboxes */}
      {dataSecond.Segments.map((segment, idx) => {
        return (
          <div key={segment.id} style={{ display: 'flex', width: '25%', marginTop: '10px' }}>
            <CustomeCheckBox
              id={segment}
              toggle={() => this.handleChangeForSegment(segment, idx)}
              selected={segment.is_enable}
            />
            {segment.name}
          </div>
        );
      })}
    </div>
  </div>
</div>


                     <div className="multiSelect mb-20  onboarding-container" style={{ height: 'auto' }} { ...(dataSecond.vehicle_details.length >6? {'data-html2canvas-ignore': true } : {}) }>
                        <div className="input-text-wrapper">
                           <div className="vehicle-owned mb-20 flex">
                              <span style={{ width: '30%' }}>Owned Vehicle Details {AWL_COMPANY_IDS.includes(userCompanyId) && <span className="error">*</span>}</span>
                              <div className="flex">
                                 {!isSeeker && <button className="button-classic" onClick={this.downloadVehicleDetails}> Download Template </button>}
                                 <a className="download-btn" style={{ display: 'none' }} ref={input => this.downloadBtn = input} href={this.state.downloadLink} download />
                                 {!isSeeker && <button className="button-classic mr-20" onClick={() => { this.uploadGSTDetials.click() }}> Upload </button>}
                                 <input type='file' style={{ display: 'none' }} ref={(e) => { this.uploadGSTDetials = e; }} onChange={(e) => { this.uploadVehicleDetails(e) }} onClick={(e) => { e.target.value = null }} />
                              </div>
                           </div>

                           <div className='vehicle-details'>
                              <div style={{ marginLeft: "15px" }}>
                                 <table className="wt-80p mb-25" style={{ float: 'left' }}>
                                    <thead>
                                       <th className="tCenter borderLight black bg-light">Vehicle Registration</th>
                                       <th className="tCenter borderLight black bg-light">Vehicle Type</th>
                                       <th className="tCenter borderLight black bg-light">Body Type</th>
                                       <th className="tCenter borderLight black bg-light">Fuel Type</th>
                                       <th className="tCenter borderLight black bg-light">Fleet Type</th>
                                    </thead>
                                    <tbody>
                                       {(dataSecond.vehicle_details || []).map((d) => {
                                          return (
                                             <tr>
                                                <td className="tCenter borderLight black">{d.vehicle_registration}</td>
                                                <td className="tCenter borderLight black">{d.type_of_vehicle}</td>
                                                <td className="tCenter borderLight black">{d.body_type}</td>
                                                <td className="tCenter borderLight black">{d.fuel_type}</td>
                                                <td className="tCenter borderLight black">{d.fleet_type}</td>
                                             </tr>
                                          )
                                       })}
                                    </tbody>
                                 </table>
                              </div>
                           </div>
                        </div>
                     </div>

                     {DEEPAK_COMPANY_ID.includes(userCompanyId) &&
                        <div className="multiSelect mb-20  onboarding-container" style={{ height: 'auto' }}>
                           <div className="input-text-wrapper">
                              <div className="headerColor mb-20">Product Handling Capability
                                          {!isSeeker ? 
                                          <div className='mt-15'> Chemical Name:<input
                                             type="text"
                                             placeholder="Chemical name"
                                             className="companyy-details-vendor-input ml-20"
                                             value={dataSecond.chemical_product_name }
                                             onChange={(e) => {
                                                const { dadataSecond } = this.state;
                                                dataSecond.chemical_product_name  = e.target.value;
                                                this.setState({ dataSecond });
                                             }}
                                          /></div>
                                          :<div><p className='fs-15'>Chemical Name:</p><div className="companyy-details-vendor-input ">{dataSecond.chemical_product_name || '-'}</div></div>}
                              </div>
                              <div className='product-handling-capability'>
                                 {(productList || []).map((product, idx) => {
                                    return (
                                       <div style={{ display: 'flex', width: '25%', marginTop: '10px' }}>
                                          <CustomeCheckBox
                                             id={product}
                                             toggle={() => this.handleChangeForProductHandling(product)}
                                             selected={(dataSecond.product_handling_capability || []).includes(product.materialName)}
                                          />
                                          {product.materialName}
                                       </div>
                                    )
                                 })}
                              </div>
                           </div>
                        </div>}

                     {!(AWL_COMPANY_IDS.includes(userCompanyId) || CARGILL_COMPANY_IDS.includes(userCompanyId) || ABFRL_COMPANY_IDS.includes(userCompanyId) ) &&
                        <div className="wt-100p mt-20 mb-20 onboarding-container">
                           <div className="headerColor ">Tanker Details</div>
                           <table>
                              <thead>
                                 <th className="wt-20p tCenter borderLight black bg-light"> Tanker Type </th>
                                 <th className="wt-20p tCenter borderLight black bg-light"> Tanker Capacity </th>
                                 <th className="wt-20p tCenter borderLight black bg-light"> Own Fleet </th>
                                 <th className="wt-20p tCenter borderLight black bg-light"> Attached Fleet </th>
                                 <th className="wt-20p tCenter borderLight black bg-light"> Specification </th>
                                 {!isSeeker && <th className="wt-20p tCenter borderLight black bg-light"> Action </th>}
                              </thead>
                              <tbody>
                                 {(dataSecond.tankerDetails || []).map((tanker, index) => {
                                    return (
                                       <tr>
                                          <td className="wt-20p tCenter borderLight">
                                             {!isSeeker ? <input
                                                className="vendor-form-input"
                                                type="text"
                                                placeholder="ex: SS Tanker"
                                                value={tanker.tanker_size} //{dataSecond.payment_currency}
                                                onChange={(e) => {
                                                   const { dataSecond } = this.state;
                                                   dataSecond.tankerDetails[index].tanker_size = e.target.value;
                                                   this.setState({ dataSecond });
                                                }}
                                             /> : <div className="black mb-20 mt-10">{tanker.tanker_size}</div>}
                                          </td>
                                          <td className="wt-20p tCenter borderLight">
                                             {!isSeeker ? <input
                                                className="vendor-form-input"
                                                type="text"
                                                placeholder="ex: 18 KL"
                                                value={tanker.tanker_type} //{dataSecond.payment_currency}
                                                onChange={(e) => {
                                                   const { dataSecond } = this.state;
                                                   dataSecond.tankerDetails[index].tanker_type = e.target.value;
                                                   this.setState({ dataSecond });
                                                }}
                                             /> : <div className="black mb-20 mt-10">{tanker.tanker_type}</div>}
                                          </td>

                                          <td className="wt-20p tCenter borderLight">
                                             {!isSeeker ? <input
                                                className="vendor-form-input"
                                                type="text"
                                                placeholder="ex: 5"
                                                value={tanker.own_fleet} //{dataSecond.payment_currency}
                                                onChange={(e) => {
                                                   const { dataSecond } = this.state;
                                                   dataSecond.tankerDetails[index].own_fleet = e.target.value;
                                                   this.setState({ dataSecond });
                                                }}
                                             /> : <div className="black mb-20 mt-10">{tanker.own_fleet}</div>}
                                          </td>
                                          <td className="wt-20p tCenter borderLight">
                                             {!isSeeker ? <input
                                                className="vendor-form-input"
                                                type="text"
                                                placeholder="ex: 2"
                                                value={tanker.attached_fleet} //{dataSecond.payment_currency}
                                                onChange={(e) => {
                                                   const { dataSecond } = this.state;
                                                   dataSecond.tankerDetails[index].attached_fleet = e.target.value;
                                                   this.setState({ dataSecond });
                                                }}
                                             /> : <div className="black mb-20 mt-10">{tanker.attached_fleet}</div>}
                                          </td>
                                          <td className="wt-20p tCenter borderLight">
                                             {!isSeeker ? <input
                                                className="vendor-form-input"
                                                type="text"
                                                placeholder="ex: Jacketed"
                                                value={tanker.specification} //{dataSecond.payment_currency}
                                                onChange={(e) => {
                                                   const { dataSecond } = this.state;
                                                   dataSecond.tankerDetails[index].specification = e.target.value;
                                                   this.setState({ dataSecond });
                                                }}
                                             /> : <div className="black mb-20 mt-10">{tanker.specification}</div>}
                                          </td>
                                          {!isSeeker && <td className="wt-20p tCenter borderLight">
                                             {(index + 1) === dataSecond.tankerDetails.length ?
                                                <Fragment>
                                                   {index !== 0 && <div className="mb-5 mr-10 curP danger-color" onClick={() => this.onTankerCancel(index)}> Cancel </div>}
                                                   <div className="mb-5 mr-10 curP theme" onClick={() => this.addTanker()}> Add </div>
                                                </Fragment> :
                                                <Fragment>
                                                   {index !== 0 && <div className="mb-10 mr-10 curP danger-color" onClick={() => this.onTankerCancel(index)}> Cancel </div>}
                                                </Fragment>
                                             }
                                          </td>}
                                       </tr>
                                    )
                                 })}


                              </tbody>
                           </table>
                        </div>}

                     {(DEEPAK_COMPANY_ID.includes(userCompanyId) || AWL_COMPANY_IDS.includes(userCompanyId) || CARGILL_COMPANY_IDS.includes(userCompanyId) || ABFRL_COMPANY_IDS.includes(userCompanyId) ) ?
                        <div className="mb-25 mt-25 fRight">
                           {this.state.errorMsgSec && <div className='error'>{this.state.errorMsgSec}</div>}
                           {/* {(!isShowPDF&& isSeeker && ABFRL_COMPANY_IDS.includes(userCompanyId)) && <button className='button-classic mr-20 dlpdf' onClick={this.printDocument}>Download PDF</button>} */}
                           <button data-html2canvas-ignore className="mb-25 button-classic mr-20" onClick={() => { this.setState({ isPage: 1 }); window.scrollTo(0, 0); }}> Previous </button>
                           <button data-html2canvas-ignore className="mb-25 button-classic mr-20" onClick={() => { history.length > 1 ? history.goBack() : window.close() }}> Cancel </button>
                           {(!isSeeker) && <button data-html2canvas-ignore className="mb-25 button-classic mr-20" onClick={this.saveSecondaryDetails}> Save & Next </button>}
                           {(isSeeker) && <button data-html2canvas-ignore className="mb-25 button-classic mr-20" onClick={() => { this.setState({ isPage: 3 }); window.scrollTo(0, 0); }}> Next </button>}
                        </div> :
                        <div className="mb-25 mt-25 fRight">
                           {this.state.errorMsgSec && <div className='error'>{this.state.errorMsgSec}</div>}
                        
                           <button data-html2canvas-ignore className="mb-25 button-classic mr-20" onClick={() => { this.setState({ isPage: 1 }); window.scrollTo(0, 0); }}> Previous </button>
                           <button data-html2canvas-ignore className="mb-25 button-classic mr-20" onClick={() => { history.length > 1 ? history.goBack() : window.close() }}> Cancel </button>
                           {!(hasCPAccess || isVendorCP) && <button data-html2canvas-ignore className="mb-25 button-classic mr-20" onClick={this.saveSecondaryDetails}> Save & Next </button>}
                           {isSeeker && <button data-html2canvas-ignore className="mb-25 button-classic mr-20" onClick={() => { this.setState({ isPage: 3 }); window.scrollTo(0, 0); }}> Next</button>}
                        </div>
                     }
                  </div>}

               {(isPage === 3 || isShowPDF) &&
                  <div className="form-page form-page-1 " id="form-page-3">
                     <div className='onboarding-container mb-20' data-html2canvas-ignore >
                        <div className="Table-header">
                           <div className="headerColor"> Documents</div>
                           <div>
                              <div className='first-table-body-company'>
                                 {!isSeeker &&
                                    <div className="upload-docs">
                                       {!ABFRL_COMPANY_IDS.includes(userCompanyId)&& <div className="dInBlock mr-20 mb-20">
                                          <button className="button-classic-doc" onClick={() => { this.uploadDeclaration.click() }}> + Compliance Declaration Form</button>
                                          <input type='file' accept= {isDPL ? '.pdf, .jpeg, .jpg, .png' : '.pdf'} style={{ display: 'none' }} ref={(e) => { this.uploadDeclaration = e; }} onChange={e => this.multipleFilesUpload(e, "complianceDeclarationDocfile")} onClick={(e) => { e.target.value = null }} />
                                       </div>}
                                       <div className="dInBlock mr-20 mb-20">
                                          <button className="button-classic-doc" onClick={() => { this.uploadGST.click() }}> + GST Certificates {ABFRL_COMPANY_IDS.includes(userCompanyId)&&<span className="error">*</span>}</button>
                                          <input type='file' accept= {isDPL ? '.pdf, .jpeg, .jpg, .png' : '.pdf'} style={{ display: 'none' }} ref={(e) => { this.uploadGST = e; }} onChange={e => this.multipleFilesUpload(e, "gstCertificatesDocfile")} onClick={(e) => { e.target.value = null }} />
                                       </div>
                                       <div className="dInBlock mr-20 mb-20">
                                          <button className="button-classic-doc" onClick={() => { this.uploadCheque.click() }}> + Bank & cancelled Cheques <span className="error">*</span></button>
                                          <input type='file' accept= {isDPL ? '.pdf, .jpeg, .jpg, .png' : '.pdf'} style={{ display: 'none' }} ref={(e) => { this.uploadCheque = e; }} onChange={e => this.multipleFilesUpload(e, "bankDetailsAndCancelledChecqueDocfile")} onClick={(e) => { e.target.value = null }} />
                                       </div>

                                       <div className="dInBlock mr-20 mb-20">
                                          <button className="button-classic-doc" onClick={() => { this.uploadCarrierCertificate.click() }}>+ Carrier Certificate  {(AWL_COMPANY_IDS.includes(userCompanyId) || CARGILL_COMPANY_IDS.includes(userCompanyId)|| ABFRL_COMPANY_IDS.includes(userCompanyId)) && <span className="error">*</span>}</button>
                                          <input type='file' accept={isDPL ? '.pdf, .jpeg, .jpg, .png' : '.pdf'} style={{ display: 'none' }} ref={(e) => { this.uploadCarrierCertificate = e; }} onChange={e => this.multipleFilesUpload(e, "carrierCertificate")} onClick={(e) => { e.target.value = null }} />
                                       </div>

                                       <div className="dInBlock mr-20 mb-20">
                                          <button className="button-classic-doc" onClick={() => { this.uploadTradeLicence.click() }}>+ Trade Licence {(AWL_COMPANY_IDS.includes(userCompanyId) || CARGILL_COMPANY_IDS.includes(userCompanyId)) && <span className="error">*</span>} </button>
                                          <input type='file' accept={isDPL ? '.pdf, .jpeg, .jpg, .png' : '.pdf'} style={{ display: 'none' }} ref={(e) => { this.uploadTradeLicence = e; }} onChange={e => this.multipleFilesUpload(e, "tradeLicence")} onClick={(e) => { e.target.value = null }} />
                                       </div>

                                       <div className="dInBlock mr-20 mb-20">
                                          <button className="button-classic-doc" onClick={() => { this.uploadFssiCertificate.click() }}>+ FSSAI Certificate {(AWL_COMPANY_IDS.includes(userCompanyId) || CARGILL_COMPANY_IDS.includes(userCompanyId)) && <span className="error">*</span>} </button>
                                          <input type='file' accept={isDPL ? '.pdf, .jpeg, .jpg, .png' : '.pdf'} style={{ display: 'none' }} ref={(e) => { this.uploadFssiCertificate = e; }} onChange={e => this.multipleFilesUpload(e, "fssiCertificate")} onClick={(e) => { e.target.value = null }} />
                                       </div>

                                       <div className="dInBlock mr-20 mb-20">
                                          <button className="button-classic-doc" onClick={() => { this.uploadRcBookDetails.click() }}>+ RC book details upload  {(totalCount > 0 && !DEEPAK_COMPANY_ID.includes(userCompanyId)) && <sup><span className="error">({totalCount})</span></sup>}</button>
                                          <input type='file' accept={isDPL ? '.pdf, .jpeg, .jpg, .png' : '.pdf'} style={{ display: 'none' }} ref={(e) => { this.uploadRcBookDetails = e; }} onChange={e => this.multipleFilesUpload(e, "rcBookDetailsUpload")} onClick={(e) => { e.target.value = null }} />
                                       </div>

                                       <div className="dInBlock mr-20 mb-20">
                                          <button className="button-classic-doc" onClick={() => { this.uploadMsmeCertificate.click() }}>+ MSME Certificate {(AWL_COMPANY_IDS.includes(userCompanyId) || CARGILL_COMPANY_IDS.includes(userCompanyId) || ABFRL_COMPANY_IDS.includes(userCompanyId)||(isDPL && data.is_msme_registered)) && <span className="error">*</span>}</button>
                                          <input type='file' accept={isDPL ? '.pdf, .jpeg, .jpg, .png' : '.pdf'} style={{ display: 'none' }} ref={(e) => { this.uploadMsmeCertificate = e; }} onChange={e => this.multipleFilesUpload(e, "msmeCertificate")} onClick={(e) => { e.target.value = null }} />
                                       </div>

                                       <div className="dInBlock mr-20 mb-20">
                                          <button className="button-classic-doc" onClick={() => { this.uploadPan.click() }}> + PAN Card <span className="error">*</span></button>
                                          <input type='file' accept={isDPL ? '.pdf, .jpeg, .jpg, .png' : '.pdf'} style={{ display: 'none' }} ref={(e) => { this.uploadPan = e; }} onChange={e => this.multipleFilesUpload(e, "pancardDocfile")} onClick={(e) => { e.target.value = null }} />
                                       </div>
                                       <div className="dInBlock mr-20 mb-20">
                                          <button className="button-classic-doc" onClick={() => { this.uploadMemo.click() }}> + Memorandum of association {ABFRL_COMPANY_IDS.includes(userCompanyId) && <span className="error">*</span>} </button>
                                          <input type='file' accept={isDPL ? '.pdf, .jpeg, .jpg, .png' : '.pdf'} style={{ display: 'none' }} ref={(e) => { this.uploadMemo = e; }} onChange={e => this.multipleFilesUpload(e, "memorandamOfAssociationDocfile")} onClick={(e) => { e.target.value = null }} />
                                       </div>
                                       <div className="dInBlock mr-20 mb-20">
                                          <button className="button-classic-doc" onClick={() => { this.uploadArticle.click() }}> + Article of association  {ABFRL_COMPANY_IDS.includes(userCompanyId) && <span className="error">*</span>}</button>
                                          <input type='file' accept={isDPL ? '.pdf, .jpeg, .jpg, .png' : '.pdf'} style={{ display: 'none' }} ref={(e) => { this.uploadArticle = e; }} onChange={e => this.multipleFilesUpload(e, "articleForAssociationDocfile")} onClick={(e) => { e.target.value = null }} />
                                       </div>
                                       {ABFRL_COMPANY_IDS.includes(userCompanyId) && <div className="dInBlock mr-20 mb-20">
                                          <button className="button-classic-doc" onClick={() => { this.EPaymentNotification.click() }}> + E-Payment Notification <span className="error">*</span></button>
                                          <input type='file' accept='application/pdf' style={{ display: 'none' }} ref={(e) => { this.EPaymentNotification = e; }} onChange={e => this.multipleFilesUpload(e, "E-PaymentNotification")} onClick={(e) => { e.target.value = null }} />
                                       </div>}
                                       {/* {ABFRL_COMPANY_IDS.includes(userCompanyId) && <div className="dInBlock mr-20 mb-20">
                                          <button className="button-classic-doc" onClick={() => { this.ComplianceForm.click() }}> + Compliance form - declaration <span className="error">*</span></button>
                                          <input type='file' accept='application/pdf' style={{ display: 'none' }} ref={(e) => { this.ComplianceForm = e; }} onChange={e => this.multipleFilesUpload(e, "ComplianceForm")} onClick={(e) => { e.target.value = null }} />
                                       </div>} */}
                                       {ABFRL_COMPANY_IDS.includes(userCompanyId) && <div className="dInBlock mr-20 mb-20">
                                          <button className="button-classic-doc" onClick={() => { this.BalanceSheet.click() }}> + Balance sheet <span className="error">*</span></button>
                                          <input type='file' accept='application/pdf' style={{ display: 'none' }} ref={(e) => { this.BalanceSheet = e; }} onChange={e => this.multipleFilesUpload(e, "BalanceSheet")} onClick={(e) => { e.target.value = null }} />
                                       </div>}
                                       {ABFRL_COMPANY_IDS.includes(userCompanyId) && <div className="dInBlock mr-20 mb-20">
                                          <button className="button-classic-doc" onClick={() => { this.carriageAct.click() }}> + Carriage Act 2007 Certificate <span className="error">*</span></button>
                                          <input type='file' accept='application/pdf' style={{ display: 'none' }} ref={(e) => { this.carriageAct = e; }} onChange={e => this.multipleFilesUpload(e, "carriageAct")} onClick={(e) => { e.target.value = null }} />
                                       </div>}
                                       {/* {ABFRL_COMPANY_IDS.includes(userCompanyId) && <div className="dInBlock mr-20 mb-20">
                                          <button className="button-classic-doc" onClick={() => { this.EIRN.click() }}> + E-IRN Invoice Declaration  <span className="error">*</span></button>
                                          <input type='file' accept='application/pdf' style={{ display: 'none' }} ref={(e) => { this.EIRN = e; }} onChange={e => this.multipleFilesUpload(e, "E-IRN")} onClick={(e) => { e.target.value = null }} />
                                       </div>} */}
                                       {DEEPAK_COMPANY_ID.includes(userCompanyId) && <div className="dInBlock mr-20 mb-20">
                                          <button className="button-classic-doc" onClick={() => { this.uploadAddress.click() }}> + Address Proof <span className="error">*</span></button>
                                          <input type='file' accept={isDPL ? '.pdf, .jpeg, .jpg, .png' : '.pdf'} style={{ display: 'none' }} ref={(e) => { this.uploadAddress = e; }} onChange={e => this.multipleFilesUpload(e, "addressProof")} onClick={(e) => { e.target.value = null }} />
                                       </div>}
                                       {DEEPAK_COMPANY_ID.includes(userCompanyId) && <div className="dInBlock mr-20 mb-20">
                                          <button className="button-classic-doc" onClick={() => { this.uploadVisitingCard.click() }}> + Visiting Card </button>
                                          <input type='file' accept={isDPL ? '.pdf, .jpeg, .jpg, .png' : '.pdf'} style={{ display: 'none' }} ref={(e) => { this.uploadVisitingCard = e; }} onChange={e => this.multipleFilesUpload(e, "visitingCard")} onClick={(e) => { e.target.value = null }} />
                                       </div>}
                                       {DEEPAK_COMPANY_ID.includes(userCompanyId) && <div className="dInBlock mr-20 mb-20">
                                          <button className="button-classic-doc" onClick={() => { this.uploadBankVerificationLetter.click() }}> + Bank Verification Letter<span className="error">*</span></button>
                                          <input type='file' accept={isDPL ? '.pdf, .jpeg, .jpg, .png' : '.pdf'} style={{ display: 'none' }} ref={(e) => { this.uploadBankVerificationLetter = e; }} onChange={e => this.multipleFilesUpload(e, "bankVerificationLetter")} onClick={(e) => { e.target.value = null }} />
                                       </div>}
                                       {DEEPAK_COMPANY_ID.includes(userCompanyId) && <div className="dInBlock mr-20 mb-20">
                                          <button className="button-classic-doc" onClick={() => { this.uploadCertificationOfIncorporation.click() }}> + Certificaton of Incorporation </button>
                                          <input type='file' accept={isDPL ? '.pdf, .jpeg, .jpg, .png' : '.pdf'} style={{ display: 'none' }} ref={(e) => { this.uploadCertificationOfIncorporation = e; }} onChange={e => this.multipleFilesUpload(e, "certificationOfIncorporation")} onClick={(e) => { e.target.value = null }} />
                                       </div>}
                                       <div className="dInBlock mr-20 mb-20">
                                          <button className="button-classic-doc" onClick={() => { this.uploadAadharCardCode.click() }}> + Aadhar Card Code/Profile {AWL_COMPANY_IDS.includes(userCompanyId) && data.company_type == 'Proprietorship' && <span className="error">*</span>}</button>
                                          <input type='file' accept={isDPL ? '.pdf, .jpeg, .jpg, .png' : '.pdf'} style={{ display: 'none' }} ref={(e) => { this.uploadAadharCardCode = e; }} onChange={e => this.multipleFilesUpload(e, "aadharCardCode")} onClick={(e) => { e.target.value = null }} />
                                       </div>

                                       {AWL_COMPANY_IDS.includes(userCompanyId) && <div className="dInBlock mr-20 mb-20">
                                          <button className="button-classic-doc" onClick={() => { this.uploadAgreement.click() }}> + Upload Agreement {AWL_COMPANY_IDS.includes(userCompanyId) && <span className="error">*</span>}</button>
                                          <input type='file' accept='application/pdf' style={{ display: 'none' }} ref={(e) => { this.uploadAgreement = e; }} onChange={e => this.multipleFilesUpload(e, "uploadAgreement")} onClick={(e) => { e.target.value = null }} />
                                       </div>}

                                       {CARGILL_COMPANY_IDS.includes(userCompanyId) &&
                                          <div className="dInBlock mr-20 mb-20">
                                             <button className="button-classic-doc" onClick={() => { this.uploadLetterHead.click() }}> + Invoice, Letterhead or Statutory document <span className="error">*</span></button>
                                             <input type='file' accept='application/pdf' style={{ display: 'none' }} ref={(e) => { this.uploadLetterHead = e; }} onChange={e => this.multipleFilesUpload(e, "letterHead")} onClick={(e) => { e.target.value = null }} />
                                          </div>
                                       }

                                       {CARGILL_COMPANY_IDS.includes(userCompanyId) &&
                                          <div className="dInBlock mr-20 mb-20">
                                             <button className="button-classic-doc" onClick={() => { this.uploadUdyamCertificate.click() }}> + UDYAM certificate <span className="error">*</span></button>
                                             <input type='file' accept='application/pdf' style={{ display: 'none' }} ref={(e) => { this.uploadUdyamCertificate = e; }} onChange={e => this.multipleFilesUpload(e, "udyamCertificate")} onClick={(e) => { e.target.value = null }} />
                                          </div>
                                       }

                                    </div>}
                              </div>
                              <div>
                                 {(fileNames || []).map((type, fId) => {
                                    return (
                                       <Fragment>
                                          {fId === 1 && type.name && type.name.length > 0 && <div className="mb-15"><b>New Files to Upload:</b> </div>}

                                          {type.name &&
                                             <div className="mb-15 flex">
                                                <span className="grey">{type.docName} - </span>
                                                {type.name}
                                                <span className="error" style={{ cursor: 'pointer', marginLeft: '20px' }} onClick={() => { this.handleRemoveNewFileUploaded(type, fId) }}> X </span>
                                             </div>}
                                       </Fragment>
                                    )
                                 })}

                                 {
                                    vendorForm.vendorDefaultData && vendorForm.vendorDefaultData.documents && vendorForm.vendorDefaultData.documents.length > 0 &&
                                    <div className="mt-25 mb-15" style={{ margin: "0px 15px" }}>
                                       <div className="mb-15 headerColor mb-20"><b>Uploaded Files:</b></div>
                                       {(vendorForm.vendorDefaultData.documents || []).map((doc) => {
                                          return (
                                             <div key={doc.id} className="mb-10">
                                                <span className="grey">{doc.file_name} - </span>
                                                <a href={doc.url} download className='files-link'> {doc.name} </a>
                                                {!isSeeker && <span className="error" style={{ cursor: 'pointer', marginLeft: '20px' }} onClick={() => this.deleteUploadedDocument(doc)}> X </span>}
                                             </div>
                                          )
                                       })}
                                    </div>
                                 }
                                 {<div className="fs-13 danger-color ml-25">Note: Please upload the files before submitting</div>}
                                 {!isSeeker && fileNames.length > 1 && <button
                                    disabled={((AWL_COMPANY_IDS.includes(userCompanyId) || CARGILL_COMPANY_IDS.includes(userCompanyId)) ? 
                                       !([1, 2, 11, 12, 13, 15, ...((AWL_COMPANY_IDS.includes(userCompanyId) && data.company_type == 'Proprietorship') ? [16] : []), 
                                       ...(CARGILL_COMPANY_IDS.includes(userCompanyId) ? [17, 18] : [])].every((val) => { return allMandatoryFiles.indexOf(val) !== -1 })) 
                                       : !((ABFRL_COMPANY_IDS.includes(userCompanyId) ? [1, 2, 3, 4, 6, 15, 23, 25, 26] : [1, 2, 7, 9,...(DEEPAK_COMPANY_ID.includes(userCompanyId) && !!data.is_msme_registered )?[15]:[]]).every((val) => { return allMandatoryFiles.indexOf(val) !== -1 }))) && (totalCount === 0)}
                                    className={((AWL_COMPANY_IDS.includes(userCompanyId) || CARGILL_COMPANY_IDS.includes(userCompanyId)) ?
                                       ((
                                          ([1, 2, 11, 12, 13, 15, ...((AWL_COMPANY_IDS.includes(userCompanyId) && data.company_type == 'Proprietorship') ? [16] : []),
                                           ...(CARGILL_COMPANY_IDS.includes(userCompanyId) ? [17, 18] : [])].every((val) => { return allMandatoryFiles.indexOf(val) !== -1 })) && (totalCount === 0)) 
                                           ? "mb-25 button-classic-doc mr-20 upload-button" : "mb-25 disabled-upload mr-20 upload-button")
                                       :
                                       (((ABFRL_COMPANY_IDS.includes(userCompanyId) ? [1, 2, 3, 4, 6, 15, 23,  25, 26] :[1, 2, 7, 9,...(DEEPAK_COMPANY_ID.includes(userCompanyId) && !!data.is_msme_registered )?[15]:[]]).every((val) => { return allMandatoryFiles.indexOf(val) !== -1 })) && totalCount === 0) ?
                                        "mb-25 button-classic-doc mr-20 upload-button" : "mb-25 disabled-upload mr-20 upload-button"
                                    )}
                                    onClick={this.uploadVendorDocs}>
                                    Upload
                                 </button>}

                              </div>
                           </div>
                        </div>
                     </div>
                     {/* <div className="fs-13 danger-color">Mandatory Certificates: Explosive (PESO), Calibration, National Permit, Insurance, Fitness Certificate, Suraksha Certificate, Hazardous Training, GPS System (AIS)</div> */}

                     {(AWL_COMPANY_IDS.includes(userCompanyId) || ABFRL_COMPANY_IDS.includes(userCompanyId)) &&
                        <div className='onboarding-container mt-20'>
                           <div className="Table-header">
                              <div className="headerColor">Declaration Forms</div>
                              {!isSeeker &&
                                 <div className='declaration-content'>
                                    {ABFRL_COMPANY_IDS.includes(userCompanyId) ? 

                                    <div>
                                          <div className='declaration-inside-content'>
                                             <span className='declaration-inside-content-name'>Compliance Form<span className="error">*</span></span>
                                             <a href='https://s3.us-east-2.amazonaws.com/tms-2.0-contracts/dev/Vendor%20Compliance%20delaration%20form_1693201980975.docx' className="declaration-button"> Download Format</a>
                                             <div className="dInBlock ml-20">
                                                <button className="button-classic-doc" onClick={() => { this.ComplianceForm.click() }}> + Upload Declaration </button>
                                                <input type='file' accept='application/pdf' style={{ display: 'none' }} ref={(e) => { this.ComplianceForm = e; }} onChange={e => this.declarationsFileUpload(e, "ComplianceForm")} onClick={(e) => { e.target.value = null }} />
                                             </div>
                                          </div>

                                          
                                          
                                             <div className='declaration-inside-content'>
                                             <span className='declaration-inside-content-name'>E-IRN Form{data.is_tds_filed ? <span className="error">*</span> : ''}</span>
                                             <a href=' https://s3.us-east-2.amazonaws.com/tms-2.0-contracts/dev/E-IRN%20Invoice%20Declaration_1693201789317.docx' className="declaration-button"> Download Format</a>
                                             <div className="dInBlock ml-20">
                                                <button className="button-classic-doc" onClick={() => { this.EIRN.click() }}> + Upload Declaration </button>
                                                <input type='file' accept='application/pdf' style={{ display: 'none' }} ref={(e) => { this.EIRN = e; }} onChange={e => this.declarationsFileUpload(e, "E-IRN")} onClick={(e) => { e.target.value = null }} />
                                             </div>
                                          </div>

                                    </div> :
                                    <div>
                                          <div className='declaration-inside-content'>
                                             <span className='declaration-inside-content-name'>TDS- TCS</span>
                                             <a href='https://s3.us-east-2.amazonaws.com/tms-2.0-contracts/dev/TDS_TCS_Declaration_from_vendor_1677482400904.docx' className="declaration-button"> Download Format</a>
                                             <div className="dInBlock ml-20">
                                                <button className="button-classic-doc" onClick={() => { this.uploadTdsTcs.click() }}> + Upload Declaration </button>
                                                <input type='file' accept='application/pdf' style={{ display: 'none' }} ref={(e) => { this.uploadTdsTcs = e; }} onChange={e => this.declarationsFileUpload(e, "tdsTcs")} onClick={(e) => { e.target.value = null }} />
                                             </div>
                                          </div>

                                          <div className='declaration-inside-content'>
                                             <span className='declaration-inside-content-name'>Not registered MSME </span>
                                             <a href='https://s3.us-east-2.amazonaws.com/tms-2.0-contracts/dev/Annexure5_No_MSME_Declaration_1677482535013.docx' className="declaration-button"> Download Format</a>
                                             <div className="dInBlock ml-20">
                                                <button className="button-classic-doc" onClick={() => { this.notRegisteredMsme.click() }}> + Upload Declaration </button>
                                                <input type='file' accept='application/pdf' style={{ display: 'none' }} ref={(e) => { this.notRegisteredMsme = e; }} onChange={e => this.declarationsFileUpload(e, "notRegisteredMsme")} onClick={(e) => { e.target.value = null }} />
                                             </div>
                                          </div>

                                          <div className='declaration-inside-content'>
                                             <span className='declaration-inside-content-name'>Not registered GST declaration</span>
                                             <a href='https://s3.us-east-2.amazonaws.com/tms-2.0-contracts/dev/No_gst_Declaration-Ann-2_1677482245750.docx' className="declaration-button"> Download Format</a>
                                             <div className="dInBlock ml-20">
                                                <button className="button-classic-doc" onClick={() => { this.notRegisteredGst.click() }}> + Upload Declaration </button>
                                                <input type='file' accept='application/pdf' style={{ display: 'none' }} ref={(e) => { this.notRegisteredGst = e; }} onChange={e => this.declarationsFileUpload(e, "notRegisteredGst")} onClick={(e) => { e.target.value = null }} />
                                             </div>
                                          </div>

                                          <div className='declaration-inside-content'>
                                             <span className='declaration-inside-content-name'>E-invoice applicable above 5 crores</span>
                                             <a href='https://s3.us-east-2.amazonaws.com/tms-2.0-contracts/dev/AWL_E-Inv applicable-above 5 crores_1694416058426.docx' className="declaration-button"> Download Format</a>
                                             <div className="dInBlock ml-20">
                                                <button className="button-classic-doc" onClick={() => { this.eInvocieApplicable.click() }}> + Upload Declaration </button>
                                                <input type='file' accept='application/pdf' style={{ display: 'none' }} ref={(e) => { this.eInvocieApplicable = e; }} onChange={e => this.declarationsFileUpload(e, "eInvocieApplicable")} onClick={(e) => { e.target.value = null }} />
                                             </div>
                                          </div>

                                          <div className='declaration-inside-content'>
                                             <span className='declaration-inside-content-name'>E-invoice not applicable below 5 crores</span>
                                             <a href='https://s3.us-east-2.amazonaws.com/tms-2.0-contracts/dev/AWL-E-Inv not applicable - Less than 5 Crores_1694415944180.docx' className="declaration-button"> Download Format</a>
                                             <div className="dInBlock ml-20">
                                                <button className="button-classic-doc" onClick={() => { this.eInvoiceNotApplicable.click() }}> + Upload Declaration </button>
                                                <input type='file' accept='application/pdf' style={{ display: 'none' }} ref={(e) => { this.eInvoiceNotApplicable = e; }} onChange={e => this.declarationsFileUpload(e, "eInvoiceNotApplicable")} onClick={(e) => { e.target.value = null }} />
                                             </div>
                                          </div>

                                          <div className='declaration-inside-content'>
                                             <span className='declaration-inside-content-name'>Vendor Application <span className="error">*</span></span>
                                             <a href='https://s3.us-east-2.amazonaws.com/tms-2.0-contracts/dev/AWL_Vendor_application_1694416224825.docx' className="declaration-button"> Download Format</a>
                                             <div className="dInBlock ml-20">
                                                <button className="button-classic-doc" onClick={() => { this.vendorAppliaction.click() }}> + Upload Declaration </button>
                                                <input type='file' accept='application/pdf' style={{ display: 'none' }} ref={(e) => { this.vendorAppliaction = e; }} onChange={e => this.declarationsFileUpload(e, "vendorAppliaction")} onClick={(e) => { e.target.value = null }} />
                                             </div>
                                          </div>

                                          <div className='declaration-inside-content'>
                                             <span className='declaration-inside-content-name'>AWL Carrier Agreement <span className="error">*</span></span>
                                             <a href='https://s3.us-east-2.amazonaws.com/tms-2.0-contracts/dev/AWL_Carrier_Agreement_Declaration_1694415908552.docx' className="declaration-button"> Download Format</a>
                                             <div className="dInBlock ml-20">
                                                <button className="button-classic-doc" onClick={() => { this.awlCarrierAgreement.click() }}> + Upload Declaration </button>
                                                <input type='file' accept='application/pdf' style={{ display: 'none' }} ref={(e) => { this.awlCarrierAgreement = e; }} onChange={e => this.declarationsFileUpload(e, "awlCarrierAgreement")} onClick={(e) => { e.target.value = null }} />
                                             </div>
                                          </div>
                                    
                                    </div>
                                    
                                    }
                                    
                                 </div>
                              }

                              {(this.state.declarationFiles || []).map((type, fId) => {
                                 return (
                                    <Fragment>
                                       <div style={{ marginLeft: '20px', marginTop: '10px' }}>
                                          {fId === 0 && type.name && type.name.length > 0 && <div className="mb-15"><b>New Files to Upload:</b> </div>}

                                          {type.name &&
                                             <div className="mb-15 flex">
                                                <span className="grey">{type.docName} - </span>
                                                {type.name}
                                                <span className="error" style={{ cursor: 'pointer', marginLeft: '20px' }} onClick={() => { this.handleRemoveNewDeclarationFile(type, fId) }}> X </span>
                                             </div>}
                                       </div>
                                    </Fragment>
                                 )
                              })}

                              {!isSeeker && !ABFRL_COMPANY_IDS.includes(userCompanyId) &&
                                 <button
                                    className={!isSeeker && this.state.declarationFiles.length > 0 ? "mb-25 button-classic-doc mr-20 upload-button" : "mb-25 disabled-upload mr-20 upload-button"}
                                    style={!isSeeker && this.state.declarationFiles.length > 0 ? { width: '110px', marginLeft: 'auto' } : { width: '120px', marginLeft: 'auto' }}
                                    onClick={!isSeeker && this.state.declarationFiles.length > 0 && this.uploadDeclarationFiles}>
                                    Upload
                                 </button>
                              }

                                    {!isSeeker && ABFRL_COMPANY_IDS.includes(userCompanyId) && this.state.declarationFiles.length > 0 && (
                                                <button
                                                   disabled={
                                                      ABFRL_COMPANY_IDS.includes(userCompanyId)
                                                      && ![24,...(ABFRL_COMPANY_IDS.includes(userCompanyId) && data.is_tds_filed===true ? [27] : [])]
                                                            .every(val => allMandatoryFiles.includes(val))
                                                   }
                                                   className={
                                                      ABFRL_COMPANY_IDS.includes(userCompanyId)
                                                      && [24, ...(ABFRL_COMPANY_IDS.includes(userCompanyId) && data.is_tds_filed===true ? [27] : [])]
                                                            .every(val => allMandatoryFiles.includes(val)) && totalCount === 0
                                                         ?  "mb-25 button-classic-doc mr-20 upload-button wt-120 ml-auto"
                                                         : "mb-25 disabled-upload mr-20 upload-button wt-120 ml-auto" 
                                                   }
                                                   onClick={this.uploadDeclarationFiles}
                                                >
                                                   Upload
                                                </button>
                                                )}

                              {
                                 vendorForm.vendorDefaultData && vendorForm.vendorDefaultData.declarations && vendorForm.vendorDefaultData.declarations.length > 0 &&
                                 <div className="mt-25 mb-15" style={{ margin: "0px 15px" }}>
                                    <div className="mb-15 headerColor mb-20"><b>Uploaded Files:</b></div>
                                    {(vendorForm.vendorDefaultData.declarations || []).map((doc) => {
                                       return (
                                          <div key={doc.id} className="mb-10">
                                             <span className="grey">{doc.file_name} - </span>
                                             <a href={doc.url} download className='files-link'> {doc.name} </a>
                                             {!isSeeker && <span className="error" style={{ cursor: 'pointer', marginLeft: '20px' }} onClick={() => this.deleteUploadedDocument(doc)}> X </span>}
                                          </div>
                                       )
                                    })}
                                 </div>
                              }
                           </div>
                        </div>
                     }
                     {DEEPAK_COMPANY_ID.includes(userCompanyId) &&
                        <div className='onboarding-container mt-20'>
                           <div className="Table-header">
                              <div className="headerColor">Declaration Forms</div>
                              {!isSeeker &&
                                 <div className='declaration-content'>
                                    <div className='declaration-inside-content'>
                                       <span className='declaration-inside-content-name'>
                                          Not filed ITR {!data.is_itr_filed && (<span style={{ color: "red" }}>*</span>)}
                                       </span>
                                      
                                       <a href='https://s3.us-east-2.amazonaws.com/tms-2.0-contracts/dev/DPL_TDS_TCS_Declaration_from_vendor.docx' className="declaration-button"> Download Format</a>
                                       <div className="dInBlock ml-20">
                                          <button className="button-classic-doc" onClick={() => { this.filedIir.click() }}> + Upload Declaration </button>
                                          <input type='file' accept={isDPL ? '.pdf, .jpeg, .jpg, .png' : '.pdf'} style={{ display: 'none' }} ref={(e) => { this.filedIir = e; }} onChange={e => this.declarationsFileUpload(e, "filedItr")} onClick={(e) => { e.target.value = null }} />
                                       </div>
                                    </div>

                                    <div className='declaration-inside-content'>
                                       <span className='declaration-inside-content-name'>Not registered MSME{!data.is_msme_registered && (<span style={{ color: "red" }}>*</span>)} </span>
                                       <a href='https://s3.us-east-2.amazonaws.com/tms-2.0-contracts/dev/Annexure5_No_MSME_Declaration_1677482535013.docx' className="declaration-button"> Download Format</a>
                                       <div className="dInBlock ml-20">
                                          <button className="button-classic-doc" onClick={() => { this.notRegisteredMsme.click() }}> + Upload Declaration </button>
                                          <input type='file' accept={isDPL ? '.pdf, .jpeg, .jpg, .png' : '.pdf'} style={{ display: 'none' }} ref={(e) => { this.notRegisteredMsme = e; }} onChange={e => this.declarationsFileUpload(e, "notRegisteredMsme")} onClick={(e) => { e.target.value = null }} />
                                       </div>
                                    </div>

                                    <div className='declaration-inside-content'>
                                       <span className='declaration-inside-content-name'>Not registered GST declaration{!data.is_gst_registered && (<span style={{ color: "red" }}>*</span>)}</span>
                                       <a href='https://s3.us-east-2.amazonaws.com/tms-2.0-contracts/dev/No_gst_Declaration_DPL_Template_1696330971904.docx' className="declaration-button"> Download Format</a>
                                       <div className="dInBlock ml-20">
                                          <button className="button-classic-doc" onClick={() => { this.notRegisteredGst.click() }}> + Upload Declaration </button>
                                          <input type='file' accept={isDPL ? '.pdf, .jpeg, .jpg, .png' : '.pdf'} style={{ display: 'none' }} ref={(e) => { this.notRegisteredGst = e; }} onChange={e => this.declarationsFileUpload(e, "notRegisteredGst")} onClick={(e) => { e.target.value = null }} />
                                       </div>
                                    </div>
                                 </div>
                              
                              }

                              {(this.state.declarationFiles || []).map((type, fId) => {
                                 return (
                                    <Fragment>
                                       <div style={{ marginLeft: '20px', marginTop: '10px' }}>
                                          {fId === 0 && type.name && type.name.length > 0 && <div className="mb-15"><b>New Files to Upload:</b> </div>}

                                          {type.name &&
                                             <div className="mb-15 flex">
                                                <span className="grey">{type.docName} - </span>
                                                {type.name}
                                                <span className="error" style={{ cursor: 'pointer', marginLeft: '20px' }} onClick={() => { this.handleRemoveNewDeclarationFile(type, fId) }}> X </span>
                                             </div>}
                                       </div>
                                    </Fragment>
                                 )
                              })}

                              {!isSeeker &&
                                 <button
                                    className={!isSeeker && !!this.SAPDPLdeclarationFilesMandatoryCheck() ? "mb-25 button-classic-doc mr-20 upload-button" : "mb-25 disabled-upload mr-20 upload-button"}
                                    style={!isSeeker && this.state.declarationFiles.length > 0 ? { width: '110px', marginLeft: 'auto' } : { width: '120px', marginLeft: 'auto' }}
                                    onClick={!isSeeker && this.state.declarationFiles.length > 0 && this.uploadDeclarationFiles}
                                    >
                                    Upload
                                 </button>
                              }

                              {
                                 vendorForm.vendorDefaultData && vendorForm.vendorDefaultData.declarations && vendorForm.vendorDefaultData.declarations.length > 0 &&
                                 <div className="mt-25 mb-15" style={{ margin: "0px 15px" }}>
                                    <div className="mb-15 headerColor mb-20"><b>Uploaded Files:</b></div>
                                    {(vendorForm.vendorDefaultData.declarations || []).map((doc) => {
                                       return (
                                          <div key={doc.id} className="mb-10">
                                             <span className="grey">{doc.file_name} - </span>
                                             <a href={doc.url} download className='files-link'> {doc.name} </a>
                                             {/*{!isSeeker && <span className="error" style={{ cursor: 'pointer', marginLeft: '20px' }} onClick={() => this.deleteUploadedDocument(doc)}> X </span>}*/}
                                          </div>
                                       )
                                    })}
                                 </div>
                              }
                           </div>
                        </div>
                     }

                     {CARGILL_COMPANY_IDS.includes(userCompanyId) &&
                        <div className='onboarding-container mt-20'>
                           <div className="Table-header">
                              <div className="headerColor">Declaration Forms</div>
                              {!isSeeker &&
                                 <div className='declaration-content'>
                                    <div className='declaration-inside-content'>
                                       <span className='declaration-inside-content-name'>MSMED Form</span>
                                       <a href='https://s3.us-east-2.amazonaws.com/tms-2.0-contracts/dev/Cargill_MSMED_Act_Form_1680870768406.doc' className="declaration-button"> Download Format</a>
                                       <div className="dInBlock ml-20">
                                          <button className="button-classic-doc" onClick={() => { this.uploadMSMED.click() }}> + Upload Declaration </button>
                                          <input type='file' accept='application/pdf' style={{ display: 'none' }} ref={(e) => { this.uploadMSMED = e; }} onChange={e => this.declarationsFileUpload(e, "MSMED Form")} onClick={(e) => { e.target.value = null }} />
                                       </div>
                                    </div>

                                    <div className='declaration-inside-content'>
                                       <span className='declaration-inside-content-name'>Filled up Vendor profile form along with approval from respective controller</span>
                                       <a href='https://s3.us-east-2.amazonaws.com/tms-2.0-contracts/dev/Cargill_Vendor_Profile_form_1680870916735.DOCX' className="declaration-button"> Download Format</a>
                                       <div className="dInBlock ml-20">
                                          <button className="button-classic-doc" onClick={() => { this.vendorProfile.click() }}> + Upload Declaration </button>
                                          <input type='file' accept='application/pdf' style={{ display: 'none' }} ref={(e) => { this.vendorProfile = e; }} onChange={e => this.declarationsFileUpload(e, "Filled up Vendor profile form along with approval from respective controller")} onClick={(e) => { e.target.value = null }} />
                                       </div>
                                    </div>

                                    <div className='declaration-inside-content'>
                                       <span className='declaration-inside-content-name'>Annexure 1 to Cargill as buyer </span>
                                       <a href='https://s3.us-east-2.amazonaws.com/tms-2.0-contracts/dev/Cargill_Annexure1_to_Cargill_as_Buyer_1680870945487.docx' className="declaration-button"> Download Format</a>
                                       <div className="dInBlock ml-20">
                                          <button className="button-classic-doc" onClick={() => { this.asBuyer.click() }}> + Upload Declaration </button>
                                          <input type='file' accept='application/pdf' style={{ display: 'none' }} ref={(e) => { this.asBuyer = e; }} onChange={e => this.declarationsFileUpload(e, "Annexure 1 to Cargill as buyer")} onClick={(e) => { e.target.value = null }} />
                                       </div>
                                    </div>
                                 </div>
                              }

                              {(this.state.declarationFiles || []).map((type, fId) => {
                                 return (
                                    <Fragment>
                                       <div style={{ marginLeft: '20px', marginTop: '10px' }}>
                                          {fId === 0 && type.name && type.name.length > 0 && <div className="mb-15"><b>New Files to Upload:</b> </div>}

                                          {type.name &&
                                             <div className="mb-15 flex">
                                                <span className="grey">{type.docName} - </span>
                                                {type.name}
                                                <span className="error" style={{ cursor: 'pointer', marginLeft: '20px' }} onClick={() => { this.handleRemoveNewDeclarationFile(type, fId) }}> X </span>
                                             </div>}
                                       </div>
                                    </Fragment>
                                 )
                              })}

                              {!isSeeker &&
                                 <button
                                    className={!isSeeker && this.state.declarationFiles.length > 0 ? "mb-25 button-classic-doc mr-20 upload-button" : "mb-25 disabled-upload mr-20 upload-button"}
                                    style={!isSeeker && this.state.declarationFiles.length > 0 ? { width: '110px', marginLeft: 'auto' } : { width: '120px', marginLeft: 'auto' }}
                                    onClick={this.uploadDeclarationFiles}>
                                    Upload
                                 </button>
                              }

                              {
                                 vendorForm.vendorDefaultData && vendorForm.vendorDefaultData.declarations && vendorForm.vendorDefaultData.declarations.length > 0 &&
                                 <div className="mt-25 mb-15" style={{ margin: "0px 15px" }}>
                                    <div className="mb-15 headerColor mb-20"><b>Uploaded Files:</b></div>
                                    {(vendorForm.vendorDefaultData.declarations || []).map((doc) => {
                                       return (
                                          <div key={doc.id} className="mb-10">
                                             <a href={doc.url} download className='files-link'> {doc.name} </a>
                                             {/* {!isSeeker && <span className="error" style={{ cursor: 'pointer', marginLeft: '20px' }} onClick={() => this.deleteUploadedDocument(doc)}> X </span>} */}
                                          </div>
                                       )
                                    })}
                                 </div>
                              }
                           </div>
                        </div>
                     }


                     {(DEEPAK_COMPANY_ID.includes(userCompanyId) || AWL_COMPANY_IDS.includes(userCompanyId) || CARGILL_COMPANY_IDS.includes(userCompanyId) || (ABFRL_COMPANY_IDS.includes(userCompanyId))) &&
                        // <div className="wt-80p mt-20 mb-20">
                        <div className='first-table-company'>
                           <div className="Table-header">
                              <div className="table-heading">Pre-Qualification Pointers<span className="error">*</span></div>
                              {/* <div className="headerColor">Pre-Qualification Pointers<span className="error">*</span></div> */}
                           </div>
                           {dataSecond.vendorRatingQuestions && dataSecond.vendorRatingQuestions.length > 0 ?
                              <div className=" table-border-none ">
                                 <div >

                                    {(dataSecond.vendorRatingQuestions || []).map((checklist, index) => {
                                       return (
                                          <div className=' '>
                                             {/* <td className="fSemibold black borderLight grey">{checklist.question_id}</td> */}
                                             <div className=" fSemibold black qualification-table tr-sanity-container">
                                                <div>
                                                   <div>{checklist.type}</div>
                                                   <p style={{ marginTop: "5px" }}>{'eg: ' + checklist.question}</p>
                                                </div>

                                                <div className=" fSemibold black " style={{ textAlign: 'right' }}>
                                                   <StarRatings
                                                      rating={checklist.vendorRating}
                                                      starRatedColor='rgb(243 236 5 / 85%)'
                                                      changeRating={(e) => this.changeVendorRating(e, index)}
                                                      numberOfStars={5}
                                                      name='rating'
                                                      starDimension='30px'
                                                      starSpacing='8px'
                                                      starHoverColor='rgb(243 236 5 / 85%)'

                                                   /></div> </div>
                                          </div>
                                       );
                                    })}
                                 </div>
                              </div> :
                              <div className="mt-10 fSemibold headerColor-color"> No data found</div>}
                        </div>}
                     {/* {DEEPAK_COMPANY_ID.includes(userCompanyId) && isSeeker && !userData.is_admin &&
      <Fragment>
         <div className="headerColor mb-10">Rating</div>
         <StarRatings
            rating={this.state.rating}
            starRatedColor={"#5289ea"}
            changeRating={this.changeRating}
            numberOfStars={5}
            name='rating'
            starDimension='25px'
         />
      </Fragment>
      } */}

                     {isSeeker && (AWL_COMPANY_IDS.includes(userCompanyId) || ABFRL_COMPANY_IDS.includes(userCompanyId)) && isPage == 3 &&
                        <div style={{ marginBottom: '30px', marginTop: '20px', width: '100%', display: 'flex', flexDirection: 'column', background: 'white', padding: '30px 50px', borderRadius: '10px', border: '1px solid black', marginLeft: 'auto', marginRight: 'auto' }}>
                           {vendorForm && vendorForm.vendorDefaultData && vendorForm.vendorDefaultData.bank_details && <p style={{ fontSize: '20px' }}>Approver Bank Details :-</p>}
                           {((vendorForm && vendorForm.vendorDefaultData && vendorForm.vendorDefaultData.bank_details)||
                           (vendorForm && vendorForm.vendorDefaultData && vendorForm.vendorDefaultData.bank_documents)) &&
                              <div  className='approverflex'>
                                 {vendorForm && vendorForm.vendorDefaultData && vendorForm.vendorDefaultData.bank_details&&
                                 <div style={{ display: 'flex', flexDirection:'column'}}>
                                 
                                    {vendorForm.vendorDefaultData.bank_details.is_security_deposit &&
                                       <>
                                          <div style={{  marginTop: '20px', width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                                             <div style={{ width: '70%', fontWeight: '900' }}>Security Bank Name <span style={{ float: 'right' }}>:</span> </div>
                                             <div style={{ marginLeft: '10px', width: '35%', marginTop:'20px' }}>{vendorForm.vendorDefaultData.bank_details.security_bank_name || '-'}</div>
                                          </div>

                                          <div style={{ display: 'flex', marginTop: '10px', width: '100%', justifyContent: 'space-between' }}>
                                             <div style={{ width: '70%', fontWeight: '900' }}>Security Cheque No <span style={{ float: 'right' }}>:</span> </div>
                                             <div style={{ marginLeft: '10px', width: '35%',marginTop:'20px' }}>{vendorForm.vendorDefaultData.bank_details.security_cheque_no || '-'}</div>
                                          </div>

                                          <div style={{ display: 'flex', marginTop: '10px', width: '100%', justifyContent: 'space-between' }}>
                                             <div style={{ width: '70%', fontWeight: '900' }}>Security Amount <span style={{ float: 'right' }}>:</span> </div>
                                             <div style={{ marginLeft: '10px', width: '35%',marginTop:'20px' }}>{vendorForm.vendorDefaultData.bank_details.security_amount || '-'}</div>
                                          </div>

                                          <div style={{ display: 'flex', marginTop: '10px', width: '100%', justifyContent: 'space-between' }}>
                                             <div style={{ width: '70%', fontWeight: '900' }}>Security Amount Date<span style={{ float: 'right' }}>:</span> </div>
                                             <div style={{ marginLeft: '10px', width: '35%',marginTop:'20px' }}>{vendorForm.vendorDefaultData.bank_details.security_amount_date && moment(vendorForm.vendorDefaultData.bank_details.security_amount_date).format('DD/MM/YYYY') || '-'}</div>
                                          </div>
                                       </>
                                    }
                                   
                                    {vendorForm.vendorDefaultData.bank_details.is_bank_guarantee &&
                                       <>
                                          <div style={{ display: 'flex', marginTop: '20px', width: '100%', justifyContent: 'space-between' }}>
                                             <div style={{ width: '70%', fontWeight: '900' }}> Guarantee Name <span style={{ float: 'right' }}>:</span> </div>
                                             <div style={{ marginLeft: '10px', width: '35%' }}>{vendorForm.vendorDefaultData.bank_details.guarantee_bank_name || '-'}</div>
                                          </div>

                                          <div style={{ display: 'flex', marginTop: '10px', width: '100%', justifyContent: 'space-between' }}>
                                             <div style={{ width: '70%', fontWeight: '900' }}> Guarantee Number<span style={{ float: 'right' }}>:</span> </div>
                                             <div style={{ marginLeft: '10px', width: '35%' }}>{vendorForm.vendorDefaultData.bank_details.bank_guarantee_number || '-'}</div>
                                          </div>

                                          <div style={{ display: 'flex', marginTop: '10px', width: '100%', justifyContent: 'space-between' }}>
                                             <div style={{ width: '70%', fontWeight: '900' }}> Guarantee Amount<span style={{ float: 'right' }}>:</span> </div>
                                             <div style={{ marginLeft: '10px', width: '35%' }}>{vendorForm.vendorDefaultData.bank_details.bank_guarantee_amount || '-'}</div>
                                          </div>

                                          <div style={{ display: 'flex', marginTop: '10px', width: '100%', justifyContent: 'space-between' }}>
                                             <div style={{ width: '70%', fontWeight: '900' }}> Guarantee Date<span style={{ float: 'right' }}>:</span> </div>
                                             <div style={{ marginLeft: '10px', width: '35%' }}>{vendorForm.vendorDefaultData.bank_details.bank_guarantee_date_from && moment(vendorForm.vendorDefaultData.bank_details.bank_guarantee_date_from).format('DD/MM/YYYY') || '-'}</div>
                                          </div>

                                          <div style={{ display: 'flex', marginTop: '10px', width: '100%', justifyContent: 'space-between' }}>
                                             <div style={{ width: '70%', fontWeight: '900' }}> Guarantee Validity<span style={{ float: 'right' }}>:</span> </div>
                                             <div style={{ marginLeft: '10px', width: '35%' }}>{vendorForm.vendorDefaultData.bank_details.bank_guarantee_date_validity && moment(vendorForm.vendorDefaultData.bank_details.bank_guarantee_date_validity).format('DD/MM/YYYY') || '-'}</div>
                                          </div>
                                          <div style={{ display: 'flex', marginTop: '10px', width: '100%', justifyContent: 'space-between' }}>
                                             <div style={{ width: '70%', fontWeight: '900' }}> Guarantee Return<span style={{ float: 'right' }}>:</span> </div>
                                             <div style={{ marginLeft: '10px', width: '35%' }}>{vendorForm.vendorDefaultData.bank_details.bank_guarantee_return && moment(vendorForm.vendorDefaultData.bank_details.bank_guarantee_return).format('DD/MM/YYYY') || '-'}</div>
                                          </div>
                                          <div style={{ display: 'flex', marginTop: '10px', width: '100%', justifyContent: 'space-between' }}>
                                             <div style={{ width: '70%', fontWeight: '900' }}>Courier Docket No <span style={{ float: 'right' }}>:</span> </div>
                                             <div style={{ marginLeft: '10px', width: '35%' }}>{vendorForm.vendorDefaultData.bank_details.courier_docket_no || '-'}</div>
                                          </div>
                                          <div style={{ display: 'flex', marginTop: '10px', width: '100%', justifyContent: 'space-between' }}>
                                             <div style={{ width: '70%', fontWeight: '900' }}>Courier Name <span style={{ float: 'right' }}>:</span> </div>
                                             <div style={{ marginLeft: '10px', width: '35%' }}>{vendorForm.vendorDefaultData.bank_details.courier_name || '-'}</div>
                                          </div>
                                       </>
                                    }
                                    
                                 </div>}
                                     {vendorForm && vendorForm.vendorDefaultData && vendorForm.vendorDefaultData.bank_documents&&
                                     <div className='bnkdocs'>
                                       {((window.location.pathname.includes('external-vendor-approver') || window.location.pathname.includes('vendor-approver')||window.location.pathname.includes('vendor-headoffice-approver')||window.location.pathname.includes('vendor-bank-approver')) && isPage == 3 && vendorForm && vendorForm.vendorDefaultData && vendorForm.vendorDefaultData.bank_documents && vendorForm.vendorDefaultData.bank_documents.length > 0 && vendorForm.vendorDefaultData.bank_documents || []).map((type, fId) => {
                                          return (
                                             <Fragment>
                                                {fId === 0 && <div className="mb-15"><b>BANK DOCUMENTS:-</b> </div>}
                                                <div className="mb-15 flex">
                                                   <a href={type.url} download className='files-link'>{type.name} </a>
                                                </div>
                                             </Fragment>
                                          )
                                       })}

                                    </div>}
                              </div>
                           }
                        </div>
                     }

                     {!isSeeker && (DEEPAK_COMPANY_ID.includes(userCompanyId) || AWL_COMPANY_IDS.includes(userCompanyId) || CARGILL_COMPANY_IDS.includes(userCompanyId) ) && <div className="flex pt-30 pr-40 pb-30" style={{ marginTop: "15px" }}>
                        <input type="checkbox" className="mr-10 curP" checked={this.state.isAccepted} onChange={this.handleAcceptTaC} />
                        <label> I accept all {' '} <a className="curP theme-color" href='https://www.termsfeed.com/public/uploads/2021/12/sample-terms-conditions-agreement.pdf' target="_blank"> Terms & Conditions </a>  </label>
                     </div>}
                     {!isSeeker && ABFRL_COMPANY_IDS.includes(userCompanyId) && <div className="flex pt-30 pr-40 pb-30" style={{ marginTop: "15px" }}>
                        < input type="checkbox" className="mr-10 curP" checked={this.state.isAccepted} onChange={this.handleAcceptTaC} />
                        {ABFRL_COMPANY_IDS.includes(userCompanyId)?<label> I hereby confirm that I have read and understood the <a className="curP theme-color" target="_blank" href="https://s3.us-east-2.amazonaws.com/tms-2.0-contracts/dev/Terms_and_Conditions_ABFRL1_1694764935226.pdf"> Terms & Conditions </a> and agree to abide by  them. I declare that all the information provided by me during the application process is true and accurate to the best of my knowledge. </label>
                        :<label> I hereby confirm that I have read and understood the {' '} <a className="curP theme-color" target="_blank"> Terms & Conditions </a> and agree to abide by  them. I declare that all the information provided by me during the application process is true and accurate to the best of my knowledge. </label>}
                          </div>}
                     {(DEEPAK_COMPANY_ID.includes(userCompanyId) || AWL_COMPANY_IDS.includes(userCompanyId) || CARGILL_COMPANY_IDS.includes(userCompanyId) || ABFRL_COMPANY_IDS.includes(userCompanyId)) ?
                        <div className="mb-25 mt-25 fRight" style={{ marginLeft: 'auto' }}>
                           {(AWL_COMPANY_IDS.includes(userCompanyId))&&((!isShowPDF) && (! window.location.pathname.includes('vendor-form'))&&<button className='button-classic mr-20 dlpdf' onClick={this.printDocument}>Download PDF</button>)}
                           <button data-html2canvas-ignore className="mb-25 button-classic mr-20" onClick={() => { this.setState({ isPage: 2 }); window.scrollTo(0, 0); }}> Previous </button>
                           <button data-html2canvas-ignore className="mb-25 button-classic mr-20" onClick={() => { history.length > 1 ? history.goBack() : window.close() }}> Cancel </button>
                           {!isSeeker &&
                           <button
                           className={shouldEnableSubmitButton(
                              this.state.isAccepted,
                              vendorForm.vendorDefaultData.documents,
                              dataSecond.vendorRatingQuestions,
                              userCompanyId,
                              data.company_type,
                              data.is_msme_registered,
                              data.is_gst_registered,
                              allMandatoryFiles,
                              this.SAPDPLdeclarationFilesMandatoryCheck()
                           )
                             ? "mb-25 button-classic mr-20"
                             : "mb-25 disabled mr-20"}
                           onClick={() => this.saveSecondaryDetails('submit')}
                           disabled={!shouldEnableSubmitButton(
                             this.state.isAccepted,
                             vendorForm.vendorDefaultData.documents,
                             dataSecond.vendorRatingQuestions,
                             userCompanyId,
                             data.company_type,
                             data.is_msme_registered,
                             data.is_gst_registered,
                             allMandatoryFiles,
                             this.SAPDPLdeclarationFilesMandatoryCheck()
                           )}
                         >
                           Submit
                         </button>}
                           {/*<button className="mb-25 button-classic mr-20" onClick={() => { this.setState({ isPage: 3 }); window.scrollTo(0,0); }}> Next </button>*/}
                        </div>
                        :
                        <div className="mb-25 mt-25 fRight" style={{ marginLeft: 'auto' }}>
                           {(!isShowPDF) && <button className='button-classic mr-20 dlpdf' onClick={this.printDocument}>Download PDF</button>}
                           {(!isShowPDF) && <button className="mb-25 button-classic mr-20" onClick={() => { this.setState({ isPage: 2 }); window.scrollTo(0, 0); }}> Previous </button>}
                           {(!isShowPDF) && <button className="mb-25 button-classic mr-20" onClick={() => { history.length > 1 ? history.goBack() : window.close() }}> Cancel </button>}
                           {!(hasCPAccess || isVendorCP) && !isSeeker && <button className="mb-25 button-classic mr-20" onClick={this.saveSecondaryDetails}> Save </button>}
                           {/*<button className="mb-25 button-classic mr-20" onClick={() => { this.setState({ isPage: 3 }); window.scrollTo(0,0); }}> Next </button>*/}
                        </div>}
                  </div>
               }

               <div id='form-page-4' style={{ width: "100%" }}>
               {isSeeker && isPage == 3 &&
                  <div style={{ marginBottom: '30px', width: '100%', display: 'flex', flexDirection: 'column', background: 'white', padding: '10px 10px', borderRadius: '10px', border: '1px solid black', marginLeft: 'auto', marginRight: 'auto' }}>
                     {vendorForm && vendorForm.vendorDefaultData && vendorForm.vendorDefaultData.approvals && vendorForm.vendorDefaultData.approvals.length > 0 && <p style={{ fontSize: '20px' }}>Approver Comments :-</p>}
                     <div>
                        {
                           vendorForm && vendorForm.vendorDefaultData && vendorForm.vendorDefaultData.approvals && vendorForm.vendorDefaultData.approvals.length > 0 &&
                           vendorForm.vendorDefaultData.approvals.map((d) => {
                              return (
                                 <div style={{ display: 'flex', marginTop: '10px', width: '100%' }}>
                                    <div style={{ width: '20%', fontWeight: '900' }}>  {d.approved_by_username || '-'} <span style={{ float: 'right' }}>:</span> </div>
                                    <div style={{ marginLeft: '10px', width: '78%' }}>{d.approver_remarks || '-'}</div>
                                 </div>
                              )
                           })
                        }
                     </div>
                  </div>
               }
               </div>
            <div>
               {(isSeeker) && !userData.is_admin && approvalItem.length === 0 && !(window.location.pathname.includes('vendor-read')) && isPage == 3 &&
                  <div className="mb-15" style={{ display: 'flex' }} >
                     <div className="mr-20 mb-10">Comments :</div>
                     <textarea
                        className="vendor-comment-input"
                        type="text"
                        value={this.state.comment}
                        onChange={(e) => {
                           this.setState({ comment: e.target.value })
                        }}
                        style={{ maxHeight: '150px', minHeight: '150px', minWidth: '80%', maxWidth: '80%' }}
                     />
                  </div>
               }
               {(isDPL && !(window.location.pathname.includes('external-vendor-approver'))&&(window.location.pathname.includes('vendor-approver'))) && isPage == 3 &&
               <div className="VendorFormSAPcontainer">
               <div className="container-heading">SAP Details</div>
     
               <div className="form-section">
                 <div id="Acctlabel" className="form-item">
                   <label className="labelname Acct" htmlFor="input1">
                     Account Group<span style={{ color: "red" }}>*</span>
                   </label>
                   <input
                     className="common"
                     type="text"
                     id="account_group"
                     placeholder="Account Group"
                     name="vendorAcctGroup"
                     value={data.vendorAcctGroup}
                     onChange={(e) => {
                        const { data } = this.state;
                        data.vendorAcctGroup = e.target.value;
                        this.setState({ data });
                     }}
                   />
                 </div>
     
                 <div className="form-item">
                   <label htmlFor="input3">
                     Company Code<span style={{ color: "red" }}>*</span>
                   </label>
                   <input
                     type="text"
                     className="common"
                     id="company_code"
                     placeholder="Company Code"
                     name="companyCode"
                     value={data.companyCode}
                     onChange={(e) => {
                        const { data } = this.state;
                        data.companyCode = e.target.value;
                        this.setState({ data });
                     }}
                   />
                 </div>
     
                 <div className="form-item">
                   <label htmlFor="input4">
                     Recon Account<span style={{ color: "red" }}>*</span>
                   </label>
                   <input
                     type="text"
                     id="recon_account"
                     placeholder="Recon Account"
                     className="common"
                     name="reconAccount"
                     value={data.reconAccount}
                     onChange={(e) => {
                        const { data } = this.state;
                        data.reconAccount = e.target.value;
                        this.setState({ data });
                     }}
                   />
                 </div>
     
                 <div className="form-item">
                   <label htmlFor="input3">
                     Sap Vendor Code
                   </label>
                   <input
                     type="text"
                     id="sap_vendor_code"
                     placeholder="Sap Vendor Code"
                     className="common"
                     name="sap_vendor_code"
                     value={data.sap_vendor_code}
                     readOnly
                   />
                 </div>
     
                 <div className="form-item">
                   <label htmlFor="input6">
                     Purchase Org<span style={{ color: "red" }}>*</span>
                   </label>
                   <input
                     type="text"
                     id="purchase_org"
                     placeholder="Purchase Org"
                     className="common"
                     name="purchaseOrganisation"
                     value={data.purchaseOrganisation}
                     onChange={(e) => {
                        const { data } = this.state;
                        data.purchaseOrganisation = e.target.value;
                        this.setState({ data });
                     }}
                   />
                 </div>
               </div>
               <div className="form-section">
               <div className="form-item">
                   <label htmlFor="input5">
                     Payment Method<span style={{ color: "red" }}>*</span>
                   </label>
                   <input
                     type="text"
                     id="payment_method"
                     placeholder="Payment Method"
                     className="common"
                     name="paymentMethod"
                     value={data.paymentMethod}
                     maxLength={4}
                     onChange={(e) => {
                        const { data } = this.state;
                        data.paymentMethod = e.target.value;
                        this.setState({ data });
                     }}
                   />
                 </div>
                 
                 <div className="form-item">
                   <label htmlFor="input1">
                     Order Currency<span style={{ color: "red" }}>*</span>
                   </label>
                   <select
                     id="order_currency"
                     name="currencyOrder"
                     className="common"
                     value={data.currencyOrder}
                     onChange={(e) => {
                        const { data } = this.state;
                        data.currencyOrder = e.target.value;
                        this.setState({ data });
                     }}
                   >
                     <option value={""}>Please Select</option>
                     {(Object.keys(vendorCurrencyDetails) || []).map((ele, ind) => (
                       <Fragment key={ind}>
                         <option value={ele}>{ele}</option>
                       </Fragment>
                     ))}
                   </select>
                 </div>
     
                 <div className="form-item">
                   <label htmlFor="input2">
                     Payment Terms<span style={{ color: "red" }}>*</span>
                   </label>
                   {/* <select
                     id="payment_terms"
                     className="common"
                     name="paymentTerms"
                     value={data.paymentTerms}
                     onChange={(e) => {
                        const { data } = this.state;
                        data.paymentTerms = e.target.value;
                        this.setState({ data });
                     }}
                   >
                     <option value={""}>Please Select</option>
                     {(this.props.vendorPaymentDetails || []).map((ele, ind) => (
                       <Fragment key={ind}>
                         <option value={ele}>{ele}</option>
                       </Fragment>
                     ))}
                   </select> */}
                   <Select
                    className="incotermsOptions"
                    options={paymentMethod}
                  //   value={incoterms.filter(
                  //     (val) => {val.value == data.incoTerm1
                  //             }
                  //   )}
                  value={paymentMethod.find((option) => option.short_name === data.paymentTerms)}
                  //  value={data.incoTerm1}
                    onChange={(option) => this.onChangepaymentTerms(option)}
                    placeholder="Select Payment Method"
                  />
                 </div>
     
                 <div className="form-item">
                   <label htmlFor="input3">
                     Inco Terms<span style={{ color: "red" }}>*</span>
                   </label>
                   {/* <input
                     type="text"
                     id="inco_terms"
                     placeholder="Inco Terms"
                     className="common"
                     name="incoTerm1"
                     value={data.incoTerm1}
                     maxLength={4}
                     onChange={(e) => {
                        const { data } = this.state;
                        data.incoTerm1 = e.target.value;
                        this.setState({ data });
                     }}
                   /> */}
                   <Select
                    className="incotermsOptions"
                    options={incoterms}
                  //   value={incoterms.filter(
                  //     (val) => {val.value == data.incoTerm1
                  //             }
                  //   )}
                  value={incoterms.find((option) => option.short_name === data.incoTerm1)}
                  //  value={data.incoTerm1}
                    onChange={(option) => this.onChangeIncoterm(option)}
                    placeholder="Select Inco Terms"
                  />
                 </div>

                 <div className="form-item">
                 {data.sapDetailsErrorMsg===true && <div className='error'>Please fill all mandatory fields</div>}
                 <button
                  className={`ml-200 button-classic`}
                  id="btn2"
                  onClick={this.handleSaveSAPDetails}
               >
                  Save
               </button>
               
                 </div>
               </div>
              
             </div> 
               }

                  {this.renderTaxCodeDetailsBlock() &&
                     <TaxCodeDetails 
                     taxCodeDetailsRows={taxCodeDetailsRows}
                     planningOptions={planningOptions}
                     taxCodeOptions={taxCodeOptions}
                     isSendToSap={isSendToSap}
                     handleTaxCodeDetailInputChange={this.handleTaxCodeDetailInputChange}
                     handleDeleteTaxCodeDetailRow={this.handleDeleteTaxCodeDetailRow}
                     handleAddTaxCodeDetailRow={this.handleAddTaxCodeDetailRow}
                     saveTaxCodeDetails={this.saveTaxCodeDetails}
                     isSaveVisible={this.isSaveVisible}
                     />
                 }

               {(window.location.pathname.includes('external-vendor-approver')) && isPage == 3 &&
                  <div className="dInBlock mr-20 mb-20" >
                     <button className="button-classic-doc" onClick={() => { this.uploadExternalApproverDoc.click() }}>Upload Document</button>
                     <input type='file' style={{ display: 'none' }} ref={(e) => { this.uploadExternalApproverDoc = e; }} onChange={e => this.externalApproverDocUpload(e)} onClick={(e) => { e.target.value = null }} />
                  </div>
               }
               {!(window.location.pathname.includes('external-vendor-approver'))&&(window.location.pathname.includes('vendor-approver')) && isPage == 3 &&
                  <div className="dInBlock mr-20 mb-20 mt-10" >
                     <button className="button-classic-doc" onClick={() => { this.uploadApproverDoc.click() }}>Upload approver Document</button>
                     <input type='file' style={{ display: 'none' }} ref={(e) => { this.uploadApproverDoc = e; }} onChange={e => this.ApproverDocUpload(e)} onClick={(e) => { e.target.value = null }} />
                  </div>
               }

               {isPage == 3 &&
                  <>
                     {(this.state.external_approver_docs || []).map((type, fId) => {
                        return (
                           <Fragment>
                              {fId === 0 && type.name && type.name.length > 0 && <div className="mb-15"><b>New Files to Upload:</b> </div>}

                              {type.name &&
                                 <div className="mb-15 flex">
                                    <span className="grey">{type.docName} - </span>
                                    {type.name}
                                    <span className="error" style={{ cursor: 'pointer', marginLeft: '20px' }} onClick={() => { this.handleRemoveExternalFileUpload(type, fId) }}> X </span>
                                 </div>}
                           </Fragment>
                        )
                     })}
                  </>
               }
               {isPage == 3 &&
                  <>
                     {(this.state.approver_docs || []).map((type, fId) => {
                        return (
                           <Fragment>
                              {fId === 0 && type.name && type.name.length > 0 && <div className="mb-15"><b>New Files to Upload:</b> </div>}

                              {type.name &&
                                 <div className="mb-15 flex">
                                    <span className="grey">{type.docName} - </span>
                                    {type.name}
                                    <span className="error" style={{ cursor: 'pointer', marginLeft: '20px' }} onClick={() => { this.handleRemoveFileUpload(type, fId) }}> X </span>
                                 </div>}
                           </Fragment>
                        )
                     })}
                  </>
               }
               <div className='DocsTab'>
                           <div className='approverdocs'>
                                 {((window.location.pathname.includes('external-vendor-approver') || window.location.pathname.includes('vendor-approver')||window.location.pathname.includes('vendor-headoffice-approver')) && isPage == 3 && vendorForm && vendorForm.vendorDefaultData && vendorForm.vendorDefaultData.approver_documents && vendorForm.vendorDefaultData.approver_documents.length > 0 && vendorForm.vendorDefaultData.approver_documents || []).map((type, fId) => {
                                    return (
                                       <Fragment>
                                          {fId === 0 && <div className="mb-15"><b>Approver Documents:-</b> </div>}
                                          <div className="mb-15 flex">
                                             <a href={type.url} download className='files-link'>{type.name}</a>&nbsp;&nbsp;<span>by: &nbsp;{type.updated_by}</span>
                                          </div>
                                       </Fragment>
                                    )
                                 })}
                           </div>
                           <div className='externaldocs' data-html2canvas-ignore >
                                 {((window.location.pathname.includes('external-vendor-approver') || window.location.pathname.includes('vendor-approver')||window.location.pathname.includes('vendor-headoffice-approver')||window.location.pathname.includes('vendor-bank-approver')) && isPage == 3 && vendorForm && vendorForm.vendorDefaultData && vendorForm.vendorDefaultData.external_documents && vendorForm.vendorDefaultData.external_documents.length > 0 && vendorForm.vendorDefaultData.external_documents || []).map((type, fId) => {
                                    return (
                                       <Fragment>
                                          {fId === 0 && <div className="mb-15"><b>External Uploaded Documents:-</b> </div>}
                                          <div className="mb-15 flex">
                                             <a href={type.url} download className='files-link'>{type.name}</a>
                                          </div>
                                       </Fragment>
                                    )
                                 })}
                           </div>
               </div>

               {this.state.external_approver_docs && this.state.external_approver_docs.length > 0 &&
                  <button
                     className={"mb-25 button-classic-doc mr-20 upload-button"}
                     style={{ width: '100px' }}
                     onClick={this.uploadExternalApproverDocument}>
                     Save
                  </button>
               }
               {this.state.approver_docs && this.state.approver_docs.length > 0 &&
                  <button
                     className={"mb-25 button-classic-doc mr-20 upload-button"}
                     style={{ width: '100px' }}
                     onClick={this.uploadApproverDocument}>
                     Save 
                  </button>
               }

               {(isSeeker) && !userData.is_admin && !(window.location.pathname.includes('vendor-read')) && (window.location.pathname.includes('vendor-bank-approver')) && isPage == 3 &&
                  <button data-html2canvas-ignore className="mb-25 button-classic mr-20" style={{ width: "100%" }} onClick={() => { this.setState({ isBankDetailsPopup: true }) }}> Bank Security Details </button>
               }

               {(isSeeker) && !userData.is_admin && approvalItem.length === 0 && !(window.location.pathname.includes('vendor-read')) && ((window.location.pathname.includes('vendor-bank-approver') ? data.bank_details : true)) && isPage == 3 &&
                  <button data-html2canvas-ignore className="mb-25 button-classic mr-20" style={{ width: "100%" }}  onClick={this.handleApprovalProcess}> Approval Process </button>
               }

               {this.state.isBankDetailsPopup &&
                  <BankDetailsPopup
                     getBasicVendorDetails={() => this.getBasicVendorDetails()}
                     vendorForm={vendorForm}
                     bank_details={(vendorForm && vendorForm.vendorDefaultData && vendorForm.vendorDefaultData.bank_details && vendorForm.vendorDefaultData.bank_details)}
                     bankApproverFiles={this.state.bankApproverFiles}
                     handleRemoveBankUploadFile={(id) => this.handleRemoveBankUploadFile(id)}
                     isBankDetailsPopup={this.state.isBankDetailsPopup}
                     onCancel={() => this.closeBankDetailPopup()}
                     bankDocumentUpload={(e, id) => this.bankDocumentUpload(e, id)}
                     documentUploadForBank={() => this.documentUploadForBank()}
                  />
               }

               {
                  ((this.state.isOpen && approvalObj && Object.keys(approvalObj || {}).length > 0) || (this.state.isOpen && (window.location.pathname.includes('external-vendor-approver') || window.location.pathname.includes('vendor-approver')))) && //companyApp.ob_vendor_id===company.ob_vendor_id &&
                  <div>
                        <div className="msg-pop-up">
                           <div className='pop'>
                        <div className="msg-section">
                              
                           
                              <span className="cancel-icon" onClick={() => { this.setState({ isOpen: false, isRejected: false, isResend: false })}}  style={{ backgroundImage: `url(${cancel})` }}></span>
                           <div className="msg"> Are You Sure To Process This Vendor Data For Validation? </div>
                           {(DEEPAK_COMPANY_ID.includes(userCompanyId) || AWL_COMPANY_IDS.includes(userCompanyId) || CARGILL_COMPANY_IDS.includes(userCompanyId)|| ABFRL_COMPANY_IDS.includes(userCompanyId)) && isSeeker && !userData.is_admin &&
                              <Fragment>
                                 <div className="mb-10">Rating:
                                    <StarRatings
                                       rating={this.state.rating}
                                       starRatedColor='rgb(243 236 5 / 85%)'
                                       changeRating={this.changeRating}
                                       numberOfStars={5}
                                       name='rating'
                                       starDimension='25px'
                                       starHoverColor='rgb(243 236 5 / 85%)'
                                    />
                                 </div>
                              </Fragment>
                           }
                           <div className="button-section">
                              {(!this.state.isRejected && !this.state.isResend) ?
                                 <Fragment>
                                    <div style={{ display: 'flex', alignItems:"center"}}>
                                       
                                       <button className="mb-25 button-classic" onClick={() => { this.setState({ isRejected: true }) }}> Reject </button>
                                       <button className="mb-25 button-classic" onClick={() => { this.setState({ isResend: true }) }}> Resend </button>
                                          <button className="mb-25 button-classic approveBtn" onClick={() => {
                                             if ((window.location.pathname.includes('vendor-headoffice-approver')) && AWL_COMPANY_IDS.includes(userCompanyId)) {
                                                this.setState({ headApprovalModal: true, isOpen: false })
                                             } else {
                                                this.handleApproval('APPROVED', approvalObj)
                                             }
                                          }}
                                          > Accept
                                          </button>

                                    </div>
                                 </Fragment>
                                 :
                                 <div className="form-textarea">
                                    <label>{this.state.isRejected ? 'Rejection Reason:' : 'Resend Reason'}</label>
                                    <textarea
                                       className="text-input-content"
                                       rows="5"
                                       cols="30"
                                       value={this.state.isRejected ? this.state.reason_for_rejection : this.state.reason_for_resend}
                                       placeholder="Content"
                                       onChange={(e) => {
                                          if (this.state.isRejected) {
                                             this.setState({ reason_for_rejection: e.target.value })
                                          } else {
                                             this.setState({ reason_for_resend: e.target.value })
                                          }
                                       }}
                                    />
                                    <div className="button-section">
                                       <button className="mb-25 button-classic mr-20"
                                          onClick={() => {
                                             if (this.state.isRejected) {
                                                this.handleApproval('REJECTED', approvalObj);
                                                this.setState({ isRejected: false });
                                             } else {
                                                this.handleApproval('RESEND', approvalObj);
                                                this.setState({ isResend: false });
                                             }

                                          }}
                                       > {this.state.isRejected ? 'Reject' : 'Resend'} </button>
                                         {ABFRL_COMPANY_IDS.includes(userCompanyId) ?null :<button className="mb-25 button-classic mr-20"
                                             onClick={() => { this.setState({ isRejected: false, isResend: false }); }}
                                          > Cancel </button>
                                          }
                                    </div>
                                 </div>
                              }
                           </div>
                        </div>
                        </div>
                        
                     </div>
                  </div>
               }

               {
                  this.state.headApprovalModal && (window.location.pathname.includes('vendor-headoffice-approver')) && AWL_COMPANY_IDS.includes(userCompanyId) &&
                  <div className="head-popup">
                     <div className="head-popup-section">
                        <p style={{ fontSize: '20px', fontWeight: 'bold', textAlign: 'center', color: '#528AEA' }}>External Approval</p>
                        <p style={{ fontSize: '15px', fontWeight: 'bold', marginTop: '40px' }}>Please select Yes to send for External Third party approval</p>
                        <div style={{ display: 'flex', margin: '20px auto', width: '100%', justifyContent: 'center' }}>
                           <button className="mb-25 button-classic" onClick={() => this.handleApproval('APPROVED', approvalObj, 'Yes')}> Yes </button>
                           <button className="mb-25 button-classic"
                              onClick={() => {
                                 this.handleApproval('APPROVED', approvalObj, 'No')
                                 this.setState({ headApprovalModal: false })
                              }}
                           > No
                           </button>
                        </div>
                     </div>
                  </div>
               }

            </div>
               </div>
         </div>

      );
   }
}

const mapStateToProps = state => {
   return {
      loader: state.loader,
      msgpopup: state.msgpopup,
      vendorForm: state.vendorForm,
      productList: state.master.itemList,
      vendorPaymentDetails: state.vendors.vendorPaymentDetails || [],
      vendorCurrencyDetails: state.vendors.vendorCurrencyDetails || [],
      incotermsOptions: state.vendors.incotermsOptions || [],
      // AllStatesForOperational : state.zonesList
   };
};

export default connect(mapStateToProps)(VendorForm);
