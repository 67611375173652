import React, { Component } from 'react';
import "./provider-user.less";
import Checkbox from "../../components/checkbox/checkbox";
import AddUserProvider from "./add-provider-user/add-provider-user";
import EditProviderUser from "./edit-provider-user/edit-provider-user";
import Button from "../button-classic/button-classic";
import Edit from "../../../assets/images/edit.svg";
import UserStatusConfirmationPopup from '../plants-depots/plants-depots-users/userStatusConfirmationPopup';

class ProviderUsers extends Component {
    constructor(props){
        super(props);
        this.state = {
            user : "",
            // selectedUserToEdit : {},
            addModalPopup : false,
            editModalPopup : false,
            selectedUserToEdit : null,
            showUserStatusChange: false,
            userSetToActive:null,
        }
    }

    componentDidMount () {
        this.props.getProviderUsers();
        this.props.fetchAllSeekers();
        this.props.getAllBranch(this.props.company_id, this.props.companyType);
        this.props.getDepartments(this.props.company_id)
    }
    toggleAdd = () => {
        this.setState({
            addModalPopup : !this.state.addModalPopup
        })
    }
    toggleEdit = (user) => {
        console.log(user);
        this.setState({
            editModalPopup : !this.state.editModalPopup,
            selectedUserToEdit : user
        })
    }

    checkBoxChange = (e,id,type,user) =>{
        const {showUserStatusChange}=this.state;
        // user.is_active = e.target.checked;
        const data = {
            company_type: user.company_type,
            company_id: user.company_id,
            "email":user.email,
            "branch_id": user.company_id,
            "user_id": user.user_id,
            is_active: e.target.checked,
         }
        this.setState({
            userSetToActive:data.is_active,
            showUserStatusChange: !showUserStatusChange,
            userStatusChangeData:data
         })
        // this.props.editSeeker(user);
    }

    downloadUserDeatils = () =>{
        this.props.downloadUserDeatils(this.props.company_id)
    }

    uploadProviderUserFile =(e)=>{
        this.props.uploadProviderUserFile(e, this.props.company_id);
    }
    shouldRenderUserStatusText=()=>{
        const {userSetToActive}=this.state;
        if(!!userSetToActive){
           return 'Are You Sure You Want To Activate The User ?'
        }
        return 'Are You Sure You Want To Dectivate The User ?'
     }
    onConfirmUserStatusChange=()=>{
        const {showUserStatusChange,userStatusChangeData}=this.state;
  
        this.props.toggleUserStatus(userStatusChangeData);
  
        this.setState({
         userSetToActive:null,
         showUserStatusChange: !showUserStatusChange,
         userStatusChangeData:null
        })
     }
     onCancelUserStatusChange=()=>{
        const {showUserStatusChange}=this.state;
        this.setState({
            userSetToActive:null,
            showUserStatusChange: !showUserStatusChange,
            userStatusChangeData:null
        }) 
     }

    render() {
        return (
           
            <div className = "plants-depots-main-wrap user-provider-root">
                <div className ="flex">
                <div className = "details-head">User Details</div>
                <div className = "details-head">
                <button className="button-classic" onClick={this.downloadUserDeatils}>  Download User</button>
                </div>
                 <div className = "details-head">
                 <button className="button-classic" onClick={() => {this.uploadExcel.click()}}> Upload User </button>
                  <input type='file' style ={{display :'none'}} ref={(e) => { this.uploadExcel = e ;}} onChange={this.uploadProviderUserFile} onClick={(e) => {e.target.value = null }}/>
                </div>  
                </div>
                <div className = "heading">
                    <div className = "head usn">Username</div>
                    <div className = "head email">Email</div>
                    <div className = "head phone">Mobile</div>
                    <div className = "head branch">Company</div>
                    <div className = "head role">Location</div>
                    <div className = "head active">
                        Status
                    </div>
                    <div className = "head actions">Actions</div>
                </div>
                { this.props.providersUsersList && this.props.providersUsersList.length == 0 &&
					  <div className="depot-users-list no-records"> That Is All, Nothing To Display!
                </div>
                }
                {
                   this.props.providersUsersList && this.props.providersUsersList.map((user,index) => {
                        return(
                            <div className = "depot-users-list" key = {index}>
                                <div className = "head usn">{user.user_name}</div>
                                <div className = "head email">{user.user_email}</div>
                                <div className = "head phone">{user.user_contact}</div>
                                <div className = "head branch">{user.company_name}</div>
                                <div className = "head role">{user.location}</div>
                                <div className = "head active">
                                    <Checkbox type = {user.company_type} status = {user.is_active} user = {user} id = {user.user_id} handleChange = {this.checkBoxChange}/>
                                </div>
                                {/* <div className = "head actions edit-delete"><button className = "add-button" onClick = {()=>this.toggleEdit(user)}>Edit</button></div> */}
                                <div className={'delete-icon'} style={{backgroundImage: `url(${Edit})`}} onClick={()=>this.toggleEdit(user)}></div>
                            </div>
                        )
                    })
                }
                <div className = "button-holder">
                    <div className = "new add-button" onClick = {this.toggleAdd}>+ New User</div>
                </div>
                {this.state.showUserStatusChange && 
               <UserStatusConfirmationPopup
                  displayText = {this.shouldRenderUserStatusText()}
                  userStatusChangeData={this.state.userStatusChangeData}
                  onConfirmUserStatusChange={this.onConfirmUserStatusChange}
                  onCancelUserStatusChange={this.onCancelUserStatusChange}
               />
               }
                {
                    this.state.addModalPopup && 
                    <AddUserProvider 
                        company_name = {this.props.company_name} 
                        company_id = {this.props.company_id} 
                        toggleAdd = {this.toggleAdd} 
                        seekersList = {this.props.seekersList} 
                        addSeekerUser = {this.props.addSeekerUser}
                        branchList={this.props.branchList} 
                        fetchBranches={this.props.fetchBranches}
                        departments = {this.props.departments}    
                        allPlantBranches={this.props.allPlantBranches}
                        company_type = {this.props.companyType}
                        companyDetails={this.props.companyDetails}
                        modeOfTransportList={this.props.modeOfTransportList}
                    />
                }
                {
                    this.state.editModalPopup &&
                    <EditProviderUser 
                        toggleEdit = {this.toggleEdit} 
                        seekersList = {this.props.seekersList} 
                        selectedUserToEdit = {this.state.selectedUserToEdit} 
                        editSeeker = {this.props.editSeeker}
                        branchList={this.props.branchList} 
                        fetchBranches={this.props.fetchBranches} 
                        departments = {this.props.departments} 
                        allPlantBranches={this.props.allPlantBranches}
                        companyDetails={this.props.companyDetails}
                        modeOfTransportList={this.props.modeOfTransportList}
                    />
                }

            </div>
        );
    }
}

export default ProviderUsers;
