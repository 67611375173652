import api from "../common/api_config";
import {getData, postData, fileUpload, deleteData, putData} from "../../utils/ajax";
import ACTION from "../common/action_constant";
import { put, call } from 'redux-saga/effects';
import apis from "../common/api_config";
import { getUserCompanyId } from "../../utils/common";
import { downloadUrl } from "../../utils/constants";


// function* master(action) {
// }

function* generateRouteExcel(action) {
    try{
        const routeImportUrl = api.routeImport + action.data;
        const data = yield call(getData, routeImportUrl);
        window.open(data.data.url);
        yield put({type: ACTION.MASTER.SAP_MODULE.STORE_ROUTE_EXCEL, data : data.data});
    }
    catch(error){
		yield put({ type : ACTION.MSG_DISPLAY.SHOW_MSG, data : { type : 1 , msg : "Could not import CSV file"}});
    }
    // ;
}

function* exportRouteExcel(action) {
    try{
        const data = yield call(fileUpload, api.routeExport, action.data);
		    yield put({ type : ACTION.MSG_DISPLAY.SHOW_MSG, data : { type : 2 , msg : "Successfully uploaded"}});
    }
    catch(error){
		    yield put({ type : ACTION.MSG_DISPLAY.SHOW_MSG, data : { type : 1 , msg : "Could not Upload CSV file"}});
    }
}

function* addNetworkChain(action) {
  try{
      yield put({
        type: ACTION.LOADER.SHOW_LOADER
      });
      const data = yield call(postData, api.addNetworkChain, action.data);
      yield put({ type : ACTION.MSG_DISPLAY.SHOW_MSG, data : { type : 1 , msg : "Data Added Successfully"}});
      yield put({ type:ACTION.MASTER.LOCATION_NETWORK.GET_NETWORK_CHAIN , data : action.param });
      yield put({
        type: ACTION.LOADER.HIDE_LOADER
      });
      if(action.onSuccess){
        action.onSuccess()
      }
  }
  catch(error){
    yield put({
      type: ACTION.LOADER.HIDE_LOADER
    });
    yield put({ type : ACTION.MSG_DISPLAY.SHOW_MSG, data : { type : 2 , msg :  error.responseJSON && error.responseJSON.message ? error.responseJSON.message : "Unable to add the data"}});
  }
}

function* updateNetworkChain(action) {
  debugger
    try{
      yield put({
        type: ACTION.LOADER.SHOW_LOADER
      });
      const data = yield call(putData, api.updateNetworkChain, JSON.stringify(action.data));
      yield put({ type : ACTION.MSG_DISPLAY.SHOW_MSG, data : { type : 1 , msg : "Data Updated Successfully"}});
      yield put({
        type: ACTION.LOADER.HIDE_LOADER
      });
      if(action.onSuccess){
        action.onSuccess()
      }
  }
  catch(error){
    yield put({
      type: ACTION.LOADER.HIDE_LOADER
    });
    yield put({ type : ACTION.MSG_DISPLAY.SHOW_MSG, data : { type : 2 , msg : error.message ? error.message : "Unable to update the data"}});
    }
}

function* getNetworkChain(action) {
  try{
    yield put({
      type: ACTION.LOADER.SHOW_LOADER
    });
    const data = yield call(getData, api.getNetworkChain+action.data);
    yield put({ type : ACTION.MASTER.LOCATION_NETWORK.STORE_NETWORK_CHAIN, data: data.data.list });
    yield put({
      type: ACTION.LOADER.HIDE_LOADER
    });
  }
  catch(error){
    yield put({
      type: ACTION.LOADER.HIDE_LOADER
    });
    yield put({ type : ACTION.MSG_DISPLAY.SHOW_MSG, data : { type : 2 , msg : error.message ? error.message : "Unable to fetch the details"}});
  }
}

function* deleteNetworkChain(action) {
  try{
    yield put({
      type: ACTION.LOADER.SHOW_LOADER
    });
    const data = yield call(deleteData, api.deleteNetworkChain, JSON.stringify(action.data));
    yield put({ type : ACTION.MSG_DISPLAY.SHOW_MSG, data : { type : 1 , msg : "Data Deleted Successfully"}});
    yield put({
      type: ACTION.LOADER.HIDE_LOADER
    });
    yield put({ type:ACTION.MASTER.LOCATION_NETWORK.GET_NETWORK_CHAIN , data : action.param });
  }
  catch(error){
    yield put({
      type: ACTION.LOADER.HIDE_LOADER
    });
    yield put({ type : ACTION.MSG_DISPLAY.SHOW_MSG, data : { type : 2 , msg : error ? error.message : "Unable to delete the data"}});
  }

}
function* addDepotConsignee(action) {
  try{
      yield put({
        type: ACTION.LOADER.SHOW_LOADER
      });
      const data = yield call(postData, api.addDepotConsignee, action.data);
      yield put({ type : ACTION.MSG_DISPLAY.SHOW_MSG, data : { type : 1 , msg : "Data Added Successfully"}});
      // yield put({ type:ACTION.MASTER.LOCATION_NETWORK.GET_DEPOT_CONSIGNEE , data : action.param });
      if(action.onSuccess){
        action.onSuccess()
      }
      yield put({
        type: ACTION.LOADER.HIDE_LOADER
      });
  }
  catch(error){
    yield put({
      type: ACTION.LOADER.HIDE_LOADER
    });
    yield put({ type : ACTION.MSG_DISPLAY.SHOW_MSG, data : { type : 2 , msg : error.message ? error.message : "Unable to add the data"}});
  }
}

function* updateDepotConsignee(action) {


    try{
      yield put({
        type: ACTION.LOADER.SHOW_LOADER
      });
      const data = yield call(putData, api.updateDepotConsignee, JSON.stringify(action.data));
      yield put({ type : ACTION.MSG_DISPLAY.SHOW_MSG, data : { type : 1 , msg : "Data Updated Successfully"}});
      if(action.onSuccess){
        action.onSuccess()
      }
      yield put({
        type: ACTION.LOADER.HIDE_LOADER
      });
  }
  catch(error){
    yield put({
      type: ACTION.LOADER.HIDE_LOADER
    });
    yield put({ type : ACTION.MSG_DISPLAY.SHOW_MSG, data : { type : 2 , msg : error.message ? error.message : "Unable to update the data"}});
    }
}

function* getDepotConsignee(action) {

  try{
    yield put({
      type: ACTION.LOADER.SHOW_LOADER
    });
    const data = yield call(getData, api.getDepotConsignee+action.params);
    yield put({ type : ACTION.MASTER.LOCATION_NETWORK.STORE_DEPOT_CONSIGNEE, data: data.data });
    
    yield put({
      type: ACTION.LOADER.HIDE_LOADER
    });
  }
  catch(error){
    yield put({
      type: ACTION.LOADER.HIDE_LOADER
    });
    yield put({ type : ACTION.MSG_DISPLAY.SHOW_MSG, data : { type : 2 , msg : error.message ? error.message : "Unable to fetch the details"}});
  }
}
function* deleteDepotConsignee(action) {
  try{
    yield put({
      type: ACTION.LOADER.SHOW_LOADER
    });
    const data = yield call(deleteData, api.deleteDepotConsignee + action.params);
    yield put({ type : ACTION.MSG_DISPLAY.SHOW_MSG, data : { type : 1 , msg : "Data Deleted Successfully"}});
    if(action.onSuccess){
      action.onSuccess()
    }
    yield put({
      type: ACTION.LOADER.HIDE_LOADER
    });
    
  }
  catch(error){
    yield put({
      type: ACTION.LOADER.HIDE_LOADER
    });
    yield put({ type : ACTION.MSG_DISPLAY.SHOW_MSG, data : { type : 2 , msg : error ? error.message : "Unable to delete the data"}});
  }
}
function* downloadDepotConsigneeTemp(action) {
  try {
    yield put({
      type: ACTION.LOADER.SHOW_LOADER
    });

    const data = yield call(getData, api.downloadDepotConsigneeTemp, { param: action.param });

    if (action.onSuccess) {
      action.onSuccess(data);
    }

    // Dispatch an action to show the success message
    yield put({ type: ACTION.MSG_DISPLAY.SHOW_MSG, data: { type: 1, msg: "File Downloaded Successfully" } });

    yield put({
      type: ACTION.LOADER.HIDE_LOADER
    });
  } catch (error) {
    yield put({
      type: ACTION.LOADER.HIDE_LOADER
    });
    yield put({ type: ACTION.MSG_DISPLAY.SHOW_MSG, data: { type: 2, msg: "Unable to download the file" } });
  }
}


function* downloadDepotConsignee(action) {
  try{
    yield put({
      type: ACTION.LOADER.SHOW_LOADER
    });
    const data = yield call(getData, api.downloadDepotConsignee+action.param);
    if(action.onSuccess) {
      action.onSuccess(data);
    }
    yield put({ type : ACTION.MSG_DISPLAY.SHOW_MSG, data : { type : 1 , msg : data.message}});
    yield put({
      type: ACTION.LOADER.HIDE_LOADER
    });
  }
  catch(error){
    yield put({
      type: ACTION.LOADER.HIDE_LOADER
    });
    yield put({ type : ACTION.MSG_DISPLAY.SHOW_MSG, data : { type : 2 , msg : "Unable to download the file"}});
  }
}
function* uploadDepotConsignee(action) {
  try{
    yield put({
      type: ACTION.LOADER.SHOW_LOADER
    });
    const data = yield call(fileUpload, api.uploadDepotConsignee+action.companyId, action.data);
    yield put({ type : ACTION.MSG_DISPLAY.SHOW_MSG, data : { type : 1 , msg : (data && data.message) || "Data Uploaded Successfully"}});
    if(action.onSuccess){
      action.onSuccess()
    }
    yield put({
      type: ACTION.LOADER.HIDE_LOADER
    });
  }
  catch(error){
    yield put({
      type: ACTION.LOADER.HIDE_LOADER
    });
    yield put({ type : ACTION.MSG_DISPLAY.SHOW_MSG, data : { type : 2 , msg : "Unable to upload the data"}});
  }
}

function* getCommonConstants(action) {
  try{
    const data = yield call(getData, api.getConstantValues);
    yield put({ type : ACTION.MASTER.INVENTORY_ITEM.STORE_COMMON_CONSTANTS, data: data.data });
  } catch(error) {

  }
}

function* getInventoryItem(action) {
  try{
    yield put({
      type: ACTION.LOADER.SHOW_LOADER
    });
    const data = yield call(getData, api.getInventoryItem + action.param );
    yield put({ 
      type : ACTION.MASTER.INVENTORY_ITEM.STORE_INVENTORY_ITEM, 
      data : data.data.list.productMasters 
    });
    yield put({
      type: ACTION.LOADER.HIDE_LOADER
    });
  } catch(error) {
    yield put({
      type: ACTION.LOADER.HIDE_LOADER
    });
    yield put({
      type: ACTION.MSG_DISPLAY.SHOW_MSG,
      data: { type: 2, msg: error.responseJSON.message }
    });
  }
}

function* addInventoryItem(action) {
  try{
    yield put({
      type: ACTION.LOADER.SHOW_LOADER
    });
    const data = yield call(postData, api.addInventoryItem, action.data );
    yield put({ 
      type : ACTION.MASTER.INVENTORY_ITEM.GET_INVENTORY_ITEM, 
      param : `${action.data.company_id}&count=50&offset=0` 
    });
    yield put({ type: ACTION.MASTER.INVENTORY_ITEM.DOWNLOAD_INVENTORY_ITEM , company_id: action.data.company_id });
    yield put({
      type: ACTION.LOADER.HIDE_LOADER
    });
    yield put({ type : ACTION.MSG_DISPLAY.SHOW_MSG, data : { type : 1 , msg : "Added Successfully"}});
  } catch(error) {
    yield put({
      type: ACTION.LOADER.HIDE_LOADER
    });
    yield put({
      type: ACTION.MSG_DISPLAY.SHOW_MSG,
      data: { type: 2, msg: error.responseJSON.message }
    });
  }
}


function* updateInventoryItem(action) {
  try{
    yield put({
      type: ACTION.LOADER.SHOW_LOADER
    });
    const data = yield call(postData, api.updateInventoryItem, action.data );
    yield put({ 
      type : ACTION.MASTER.INVENTORY_ITEM.GET_INVENTORY_ITEM, 
      param : `${action.data.company_id}&count=50&offset=0` 
    });
    yield put({ type: ACTION.MASTER.INVENTORY_ITEM.DOWNLOAD_INVENTORY_ITEM, company_id: action.data.company_id   });
    yield put({
      type: ACTION.LOADER.HIDE_LOADER
    });
    yield put({ type : ACTION.MSG_DISPLAY.SHOW_MSG, data : { type : 1 , msg : "Updated Successfully"}});
  } catch(error) {
      yield put({
        type: ACTION.LOADER.HIDE_LOADER
      });
      yield put({
        type: ACTION.MSG_DISPLAY.SHOW_MSG,
        data: { type: 2, msg: error.responseJSON.message }
      });
  }
}

function* deleteInventoryItem(action) {
  try{
    yield put({
      type: ACTION.LOADER.SHOW_LOADER
    });
    const data = yield call(deleteData, api.deleteInventoryItem + action.data);
    yield put({ 
      type : ACTION.MASTER.INVENTORY_ITEM.GET_INVENTORY_ITEM, 
      param : `${action.company_id}&count=50&offset=0`
    });
    yield put({
      type: ACTION.LOADER.HIDE_LOADER
    });
    yield put({ type : ACTION.MSG_DISPLAY.SHOW_MSG, data : { type : 1 , msg : "Data Deleted Successfully" }});
    yield put({ type: ACTION.MASTER.INVENTORY_ITEM.DOWNLOAD_INVENTORY_ITEM, company_id: action.company_id });
  } catch(error) {
    yield put({
      type: ACTION.LOADER.HIDE_LOADER
    });
    yield put({
      type: ACTION.MSG_DISPLAY.SHOW_MSG,
      data: { type: 2, msg: error && error.responseJSON ? error.responseJSON.message : 'Unable to delete the data' }
    });
  }
}

function* uploadInventoryItem(action) {
  try{
    yield put({
      type: ACTION.LOADER.SHOW_LOADER
    });
    const data = yield call(fileUpload, api.uploadInventoryItem+action.company_id, action.data);
    yield put({ 
      type : ACTION.MASTER.INVENTORY_ITEM.GET_INVENTORY_ITEM, 
      param : `${action.company_id}&count=50&offset=0` 
    });
    yield put({ type: ACTION.MASTER.INVENTORY_ITEM.DOWNLOAD_INVENTORY_ITEM, company_id : action.company_id });
    yield put({ type : ACTION.MSG_DISPLAY.SHOW_MSG, data : { type : 1 , msg : "File Uploaded Successfully"}});

  } catch(error) {
    yield put({
      type: ACTION.LOADER.HIDE_LOADER
    });
    yield put({
      type: ACTION.MSG_DISPLAY.SHOW_MSG,
      data: { type: 2, msg: error && error.responseJSON &&  error.responseJSON.result ? error.responseJSON.result.message : "Unable to upload the file" }
    });
  }
}

function* downloadInventoryItem(action) {
  try{
     const data = yield call(getData, api.downloadInventoryItem + action.company_id);
     yield put({ type : ACTION.MASTER.INVENTORY_ITEM.STORE_INVENTORY_DOWNLOAD_LINK, data : data.data[0].Link })
  } catch(error) {
    console.log(error);
    yield put({
      type: ACTION.MSG_DISPLAY.SHOW_MSG,
      data: { type: 2, msg: error && (error.responseJSON || {}).message }
    });
  }
}

function* downloadTruckDetails(action) {
  try{
     const data = yield call(getData, api.downloadTruckDetails + action.company_id);
     if(action.onSuccess){
       action.onSuccess(data.data)
     }
     yield put({ type : ACTION.MASTER.VEHICLE_PLANNING.STORE_TRUCK_DOWNLOAD_LINK, data : data.data[0].Link })
  } catch(error) {
    console.log(error);
      yield put({
      type: ACTION.MSG_DISPLAY.SHOW_MSG,
      data: { type: 2, msg: error.responseJSON.message }
    });
  }
}

function* downloadTruckTemp(action) {
  try{
     const data = yield call(getData, api.downloadTruckTemp + action.company_id);
     if(action.onSuccess){
      action.onSuccess(data.data)
    }
     yield put({ type : ACTION.MASTER.VEHICLE_PLANNING.STORE_TRUCK_DOWNLOAD_TEMP_LINK, data : data.data[0].Link })
  } catch(error) {
    console.log(error);
  }
}

function* getAccountEvents(action) {
  try{
    yield put({
      type: ACTION.LOADER.SHOW_LOADER
    });
    const data = yield call(getData, api.getAccountEvents+action.data);
    let actionConst;
    if(action.data == 1){
      actionConst = ACTION.MASTER.ACCOUNTS.STORE_ACCOUNT_DETAILS_1;
    }
    else if(action.data == 2){
        actionConst = ACTION.MASTER.ACCOUNTS.STORE_ACCOUNT_DETAILS_2;
    }
    else if(action.data == 3){
        actionConst = ACTION.MASTER.ACCOUNTS.STORE_ACCOUNT_DETAILS_3;
    }
    else if(action.data == 4){
        actionConst = ACTION.MASTER.ACCOUNTS.STORE_ACCOUNT_DETAILS_4;
    }
    else if(action.data == 5){
        actionConst = ACTION.MASTER.ACCOUNTS.STORE_ACCOUNT_DETAILS_5;
    }
    else if(action.data == 6){
        actionConst = ACTION.MASTER.ACCOUNTS.STORE_ACCOUNT_DETAILS_6;
    } 
    else if(action.data == 7){
      actionConst = ACTION.MASTER.ACCOUNTS.STORE_ACCOUNT_DETAILS_7;
    }
    yield put({
      type: actionConst,
      data: data.data
    });
    yield put({
      type: ACTION.LOADER.HIDE_LOADER
    })
  }catch(error) {
    yield put({
      type: ACTION.LOADER.HIDE_LOADER
    });
    yield put({
      type: ACTION.MSG_DISPLAY.SHOW_MSG,
      data: { type: 2, msg: error.responseJSON.result.message }
    });
  }
}

function* addAccountEvents(action) {
  try{
    yield put({
      type: ACTION.LOADER.SHOW_LOADER
    });
    const data = yield call(postData, api.postAccountEvents, action.data);
    yield put({ type: ACTION.MASTER.ACCOUNTS.GET_ACCOUNT_DETAILS, data: action.data.type });
    yield put({
      type: ACTION.MSG_DISPLAY.SHOW_MSG,
      data: { type: 1, msg: "Event Added Successfully" }
    });
    yield put({
      type: ACTION.LOADER.HIDE_LOADER
    });
  }catch(error) {
    yield put({
      type: ACTION.LOADER.HIDE_LOADER
    });
    yield put({
      type: ACTION.MSG_DISPLAY.SHOW_MSG,
      data: { type: 2, msg: error.responseJSON.result.message }
    });
  }
}

function* deleteAccountEvents(action) {
  try{
    yield put({
      type: ACTION.LOADER.SHOW_LOADER
    });
    const data = yield call(deleteData, api.deleteAccountEvents, JSON.stringify(action.data));
    yield put({ type: ACTION.MASTER.ACCOUNTS.GET_ACCOUNT_DETAILS, data: action.data.type });
    yield put({
      type: ACTION.MSG_DISPLAY.SHOW_MSG,
      data: { type: 1, msg: 'Event Deleted Sucecssfully' }
    });
    yield put({
      type: ACTION.LOADER.HIDE_LOADER
    });
  }catch(error) {
    yield put({
      type: ACTION.LOADER.HIDE_LOADER
    });
    yield put({
      type: ACTION.MSG_DISPLAY.SHOW_MSG,
      data: { type: 2, msg: error.responseJSON.result.message }
    });
  }
}

function* uploadNetworkChain(action) {
  try{
    yield put({
      type: ACTION.LOADER.SHOW_LOADER
    });
    const data = yield call(fileUpload, api.uploadNetworkChain+action.companyId, action.data);
    yield put({ type : ACTION.MSG_DISPLAY.SHOW_MSG, data : { type : 1 , msg : (data && data.message) || "Data Uploaded Successfully"}});
    yield put({ type:ACTION.MASTER.LOCATION_NETWORK.GET_NETWORK_CHAIN , data : action.param });
    yield put({
      type: ACTION.LOADER.HIDE_LOADER
    });
  }
  catch(error){
    yield put({
      type: ACTION.LOADER.HIDE_LOADER
    });
    yield put({ type : ACTION.MSG_DISPLAY.SHOW_MSG, data : { type : 2 , msg : "Unable to upload the data"}});
  }
}

function* downloadNetworkChain(action) {
  try{
    yield put({
      type: ACTION.LOADER.SHOW_LOADER
    });
    const data = yield call(getData, api.downloadNetworkChain+action.param);
    if(action.onSuccess) {
      action.onSuccess(data);
    }
    yield put({ type:ACTION.MASTER.LOCATION_NETWORK.GET_NETWORK_CHAIN , data : action.param });
    yield put({
      type: ACTION.LOADER.HIDE_LOADER
    });
  }
  catch(error){
    yield put({
      type: ACTION.LOADER.HIDE_LOADER
    });
    yield put({ type : ACTION.MSG_DISPLAY.SHOW_MSG, data : { type : 2 , msg : "Unable to download the file"}});
  }
}

function* downloadNetworkTemp(action) {
  try{
    yield put({
      type: ACTION.LOADER.SHOW_LOADER
    });
    const data = yield call(getData, api.downloadNetworkTemp+action.param);
    if(action.onSuccess) {
      action.onSuccess(data);
    }
    yield put({ type:ACTION.MASTER.LOCATION_NETWORK.GET_NETWORK_CHAIN , data : action.param });
    yield put({
      type: ACTION.LOADER.HIDE_LOADER
    });
  }
  catch(error){
    yield put({
      type: ACTION.LOADER.HIDE_LOADER
    });
    yield put({ type : ACTION.MSG_DISPLAY.SHOW_MSG, data : { type : 2 , msg : "Unable to download the file"}});
  }
}

function* getPackagingDetails(action) {
  try{
    const data = yield call(getData, api.getPackagingDetails);
    yield put({ type: ACTION.MASTER.INVENTORY_ITEM.STORE_PACKAGING_DETAILS , data : data.data ? data.data.list : [] });
  }
  catch(error){
    console.log(error);
  }
}

function* addCompanyTransporters(action) {
  try {
    yield put({
      type: ACTION.LOADER.SHOW_LOADER
    });
    const url = `${api.addCompanyTransporters}${action.companyId}&branchId=${action.branchId}&companyTypeToAdd=${action.companyTypeToAdd}`;
    yield call(postData, url, action.data);
    if(action.onSuccess) {
      action.onSuccess();
    }
    yield put({ type: ACTION.MASTER.TRANSPORTERS.GET_COMPANY_TRANSPORTERS, companyId: action.companyId, branchId: action.branchId, isMultimode: action.data.is_multimode ? 2 : action.data.is_multimode, isWarehouse: action.data.is_warehouse && 3 || undefined,  isExim: action.data.is_exim && 4 || undefined});
    yield put({
      type: ACTION.LOADER.HIDE_LOADER
    });
  } catch(e) {
    yield put({
      type: ACTION.LOADER.HIDE_LOADER
    });
  }
}

function* getCompanyTransporters(action) {
  try{
      yield put({
        type: ACTION.LOADER.SHOW_LOADER
      });
      const url = `${api.getCompanyTransporters}${action.companyId}&branchId=${action.branchId}${action.isMultimode ? `&isMultimode=${action.isMultimode}` : ''}${action.isWarehouse ? `&isWarehouse=${action.isWarehouse}` : ''}${action.isExim ? `&isExim=${action.isExim}` : ''}`
      const data = yield call(getData, url);
      let actionConst;
      if(action.companyId === action.branchId)  {
        actionConst = ACTION.MASTER.TRANSPORTERS.STORE_COMPANY_TRANSPORTERS;
      } else {
        actionConst = ACTION.MASTER.TRANSPORTERS.STORE_BRANCH_TRANSPORTERS;
      }
      yield put({
        type: actionConst,
        data: data.data || []
      })
      yield put({
        type: ACTION.LOADER.HIDE_LOADER
      });
  } catch(e) {
      yield put({
        type: ACTION.LOADER.HIDE_LOADER
      });

  }
}

function* deleteCompanyTransporters(action) {
  try {
    yield put({
      type: ACTION.LOADER.SHOW_LOADER
    });
    const url = `${api.deleteCompanyTransporters}${action.companyId}&branchId=${action.branchId}&transporterId=${action.transporterId}&companyTypeToDelete=${action.companyTypeToDelete}${!action.isWarehouse ? `&isMultimode=${action.isMultimode ? action.isMultimode : 1}` : ''}${action.isWarehouse ? `&isWarehouse=${action.isWarehouse}` : ''}${action.isExim ? `&isExim=${action.isExim}` : ''}`
    yield call(deleteData, url);
    yield put({
      type: ACTION.LOADER.HIDE_LOADER
    });
    yield put({ type: ACTION.MASTER.TRANSPORTERS.GET_COMPANY_TRANSPORTERS, companyId: action.companyId, branchId: action.branchId, isMultimode: action.isMultimode, isWarehouse: action.isWarehouse, isExim: action.isExim });

  }catch(e) {
    yield put({
      type: ACTION.LOADER.HIDE_LOADER
    });
  }
}
function* getTruckDetails(action) {
  try{
    yield put({
      type: ACTION.LOADER.SHOW_LOADER
    });
    const data = yield call(getData, api.getTruckDetails + action.param );
    yield put({ 
      type : ACTION.MASTER.VEHICLE_PLANNING.STORE_TRUCK_DETAILS, 
      data: data.data.list || []
    });
    yield put({
      type: ACTION.LOADER.HIDE_LOADER
    });
  } catch(error) {
    yield put({
      type: ACTION.LOADER.HIDE_LOADER
    });
    yield put({
      type: ACTION.MSG_DISPLAY.SHOW_MSG,
      data: { type: 2, msg: error.responseJSON.message }
    });
  }
}



function* addTruckDetails(action) {
  try{
    yield put({
      type: ACTION.LOADER.SHOW_LOADER
    });
    const data = yield call(postData, api.addTruckDetails, action.data );
    yield put({ 
      type : ACTION.MASTER.VEHICLE_PLANNING.GET_TRUCK_DETAILS, 
      param : `${action.data.company_id}&limit=50&offset=0` 
    });
    yield put({ type: ACTION.MASTER.VEHICLE_PLANNING.DOWNLOAD_VEHICLE_PLANNING , company_id: action.data.company_id });
    yield put({
      type: ACTION.LOADER.HIDE_LOADER
    });
    yield put({ type : ACTION.MSG_DISPLAY.SHOW_MSG, data : { type : 1 , msg : "Added Successfully"}});
  } catch(error) {
    yield put({
      type: ACTION.LOADER.HIDE_LOADER
    });
    yield put({
      type: ACTION.MSG_DISPLAY.SHOW_MSG,
      data: { type: 2, msg: error.responseJSON.message }
    });
  }
}


function* updateTruckDetails(action) {
  try{
    yield put({
      type: ACTION.LOADER.SHOW_LOADER
    });
     const data = yield call(putData, api.updateTruckDetails, JSON.stringify(action.data));
    yield put({ 
      type : ACTION.MASTER.VEHICLE_PLANNING.GET_TRUCK_DETAILS, 
      param : `${action.data.company_id}&limit=50&offset=0` 
    });
    
    yield put({ type : ACTION.MSG_DISPLAY.SHOW_MSG, data : { type : 1 , msg : (data && data.message) || "Updated Successfully"}});
  } catch(error) {
      yield put({
        type: ACTION.LOADER.HIDE_LOADER
      });
      yield put({
        type: ACTION.MSG_DISPLAY.SHOW_MSG,
        data: { type: 2, msg: error.responseJSON.message }
      });
  }
}



function* deleteTruckDetails(action) {
	try {
		yield put({
			type: ACTION.LOADER.SHOW_LOADER
		})
		yield call(deleteData, api.deleteTruckDetails, JSON.stringify(action.data));
		yield put({
			type: ACTION.MASTER.VEHICLE_PLANNING.GET_TRUCK_DETAILS,
      param : `${action.company_id}&limit=50&offset=0`
		})
		yield put({
			type: ACTION.MSG_DISPLAY.SHOW_MSG,
			data: { type: 1, msg: "Truck deleted Successfully" }
		})
		yield put({
			type: ACTION.LOADER.HIDE_LOADER
		})
	} catch (e) {
		yield put({
			type: ACTION.LOADER.HIDE_LOADER
		})
		yield put({
			type: ACTION.MSG_DISPLAY.SHOW_MSG,
      			// type: ACTION.MSG_DISPLAY.SHOW_MSG,
            data: { type: 2, msg: e && e.responseJSON ? e.responseJSON.message : "Something Went Wrong" }
		})
	}
}


function* uploadTruckDetails(action) {
  try{
    yield put({
      type: ACTION.LOADER.SHOW_LOADER
    });
    const data = yield call(fileUpload, api.uploadTruckDetails+action.company_id, action.data);
    yield put({ 
      type : ACTION.MASTER.VEHICLE_PLANNING.GET_TRUCK_DETAILS, 
      param : `${action.company_id}&limit=20&offset=0` 
    });
    yield put({ type: ACTION.MASTER.VEHICLE_PLANNING.DOWNLOAD_TRUCK_DETAILS, company_id : action.company_id });
    yield put({ type : ACTION.MSG_DISPLAY.SHOW_MSG, data : { type : 1 , msg : (data && data.message) || "File Uploaded Successfully"}});

  } catch(error) {
    yield put({
      type: ACTION.LOADER.HIDE_LOADER
    });
    yield put({
      type: ACTION.MSG_DISPLAY.SHOW_MSG,
      data: { type: 2, msg: error && error.responseJSON &&  error.responseJSON.result ? error.responseJSON.result.message : "Unable to upload the file" }
    });
  }
}

function* getVehicleSize(action) {
  try{
    const data = yield call(getData, api.getVehicleSize);
    yield put({ type: ACTION.MASTER.VEHICLE_PLANNING.STORE_VEHICLE_SIZE_DETAILS , data : data.data ? data.data.list : [] });
  }
  catch(error){
    console.log(error);
  }
}

function* getGpsVendorData(action) {
  try{
    const companyId = getUserCompanyId()
    const data = yield call(getData, api.getGpsVendorData + `?seeker_id=${companyId}` , true);
    yield put({
       type: ACTION.MASTER.VEHICLE_PLANNING.GET_GPS_VENDOR_DATA_SUCCESS , 
       data : data.data ? data.data.results : [] });
  }
  catch(error){
    console.log(error);
  }
}

function* getTransporterDetails(action) {
  try{
    const data = yield call(getData, api.getTransporterDetails);
    yield put({ type: ACTION.MASTER.VEHICLE_PLANNING.STORE_TRANSPORTER_DETAILS , data : data.data ? data.data.list : [] });
  }
  catch(error){
    console.log("Error",error);
  }
}

// function* getTruckDriverDetails(action) {
//   try{
//     yield put({
//       type: ACTION.LOADER.SHOW_LOADER
//     });
//     const data = yield call(getData, api.getTruckDriverDetails + action.param );
//     yield put({ 
//       type : ACTION.MASTER.VEHICLE_PLANNING.STORE_TRUCK_DRIVER_DETAILS, 
//       data: data.data.list || []
//     });
//     yield put({
//       type: ACTION.LOADER.HIDE_LOADER
//     });
//   } catch(error) {
//     yield put({
//       type: ACTION.LOADER.HIDE_LOADER
//     });
//     yield put({
//       type: ACTION.MSG_DISPLAY.SHOW_MSG,
//       data: { type: 2, msg: error.responseJSON.message }
//     });
//   }
// }

function* getTruckDriverDetails(action) {
  try{
    yield put({
      type: ACTION.LOADER.SHOW_LOADER
    });
    const data = yield call(getData, api.getTruckDriverDetails + action.param );
    yield put({ 
      type : ACTION.MASTER.VEHICLE_PLANNING.STORE_TRUCK_DRIVER_DETAILS, 
      data: data.data.list || []
    });
    yield put({
      type: ACTION.LOADER.HIDE_LOADER
    });
  } catch(error) {
    yield put({
      type: ACTION.LOADER.HIDE_LOADER
    });
    yield put({
      type: ACTION.MSG_DISPLAY.SHOW_MSG,
      data: { type: 2, msg: error.responseJSON.message }
    });
  }
}

function* getSkuDetails(action) {
  try{
    yield put({
      type: ACTION.LOADER.SHOW_LOADER
    });
    const data = yield call(getData, api.getSkuDetails + action.param );
    yield put({ 
      type : ACTION.MASTER.VEHICLE_PLANNING.STORE_SKU_DETAILS, 
      data: data.data.list || []
    });
    yield put({
      type: ACTION.LOADER.HIDE_LOADER
    });
  } catch(error) {
    yield put({
      type: ACTION.LOADER.HIDE_LOADER
    });
    yield put({
      type: ACTION.MSG_DISPLAY.SHOW_MSG,
      data: { type: 2, msg: error.responseJSON.message }
    });
  }
}


function* addSkuDetails(action) {
  try{
    yield put({
      type: ACTION.LOADER.SHOW_LOADER
    });
    const data = yield call(postData, api.addSkuDetails, action.data );
    yield put({ 
      type : ACTION.MASTER.VEHICLE_PLANNING.GET_SKU_DETAILS, 
      param : `${action.data.company_id}&limit=50&offset=0` 
    });
    yield put({ type: ACTION.MASTER.VEHICLE_PLANNING.DOWNLOAD_SKU_DETAILS , company_id: action.data.company_id });
    yield put({
      type: ACTION.LOADER.HIDE_LOADER
    });
    yield put({ type : ACTION.MSG_DISPLAY.SHOW_MSG, data : { type : 1 , msg : "Added Successfully"}});
  } catch(error) {
    yield put({
      type: ACTION.LOADER.HIDE_LOADER
    });
    yield put({
      type: ACTION.MSG_DISPLAY.SHOW_MSG,
      data: { type: 2, msg: error.responseJSON.message }
    });
  }
}


function* updateSkuDetails(action) {
  try{
    yield put({
      type: ACTION.LOADER.SHOW_LOADER
    });
    const data = yield call(putData, api.updateSkuDetails, JSON.stringify(action.data));
    yield put({ 
      type : ACTION.MASTER.VEHICLE_PLANNING.GET_SKU_DETAILS, 
      param : `${action.data.company_id}&limit=50&offset=0` 
    });
   
    yield put({ type : ACTION.MSG_DISPLAY.SHOW_MSG, data : { type : 1 , msg : (data && data.message) || "Updated Successfully"}});
  } catch(error) {
      yield put({
        type: ACTION.LOADER.HIDE_LOADER
      });
      yield put({
        type: ACTION.MSG_DISPLAY.SHOW_MSG,
        data: { type: 2, msg: error.responseJSON.message }
      });
  }
}
function* downloadSkuDetails(action) {
  try{
     const data = yield call(getData, api.downloadSkuDetails + action.company_id);
     if(action.onSuccess){
       action.onSuccess(data.data);
     }
     yield put({ type : ACTION.MASTER.VEHICLE_PLANNING.STORE_SKU_DOWNLOAD_LINK, data : data.data[0].Link })
  } catch(error) {
    console.log(error);
  }
}

function* downloadSkuTemp(action) {
  try{
     const data = yield call(getData, api.downloadSkuTemp + action.company_id);
     if(action.onSuccess){
      action.onSuccess(data.data);
    }
     yield put({ type : ACTION.MASTER.VEHICLE_PLANNING.STORE_SKU_DOWNLOAD_TEMP_LINK, data : data.data[0].Link })
  } catch(error) {
    console.log(error);
  }
}

function* uploadSkuDetails(action) {
  try{
    yield put({
      type: ACTION.LOADER.SHOW_LOADER
    });
    const data = yield call(fileUpload, api.uploadSkuDetails+action.company_id, action.data);
    yield put({ 
      type : ACTION.MASTER.VEHICLE_PLANNING.GET_SKU_DETAILS, 
      param : `${action.company_id}&limit=50&offset=0` 
    });
    yield put({ type: ACTION.MASTER.VEHICLE_PLANNING.DOWNLOAD_SKU_DETAILS, company_id : action.company_id });
    yield put({ type : ACTION.MSG_DISPLAY.SHOW_MSG, data : { type : 1 , msg : (data && data.message) || "File Uploaded Successfully"}});

  } catch(error) {
    yield put({
      type: ACTION.LOADER.HIDE_LOADER
    });
    yield put({
      type: ACTION.MSG_DISPLAY.SHOW_MSG,
      data: { type: 2, msg: error && error.responseJSON &&  error.responseJSON.result ? error.responseJSON.result.message : "Unable to upload the file" }
    });
  }
}

function* deleteSkuDetails(action) {
	try {
		yield put({
			type: ACTION.LOADER.SHOW_LOADER
		})
		yield call(deleteData, api.deleteSkuDetails, JSON.stringify(action.data));
		yield put({
			type: ACTION.MASTER.VEHICLE_PLANNING.GET_SKU_DETAILS,
		  param : `${action.company_id}&limit=50&offset=0`
		})
		yield put({
			type: ACTION.MSG_DISPLAY.SHOW_MSG,
			data: { type: 1, msg: "SKU deleted Successfully" }
		})
		yield put({
			type: ACTION.LOADER.HIDE_LOADER
		})
	} catch (e) {
		yield put({
			type: ACTION.LOADER.HIDE_LOADER
		})
		yield put({
			type: ACTION.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e && e.responseJSON ? e.responseJSON.message : "Something Went Wrong" }
		})
	}
}

// Driver Master
function* getDriverDetails(action) {
  try{
    yield put({
      type: ACTION.LOADER.SHOW_LOADER
    });
    const data = yield call(getData, api.getDriverDetails + action.param );
    yield put({ 
      type : ACTION.MASTER.VEHICLE_PLANNING.STORE_DRIVER_DETAILS, 
      data: data.data.list || []
    });
    yield put({
      type: ACTION.LOADER.HIDE_LOADER
    });
  } catch(error) {
    yield put({
      type: ACTION.LOADER.HIDE_LOADER
    });
    yield put({
      type: ACTION.MSG_DISPLAY.SHOW_MSG,
      data: { type: 2, msg: error.responseJSON.message }
    });
  }
}


function* addDriverDetails(action) {
  try{
    yield put({
      type: ACTION.LOADER.SHOW_LOADER
    });
    const data = yield call(postData, api.addDriverDetails, action.data );
    yield put({ 
      type : ACTION.MASTER.VEHICLE_PLANNING.GET_DRIVER_DETAILS, 
      param : `${action.data.company_id}&limit=50&offset=0` 
    });
    // yield put({ type: ACTION.MASTER.VEHICLE_PLANNING.DOWNLOAD_DRIVER_DETAILS , company_id: action.data.company_id });
    // yield put({
    //   type: ACTION.LOADER.HIDE_LOADER
    // });
    yield put({ type : ACTION.MSG_DISPLAY.SHOW_MSG, data : { type : 1 , msg : "Added Successfully"}});
  } catch(error) {
    yield put({
      type: ACTION.LOADER.HIDE_LOADER
    });
    yield put({
      type: ACTION.MSG_DISPLAY.SHOW_MSG,
      data: { type: 2, msg: error.responseJSON.message }
    });
  }
}

function* updateDriverDetails(action) {
  try{
    yield put({
      type: ACTION.LOADER.SHOW_LOADER
    });
    const data = yield call(putData, api.updateDriverDetails, JSON.stringify(action.data));
    yield put({ 
      type : ACTION.MASTER.VEHICLE_PLANNING.GET_DRIVER_DETAILS, 
      param : `${action.data.company_id}&limit=50&offset=0` 
    });
   
    yield put({ type : ACTION.MSG_DISPLAY.SHOW_MSG, data : { type : 1 , msg : (data.data && data.data.message) || "Updated Successfully"}});
  } catch(error) {
      yield put({
        type: ACTION.LOADER.HIDE_LOADER
      });
      yield put({
        type: ACTION.MSG_DISPLAY.SHOW_MSG,
        data: { type: 2, msg: error.responseJSON.message }
      });
  }
}
function* downloadDriverDetails(action) {
  try{
     let url = `${api.downloadDriverDetails}?companyId=${action.params.company_id}&withData=${action.params.withData}`
     const data = yield call(getData, url);
     if(action.onSuccess){
      action.onSuccess(data.data);
    }
     yield put({ type : ACTION.MASTER.VEHICLE_PLANNING.STORE_DRIVER_DOWNLOAD_LINK, data : data.data[0].Link })
  } catch(error) {
    console.log(error);
  }
}

function* downloadDriverTemp(action) {
  try{
     const data = yield call(getData, api.downloadDriverTemp + action.company_id);
     if(action.onSuccess){
      action.onSuccess(data.data);
    }
     yield put({ type : ACTION.MASTER.VEHICLE_PLANNING.STORE_DRIVER_DOWNLOAD_TEMP_LINK, data : data.data[0].Link })
  } catch(error) {
    console.log(error);
  } 
}

function* uploadDriverDetails(action) {
  try{
    yield put({
      type: ACTION.LOADER.SHOW_LOADER
    });
    const data = yield call(fileUpload, api.uploadDriverDetails+action.company_id, action.data);
    yield put({ 
      type : ACTION.MASTER.VEHICLE_PLANNING.GET_DRIVER_DETAILS, 
      param : `${action.company_id}&limit=50&offset=0` 
    });
    yield put({ type : ACTION.MSG_DISPLAY.SHOW_MSG, data : { type : 1 , msg : (data && data.message) || "File Uploaded Successfully"}});

  } catch(error) {
    yield put({
      type: ACTION.LOADER.HIDE_LOADER
    });
    yield put({
      type: ACTION.MSG_DISPLAY.SHOW_MSG,
      data: { type: 2, msg: error && error.responseJSON &&  error.responseJSON.result ? error.responseJSON.result.message : "Unable to upload the file" }
    });
  }
}

function* deleteDriverDetails(action) {
  try{
    yield put({
      type: ACTION.LOADER.SHOW_LOADER
    });
 
    let driverId= '&driverId='+action.driver;
    const data = yield call(deleteData, api.deleteDriverDetails + action.company_id+ driverId);
  
    yield put({ 
      type : ACTION.MASTER.VEHICLE_PLANNING.GET_DRIVER_DETAILS,
      param : `${action.company_id}`
    });
    yield put({
      type: ACTION.LOADER.HIDE_LOADER
    });
    yield put({ type : ACTION.MSG_DISPLAY.SHOW_MSG, data : { type : 1 , msg : "Data Deleted Successfully" }});
  } catch(error) {
    yield put({
      type: ACTION.LOADER.HIDE_LOADER
    });
    yield put({
      type: ACTION.MSG_DISPLAY.SHOW_MSG,
      data: { type: 2, msg: error && error.responseJSON ? error.responseJSON.message : 'Unable to delete the data' }
    });
  }
}

function* getState(action) {
  try {
      let url = api.getState;
      
      if(action.data && action.data.company_id){
        url = url + '?company_id=' + action.data.company_id;
      }

      const data = yield call(getData, url);
      if(action.onSuccess) {
        action.onSuccess( data.data || []);
      }
      yield put({
          type: ACTION.MASTER.LOCATION_NETWORK.STORE_STATE,
          data: data.data || []
      })
  } catch(error) {
      console.log('ERROR', error);
  }
}

export function* getDPLbranchListDetails(action) {
  try {
      let url = api.getDPLbranchListDetails;
      
      if(action.data && action.data.company_id){
        url = url + action.data.company_id;
      }

      const data = yield call(getData, url);
      if(action.onSuccess) {
        action.onSuccess( data.data.list || []);
      }
      yield put({
          type: ACTION.MASTER.LOCATION_NETWORK.STORE_DPL_BRANCH_LIST_DETAILS,
          data: data.data.list || []
      })
  } catch(error) {
      console.log('ERROR', error);
  }
}

function* getCountry(action) {
  try {
      const data = yield call(getData, api.getCountry);
      yield put({
          type: ACTION.MASTER.LOCATION_NETWORK.STORE_COUNTRY,
          data: data.data || []
      })
  } catch(error) {
      console.log('ERROR', error);
  }
}

function* getDropDownNamesForMaster(action) {
  try{
    yield put({
      type: ACTION.LOADER.SHOW_LOADER
    });
    const data = yield call(getData, api.getDropDownNameURL + action.param );
    yield put({ 
      type : ACTION.MASTER.MASTER_DROP_DOWN.STORE_DROP_DOWN_NAME, 
      data: data.data.list || []
    });
    yield put({
      type: ACTION.LOADER.HIDE_LOADER
    });
  } catch(error) {
    yield put({
      type: ACTION.LOADER.HIDE_LOADER
    });
    yield put({
      type: ACTION.MSG_DISPLAY.SHOW_MSG,
      data: { type: 2, msg: error.responseJSON.message }
    });
  }
}

function* getInsideTabDropDown(action) {
  try{
    yield put({
      type: ACTION.LOADER.SHOW_LOADER
    });
    const data = yield call(getData, api.getInsideTabDropDown + action.param );
    yield put({ 
      type : ACTION.MASTER.MASTER_DROP_DOWN.STORE_INSIDE_TAB_DROPDOWN, 
      data: data.data.list || []
    });
    yield put({
      type: ACTION.LOADER.HIDE_LOADER
    });
  } catch(error) {
    yield put({
      type: ACTION.LOADER.HIDE_LOADER
    });
    yield put({
      type: ACTION.MSG_DISPLAY.SHOW_MSG,
      data: { type: 2, msg: error.responseJSON.message }
    });
  }
}

function* updateMasterDropDownData(action) {
  try{
    yield put({
      type: ACTION.LOADER.SHOW_LOADER
    });
    const data = yield call(putData, api.updateMasterDropDownDataUrl , action.data );
    yield put({
      type: ACTION.LOADER.HIDE_LOADER
    });
    if(action.onSuccess) {
      action.onSuccess()
    }
  } catch(error) {
    yield put({
      type: ACTION.LOADER.HIDE_LOADER
    });
    yield put({
      type: ACTION.MSG_DISPLAY.SHOW_MSG,
      data: { type: 2, msg: error.responseJSON.message }
    });
  }
}

function* addMasterDropDownData(action) {
  try{
    yield put({
      type: ACTION.LOADER.SHOW_LOADER
    });
    const data = yield call(postData, api.addMasterDropDownDataUrl + action.params, action.data );
   
    // yield put({ type : ACTION.MSG_DISPLAY.SHOW_MSG, data : { type : 1 , msg : "Added Successfully"}});
    if(action.onSuccess){
      action.onSuccess()
    }
    yield put({
      type: ACTION.LOADER.HIDE_LOADER
    });
  } catch(error) {
    yield put({
      type: ACTION.LOADER.HIDE_LOADER
    });
    yield put({
      type: ACTION.MSG_DISPLAY.SHOW_MSG,
      data: { type: 2, msg: error.responseJSON.message }
    });
  }
}

function* deleteMasterDropDownData(action) {
  try{
    yield put({
      type: ACTION.LOADER.SHOW_LOADER
    });
    const data = yield call(deleteData, api.deleteMasterDropDownDataUrl, JSON.stringify(action.data));
    // yield put({ type : ACTION.MSG_DISPLAY.SHOW_MSG, data : { type : 1 , msg : "Data Deleted Successfully"}});

    if(action.onSuccess){
      action.onSuccess()
    }
    yield put({
      type: ACTION.LOADER.HIDE_LOADER
    });
  }
  catch(error){
    yield put({
      type: ACTION.LOADER.HIDE_LOADER
    });
    yield put({ type : ACTION.MSG_DISPLAY.SHOW_MSG, data : { type : 2 , msg : error ? error.message : "Unable to delete the data"}});
  }
}

export function* uploadRateChart(action) {
  try{
    yield put({
      type: ACTION.LOADER.SHOW_LOADER
    });
    const url = `${api.uploadRateChartUrl}?companyId=${action.company_id}`;
    const data = yield call(fileUpload, url, action.data);
    yield put({ type : ACTION.MSG_DISPLAY.SHOW_MSG, data : { type : 1 , msg : data.message || "File Uploaded Successfully"}});
    yield put({
      type: ACTION.LOADER.HIDE_LOADER
    });

  } catch(error) {
    yield put({
      type: ACTION.LOADER.HIDE_LOADER
    });
    yield put({
      type: ACTION.MSG_DISPLAY.SHOW_MSG,
      data: { type: 2, msg: error && error.responseJSON &&  error.responseJSON.result ? error.responseJSON.result.message : "Unable to upload the file" }
    });
  }
}

export function* downloadRateChart(action) {
  try{
    yield put({
      type: ACTION.LOADER.SHOW_LOADER
    });
    const url = `${api.downloadRateChartUrl}?companyId=${action.company_id}`;
    const data = yield call(getData, url);
    if(((data.data || [])[0] || {}).Link) {
      downloadUrl(((data.data || [])[0] || {}).Link);
    }
    yield put({
      type: ACTION.LOADER.HIDE_LOADER
    });
  } catch(error) {
    yield put({
      type: ACTION.LOADER.HIDE_LOADER
    });
    yield put({
      type: ACTION.MSG_DISPLAY.SHOW_MSG,
      data: { type: 2, msg: error && (error.responseJSON || {}).message }
    });
  }
}

export function* getRateChartDetails(action) {
  try{
    yield put({
      type: ACTION.LOADER.SHOW_LOADER
    });
    const url = `${api.getProductRateChartUrl}?companyId=${action.data.companyId}&productMasterId=${action.data.productMasterId}`;
    const data = yield call(getData, url);
    if(action.onSuccess) {
      action.onSuccess(data && (data.data || {}).list || []);
    }
    yield put({
      type: ACTION.LOADER.HIDE_LOADER
    });
  } catch(error) {
    yield put({
      type: ACTION.LOADER.HIDE_LOADER
    });
    yield put({
      type: ACTION.MSG_DISPLAY.SHOW_MSG,
      data: { type: 2, msg: error && (error.responseJSON || {}).message }
    });
  }
}

export function* addRateChartDetails(action) {
  try{
    yield put({
      type: ACTION.LOADER.SHOW_LOADER
    });
    
    const data = yield call(postData, api.addProductRateChartUrl, action.data);
    if(action.onSuccess) {
      action.onSuccess();
    }
    yield put({
      type: ACTION.MSG_DISPLAY.SHOW_MSG,
      data: { type: 1, msg: (data || {}).message || 'Added Successfully' }
    });
    yield put({
      type: ACTION.LOADER.HIDE_LOADER
    });
  } catch(error) {
    yield put({
      type: ACTION.LOADER.HIDE_LOADER
    });
    yield put({
      type: ACTION.MSG_DISPLAY.SHOW_MSG,
      data: { type: 2, msg: error && (error.responseJSON || {}).message }
    });
  }
}

export function* deleteRateChartDetails(action) {
  try{
    yield put({
      type: ACTION.LOADER.SHOW_LOADER
    });
    
    const data = yield call(putData, api.deleteProductRateChartUrl, JSON.stringify(action.data));
    if(action.onSuccess) {
      action.onSuccess();
    }
    yield put({
      type: ACTION.MSG_DISPLAY.SHOW_MSG,
      data: { type: 1, msg: (data || {}).message || 'Deleted Successfully' }
    });
    yield put({
      type: ACTION.LOADER.HIDE_LOADER
    });
  } catch(error) {
    yield put({
      type: ACTION.LOADER.HIDE_LOADER
    });
    yield put({
      type: ACTION.MSG_DISPLAY.SHOW_MSG,
      data: { type: 2, msg: error && (error.responseJSON || {}).message }
    });
  }
}

export function* updateRateChartDetails(action) {
  try{
    yield put({
      type: ACTION.LOADER.SHOW_LOADER
    });
    
    const data = yield call(putData, api.updateProductRateChartUrl, JSON.stringify(action.data));
    if(action.onSuccess) {
      action.onSuccess();
    }
    yield put({
      type: ACTION.MSG_DISPLAY.SHOW_MSG,
      data: { type: 1, msg: (data || {}).message || 'Updated Successfully' }
    });
    yield put({
      type: ACTION.LOADER.HIDE_LOADER
    });
  } catch(error) {
    yield put({
      type: ACTION.LOADER.HIDE_LOADER
    });
    yield put({
      type: ACTION.MSG_DISPLAY.SHOW_MSG,
      data: { type: 2, msg: error && (error.responseJSON || {}).message }
    });
  }
}


export {
  // master, 
  generateRouteExcel, 
  exportRouteExcel,
  getCommonConstants,
  getInventoryItem,
  addInventoryItem,
  updateInventoryItem,
  deleteInventoryItem,
  uploadInventoryItem,
  downloadInventoryItem,
  getAccountEvents,
  addAccountEvents,
  deleteAccountEvents,
  addNetworkChain,
  updateNetworkChain,
  getNetworkChain,
  deleteNetworkChain,
  uploadNetworkChain,
  downloadNetworkChain,
  getDepotConsignee,
  addDepotConsignee,
  updateDepotConsignee,
  deleteDepotConsignee,
  downloadDepotConsigneeTemp,
  downloadDepotConsignee,
  uploadDepotConsignee,
  getPackagingDetails,
  addCompanyTransporters,
  getCompanyTransporters,
  deleteCompanyTransporters,
  getTruckDetails,
  addTruckDetails,
  updateTruckDetails,
  deleteTruckDetails,
  uploadTruckDetails,
  downloadTruckDetails,
  downloadTruckTemp,
  getVehicleSize,
  getTransporterDetails,
  getTruckDriverDetails,
  getSkuDetails,
  addSkuDetails,
  updateSkuDetails,
  downloadSkuDetails,
  deleteSkuDetails,
  uploadSkuDetails,
  downloadSkuTemp,

  getDriverDetails,
  addDriverDetails,
  updateDriverDetails,
  downloadDriverDetails,
  uploadDriverDetails,
  downloadDriverTemp,
  deleteDriverDetails,
  getState,
  getCountry,
  downloadNetworkTemp,
  getGpsVendorData,

  getDropDownNamesForMaster,
  getInsideTabDropDown,
  updateMasterDropDownData,
  addMasterDropDownData,
  deleteMasterDropDownData
};
