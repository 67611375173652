import { put, call, select } from "redux-saga/effects";
import ACTIONS from "../common/action_constant";
import API from "../common/api_config";
import {
  getData,
  postData,
  postDataForGetDetails,
  putData,
  fileUpload,
  deleteData,
  putDataForMoglix,
} from "../../utils/ajax";
import history from "../history";
import { getToken, getUser } from "../../utils/common";
import axiosT1 from "../../utils/axios";
import { setItemToSessionStorage } from "../../utils/sessionStorage";

function* getHome(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    const data = yield call(postDataForGetDetails, API.getHome + action.data);
    yield put({
      type: ACTIONS.HOME.STORE_HOME_DETAILS,
      data: data.data.list,
    });
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) { }
}

function* updateCompnayStatus(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    yield call(putData, API.updateStatus, JSON.stringify(action.data));
    yield put({
      type: ACTIONS.HOME.STORE_COMPANY_STATUS,
      data: action.data,
    });
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) { }
}

function* addCompany(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    yield call(postData, API.addCompany, action.data);
    yield put({
      type: ACTIONS.HOME.TOGGLE_ADD_COMPANY_MODAL,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: { type: 1, msg: "Company added Successfully" },
    });
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: { type: 2, msg: e.responseJSON.message },
    });
  }
}

function* getCompanyBasicDetils(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    const data = yield call(getData, API.getBasicDetails + action.data);
    // if (!data.data.id) {
    //   history.push("/home");
    // }
    if(!!data.data){
      setItemToSessionStorage('finalData',data.data ||{})
    }
    yield put({
      type: ACTIONS.HOME.STORE_BASIC_DETAILS,
      data: data.data,
    });
    if (action.onSuccess) {
      action.onSuccess(data.data);
    }
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) { }
}
function* getCompanyBasicDetilsForVendorScreen(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(getData, API.getBasicDetailsForVendorScreen + action.data);
		if (!data.data.id) {
			history.push("/home")
		}
		yield put({
			type: ACTIONS.HOME.STORE_BASIC_DETAILS_FOR_VENDOR_SCREEN,
			data: data.data
		});
		if (action.onSuccess) {
      action.onSuccess(data.data);
    }
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
	} catch (e) {
	}
}

function* getEstCategory(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    const data = yield call(getData, API.getEstbCategory);
    yield put({
      type: ACTIONS.HOME.STORE_EST_CATEGORY,
      data: data.data.list,
    });
    if (action.onSuccess) {
      action.onSuccess(data.data);
    }
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) { }
}

function* updateThreshold(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    yield call(postData, API.updateThreshold, action.data);
    /*if (action.onSuccess) {
      action.onSuccess(data.data);
    }*/
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: { type: 1, msg: "LSP thresholds updated successfully!!" },
    });
    if (action.onSuccess) {
      action.onSuccess();
    }
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: { type: 2, msg: e.responseJSON.message },
    });
  }
}

//roles and departments sage to be changed

function* lspOptions(action) {
  // const param = "8f59aec312c948a196db6d3385b8ef75";
  const param = action.data; // to be changed
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    const data = yield call(getData, API.lspOptions + param);
    if (action.onSuccess) {
      action.onSuccess(data.data);
    }

    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg:
          e && e.responseJSON ? e.responseJSON.message : "Something Went Wrong",
      },
    });
  }
}

function* selectedOptions(action) {
  // const param = "8f59aec312c948a196db6d3385b8ef75";
  const param = action.data; // to be changed
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    const data = yield call(getData, API.selectedOptions + param);
    if (action.onSuccess) {
      action.onSuccess(data.data);
    }

    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg:
          e && e.responseJSON ? e.responseJSON.message : "Something Went Wrong",
      },
    });
  }
}

function* downloads(action) {
  // const param = "8f59aec312c948a196db6d3385b8ef75";
  const param = action.data; // to be changed
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    const data = yield call(getData, API.downloads + param);
    if (action.onSuccess) {
      action.onSuccess(data.data);
    }

    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg:
          e && e.responseJSON ? e.responseJSON.message : "Something Went Wrong",
      },
    });
  }
}

function* getRoles(action) {
  // const param = "8f59aec312c948a196db6d3385b8ef75";
  const param = action.data; // to be changed
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    const data = yield call(getData, API.getRoles + param);
    yield put({
      type: ACTIONS.HOME.STORE_ROLES,
      data: data.data.list,
    });
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    if (action.onSuccess) {
      action.onSuccess(data)
    }
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg:
          e && e.responseJSON ? e.responseJSON.message : "Something Went Wrong",
      },
    });
  }
}

function* addRole(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    yield call(postData, API.addRole, action.data);

    yield put({
      type: ACTIONS.HOME.GET_ROLES,
      data: action.data.company_id,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: { type: 1, msg: "Role added Successfully" },
    });
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg:
          e && e.responseJSON ? e.responseJSON.message : "Something Went Wrong",
      },
    });
  }
}

function* deleteRole(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    yield call(deleteData, API.deleteRole, JSON.stringify(action.data));
    yield put({
      type: ACTIONS.HOME.GET_ROLES,
      data: action.data.company_id,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: { type: 1, msg: "Role deleted Successfully" },
    });
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg:
          e && e.responseJSON ? e.responseJSON.message : "Something Went Wrong",
      },
    });
  }
}

function* getDepartments(action) {
  const param = action.data;
  // const param = "8f59aec312c948a196db6d3385b8ef75";
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    const data = yield call(getData, API.getDepartments + param);
    yield put({
      type: ACTIONS.HOME.STORE_DEPARTMENTS,
      data: data.data.list,
    });
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    if (action.onSuccess) {
      action.onSuccess(data)
    }
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg:
          e && e.responseJSON ? e.responseJSON.message : "Something Went Wrong",
      },
    });
  }
}

function* addDepartment(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    yield call(postData, API.addDepartment, action.data);

    yield put({
      type: ACTIONS.HOME.GET_DEPARTMENTS,
      data: action.data.company_id,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: { type: 1, msg: "Department added Successfully" },
    });
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg:
          e && e.responseJSON ? e.responseJSON.message : "Something Went Wrong",
      },
    });
  }
}

function* updateDepartment(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    yield call(
      putData,
      API.updateDepartment,
      JSON.stringify(action.data.mainData)
    );
    yield put({
      type: ACTIONS.HOME.GET_DEPARTMENTS,
      data: action.data.comp_id,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: { type: 1, msg: "Department edited Successfully" },
    });
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg:
          e && e.responseJSON ? e.responseJSON.message : "Something Went Wrong",
      },
    });
  }
}

function* deleteDepartment(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    yield call(deleteData, API.deleteDepartment, JSON.stringify(action.data));
    yield put({
      type: ACTIONS.HOME.GET_DEPARTMENTS,
      data: action.data.company_id,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: { type: 1, msg: "Department deleted Successfully" },
    });
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg:
          e && e.responseJSON ? e.responseJSON.message : "Something Went Wrong",
      },
    });
  }
}

function* getBusinessSeg(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    const data = yield call(getData, API.getBusinessSeg);
    yield put({
      type: ACTIONS.HOME.STORE_BUSINESS_SEG,
      data: data.data.list,
    });
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) { }
}

function* uploadCompanyimg(action) {
  try {
    yield put({
      type: ACTIONS.HOME.STORE_COMPANY_IMG,
      data: { url: "" },
    });
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    const data = yield call(fileUpload, API.uploadImg, action.data);
    yield put({
      type: ACTIONS.HOME.STORE_COMPANY_IMG,
      data,
    });
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) { }
}

function* updateBasicDetails(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    yield call(putData, API.updateBasicDetails, JSON.stringify(action.data));
    const data =
      "?companyId=" +
      action.data.company_id +
      "&companyType=" +
      action.data.company_type;
    yield put({ type: ACTIONS.HOME.GET_BASIC_DETAILS, data });
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: { type: 1, msg: "Details Updated Successfully!" },
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg:
          e && e.responseJSON ? e.responseJSON.message : "Something Went Wrong",
      },
    });
  }
}

function* getProviderUsers(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    let url =
      API.getProviderUsers +
      action.data.id +
      "&companyType=" +
      action.data.company_type;
    if (action.data.vendorScreen) {
      url += "&vendorScreen=" + action.data.vendorScreen;
    }
    if (action.data.seekerId) {
      url += "&seekerId=" + action.data.seekerId;
    }
    const data = yield call(getData, url);
    yield put({
      type: ACTIONS.HOME.STORE_PROVIDER_USERS,
      data: data.data.list,
    });
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) { }
}

function* addProviderUsers(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    yield call(postData, API.addUpdateProviderUsers, action.data);
    yield put({
      type: ACTIONS.HOME.GET_PROVIDER_USERS,
      data: {
        id: action.data.company_id,
        company_type: action.data.company_type,
      },
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: { type: 1, msg: "Added Successfully" },
    });
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg:
          e && e.responseJSON ? e.responseJSON.message : "Something Went Wrong",
      },
    });
  }
}
function* updateProviderUsers(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    yield call(
      putData,
      API.addUpdateProviderUsers,
      JSON.stringify(action.data)
    );

    let data = {
      id: action.data.company_id,
      company_type: action.data.company_type,
    };
    yield put({ type: ACTIONS.HOME.GET_PROVIDER_USERS, data });
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: { type: 1, msg: "Details Updated Successfully!" },
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg:
          e && e.responseJSON ? e.responseJSON.message : "Something Went Wrong",
      },
    });
  }
}

function* uploadFile(action) {
  try {
    yield put({
      type: ACTIONS.HOME.FILE_UPLOAD,
      data: { url: "" },
    });
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    const data = yield call(fileUpload, API.uploadFile, action.data);
    /*yield put({
      type: ACTIONS.HOME.FILE_UPLOAD,
      data,
    });*/
    if (action.onSuccess) {
      action.onSuccess(data);
    }
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: { type: 1, msg: "File Upload Successfully" },
    });
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg:
          e && e.responseJSON ? e.responseJSON.message : "Something Went Wrong",
      },
    });
  }
}

function* fetchAllSeekers(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    const data = yield call(getData, API.fetchAllSeekers);
    yield put({
      type: ACTIONS.HOME.STORE_ALL_SEEKERS,
      data: data.data.list,
    });
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    console.log("ERROR", e);
  }
}

function* fetchAllStates(action) {
  try {
    const data = yield call(getData, API.getStateListUrl);
    yield put({
      type: ACTIONS.HOME.STORE_ALL_STATE,
      data: data.data || [],
    });
  } catch (e) {
    console.log("ERROR", e);
  }
}

function* getIndentReasons(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });

    const data = yield call(getData, API.getIndentReasons + action.param);
    yield put({
      type: ACTIONS.HOME.STORE_INDENT_REASONS,
      data: data.data || [],
    });

    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  }
}

function* addIndentReasons(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    const data = yield call(
      postData,
      API.addIndentReasons + action.param,
      action.data
    );
    yield put({
      type: ACTIONS.HOME.GET_INDENT_REASONS,
      param: action.param,
    });
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  }
}

function* updateIndentReasons(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });

    const data = yield call(
      putData,
      API.updateIndentReasons + action.reasonId,
      JSON.stringify(action.data)
    );
    yield put({
      type: ACTIONS.HOME.GET_INDENT_REASONS,
      param: action.param,
    });
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  }
}

function* deleteIndentReasons(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });

    const data = yield call(
      deleteData,
      API.deleteIndentReasons + action.reasonId
    );
    yield put({
      type: ACTIONS.HOME.GET_INDENT_REASONS,
      param: action.param,
    });

    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  }
}

function* getModuleList(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    const url = `${API.getModuleLists}${action.company_id}&companyType=${action.company_type}`;
    const data = yield call(getData, url);
    if (action.onSuccess) {
      action.onSuccess(data.data || []);
    }
    yield put({
      type: ACTIONS.HOME.STORE_MODULES_LIST,
      data: data.data || [],
    });

    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg:
          e && e.responseJSON ? e.responseJSON.message : "Something Went Wrong",
      },
    });
  }
}

function* saveModuleList(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    const url = `${API.saveModuleLists}${action.company_id}&companyType=${action.company_type}`;
    const data = yield call(postData, url, action.data);

    yield put({
      type: ACTIONS.HOME.GET_MODULES_LIST,
      company_id: action.company_id,
      company_type: action.company_type,
    });

    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: { type: 1, msg: data.message || "Details Updated Successfully!" },
    });
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg:
          e && e.responseJSON ? e.responseJSON.message : "Something Went Wrong",
      },
    });
  }
}

function* uploadMultipleFiles(action) {
  try {
    yield put({
      type: ACTIONS.HOME.STORE_COMPANY_FILE,
      data: { url: "" },
    });
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    const data = yield call(fileUpload, API.uploadMultipleFile, action.data);
    console.log(data);
    yield put({
      type: ACTIONS.HOME.STORE_COMPANY_FILE,
      data,
    });
    if (action.onSuccess) {
      action.onSuccess(data);
    }
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: { type: 1, msg: "File Upload Successfully" },
    });
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg:
          e && e.responseJSON ? e.responseJSON.message : "Something Went Wrong",
      },
    });
  }
}

function* deleteFile(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    const url = `${API.deleteFileUrl}${action.data.companyId}&fileName=${action.data.fileName}&id=${action.data.id}`;
    const data = yield call(deleteData, url);
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: { type: 1, msg: "File Deleted Successfully" },
    });
    if (action.onSuccess) {
      action.onSuccess(data);
    }
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg:
          e && e.responseJSON ? e.responseJSON.message : "Something Went Wrong",
      },
    });
  }
}

function* fetchAllCompanyCategory(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    const data = yield call(getData, API.companyCategory);
    yield put({
      type: ACTIONS.HOME.STORE_ALL_COMPANY_CATEGORY,
      data: data.data,
    });

    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    console.log("ERROR", e);
  }
}

function* saveCompanyCategoryDetails(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    const data = yield call(
      postData,
      API.saveCompanyCategoryDetails,
      action.data
    );

    if (action.onSuccess) {
      action.onSuccess(data);
    }
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: { type: 1, msg: "Vendor Company Category Added Successfully" },
    });

    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg:
          e && e.responseJSON ? e.responseJSON.message : "Something Went Wrong",
      },
    });
  }
}

function* fetchVendorDetails(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    const data = yield call(getData, API.fetchVendorDetails + action.data);
    if (action.onSuccess) {
      action.onSuccess((data && data.data) || []);
    }
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    console.log("ERROR", e);
  }
}

function* fetchCargillVendorDetails(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER
    });
    const data = yield call(getData, API.fetchCargillVendorDetails + action.data);
    if (action.onSuccess) {
      action.onSuccess(data && data.data || []);
    }
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    console.log('ERROR', e);
  }
}

function* downloadVendorDetails(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER
    });
    const data = yield call(getData, API.downloadVendorDetailsURL);
    if (action.onSuccess) {
      action.onSuccess(data && data.data || []);
    }
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    console.log('ERROR', e);
  }
}

function* fetchCompanyCategoryDetails(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    const data = yield call(
      getData,
      API.fetchCompanyCategoryDetails + action.data
    );
    yield put({
      type: ACTIONS.HOME.STORE_COMPANY_CATEGORY_DETAILS,
      data: data.data,
    });
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    console.log("ERROR", e);
  }
}

function* fetchAllTypesData(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    const data = yield call(getData, API.fetchAllTypes);
    yield put({
      type: ACTIONS.HOME.STORE_ALL_TYPES,
      data: data.data,
    });

    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    console.log("ERROR", e);
  }
}

function* fetchAllModesData(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    const data = yield call(getData, API.fetchAllModes);
    yield put({
      type: ACTIONS.HOME.STORE_ALL_MODES,
      data: data.data,
    });

    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    console.log("ERROR", e);
  }
}

function* getRouteCode(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    const isRIL = ["737"].includes(action.param.companyId);
    let url = `${API[isRIL ? "getRILRouteCode" : "getRouteCode"]}?companyId=${action.param.companyId
      }&offset=${action.param.offset}&limit=${action.param.limit}`;

    if (action.param.search) {
      url += `&search=${action.param.search}`;
    }
    if (action.param.is_multimodal) {
      url += `&is_multimodal=${action.param.is_multimodal}`;
    }
    if (isRIL) {
      url += `&routeCodeType=${action.param.routeCodeType}`;
    }

    const data = yield call(getData, url);
    yield put({
      type: ACTIONS.HOME.SAVE_ROUTE_CODE,
      data: data.data || [],
    });
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    if (action.onSuccess) {
      action.onSuccess(data.data);
    }
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg:
          e && e.responseJSON ? e.responseJSON.message : "Something Went Wrong",
      },
    });
  }
}

function* addRouteCode(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    const isRIL = ["737"].includes(action.data.company_id);
    const data = yield call(
      postData,
      API[isRIL ? "addRILRouteCode" : "addRouteCode"],
      action.data
    );
    if (action.onSuccess) {
      action.onSuccess();
    }

    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 1,
        msg:
          (data && data.data && data.data.message) ||
          "Route Code Details Added Successfully",
      },
    });

    yield put({
      type: ACTIONS.HOME.GET_ROUTE_CODE,
      param: action.param,
    });
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg:
          e && e.responseJSON ? e.responseJSON.message : "Something Went Wrong",
      },
    });
  }
}

function* updateRouteCode(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    const isRIL = ["737"].includes(action.param.companyId);
    const data = yield call(
      putData,
      API[isRIL ? "editRILRouteCode" : "editRouteCode"],
      JSON.stringify(action.data)
    );
    if (action.onSuccess) {
      action.onSuccess();
    }
    yield put({
      type: ACTIONS.HOME.GET_ROUTE_CODE,
      param: action.param,
    });
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 1,
        msg:
          (data && data.data && data.data.message) ||
          "Route Code Details Updated Successfully",
      },
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg:
          e && e.responseJSON ? e.responseJSON.message : "Something Went Wrong",
      },
    });
  }
}

function* getDistance(action) {
  try {
    const query = new URLSearchParams(action.params).toString();
    const url = API.googleDistance + query;
    const data = yield call(getData, url);
    if (action.onSuccess) {
      action.onSuccess(data);
    }
  } catch (e) {
    console.log(e);
  }
}

function* getToDistrict(action) {
  try {
    let url = `${API.getToDistrict}${action.data}`;
    const data = yield call(getData, url);

    yield put({
      type: ACTIONS.HOME.SAVE_TO_DISTRICT,
      data: data.data || [],
    });
  } catch (error) {
    console.log("ERROR", error);
  }
}

function* getDistrict(action) {
	try {
		let url = `${API.getDistrict}${action.data}`;
		const data = yield call(getData, url);
		
		yield put({
			type: ACTIONS.HOME.SAVE_DISTRICT,
			data: data.data || []
		})
		
	} catch(error) {
		console.log('ERROR', error);
	}
  }

function* getVendorMDGDetils(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    const data = yield call(getData, API.allMDGDetails + action.data);
    yield put({
      type: ACTIONS.HOME.STORE_VENDOR_DETAILS,
      data: data.data,
    });
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) { }
}

function* getRFQTypes(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    const data = yield call(getData, API.getRFQTypes);

    yield put({
      type: ACTIONS.HOME.SAVE_RFQ_TYPES,
      data: data.data || [],
    });

    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg:
          e && e.responseJSON ? e.responseJSON.message : "Something Went Wrong",
      },
    });
  }
}

export function* downloadProviderUserDeatils(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    const data = yield call(
      getData,
      API.downloadProviderUserDeatils + action.data
    );
    yield put({
      type: ACTIONS.HOME.STORE_USER_URL,
      data: data,
    });
    if (action.onSuccess) {
      action.onSuccess(data.data);
    }
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg: e && e.message ? e.message : "Oops! Something went wrong.",
      },
    });
  }
}

export function* uploadProviderUserDetails(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    const data = yield call(
      fileUpload,
      API.uploadProviderUserDetails + action.params,
      action.data
    );
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    if (action.onSuccess) {
      action.onSuccess(data);
    }
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: { type: 1, msg: "Excel uploaded Sucessfully!" },
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg: e && e.message ? e.message : "Oops! Something went wrong.",
      },
    });
  }
}

export function* downloadProviderBranchDeatils(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    const data = yield call(
      getData,
      API.downloadProviderBranchDetails +
      action.data.id +
      "&companyType=" +
      action.data.type
    );
    yield put({
      type: ACTIONS.HOME.STORE_BRANCH_URL,
      data: data,
    });
    if (action.onSuccess) {
      action.onSuccess(data.data);
    }
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg: e && e.message ? e.message : "Oops! Something went wrong.",
      },
    });
  }
}

export function* uploadProviderBranchDetails(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    const data = yield call(
      fileUpload,
      API.uploadProviderBranchDetails + action.params,
      action.data
    );
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    if (action.onSuccess) {
      action.onSuccess(data);
    }
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: { type: 1, msg: "Excel uploaded Sucessfully!" },
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg: e && e.message ? e.message : "Oops! Something went wrong.",
      },
    });
  }
}

export function* downloadProviderDepartmentDetails(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    const data = yield call(
      getData,
      API.downloadProviderDepartmentDetails +
      action.data.id +
      "&companyType=" +
      action.data.type
    );
    yield put({
      type: ACTIONS.HOME.STORE_DEPARTMENT_URL,
      data: data,
    });
    if (action.onSuccess) {
      action.onSuccess(data.data);
    }
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg: e && e.message ? e.message : "Oops! Something went wrong.",
      },
    });
  }
}

export function* uploadProviderDepartmentDetails(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    const data = yield call(
      fileUpload,
      API.uploadProviderDepartmentDetails + action.params,
      action.data
    );
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    if (action.onSuccess) {
      action.onSuccess(data);
    }
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: { type: 1, msg: "Excel uploaded Sucessfully!" },
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg: e && e.message ? e.message : "Oops! Something went wrong.",
      },
    });
  }
}

export function* downloadProviderRolesDeatils(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    const data = yield call(
      getData,
      API.downloadProviderRolesDeatils +
      action.data.id +
      "&companyType=" +
      action.data.type
    );
    if (action.onSuccess) {
      action.onSuccess(data.data);
    }
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg: e && e.message ? e.message : "Oops! Something went wrong.",
      },
    });
  }
}

export function* uploadProviderRolesDetails(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    const data = yield call(
      fileUpload,
      API.uploadProviderRolesDetails + action.params,
      action.data
    );
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    if (action.onSuccess) {
      action.onSuccess(data);
    }
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: { type: 1, msg: "Excel uploaded Sucessfully!" },
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg: e && e.message ? e.message : "Oops! Something went wrong.",
      },
    });
  }
}

function* getMaterialConversion(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    let url = `${API.getMaterialConversion}?company_id=${action.param.companyId}&offset=${action.param.offset}&limit=${action.param.limit}`;

    if (action.param.search) {
      url += `&search=${action.param.search}`;
    }

    const data = yield call(getData, url);
    yield put({
      type: ACTIONS.HOME.SAVE_MATERIAL_CONVERSION,
      data: data.data || [],
    });
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg: e && e.message ? e.message : "Oops! Something went wrong.",
      },
    });
  }
}

function* addMaterialConversion(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    const data = yield call(postData, API.addMaterialConversion, action.data);
    if (action.onSuccess) {
      action.onSuccess();
    }
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 1,
        msg:
          (data && data.data && data.data.message) ||
          "Material Conversion Details Added Successfully",
      },
    });

    yield put({
      type: ACTIONS.HOME.GET_MATERIAL_CONVERSION,
      param: action.param,
    });
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg:
          e && e.responseJSON ? e.responseJSON.message : "Something Went Wrong",
      },
    });
  }
}

export function* downloadSapRouteCode(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    let url = `${action.param.routeCodeType
        ? API.downloadRILRouteCode
        : API.downloadSapRouteCode
      }${action.param.companyId}`;
    if (action.param.routeCodeType) {
      url += `&routeCodeType=${action.param.routeCodeType}`;
    }
    if (action.param.is_multimodal) {
      url += `&is_multimodal=${action.param.is_multimodal}`;
    }
    const data = yield call(getData, url);
    yield put({
      type: ACTIONS.HOME.STORE_USER_URL,
      data: data,
    });
    if (action.onSuccess) {
      action.onSuccess(data.data);
    }
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg: e && e.message ? e.message : "Oops! Something went wrong.",
      },
    });
  }
}

export function* uploadSapRouteCodeDetails(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    const data = yield call(
      fileUpload,
      API[action.routeCodeType ? "uploadRILRouteCode" : "uploadSapRouteCode"] + `${action.is_multimodal ? '?is_multimodal=true' : ''}`,
      action.data
    );
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    if (action.onSuccess) {
      action.onSuccess(data);
    }
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: { type: 1, msg: data.message || "Excel uploaded Sucessfully!" },
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg: e && e.message ? e.message : "Oops! Something went wrong.",
      },
    });
  }
}

function* deleteSapRouteCodeDetails(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    const isRIL = ["737"].includes(action.data.company_id);
    const data = yield call(
      deleteData,
      API[isRIL ? "deleteRILRouteCode" : "deleteSapRouteDeatils"],
      JSON.stringify(action.data)
    );
    if (action.onSuccess) {
      action.onSuccess(data);
    }
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });

    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 1,
        msg:
          (data && data.data && data.data.message) ||
          "Route Code Details Deleted Successfully",
      },
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg: e && e.message ? e.message : "Oops! Something went wrong.",
      },
    });
  }
}

export function* downloadSapRouteCodeDetails(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    let url = `${action.param.routeCodeType
        ? API.downloadRILROuteCodeDetails
        : API.downloadSapRouteCodeDetails
      }${action.param.companyId}`;
    if (action.param.routeCodeType) {
      url += `&routeCodeType=${action.param.routeCodeType}`;
    }
    if (action.param.is_multimodal) {
      url += `&is_multimodal=${action.param.is_multimodal}`;
    }
    const data = yield call(getData, url);
    yield put({
      type: ACTIONS.HOME.STORE_USER_URL,
      data: data,
    });
    if (action.onSuccess) {
      action.onSuccess(data.data);
    }
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg: e && e.message ? e.message : "Oops! Something went wrong.",
      },
    });
  }
}

function* getSchedulerCode(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    let url = `${API.schedulerDetails}?companyId=${action.param.companyId}&offset=${action.param.offset}&limit=${action.param.limit}`;

    if (action.param.search) {
      url += `&search=${action.param.search}`;
    }

    const data = yield call(getData, url);
    yield put({
      type: ACTIONS.HOME.SAVE_SCHEDULER_CODE,
      data: data.data.list || [],
    });
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    if (action.onSuccess) {
      action.onSuccess(data.data.list);
    }
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg:
          e && e.responseJSON ? e.responseJSON.message : "Something Went Wrong",
      },
    });
  }
}

function* addSchedulerCode(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    const data = yield call(postData, API.schedulerAddRecord, action.data);
    if (action.onSuccess) {
      action.onSuccess();
    }

    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 1,
        msg:
          (data && data.data && data.data.message) ||
          "Scheduler Details Added Successfully",
      },
    });
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg:
          e && e.responseJSON ? e.responseJSON.message : "Something Went Wrong",
      },
    });
  }
}

function* updateSchedulerCode(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    const data = yield call(
      putData,
      API.schedulerAddRecord,
      JSON.stringify(action.data)
    );
    if (action.onSuccess) {
      action.onSuccess();
    }
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 1,
        msg:
          (data && data.data && data.data.message) ||
          "Route Code Details Updated Successfully",
      },
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg:
          e && e.responseJSON ? e.responseJSON.message : "Something Went Wrong",
      },
    });
  }
}

function* deleteSchedulerDetails(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });

    const data = yield call(
      deleteData,
      API.schedulerDeleteRecord,
      JSON.stringify(action.data)
    );
    if (action.onSuccess) {
      action.onSuccess(data);
    }
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });

    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 1,
        msg:
          (data && data.data && data.data.message) ||
          "Route Code Details Deleted Successfully",
      },
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg: e && e.message ? e.message : "Oops! Something went wrong.",
      },
    });
  }
}

export function* downloadSchedulerDetails(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    let url = `${API.downloadSchedulerDetails}?companyId=${action.param.companyId}&withData=${action.param.withData}`;
    const data = yield call(getData, url);

    yield put({
      type: ACTIONS.HOME.STORE_USER_URL,
      data: data,
    });
    if (action.onSuccess) {
      action.onSuccess(data.data);
    }
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg: e && e.message ? e.message : "Oops! Something went wrong.",
      },
    });
  }
}

export function* uploadSchedulerDetails(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    let url = `${API.uploadSchedulerDetails}?companyId=${action.param.companyId}`;
    const data = yield call(fileUpload, url, action.data);

    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    if (action.onSuccess) {
      action.onSuccess(data);
    }
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: { type: 1, msg: "Excel uploaded Sucessfully!" },
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg: e && e.message ? e.message : "Oops! Something went wrong.",
      },
    });
  }
}

function* getWarehouseParticulars(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    let url = `${API.getWarehouseParticulars}?companyId=${action.param.companyId}&offset=${action.param.offset}&limit=${action.param.limit}`;

    const data = yield call(getData, url);
    if (action.onSuccess) {
      action.onSuccess((data.data || {}).list);
    }
    // yield put({
    // 	type: ACTIONS.HOME.SAVE_WAREHOUSE_PARTICULARS_LIST,
    // 	data: data.data.list || []
    // });
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) {
    console.log("e", e);
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg:
          e && e.responseJSON ? e.responseJSON.message : "Something Went Wrong",
      },
    });
  }
}

function* postWarehouseParticulars(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });

    const data = yield call(
      postData,
      API.postWarehouseParticulars,
      action.data
    );
    if (action.onSuccess) {
      action.onSuccess();
    }
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) {
    console.log("e", e);
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg:
          e && e.responseJSON ? e.responseJSON.message : "Something Went Wrong",
      },
    });
  }
}

function* getWarehouseTemplateList(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    let url = `${API.getWarehouseTemplate}?companyId=${action.param.companyId}&offset=${action.param.offset}&limit=${action.param.limit}`;

    const data = yield call(getData, url);
    if (action.onSuccess) {
      action.onSuccess((data.data || {}).list);
    }
    // yield put({
    // 	type: ACTIONS.HOME.SAVE_WAREHOUSE_PARTICULARS_LIST,
    // 	data: data.data.list || []
    // });
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) {
    console.log("e", e);
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg:
          e && e.responseJSON ? e.responseJSON.message : "Something Went Wrong",
      },
    });
  }
}

function* postWarehouseTemplate(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });

    const data = yield call(postData, API.postWarehouseTemplate, action.data);
    if (action.onSuccess) {
      action.onSuccess();
    }
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) {
    console.log("e", e);
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg:
          e && e.responseJSON ? e.responseJSON.message : "Something Went Wrong",
      },
    });
  }
}

export function* editMaterialConversionDetails(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    const data = yield call(
      putData,
      API.editMaterialConversionDetails,
      JSON.stringify(action.data)
    );
    if (action.onSuccess) {
      action.onSuccess();
    }
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 1,
        msg:
          (data && data.message) ||
          "Material Conversion Record Updated Successfully",
      },
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg:
          e && e.responseJSON ? e.responseJSON.message : "Something Went Wrong",
      },
    });
  }
}

export function* deleteMaterialConversionDetails(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });

    const data = yield call(
      deleteData,
      API.deleteMaterialConversionDetails,
      JSON.stringify(action.data)
    );
    if (action.onSuccess) {
      action.onSuccess(data);
    }
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });

    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 1,
        msg:
          (data && data.message) ||
          "Material Conversion Record Deleted Successfully",
      },
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg: e && e.message ? e.message : "Oops! Something went wrong.",
      },
    });
  }
}

export function* downloadMaterialConversionTemplate(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    let url = action.withData
      ? `${API.downloadMaterialConversionTemplate}/temp`
      : API.downloadMaterialConversionTemplate;
    const data = yield call(getData, url);

    // yield put({
    // 	type :ACTIONS.HOME.STORE_USER_URL,
    // 	data : data
    // });
    if (action.onSuccess) {
      action.onSuccess(data.data);
    }
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg: e && e.message ? e.message : "Oops! Something went wrong.",
      },
    });
  }
}

export function* uploadMaterialConversionDetails(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    // let url = `${API.uploadMaterialConversionDetails}?companyId=${action.param.companyId}`;
    const data = yield call(
      fileUpload,
      API.uploadMaterialConversionDetails,
      action.data
    );

    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    if (action.onSuccess) {
      action.onSuccess(data);
    }
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: { type: 1, msg: "Excel uploaded Sucessfully!" },
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg: e && e.message ? e.message : "Oops! Something went wrong.",
      },
    });
  }
}

export function* getVendorMaterialDetails(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    let url = `${API.getVendorMaterialDetails}${action.param.companyId}&offset=${action.param.offset}&limit=${action.param.limit}`;

    if (action.param.search) {
      url += `&search=${action.param.search}`;
    }

    const data = yield call(getData, url);
    yield put({
      type: ACTIONS.HOME.SAVE_VENDOR_MATERIAL_DETAILS,
      data: data.data || [],
      count: data.TotalCount || null
    });
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) {
    console.log("e", e);
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg:
          e && e.responseJSON ? e.responseJSON.message : "Something Went Wrong",
      },
    });
  }
}

export function* uploadVendorMaterialDetails(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    const data = yield call(
      fileUpload,
      API.uploadVendorMaterialDetails + action.companyId + `&userEmail=${action.userEmail}`,
      action.data
    );
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    if (action.onSuccess) {
      action.onSuccess(data);
    }
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: { type: 1, msg: "Excel uploaded Sucessfully!" },
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg: e && e.message ? e.message : "Oops! Something went wrong.",
      },
    });
  }
}

export function* downloadVendorMaterialDetails(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    const url =
      action.actionType == 1
        ? API.downloadVendorMaterialTemplate
        : API.downloadVendorMaterialData + action.param.companyId;
    const data = yield call(getData, url);
    if (action.onSuccess) {
      action.onSuccess(data.data);
    }
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg: e && e.message ? e.message : "Oops! Something went wrong.",
      },
    });
  }
}

export function* addVendorMaterialDetails(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    const data = yield call(
      postData,
      API.addVendorMaterialDetails + action.companyId,
      action.data
    );
    if (action.onSuccess) {
      action.onSuccess();
    }
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 1,
        msg:
          (data && data.data && data.data.message) || "Data Added Successfully",
      },
    });
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg:
          e && e.responseJSON ? e.responseJSON.message : "Something Went Wrong",
      },
    });
  }
}

export function* updateVendorMaterialDetails(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    const data = yield call(
      putData,
      API.updateVendorMaterialDetails,
      JSON.stringify(action.data)
    );
    if (action.onSuccess) {
      action.onSuccess();
    }
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 1,
        msg:
          (data && data.data && data.data.message) ||
          "Data Updated Successfully",
      },
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg:
          e && e.responseJSON ? e.responseJSON.message : "Something Went Wrong",
      },
    });
  }
}

export function* deleteVendorMaterialDetails(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    yield call(
      deleteData,
      API.deleteVendorMaterialDetails,
      JSON.stringify(action.data)
    );
    if (action.onSuccess) {
      action.onSuccess();
    }
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: { type: 1, msg: "Data Deleted Successfully" },
    });
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg:
          e && e.responseJSON ? e.responseJSON.message : "Something Went Wrong",
      },
    });
  }
}

export function* getVendorWithSapCode(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    let url = `${API.getVendorWithSapCode}${action.companyId}&companyType=${action.companyType}`;

    const data = yield call(getData, url);
    if (action.onSuccess) {
      action.onSuccess(
        (data.data && (data.data || {}).sap_vendor_details) || []
      );
    }
    // yield put({
    // 	type: ACTIONS.HOME.SAVE_VENDOR_WITH_SAP_CODE,
    // 	data: data.sap_vendor_code || []
    // });
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) {
    console.log("e", e);
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg:
          e && e.responseJSON ? e.responseJSON.message : "Something Went Wrong",
      },
    });
  }
}

export function* downloadParticularsConversionTemplate(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    let url = action.withData
      ? `${API.downloanParticularsTemplate}/temp`
      : API.downloadParticularsDetails + action.param.companyId;
    const data = yield call(getData, url);

    if (action.onSuccess) {
      action.onSuccess(data.data);
    }
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg: e && e.message ? e.message : "Oops! Something went wrong.",
      },
    });
  }
}

export function* uploadParticularsDetails(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    const data = yield call(
      fileUpload,
      API.uploadParticularsDetailsDetails + action.companyId,
      action.data
    );
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    if (action.onSuccess) {
      action.onSuccess(data);
    }
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: { type: 1, msg: "Excel uploaded Sucessfully!" },
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg: e && e.message ? e.message : "Oops! Something went wrong.",
      },
    });
  }
}

export function* getModeOfTransport(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    const data = yield call(getData, API.getModeOfTransport);
    yield put({
      type: ACTIONS.HOME.SAVE_MODE_OF_TRANSPORT,
      data: (data && data.data) || [],
    });
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg: e && e.message ? e.message : "Oops! Something went wrong.",
      },
    });
  }
}

function* getMaterialItemDetails(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    const data = yield call(
      getData,
      API.getMaterialItemDetails + action.companyId
    );
    yield put({
      type: ACTIONS.HOME.STORE_MATERIAL_ITEM_DETAILS,
      data: data.data,
    });
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) { }
}

function* syncChainWithVIMS(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    const data = yield call(postData, API.syncChainWithVIMS, action.data);
    if (action.onSuccess) {
      action.onSuccess();
    }
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });

    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 1,
        msg:
          (data && data.data && data.data.message) ||
          "Data Updated Successfully!",
      },
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: { type: 2, msg: e.responseJSON.message },
    });
  }
}

function* getRouteMaster(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });

    let url = `${API["getMasterRouteDetails"]}?companyId=${action.param.companyId}&offset=${action.param.offset}&limit=${action.param.limit}`;

    if (action.param.search) {
      url += `&search=${action.param.search}`;
    }

    const data = yield call(getData, url);
    yield put({
      type: ACTIONS.HOME.SAVE_ROUTE_MASTER,
      data: data.data || [],
    });
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    if (action.onSuccess) {
      action.onSuccess(data.data);
    }
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg:
          e && e.responseJSON ? e.responseJSON.message : "Something Went Wrong",
      },
    });
  }
}

function* downloadRouteMasterDetails(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    let url = `${API.downloadMasterRouteTemplateDetails}${action.param.companyId}`;

    const data = yield call(getData, url);
    yield put({
      type: ACTIONS.HOME.STORE_USER_URL,
      data: data,
    });
    if (action.onSuccess) {
      action.onSuccess(data.data);
    }
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg: e && e.message ? e.message : "Oops! Something went wrong.",
      },
    });
  }
}

function* downloadRouteMasterTemplate(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    let url = `${API.downloadMasterRouteTemplate}${action.param.companyId}`;

    const data = yield call(getData, url);
    yield put({
      type: ACTIONS.HOME.STORE_USER_URL,
      data: data,
    });
    if (action.onSuccess) {
      action.onSuccess(data.data);
    }
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg: e && e.message ? e.message : "Oops! Something went wrong.",
      },
    });
  }
}

function* uploadMasterRouteDetails(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    const data = yield call(
      fileUpload,
      API["uploadMasterRouteDetails"],
      action.data
    );
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    if (action.onSuccess) {
      action.onSuccess(data);
    }
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: { type: 1, msg: "Excel uploaded Sucessfully!" },
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg: e && e.message ? e.message : "Oops! Something went wrong.",
      },
    });
  }
}

function* addRouteMaster(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    const data = yield call(
      postData,
      API["addMasterRouteDetails"],
      action.data
    );
    if (action.onSuccess) {
      action.onSuccess();
    }

    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 1,
        msg:
          (data && data.data && data.data.message) ||
          "Route Details Added Successfully",
      },
    });

    yield put({
      type: ACTIONS.HOME.GET_ROUTE_MASTER,
      param: action.param,
    });
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg:
          e && e.responseJSON ? e.responseJSON.message : "Something Went Wrong",
      },
    });
  }
}

function* updateRouteMaster(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    const data = yield call(
      putData,
      API["updateMasterRouteDetails"],
      JSON.stringify(action.data)
    );
    if (action.onSuccess) {
      action.onSuccess();
    }
    yield put({
      type: ACTIONS.HOME.GET_ROUTE_MASTER,
      param: action.param,
    });
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 1,
        msg:
          (data && data.data && data.data.message) ||
          "Route Details Updated Successfully",
      },
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg:
          e && e.responseJSON ? e.responseJSON.message : "Something Went Wrong",
      },
    });
  }
}

function* deleteRouteMaster(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    const data = yield call(
      deleteData,
      API["deleteMasterRouteDetails"],
      JSON.stringify(action.data)
    );
    if (action.onSuccess) {
      action.onSuccess(data);
    }
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });

    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 1,
        msg:
          (data && data.data && data.data.message) ||
          "Route Details Deleted Successfully",
      },
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg: e && e.message ? e.message : "Oops! Something went wrong.",
      },
    });
  }
}

function* getDisposalMaterial(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });

    let url = `${API["getDisposalMSP"]}${action.param.companyId}&offset=${action.param.offset}&limit=${action.param.limit}`;

    if (action.param.search) {
      url += `&search=${action.param.search}`;
    }

    const data = yield call(getData, url);
    yield put({
      type: ACTIONS.HOME.SAVE_DISPOSAL_MATERIAL,
      data: data.data || [],
    });
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg:
          e && e.responseJSON ? e.responseJSON.message : "Something Went Wrong",
      },
    });
  }
}

function* downloadDisposalMaterialTemplate(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    let url = `${API.downloadDisposalMSPTemplate}${action.param.companyId}`;

    const data = yield call(getData, url);
    if (action.onSuccess) {
      action.onSuccess(data.data);
    }
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg: e && e.message ? e.message : "Oops! Something went wrong.",
      },
    });
  }
}

function* uploadDisposalMaterial(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    const data = yield call(fileUpload, API["uploadDisposalMSP"], action.data);
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    if (action.onSuccess) {
      action.onSuccess(data);
    }
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: { type: 1, msg: "Excel uploaded Sucessfully!" },
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg: e && e.message ? e.message : "Oops! Something went wrong.",
      },
    });
  }
}

function* downloadDispsalMaterialDetails(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    let url = `${API.downloadDisposalMSPDetails}${action.param.companyId}`;

    const data = yield call(getData, url);
    yield put({
      type: ACTIONS.HOME.STORE_USER_URL,
      data: data,
    });
    if (action.onSuccess) {
      action.onSuccess(data.data);
    }
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg: e && e.message ? e.message : "Oops! Something went wrong.",
      },
    });
  }
}

function* getDisposalMaterialDropdowns(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });

    let url = `${API["getDisposalMSPDropdownList"]}${action.companyId}`;
    const data = yield call(getData, url);
    yield put({
      type: ACTIONS.HOME.SAVE_DISPOSAL_MATERIAL_DROPDOWNS,
      data: data.data || {},
    });
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    if (action.onSuccess) {
      action.onSuccess(data.data);
    }
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg:
          e && e.responseJSON ? e.responseJSON.message : "Something Went Wrong",
      },
    });
  }
}

function* addDisposalMaterial(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    const data = yield call(postData, API["addDisposalMSP"], action.data);
    if (action.onSuccess) {
      action.onSuccess();
    }
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 1,
        msg:
          (data && data.data && data.data.message) || "Data Added Successfully",
      },
    });
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg:
          e && e.responseJSON ? e.responseJSON.message : "Something Went Wrong",
      },
    });
  }
}

function* updateDisposalMaterial(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    const data = yield call(
      putData,
      API["updateDisposalMSP"],
      JSON.stringify(action.data)
    );
    if (action.onSuccess) {
      action.onSuccess();
    }
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 1,
        msg:
          (data && data.data && data.data.message) ||
          "Data Updated Successfully",
      },
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg:
          e && e.responseJSON ? e.responseJSON.message : "Something Went Wrong",
      },
    });
  }
}

function* deleteDisposalMaterial(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    const data = yield call(
      deleteData,
      API["deleteDisposalMSP"],
      JSON.stringify(action.data)
    );
    if (action.onSuccess) {
      action.onSuccess(data);
    }
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });

    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 1,
        msg:
          (data && data.data && data.data.message) ||
          "Data Deleted Successfully",
      },
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg: e && e.message ? e.message : "Oops! Something went wrong.",
      },
    });
  }
}

function* getContractTenureTypes(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    const data = yield call(getData, API["getContractTenureTypes"]);
    if (action.onSuccess) {
      action.onSuccess(data.data || []);
    }
    yield put({
      type: ACTIONS.HOME.SAVE_CONTRACT_TENURE_TYPES,
      data: data.data || [],
    });

    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg: e && e.message ? e.message : "Oops! Something went wrong.",
      },
    });
  }
}

function* getContractTenureList(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    const query = new URLSearchParams(action.params).toString();
    const data = yield call(
      getData,
      `${API["getContractTenureList"]}?${query}`
    );
    if (action.onSuccess) {
      action.onSuccess(data.data || {});
    }
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg: e && e.message ? e.message : "Oops! Something went wrong.",
      },
    });
  }
}

function* saveContractTenureList(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    const data = yield call(
      postData,
      API["addContractTenureList"],
      action.data
    );
    if (action.onSuccess) {
      action.onSuccess();
    }
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 1,
        msg:
          (data && data.data && data.data.message) ||
          "Data Updated Successfully",
      },
    });
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg: e && e.message ? e.message : "Oops! Something went wrong.",
      },
    });
  }
}


function* fetchLRUploadSummary(action) {
  let URL = API.fetchLRuploadsummary

  if (action.companyType) {
    URL += `companyType=${action.companyType}`
  }
  if (action.companyId) {
    URL += `companyId=${action.companyId}`
  }
  if (action.lspName && action.lspName !== "All") {
    URL += `&lspName=${action.lspName}`
  }
  if (action.boundType && action.boundType != 3) {
    URL += `&BoundType=${action.boundType}`
  }
  if (action.fromDate) {
    URL += `&fromDate=${action.fromDate}`
  }
  if (action.toDate) {
    URL += `&toDate=${action.toDate}`
  }

  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    const data = yield call(getData, URL);
    if (action.onSuccess) {
      action.onSuccess(data.data)
    }
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.HOME.FETCH_LR_UPLOAD_SUMMARY_SUCCESS,
      data: data.data || []
    });
  } catch (e) { }
}

function* deleteLRUploadDetails(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });

    yield call(
      deleteData,
      API.deleteLRUploadDetails,
      action.data,
      'stringify'
    );
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });

    if (action.onSuccess) {
      action.onSuccess()
    }
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  }
}

function* downloadLRUploadDetail(action) {
  // const param = "8f59aec312c948a196db6d3385b8ef75";
  const param = action.data; // to be changed
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    const data = yield call(getData, API.downloadLRUploadDetail + param);
    if (action.onSuccess) {
      action.onSuccess(data.data);
    }

    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg:
          e && e.responseJSON ? e.responseJSON.message : "Something Went Wrong",
      },
    });
  }
}

function* downloadLSPName(action) {
  const param = action.data; // to be changed
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    const data = yield call(getData, API.downloadLspName + param);
    if (action.onSuccess) {
      action.onSuccess(data.data);
    }

    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg:
          e && e.responseJSON ? e.responseJSON.message : "Something Went Wrong",
      },
    });
  }
}

function* SendLRtoMoglix(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    const data = yield call(putDataForMoglix, API.sendLRtoMoglix + action.data, true);
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    if (action.onSuccess) {
      action.onSuccess(data.message)
    }
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  }
}

function* uploadLRDetail(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    yield call(fileUpload, API.uploadLRdetail + action.data, action.file);
    if (action.onSuccess) {
      action.onSuccess();
    }
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: { type: 2, msg: e.responseJSON.message },
    });
  }
}

function* setLrRange(action) {

  let URL = API.setLrRangeURL + action.data

  if (action.start) {
    URL += `&start=LRAWL${action.start}`
  }
  if (action.end) {
    URL += `&end=LRAWL${action.end}`
  }
  if (action.branchId) {
    URL += `&branchId=${action.branchId}`
  }
  if (action.selectedSeekerName) {
    URL += `&seekerName=${action.selectedSeekerName}`
  }
  if (action.companyName) {
    URL += `&companyName=${action.companyName}`
  }
  if (action.companyId) {
    URL += `&companyId=${action.companyId}`
  }

  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    yield call(postData, URL);
    if (action.onSuccess) {
      action.onSuccess();
    }
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: { type: 2, msg: e.responseJSON.message },
    });
  }
}

function* setLrRangeForProvider(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    yield call(postData, API.setLrRangeForProviderURL, action.data);
    if (action.onSuccess) {
      action.onSuccess();
    }
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) {
    console.log(e)
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: { type: 2, msg: e.responseJSON.message },
    });
  }
}


function* fetchBranchForLr(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    const data = yield call(getData, API.getBranchForLr + action.data);
    if (action.onSuccess) {
      action.onSuccess(data.data)
    }
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.HOME.FETCH_LR_UPLOAD_SUMMARY_SUCCESS,
      data: data.data || []
    });
  } catch (e) { }
}

function* fetchAllSeekerForLr(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    const data = yield call(getData, API.getAllSeekerForLR);
    if (action.onSuccess) {
      action.onSuccess(data.data)
    }
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.HOME.FETCH_LR_UPLOAD_SUMMARY_SUCCESS,
      data: data.data || []
    });
  } catch (e) { }
}

function* getRouteCodeDropdowns(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    let url = `${API.getPlantDepots}?companyId=${action.param.companyId}&companyType=${action.param.companyType}&offset=${action.param.offset}&limit=${action.param.limit}&type=${action.param.type}`;
    const data = yield call(getData, url);
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    if (action.onSuccess) {
      action.onSuccess(data.data.list);
    }
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg:
          e && e.responseJSON ? e.responseJSON.message : "Something Went Wrong",
      },
    });
  }
}
function* getGroupNameDropdowns(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    let url = `${API.getGroupNamesDropdownURL}?company_id=${action.param.companyId}`;
    //  &companyType=${action.param.companyType}&offset=${action.param.offset}&limit=${action.param.limit}&type=${action.param.type}`;
    const data = yield call(getData, url);
    yield put({
      type: ACTIONS.HOME.SAVE_GROUP_NAME_DROPDOWN,
      data: data && data.data || []
    })
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg:
          e && e.responseJSON ? e.responseJSON.message : "Something Went Wrong",
      },
    });
  }
}

function* downloadIndividualLRDetails(action) {
  const param = action.data; // to be changed
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    const data = yield call(getData, API.downloadIndividualLRURL + param);
    if (action.onSuccess) {
      action.onSuccess(data.data);
    }

    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg:
          e && e.responseJSON ? e.responseJSON.message : "Something Went Wrong",
      },
    });
  }
}

function* getProviderLRDetails(action) {
  const param = action.data; // to be changed
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    const data = yield call(getData, API.getProviderLRdetailsURL + param);
    if (action.onSuccess) {
      action.onSuccess(data.data);
    }

    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg:
          e && e.responseJSON ? e.responseJSON.message : "Something Went Wrong",
      },
    });
  }
}

function* deleteLRForProvider(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });

    yield call(
      deleteData,
      API.deleteLRForProviderURL,
      action.data,
      'stringify'
    );
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });

    if (action.onSuccess) {
      action.onSuccess()
    }
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  }
}

function* editLRDataForProvider(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });

    yield call(
      putData,
      API.editLRDataForProviderURL,
      JSON.stringify(action.data),
    );
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });

    if (action.onSuccess) {
      action.onSuccess()
    }
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  }
}

function* getParentChildLocationListing(action) {
  let URL = API.getLocationMgmtListing
  if (action.plantName) {
    URL += `&plantName=${action.plantName}`
  }
  if (action.state) {
    URL += `&state=${action.state}`
  }
  if (action.locationChild) {
    URL += `&locationChild=${action.locationChild}`
  }
  if (action.locationCodeChild) {
    URL += `&locationCodeChild=${action.locationCodeChild}`
  }
  if (action.zoneChild) {
    URL += `&zoneChild=${action.zoneChild}`
  }
  if (action.fromDate) {
    URL += `&fromDate=${action.fromDate}`
  }
  if (action.toDate) {
    URL += `&toDate=${action.toDate}`
  }
  if (action.search) {
    URL += `&search=${action.search}`
  }
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    const data = yield call(getData, URL);
    if (action.onSuccess) {
      action.onSuccess(data && data.data && data.data.list);
    }
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg:
          e && e.responseJSON ? e.responseJSON.message : "Something Went Wrong",
      },
    });
  }
}
function* getParentChildViewListing(action) {
  let URL = API.getParentChildViewListing
  if (action.plantName) {
    URL += `&plantName=${action.plantName}`
  }
  if (action.state) {
    URL += `&state=${action.state}`
  }
  if (action.locationChild) {
    URL += `&locationChild=${action.locationChild}`
  }
  if (action.locationCodeChild) {
    URL += `&locationCodeChild=${action.locationCodeChild}`
  }
  if (action.zoneChild) {
    URL += `&zoneChild=${action.zoneChild}`
  }
  if (action.locationCodeParent) {
    URL += `&locationCodeParent=${action.locationCodeParent}`
  }
  if (action.clusterName) {
    URL += `&clusterName=${action.clusterName}`
  }
  if (action.fromDate) {
    URL += `&fromDate=${action.fromDate}`
  }
  if (action.toDate) {
    URL += `&toDate=${action.toDate}`
  }
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    const data = yield call(getData, URL);
    if (action.onSuccess) {
      action.onSuccess(data && data.data && data.data.list);
    }
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg:
          e && e.responseJSON ? e.responseJSON.message : "Something Went Wrong",
      },
    });
  }
}
function* downloadTemplateInUploadLocation(action) {
  //const param = action.data; // to be changed
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    const data = yield call(getData, API.downloadTemplateInUploadLocationURL + action.data);
    if (action.onSuccess) {
      action.onSuccess(data && data.data);
    }
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg:
          e && e.responseJSON ? e.responseJSON.message : "Something Went Wrong",
      },
    });
  }
}
function* uploadFileInUploadLocation(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    const data = yield call(fileUpload, API.uploadFileInUploadLocationURL, action.file);
    if (action.onSuccess) {
      action.onSuccess(data.data);
    }
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  }
}
function* deleteFileInUploadLocation(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    yield call(deleteData, API.deleteFileInUploadLocationURL + action.data);
    if (action.onSuccess) {
      action.onSuccess()
    }
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: { type: 1, msg: "File deleted Successfully" },
    });
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg:
          e && e.responseJSON ? e.responseJSON.message : "Something Went Wrong",
      },
    });
  }
}
function* getAllClusterMappingData(action) {
  let URL = API.getAllClusterMappingDataURL
  if (action.plantName) {
    URL += `?plantName=${action.plantName}`
  }
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    const data = yield call(getData, URL);
    if (action.onSuccess) {
      action.onSuccess(data.data);
    }
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg:
          e && e.responseJSON ? e.responseJSON.message : "Something Went Wrong",
      },
    });
  }
}
function* getAllLOCOptions(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    const data = yield call(getData, API.getLocChildOptionURL);
    if (action.onSuccess) {
      action.onSuccess(data.data);
    }
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg:
          e && e.responseJSON ? e.responseJSON.message : "Something Went Wrong",
      },
    });
  }
}
function* addClusterMappingData(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    const data = yield call(postData, API.addClusterMappingDataURL, action.data);

    if (data.status == 400 && data.data && data.data.list) {
      yield put({
        type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
        data: {
          type: 2,
          msg:
            data.data.list.message ? data.data.list.message : "Something Went Wrong",
        },
      });
    }
    if (action.onSuccess) {
      action.onSuccess(data);
    }
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg:
          e && e.responseJSON ? e.responseJSON.message : "Something Went Wrong",
      },
    });
  }
}
function* deleteClusterMappingData(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    yield call(deleteData, API.deleteClusterMappingData + action.data);
    if (action.onSuccess) {
      action.onSuccess()
    }
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: { type: 1, msg: "File deleted Successfully" },
    });
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg:
          e && e.responseJSON ? e.responseJSON.message : "Something Went Wrong",
      },
    });
  }
}
function* EditClusterMappingData(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    const data = yield call(putData, API.editClusterMappingData, JSON.stringify(action.data));
    if (action.onSuccess) {
      action.onSuccess(data.data);
    }
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg:
          e && e.responseJSON ? e.responseJSON.message : "Something Went Wrong",
      },
    });
  }
}

function* deleteParentChildLocationData(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    yield call(deleteData, API.deleteParentChildLocationDataURL + action.data);
    if (action.onSuccess) {
      action.onSuccess()
    }
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: { type: 1, msg: "File deleted Successfully" },
    });
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg:
          e && e.responseJSON ? e.responseJSON.message : "Something Went Wrong",
      },
    });
  }
}

function* EditParentChildLocaitonData(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    const data = yield call(putData, API.editParentChildLocaitonDataURL, JSON.stringify(action.data));
    if (action.onSuccess) {
      action.onSuccess(data.data);
    }
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg:
          e && e.responseJSON ? e.responseJSON.message : "Something Went Wrong",
      },
    });
  }
}

function* deleteAllChildData(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    const data = yield call(putData, API.deleteAllClusterDataURL, JSON.stringify(action.data));
    if (action.onSuccess) {
      action.onSuccess()
    }
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: { type: 1, msg: data.message ? data.message : "Line Items deleted Successfully" },
    });
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg:
          e && e.responseJSON ? e.responseJSON.message : "Something Went Wrong",
      },
    });
  }
}

function* fetchLRRange(action) {
  let URL = API.fetchLRRange

  if (action.companyType) {
    URL += `companyType=${action.companyType}`
  }
  if (action.companyId) {
    URL += `company_id=${action.companyId}`
  }
  if (action.lspName && action.lspName !== "All") {
    URL += `&lspName=${action.lspName}`
  }
  if (action.boundType && action.boundType != 3) {
    URL += `&BoundType=${action.boundType}`
  }
  if (action.fromDate) {
    URL += `&fromDate=${action.fromDate}`
  }
  if (action.toDate) {
    URL += `&toDate=${action.toDate}`
  }

  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    const data = yield call(getData, URL);
    if (action.onSuccess) {
      action.onSuccess(data.data)
    }
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.HOME.FETCH_LR_RANGE_SUCCESS,
      data: data.data || []
    });
  } catch (e) { }
}

function* deleteLRRange(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });

    yield call(
      deleteData,
      API.deleteLRRange,
      action.data,
      'stringify'
    );
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });

    if (action.onSuccess) {
      action.onSuccess()
    }
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  }
}


function* saveLrRange(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    yield call(postData, API.saveLrRange, action.data);
    if (action.onSuccess) {
      action.onSuccess();
    }
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) {
    console.log(e)
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: { type: 2, msg: e.responseJSON.message },
    });
  }
}

function* editLRRange(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });

    yield call(
      putData,
      API.updateLrRange,
      JSON.stringify(action.data),
    );
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });

    if (action.onSuccess) {
      action.onSuccess()
    }
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  }
}

function* fetchLspLRange(action) {
  let URL = API.getLspLRRange

  if (action.companyId) {
    URL += `company_id=${action.companyId}`
  }
  if (action.lsp_alias) {
    URL += `&lsp_alias=${action.lsp_alias}`
  }

  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    const data = yield call(getData, URL);
    if (action.onSuccess) {
      action.onSuccess(data.data)
    }
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.HOME.FETCH_LR_LSP_SUCCESS,
      data: data.data || []
    });
  } catch (e) { }
}

function* SendLRRangetoMoglix(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    const data = yield call(putDataForMoglix, API.sendLrRangeToMoglix + action.data, true);
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    if (action.onSuccess) {
      action.onSuccess(data.message)
    }
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  }
}

function* SendLRRangetoSimbus(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    const data = yield call(putDataForMoglix, API.sendLrRangeToSimbus + action.data, true);
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    if (action.onSuccess) {
      action.onSuccess(data.message)
    }
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  }
}

export function* getEmamiPaymentTerms(action) {
  try {
    const resp = yield call(
      axiosT1.get,
      API.getPaymentTermsURL,
      {
        params: action.queryParams,
        headers: {
          token: getToken(),
        },
      });

    if (action.onSuccess) {
      const emamiPaymentList = resp.data.data || []
      action.onSuccess(emamiPaymentList)
    }

  } catch (e) {
    console.log('e:', e)
  }
}

export function* getMaterialMRPDetails(action) {
  try {
    const resp = yield call(
      axiosT1.get,
      API.fetchmrpData,
      {
        params: action.queryParams,
        headers: {
          token: getToken(),
        },
      });

    if (action.onSuccess) {
      const materialMrpDetails = resp.data.data || []
      action.onSuccess(materialMrpDetails)
    }

  } catch (e) {
    console.log('e:', e)
  }
}

export function* downloadMRPTemplate(action) {
  try {
    const resp = yield call(
      axiosT1.get,
      API.downloadMRPTemplateUrl,
      {
        params: action.queryParams,
        headers: {
          token: getToken(),
        },
      });

    if (action.onSuccess) {
      const mrpTemplateDetails = resp.data.data || []
      action.onSuccess(mrpTemplateDetails)
    }

  } catch (e) {
    console.log('e:', e)
  }
}

export function* downloadMRPData(action) {
  try {
    const resp = yield call(
      axiosT1.get,
      API.downloadMRPDataUrl,
      {
        params: action.queryParams,
        headers: {
          token: getToken(),
        },
      });

    if (action.onSuccess) {
      const mrpDataDetails = resp.data.data || []
      action.onSuccess(mrpDataDetails)
    }

  } catch (e) {
    console.log('e:', e)
  }
}

export function* uploadMRPData(action) {
  var headers={
    token: getToken()
  }
  try {
    const resp = yield call(
      axiosT1.post,
      API.uploadMRPUrl,
      action.file,
      {
        headers: headers
      }
      );

    if (action.onSuccess) {
      const uploadMRPDataDetails = resp.data || []
      action.onSuccess(uploadMRPDataDetails)
    }

  } catch (error) {
    action.onCatchError(error)
}
}

// function* fetchAwbLspLRange(action) {
//   let URL = API.getLspAwbLRRange

//   if (action.companyId) {
//     URL += `company_id=${action.companyId}`
//   }
//   if (action.lsp_alias) {
//     URL += `&lsp_alias=${action.lsp_alias}`
//   }

//   try {
//     yield put({
//       type: ACTIONS.LOADER.SHOW_LOADER,
//     });
//     const data = yield call(getData, URL);
//     if (action.onSuccess) {
//       action.onSuccess(data.data)
//     }
//     yield put({
//       type: ACTIONS.LOADER.HIDE_LOADER,
//     });
//     yield put({
//       type: ACTIONS.HOME.FETCH_AWB_LR_LSP_SUCCESS,
//       data: data.data || []
//     });
//   } catch (e) { }
// }

function* fetchAwbLRRange(action) {
  let URL = API.fetchAwbLRRange

  if (action.companyType) {
    URL += `companyType=${action.companyType}`
  }
  if (action.companyId) {
    URL += `company_id=${action.companyId}`
  }
  if (action.lspName && action.lspName !== "All") {
    URL += `&lspName=${action.lspName}`
  }
  if (action.boundType && action.boundType != 3) {
    URL += `&BoundType=${action.boundType}`
  }
  if (action.fromDate) {
    URL += `&fromDate=${action.fromDate}`
  }
  if (action.toDate) {
    URL += `&toDate=${action.toDate}`
  }

  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    const data = yield call(getData, URL);
    if (action.onSuccess) {
      action.onSuccess(data.data)
    }
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.HOME.FETCH_AWB_LR_RANGE_SUCCESS,
      data: data.data || []
    });
  } catch (e) { }
}

function* deleteAwbLRRange(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });

    yield call(
      deleteData,
      API.deleteAwbLRRange,
      action.data,
      'stringify'
    );
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });

    if (action.onSuccess) {
      action.onSuccess()
    }
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  }
}


function* saveAwbLrRange(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    yield call(postData, API.saveAwbLrRange, action.data);
    if (action.onSuccess) {
      action.onSuccess();
    }
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) {
    console.log(e)
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: { type: 2, msg: e.responseJSON.message },
    });
  }
}

function* editAwbLRRange(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });

    yield call(
      putData,
      API.updateAwbLrRange,
      JSON.stringify(action.data),
    );
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });

    if (action.onSuccess) {
      action.onSuccess()
    }
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  }
}

// function* fetchAwbLspLRange(action) {
//   let URL = API.getLspAwbLRRange

//   if (action.companyId) {
//     URL += `company_id=${action.companyId}`
//   }
//   if (action.lsp_alias) {
//     URL += `&lsp_alias=${action.lsp_alias}`
//   }

//   try {
//     yield put({
//       type: ACTIONS.LOADER.SHOW_LOADER,
//     });
//     const data = yield call(getData, URL);
//     if (action.onSuccess) {
//       action.onSuccess(data.data)
//     }
//     yield put({
//       type: ACTIONS.LOADER.HIDE_LOADER,
//     });
//     yield put({
//       type: ACTIONS.HOME.FETCH_AWB_LR_LSP_SUCCESS,
//       data: data.data || []
//     });
//   } catch (e) { }
// }

function* fetchAwbLspLRange(action) {
  // const param = "8f59aec312c948a196db6d3385b8ef75";
  const param = action.data; // to be changed
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    const data = yield call(getData, API.getLspAwbLRRange + param);
    if (action.onSuccess) {
      action.onSuccess(data.data);
    }

    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg:
          e && e.responseJSON ? e.responseJSON.message : "Something Went Wrong",
      },
    });
  }
}

function* SendAwbLRRangetoSimbus(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    const data = yield call(putDataForMoglix, API.sendAwbLrRangeToSimbus + action.data, true);
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    if (action.onSuccess) {
      action.onSuccess(data.message)
    }
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  }
}

function* awbSelectedOptions(action) {
  // const param = "8f59aec312c948a196db6d3385b8ef75";
  const param = action.data; // to be changed
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    const data = yield call(getData, API.awbSelectedOptions + param);
    if (action.onSuccess) {
      action.onSuccess(data.data);
    }

    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg:
          e && e.responseJSON ? e.responseJSON.message : "Something Went Wrong",
      },
    });
  }
}

function* downloadIndividualAwbDetails(action) {
  const param = action.data; // to be changed
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    const data = yield call(getData, API.downloadIndividualAwbURL + param);
    if (action.onSuccess) {
      action.onSuccess(data.data);
    }

    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg:
          e && e.responseJSON ? e.responseJSON.message : "Something Went Wrong",
      },
    });
  }
}
function* downloadAwbLSPName(action) {
  const param = action.data; // to be changed
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    const data = yield call(getData, API.downloadAwbLspName + param);
    if (action.onSuccess) {
      action.onSuccess(data.data);
    }

    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg:
          e && e.responseJSON ? e.responseJSON.message : "Something Went Wrong",
      },
    });
  }
}
function* awbLspOptions(action) {
  // const param = "8f59aec312c948a196db6d3385b8ef75";
  const param = action.data; // to be changed
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    const data = yield call(getData, API.awblspOptions + param);
    if (action.onSuccess) {
      action.onSuccess(data.data);
    }

    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg:
          e && e.responseJSON ? e.responseJSON.message : "Something Went Wrong",
      },
    });
  }
}

function* awbUpdateThreshold(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    yield call(postData, API.awbUpdateThreshold, action.data);
    /*if (action.onSuccess) {
      action.onSuccess(data.data);
    }*/
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: { type: 1, msg: "LSP thresholds updated successfully!!" },
    });
    if (action.onSuccess) {
      action.onSuccess();
    }
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: { type: 2, msg: e.responseJSON.message },
    });
  }
}

export function* getDamageRateDetails(action) {
  try {
    const resp = yield call(
      axiosT1.get,
      API.getDamageRateUrl,
      {
        params: action.queryParams,
        headers: {
          token: getToken(),
        },
      });

    if (action.onSuccess) {
      const damageRateDetails = resp.data.data || []
      action.onSuccess(damageRateDetails)
    }

  } catch (e) {
    console.log('e:', e)
  }
}

export function* downloadDamageRateTemplate(action) {
  try {
    const resp = yield call(
      axiosT1.get,
      API.downloadDamageRateTemplateUrl,
      {
        params: action.queryParams,
        headers: {
          token: getToken(),
        },
      });

    if (action.onSuccess) {
      const damageRateTemplateDetails = resp.data.data || []
      action.onSuccess(damageRateTemplateDetails)
    }

  } catch (e) {
    console.log('e:', e)
  }
}

export function* downloadDamageRateData(action) {
  try {
    const resp = yield call(
      axiosT1.get,
      API.downloadDamageRateDataUrl,
      {
        params: action.queryParams,
        headers: {
          token: getToken(),
        },
      });

    if (action.onSuccess) {
      const damageRateDataDetails = resp.data.data || []
      action.onSuccess(damageRateDataDetails)
    }

  } catch (e) {
    console.log('e:', e)
  }
}

export function* uploadDamageRateData(action) {
  var headers={
    token: getToken()
  }
  try {
    const resp = yield call(
      axiosT1.post,
      API.uploadDamageRateUrl,
      action.file,
      {
        headers: headers
      }
      );

    if (action.onSuccess) {
      const uploadDamageRateDataDetails = resp.data || []
      action.onSuccess(uploadDamageRateDataDetails)
    }

  } catch (error) {
    action.onCatchError(error)
  }
}

  function* addGroupName(action) {
	try {
	  yield put({
		type: ACTIONS.LOADER.SHOW_LOADER,
	  });
	  yield call(postData, API.addGroupName, action.data);
	  if (action.onSuccess) {
		action.onSuccess();
	  }
	  yield put({
		type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
		data: { type: 1, msg: "Group added Successfully" },
	  });
	  yield put({
		type: ACTIONS.LOADER.HIDE_LOADER,
	  });
	} catch (e) {
	  yield put({
		type: ACTIONS.LOADER.HIDE_LOADER,
	  });
	  yield put({
		type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
		data: { type: 2, msg: e.responseJSON.message },
	  });
	}
  }

export function* getDebitNoteConfig(action) {
  try {
   yield put({
     type: ACTIONS.LOADER.SHOW_LOADER,
   });
   const data = yield call(getData, API.getDebitNoteDetailsUrl + action.param);
  //  debugger;
   if (action.onSuccess) {
     action.onSuccess(((data.data || {}).list || [])[0] || {});
   }
   yield put({
     type: ACTIONS.LOADER.HIDE_LOADER,
   });
 } catch (e) {
   yield put({
     type: ACTIONS.LOADER.HIDE_LOADER,
   });
   yield put({
     type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
     data: { type: 2, msg: e.responseJSON.message },
   });
 }
}

export function* saveDebitNoteConfig(action) {
 try {
   yield put({
     type: ACTIONS.LOADER.SHOW_LOADER,
   });
   yield call(postData, API.saveDebitNoteDetailsUrl, action.data);
   if (action.onSuccess) {
     action.onSuccess();
   }
   yield put({
     type: ACTIONS.LOADER.HIDE_LOADER,
   });
 } catch (e) {
   console.log(e)
   yield put({
     type: ACTIONS.LOADER.HIDE_LOADER,
   });
   yield put({
     type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
     data: { type: 2, msg: e.responseJSON.message },
   });
  }
}


export function* fetchTaxCodes(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(getData, API.fetchTaxCodesUrl);
    if(action.onSuccess){
      action.onSuccess(data)
   }
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
	} catch (e) {
    console.log('e:', e)
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: { type: 2, msg: e.responseJSON.message },
    });
	}
}

export function* getCustomerLaneMapping(action) {
  try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(getData, API.getCustomerLaneVendorMapping + action.params);
    yield put({
      type: ACTIONS.HOME.SAVE_CUSTOMER_LANE_MAPPING,
      data: data.data || []
    })
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
	} catch (e) {
    console.log('e:', e)
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: { type: 2, msg: e.responseJSON.message },
    });
	}
}

export function* postCustomerLaneMapping(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    const data = yield call(postData, API.postCustomerLaneVendorMapping, action.data);
    if(action.onSuccess) {
      action.onSuccess();
    }
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: { type: 1, msg: data && data.message || "Added Successfully" },
    });
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch(e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: { type: 2, msg: e.responseJSON.message },
    });
  }
}

export function* updateCustomerLaneMapping(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    const data = yield call(putData, API.putCustomerLaneVendorMapping, JSON.stringify(action.data));
    if(action.onSuccess) {
      action.onSuccess();
    }
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: { type: 1, msg: data && data.message || "Updated Successfully" },
    });
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch(e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: { type: 2, msg: e.responseJSON.message },
    });
  }
}

export function* deleteCustomerLaneMapping(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    const data = yield call(putData, API.deleteCustomerLaneVendorMapping, JSON.stringify(action.data));
    if(action.onSuccess) {
      action.onSuccess();
    }
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: { type: 1, msg: data && data.message || "Deleted Successfully" },
    });
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: { type: 2, msg: e && e.responseJSON ? e.responseJSON.message : "Something Went Wrong" },
    });
  }
}

export function* getExtraDeliveryChargesDetails(action) {
  try {
    const resp = yield call(
      axiosT1.get,
      API.getExtraDeliveryChargeUrl,
      {
        params: action.queryParams,
        headers: {
          token: getToken(),
        },
      });

    if (action.onSuccess) {
      const extraDeliveryChargeDetails = resp.data.data.list || []
      action.onSuccess(extraDeliveryChargeDetails)
      console.log('extraDeliveryChargeDetails:', extraDeliveryChargeDetails)
    }

  } catch (e) {
    console.log('e:', e)
  }
}

export function* deleteExtraDeliveryChargesDetails(action) {
  try {
	  yield put({
		type: ACTIONS.LOADER.SHOW_LOADER,
	  });
  
	  const data = yield call(
		deleteData,
		API.deleteExtraDeliveryChargeUrl + action.queryString
		
	  );
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
	  if (action.onSuccess) {
		action.onSuccess(data);
	  }

	} catch (e) {
	  yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
	}
}

export function* getAdditionalCostChargesDetails(action) {
  try {
    const resp = yield call(
      axiosT1.get,
      API.getAdditionalCostChargeUrl,
      {
        params: action.queryParams,
        headers: {
          token: getToken(),
        },
      });

    if (action.onSuccess) {
      const extraDeliveryChargeDetails = resp.data.data.list || []
      action.onSuccess(extraDeliveryChargeDetails)
      console.log('extraDeliveryChargeDetails:', extraDeliveryChargeDetails)
    }

  } catch (e) {
    console.log('e:', e)
  }
}

export function* deleteAdditionalCostChargesDetails(action) {
  try {
	  yield put({
		type: ACTIONS.LOADER.SHOW_LOADER,
	  });
  
	  const data = yield call(
		deleteData,
		API.deleteAdditionalCostChargeUrl + action.queryString
		
	  );
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
	  if (action.onSuccess) {
		action.onSuccess(data);
	  }

	} catch (e) {
	  yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
	}
}

export function* saveEmudhraDetails(action) {
  console.log('action:', action)
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    yield call(postData, API.saveEmudhraDetailsUrl + action.param, action.data);
    if (action.onSuccess) {
      action.onSuccess();
    }
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) {
    console.log(e)
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: { type: 2, msg: e.responseJSON.message },
    });
   }
 }

 export function* unblockAdminUser(action) {
  var headers={
    token: getToken()
  }
  try {
    const resp = yield call(
      axiosT1.put,
      API.unblockAdminUserUrl,
      action.payload,
      {
        headers: headers
      }
      );

    if (action.onSuccess) {
      action.onSuccess()
    }

  } catch (error) {
    action.onCatchError(error)
  }
}

export {
  getHome,
  addCompany,
  updateCompnayStatus,
  getCompanyBasicDetils,
  getCompanyBasicDetilsForVendorScreen,
  getEstCategory,
  getBusinessSeg,
  uploadCompanyimg,
  updateBasicDetails,
  getRoles,
  lspOptions,
  uploadFile,
  selectedOptions,
  downloads,
  updateThreshold,
  getDepartments,
  addRole,
  deleteRole,
  addDepartment,
  updateDepartment,
  deleteDepartment,
  getProviderUsers,
  addProviderUsers,
  updateProviderUsers,
  fetchAllSeekers,
  fetchAllStates,
  getIndentReasons,
  addIndentReasons,
  updateIndentReasons,
  deleteIndentReasons,
  getModuleList,
  saveModuleList,
  uploadMultipleFiles,
  deleteFile,
  fetchAllCompanyCategory,
  saveCompanyCategoryDetails,
  fetchCompanyCategoryDetails,
  fetchVendorDetails,
  fetchAllTypesData,
  fetchAllModesData,
  getRouteCode,
  addRouteCode,
  updateRouteCode,
  getGroupNameDropdowns,
  addGroupName,
  getDistrict,
  getToDistrict,
  getDistance,
  getVendorMDGDetils,
  getRFQTypes,
  getMaterialConversion,
  addMaterialConversion,
  deleteSapRouteCodeDetails,
  getSchedulerCode,
  addSchedulerCode,
  updateSchedulerCode,
  deleteSchedulerDetails,
  getWarehouseParticulars,
  postWarehouseParticulars,
  getWarehouseTemplateList,
  postWarehouseTemplate,
  getMaterialItemDetails,
  syncChainWithVIMS,
  getRouteMaster,
  downloadRouteMasterDetails,
  downloadRouteMasterTemplate,
  uploadMasterRouteDetails,
  updateRouteMaster,
  addRouteMaster,
  deleteRouteMaster,
  getDisposalMaterial,
  downloadDisposalMaterialTemplate,
  uploadDisposalMaterial,
  downloadDispsalMaterialDetails,
  getDisposalMaterialDropdowns,
  addDisposalMaterial,
  updateDisposalMaterial,
  deleteDisposalMaterial,
  getContractTenureTypes,
  getContractTenureList,
  saveContractTenureList,
  fetchCargillVendorDetails,

  fetchLRUploadSummary,
  deleteLRUploadDetails,
  downloadLRUploadDetail,
  SendLRtoMoglix,
  uploadLRDetail,
  downloadLSPName,
  setLrRange,
  fetchBranchForLr,
  fetchAllSeekerForLr,
  setLrRangeForProvider,
  getRouteCodeDropdowns,
  downloadIndividualLRDetails,
  getProviderLRDetails,
  deleteLRForProvider,
  editLRDataForProvider,

  getParentChildLocationListing,
  getParentChildViewListing,
  downloadTemplateInUploadLocation,
  uploadFileInUploadLocation,
  deleteFileInUploadLocation,
  getAllClusterMappingData,
  getAllLOCOptions,
  addClusterMappingData,
  deleteClusterMappingData,
  EditClusterMappingData,
  deleteAllChildData,
  deleteParentChildLocationData,
  EditParentChildLocaitonData,
  downloadVendorDetails,
  fetchLRRange,
  deleteLRRange,
  saveLrRange,
  editLRRange,
  fetchLspLRange,
  SendLRRangetoMoglix,
  SendLRRangetoSimbus,
  fetchAwbLRRange,
  deleteAwbLRRange,
  saveAwbLrRange,
  editAwbLRRange,
  fetchAwbLspLRange,
  SendAwbLRRangetoSimbus,
  awbSelectedOptions,
  downloadIndividualAwbDetails,
  downloadAwbLSPName,
  awbLspOptions,
  awbUpdateThreshold
};
