import React, { Component } from 'react';
import { connect } from 'react-redux';
import './home.less';
import history from "../history";
import MainComponent from "./main/main";
import logo from "../../assets/images/group-2.svg";
import crossMark from "../../assets/images/cross-mark.svg";
import Select from 'react-select';
import CheckBox from "../components/checkbox/checkbox";
import AddCompany from "./add-company/add-company";
import ACTION from "../common/action_constant";
import Button from "./button-classic/button-classic";
import moment from "moment";
import Loader from "../common/components/loader/loader";
import MSGPopUp from "../common/components/msg-popup/msg-popup";
import SearchText from "../common/components/search-text/search-text";
import { getCookie, setCookie } from "../../utils/cookies";
import { getUserCompanyId, isCompanyAdmin } from "../../utils/common.js";
import { toPascalCase } from "../../utils/validate";
import { withRouter } from 'react-router';

function getToken() {
	let data = getCookie("user") ? getCookie("user") : "";
	let token = data ? JSON.parse(data).token : "";
	return token;
}

class Home extends Component {
	constructor(props) {
		super(props);
		this.state = {
			pan: "",
			offset: 0,
			checkbox: false,
			selectedOption: { value: '1', label: 'Seekers' }
		}

	}
	changeHandler = (val, id) => {
		this.setState({
			[id]: val
		});
	}
	submitSearch = () => {
		this.callHomeDetails()
	}
	callHomeDetails = () => {
		const { dispatch } = this.props;
		let params = "?type=" + this.state.selectedOption.value + "&limit=10&offset=" + this.state.offset;
		if (this.state.pan != "") {
			params = params + "&search=" + this.state.pan;
		}
		dispatch({ type: ACTION.HOME.GET_HOME, data: params });
	}
	handleChange = (selectedOption) => {
		this.setState({ selectedOption }, () => {
			this.callHomeDetails()
		});
	}
	checkBoxChange = (e, id, type) => {
		e.preventDefault();
		e.stopPropagation();
		const data = {
			company_type: type,
			company_id: id,
			is_active: e.target.checked
		}
		const { dispatch } = this.props;
		dispatch({ type: ACTION.HOME.UPDATE_COMPANY_STATUS, data })
	}
	clearFilterOnCancel = (e, type) => {
		this.setState({ [type]: '' }, () => {
			this.callHomeDetails();
		});
	}
	toggelAddCompany = () => {
		const { dispatch } = this.props;
		dispatch({ type: ACTION.HOME.TOGGLE_ADD_COMPANY_MODAL })
	}
	submitAddCompany = (data) => {
		const { dispatch } = this.props;
		dispatch({ type: ACTION.HOME.ADD_COMPANY_API, data })
	}
	handleOnClickForAuditBtn = () => {
		const {history} = this.props;
		const masterType = 3
		const companyId = getUserCompanyId()
		const navUrl = `/auditScreen/${companyId}/${masterType}`;
		history.push(navUrl)
	}
	componentDidMount() {
		const { dispatch } = this.props;
		let url = window.location.href;
    	let params = url.split("?");
   		const isSSOUser = localStorage.getItem('isSSOUser');
		const SSOSessionSaved = localStorage.getItem('teg-sso-session');
		const isToken = getCookie("token");
		console.log('===> cdm')
		if(params && params.length > 1 && !isToken){
			let objects = params[1].split("&");
			(objects || []).forEach((obj) => {
				let data = obj.split("=");
				if(data[0] === "token"){
					setCookie("token", data[1]);
				}
				else if(data[0] === "company_type"){
					setCookie("company_type", Number(data[1]));
				}
				else if(data[0] === "company_id"){
					setCookie("company_id", data[1]);
				}
			})
		}
		if(isSSOUser && SSOSessionSaved !== 'true'){
			dispatch({
			type: ACTION.LOGIN.LOGIN_WITH_SSO,
			onSuccess: () => {
				localStorage.setItem('teg-sso-session', true);
				if (isCompanyAdmin()) {
					this.getCompanyDetails();
				} else {
					this.callHomeDetails();
				}
				dispatch({ type: ACTION.HOME.GET_MODE_OF_TRANSPORT });
			}
			})
		} else{
			if (isCompanyAdmin()) {
				this.getCompanyDetails();
			} else {
				this.callHomeDetails();
			}
			dispatch({ type: ACTION.HOME.GET_MODE_OF_TRANSPORT });
		}
	}
	componentWillReceiveProps(nextProps) {
		if (nextProps !== this.props) {
			this.getCompanyDetails();
		}

	}
	getCompanyDetails = () => {
		if (isCompanyAdmin()) {
			const userInfo = JSON.parse(getCookie('user'));
			if(userInfo.disable_menus && userInfo.disable_menus.indexOf('home') > -1)
			{
				this.props.history.push("/"+userInfo.default_menu[0]+"/");
			}
			else
			{
				this.props.history.push("/home/" + userInfo.company_id + "/" + userInfo.company_type + "?tab=1");
			}
			
		}
	}
	previous = () => {
		if (this.state.offset > 0) {
			this.setState({
				offset: this.state.offset - 10
			}, () => {
				this.callHomeDetails()
			});
		}
	}
	next = () => {
		this.setState({
			offset: this.state.offset + 10
		}, () => {
			this.callHomeDetails()
		});
	}
	goToCompanyDetails = (e = { event }, id, type) => {
		if (e.target.tagName !== "LABEL" && e.target.type !== "checkbox" && !e.target.parentElement.classList.value.includes('switch')) {
			e.preventDefault();
			e.stopPropagation();
			this.props.history.push("/home/" + id + "/" + type + "?tab=1")
		}
	}
	closeMsgPopup = () => {
		const { dispatch } = this.props;
		dispatch({ type: ACTION.MSG_DISPLAY.HIDE_MSG })
		this.callHomeDetails()
	}

	render() {
		const isSSOUser = localStorage.getItem('isSSOUser');
		const SSOSessionSaved = localStorage.getItem('teg-sso-session');
		const addCompanyModal = this.props.home.addCompanyModal;
		const options = [
			{ value: '1', label: 'Seekers', name: 'Seeker' },
			{ value: '2', label: 'Providers', name: 'Provider' },
			{ value: '3', label: '3PL', name: '3PL' },
			{ value: '4', label: 'Fleet Owner & Broker', name: 'Fleet Owner & Broker' },
			{ value: '5', label: 'Fleet Owner', name: 'Fleet Owner' },
			{ value: '6', label: 'Broker', name: 'Broker' },
			{ value: '7', label: 'Fleet Owner & Transporter', name: 'Fleet Owner & Transporter' },
			// { value: '8', label: 'Aggregators', name: 'Aggregator'},
			{ value: '9', label: 'Surveyors', name: 'Surveyor' },
			{ value: '10', label: 'Lashers', name: 'Lasher' },
			{ value: '21', label: 'Handling Agents', name: 'Handling Agent' },
			{ value: '22', label: 'Shipping Liners', name: 'Shipping Liner' },
			{ value: '11', label: 'Fleet Owner and Company', name: 'Fleet Owner and Company'},
			{ value: '8', label: 'Multi Service', name: 'Multi Service'},
			{ value: '24', label: 'Buyer', name: 'Buyer'}, 
			{ value: '13', label: 'CHA', name: 'CHA' },
			{ value: '14', label: 'CFS', name: 'CFS' },
			{ value: '27', label: 'Freight Forwarder', name: 'Freight Forwarder' }
		];
		var companyList = this.props.home.homeDetails && this.props.home.homeDetails.length > 0 ? this.props.home.homeDetails : [];
		let enabledList = ['1','2','3','4','5','6','7','8','9','10', '21','22','11', '23', '24', '13', '14', '27'];
		return (
			<>
			{isSSOUser === "true" ? SSOSessionSaved === "true" : true &&
				<div className="home-wrap">
				{
					addCompanyModal && <AddCompany close={this.toggelAddCompany} submit={this.submitAddCompany} enableList={enabledList} modeOfTransportList={this.props.home.modeOfTransport}/>
				}
				{
					this.props.loader.isLoading && <Loader />
				}
				{
					this.props.msgpopup.active && <MSGPopUp close={this.closeMsgPopup} type={this.props.msgpopup.type} msg={this.props.msgpopup.msg} />
				}
				<div className="wrapper">
					<MainComponent>
						<div className="home-title">
							Home
						</div>
						<div className="sub-heading-section">
							<div className="sub-heading">
								Company List
							</div>
							<div className="add-company-section">
								<Button value="+ Add Company" click={this.toggelAddCompany} />
								<button
										className="button-classic"
										onClick={this.handleOnClickForAuditBtn}
									>
										Audit
									</button>
							</div>
						</div>
						<div className="search-filter-section">
							<SearchText id="pan" value={this.state.pan} placeholder="Search PAN No Or Company..." changeHandler={this.changeHandler} click={this.submitSearch} canClear={this.state.pan !== ''} handleClearFilter={(e) => this.clearFilterOnCancel(e, "pan")} />
							<Select className="select" value={this.state.selectedOption} onChange={this.handleChange} options={options} />
						</div>
						<div className="company-list-wrap">
							<div className="list-section header">
								<div className="company-name-section table-header">
									Company Name
								</div>
								<div className="admin-details table-header">
									Admin Details
								</div>
								<div className="account-type table-header">
									Account Type
								</div>
								<div className="status table-header">
									Status
								</div>
							</div>
							{
								this.props.home.homeDetails && this.props.home.homeDetails.length == 0 && <div className="no-records-section">No records found.</div>
							}
							{
								companyList.map((company, index) => {
									const compnay_logo = company.company_logo == "" ? logo : company.company_logo;
									return (
										<div className="list-section content" key={index} onClick={(e) => this.goToCompanyDetails(e, company.id, company.company_type)}>
											<div className="company-name-section">
												<div className="comany-logo" style={{ backgroundImage: `url(${compnay_logo})` }}></div>
												<div className="compnay-details">
													<div className="name">{toPascalCase(company.company_name)}</div>
													<div className="date">{moment(company.created).format('DD-MMM-YYYY')}</div>
												</div>
											</div>
											<div className="admin-details">
												<div className="admin-email">{company.email}</div>
												<div className="admin-pan">{company.pan_no}</div>
											</div>
											<div className="account-type">
												<div>
											{
											((options|| []).find(opt => +opt.value === company.company_type) || {}).name}
											</div>
											</div>
											<div className="status">
												<CheckBox type={company.company_type} status={company.is_active} id={company.id} handleChange={this.checkBoxChange} />
											</div>
										</div>
									)
								})
							}
							<div className="submit-section-pagination">
								<div className="next">
									<Button value="Next" click={this.next} />
								</div>
								<div className="pre">
									<Button value="Previous" click={this.previous} />
								</div>
							</div>
						</div>
					</MainComponent>
				</div>
			</div>
			}
		</>
		);
	}
}



const mapStateToProps = state => {
	return {
		home: state.home,
		loader: state.loader,
		msgpopup: state.msgpopup
	};
};

export default connect(mapStateToProps)(withRouter(Home));