import React, { Component } from "react";
import Select from "react-select";
import { FaPlus, FaTrashAlt } from "react-icons/fa";
import { IoIosAddCircleOutline } from "react-icons/io";
import { RiSaveLine } from "react-icons/ri";
import "./TaxCodeDetails.scss";

class TaxCodeDetails extends Component {
  render() {
    const {
      taxCodeDetailsRows,
      planningOptions,
      taxCodeOptions,
      isSendToSap,
      handleTaxCodeDetailInputChange,
      handleDeleteTaxCodeDetailRow,
      handleAddTaxCodeDetailRow,
      saveTaxCodeDetails,
      isSaveVisible,
    } = this.props;

    return (
      <div className="tax-code-details">
        <h3>Tax code details</h3>
        <div className="planning-component">
          <div className="row-container">
            {(taxCodeDetailsRows || []).map((row, index) => (
              <div key={row.id} className="row">
                <div className="column">
                  {index == 0 && <label>Planning Point</label>}
                  <Select
                    value={(planningOptions || []).find(
                      (option) => option.value === row.planningPoint
                    )}
                    onChange={(selectedOption) =>
                      handleTaxCodeDetailInputChange(
                        index,
                        "planningPoint",
                        selectedOption
                      )
                    }
                    options={(planningOptions || []).filter(
                      (option) =>
                        !(taxCodeDetailsRows || []).some(
                          (rowItem) => rowItem.planningPoint === option.value
                        )
                    )}
                    placeholder="Planning Point"
                  />
                </div>
                <div className="column">
                  {index == 0 && <label>Percentage (%)</label>}
                  <input
                    type="number"
                    placeholder="Tax Percent"
                    value={row.condAmount}
                    onChange={(e) =>
                      handleTaxCodeDetailInputChange(
                        index,
                        "condAmount",
                        e.target.value
                      )
                    }
                    disabled
                    className="row-input"
                  />
                </div>
                <div className="column">
                  {index == 0 && <label>Tax Code</label>}
                  <Select
                    value={(taxCodeOptions || []).find(
                      (option) => option.value === row.taxCode
                    )}
                    onChange={(selectedOption) =>
                      handleTaxCodeDetailInputChange(
                        index,
                        "taxCode",
                        selectedOption
                      )
                    }
                    options={taxCodeOptions || []}
                    placeholder="Tax Code"
                  />
                </div>

                <div className={index == 0 ? "transhColumn" : "column"}>
                  {!isSendToSap && (
                    <button
                      className="delete-btn"
                      onClick={() => handleDeleteTaxCodeDetailRow(index)}
                    >
                      <FaTrashAlt />
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div>
          {!isSendToSap && (
            <div className="tax-code-btns">
              <button className="add-btn" onClick={handleAddTaxCodeDetailRow}>
                <IoIosAddCircleOutline /> Add
              </button>
              {isSaveVisible() && (
                <button className="add-btn" onClick={saveTaxCodeDetails}>
                  <RiSaveLine /> Save
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default TaxCodeDetails;
