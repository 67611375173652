const actions = {
  LOGIN: {
    USERLOGIN: "USERLOGIN",
    STORE_USER: "STORE_USER",
    VALIDATE_SSO_USER: "VALIDATE_SSO_USER",
    LOGIN_WITH_SSO: "LOGIN_WITH_SSO",
    GET_SSO_CONFIG: 'GET_SSO_CONFIG',
    STORE_SSO_CONFIG_DATA: 'STORE_SSO_CONFIG_DATA',
  },
  HOME: {
    TOGGLE_ADD_COMPANY_MODAL: "TOGGLE_ADD_COMPANY_MODAL",
    ADD_COMPANY_API: "ADD_COMPANY_API",
    GET_HOME: "GET_HOME",
    STORE_HOME_DETAILS: "STORE_HOME_DETAILS",
    UPDATE_COMPANY_STATUS: "UPDATE_COMPANY_STATUS",
    STORE_COMPANY_STATUS: "STORE_COMPANY_STATUS",
    GET_ROLES: "GET_ROLES",
    LSP_OPTIONS: "LSP_OPTIONS",
    SELECTED_OPTIONS : "SELECTED_OPTIONS",
    DOWNLOADS_OPTIONS : "DOWNLOADS_OPTIONS",
    THRESHOLD_UPDATES : "THRESHOLD_UPDATES",
    FILE_UPLOAD: "FILE_UPLOAD",
    STORE_ROLES: "STORE_ROLES",
    ADD_ROLE: "ADD_ROLE",
    GET_TRANSPOTER_LIST:"GET_TRANSPOTER_LIST",
    ADD_ROLE_DETAILS : "ADD_ROLE_DETAILS",
    STORE_ROLE_DETAILS : "STORE_ROLE_DETAILS",
    GET_ROLE_DETAILS : "GET_ROLE_DETAILS",
    DELETE_ROLE_DETAILS: "DELETE_ROLE_DETAILS",
    UPDATE_ROLE_DETAILS: "UPDATE_ROLE_DETAILS",
    ADD_ACTION_DETAILS : "ADD_ACTION_DETAILS",
    GET_ACTION_DETAILS : "GET_ACTION_DETAILS",
    STORE_ACTION_DETAILS : "STORE_ACTION_DETAILS",
    UPDATE_ACTION_DETAILS : "UPDATE_ACTION_DETAILS",
    DELETE_ROLE: "DELETE_ROLE",
    GET_DEPARTMENTS: "GET_DEPARTMENTS",
    STORE_DEPARTMENTS: "STORE_DEPARTMENTS",
    ADD_DEPARTMENT: "ADD_DEPARTMENT",
    UPDATE_DEPARTMENT: "UPDATE_DEPARTMENT",
    DELETE_DEPARTMENT: "DELETE_DEPARTMENT",
    GET_BASIC_DETAILS: "GET_BASIC_DETAILS",
    STORE_BASIC_DETAILS: "STORE_BASIC_DETAILS",
    GET_BASIC_DETAILS_FOR_VENDOR_SCREEN: "GET_BASIC_DETAILS_FOR_VENDOR_SCREEN",
    STORE_BASIC_DETAILS_FOR_VENDOR_SCREEN: "STORE_BASIC_DETAILS_FOR_VENDOR_SCREEN",
    GET_EMAMI_PAYMENT_TERMS: "GET_EMAMI_PAYMENT_TERMS",
    GET_EST_CATEGORY: "GET_EST_CATEGORY",
    STORE_EST_CATEGORY: "STORE_EST_CATEGORY",
    GET_BUSINESS_SEG: "GET_BUSINESS_SEG",
    STORE_BUSINESS_SEG: "STORE_BUSINESS_SEG",
    UPLOAD_COMPANY_IMG: "UPLOAD_COMPANY_IMG",
    STORE_COMPANY_IMG: "STORE_COMPANY_IMG",
    UPDATE_BASIC_DETAILS: "UPDATE_BASIC_DETAILS",
    GET_PROVIDER_USERS: "GET_PROVIDER_USERS",
    STORE_PROVIDER_USERS: "STORE_PROVIDER_USERS",
    ADD_PROVIDER_USERS: "ADD_PROVIDER_USERS",
    UPDATE_PROVIDER_USERS: "UPDATE_PROVIDER_USERS",
    FETCH_ALL_SEEKERS: "FETCH_ALL_SEEKERS",
    STORE_ALL_SEEKERS: "STORE_ALL_SEEKERS",
    GET_ALL_STATE: "GET_ALL_STATE",
    STORE_ALL_STATE: "STORE_ALL_STATE",
    ADD_INDENT_REASONS: "ADD_INDENT_REASONS",
    GET_INDENT_REASONS: "GET_INDENT_REASONS",
    STORE_INDENT_REASONS: "STORE_INDENT_REASONS",
    UPDATE_INDENT_REASONS: "UPDATE_INDENT_REASONS",
    DELETE_INDENT_REASONS: "DELETE_INDENT_REASONS",
    GET_MODULES_LIST: "GET_MODULES_LIST",
    STORE_MODULES_LIST: "STORE_MODULES_LIST",
    SAVE_MODULES_LIST: "SAVE_MODULES_LIST",
    SAVE_ROLE_ACTION_LIST : "SAVE_ROLE_ACTION_LIST",
    UPLOAD_MULTIPLE_FILE: "UPLOAD_MULTIPLE_FILE",
    STORE_COMPANY_FILE: "STORE_COMPANY_FILE",
    DELETE_FILE : "DELETE_FILE",
    STORE_ALL_COMPANY_CATEGORY: "STORE_ALL_COMPANY_CATEGORY",
    GET_COMPANY_CATEGORY: "GET_COMPANY_CATEGORY",
    SAVE_COMPANY_CATEGORY_DETAILS :"SAVE_COMPANY_CATEGORY_DETAILS",
    FETCH_COMPANY_CATEGORY: "FETCH_COMPANY_CATEGORY",
    FETCH_VENDOR_DETAILS: "FETCH_VENDOR_DETAILS",
    FETCH_CARGILL_VENDOR_DETAILS: "FETCH_CARGILL_VENDOR_DETAILS",
    DOWNLOAD_VENDOR_DETAILS: "DOWNLOAD_VENDOR_DETAILS",
    STORE_COMPANY_CATEGORY_DETAILS: "STORE_COMPANY_CATEGORY_DETAILS",
    GET_ALL_TYPES: "GET_ALL_TYPES",
    STORE_ALL_TYPES: "STORE_ALL_TYPES",
    GET_ALL_MODES: "GET_ALL_MODES",
    STORE_ALL_MODES: "STORE_ALL_MODES",
    GET_ROUTE_CODE: "GET_ROUTE_CODE",
    ADD_ROUTE_CODE: "ADD_ROUTE_CODE",
    UPDATE_ROUTE_CODE: "UPDATE_ROUTE_CODE",
    SAVE_ROUTE_CODE: "SAVE_ROUTE_CODE",
    GET_ROUTE_CODE_DISTANCE: "GET_ROUTE_CODE_DISTANCE",
    GET_DISTRICT:"GET_DISTRICT",
    SAVE_DISTRICT:"SAVE_DISTRICT",
    GET_TO_DISTRICT:"GET_TO_DISTRICT",
    SAVE_TO_DISTRICT:"SAVE_TO_DISTRICT",
    STORE_VENDOR_DETAILS: "STORE_VENDOR_DETAILS",
    GET_VENDOR_MDG_DETAILS: "GET_VENDOR_MDG_DETAILS",
    GET_RFQ_TYPES: "GET_RFQ_TYPES",
    SAVE_RFQ_TYPES: "SAVE_RFQ_TYPES",
    UPLOAD_USER : "UPLOAD_USER",
    DOWNLOAD_USER :"DOWNLOAD_USER",
    STORE_USER_URL : "STORE_USER_URL",
    STORE_BRANCH_URL : "STORE_BRANCH_URL",
    DOWNLOAD_BRANCH :"DOWNLOAD_BRANCH",
    UPLOAD_BRANCH : "UPLOAD_BRANCH",
    STORE_DEPARTMENT_URL :"STORE_DEPARTMENT_URL",
    DOWNLOAD_DEPARTMENT :"DOWNLOAD_DEPARTMENT",
    UPLOAD_DEPARTMENT : "UPLOAD_DEPARTMENT",
    DOWNLOAD_ROLES :"DOWNLOAD_ROLES",
    UPLOAD_ROLES : "UPLOAD_ROLES",
    GET_MATERIAL_CONVERSION: "GET_MATERIAL_CONVERSION",
    ADD_MATERIAL_CONVERSION: "ADD_MATERIAL_CONVERSION",
    SAVE_MATERIAL_CONVERSION: "SAVE_MATERIAL_CONVERSION",
    DOWNLOAD_ROUTE_CODE: "DOWNLOAD_ROUTE_CODE",
    UPLOADLOAD_ROUTE_CODE: "UPLOADLOAD_ROUTE_CODE",
    DELETE_ROUTE_CODE : "DELETE_ROUTE_CODE",
    DOWNLOAD_ROUTE_CODE_DETAILS: "DOWNLOAD_ROUTE_CODE_DETAILS",
    GET_SCHEDULER_CODE: "GET_SCHEDULER_CODE",
    ADD_SCHEDULER_CODE: "ADD_SCHEDULER_CODE",
    UPDATE_SCHEDULER_CODE: "UPDATE_SCHEDULER_CODE",
    SAVE_SCHEDULER_CODE: "SAVE_SCHEDULER_CODE",
    DELETE_SCHEDULER_CODE: "DELETE_SCHEDULER_CODE",
    DOWNLOAD_SCHEDULER_DETAILS: "DOWNLOAD_SCHEDULER_DETAILS",
    UPLOAD_SCHEDULER_DETAILS: "UPLOAD_SCHEDULER_DETAILS",
    GET_WAREHOUSE_PARTICULARS_LIST: "GET_WAREHOUSE_PARTICULARS_LIST",
    SAVE_WAREHOUSE_PARTICULARS_LIST: "SAVE_WAREHOUSE_PARTICULARS_LIST",
    UPDATE_WAREHOUSE_PARTICULARS_LIST: "UPDATE_WAREHOUSE_PARTICULARS_LIST",
    GET_WAREHOUSE_TEMPLATE_LIST: "GET_WAREHOUSE_TEMPLATE_LIST",
    SAVE_WAREHOUSE_TEMPLATE_LIST: "SAVE_WAREHOUSE_TEMPLATE_LIST",
    UPLOAD_MATERIAL_CONVERSION: "UPLOAD_MATERIAL_CONVERSION",
    DOWNLOAD_MATERIAL_CONVERSION: "DOWNLOAD_MATERIAL_CONVERSION",
    UPDATE_MATERIAL_CONVERSION: "UPDATE_MATERIAL_CONVERSION",
    DELETE_MATERIAL_CONVERSION: "DELETE_MATERIAL_CONVERSION",
    GET_VENDOR_MATERIAL_DETAILS: "GET_VENDOR_MATERIAL_DETAILS",
    SAVE_VENDOR_MATERIAL_DETAILS: "SAVE_VENDOR_MATERIAL_DETAILS",
    ADD_VENDOR_MATERIAL_DETAILS: "ADD_VENDOR_MATERIAL_DETAILS",
    UPDATE_VENDOR_MATERIAL_DETAILS: "UPDATE_VENDOR_MATERIAL_DETAILS",
    DELETE_VENDOR_MATERIAL_DETAILS: "DELETE_VENDOR_MATERIAL_DETAILS",
    UPLOAD_VENDOR_MATERIAL_DETAILS: "UPLOAD_VENDOR_MATERIAL_DETAILS",
    DOWNLOAD_VENDOR_MATERIAL_DETAILS: "DOWNLOAD_VENDOR_MATERIAL_DETAILS",
    GET_VENDOR_WITH_SAP_CODE: "GET_VENDOR_WITH_SAP_CODE",
    SAVE_VENDOR_WITH_SAP_CODE: "SAVE_VENDOR_WITH_SAP_CODE",
    DOWNLOAD_PARTICULARS_CONVERSION: "DOWNLOAD_PARTICULARS_CONVERSION",
    UPLOAD_PARTICULARS_CONVERSION: "UPLOAD_PARTICULARS_CONVERSION",
    STORE_MATERIAL_ITEM_DETAILS: "STORE_MATERIAL_ITEM_DETAILS",
    GET_MATERIAL_ITEM_DETAILS: "GET_MATERIAL_ITEM_DETAILS",
    GET_MODE_OF_TRANSPORT: "GET_MODE_OF_TRANSPORT",
    SAVE_MODE_OF_TRANSPORT: "SAVE_MODE_OF_TRANSPORT",
    SYNC_CHAIN_WITH_VIMS: 'SYNC_CHAIN_WITH_VIMS',
    GET_ROUTE_MASTER: 'GET_ROUTE_MASTER',
    ADD_ROUTE_MASTER: 'ADD_ROUTE_MASTER',
    UPDATE_ROUTE_MASTER: 'UPDATE_ROUTE_MASTER',
    DELETE_ROUTE_MASTER: 'DELETE_ROUTE_MASTER',
    DOWNLOAD_ROUTE_MASTER_TEMPLATE: 'DOWNLOAD_ROUTE_MASTER_TEMPLATE',
    DOWNLOAD_ROUTE_MASTER_DETAILS: 'DOWNLOAD_ROUTE_MASTER_DETAILS',
    UPLOAD_ROUTE_MASTER: 'UPLOAD_ROUTE_MASTER',
    SAVE_ROUTE_MASTER: 'SAVE_ROUTE_MASTER',
    GET_DISPOSAL_MATERIAL: 'GET_DISPOSAL_MATERIAL',
    SAVE_DISPOSAL_MATERIAL: 'SAVE_DISPOSAL_MATERIAL',
    ADD_DISPOSAL_MATERIAL: 'ADD_DISPOSAL_MASTERIAL',
    UPDATE_DISPOSAL_MATERIAL: 'UPDATE_DISPOSAL_MATERIAL',
    DELETE_DISPOSAL_MATERIAL: 'DELETE_DISPOSAL_MATERIAL',
    DOWNLOAD_DISPOSAL_MATERIAL_TEMPLATE: 'DOWNLOAD_DISPOSAL_MATERIAL_TEMPLATE',
    UPLOAD_DISPOSAL_MATERIAL: 'UPLOAD_DISPOSAL_MATERIAL',
    DOWNLOAD_DISPOSAL_MATERIAL_DETAILS: 'DOWNLOAD_DISPOSAL_MATERIAL_DETAILS',
    GET_DISPOSAL_MATERIAL_DROPDOWNS: 'GET_DISPOSAL_MATERIAL_DROPDOWNS',
    SAVE_DISPOSAL_MATERIAL_DROPDOWNS: 'SAVE_DISPOSAL_MATERIAL_DROPDOWNS',
    GET_CONTRACT_TENURE_TYPES: 'GET_CONTRACT_TENURE_TYPES',
    SAVE_CONTRACT_TENURE_TYPES: 'SAVE_CONTRACT_TENURE_TYPES',
    GET_CONTRACT_TENURE_LIST: 'GET_CONTRACT_TENURE_LIST',
    SAVE_CONTRACT_TENURE_LIST: 'SAVE_CONTRACT_TENURE_LIST',
    UPDATE_CONTRACT_TENURE_LIST: 'UPDATE_CONTRACT_TENURE_LIST',
    FETCH_LR_UPLOAD_SUMMARY: 'FETCH_LR_UPLOAD_SUMMARY',
    FETCH_LR_UPLOAD_SUMMARY_SUCCESS: 'FETCH_LR_UPLOAD_SUMMARY_SUCCESS',
    DELETE_LR_UPLOAD_DETAILS : 'DELETE_LR_UPLOAD_DETAILS',
    DOWNLOAD_LR_UPLOAD_DETAIL : 'DOWNLOAD_LR_UPLOAD_DETAIL',
    SEND_LR_TO_MOGLIX : 'SEND_LR_TO_MOGLIX',
    SEND_LR_TO_MOGLIX_SUCCESS : 'SEND_LR_TO_MOGLIX_SUCCESS',
    UPLOAD_LR_DETAIL : 'UPLOAD_LR_DETAIL',
    DOWNLOAD_LSP_DATA : 'DOWNLOAD_LSP_DATA',
    SET_LR_RANGE : 'SET_LR_RANGE',
    GET_BRANCH_FOR_LR : 'GET_BRANCH_FOR_LR',
    GET_ALL_SEEKERS_FOR_LR : 'GET_ALL_SEEKERS_FOR_LR',
    SET_LR_RANGE_FOR_PROVIDER : 'SET_LR_RANGE_FOR_PROVIDER',
    GET_ROUTE_CODE_DROPDOWN : 'GET_ROUTE_CODE_DROPDOWN',
    DOWNLOAD_INDIVIDUAL_LR_DETAILS : 'DOWNLOAD_INDIVIDUAL_LR_DETAILS',
    GET_PROVIDER_LR_DETAILS : 'GET_PROVIDER_LR_DETAILS',
    DELETE_LR_FOR_PROVIDER : 'DELETE_LR_FOR_PROVIDER',
    EDIT_LR_DATA_FOR_PROVIDER : 'EDIT_LR_DATA_FOR_PROVIDER',
    GET_GROUP_NAME_DROPDOWN : 'GET_GROUP_NAME_DROPDOWN',
    SAVE_GROUP_NAME_DROPDOWN : 'SAVE_GROUP_NAME_DROPDOWN',
    ADD_GROUP_NAME : 'ADD_GROUP_NAME',
    FETCH_LR_RANGE_SUCCESS: 'FETCH_LR_RANGE_SUCCESS',
    FETCH_LR_RANGE: 'FETCH_LR_RANGE',
    DELETE_LR_RANGE : 'DELETE_LR_RANGE',
    ADD_LR_RANGE:'ADD_LR_RANGE',
    EDIT_LR_RANGE : 'EDIT_LR_RANGE',
    FETCH_LR_LSP_SUCCESS:"FETCH_LR_LSP_SUCCESS",
    FETCH_LR_LSP_RANGE: 'FETCH_LR_LSP_RANGE',
    SEND_LR_RANGE_TO_MOGLIX : 'SEND_LR_RANGE_TO_MOGLIX',
    SEND_LR_RANGE_TO_SIMBUS : 'SEND_LR_RANGE_TO_SIMBUS',
    GET_MATERIALA_DETAILS:'GET_MATERIALA_DETAILS',
    DOWNLOAD_MRP_TEMPLATE:'DOWNLOAD_MRP_TEMPLATE',
    DOWNLOAD_MRP_DATA:'DOWNLOAD_MRP_DATA',
    UPLOAD_MRP_DATA:'UPLOAD_MRP_DATA',

    GET_DAMAGE_RATE_DETAILS:'GET_DAMAGE_RATE_DETAILS',
    DOWNLOAD_DAMAGE_RATE_TEMPLATE:'DOWNLOAD_DAMAGE_RATE_TEMPLATE',
    DOWNLOAD_DAMAGE_RATE_DATA:'DOWNLOAD_DAMAGE_RATE_DATA',
    UPLOAD_DAMAGE_RATE_DATA:'UPLOAD_DAMAGE_RATE_DATA',

    FETCH_AWB_LR_RANGE_SUCCESS: 'FETCH_AWB_LR_RANGE_SUCCESS',
    FETCH_AWB_LR_RANGE: 'FETCH_AWB_LR_RANGE',
    DELETE_AWB_LR_RANGE : 'DELETE_AWB_LR_RANGE',
    ADD_AWB_LR_RANGE:'ADD_AWB_LR_RANGE',
    EDIT_AWB_LR_RANGE : 'EDIT_AWB_LR_RANGE',
    FETCH_AWB_LR_LSP_SUCCESS:"FETCH_AWB_LR_LSP_SUCCESS",
    FETCH_AWB_LR_LSP_RANGE: 'FETCH_AWB_LR_LSP_RANGE',
    SEND_AWB_LR_RANGE_TO_SIMBUS : 'SEND_AWB_LR_RANGE_TO_SIMBUS',
    AWB_SELECTED_OPTIONS : "AWB_SELECTED_OPTIONS",
    DOWNLOAD_INDIVIDUAL_AWB_DETAILS : 'DOWNLOAD_INDIVIDUAL_AWB_DETAILS',
    DOWNLOAD_AWB_LSP_DATA : 'DOWNLOAD_AWB_LSP_DATA',
    AWB_LSP_OPTIONS: "AWB_LSP_OPTIONS",
    AWB_THRESHOLD_UPDATES : "AWB_THRESHOLD_UPDATES",
    GET_DEBIT_NOTE_CONFIG: "GET_DEBIT_NOTE_CONFIG",
    SAVE_DEBIT_NOTE_CONFIG: "SAVE_DEBIT_NOTE_CONFIG",

    FETCH_TAX_CODES:"FETCH_TAX_CODES",

    GET_CUSTOMER_LANE_MAPPING: "GET_CUSTOMER_LANE_MAPPING",
    SAVE_CUSTOMER_LANE_MAPPING: "SAVE_CUSTOMER_LANE_MAPPING",
    POST_CUSTOMER_LANE_MAPPING: "POST_CUSTOMER_LANE_MAPPING",
    UPDATE_CUSTOMER_LANE_MAPPING: "UPDATE_CUSTOMER_LANE_MAPPING",
    DELETE_CUSTOMER_LANE_MAPPING: "DELETE_CUSTOMER_LANE_MAPPING",
    
    //Delivery-Charges: extra delivery charges
    GET_EXTRA_DELIVERY_CHARGE_DETAILS:'GET_EXTRA_DELIVERY_CHARGE_DETAILS',
    DELETE_EXTRA_DELIVERY_CHARGE_DETAILS:'DELETE_EXTRA_DELIVERY_CHARGE_DETAILS',

    //Delivery-Charges: Additional cost charges
    GET_ADDITIONAL_COST_CHARGE_DETAILS:'GET_ADDITIONAL_COST_CHARGE_DETAILS',
    DELETE_ADDITIONAL_COST_CHARGE_DETAILS:'DELETE_ADDITIONAL_COST_CHARGE_DETAILS',

    SAVE_EMUDHRA_DETAILS: 'SAVE_EMUDHRA_DETAILS',
    UNBLOCK_ADMIN_USER:'UNBLOCK_ADMIN_USER',
  },
  PLANTS: {
    GET_PLANTS_DEPOTS: "GET_PLANTS_DEPOTS",
    STORE_PLANTS_DEPOTS: "STORE_PLANTS_DEPOTS",
    GET_ZONES: "GET_ZONES",
    STORE_ZONES: "STORE_ZONES",
    GET_BRANCH_TYPES: "GET_BRANCH_TYPES",
    STORE_BRANCH_TYPES: "STORE_BRANCH_TYPES",
    SET_CURRENT_DEPOT: "SET_CURRENT_DEPOT",
    SET_TRACK_DETAILS: "SET_TRACK_DETAILS",
    UPDATE_CURRENT_DEPOT: "UPDATE_CURRENT_DEPOT",
    ADD_NEW_CURRENT_DEPOT: "ADD_NEW_CURRENT_DEPOT",
    STORE_DEPOT_USERS: "STORE_DEPOT_USERS",
    GET_DEPOT_USERS: "GET_DEPOT_USERS",
    GET_BRANCHES: "GET_BRANCHES",
    STORE_BRANCHES: "STORE_BRANCHES",
    VERIFY_USER: "VERIFY_USER",
    STORE_VERIFIED_USER: "STORE_VERIFIED_USER",
    GET_ACTIONS: "GET_ACTIONS",
    STORE_ACTIONS: "STORE_ACTIONS",
    ADD_USER: "ADD_USER",
    UPDATE_USER: "UPDATE_USER",
    TOGGLE_USER_STATUS: "TOGGLE_USER_STATUS",
    DELETE_USER: "DELETE_USER",
    GET_INDENTING_FLOWS: "GET_INDENTING_FLOWS",
    GET_INDENTING_ITEMS: "GET_INDENTING_ITEMS",
    STORE_INDENTING_FLOWS: "STORE_INDENTING_FLOWS",
    STORE_INDENTING_ITEMS: "STORE_INDENTING_ITEMS",
    GET_INDENTING_USERS: "GET_INDENTING_USERS",
    STORE_INDENTING_USERS: "STORE_INDENTING_USERS",
    GET_INDENTING_MOVES: "GET_INDENTING_MOVES",
    STORE_INDENTING_MOVES: "STORE_INDENTING_MOVES",
    GET_INDENTING_SUBMOVES: "GET_INDENTING_SUBMOVES",
    STORE_INDENTING_SUBMOVES: "STORE_INDENTING_SUBMOVES",
    GET_INDENTING_ACTIONS: "GET_INDENTING_ACTIONS",
    STORE_INDENTING_ACTIONS: "STORE_INDENTING_ACTIONS",
    GET_INDENTING_CHECKLISTS: "GET_INDENTING_CHECKLISTS",
    STORE_INDENTING_CHECKLISTS: "STORE_INDENTING_CHECKLISTS",
    GET_INDENTING_WAREHOUSES: "GET_INDENTING_WAREHOUSES",
    STORE_INDENTING_WAREHOUSES: "STORE_INDENTING_WAREHOUSES",
    GET_INDENTING_VENDORS: "GET_INDENTING_VENDORS",
    STORE_INDENTING_VENDORS: "STORE_INDENTING_VENDORS",
    ADD_MOVE: "ADD_MOVE",
    ADD_ACTION: "ADD_ACTION",
    ADD_CHECKLIST: "ADD_CHECKLIST",
    ADD_WAREHOUSE: "ADD_WAREHOUSE",
    ADD_VENDOR: "ADD_VENDOR",
    GET_DEPOT_MOVES: "GET_DEPOT_MOVES",
    SUBMIT_MOVES: "SUBMIT_MOVES",
    DELETE_MOVE: "DELETE_MOVE",
    GET_FLOW_DETAILS: "GET_FLOW_DETAILS",
    STORE_INDENTING_FLOW_DETAILS: "STORE_INDENTING_FLOW_DETAILS",
    GET_SUBPLANTS: "GET_SUBPLANTS",
    STORE_SUBPLANTS: "STORE_SUBPLANTS",
    ADD_SUBPLANTS: "ADD_SUBPLANTS",
    GET_ACTION_TYPES: "GET_ACTION_TYPES",
    STORE_ACTION_TYPES: "STORE_ACTION_TYPES",
    GET_ALL_BRANCH: "GET_ALL_BRANCH",
    STORE_ALL_BRANCH: "STORE_ALL_BRANCH",
    STORE_ROLE_DETAILS : "STORE_ROLE_DETAILS",
    GET_INDENTING_FIELDS : "GET_INDENTING_FIELDS",
    STORE_INDENTING_FIELDS : "STORE_INDENTING_FIELS",
    ADD_INDENTING_FIELDS : "ADD_INDENTING_FIELDS",
    ADD_LOADING_BAY : "ADD_LOADING_BAY",
    GET_LOADING_BAY : "GET_LOADING_BAY",
    UPDATE_LOADING_BAY : "UPDATE_LOADING_BAY",
    ADD_LOADING_GANG : "ADD_LOADING_GANG",
    GET_LOADING_GANG : "GET_LOADING_GENG",
    UPDATE_LOADING_GANG : "UPDATE_LOADING_GANG",
    SAVE_LOADING_BAY : "SAVE_LOADING_BAY",
    SAVE_LAODING_GANG : "SAVE_LAODING_GANG",
    DELETE_LOADING_BAY : "DELETE_LOADING_BAY",
    DELETE_LODING_GANG : "DELETE_LODING_GANG",
    GET_INDENTING_EDIT_FIELDS : "GET_INDENTING_EDIT_FIELDS",
    SAVE_INDENTING_EDIT_FIELDS : "SAVE_INDENTING_EDIT_FIELDS",
    ADD_MOVE_STANDARD_TIME : "ADD_MOVE_STANDARD_TIME",
    GET_MOVE_STANDARD_TIME : "GET_MOVE_STANDARD_TIME",
    SAVE_MOVE_STANDARD_TIME : "SAVE_MOVE_STANDARD_TIME",
    UPDATE_MOVE_STANDARD_TIME: "UPDATE_MOVE_STANDARD_TIME",
    GET_APPROVER_LIST: "GET_APPROVER_LIST",
    SAVE_APPROVER_LIST: "SAVE_APPROVER_LIST",
    POST_APPROVER_LIST: "POST_APPROVER_LIST",
    UPDATE_APPROVER_LIST: "UPDATE_APPROVER_LIST",
    APPLY_APPROVER_SETTING_TO_OTHER_BRANCH: "APPLY_APPROVER_SETTING_TO_OTHER_BRANCH",
    SAVE_WEIGH_BRIDGE_CONFIG: "SAVE_WEIGH_BRIDGE_CONFIG",
    DEPARTMENT_EMAIL_LIST_DATA: "DEPARTMENT_EMAIL_LIST_DATA",
    DEPARTMENT_EMAIL_LIST: "DEPARTMENT_EMAIL_LIST",
    GET_ALL_APPROVERS: "GET_ALL_APPROVERS",
    GET_WB_CONVERSION: "GET_WB_CONVERSION",
    ADD_WB_CONVERSION: "ADD_WB_CONVERSION",
    SAVE_WB_CONVERSION: "SAVE_WB_CONVERSION",
    UPDATE_WB_CONVERSION: "UPDATE_WB_CONVERSION",
    DELETE_WB_CONVERSION: "DELETE_WB_CONVERSION",
    GET_WAREHOUSE_LIST: "GET_WAREHOUSE_LIST",
    SAVE_WAREHOUSE_LIST: "SAVE_WAREHOUSE_LIST",
    GET_WEIGH_BRIDGE_DETAILS: "GET_WEIGH_BRIDGE_DETAILS",
    SAVE_WEIGH_BRIDGE_DETAILS: "SAVE_WEIGH_BRIDGE_DETAILS",
    UPLOAD_TERM_CONDITION: "UPLOAD_TERM_CONDITION",
    STORE_TERM_CONDITION: "STORE_TERM_CONDITION",
    DOWNLOAD_SUBPLANT: "DOWNLOAD_SUBPLANT",
    UPLOAD_SUBPLANT: "UPLOAD_SUBPLANT",
    DELETE_SUBPLANT : "DELETE_SUBPLANT",
    EDIT_SUBPLANT : "EDIT_SUBPLANT",
    SUBPLANT_TEMPLATE : "SUBPLANT_TEMPLATE",
    // DOWNLOAD_LOADINGBAY_DETAILS: "DOWNLOAD_LOADINGBAY_DETAILS",
    UPLOAD_LOADINGBAY_DETAILS: "UPLOAD_LOADINGBAY_DETAILS",
    DOWNLOAD_LOADINGBAY_TEMP: "DOWNLOAD_LOADINGBAY_TEMP",
    // UPLOAD_TERM_CONDITION: "UPLOAD_TERM_CONDITION",
    // STORE_TERM_CONDITION: "STORE_TERM_CONDITION",
    DOWNLOAD_PLANT_DEPOT: "DOWNLOAD_PLANT_DEPOT",
    PLANT_DEPOT_DETAILS: "PLANT_DEPOT_DETAILS",
    UPLOAD_LOADINGVENDOR_DETAILS: "UPLOAD_LOADINGVENDOR_DETAILS",
    DOWNLOAD_LOADINGVENDOR_TEMP: "DOWNLOAD_LOADINGVENDOR_TEMP",
    UPLOAD_PLANT_DETAILS: "UPLOAD_PLANT_DETAILS",
    DELETE_PLANTS_DEPOTS: "DELETE_PLANTS_DEPOTS",
    WAREHOUSE_STORAGE_DETAILS: "WAREHOUSE_STORAGE_DETAILS",
    EDIT_WAREHOUSE_DETAILS: "EDIT_WAREHOUSE_DETAILS",
    DOWNLOAD_WAREHOUSE_TEMP_DETAILS: "DOWNLOAD_WAREHOUSE_TEMP_DETAILS",
    UPLOAD_WAREHOUSE_DETAILS: "UPLOAD_WAREHOUSE_DETAILS",
    STORE_WAREHOUSE_DETAILS: "STORE_WAREHOUSE_DETAILS",
    GET_INDENTING_OPERATION_TIME: "GET_INDENTING_OPERATION_TIME",
    STORE_INDENTING_OPERATION_TIME: "STORE_INDENTING_OPERATION_TIME",
    ADD_INDENTING_OPERATION_TIME: "ADD_INDENTING_OPERATION_TIME",
    EDIT_INDENTING_OPERATION_TIME: "EDIT_INDENTING_OPERATION_TIME",
    DELETE_INDENTING_OPERATION_TIME: "DELETE_INDENTING_OPERATION_TIME",
    USER_BRANCH_TRANSFER: "USER_BRANCH_TRANSFER",
    DEACTIVATE_BRANCH_USER: "DEACTIVATE_BRANCH_USER",
    UPLOAD_USER_DETAILS: "UPLOAD_USER_DETAILS",
    DOWNLOAD_USER_DETAILS_TEMPLATE: "DOWNLOAD_USER_DETAILS_TEMPLATE",
    GET_DEPOT_AND_USER_DETAILS: 'GET_DEPOT_AND_USER_DETAILS',
    SAVE_DEPOT_AND_USER_DETAILS: 'SAVE_DEPOT_AND_USER_DETAILS',
    APPLY_INDENT_FLOW_SETTINGS_TO_BRANCHES: 'APPLY_INDENT_FLOW_SETTINGS_TO_BRANCHES',
    GET_AUTOMOVE: 'GET_AUTOMOVE',
    SAVE_AUTOMOVE: 'SAVE_AUTOMOVE',
    ADD_AUTOMOVE: 'ADD_AUTOMOVE',
    GET_LOB_LIST: 'GET_LOB_LIST',
    STORE_LOB_LIST: 'STORE_LOB_LIST',
    STORE_CITIES_LIST: 'STORE_CITIES_LIST',
    GET_CITIES_LIST: 'GET_CITIES_LIST',
    GET_MOVE_NAME:'GET_MOVE_NAME',
    STORE_MOVE_NAME:'STORE_MOVE_NAME',
    GET_WEIGH_BRIDGE_MASTER_LISTING: 'GET_WEIGH_BRIDGE_MASTER_LISTING',
    SAVE_WEIGH_BRIDGE_MASTER_LISTING: 'SAVE_WEIGH_BRIDGE_MASTER_LISTING',
    CREATE_WEIGH_BRIDGE_MASTER: 'CREATE_WEIGH_BRIDGE_MASTER',
    UPDATE_WEIGH_BRIDGE_MASTER: 'UPDATE_WEIGH_BRIDGE_MASTER',
    DELETE_WEIGH_BRIDGE_MASTER: 'DELETE_WEIGH_BRIDGE_MASTER',
    STORE_INDENTING_DASHBOARD_LIST: 'STORE_INDENTING_DASHBOARD_LIST',
    INDENTING_DASHBOARD_LIST: 'INDENTING_DASHBOARD_LIST',
    GET_PARKING_LOT_LISTING: 'GET_PARKING_LOT_LISTING',
    SAVE_PARKING_LOT_LISTING: 'SAVE_PARKING_LOT_LISTING',
    CREATE_PARKING_LOT: 'CREATE_PARKING_LOT',
    UPDATE_PARKING_LOT: 'UPDATE_PARKING_LOT',
    DELETE_PARKING_LOT: 'DELETE_PARKING_LOT',
    GET_ALL_YARDS: 'GET_ALL_YARDS',

    //volumetric master
    GET_VOLUMETRIC_MASTER:'GET_VOLUMETRIC_MASTER',
    SET_VOLUMETRIC_MASTER:'SET_VOLUMETRIC_MASTER',
    ADD_VOLUMETRIC_MASTER: 'ADD_VOLUMETRIC_MASTER',
    UPDATE_VOLUMETRIC_MASTER: 'UPDATE_VOLUMETRIC_MASTER',
    DELETE_VOLUMETRIC_MASTER: 'DELETE_VOLUMETRIC_MASTER',
    GET_REPORTS_DASHBOARDS_LIST: 'GET_REPORTS_DASHBOARDS_LIST',
    SAVE_REPORTS_DASHBOARDS_LIST: 'SAVE_REPORTS_DASHBOARDS_LIST',
    GET_PACK_UOM_DROPDOWN:'GET_PACK_UOM_DROPDOWN',
    SET_PACK_UOM_DROPDOWN:'SET_PACK_UOM_DROPDOWN',
    DOWNLOAD_VOLUMETRIC_MASTER:'DOWNLOAD_VOLUMETRIC_MASTER',
    UPLOAD_VOLUMETRIC_MASTER_DATA:'UPLOAD_VOLUMETRIC_MASTER_DATA',
    GET_DEPO_NAME: 'GET_DEPO_NAME',
    SET_DEPO_NAME: 'SET_DEPO_NAME', 

    // MFS Master
    GET_MFS_MASTER_DETAILS: 'GET_MFS_MASTER_DETAILS',
    SAVE_MFS_MASTER_DETAILS: 'SAVE_MFS_MASTER_DETAILS',
    UPDATE_MFS_MASTER_DETAILS: 'UPDATE_MFS_MASTER_DETAILS',
    CREATE_MFS_MASTER_DETAILS:'CREATE_MFS_MASTER_DETAILS',
    DELETE_MFS_MASTER_DETAILS :'DELETE_MFS_MASTER_DETAILS',
    GET_MFS_MASTER_UOM_DETAILS: 'GET_MFS_MASTER_UOM_DETAILS',
    SAVE_MFS_MASTER_UOM_DETAILS: 'SAVE_MFS_MASTER_UOM_DETAILS',
    DOWNLOAD_MFS:'DOWNLOAD_MFS',
    UPLOAD_MFS:'UPLOAD_MFS',

    GET_SERVICE_MODES:'GET_SERVICE_MODES',
    SET_SERVICE_MODES:'SET_SERVICE_MODES',
    GET_COMPANY_WISE_ZONES: 'GET_COMPANY_WISE_ZONES',
    STORE_COMPANY_WISE_ZONES: 'STORE_COMPANY_WISE_ZONES',
    GET_REGION_DROPDOWN : 'GET_REGION_DROPDOWN',
    SAVE_REGION_DROPDOWN : 'SAVE_REGION_DROPDOWN',
    GET_SLAB_RANGE : 'GET_SLAB_RANGE',
    SAVE_SLAB_RANGE_DETAILS : 'SAVE_SLAB_RANGE_DETAILS',
    ADD_SLAB_RANGE : 'ADD_SLAB_RANGE',
    DELETE_SLAB_RANGE : 'DELETE_SLAB_RANGE',
    GET_ADD_PLANT_CATEGORY : 'GET_ADD_PLANT_CATEGORY',
    SET_ADD_PLANT_CATEGORY : 'SET_ADD_PLANT_CATEGORY',
    GET_INDENTING_REASONS : 'GET_INDENTING_REASONS',
    ADD_INDENTING_REASONS : 'ADD_INDENTING_REASONS',
    EDIT_INDENTING_REASONS : 'EDIT_INDENTING_REASONS',
    DELETE_INDENTING_REASONS : 'DELETE_INDENTING_REASONS',
    STORE_REASONS : 'STORE_REASONS',

    //Inventory Master
    GET_INVENTORY_MASTER : 'GET_INVENTORY_MASTER',
    SET_INVENTORY_MASTER : 'SET_INVENTORY_MASTER',
    ADD_INVENTORY_MASTER : 'ADD_INVENTORY_MASTER',
    UPDATE_INVENTORY_MASTER : 'UPDATE_INVENTORY_MASTER',
    DELETE_INVENTORY_MASTER : 'DELETE_INVENTORY_MASTER',

    //Sales_district_mapping Master
    GET_DISTRICT_MAPPING_MASTER : 'GET_DISTRICT_MAPPING_MASTER',
    SET_DISTRICT_MAPPING_MASTER : 'SET_DISTRICT_MAPPING_MASTER',
    ADD_DISTRICT_MAPPING_MASTER : 'ADD_DISTRICT_MAPPING_MASTER',
    UPDATE_DISTRICT_MAPPING_MASTER : 'UPDATE_DISTRICT_MAPPING_MASTER',
    DELETE_DISTRICT_MAPPING_MASTER : 'DELETE_DISTRICT_MAPPING_MASTER',

    SET_INDENTING_MOVES : 'SET_INDENTING_MOVES',
    FETCH_INDENTING_MOVES : 'FETCH_INDENTING_MOVES',
    
    LOREAL_DOWNLOAD_USER_DETAILS_TEMPLATE: "LOREAL_DOWNLOAD_USER_DETAILS_TEMPLATE",
    LOREAL_UPLOAD_USER_DETAILS: "LOREAL_UPLOAD_USER_DETAILS",
  },
  CONTRACTS: {
    'GET_BASIC_CONTRACT_SETTING' : 'GET_BASIC_CONTRACT_SETTING',
    'STORE_BASIC_CONTRACT_SETTING' : 'STORE_BASIC_CONTRACT_SETTING',
    'GET_OTHER_CONTRACT_SETTING' : 'GET_OTHER_CONTRACT_SETTING',
    'STORE_OTHER_CONTRACT_SETTING' : 'STORE_OTHER_CONTRACT_SETTING',
    "ADD_CONTRACT" : "ADD_CONTRACT",
    "EDIT_CONTRACT" : "EDIT_CONTRACT",
    "ADD_COLLAPSIBLE_SECTION": "ADD_COLLAPSIBLE_SECTION",
    "GET_COLLAPSIBLE_SECTION" : "GET_COLLAPSIBLE_SECTION",
    "GET_COLLAPSIBLE_SECTION_DATA" : "GET_COLLAPSIBLE_SECTION_DATA",
    "STORE_COLLAPSIBLE_SECTION_1" : "STORE_COLLAPSIBLE_SECTION_1",
    "STORE_COLLAPSIBLE_SECTION_2" : "STORE_COLLAPSIBLE_SECTION_2",
    "STORE_COLLAPSIBLE_SECTION_3" : "STORE_COLLAPSIBLE_SECTION_3",
    "STORE_COLLAPSIBLE_SECTION_4" : "STORE_COLLAPSIBLE_SECTION_4",
    "STORE_COLLAPSIBLE_SECTION_5" : "STORE_COLLAPSIBLE_SECTION_5",
    "STORE_COLLAPSIBLE_SECTION_6" : "STORE_COLLAPSIBLE_SECTION_6",
    "DELETE_COLLAPSIBLE_SECTION" : "DELETE_COLLAPSIBLE_SECTION",
    "GET_BRANCH_LIST" : "GET_BRANCH_LIST",
    "STORE_BRANCH_LIST" : "STORE_BRANCH_LIST",
    "APPLY_SETTINGS_TO_BRANCHES" : "APPLY_SETTINGS_TO_BRANCHES",
    "GET_RFQS" : "GET_RFQS",
    "STORE_RFQ_LIST" : "STORE_RFQ_LIST",
    "GET_EMAIL_LIST":"GET_EMAIL_LIST",
    "GET_EMAIL_LIST_DATA": "GET_EMAIL_LIST_DATA",
    "GET_APPROVER_TYPES": "GET_APPROVER_TYPES",
    "SAVE_APPROVER_TYPES": "SAVE_APPROVER_TYPES",
    'GET_CHARGES_FOR_APPROVAL' : 'GET_CHARGES_FOR_APPROVAL',
    "GET_CURRENCY_DATA" : "GET_CURRENCY_DATA",
    "ADD_CURRENCY" : "ADD_CURRENCY",
    "GET_CURRENCY_DETAILS" : "GET_CURRENCY_DETAILS",
    'STORE_CURRENCY_DETAILS' : 'STORE_CURRENCY_DETAILS',
    "ADD_PRODUCT_CATEGORY" : "ADD_PRODUCT_CATEGORY",
    "ADD_PACKING_TYPE" : "ADD_PACKING_TYPE",
    "ADD_PRICING_BASIS" : "ADD_PRICING_BASIS",
    "ADD_VEHICLE_TYPE"  : "ADD_VEHICLE_TYPE",
    "ADD_VEHICLE_BODY_TYPE"  : "ADD_VEHICLE_BODY_TYPE",
    "ADD_UOM"  : "ADD_UOM",
    'STORE_MASTER_CONTRACT_DATA' : 'STORE_MASTER_CONTRACT_DATA',
    'GET_MASTER_CONTRACT_DATA' : 'GET_MASTER_CONTRACT_DATA',
    "GET_PLANT_WISE_CONTRACT_DATA" : 'GET_PLANT_WISE_CONTRACT_DATA',
    'STORE_PLANT_WISE_CONTRACT_DATA' : 'STORE_PLANT_WISE_CONTRACT_DATA',
    'GET_COMPANY_WISE_CONTRACT_DATA': 'GET_COMPANY_WISE_CONTRACT_DATA',
    'ADD_LOADABILITY': 'ADD_LOADABILITY',
    'GET_BASIC_MSP_CONTRACT_SETTING': 'GET_BASIC_MSP_CONTRACT_SETTING',
    "ADD_MSP_CONTRACT" : "ADD_MSP_CONTRACT",
    "EDIT_MSP_CONTRACT" : "EDIT_MSP_CONTRACT",
    "APPLY_MSP_SETTINGS_TO_BRANCHES": "APPLY_MSP_SETTINGS_TO_BRANCHES",
    'GET_AUTO_DECREMENT_DATA' : "GET_AUTO_DECREMENT_DATA",
    'STORE_AUTO_DECREMENT_DATA' : 'STORE_AUTO_DECREMENT_DATA',
    'ADD_AUTO_DECREMENT_DATA' : 'ADD_AUTO_DECREMENT_DATA'
  },
  LOADER: {
    SHOW_LOADER: "SHOW_LOADER",
    HIDE_LOADER: "HIDE_LOADER",
    START_LOADER_T1:'START_LOADER_T1',
    STOP_LOADER_T1:'STOP_LOADER_T1',
  },
  MSG_DISPLAY: {
    SHOW_MSG: "SHOW_MSG",
    HIDE_MSG: "HIDE_MSG",
    IS_FORM_EDITED: "IS_FORM_EDITED"
  },
  COMPANY_ACCOUNTS: {
    GET_CREDIT_MASTER: "GET_CREDIT_MASTER",
    POST_CREDIT_MASTER: "POST_CREDIT_MASTER",
    UPADTE_CREDIT_MASTER: "UPADTE_CREDIT_MASTER",
    STORE_CREDIT_MASTER: "STORE_CREDIT_MASTER",
    GET_DEBIT_MASTER: "GET_DEBIT_MASTER",
    POST_DEBIT_MASTER: "POST_DEBIT_MASTER",
    UPDATE_DEBIT_MASTER: "UPDATE_DEBIT_MASTER",
    DELETE_DEBIT_MASTER: "DELETE_DEBIT_MASTER",
    STORE_DEBIT_MASTER_TYPE1: "STORE_DEBIT_MASTER_TYPE1",
    STORE_DEBIT_MASTER_TYPE2: "STORE_DEBIT_MASTER_TYPE2",
    STORE_DEBIT_MASTER_TYPE3: "STORE_DEBIT_MASTER_TYPE3",
    STORE_DEBIT_MASTER_TYPE4: "STORE_DEBIT_MASTER_TYPE4",
    STORE_DEBIT_MASTER_TYPE5: "STORE_DEBIT_MASTER_TYPE5",
    STORE_DEBIT_MASTER_TYPE6: "STORE_DEBIT_MASTER_TYPE6",
    STORE_DEBIT_MASTER_TYPE7: "STORE_DEBIT_MASTER_TYPE7",
    STORE_DEBIT_MASTER_TYPE8: "STORE_DEBIT_MASTER_TYPE8",
    STORE_DEBIT_MASTER_TYPE9: "STORE_DEBIT_MASTER_TYPE9",
    STORE_DEBIT_MASTER_TYPE10: "STORE_DEBIT_MASTER_TYPE10",
    STORE_DEBIT_MASTER_TYPE11: "STORE_DEBIT_MASTER_TYPE11",
    STORE_DEBIT_MASTER_TYPE12: "STORE_DEBIT_MASTER_TYPE12",
    STORE_DEBIT_MASTER_TYPE13: "STORE_DEBIT_MASTER_TYPE13",
    GET_VEHICLE_DETAILS: "GET_VEHICLE_DETAILS",
    STORE_VEHICLE_DETAILS: "STORE_VEHICLE_DETAILS",
    GET_ACCOUNTS_DETAILS: "GET_ACCOUNTS_DETAILS",
    POST_ACCOUNTS_DETAILS: "POST_ACCOUNTS_DETAILS",
    UPDATE_ACCOUNTS_DETAILS: "UPDATE_ACCOUNTS_DETAILS",
    DELETE_ACCOUNTS_DETAILS: "DELETE_ACCOUNTS_DETAILS",
    DELETE_CREDIT_MASTER: "DELETE_CREDIT_MASTER",
    GET_BILL_NUMBER_DETAILS: "GET_BILL_NUMBER_DETAILS",
    POST_BILL_NUMBER_DETAILS: "POST_BILL_NUMBER_DETAILS",
    UPDATE_BILL_NUMBER_DETAILS: "UPDATE_BILL_NUMBER_DETAILS",
    STORE_BILL_NUMBER_DETAILS: "STORE_BILL_NUMBER_DETAILS",
    DELETE_BILL_NUMBER: "DELETE_BILL_NUMBER",
    GET_MATERIAL_DETAILS: "GET_MATERIAL_DETAILS",
    STORE_MATERIAL_DETAILS: "STORE_MATERIAL_DETAILS",
    GET_DETENTION_DETAILS: "GET_DETENTION_DETAILS",
    STORE_DETENTION_DETAILS: "STORE_DETENTION_DETAILS",
    UPDATE_DETENTION_DETAILS: "UPDATE_DETENTION_DETAILS",
    DELETE_DETENTION_DETAILS: "DELETE_DETENTION_DETAILS",
    ADD_DETENTION_DETAILS: "ADD_DETENTION_DETAILS",
    ADD_MULTI_PICKUP_DETAILS: "ADD_MULTI_PICKUP_DETAILS",
    STORE_MULTI_PICKUP_DETAILS: "STORE_MULTI_PICKUP_DETAILS",
    UPDATE_MULTI_PICKUP_DETAILS: "UPDATE_MULTI_PICKUP_DETAILS",
    GET_MULTI_PICKUP_DETAILS: "GET_MULTI_PICKUP_DETAILS",
    UPLOAD_HOLIDAY_LIST: "UPLOAD_HOLIDAY_LIST",
    GET_HOLIDAY_LIST: "GET_HOLIDAY_LIST",
    STORE_HOLIDAY_LIST: "STORE_HOLIDAY_LIST",
    DOWNLOAD_HOLIDAY_LIST: "DOWNLOAD_HOLIDAY_LIST",
    DOWNLOAD_HOLIDAY_LIST_URL: "DOWNLOAD_HOLIDAY_LIST_URL",
    STORE_FREIGHT_RATE: "STORE_FREIGHT_RATE",
    STORE_MINIMUM_FREIGHT_RATE:"STORE_MINIMUM_FREIGHT_RATE",
    GET_TRANSPORTER_LIST: "GET_TRANSPORTER_LIST",
    STORE_TRANSPORTER_LIST: "STORE_TRANSPORTER_LIST",
    GET_CREDIT_MASTER_PAYABLES: "GET_CREDIT_MASTER_PAYABLES",
    STORE_CREDIT_MASTER_PAYABLES_RHC: "STORE_CREDIT_MASTER_PAYABLES_RHC",
    STORE_CREDIT_MASTER_PAYABLES_FUEL_SURCHARGE:"STORE_CREDIT_MASTER_PAYABLES_FUEL_SURCHARGE",
    STORE_CREDIT_MASTER_PAYABLES_RAS: "STORE_CREDIT_MASTER_PAYABLES_RAS",
    STORE_CREDIT_MASTER_PAYABLES_FOV: "STORE_CREDIT_MASTER_PAYABLES_FOV",
    ADD_CREDIT_MASTER_PAYABLES: "ADD_CREDIT_MASTER_PAYABLES",
    UPDATE_CREDIT_MASTER_PAYABLES: "UPDATE_CREDIT_MASTER_PAYABLES",
    DELETE_CREDIT_MASTER_PAYABLES: "DELETE_CREDIT_MASTER_PAYABLES",
    GET_STATE_LIST: "GET_STATE_LIST",
    STORE_STATE_LIST: "STORE_STATE_LIST",
    STORE_CREDIT_MASTER_PAYABLES_ODA: "STORE_CREDIT_MASTER_PAYABLES_ODA",
    GET_ZIPCODE_LIST: "GET_ZIPCODE_LIST",
    STORE_ZIPCODE_LIST: "STORE_ZIPCODE_LIST",
    GET_SHORTAGE_PILFERAGE_TOLERANCE_LIST: "GET_SHORTAGE_PILFERAGE_TOLERANCE_LIST",
    STORE_SHORTAGE_PILFERAGE_TOLERANCE_LIST: "STORE_SHORTAGE_PILFERAGE_TOLERANCE_LIST",
    SAVE_SHORTAGE_PILFERAGE_TOLERANCE: "SAVE_SHORTAGE_PILFERAGE_TOLERANCE",
    UPDATE_SHORTAGE_PILFERAGE_TOLERANCE: "UPDATE_SHORTAGE_PILFERAGE_TOLERANCE",
    DELETE_SHORTAGE_PILFERAGE_TOLERANCE: "DELETE_SHORTAGE_PILFERAGE_TOLERANCE",
    STORE_LOADING_CHARGES: "STORE_LOADING_CHARGES"
  },
  MASTER: {
    SAP_MODULE: {
      GENERATE_ROUTE_EXCEL: "GENERATE_ROUTE_EXCEL",
      STORE_ROUTE_EXCEL: "STORE_ROUTE_EXCEL",
      EXPORT_ROUTE_EXCEL: "EXPORT_ROUTE_EXCEL"
    },
    LOCATION_NETWORK: {
      ADD_NETWORK_CHAIN: "ADD_NETWORK_CHAIN",
      UPDATE_NETWORK_CHAIN: "UPDATE_NETWORK_CHAIN",
      GET_NETWORK_CHAIN: "GET_NETWORK_CHAIN",
      GET_NETWORK_CHAIN_V1: "GET_NETWORK_CHAIN_V1",
      STORE_NETWORK_CHAIN: "STORE_NETWORK_CHAIN",
      DELETE_NETWORK_CHAIN: "DELETE_NETWORK_CHAIN",
      DOWNLOAD_NETWORK_CHAIN: "DOWNLOAD_NETWORK_CHAIN",
      UPLOAD_NETWORK_CHAIN: "UPLOAD_NETWORK_CHIAN",
      GET_ENTITY_TYPE: "GET_ENTITY_TYPE",
      STORE_ENTITY_TYPE: "STORE_ENTITY_TYPE",
      GET_CHAIN_TYPE: "GET_CHAIN_TYPE",
      STORE_CHAIN_TYPE: "STORE_CHAIN_TYPE",
      GET_STATE:"GET_STATE",
      STORE_STATE:"STORE_STATE",
      GET_DPL_BRANCH_LIST_DETAILS : "GET_DPL_BRANCH_LIST_DETAILS",
      STORE_DPL_BRANCH_LIST_DETAILS : "STORE_DPL_BRANCH_LIST_DETAILS",
      GET_COUNTRY:"GET_COUNTRY",
      STORE_COUNTRY:"STORE_COUNTRY",
      DOWNLOAD_NETWORK_CHAIN_TEMP: "DOWNLOAD_NETWORK_CHAIN_TEMP",
      GET_DEPOT_CONSIGNEE: "GET_DEPOT_CONSIGNEE",
      STORE_DEPOT_CONSIGNEE: "STORE_DEPOT_CONSIGNEE",
      UPDATE_DEPOT_CONSIGNEE: "UPDATE_DEPOT_CONSIGNEE",
      ADD_DEPOT_CONSIGNEE: "ADD_DEPOT_CONSIGNEE",
      DELETE_DEPOT_CONSIGNEE: "DELETE_DEPOT_CONSIGNEE",
      DOWNLOAD_DEPOT_CONSIGNEE_TEMP: "DOWNLOAD_DEPOT_CONSIGNEE_TEMP",
      DOWNLOAD_DEPOT_CONSIGNEE: "DOWNLOAD_DEPOT_CONSIGNEE",
      UPLOAD_DEPOT_CONSIGNEE: "UPLOAD_DEPOT_CONSIGNEE",
    },
    INVENTORY_ITEM: {
      GET_INVENTORY_ITEM: "GET_INVENTORY_ITEM",
      ADD_INVENTORY_ITEM: "ADD_INVENTORY_ITEM",
      STORE_INVENTORY_ITEM: "STORE_INVENTORY_ITEM",
      UPDATE_INVENTORY_ITEM: "UPDATE_INVENTORY_ITEM",
      DELETE_INVENTORY_ITEM: "DELETE_INVENTORY_ITEM",
      UPLOAD_INVENTORY_ITEM: "UPLOAD_INVENTORY_ITEM",
      DOWNLOAD_INVENTORY_ITEM: "DOWNLOAD_INVENTORY_ITEM",
      GET_COMMON_CONSTANTS: "GET_COMMON_CONSTANTS",
      STORE_COMMON_CONSTANTS: "STORE_COMMON_CONSTANTS",
      STORE_INVENTORY_DOWNLOAD_LINK: "STORE_INVENTORY_DOWNLOAD_LINK",
      GET_PACKAGING_DETAILS: "GET_PACKAGING_DETAILS",
      STORE_PACKAGING_DETAILS: "STORE_PACKAGING_DETAILS",
      UPLOAD_RATE_CHART: "UPLOAD_RATE_CHART",
      DOWNLOAD_RATE_CHART: "DOWNLOAD_RATE_CHART",
      GET_PRODUCT_RATE_CHART_DETAILS: "GET_PRODUCT_RATE_CHART_DETAILS",
      ADD_PRODUCT_RATE_CHART_DETAILS: "ADD_PRODUCT_RATE_CHART_DETAILS",
      UPDATE_PRODUCT_RATE_CHART_DETAILS: "UPDATE_PRODUCT_RATE_CHART_DETAILS",
      DELETE_PRODUCT_RATE_CHART_DETAILS: "DELETE_PRODUCT_RATE_CHART_DETAILS"
    },
    ACCOUNTS: {
      GET_ACCOUNT_DETAILS: "GET_ACCOUNT_DETAILS",
      ADD_ACCOUNT_DETAILS: "ADD_ACCOUNT_DETAILS",
      DELETE_ACCOUNT_DETAILS: "DELETE_ACCOUNT_DETAILS",
      STORE_ACCOUNT_DETAILS_1: "STORE_ACCOUNT_DETAILS_1",
      STORE_ACCOUNT_DETAILS_2: "STORE_ACCOUNT_DETAILS_2",
      STORE_ACCOUNT_DETAILS_3: "STORE_ACCOUNT_DETAILS_3",
      STORE_ACCOUNT_DETAILS_4: "STORE_ACCOUNT_DETAILS_4",
      STORE_ACCOUNT_DETAILS_5: "STORE_ACCOUNT_DETAILS_5",
      STORE_ACCOUNT_DETAILS_6: "STORE_ACCOUNT_DETAILS_6",
      STORE_ACCOUNT_DETAILS_7: "STORE_ACCOUNT_DETAILS_7"
    },
    TRANSPORTERS: {
      ADD_COMPANY_TRANSPORTERS: "ADD_COMPANY_TRANSPORTERS",
      GET_COMPANY_TRANSPORTERS: "GET_COMPANY_TRANSPORTERS",
      DELETE_COMPANY_TRANSPORTERS: "DELETE_COMPANY_TRANSPORTERS",
      STORE_COMPANY_TRANSPORTERS: "STORE_COMPANY_TRANSPORTERS",
      STORE_BRANCH_TRANSPORTERS: "STORE_BRANCH_TRANSPORTERS",
    },

    VEHICLE_PLANNING:{
      
      // Truck Master
      GET_TRUCK_DETAILS: "GET_TRUCK_DETAILS",
      STORE_TRUCK_DETAILS: "STORE_TRUCK_DETAILS",
      ADD_TRUCK_DETAILS: "ADD_TRUCK_DETAILS",
      UPDATE_TRUCK_DETAILS: "UPDATE_TRUCK_DETAILS",
      DELETE_TRUCK_DETAILS:"DELETE_TRUCK_DETAILS",
      UPLOAD_TRUCK_DETAILS:"UPLOAD_TRUCK_DETAILS",
      DOWNLOAD_TRUCK_DETAILS:"DOWNLOAD_TRUCK_DETAILS",
      STORE_TRUCK_DOWNLOAD_LINK: "STORE_TRUCK_DOWNLOAD_LINK",
      DOWNLOAD_TRUCK_TEMP:"DOWNLOAD_TRUCK_TEMP",
      STORE_TRUCK_DOWNLOAD_TEMP_LINK: "STORE_TRUCK_DOWNLOAD_TEMP_LINK",
      GET_VEHICLE_SIZE_DETAILS: "GET_VEHICLE_SIZE_DETAILS",
      STORE_VEHICLE_SIZE_DETAILS: "STORE_VEHICLE_SIZE_DETAILS",
      GET_TRANSPORTER_DETAILS: "GET_TRANSPORTER_DETAILS",
      STORE_TRANSPORTER_DETAILS: "STORE_TRANSPORTER_DETAILS",
      GET_TRUCK_DRIVER_DETAILS:"GET_TRUCK_DRIVER_DETAILS",
      STORE_TRUCK_DRIVER_DETAILS:"STORE_TRUCK_DRIVER_DETAILS",
      GET_GPS_VENDOR_DATA: "GET_GPS_VENDOR_DATA",
      GET_GPS_VENDOR_DATA_SUCCESS: 'GET_GPS_VENDOR_DATA_SUCCESS',
      // SKU Master
      GET_SKU_DETAILS: "GET_SKU_DETAILS",
      STORE_SKU_DETAILS: "STORE_SKU_DETAILS",
      UPDATE_SKU_DETAILS: "UPDATE_SKU_DETAILS",
      ADD_SKU_DETAILS: "ADD_SKU_DETAILS",
      DOWNLOAD_SKU_DETAILS:"DOWNLOAD_SKU_DETAILS",
      STORE_SKU_DOWNLOAD_LINK: "STORE_SKU_DOWNLOAD_LINK",
      DELETE_SKU_DETAILS:"DELETE_SKU_DETAILS",
      UPLOAD_SKU_DETAILS:"UPLOAD_SKU_DETAILS",
      DOWNLOAD_SKU_TEMP:"DOWNLOAD_SKU_TEMP",
      STORE_SKU_DOWNLOAD_TEMP_LINK: "STORE_SKU_DOWNLOAD_TEMP_LINK",
    
      // DRIVER MASTER

    GET_DRIVER_DETAILS: "GET_DRIVER_DETAILS",
    STORE_DRIVER_DETAILS: "STORE_DRIVER_DETAILS",
    ADD_DRIVER_DETAILS: "ADD_DRIVER_DETAILS",
    UPDATE_DRIVER_DETAILS: "UPDATE_DRIVER_DETAILS",
    DELETE_DRIVER_DETAILS:"DELETE_DRIVER_DETAILS",
    DOWNLOAD_DRIVER_DETAILS:"DOWNLOAD_DRIVER_DETAILS",
    STORE_DRIVER_DOWNLOAD_LINK: "STORE_DRIVER_DOWNLOAD_LINK",
    UPLOAD_DRIVER_DETAILS:"UPLOAD_DRIVER_DETAILS",
    DOWNLOAD_DRIVER_TEMP:"DOWNLOAD_DRIVER_TEMP",
    STORE_DRIVER_DOWNLOAD_TEMP_LINK: "STORE_DRIVER_DOWNLOAD_TEMP_LINK",
    },

    MASTER_DROP_DOWN : {
      GET_DROP_DOWN_NAME : 'GET_DROP_DOWN_NAME',
      STORE_DROP_DOWN_NAME : 'STORE_DROP_DOWN_NAME',
      GET_INSIDE_TAB_DROPDOWN : 'GET_INSIDE_TAB_DROPDOWN',
      STORE_INSIDE_TAB_DROPDOWN: 'STORE_INSIDE_TAB_DROPDOWN',
      EDIT_MASTER_DROP_DOWN_DATA : 'EDIT_MASTER_DROP_DOWN_DATA',
      ADD_MASTER_DROP_DOWN_DATA : 'ADD_MASTER_DROP_DOWN_DATA',
      DELETE_MASTER_DROP_DOWN_DATA : 'DELETE_MASTER_DROP_DOWN_DATA'
    },



  },
  BOUND_TYPE_OPTION: [
    { value: "inbound", label: "Inbound", id: 1 },
    { value: "outbound", label: "Outbound", id: 2 }
  ],
  SERVICE_CATEGORY_OPTION: [
    { value: "FTL", label: "FTL", id: 1 },
    { value: "LCL", label: "LCL", id: 2 },
    { value: "Courier", label: "Courier", id: 3 },
    { value: "Container", label: "Container", id: 4 }
  ],
  MODE_OPTION: [
    { value: "road", label: "Road", id: 1 },
    { value: "rail", label: "Rail", id: 2 },
    { value: "coastal", label: "Coastal", id: 3 }
  ],
  PACKING_TYPE: [
    { value: "cp", label: "CP", id: 1 },
    { value: "lp", label: "LP", id: 2 },
    { value: "bp", label: "BP", id: 3 },
    { value: "mix", label: "Mix", id: 4 }
  ],
  UOM_OPTION: [
    { value: "numbers", label: "Numbers", id: 1 },
    { value: "weight", label: "Weight", id: 2 }
  ],
  INDENT_TYPE_OPTION: [
    { value: "inhousecargo", label: "In-house Cargo", id: 1 },
    {
      value: "externalcargocontrolled",
      label: "External cargo controlled",
      id: 2
    },
    {
    value: "externalcargononcontrolled",
    label: "External cargo non-controlled",
    id: 3
    },
    { value: "inhouseexternal", label: "In-house & External", id: 4 }
    ],
  BROADCAST: {
    GET_ALL_EMAILS: "GET_ALL_EMAILS",
    GET_ALL_EMAILS_DATA: "GET_ALL_EMAILS_DATA",
    POST_ALL_EMAILS: "POST_ALL_EMAILS",
    POST_ALL_EMAILS_DATA: "POST_ALL_EMAILS_DATA",
    COPY_ALL_EMAILS: "COPY_ALL_EMAILS",
    COPY_ALL_EMAILS_DATA: "COPY_ALL_EMAILS_DATA"
  },
  DATA_GOVERNANCE: {
    // DRIVER INFO
    GET_DRIVER_INFO:"GET_DRIVER_INFO",
    STORE_DRIVER_INFO:"STORE_DRIVER_INFO",
    DELETE_DRIVER_INFO:"DELETE_DRIVER_INFO",
    ADD_DRIVER_INFO:"ADD_DRIVER_INFO",
    UPDATE_DRIVER_INFO:"UPDATE_DRIVER_INFO",
    DOWNLOAD_DRIVER_TEMPLATE : 'DOWNLOAD_DRIVER_TEMPLATE',
    UPLOAD_DRIVER_TEMPLATE : 'UPLOAD_DRIVER_TEMPLATE',
    // TRUCK INFO
    GET_TRUCK_INFO:"GET_TRUCK_INFO",
    STORE_TRUCK_INFO:"STORE_TRUCK_INFO",
    DELETE_TRUCK_INFO:"DELETE_TRUCK_INFO",
    ADD_TRUCK_INFO:"ADD_TRUCK_INFO",
    UPDATE_TRUCK_INFO:"UPDATE_TRUCK_INFO",
    GET_TRUCK_TYPE:"GET_TRUCK_TYPE",
    STORE_VEHICLE_TYPE:"STORE_VEHICLE_TYPE",
    GET_FULE_TYPE:"GET_FULE_TYPE",
    STORE_FULE_TYPE:"STORE_FULE_TYPE",
    UPLOAD_VEHICLE_FIELS:"UPLOAD_VEHICLE_FIELS",
    DOWNLOAD_VEHICLE_TEMPLATE: 'DOWNLOAD_VEHICLE_TEMPLATE',
    UPLOAD_VEHICLE_TEMPLATE: 'UPLOAD_VEHICLE_TEMPLATE',

    GET_DELAY_PENALTY: "GET_DELAY_PENALTY",
    GET_DELAY_PENALTY_DATA: "GET_DELAY_PENALTY_DATA",
    
    POST_DELAY_PENALTY: "POST_DELAY_PENALTY",
    UPDATE_DELAY_PENALTY: "UPDATE_DELAY_PENALTY",
    DELETE_DELAY_PENALTY: "DELETE_DELAY_PENALTY",
    
    UPLOAD_DELAY_PENALTY: "UPLOAD_DELAY_PENALTY",
    DOWNLOAD_DELAY_PENALTY: "DOWNLOAD_DELAY_PENALTY",

    GET_MODE_TYPES: "GET_MODE_TYPES",
    GOVERNANCE_MODE_TYPES_DATA: "GOVERNANCE_MODE_TYPES_DATA",

    GET_CHANNEL_TYPES: "GET_CHANNEL_TYPES",
    GOVERNANCE_CHANNEL_TYPES_DATA: "GOVERNANCE_CHANNEL_TYPES_DATA",

    GET_DISTRIBUTION_TYPES: "GET_DISTRIBUTION_TYPES",
    GOVERNANCE_DISTRIBUTION_TYPES_DATA: "GOVERNANCE_DISTRIBUTION_TYPES_DATA",

    GET_VENDORS_TYPES: "GET_VENDORS_TYPES",
    GET_GOVERNANCE_VENDORS_DATA: "GET_GOVERNANCE_VENDORS_DATA",
    

    GET_POD_VENDORS_TYPES: "GET_POD_VENDORS_TYPES",
    GET_GOVERNANCE_POD_VENDORS_DATA: "GET_GOVERNANCE_POD_VENDORS_DATA",
    GET_POD_CYCLES: "GET_POD_CYCLES",
    GET_POD_CYCLES_DATA: "GET_POD_CYCLES_DATA",
    GET_POD_STATUS: "GET_POD_STATUS",
    GET_POD_STATUS_DATA: "GET_POD_STATUS_DATA",

    GET_POD_CLAUSE_DATA: "GET_POD_CLAUSE_DATA",
    GET_POD_CLAUSE_MASTER_DATA: "GET_POD_CLAUSE_MASTER_DATA",
   
    POST_POD_DATA: "POST_POD_DATA",
    UPDATE_POD_DATA: "UPDATE_POD_DATA",
    DELETE_POD_DATA: "DELETE_POD_DATA",

    GET_LIABILITY_DATA: "GET_LIABILITY_DATA",
    LIABILITY_DETAILS: "LIABILITY_DETAILS",
    POST_LIABILITY_DATA: "POST_LIABILITY_DATA",
    UPDATE_LIABILITY_DATA: "UPDATE_LIABILITY_DATA",
    DELETE_LIABILITY: "DELETE_LIABILITY",


    NETWORK_MASTER_DETAILS:"NETWORK_MASTER_DETAILS",
    UPDATE_NETWORK_MASTER_DATA:"UPDATE_NETWORK_MASTER_DATA",
    UPLOAD_NETWORK_MASTER:"UPLOAD_NETWORK_MASTER",
    DOWNLOAD_NM:"DOWNLOAD_NM",

    DOWNLOAD_LIABILITY: "DOWNLOAD_LIABILITY",
    DOWNLOAD_NM_TEMPLATE:"DOWNLOAD_NM_TEMPLATE",

    GET_CLAIM_TYPES: "GET_CLAIM_TYPES",
    CLAIM_TYPES_DATA: "CLAIM_TYPES_DATA",
    
    GET_SHORTAGES_TYPES: "GET_SHORTAGES_TYPES",
    SHORTAGES_TYPES_DATA: "SHORTAGES_TYPES_DATA",

    POST_VOLUMETRIC_WEIGHT :"POST_VOLUMETRIC_WEIGHT",
    GET_VOLUMETRIC_WEIGHT : "GET_VOLUMETRIC_WEIGHT",
    GET_VOLUMETRIC_WEIGHT_DATA :"GET_VOLUMETRIC_WEIGHT_DATA",
    DELETE_VOLUMETRIC_WEIGHT :"DELETE_VOLUMETRIC_WEIGHT",
    
    GET_LANE_MASTERS: "GET_LANE_MASTERS",
    GET_LANE_MASTER_DATA: "GET_LANE_MASTER_DATA",

    GET_CITY_LIST: "GET_CITY_LIST",
    GET_CITY_LIST_DATA: "GET_CITY_LIST_DATA",

    GET_HUB_LIST: "GET_HUB_LIST",
    GET_HUB_LIST_DATA: "GET_HUB_LIST_DATA",

    GET_CITY_PINCODES: "GET_CITY_PINCODES",
    GET_CITY_PINCODES_DATA: "GET_CITY_PINCODES_DATA",

    UPDATE_VOLUMETRIC_WEIGHT :"UPDATE_VOLUMETRIC_WEIGHT",
    DOWNLOAD_VOLUMETRIC_LINK :"DOWNLOAD_VOLUMETRIC_LINK",
    DOWNLOAD_VOLUMETRIC_DATA : "DOWNLOAD_VOLUMETRIC_DATA",
    UPLOAD_VOLUMETRIC_FILE :"UPLOAD_VOLUMETRIC_FILE",

    POST_VOLUMETRIC_WEIGHT_TYPE: "POST_VOLUMETRIC_WEIGHT_TYPE",
    DELETE_VOLUMETRIC_WEIGHT_TYPE: "DELETE_VOLUMETRIC_WEIGHT_TYPE",
    GET_VOLUMETRIC_WEIGHT_TYPE: "GET_VOLUMETRIC_WEIGHT_TYPE",
    GET_VOLUMETRIC_WEIGHT_TYPE_DATA: "GET_VOLUMETRIC_WEIGHT_TYPE_DATA",

    POST_LANE: "POST_LANE",
    UPDATE_LANE: "UPDATE_LANE",
    DELETE_LANE: "DELETE_LANE",

    SHOW_DATA_LOADER: "SHOW_DATA_LOADER",
    HIDE_DATA_LOADER: "HIDE_DATA_LOADER",

    GET_ODA_MASTERS: "GET_ODA_MASTERS",
    GET_ODA_MASTER_DATA: "GET_ODA_MASTER_DATA",
    POST_ODA_MASTERS: "POST_ODA_MASTERS",
    UPDATE_ODA_MASTERS: "UPDATE_ODA_MASTERS",
    DELETE_ODA_MASTERS: "DELETE_ODA_MASTERS",
    DOWNLOAD_ODA_MASTER: "DOWNLOAD_ODA_MASTER",
    UPLOAD_ODA_MASTER: "UPLOAD_ODA_MASTER",

    GET_GLCODE_MASTERS: "GET_GLCODE_MASTERS",
    GET_GLCODE_MASTER_DATA: "GET_GLCODE_MASTER_DATA",
    POST_GLCODE_MASTERS: "POST_GLCODE_MASTERS",
    UPDATE_GLCODE_MASTERS: "UPDATE_GLCODE_MASTERS",
    DELETE_GLCODE_MASTERS: "DELETE_GLCODE_MASTERS",
    DOWNLOAD_GLCODE_MASTER: "DOWNLOAD_GLCODE_MASTER",
    UPLOAD_GLCODE_MASTER: "UPLOAD_GLCODE_MASTER",


    GET_ALL_TYPES_OF_ODA: "GET_ALL_TYPES_OF_ODA",
    ALL_TYPES_OF_ODA_DATA: "ALL_TYPES_OF_ODA_DATA",

    UPLOAD_SLAB: "UPLOAD_SLAB",
    UPLOAD_ODA: "UPLOAD_ODA",
    DOWNLOAD_SLAB: "DOWNLOAD_SLAB",
    DOWNLOAD_ODA: "DOWNLOAD_ODA",
    DOWNLOAD_ODA_DATA: "DOWNLOAD_ODA_DATA",

    UPLOAD_LANE: "UPLOAD_LANE",
    DOWNLOAD_LANE: "DOWNLOAD_LANE",

    POST_CITY_MASTER: "POST_CITY_MASTER",
    UPDATE_CITY_MASTER: "UPDATE_CITY_MASTER",
    DELETE_CITY_MASTER: "DELETE_CITY_MASTER",
    GET_CITY_MASTER: "GET_CITY_MASTER",
    UPLOAD_CITY_MASTER: "UPLOAD_CITY_MASTER",
    DOWNLOAD_CITY_MASTER: "DOWNLOAD_CITY_MASTER",
    GET_HUBS: "GET_HUBS",

    GET_CITY_MASTER_DATA: "GET_CITY_MASTER_DATA",
    GET_HUBS_DATA: "GET_HUBS_DATA",

    GET_RM_VENDORS_TYPES: "GET_RM_VENDORS_TYPES",
    GET_RM_VENDORS_TYPES_DATA: "GET_RM_VENDORS_TYPES_DATA",
    GET_RM_MODES_TYPES: "GET_RM_MODES_TYPES",
    GET_RM_MODES_TYPES_DATA: "GET_RM_MODES_TYPES_DATA",
    GET_RM_REGIONS: "GET_RM_REGIONS",
    GET_RM_REGIONS_DATA: "GET_RM_REGIONS_DATA",
    GET_RM_DATA: "GET_RM_DATA",
    GET_RM_MASTER_DATA: "GET_RM_MASTER_DATA",
    POST_RM_DATA: "POST_RM_DATA",
    UPDATE_RM_DATA: "UPDATE_RM_DATA",
    DELETE_RM_DATA: "DELETE_RM_DATA",
    DOWNLOAD_RM_TEMPLATE: 'DOWNLOAD_RM_TEMPLATE',
    UPLOAD_RM_TEMPLATE: 'UPLOAD_RM_TEMPLATE',
    GET_RM_LANES: "GET_RM_LANES",
    GET_RAPID_RATES: "GET_RAPID_RATES",
    RAPID_RATES_DATA: "RAPID_RATES_DATA",
    POST_MONTHLY_RATES: "POST_MONTHLY_RATES",
    DEMURRAGE_DATA: "DEMURRAGE_DATA",
    GET_DEMURRAGE: "GET_DEMURRAGE",
    ADD_DEMURRAGE: "ADD_DEMURRAGE",
    UPDATE_DEMURRAGE: "UPDATE_DEMURRAGE",
    OTHER_CHARGES_DATA: "OTHER_CHARGES_DATA",
    OTHER_CHARGES_DROPDOWN_DATA: "OTHER_CHARGES_DROPDOWN_DATA",
    GET_MDG_OTHERCHARGES: "GET_MDG_OTHERCHARGES",
    ADD_NEW_OTHERCHARGES: "ADD_NEW_OTHERCHARGES",
    UPDATE_OTHERCHARGES: "UPDATE_OTHERCHARGES",
    DELETE_OTHERCHARGES: "DELETE_OTHERCHARGES",
    GET_OTHERCHARGES_DROPDOWNLIST: "GET_OTHERCHARGES_DROPDOWNLIST",

    HUBS_MASTER_DATA: "HUBS_MASTER_DATA",
    DOWNLOAD_HUBS_MASTER_DATA: "DOWNLOAD_HUBS_MASTER_DATA",
    GET_HUBS_MASTER_DROPDOWN: "GET_HUBS_MASTER_DROPDOWN",
    DELETE_HUBS_MASTER_DATA: "DELETE_HUBS_MASTER_DATA",
    UPDATE_HUBS_MASTER: "UPDATE_HUBS_MASTER",
    ADD_NEW_HUB_MASTER: "ADD_NEW_HUB_MASTER",
    DOWNLOAD_HUBS_MASTER: "DOWNLOAD_HUBS_MASTER",
    UPLOAD_HUBS_MASTER_DATA: "UPLOAD_HUBS_MASTER_DATA",
    HUBS_MASTER_DROPDOWN_DATA: 'HUBS_MASTER_DROPDOWN_DATA',
    DOWNLOAD_VENDOR_LANES: 'DOWNLOAD_VENDOR_LANES',

    ORIGIN_NETWORK_MASTER_DROPDOWN_DATA:"ORIGIN_NETWORK_MASTER_DROPDOWN_DATA",
    SAVE_ORIGIN_NETWORK_MASTER_DROPDOWN_DATA: "SAVE_ORIGIN_NETWORK_MASTER_DROPDOWN_DATA",
    MODE_NETWORK_MASTER_DROPDOWN_DATA:"MODE_NETWORK_MASTER_DROPDOWN_DATA",
    SAVE_MODE_NETWORK_MASTER_DROPDOWN_DATA:"SAVE_MODE_NETWORK_MASTER_DROPDOWN_DATA",
    ROUTE_NAME_NETWORK_MASTER_DROPDOWN_DATA:"ROUTE_NAME_NETWORK_MASTER_DROPDOWN_DATA",
    SAVE_ROUTE_NAME_NETWORK_MASTER_DROPDOWN_DATA:"SAVE_ROUTE_NAME_NETWORK_MASTER_DROPDOWN_DATA",
    SOURCE_NETWORK_MASTER_DROPDOWN_DATA:"SOURCE_NETWORK_MASTER_DROPDOWN_DATA",
    SAVE_SOURCE_NETWORK_MASTER_DROPDOWN_DATA:"SAVE_SOURCE_NETWORK_MASTER_DROPDOWN_DATA",

    GET_DPH_DISCOUNT_STRUCTURE: 'GET_DPH_DISCOUNT_STRUCTURE',
    DELETE_DPH_DISCOUNT_STRUCTURE: 'DELETE_DPH_DISCOUNT_STRUCTURE',
    UPDATE_DPH_DISCOUNT_STRUCTURE: 'UPDATE_DPH_DISCOUNT_STRUCTURE',
    ADD_DPH_DISCOUNT_STRUCTURE: 'ADD_DPH_DISCOUNT_STRUCTURE',
    DPH_DISCOUNT_STRUCTURE_DATA: 'DPH_DISCOUNT_STRUCTURE_DATA',

    HSD_CONTRACT_DETAILS: 'HSD_CONTRACT_DETAILS',
    HSD_CONTRACT_LANE_DETAILS: 'HSD_CONTRACT_LANE_DETAILS',
    HSD_CONTRACT_DATA: 'HSD_CONTRACT_DATA',
    HSD_CONTRACT_LANE_DATA: 'HSD_CONTRACT_LANE_DATA',
    HSD_EDIT_CONTRACT_DETAILS: 'HSD_EDIT_CONTRACT_DETAILS',
    HSD_LANE_APPROVAL: 'HSD_LANE_APPROVAL',
    HSD_ZONE_DETAILS: 'HSD_ZONE_DETAILS',
    HSD_CONSOLIDATED_DETAILS: 'HSD_CONSOLIDATED_DETAILS',
    HSD_CONSOLIDATED_RFQ_DETAILS: 'HSD_CONSOLIDATED_RFQ_DETAILS',
    HSD_CONSOLIDATED_RFQ_DATA: 'HSD_CONSOLIDATED_RFQ_DATA',
    POST_HSD_DETAILS:'POST_HSD_DETAILS',
    DOWNLOAD_HSD_DETAILS: "DOWNLOAD_HSD_DETAILS",
    UPLOAD_HSD_DETAILS: "UPLOAD_HSD_DETAILS",

    TAT_FORM_VENDOR_DATA: "TAT_FORM_VENDOR_DATA",
    GET_TAT_FORM_VENDOR_DETAILS: "GET_TAT_FORM_VENDOR_DETAILS",
    UPDATE_TAT_FORM: "UPDATE_TAT_FORM",

    GET_DAILY_DIESEL_PRICE_HIKE: "GET_DAILY_DIESEL_PRICE_HIKE",
    STORE_DAILY_DPH: "STORE_DAILY_DPH",
    POST_NETWORK_MASTER_DATA:"POST_NETWORK_MASTER_DATA",
    GET_NETWORK_MASTER_DATA:'GET_NETWORK_MASTER_DATA',

    // GET__MFS_MATERIAL_CODE: "GET__MFS_MATERIAL_CODE",
    // GET__MFS_QUANTITY: "GET__MFS_QUANTITY",
    // GET__MFS_PERCENTAGE:"GET__MFS_PERCENTAGE",
    // GET__MFS_UOM:'GET__MFS_UOM',
    // GET_MFS_MASTER_DATA:'GET_MFS_MASTER_DATA'
    DPH_AMENDMENT_MONTHLY_RATES:'DPH_AMENDMENT_MONTHLY_RATES',
    GET_DPH_AMENDMENT_MONTHLY_RATES:'GET_DPH_AMENDMENT_MONTHLY_RATES',
    
  },

  VENDORS: {
    GET_ALL_VENDOR_LIST: "GET_ALL_VENDOR_LIST",
    GET_VENDOR_MODE_LIST: "GET_VENDOR_MODE_LIST",
    GET_VENDORS_DATA: "GET_VENDORS_DATA",
    FETCH_VENDOR_ONBOARD_DATA: "FETCH_VENDOR_ONBOARD_DATA",
    GET_VENDOR_DETAILS: "GET_VENDOR_DETAILS",
    GET_VENDOR_DETAILS_DATA: "GET_VENDOR_DETAILS_DATA",
    FETCH_VENDOR_ONBOARD: "FETCH_VENDOR_ONBOARD",
    ALL_VENDOR_MODES: "ALL_VENDOR_MODES",
    GET_ALL_VENDOR_MODES: "GET_ALL_VENDOR_MODES",
    POST_PRIMARY_VENDOR: "POST_PRIMARY_VENDOR",
    POST_PRIMARY_VENDOR_FIRST_TIME : 'POST_PRIMARY_VENDOR_FIRST_TIME',
    GET_ALL_SCOPE: "GET_ALL_SCOPE",
    SCOPES_LIST: "SCOPES_LIST",
    GET_FORM_VENDOR_MODES: "GET_FORM_VENDOR_MODES",
    GET_CHARGES: "GET_CHARGES",
    CHARGES_DATA: "CHARGES_DATA",
    POST_PRIMARY_VENDOR_FORM: "POST_PRIMARY_VENDOR_FORM",
    POST_SECONDARY_VENDOR_FORM: "POST_SECONDARY_VENDOR_FORM",
    UPDATE_SECONDARY_VENDOR_FORM: "UPDATE_SECONDARY_VENDOR_FORM",
    UPDATE_VENDOR_APPROVAL: "UPDATE_VENDOR_APPROVAL",
    ALL_CHARGES: "ALL_CHARGES",
    ACTIVATE_VENDOR: "ACTIVATE_VENDOR",
    UPLOAD_VENDOR_OP_AREAS: "UPLOAD_VENDOR_OP_AREAS",
    DOWNLOAD_OPERATIONAL_AREAS: "DOWNLOAD_OPERATIONAL_AREAS",
    UPLOAD_VENDOR_LOGO: "UPLOAD_VENDOR_LOGO",
    ONBOARD_AREAS_DETAILS: "ONBOARD_AREAS_DETAILS",
    GET_VENDOR_ONBOARD_AREAS: "GET_VENDOR_ONBOARD_AREAS",
    UPLOAD_VENDOR_DOCS: "UPLOAD_VENDOR_DOCS",
    UPLOAD_VENDOR_DECLARATION_FILES: "UPLOAD_VENDOR_DECLARATION_FILES",
    UPLOAD_EXTERNAL_APPROVER_DOC_IN_VENDOR: "UPLOAD_EXTERNAL_APPROVER_DOC_IN_VENDOR",
    UPLOAD_APPROVER_DOC_IN_VENDOR: "UPLOAD_APPROVER_DOC_IN_VENDOR",
    SEND_CHALLENGE_PRICE: "SEND_CHALLENGE_PRICE",
    FORM_ZONES: "FORM_ZONES",
    SET_VENDOR_SOURCE_LIST: "SET_VENDOR_SOURCE_LIST",
    GET_VENDOR_SOURCE_DROPDOWN: "GET_VENDOR_SOURCE_DROPDOWN",
    FORM_ZONES_DATA: "FORM_ZONES_DATA",
    PUT_PRIMARY_VENDOR_FORM: "PUT_PRIMARY_VENDOR_FORM",
    SUBMIT_PRIMARY_VENDOR_FORM: "SUBMIT_PRIMARY_VENDOR_FORM",
	  DOWNLOAD_CONTRACT_FORM: "DOWNLOAD_CONTRACT_FORM",
    GET_ALL_VENDOR_APPROVERS: "GET_ALL_VENDOR_APPROVERS",
    APPROVAL_LEVELS_DATA: "APPROVAL_LEVELS_DATA",
    GET_VENDORA_PPROVAL_COUNTS: "GET_VENDORA_PPROVAL_COUNTS",
    VENDOR_APPROVAL_COUNT: "VENDOR_APPROVAL_COUNT",
    GET_OPERATIONAL_AREA: "GET_OPERATIONAL_AREA",
    STORE_OPERATIONAL_AREAS: "STORE_OPERATIONAL_AREAS",
    GET_SERVICE_PROVIDED: "GET_SERVICE_PROVIDED",
    STORE_SERVICE_PROVIDED: "STORE_SERVICE_PROVIDED",
    GET_ALL_SERVICE_OFFERED: "GET_ALL_SERVICE_OFFERED",
    STORE_ALL_SERVICE_OFFERED: "STORE_ALL_SERVICE_OFFERED",
    GET_ALL_QUESTIONS: "GET_ALL_QUESTIONS",
    STORE_ALL_QUESTIONS: "STORE_ALL_QUESTIONS",
    GET_VENDOR_GST_DETAILS: "GET_VENDOR_GST_DETAILS",
    DOWNLOAD_VENDOR_GST_DETAILS: "DOWNLOAD_VENDOR_GST_DETAILS",
    EDIT_VENDOR_EMAIL: "EDIT_VENDOR_EMAIL",
    RETRIGGER_VENDOR_FORM: "RETRIGGER_VENDOR_FORM",
    GET_STATE_LIST_DETAILS : "GET_STATE_LIST_DETAILS",
    STORE_STATE_LIST_DETAILS : "STORE_STATE_LIST_DETAILS",
    GET_STATE_AND_CITY_FROM_PIN_CODE : 'GET_STATE_AND_CITY_FROM_PIN_CODE',
    DELETE_UPLOADED_DOCUMENT_IN_VENDOR : 'DELETE_UPLOADED_DOCUMENT_IN_VENDOR',
    GET_BANK_DETAILS_FROM_IFSC : 'GET_BANK_DETAILS_FROM_IFSC',
    // GET_BANK_DETAILS_FROM_IFSC : 'GET_BANK_DETAILS_FROM_IFSC',
    VERIFY_VENDOR_CONTACTS: 'VERIFY_VENDOR_CONTACTS',
    SEND_OTP_IN_VENDOR_FORM : 'SEND_OTP_IN_VENDOR_FORM',
    VERIFY_OTP_IN_VENDOR_FORM: 'VERIFY_OTP_IN_VENDOR_FORM',
    DOWNLOAD_VEHICLE_DETAILS: 'DOWNLOAD_VEHICLE_DETAILS',
    GET_UPLOADED_VEHICLE_DETAILS: "GET_UPLOADED_VEHICLE_DETAILS",
    GET_PAYMENT_TERMS: 'GET_PAYMENT_TERMS',
    GET_ADMIN_NAMES : 'GET_ADMIN_NAMES',
    GET_CURRENCY_OPTIONS : 'GET_CURRENCY_OPTIONS',
    GET_COUNTRY_LIST: 'GET_COUNTRY_LIST',
    SAVE_SAP_VENDOR_FORM : 'SAVE_SAP_VENDOR_FORM',
    BANK_APPROVER_DOCUMENT_UPLOAD : 'BANK_APPROVER_DOCUMENT_UPLOAD',
    SAVE_BANK_APPROVER_DATA : 'SAVE_BANK_APPROVER_DATA',
    GET_INCO_TERMS_OPTIONS:'GET_INCO_TERMS_OPTIONS',
    SAVE_INCO_TERMS_OPTIONS:'SAVE_INCO_TERMS_OPTIONS',
    
      //vendorDetails
    GET_VENDOR_DETAILS_FOR_SAP: "GET_VENDOR_DETAILS_FOR_SAP",
    SET_VENDOR_DETAILS_FOR_SAP: "SET_VENDOR_DETAILS_FOR_SAP",
    GET_PAYMENT_TERMS_LIST: "GET_PAYMENT_TERMS_LIST",
    SET_PAYMENT_TERMS_LIST: "SET_PAYMENT_TERMS_LIST",
    GET_CURRENCY_LIST: "GET_CURRENCY_LIST",
    SET_CURRENCY_LIST: "SET_CURRENCY_LIST",
    POST_SAVE_SAP_DEATAILS: "POST_SAVE_SAP_DEATAILS",
    POST_SEND_SAP_DEATAILS :"POST_SEND_SAP_DEATAILS",
    GET_VENDORS_MODE_DATA: "GET_VENDORS_MODE_DATA",

    GET_PLANNING_OPTIONS : "GET_PLANNING_OPTIONS",
    GET_TAXCODE_OPTIONS : "GET_TAXCODE_OPTIONS",
    SAVE_TAXCODE_DETAILS : "SAVE_TAXCODE_DETAILS",

    // Exworks Details
    POST_EXWORKS_DEATAILS: "POST_EXWORKS_DEATAILS",
    //Bill Sequence
    GET_BILL_SEQUENCE_TEST_LIST: "GET_BILL_SEQUENCE_TEST_LIST",
    GET_BILL_SEQUENCE_LISTING: "GET_BILL_SEQUENCE_LISTING",
    GET_BILL_SEQUENCE_DROPDOWNS: "GET_BILL_SEQUENCE_DROPDOWNS",
    GET_BILL_SEQUENCE: "GET_BILL_SEQUENCE",
    POST_BILL_SEQUENCE: "POST_BILL_SEQUENCE",
    UPDATE_BILL_SEQUENCE: "UPDATE_BILL_SEQUENCE",
    DELETE_BILL_SEQUENCE: "DELETE_BILL_SEQUENCE",
    SET_BILL_SEQUENCE_DROPDOWNS: "SET_BILL_SEQUENCE_DROPDOWNS",
    SET_BILL_SEQUENCE_LIST: "SET_BILL_SEQUENCE_LIST"
  },

  PARENTCHILD: {
    GET_LOCATION_MANAGEMENT_LISTING: 'GET_LOCATION_MANAGEMENT_LISTING',
    GET_PARENT_CHILD_VIEW_LISTING: 'GET_PARENT_CHILD_VIEW_LISTING',
    DOWNLOAD_TEMPLATE_IN_UPLOAD_LOCATION : 'DOWNLOAD_TEMPLATE_IN_UPLOAD_LOCATION',
    UPLOAD_FILE_IN_UPLOAD_LOCATION : 'UPLOAD_FILE_IN_UPLOAD_LOCATION',
    DELETE_FILE_IN_UPLOAD_LOCATION : 'DELETE_FILE_IN_UPLOAD_LOCATION',
    GET_ALL_CLUSTER_MAPPING_DATA : 'GET_ALL_CLUSTER_MAPPING_DATA',
    GET_LOC_CHILD_OPTIONS : 'GET_LOC_CHILD_OPTIONS',
    ADD_CLUSTER_MAPPING_DATA : 'ADD_CLUSTER_MAPPING_DATA',
    DELETE_CLUSTER_MAPPING_DATA : 'DELETE_CLUSTER_MAPPING_DATA',
    EDIT_CLUSTER_MAPPING_DATA: 'EDIT_CLUSTER_MAPPING_DATA',
    DELETE_PARENT_CHILD_LOCATION_DATA : 'DELETE_PARENT_CHILD_LOCATION_DATA',
    EDIT_PARENT_CHILD_LOCATION_DATA: 'EDIT_PARENT_CHILD_LOCATION_DATA',
    DELETE_ALL_CHILD_DATA : 'DELETE_ALL_CHILD_DATA'
  },
  INDENTING: {
    GET_INDENT_CONFIG_DETAILS: 'GET_INDENT_CONFIG_DETAILS',
    ADD_INDENT_CONFIG_DETAILS: 'ADD_INDENT_CONFIG_DETAILS',
    SAVE_INDENT_CONFIG_DETAILS: 'SAVE_INDENT_CONFIG_DETAILS'
  },
  AUDIT_SCREEN: {
    GET_AUDIT_LOGS: 'GET_AUDIT_LOGS',
    GET_ALL_BRANCH_LIST: 'GET_ALL_BRANCH_LIST',
    SET_ALL_BRANCH_LIST: 'SET_ALL_BRANCH_LIST',
    DOWNLOAD_AUDIT_LOGS: 'DOWNLOAD_AUDIT_LOGS',
    GET_ALL_DL_NO: 'GET_ALL_DL_NO',
    SET_ALL_DL_NO: 'SET_ALL_DL_NO',
    GET_ALL_VEH_REG_NO: 'GET_ALL_VEH_REG_NO',
    SET_ALL_VEH_REG_NO: 'SET_ALL_VEH_REG_NO',
    GET_ALL_SAP_MATERIAL_CODE: 'GET_ALL_SAP_MATERIAL_CODE',
    SET_ALL_SAP_MATERIAL_CODE: 'SET_ALL_SAP_MATERIAL_CODE',
  },
  BANNER_SETTING:{
    GET_BANNER_LIST:'GET_BANNER_LIST',
    DELETE_BANNER_MESSAGE:'DELETE_BANNER_MESSAGE',
    ADD_BANNER_MESSAGE:'ADD_BANNER_MESSAGE',
    UPDATE_BANNER_MESSAGE:'UPDATE_BANNER_MESSAGE',

    GET_BROADCAST_MSG:'GET_BROADCAST_MSG',
    STORE_BROADCAST_MSG:'STORE_BROADCAST_MSG',

  }
};

export default actions;
