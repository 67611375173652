import React, { Component, Fragment } from "react";
import InputText from "../../common/components/input-text/input-text";
import Button from "../button-classic/button-classic";
import "./route_code.less";
import { connect } from "react-redux";
import ACTIONS from "../../common/action_constant";
import { TTBIL_COMPANY_IDS, AWL_COMPANY_IDS, BEAM_COMPANY_IDS, DEEPAK_COMPANY_ID, isDPL } from "../../../utils/common";
import Delete from "../../../assets/images/delete.svg";
import Select from "react-select";
import { validationCheck } from "../../../utils/validate";
const TAT_UOM = [
  { value: 1, label: "Days" },
  { value: 2, label: "Hours" },
];

const ROUTE_CODE_TYPE = [
  { value: 1, label: "Coastal" },
  { value: 2, label: "Rail" },
];
class AddRouteCode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      multiModeYard: [],
      multiModeStations: [],
      data: ["737"].includes(this.props.company_id)
        ? {
            plant_name: this.props.editData
              ? this.props.editData.plant_name
              : "",
            point_of_loading: this.props.editData
              ? this.props.editData.point_of_loading
              : "",
            point_of_destination: this.props.editData
              ? this.props.editData.point_of_destination
              : "",
            destination: this.props.editData
              ? this.props.editData.destination
              : "",
            route_code: this.props.editData
              ? this.props.editData.route_code
              : "",
            route_code_type: this.props.editData
              ? this.props.editData.route_code_type
              : "",
            company_id: this.props.company_id,
          }
        : {
          routestages: !!(this.props.editData||{}).routestages?this.props.editData.routestages:
          [],
            from: this.props.editData ? this.props.editData.source : "",
            to: this.props.editData ? this.props.editData.destination : "",
            sap_code: this.props.editData ? this.props.editData.sap_code : "",
            distance: this.props.editData ? this.props.editData.distance : null,
            ...([...BEAM_COMPANY_IDS, ...AWL_COMPANY_IDS].includes(this.props.company_id) && (this.props.modeType == 2) ? {
              from_yard: this.props.editData ? this.props.editData.origin_yard : null,
              to_yard: this.props.editData ? this.props.editData.destination_yard : null,
              from_station_code: this.props.editData ? this.props.editData.source_station_code : null,
              to_station_code: this.props.editData ? this.props.editData.dest_station_code : null,
              from_point: this.props.editData ? this.props.editData.departure_point : null,
              loading_point: this.props.editData ? this.props.editData.loading_point : null,
              drop_point: this.props.editData ? this.props.editData.destination_point : null,
              plant_to_yard_distance: this.props.editData ? this.props.editData.plant_to_yard_distance : null,
              yard_to_yard_distance: this.props.editData ? this.props.editData.yard_to_yard_distance : null,
              yard_to_depot_distance: this.props.editData ? this.props.editData.yard_to_depot_distance : null,
              transportation_type: this.props.editData ? this.props.editData.movement_type : null,
            } : {}),
            distance_uom: this.props.editData
              ? this.props.editData.distance_uom
              : "KM",
            company_id: this.props.company_id,
            is_distance_from_google: false,
            destination_list: this.props.editData
              ? this.props.editData.destination_list
              : [],
            tat: this.props.editData ? this.props.editData.tat : "",
            tat_uom: this.props.editData ? this.props.editData.tat_uom : "",
            from_district: this.props.editData
              ? this.props.editData.source_district
              : "",
            from_state: this.props.editData
              ? this.props.editData.source_state
              : "",
            to_state: this.props.editData
              ? (this.props.modeType == 2 ? this.props.editData.state : this.props.editData.destination_state)
              : "",
            to_district: this.props.editData
              ? this.props.editData.destination_district
              : "",
              branch_name: this.props.editData
              ? this.props.editData.branch_name
              : "",
              branch_id: this.props.editData
              ? this.props.editData.branch_id
              : "",
          },
    };
  }

  componentDidMount() {
    const { editData, company_id, modeType } = this.props;
    const { dispatch,isMultimode } = this.props;
    dispatch({
      type: ACTIONS.HOME.GET_DISTRICT,
      data: this.props.editData.source_state_code,
    });
    dispatch({
      type: ACTIONS.HOME.GET_TO_DISTRICT,
      data: this.props.editData.destination_state_code,
    });
    if(isMultimode && modeType==2){
      dispatch({
        type: ACTIONS.HOME.GET_ROUTE_CODE_DROPDOWN,
        param: {
          companyId: company_id,
          companyType: 1,
          limit: 100,
          offset: 0,
          type: 46
        },
        onSuccess: (data)=>{
          this.setState({
            multiModeYard: (data || []).map((op) => { return { label: op.branch_name, value: op.branch_name } }),
            multiModeStations: (data || []).map((op) => { return { label: op.branch_code, value: op.branch_code } })
          })
        }
      })
    }
  }

  onChangeHandler = (value, id, isType) => {
    const { data } = this.state;
    const result = validationCheck(value, data[id], isType);
    data[id] = result;
    this.setState({ data });
  };
  handleChange = (value, index, selectedOption) => {
    const { data } = this.state;
    if (index === 0) {
      data.destination_list[index] = value;
      data.to = value;
    } else {
      data.destination_list[index] = value;
    }
    this.setState({ data });
  };
  addDestination = () => {
    const { data } = this.state;
    let destination = [];
    destination.push("");
    data['destination_list'] = destination;
    this.setState({ data });
  };

  onDeleteDestination = (index) => {
    const { data } = this.state;
    data.destination_list.splice(index, 1);
    this.setState({ data });
  };

  onSave = () => {
    const { data } = this.state;
    const {modeType,isMultimode} = this.props;
    if (TTBIL_COMPANY_IDS.includes(data.company_id)) {
      if (
        !(
          data.from &&
          data.sap_code &&
          data.destination_list.indexOf("") === -1
        )
      ) {
        this.setState({ isError: true });
      } else {
        this.setState({ isError: false });
        this.props.onSaveRoute(data);
      }
    } else if (["737"].includes(this.props.company_id)) {
      if (!(data.route_code && data.plant_name && data.destination)) {
        this.setState({ isError: true });
      } else {
        this.setState({ isError: false });
        this.props.onSaveRoute(data);
      }
    } 
    else if ([...AWL_COMPANY_IDS, ...BEAM_COMPANY_IDS].includes(data.company_id) && (modeType == 1)) {
      if (
        !(
          data.from &&
          data.to &&
          data.to_state &&
          data.sap_code &&
          data.to_district &&
          data.from_state &&
          data.from_district &&
          data.distance
        )
      ) {
        this.setState({ isError: true });
      } else {
        this.setState({ isError: false });
        this.props.onSaveRoute(data);
      }
    } 
    else if ([...DEEPAK_COMPANY_ID].includes(data.company_id) && (modeType == 1)) {
      if (
        !(
          data.from &&
          data.to &&
          data.to_state &&
          data.sap_code &&
          data.to_district &&
          data.from_state &&
          data.from_district &&
          data.distance &&
          data.branch_name &&
          data.branch_id
        )
      ) {
        this.setState({ isError: true });
      } else {
        this.setState({ isError: false });
        this.props.onSaveRoute(data);
      }
    } 
    else if (isMultimode && (modeType == 2)) {
      if (
        !(
          data.from &&
          data.to &&
          data.sap_code
        )
      ) {
        this.setState({ isError: true });
      } else {
        this.setState({ isError: false });
        data.is_multimodal = true;
        this.props.onSaveRoute(data);
      }
    }else if ([...BEAM_COMPANY_IDS].includes(data.company_id) && (modeType == 2)) {
      if (
        !(
          data.from &&
          data.to &&
          data.to_state &&
          data.sap_code &&
          data.from_yard &&
          data.to_yard &&
          data.from_station_code &&
          data.to_station_code &&
          data.from_point &&
          data.loading_point &&
          data.drop_point &&
          data.plant_to_yard_distance &&
          data.yard_to_yard_distance &&
          data.yard_to_depot_distance &&
          data.transportation_type
        )
      ) {
        this.setState({ isError: true });
      } else {
        this.setState({ isError: false });
        data.is_multimodal = true;
        this.props.onSaveRoute(data);
      }
    }else {
      if (!(data.from && data.to && data.sap_code)) {
        this.setState({ isError: true });
      } else {
        this.setState({ isError: false });
        this.props.onSaveRoute(data);
      }
    }
  };

  onUpdate = () => {
    const { data } = this.state;
    const { editData, modeType, isMultimode } = this.props;
    const isRIL = ["737"].includes(data.company_id);
    if (
      !isRIL &&
      !(
        data.from &&
        data.to &&
        data.sap_code &&
        (data.destination_list || []).indexOf("") === -1
      )
    ) {
      this.setState({ isError: true });
    } 
    else if ([...AWL_COMPANY_IDS, ...BEAM_COMPANY_IDS].includes(data.company_id) && (modeType == 1)) {
      if (
        !(
          data.to_state &&
          data.to_district &&
          data.from_state &&
          data.from_district &&
          data.from &&
          data.to && data.sap_code
        )
      ) {
        this.setState({ isError: true });
      } else {
        this.setState({ isError: false });
      }
    } 
    else if ([ ...DEEPAK_COMPANY_ID].includes(data.company_id) && (modeType == 1)) {
      if (
        !(
          data.to_state &&
          data.to_district &&
          data.from_state &&
          data.from_district &&
          data.from &&
          data.to && data.sap_code &&
          data.branch_name &&
          data.branch_id
        )
      ) {
        this.setState({ isError: true });
      } else {
        this.setState({ isError: false });
      }
    } 
    else if (isMultimode && (modeType == 2)) {
      if (
        !(
          data.from &&
          data.to &&
          data.sap_code
        )
      ) {
        this.setState({ isError: true });
      } else {
        data.is_multimodal = true
        this.setState({ isError: false });
      }
    }else if ([...BEAM_COMPANY_IDS].includes(data.company_id) && (modeType == 2)) {
      if (
        !(
          data.from &&
          data.to &&
          data.to_state &&
          data.sap_code &&
          data.from_yard &&
          data.to_yard &&
          data.from_station_code &&
          data.to_station_code &&
          data.from_point &&
          data.loading_point &&
          data.drop_point &&
          data.plant_to_yard_distance &&
          data.yard_to_yard_distance &&
          data.yard_to_depot_distance &&
          data.transportation_type
        )
      ) {
        this.setState({ isError: true });
      } else {
        data.is_multimodal = true
        this.setState({ isError: false });
      }
    }
    {
      data["route_id"] = (editData && editData["route_id"]) || "";
      this.setState({ isError: false });
      this.props.onUpdateRoute(data);
    }
  };

  onBlurChange = () => {
    const { data } = this.state;
    if (data.from && data.to) {
      const { dispatch } = this.props;
      dispatch({
        type: ACTIONS.HOME.GET_ROUTE_CODE_DISTANCE,
        params: {
          origin: data.from,
          destinations: data.to,
          key: "AIzaSyCdwdm6vDZnZzagSdXU_YGa5It_stJZHeQ",
        },
        onSuccess: (data) => {
          console.log(data);
        },
      });
    }
  };
  onSelectState = (option) => {
    const { data } = this.state;
    data.from_state = option.value;
    this.setState({ data });
    if (option) {
      const { dispatch } = this.props;
      dispatch({
        type: ACTIONS.HOME.GET_DISTRICT,
        data: option.code,
      });
    }
    data.from_district = null;
    this.setState({ data });
  };

  onSelectFromYard = (option) => {
    const { data } = this.state;
    data.from_yard = option.label;
    this.setState({ data });
  }

  onSelectToYard = (option) => {
    const { data } = this.state;
    data.to_yard = option.label;
    this.setState({ data });
  }

  onSelectFromStationCode = (option) => {
    const { data } = this.state;
    data.from_station_code = option.label;
    this.setState({ data });
  }

  onSelectToStationCode = (option) => {
    const { data } = this.state;
    data.to_station_code = option.label;
    this.setState({ data });
  }

  onSelectToState = (option) => {
    const { data } = this.state;
    data.to_state = option.value;
    this.setState({ data });
    if (option) {
      const { dispatch } = this.props;
      dispatch({
        type: ACTIONS.HOME.GET_TO_DISTRICT,
        data: option.code,
      });
    }
    data.to_district = null;
    this.setState({ data });
  };
  onDplBranch = (option) => {
    const { data } = this.state;
    data.branch_name = option.value;
    data.branch_id = option.branch_id;
    
    this.setState({ data });
  };

  getRilRouteELements = () => {
    const { data } = this.state;
    return (
      <div className="details-section">
        <Select
          className="select service_mode"
          options={ROUTE_CODE_TYPE}
          value={ROUTE_CODE_TYPE.filter(
            (val) => val.value == data.route_code_type
          )}
          onChange={(option) => {
            const { data } = this.state;
            data.route_code_type = option.value;
            this.setState({ data });
          }}
          placeholder="Service Mode"
        />
        <InputText
          placeholder="Plant Name"
          id={"plant_name"}
          label="Plant Name"
          changeHandler={this.onChangeHandler}
          value={data.plant_name}
          required={true}
        />
        <InputText
          placeholder="Destination"
          id={"destination"}
          label="Destination"
          changeHandler={this.onChangeHandler}
          value={data.destination}
          required={true}
        />
        <InputText
          placeholder="Route Code"
          id={"route_code"}
          label="Route Code"
          changeHandler={this.onChangeHandler}
          value={data.route_code}
          required={true}
        />
        {data.route_code_type === 1 && (
          <>
            <InputText
              placeholder="Point Of Loading"
              id={"point_of_loading"}
              label="Point Of Loading"
              changeHandler={this.onChangeHandler}
              value={data.point_of_loading}
              required={true}
            />
            <InputText
              placeholder="Point Of Destination"
              id={"point_of_destination"}
              label="Point Of Destination"
              changeHandler={this.onChangeHandler}
              value={data.point_of_destination}
              required={true}
            />
          </>
        )}
      </div>
    );
  };
  onChangePointofContact = (e, id , index) => {
    let { data } = this.state;
    data.routestages[index][id] = e.target.value;
    this.setState( { data });
}

  onAddRouteStage = () => {
    let { data } = this.state;
    const addedRs= {
      itinerary : "",
      stageCat : "",
      departurePoint : "",
      stageNum : "",
      departurePtDesc:"",
      destinationPoint:"",
      destinationPtDesc:"",
      shipType:"",
      distance:"",
      unit:"",
      legIndicator:""
           }
    data.routestages.push(addedRs);
    this.setState({ data });
}
  onDeleteRouteStage = (index) => {
    let { data } = this.state;
    data.routestages.splice(index, 1);
    this.setState({ data });
}

  render() {
    const { districtList, modeType, company_id } = this.props;
    const isAdaniWilmar = ['999', '872'].includes(company_id);
    const districtListDatas =
      (districtList || []).map((data) => ({
        label: data.district,
        value: data.district,
      })) || [];

    const { TodistrictList } = this.props;
    const TodistrictLists =
      (TodistrictList || []).map((data) => ({
        label: data.district,
        value: data.district,
      })) || [];
    const { stateListData } = this.props;
    const StateDataList =
      (stateListData || []).map((data) => ({
        label: data.name,
        value: data.name,
        code: data.code,
      })) || [];

      const { DplBranchListData } = this.props;
      const DplBranchList =
      (DplBranchListData || []).map((data) => ({
        label: data.branch_name,
        value: data.branch_name,
        branch_id: data.branch_id,
      })) || [];

    const { data, multiModeYard, multiModeStations } = this.state;
    const isRIL = ["737"].includes(this.props.company_id);
    return (
      <div className="route_code_detail">
        <div onClick={this.props.toggleAdd} className="overlay"></div>
        <div id="modal" className="modal-popup">
          <div className="pop-heading">
            {`${this.props.editData ? "Edit" : "Add"} Route Details`}
          </div>

          <div className="route-detail">
            {!TTBIL_COMPANY_IDS.includes(data.company_id) && !isRIL ? (
              <div className="details-section">
                <InputText
                  placeholder="From"
                  id={"from"}
                  label="From"
                  changeHandler={this.onChangeHandler}
                  value={data.from}
                  readonly={modeType == 2 ? !!this.props.editData : false}
                  required={true}
                  additionalProps={{ onBlur: this.onBlurChange }}
                />

                {[...AWL_COMPANY_IDS, ...BEAM_COMPANY_IDS,...DEEPAK_COMPANY_ID].includes(data.company_id) && (modeType == 1) && (
                  <div className="input-text-wrap">
                    <div className="label">
                      From State<span className={"error error-font"}>*</span>
                    </div>
                    <Select
                      className="select service_mode"
                      options={StateDataList}
                      label="From State"
                      value={StateDataList.filter(
                        (val) => val.value == data.from_state
                      )}
                      placeholder="Select State"
                      onChange={(option) => this.onSelectState(option)}
                    />
                  </div>
                )}

                {[...AWL_COMPANY_IDS, ...BEAM_COMPANY_IDS,...DEEPAK_COMPANY_ID].includes(data.company_id) && (modeType == 1) && (
                  <div className="input-text-wrap">
                    <div className="label">
                      From District<span className={"error error-font"}>*</span>
                    </div>
                    {districtListDatas.length === 0 || districtListDatas=== undefined ?
                    (<>{data.from_district}</>)
                    :(<Select
                      className="select service_mode"
                      options={districtListDatas}
                      onChange={(option) => {
                        const { data } = this.state;
                        data.from_district = option.value;
                        this.setState({ data });
                      }}
                      value={districtListDatas.filter(
                        (val) => val.value === data.from_district
                      )}
                      placeholder="Select District"
                    />)}
                  </div>
                )}

                <InputText
                  placeholder="To"
                  id={"to"}
                  label="To"
                  changeHandler={this.onChangeHandler}
                  value={data.to}
                  required={true}
                  readonly={modeType == 2 ? !!this.props.editData : false}
                  additionalProps={{ onBlur: this.onBlurChange }}
                />

                {[ ...BEAM_COMPANY_IDS].includes(data.company_id) && (modeType == 2) && (
                  <div className="input-text-wrap">
                    <div className="label">
                      From Station Code<span className={"error error-font"}>*</span>
                    </div>
                    <Select
                      className="select service_mode"
                      options={multiModeStations}
                      label="From Station Code"
                      dis
                      value={multiModeStations.filter(
                        (val) => val.label == data.from_station_code
                      )}
                      placeholder="Select Station Code"
                      onChange={(option) => this.onSelectFromStationCode(option)}
                    />
                  </div>
                )}

                {[ ...BEAM_COMPANY_IDS].includes(data.company_id) && (modeType == 2) && (
                  <div className="input-text-wrap">
                    <div className="label">
                      To Station Code<span className={"error error-font"}>*</span>
                    </div>
                    <Select
                      className="select service_mode"
                      options={multiModeStations}
                      label="To Station Code"
                      dis
                      value={multiModeStations.filter(
                        (val) => val.label == data.to_station_code
                      )}
                      placeholder="Select Station Code"
                      onChange={(option) => this.onSelectToStationCode(option)}
                    />
                  </div>
                )}

                {[...AWL_COMPANY_IDS, ...BEAM_COMPANY_IDS, ...DEEPAK_COMPANY_ID].includes(data.company_id) && (modeType == 1) && (
                  <div className="input-text-wrap">
                    <div className="label">
                      To State<span className={"error error-font"}>*</span>
                    </div>
                    <Select
                      className="select service_mode"
                      options={StateDataList}
                      value={StateDataList.filter(
                        (val) => val.value == data.to_state
                      )}
                      onChange={(option) => this.onSelectToState(option)}
                      placeholder="Select State"
                    />
                  </div>
                )}
                {[...AWL_COMPANY_IDS, ...BEAM_COMPANY_IDS, ...DEEPAK_COMPANY_ID].includes(data.company_id) && (modeType == 1) && (
                  <div className="input-text-wrap">
                    <div className="label">
                      To District<span className={"error error-font"}>*</span>
                    </div>
                    {TodistrictLists === undefined || TodistrictLists.length === 0 ?
                    (<>{ data.to_district }</>)
                     : (<Select
                      className="select service_mode"
                      options={TodistrictLists}
                      value={
                        TodistrictLists.filter((val) => {
                          return val.value == data.to_district;
                        }) || ""
                      }
                      onChange={(option) => {
                        const { data } = this.state;
                        data.to_district = option.value;
                        this.setState({ data });
                      }}
                      placeholder="Select District"
                    />)}
                  </div>
                )}

                <InputText
                  placeholder="Route Code"
                  id={"sap_code"}
                  label="Route Code"
                  changeHandler={this.onChangeHandler}
                  value={data.sap_code}
                  required={true}
                  readonly={modeType == 2 ? !!this.props.editData : false}
                  isValidate={"alphanumeric"}
                />


                {(modeType == 1) && <InputText
                  type="number"
                  placeholder="Distance"
                  id={"distance"}
                  required={true}
                  label="Distance in Km"
                  changeHandler={this.onChangeHandler}
                  value={data.distance}
                />}

                {DEEPAK_COMPANY_ID.includes(data.company_id) && (modeType == 1) &&
                  <div className="input-text-wrap">
                  <div className="label">
                    Plant Name<span className={"error error-font"}>*</span>
                  </div>
                  <Select
                    className="select service_mode"
                    options={DplBranchList}
                    value={DplBranchList.filter(
                      (val) => val.value == data.branch_name
                    )}
                    onChange={(option) => this.onDplBranch(option)}
                    placeholder="Select Branch"
                  />
                </div>
                  }
                
                {isAdaniWilmar &&  
                <div className="route-stages-section point-of-contact">
                <div className={'heading-section'}>Route Stages:</div>
                { data.routestages .map((val, index) => {
                    return(<div className={'RouteStages'} key={index}>
                            <div className="list-section">
                            <div className="rs-item"><span>Itinerary:</span><span><input className={'routestg-input'} value={val && val.itinerary} onChange={(e) => this.onChangePointofContact(e, 'itinerary', index)}></input></span></div>
                            <div className="rs-item"><span>Stage Category:</span><span><input className={'routestg-input'} type="number" value={val && val.stageCat} onChange={(e) => this.onChangePointofContact(e, 'stageCat', index)}></input></span></div>
                            <div className="rs-item"><span>Departure Point:</span><span><input className={'routestg-input'} value={val && val.departurePoint} onChange={(e) => this.onChangePointofContact(e, 'departurePoint', index)}></input></span></div>
                            <div className="rs-item"><span>Stage Number:</span><span><input className={'routestg-input'} value={val && val.stageNum} onChange={(e) => this.onChangePointofContact(e, 'stageNum', index)}></input></span></div>
                            </div>
                            <div className="list-section ml-20">
                            <div className="rs-item"><span>Departure Point Desc:</span><span><input className={'routestg-input'} value={val && val.departurePtDesc} onChange={(e) => this.onChangePointofContact(e, 'departurePtDesc', index)}></input></span></div>
                            <div className="rs-item"><span>Destination Point:</span><span><input className={'routestg-input'} value={val && val.destinationPoint} onChange={(e) => this.onChangePointofContact(e, 'destinationPoint', index)}></input></span></div>
                            <div className="rs-item"><span>Destination Point Desc:</span><span><input className={'routestg-input'} value={val && val.destinationPtDesc} onChange={(e) => this.onChangePointofContact(e, 'destinationPtDesc', index)}></input></span></div>
                            <div className="rs-item"><span>Shipment Type:</span><span><input className={'routestg-input'} value={val && val.shipType} onChange={(e) => this.onChangePointofContact(e, 'shipType', index)}></input></span></div>
                            </div>
                            <div className="list-section ml-20">
                            <div className="rs-item"><span>Distance:</span><span><input className={'routestg-input'} value={val && val.distance} onChange={(e) => this.onChangePointofContact(e, 'distance', index)}></input></span></div>
                            <div className="rs-item"><span>Unit:</span><span><input className={'routestg-input'} value={val && val.unit} onChange={(e) => this.onChangePointofContact(e, 'unit', index)}></input></span></div>
                            <div className="rs-item"><span>Leg Indicator:</span><span><input className={'routestg-input'} type="number" value={val && val.legIndicator} onChange={(e) => this.onChangePointofContact(e, 'legIndicator', index)}></input></span></div>
                            
                            </div>
                            <div className="rs-delete-item">
                                <span className={'delete-icon'} style={{backgroundImage: `url(${Delete})`}} onClick={() => this.onDeleteRouteStage(index)}></span>
                            </div>
                           </div>)})
                }
                <div className='contact-btn'>
                    <Button value="+ Add" click={this.onAddRouteStage} />
                </div>
                </div>
                }
              </div>
            ) : (
              <Fragment>
                {isRIL ? (
                  <Fragment>{this.getRilRouteELements()}</Fragment>
                ) : (
                  <div className="details-section">
                    <InputText
                      placeholder="From"
                      id={"from"}
                      label="From"
                      changeHandler={this.onChangeHandler}
                      value={data.from}
                      required={true}
                      additionalProps={{ onBlur: this.onBlurChange }}
                    />
                    <InputText
                      placeholder="Route Code"
                      id={"sap_code"}
                      label="Route Code"
                      changeHandler={this.onChangeHandler}
                      value={data.sap_code}
                      required={true}
                    />
                    <InputText
                      placeholder="Distance"
                      id={"distance"}
                      label="Distance in Km"
                      changeHandler={this.onChangeHandler}
                      value={data.distance}
                      type="number"
                    />
                    <InputText
                      placeholder="TAT"
                      id={"tat"}
                      label="TAT"
                      changeHandler={this.onChangeHandler}
                      value={data.tat}
                    />
                    <Select
                      className="select"
                      options={TAT_UOM}
                      value={TAT_UOM.filter((val) => val.label == data.tat_uom)}
                      onChange={(value) => {
                        const { data } = this.state;
                        data.tat_uom = value.label;
                        this.setState({ data });
                      }}
                      placeholder="TAT UOM"
                    />
                    {data.destination_list && data.destination_list.length > 0
                      ? data.destination_list.map((multiDest, index) => {
                          return (
                            <Fragment>
                              <div>
                                <InputText
                                  placeholder="To"
                                  id={"to"}
                                  label="To"
                                  changeHandler={(e) =>
                                    this.handleChange(e, index)
                                  }
                                  value={multiDest}
                                  required={true}
                                  additionalProps={{
                                    onBlur: this.onBlurChange,
                                  }}
                                />
                                {data.destination_list.length !== 1 && (
                                  <div
                                    className={"delete-icon"}
                                    style={{
                                      backgroundImage: `url(${Delete})`,
                                    }}
                                    onClick={() =>
                                      this.onDeleteDestination(index)
                                    }
                                  />
                                )}
                                {index === data.destination_list.length - 1 && (
                                  <Button
                                    value={"+"}
                                    click={this.addDestination}
                                  />
                                )}
                              </div>
                            </Fragment>
                          );
                        })
                      : this.addDestination()}
                  </div>
                )}
              </Fragment>
            )}
            {this.state.isError && (
              <div className={"error-message"}>
                {"Please Enter all mandatory fields"}
              </div>
            )}
            <div className={"button_route"}>
              <Button value={"Cancel"} click={this.props.toggleAdd} />
              {!this.props.editData ? (
                <Button value={"Save"} click={this.onSave} />
              ) : (
                <Button value={"Update"} click={this.onUpdate} />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect()(AddRouteCode);
