import React, { Component } from "react";
import InputText from "../../../../common/components/input-text/input-text";
import Select from "react-select";
import Button from "../../../../home/button-classic/button-classic";
import {
  cargoType,
  productUnit,
  physicalState,
  companyName,
  chainType,
  handlingInstruction,
} from "../../../../../utils/constants";
import { getCookie } from "../../../../../utils/cookies.js";
import {
  DEEPAK_COMPANY_ID,
  CARGILL_COMPANY_IDS,
  EMAMI_COMPANY_IDS
} from "../../../../../utils/common";
import { SingleDatePicker } from "react-dates";
import moment from "moment";

class AddItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companyType: "",
      chainType: "",
      product: "",
      flowPriority: "",
      physicalState: "",
      insideCount: "",
      length: "",
      breadth: "",
      height: "",
      unit: "",
      volume: "",
      actualWeight: "",
      volumentricWeight: "",
      volumetricFlag: "",
      chargedWeight: "",
      mpgCode: "",
      materialName: "",
      transporterPacking: "",
      unitPacking: "",
      stackingPermitted: "",
      company_id: JSON.parse(getCookie("user")).company_id,
      handlingInstruction: "",
      auto_loading: false,
      density: "",
      valid_from: "",
      valid_to: "",
      retest:'',
    };
  }

  handleChange = (id, selectedOption) => {
    this.setState({ [id]: selectedOption.label });
  };

  onChangeHandler = (value, id) => {
    const data = this.state;
    data[id] = value;
    if (["length", "breadth", "height"].indexOf(id) > -1) {
      data["volume"] =
        Number(data["length"]) *
        Number(data["breadth"]) *
        Number(data["height"]);
    }
    this.setState({ [id]: value, volume: data.volume });
  };

  onSave = () => {
    const data = {...this.state};
    delete data.validityToFocused;
    delete data.validityfromFocused;
    this.props.toggleAdd();
    this.props.addInventoryItem(data);
  };
  companyDropDownOptions=()=>{
    const { company_id } = this.state;
    const isCargill = CARGILL_COMPANY_IDS.includes(company_id);
    if(isCargill){
      return [{ label: "Cargill", value: 6 }]
    }
    return companyName
  }
  

  render() {
    const { company_id } = this.state;
    const { packagingDetails } = this.props;
    const packagingOptions =
      (packagingDetails || []).map((data) => ({
        label: data.name,
        value: data.id,
      })) || [];
    const companyOptions = this.companyDropDownOptions();

    const gridStyle = {
      display: "grid",
      gridTemplateColumns: "repeat(3, 1fr)",
      gap: "40px",
      alignItems: "center",
      padding: "30px",
    };

    const itemStyle = {
      width: "100%",
    };

    const buttonContainerStyle = {
      display: "flex",
      justifyContent: "center",
      marginTop: "16px",
      gap: "40px",
    };
    return (
      <div style={{ position: "relative", zIndex: 1000 }}>
        <div
          onClick={this.props.toggleAdd}
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(27, 33, 67, 0.8)",
          }}
        ></div>
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "#fff",
            padding: "24px",
            borderRadius: "8px",
            height: "90vH",
            width: "90%",
            maxWidth: "1200px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            overflow: "auto",
          }}
        >
          <div
            style={{
              fontSize: "22px",
              fontWeight: "bold",
              margin: "20px",
              textAlign: "center",
            }}
          >
            Add Item
          </div>
          <div style={gridStyle}>
            <div style={itemStyle}>
              <div style={{ fontSize: "10px", color: "#868e96" }}>Company</div>
              <Select
                value={companyOptions.find(
                  (val) => val.value === this.state.companyType,
                )}
                onChange={this.handleChange.bind(this, "companyType")}
                options={companyOptions}
              />
            </div>
            <div style={itemStyle}>
              <div style={{ fontSize: "10px", color: "#868e96" }}>
                Storage Type
              </div>
              <Select
                value={chainType.find(
                  (val) => val.value === this.state.chainType,
                )}
                onChange={this.handleChange.bind(this, "chainType")}
                options={chainType}
              />
            </div>
            <InputText
              label="Major Product Group"
              id="mpgCode"
              value={this.state.mpgCode}
              placeholder="Major Product Group"
              changeHandler={this.onChangeHandler}
              style={itemStyle}
            />
            <InputText
              label="Material Code"
              id="product"
              value={this.state.product}
              placeholder="Material Code"
              changeHandler={this.onChangeHandler}
              style={itemStyle}
            />
            <InputText
              label="Product Description"
              id="materialName"
              value={this.state.materialName}
              placeholder="Product Description"
              changeHandler={this.onChangeHandler}
              style={itemStyle}
            />
            <InputText
              label="Flow Priority"
              id="flowPriority"
              value={this.state.flowPriority}
              placeholder="Flow Priority"
              changeHandler={this.onChangeHandler}
              style={itemStyle}
            />
            <div style={itemStyle}>
              <div style={{ fontSize: "10px", color: "#868e96" }}>
                Physical State
              </div>
              <Select
                value={physicalState.find(
                  (val) => val.value === this.state.physicalState,
                )}
                options={physicalState}
                onChange={this.handleChange.bind(this, "physicalState")}
              />
            </div>
            <InputText
              label="Inside Count"
              id="insideCount"
              value={this.state.insideCount}
              placeholder="Inside Count"
              changeHandler={this.onChangeHandler}
              style={itemStyle}
            />
            <InputText
              label="Length"
              id="length"
              value={this.state.length}
              placeholder="Length"
              changeHandler={this.onChangeHandler}
              style={itemStyle}
            />
            <InputText
              label="Breadth"
              id="breadth"
              value={this.state.breadth}
              placeholder="Breadth"
              changeHandler={this.onChangeHandler}
              style={itemStyle}
            />
            <InputText
              label="Height"
              id="height"
              value={this.state.height}
              placeholder="Height"
              changeHandler={this.onChangeHandler}
              style={itemStyle}
            />
            <InputText
              label="Volume"
              id="volume"
              value={this.state.volume}
              placeholder="Volume"
              changeHandler={this.onChangeHandler}
              style={itemStyle}
            />
            <div style={itemStyle}>
              <div style={{ fontSize: "10px", color: "#868e96" }}>Unit</div>
              <Select
                value={productUnit.find((val) => val.value === this.state.unit)}
                options={productUnit}
                onChange={this.handleChange.bind(this, "unit")}
              />
            </div>
            <InputText
              label="Actual Weight"
              id="actualWeight"
              value={this.state.actualWeight}
              placeholder="Actual Weight"
              changeHandler={this.onChangeHandler}
              style={itemStyle}
            />
            <InputText
              label="Charged Weight"
              id="chargedWeight"
              value={this.state.chargedWeight}
              placeholder="Charged Weight"
              changeHandler={this.onChangeHandler}
              style={itemStyle}
            />
            <div style={itemStyle}>
              <div style={{ fontSize: "10px", color: "#868e96" }}>
                Cargo Type
              </div>
              <Select
                value={cargoType.find(
                  (val) => val.label === this.state.volumetricFlag,
                )}
                options={cargoType}
                onChange={this.handleChange.bind(this, "volumetricFlag")}
              />
            </div>
            <InputText
              label="Volumetric Weight"
              id="volumentricWeight"
              value={this.state.volumentricWeight}
              placeholder="Volumetric Weight"
              changeHandler={this.onChangeHandler}
              style={itemStyle}
            />
            <div style={itemStyle}>
              <div style={{ fontSize: "10px", color: "#868e96" }}>
                Unit Packing
              </div>
              <Select
                value={packagingOptions.find(
                  (val) => val.value === this.state.unitPacking,
                )}
                options={packagingOptions}
                onChange={this.handleChange.bind(this, "unitPacking")}
              />
            </div>
            {DEEPAK_COMPANY_ID.includes(company_id) && (
              <div style={itemStyle}>
                <div style={{ fontSize: "10px", color: "#868e96" }}>
                  Handling Instruction
                </div>
                <Select
                  value={handlingInstruction.find(
                    (val) => val.label === this.state.handlingInstruction,
                  )}
                  options={handlingInstruction}
                  onChange={this.handleChange.bind(this, "handlingInstruction")}
                />
              </div>
            )}
            <div style={itemStyle}>
              <div style={{ fontSize: "10px", color: "#868e96" }}>
                Transporter Packing
              </div>
              <Select
                value={packagingOptions.find(
                  (val) => val.label === this.state.transporterPacking,
                )}
                options={packagingOptions}
                onChange={this.handleChange.bind(this, "transporterPacking")}
              />
            </div>
            <InputText
              label="Stacking Permitted"
              id="stackingPermitted"
              value={this.state.stackingPermitted}
              placeholder="Stacking Permitted"
              changeHandler={this.onChangeHandler}
            />

            {DEEPAK_COMPANY_ID.includes(company_id) && (
              <div className="select-wrap">
                <div
                  className={
                    this.state.auto_loading
                      ? "custome-checkbox-wrap selected"
                      : "custome-checkbox-wrap"
                  }
                  onClick={() =>
                    this.onChangeHandler(
                      !this.state.auto_loading,
                      "auto_loading",
                    )
                  }
                >
                  {this.state.auto_loading && (
                    <div className="inner-circle"></div>
                  )}
                </div>
                <div
                  className="title"
                  style={{ marginLeft: "5px", marginTop: "2px" }}
                >
                  {" "}
                  Auto Loading
                </div>
              </div>
            )}

            <InputText
              label={`Density`}            
              id="density"
              value={this.state.density}
              placeholder="Density"
              changeHandler={this.onChangeHandler}
              style={itemStyle}
            />

            <div style={itemStyle}>
              <div
                style={{
                  fontSize: "10px",
                  color: "#868e96",
                  marginBottom: "8px",
                }}
              >
                Validity From
              </div>
              <div style={{ width: "100%", position: "relative" }}>
                <SingleDatePicker
                  id="validity-date-picker"
                  displayFormat={"DD MMM YYYY"}
                  hideKeyboardShortcutsPanel={true}
                  placeholder={"Date"}
                  numberOfMonths={1}
                  date={this.state.valid_from && moment(this.state.valid_from)}
                  onDateChange={(date) => {
                    this.setState({ valid_from: moment(date).valueOf() });
                  }}
                  focused={this.state.validityfromFocused}
                  onFocusChange={({ focused }) =>
                    this.setState({ validityfromFocused: focused })
                  }
                  isOutsideRange={() => false}
                  block
                />
              </div>
            </div>

            {!!this.state.valid_from &&
              <div style={itemStyle}>
              <div
                style={{
                  fontSize: "10px",
                  color: "#868e96",
                  marginBottom: "8px",
                }}
              >
                Validity To
              </div>
              <div style={{ width: "100%", position: "relative" }}>
                <SingleDatePicker
                  id="validity-date-picker"
                  displayFormat={"DD MMM YYYY"}
                  hideKeyboardShortcutsPanel={true}
                  placeholder={"Date"}
                  numberOfMonths={1}
                  date={this.state.valid_to && moment(this.state.valid_to)}
                  onDateChange={(date) => {
                    this.setState({ valid_to: moment(date).valueOf() });
                  }}
                  focused={this.state.validityToFocused}
                  onFocusChange={({ focused }) =>
                    this.setState({ validityToFocused: focused })
                  }
                  isOutsideRange={(day) =>
                    day.isBefore(moment(this.state.valid_from), "day")
                  }
                  block
                />
              </div>
              </div>}
            
            {DEEPAK_COMPANY_ID.includes(company_id) &&
              <InputText
                label={`Retest (In Months)`}            
                id="retest"
                value={this.state.retest}
                placeholder="Retest"
                changeHandler={this.onChangeHandler}
                style={itemStyle}
            />
            }

          </div>
          <div style={buttonContainerStyle}>
            <Button value="Save" click={this.onSave} />
            <Button value="Cancel" click={this.props.toggleAdd} />
          </div>
        </div>
      </div>
    );
  }
}

export default AddItem;
