import React, { Component } from "react";
import InputText from "../../../../common/components/input-text/input-text";
import Select from "react-select";
import Button from "../../../../home/button-classic/button-classic";
import {
  cargoType,
  productUnit,
  physicalState,
  companyName,
  chainType,
  handlingInstruction,
} from "../../../../../utils/constants";
import { DEEPAK_COMPANY_ID } from "../../../../../utils/common";
import { getCookie } from "../../../../../utils/cookies.js";
import { SingleDatePicker } from "react-dates";
import moment from "moment";

class AddItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        productMasterId: this.props.data.productMasterId,
        companyType: this.props.data.companyType,
        chainType: this.props.data.chainType,
        product: this.props.data.product,
        flowPriority: this.props.data.flowPriority,
        physicalState: this.props.data.physicalState,
        insideCount: this.props.data.insideCount,
        length: this.props.data.length,
        breadth: this.props.data.breadth,
        height: this.props.data.height,
        unit: this.props.data.unit,
        volume: this.props.data.volume,
        actualWeight: this.props.data.actualWeight,
        volumentricWeight: this.props.data.volumentricWeight,
        volumetricFlag: this.props.data.volumetricFlag,
        chargedWeight: this.props.data.chargedWeight,
        mpgCode: this.props.data.mpgCode,
        materialName: this.props.data.materialName,
        transporterPacking: this.props.data.transporterPacking,
        unitPacking: this.props.data.unitPacking,
        stackingPermitted: this.props.data.stackingPermitted,
        auto_loading: this.props.data.auto_loading || false,
        density:this.props.data.density||'',
        valid_from:this.props.data.valid_from||'',
        valid_to:this.props.data.valid_to||'',
        retest:this.props.data.retest||'',
      },
    };
  }

  handleChange = (id, selectedOption) => {
    let { data } = this.state;
    data[id] = selectedOption.label;
    this.setState({ data });
  };

  onChangeHandler = (value, id) => {
    let { data } = this.state;
    data[id] = value;
    if (["length", "breadth", "height"].indexOf(id) > -1) {
      data["volume"] =
        Number(data["length"]) *
        Number(data["breadth"]) *
        Number(data["height"]);
    }
    this.setState({ data });
  };

  onSave = () => {
    const { data } = this.state;
    this.props.toggleAdd("Edit");
    this.props.updateInventoryItem(data);
  };
  
  render() {
    let { data } = this.state;
    const company_id = JSON.parse(getCookie("user")).company_id;
    const { packagingDetails } = this.props;
    const packagingOptions =
      (packagingDetails || []).map((data) => ({
        label: data.name,
        value: data.id,
      })) || [];

    const gridStyle = {
      display: "grid",
      gridTemplateColumns: "repeat(3, 1fr)",
      gap: "40px",
      alignItems: "center",
      padding: "30px",
    };

    const itemStyle = {
      width: "100%",
    };

    const buttonContainerStyle = {
      display: "flex",
      justifyContent: "center",
      marginTop: "16px",
      gap: "40px",
    };

    return (
      <div style={{ position: "relative", zIndex: 1000 }}>
        <div
          onClick={() => this.props.toggleAdd("Edit")}
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(27, 33, 67, 0.8)",
          }}
        ></div>
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "#fff",
            padding: "24px",
            borderRadius: "8px",
            height: "90vH",
            width: "90%",
            maxWidth: "1200px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            overflow: "auto",
          }}
        >
          <div
            style={{
              fontSize: "22px",
              fontWeight: "bold",
              margin: "20px",
              textAlign: "center",
            }}
          >
            Edit Item
          </div>
          <div style={gridStyle}>
            <div style={itemStyle}>
              <div style={{ fontSize: "10px", color: "#868e96" }}>Company</div>
              <Select
                value={companyName.find(
                  (val) => val.label === data.companyType,
                )}
                onChange={this.handleChange.bind(this, "companyType")}
                options={companyName}
              />
            </div>
            <div style={itemStyle}>
              <div style={{ fontSize: "10px", color: "#868e96" }}>
                Storage Type
              </div>
              <Select
                value={chainType.find((val) => val.label === data.chainType)}
                options={chainType}
                onChange={this.handleChange.bind(this, "chainType")}
              />
            </div>
            <InputText
              label="Major Product Group"
              id="mpgCode"
              value={data.mpgCode}
              placeholder="Major Product Group"
              changeHandler={this.onChangeHandler}
              style={itemStyle}
            />
            <InputText
              label="Material Code"
              id="product"
              value={data.product}
              placeholder="Material Code"
              changeHandler={this.onChangeHandler}
              style={itemStyle}
            />
            <InputText
              label="Product Description"
              id="materialName"
              value={data.materialName}
              placeholder="Product Description"
              changeHandler={this.onChangeHandler}
              style={itemStyle}
            />
            <InputText
              label="Flow Priority"
              placeholder="Flow Priority"
              id="flowPriority"
              value={data.flowPriority}
              changeHandler={this.onChangeHandler}
              style={itemStyle}
            />
            <div style={itemStyle}>
              <div style={{ fontSize: "10px", color: "#868e96" }}>
                Physical State
              </div>
              <Select
                value={physicalState.find(
                  (val) => val.value === data.physicalState,
                )}
                options={physicalState}
                onChange={this.handleChange.bind(this, "physicalState")}
              />
            </div>
            <InputText
              label="Inside Count"
              placeholder="Inside Count"
              id="insideCount"
              value={data.insideCount}
              changeHandler={this.onChangeHandler}
              style={itemStyle}
            />
            <InputText
              label="Length"
              placeholder="Length"
              id="length"
              value={data.length}
              changeHandler={this.onChangeHandler}
              style={itemStyle}
            />
            <InputText
              label="Breadth"
              placeholder="Breadth"
              id="breadth"
              value={data.breadth}
              changeHandler={this.onChangeHandler}
              style={itemStyle}
            />
            <InputText
              label="Height"
              placeholder="Height"
              id="height"
              value={data.height}
              changeHandler={this.onChangeHandler}
              style={itemStyle}
            />
            <InputText
              label="Volume"
              placeholder="Volume"
              id="volume"
              value={data.volume}
              changeHandler={this.onChangeHandler}
              style={itemStyle}
            />
            <div style={itemStyle}>
              <div style={{ fontSize: "10px", color: "#868e96" }}>Unit</div>
              <Select
                value={productUnit.find((val) => val.value === data.unit)}
                options={productUnit}
                onChange={this.handleChange.bind(this, "unit")}
              />
            </div>
            <InputText
              label="Actual Weight"
              id="actualWeight"
              value={data.actualWeight}
              placeholder="Actual Weight"
              changeHandler={this.onChangeHandler}
              style={itemStyle}
            />
            <InputText
              label="Charged Weight"
              id="chargedWeight"
              value={data.chargedWeight}
              placeholder="Charged Weight"
              changeHandler={this.onChangeHandler}
              style={itemStyle}
            />
            <div style={itemStyle}>
              <div style={{ fontSize: "10px", color: "#868e96" }}>
                Cargo Type
              </div>
              <Select
                value={cargoType.find(
                  (val) =>
                    val.label.toLowerCase() ===
                    data.volumetricFlag.toLowerCase(),
                )}
                options={cargoType}
                onChange={this.handleChange.bind(this, "volumetricFlag")}
              />
            </div>
            <InputText
              label="Volumetric Weight"
              id="volumentricWeight"
              value={data.volumentricWeight}
              placeholder="Volumetric Weight"
              changeHandler={this.onChangeHandler}
              style={itemStyle}
            />
            <div style={itemStyle}>
              <div style={{ fontSize: "10px", color: "#868e96" }}>
                Unit Packing
              </div>
              <Select
                value={packagingOptions.find(
                  (val) => val.label === data.unitPacking,
                )}
                options={packagingOptions}
                onChange={this.handleChange.bind(this, "unitPacking")}
              />
            </div>

            {DEEPAK_COMPANY_ID.includes(company_id) && (
              <div style={itemStyle}>
                <div style={{ fontSize: "10px", color: "#868e96" }}>
                  Handling Instruction
                </div>
                <Select
                  value={handlingInstruction.find(
                    (val) => val.label === this.state.handlingInstruction,
                  )}
                  options={handlingInstruction}
                  onChange={this.handleChange.bind(this, "handlingInstruction")}
                />
              </div>
            )}

            <div style={itemStyle}>
              <div style={{ fontSize: "10px", color: "#868e96" }}>
                Transporter Packing
              </div>
              <Select
                value={packagingOptions.find(
                  (val) => val.label === data.transporterPacking,
                )}
                options={packagingOptions}
                onChange={this.handleChange.bind(this, "transporterPacking")}
              />
            </div>
            <InputText
              label="Stacking Permitted"
              id="stackingPermitted"
              value={data.stackingPermitted}
              placeholder="Stacking Permitted"
              changeHandler={this.onChangeHandler}
              style={itemStyle}
            />
            {DEEPAK_COMPANY_ID.includes(company_id) && (
              <div
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                <div
                  style={{
                    width: "20px",
                    height: "20px",
                    borderRadius: "50%",
                    border: "2px solid #000",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                    backgroundColor: data.auto_loading ? "#000" : "#fff",
                  }}
                  onClick={() =>
                    this.onChangeHandler(!data.auto_loading, "auto_loading")
                  }
                >
                  {data.auto_loading && (
                    <div
                      style={{
                        width: "10px",
                        height: "10px",
                        backgroundColor: "#fff",
                        borderRadius: "50%",
                      }}
                    />
                  )}
                </div>
                <div style={{ marginLeft: "5px", marginTop: "2px" }}>
                  Auto Loading
                </div>
              </div>
            )}
            <InputText
              label="Density"
              id="density"
              value={data.density}
              placeholder="Density"
              changeHandler={this.onChangeHandler}
              style={itemStyle}
            />

            <div style={itemStyle}>
              <div
                style={{
                  fontSize: "10px",
                  color: "#868e96",
                  marginBottom: "8px",
                }}
              >
                Validity From
              </div>
              <div style={{ width: "100%", position: "relative" }}>
                <SingleDatePicker
                  id="validity-date-picker"
                  displayFormat={"DD MMM YYYY"}
                  hideKeyboardShortcutsPanel={true}
                  placeholder={"Date"}
                  numberOfMonths={1}
                  date={data.valid_from && moment(data.valid_from)}
                  onDateChange={(date) => {
                    data.valid_from= moment(date).valueOf()
                    this.setState({ data });
                  }}
                  focused={this.state.validityfromFocused}
                  onFocusChange={({ focused }) =>
                    this.setState({ validityfromFocused: focused })
                  }
                  isOutsideRange={() => false}
                  block
                />
              </div>
            </div>

            {!!data.valid_from&&
            <div style={itemStyle}>
              <div
                style={{
                  fontSize: "10px",
                  color: "#868e96",
                  marginBottom: "8px",
                }}
              >
                Validity To
              </div>
              <div style={{ width: "100%", position: "relative" }}>
                <SingleDatePicker
                  id="validity-date-picker"
                  displayFormat={"DD MMM YYYY"}
                  hideKeyboardShortcutsPanel={true}
                  placeholder={"Date"}
                  numberOfMonths={1}
                  date={data.valid_to && moment(data.valid_to)}
                  onDateChange={(date) => {
                    data.valid_to= moment(date).valueOf()
                    this.setState({ data });
                  }}
                  focused={this.state.validityToFocused}
                  onFocusChange={({ focused }) =>
                    this.setState({ validityToFocused: focused })
                  }
                  isOutsideRange={(day) =>
                    day.isBefore(moment(data.valid_from), "day")
                  }
                  block
                />
              </div>
            </div>}
          
            {DEEPAK_COMPANY_ID.includes(company_id) &&
              <InputText
                label={`Retest (In Months)`}            
                id="retest"
                value={data.retest}
                placeholder="Retest"
                changeHandler={this.onChangeHandler}
                style={itemStyle}
            />
            }

          </div>
          <div style={buttonContainerStyle}>
            <Button value="Save" click={this.onSave} />
            <Button value="Cancel" click={() => this.props.toggleAdd("Edit")} />
          </div>
        </div>
      </div>
    );
  }
}

export default AddItem;
