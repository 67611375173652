import React, { Component} from 'react';
import {connect} from 'react-redux';
import NavigationPanel from "./navigation-panel/navigation-panel";
import MainComponent from "../home/main/main";
import "./master.less";
import SapModule from "./SAP_module/sap_module";
import Fuel from "./fuel/fuel";
import Checkpoint from "./checkpoint/checkpoint";
import Contract from "./contract/contract";
import DisputedLocation from "./disputed_location/disputed_location";
import Indent from "./indent/indent";
import Inventory from "./inventory/inventory";
import ACTION from "../common/action_constant";
import MessagePopUp from "../common/components/msg-popup/msg-popup";
import Loader from  "../common/components/loader/loader";
import AccountMaster from "./accountmaster/accountmaster";
import { getCookie } from "../../utils/cookies";
import TransporterList from "./transporters_mapping/transporters_mapping";
import VehiclePlanning from './vehicle_planning/Vehicle_Planning'
import MasterDropDown from './masterdropdown/masterDropDown';

class Master extends Component {
	constructor(props){
		super(props);
		this.state = {
			selectedSubTab : 1,
			selectedMainTab : 1
		}
	}

	selectedTab = (num) => {
		this.setState({
			selectedMainTab : num
		});
	}

	selectedSubTab = (num) => {
		this.setState({
			selectedSubTab : num
		})
	}

	generateRouteExcel = (data) => {
		const { dispatch } = this.props;
		dispatch({type : ACTION.MASTER.SAP_MODULE.GENERATE_ROUTE_EXCEL, data : data});
	}


	closeMsgPopup = () =>{
		const {dispatch} = this.props;
		dispatch({ type : ACTION.MSG_DISPLAY.HIDE_MSG })
	}

	exportRouteExcel = (data) => {
		const { dispatch } = this.props;
		dispatch({type : ACTION.MASTER.SAP_MODULE.EXPORT_ROUTE_EXCEL, data : data});
	}

	componentDidMount(){
		let params = "?type="+"1"+"&limit=50";
		const { dispatch } = this.props;
		dispatch({ type : ACTION.HOME.GET_HOME,data : params });
		this.getTransporters();
	}

	getTransporters = (offset = 0, search, companyTypeTo, isMultimode, isWarehouse, isExim) => {

		const company_type = JSON.parse(getCookie('user')).company_type;
		const { dispatch } = this.props;
		const param = {
			offset: offset,
			limit: 20,
			companyType: company_type,
			companyTypeTo: companyTypeTo,
			isMultimode : isMultimode || false,
			isWarehouse: isWarehouse,
			isExim: isExim
		}
		if(search) {
			param.search = search
		}
		dispatch({ type : ACTION.COMPANY_ACCOUNTS.GET_TRANSPORTER_LIST, param });
	}

	getCommonConstants = () => {
		const { dispatch } = this.props;
		dispatch({ type : ACTION.MASTER.INVENTORY_ITEM.GET_COMMON_CONSTANTS });
	}

	getInventoryItem = (data) => {
		const company_id = JSON.parse(getCookie('user')).company_id;
		let param = `${company_id}&count=${data.count}&offset=${data.offset}`;
		if(data.search) {
			param += `&search=${data.search}`;
		}
		const { dispatch } = this.props;
		dispatch({ type : ACTION.MASTER.INVENTORY_ITEM.GET_INVENTORY_ITEM, param, company_id });
	}
// Truck Master
	getTruckDetails = (data) => {
		const company_id = JSON.parse(getCookie('user')).company_id;
		let param = `${company_id}&limit=${data.limit}&offset=${data.offset}`;
		if(data.search) {
			param += `&search=${data.search}`;
		}
		const { dispatch } = this.props;
		dispatch({ type : ACTION.MASTER.VEHICLE_PLANNING.GET_TRUCK_DETAILS, param, company_id });
	}

	
	addTruckDetails = (data) => {
		const { dispatch } = this.props;
		data.company_id = JSON.parse(getCookie('user')).company_id;
		dispatch({ type : ACTION.MASTER.VEHICLE_PLANNING.ADD_TRUCK_DETAILS, data });
	}

	updateTruckDetails = (data) => {
		
		const { dispatch } = this.props;
		data.company_id = JSON.parse(getCookie('user')).company_id;
		dispatch({ type : ACTION.MASTER.VEHICLE_PLANNING.UPDATE_TRUCK_DETAILS, data });
	}
	
	deleteTruckDetails = (data) => {
		const { dispatch } = this.props;
		const company_id = JSON.parse(getCookie('user')).company_id;
		dispatch({ type : ACTION.MASTER.VEHICLE_PLANNING.DELETE_TRUCK_DETAILS, data,company_id });
	}
	
	uploadTruckDetails = (data) => {
		const company_id = JSON.parse(getCookie('user')).company_id;
		const { dispatch } = this.props;
		dispatch({ type : ACTION.MASTER.VEHICLE_PLANNING.UPLOAD_TRUCK_DETAILS, data, company_id });
	}
	downloadTruckDetails = () => {
		const company_id = JSON.parse(getCookie('user')).company_id;
		const { dispatch } = this.props;
		dispatch({ type : ACTION.MASTER.VEHICLE_PLANNING.DOWNLOAD_TRUCK_DETAILS, company_id });
	}
	downloadTruckTemp = () => {
		const company_id = JSON.parse(getCookie('user')).company_id;
		const { dispatch } = this.props;
		dispatch({ type : ACTION.MASTER.VEHICLE_PLANNING.DOWNLOAD_TRUCK_TEMP, company_id });
	}
	

  getVehicleSize = () => {
    const { dispatch } = this.props;
    dispatch({type: ACTION.MASTER.VEHICLE_PLANNING.GET_VEHICLE_SIZE_DETAILS });
  }
  getTransporterDetails = () => {
    const { dispatch } = this.props;
    dispatch({type: ACTION.MASTER.VEHICLE_PLANNING.GET_TRANSPORTER_DETAILS });
  }

  getTruckDriverDetails = (data) => {
	const company_id = JSON.parse(getCookie('user')).company_id;
	let param = `${company_id}&limit=${data.limit}&offset=${data.offset}`;
	if(data.search) {
		param += `&search=${data.search}`;
	}
	const { dispatch } = this.props;
	dispatch({ type : ACTION.MASTER.VEHICLE_PLANNING. GET_TRUCK_DRIVER_DETAILS, param, company_id });
}

// SKU Master
	getSkuDetails = (data) => {
		const company_id = JSON.parse(getCookie('user')).company_id;
		let param = `${company_id}&limit=${data.limit}&offset=${data.offset}`;
		if(data.search) {
			param += `&search=${data.search}`;
		}
		const { dispatch } = this.props;
		dispatch({ type : ACTION.MASTER.VEHICLE_PLANNING.GET_SKU_DETAILS, param, company_id });
	}

	addSkuDetails = (data) => {
		const { dispatch } = this.props;
		data.company_id = JSON.parse(getCookie('user')).company_id;
		dispatch({ type : ACTION.MASTER.VEHICLE_PLANNING.ADD_SKU_DETAILS, data });
	}



	
	updateSkuDetails = (data) => {
		const { dispatch } = this.props;
		data.company_id = JSON.parse(getCookie('user')).company_id;
		dispatch({ type : ACTION.MASTER.VEHICLE_PLANNING.UPDATE_SKU_DETAILS, data });
	}

	downloadSkuDetails = () => {
		const company_id = JSON.parse(getCookie('user')).company_id;
		const { dispatch } = this.props;
		dispatch({ type : ACTION.MASTER.VEHICLE_PLANNING.DOWNLOAD_SKU_DETAILS, company_id });
	}
	downloadSkuTemp = () => {
		const company_id = JSON.parse(getCookie('user')).company_id;
		const { dispatch } = this.props;
		dispatch({ type : ACTION.MASTER.VEHICLE_PLANNING.DOWNLOAD_SKU_TEMP, company_id });
	}
	uploadSkuDetails = (data) => {
		const company_id = JSON.parse(getCookie('user')).company_id;
		const { dispatch } = this.props;
		dispatch({ type : ACTION.MASTER.VEHICLE_PLANNING.UPLOAD_SKU_DETAILS, data, company_id });
	}

	deleteSkuDetails = (data) => {
		const { dispatch } = this.props;
		const company_id = JSON.parse(getCookie('user')).company_id;
		dispatch({ type : ACTION.MASTER.VEHICLE_PLANNING.DELETE_SKU_DETAILS, data,company_id});
	}
// Driver Master

getDriverDetails = (data) => {
	const company_id = JSON.parse(getCookie('user')).company_id;
	let param = `${company_id}&limit=${data.limit}&offset=${data.offset}`;
	if(data.search) {
		param += `&search=${data.search}`;
	}
	const { dispatch } = this.props;
	dispatch({ type : ACTION.MASTER.VEHICLE_PLANNING.GET_DRIVER_DETAILS, param, company_id });
}

addDriverDetails = (data) => {
	const { dispatch } = this.props;
	data.company_id = JSON.parse(getCookie('user')).company_id;
	dispatch({ type : ACTION.MASTER.VEHICLE_PLANNING.ADD_DRIVER_DETAILS, data });
}

updateDriverDetails = (data) => {
	const { dispatch } = this.props;
	data.company_id = JSON.parse(getCookie('user')).company_id;
	dispatch({ type : ACTION.MASTER.VEHICLE_PLANNING.UPDATE_DRIVER_DETAILS, data });
}

downloadDriverDetails = () => {
	const company_id = JSON.parse(getCookie('user')).company_id;
	const { dispatch } = this.props;
	dispatch({ type : ACTION.MASTER.VEHICLE_PLANNING.DOWNLOAD_DRIVER_DETAILS, company_id });
}
downloadDriverTemp = () => {
	const company_id = JSON.parse(getCookie('user')).company_id;
	const { dispatch } = this.props;
	dispatch({ type : ACTION.MASTER.VEHICLE_PLANNING.DOWNLOAD_DRIVER_TEMP, company_id });
}
uploadDriverDetails = (data) => {
	const company_id = JSON.parse(getCookie('user')).company_id;
	const { dispatch } = this.props;
	dispatch({ type : ACTION.MASTER.VEHICLE_PLANNING.UPLOAD_DRIVER_DETAILS, data, company_id });
}


deleteDriverDetails = (data,driverdata) => {
	const company_id = JSON.parse(getCookie('user')).company_id;
	let driver= driverdata.driver_id;
	const { dispatch } = this.props;
	dispatch({ type :  ACTION.MASTER.VEHICLE_PLANNING.DELETE_DRIVER_DETAILS,company_id,driver});
}




	addInventoryItem = (data) => {
		const { dispatch } = this.props;
		data.company_id = JSON.parse(getCookie('user')).company_id;
		dispatch({ type : ACTION.MASTER.INVENTORY_ITEM.ADD_INVENTORY_ITEM, data });
	}

	updateInventoryItem = (data) => {
		const { dispatch } = this.props;
		data.company_id = JSON.parse(getCookie('user')).company_id;
		dispatch({ type : ACTION.MASTER.INVENTORY_ITEM.UPDATE_INVENTORY_ITEM, data });
	}

	deleteInventoryItem = (data) => {
		const company_id = JSON.parse(getCookie('user')).company_id;
		const { dispatch } = this.props;
		dispatch({ type : ACTION.MASTER.INVENTORY_ITEM.DELETE_INVENTORY_ITEM, data, company_id });
	}

	downloadInventoryItem = () => {
		const company_id = JSON.parse(getCookie('user')).company_id;
		const { dispatch } = this.props;
		dispatch({ type : ACTION.MASTER.INVENTORY_ITEM.DOWNLOAD_INVENTORY_ITEM, company_id });
	}

	uploadInventoryItem = (data) => {
		const company_id = JSON.parse(getCookie('user')).company_id;
		const { dispatch } = this.props;
		dispatch({ type : ACTION.MASTER.INVENTORY_ITEM.UPLOAD_INVENTORY_ITEM, data, company_id });
	}

	uploadRateChart = (data) => {
		const user = JSON.parse(getCookie('user'));
		const company_id = user.company_id;
		const { dispatch } = this.props;
		dispatch({ type : ACTION.MASTER.INVENTORY_ITEM.UPLOAD_RATE_CHART, data, company_id });
	}

	downloadRateChart = () => {
		const company_id = JSON.parse(getCookie('user')).company_id;
		const { dispatch } = this.props;
		dispatch({ type : ACTION.MASTER.INVENTORY_ITEM.DOWNLOAD_RATE_CHART, company_id });
	}

	getPackagingDetails = () => {
		const { dispatch } = this.props;
		dispatch({ type : ACTION.MASTER.INVENTORY_ITEM.GET_PACKAGING_DETAILS });
	}

	addCompanyTransporters = (data, type) => {
		const { dispatch } = this.props;
		const company_id = JSON.parse(getCookie('user')).company_id;
		this.setState({ isSuccess: false });
		dispatch({ 
			type: ACTION.MASTER.TRANSPORTERS.ADD_COMPANY_TRANSPORTERS, 
			data, 
			companyId: company_id, 
			branchId: company_id,
			companyTypeToAdd: type,
			onSuccess: () => {
				this.setState({ isSuccess : true });
			}
		});
	}

	getCompanyTransporters = (isMultimode, isWarehouse, isExim) => {
		const { dispatch } = this.props;
		const company_id = JSON.parse(getCookie('user')).company_id;
		dispatch({ type: ACTION.MASTER.TRANSPORTERS.GET_COMPANY_TRANSPORTERS , companyId: company_id, branchId: company_id, isMultimode: isMultimode && 2 || undefined, isWarehouse: isWarehouse && 3 || undefined, isExim: isExim && 4 || undefined });
	}

	onDeleteTransporter = (id, type, isMultimode, isWarehouse, isExim) => {
		const { dispatch } = this.props;
		const company_id = JSON.parse(getCookie('user')).company_id;
		dispatch({ type: ACTION.MASTER.TRANSPORTERS.DELETE_COMPANY_TRANSPORTERS , companyId: company_id, branchId: company_id , transporterId: id, companyTypeToDelete : type, isMultimode: isMultimode && 2 || undefined, isWarehouse: isWarehouse && 3 || undefined, isExim: isExim && 4 || undefined });
	}

	getAllBranch = () => {
		const { dispatch } = this.props;
		dispatch({ 
		  type : ACTION.PLANTS.GET_ALL_BRANCH,
		  data:{
			companyId: JSON.parse(getCookie('user')).company_id,
			companyType: JSON.parse(getCookie('user')).company_type
		  }
		});
	}

	getRateChartDetails = (val, onStoreData) => {
		const { dispatch } = this.props;
		dispatch({
			type: ACTION.MASTER.INVENTORY_ITEM.GET_PRODUCT_RATE_CHART_DETAILS,
			data: {
				companyId: JSON.parse(getCookie('user')).company_id,
				productMasterId: val.productMasterId
			},
			onSuccess: (data) => {
				onStoreData(data, val);
			}
		})
	}

	onAddRateChart = (postData, val, onStoreData) => {
		const { dispatch } = this.props;
		dispatch({
			type: ACTION.MASTER.INVENTORY_ITEM.ADD_PRODUCT_RATE_CHART_DETAILS,
			data: {
				company_id: JSON.parse(getCookie('user')).company_id,
				...postData
			},
			onSuccess: () => {
				this.getRateChartDetails(val, onStoreData);
			}
		});
	}

	onDeleteRate = (postData, val, onStoreData) => {
		const { dispatch } = this.props;
		dispatch({
			type: ACTION.MASTER.INVENTORY_ITEM.DELETE_PRODUCT_RATE_CHART_DETAILS,
			data: {
				company_id: postData.company_id,
				product_rate_id: postData.product_rate_id
			},
			onSuccess: () => {
				this.getRateChartDetails(val, onStoreData);
			}
		});
	}

	onUpdateRateChart = (postData, val, onStoreData) => {
		const { dispatch } = this.props;
		dispatch({
			type: ACTION.MASTER.INVENTORY_ITEM.UPDATE_PRODUCT_RATE_CHART_DETAILS,
			data: {
				...postData
			},
			onSuccess: () => {
				this.getRateChartDetails(val, onStoreData);
			}
		});
	}
	
	
	render() {
		const { transporterList, master } = this.props;
		const company_type = JSON.parse(getCookie('user')).company_type;

		return (
			<div className = "master">
				<MainComponent>
					{
						this.props.msgpopup.active && <MessagePopUp close = {this.closeMsgPopup} type = {this.props.msgpopup.type} msg = {this.props.msgpopup.msg}/>
					}
					{
						this.props.loader.isLoading && <Loader/>
					}
					<NavigationPanel selectedSubTab = {this.selectedSubTab} selectedTab = {this.selectedTab} /> 
					{this.state.selectedMainTab == 1 && <SapModule companyList = {this.props.home && this.props.home.homeDetails} selectedSubTab = {this.state.selectedSubTab} generateRouteExcel = {this.generateRouteExcel} exportRouteExcel = {this.exportRouteExcel}/> }
					{this.state.selectedMainTab == 2 && <Fuel/>}
					{this.state.selectedMainTab == 3 && <Checkpoint/>}
					{this.state.selectedMainTab == 4 && <Inventory 
															getInventoryItem={this.getInventoryItem}
															getCommonConstants={this.getCommonConstants}
															addInventoryItem={this.addInventoryItem}
															updateInventoryItem={this.updateInventoryItem}
															itemData={this.props.master.itemList}
															deleteInventoryItem={this.deleteInventoryItem}
															downloadInventoryItem={this.downloadInventoryItem}
															downloadUrl={this.props.master.dowlUrl}
															uploadInventoryItem={this.uploadInventoryItem}
															getPackagingDetails={this.getPackagingDetails}
															packagingDetails={this.props.master.packagingDetails}
															uploadRateChart={this.uploadRateChart}
															downloadRateChart={this.downloadRateChart}
															getRateChartDetails={this.getRateChartDetails}
															onAddRateChart={this.onAddRateChart}
															onDeleteRate={this.onDeleteRate}
															onUpdateRateChart={this.onUpdateRateChart}
															/>}
					{this.state.selectedMainTab == 5 && <DisputedLocation/>}
					{this.state.selectedMainTab == 6 && <Contract/>}
					{this.state.selectedMainTab == 7 && <Indent/>}
					{this.state.selectedMainTab == 8 && <AccountMaster/> }
					{this.state.selectedMainTab == 9 && <TransporterList 
															transporterList={transporterList} 
															addCompanyTransporters={this.addCompanyTransporters} 
															getCompanyTransporters={this.getCompanyTransporters}
															getTransporters={this.getTransporters}
															companyTransporters={master.companyTransporters}
															onDeleteTransporter={this.onDeleteTransporter}
															heading={'Transporters'}
															subHeading={'My Transporters'}
															isSuccess={this.state.isSuccess}
															company_type={company_type}
														/>}
					{this.state.selectedMainTab == 10 && <VehiclePlanning 
						// Truck Master
						selectedSubTab = {this.state.selectedSubTab}  
						  truck={this.props.master.truckData}  
						  getTruckDetails={this.getTruckDetails} 
						  addTruckDetails={this.addTruckDetails}
						  updateTruckDetails={this.updateTruckDetails}
						  deleteTruckDetails={this.deleteTruckDetails}
						  uploadTruckDetails={this.uploadTruckDetails}
						  downloadTruckDetails={this.downloadTruckDetails}
						  downloadUrl={this.props.master.downloadUrl}
						  downloadTruckTemp={this.downloadTruckTemp}
						  downloadTempUrl={this.props.master.downloadTempUrl}
						  getTransporterDetails={this.getTransporterDetails}
						  getVehicleSize={this.getVehicleSize}
						  vehicleSize={this.props.master.vehicleSizeData}
						  getCompanyTransporters={this.getCompanyTransporters}
						  companyTransporters ={this.props.master.companyTransporters }
						  vehicleSizeData={this.props.master.vehicleSizeData}
						  getTruckDriverDetails={this.getTruckDriverDetails}
						  driverData={this.props.master.driverData}
						  uploadfiles={this.uploadfiles}
						  attachments={this.state.attachments}
						  getAllBranch={this.getAllBranch}
						  allPlantBranches={this.props.plantsDepots.allPlantBranches}

						//   SKU Master
						  getSkuDetails={this.getSkuDetails}
						  sku={this.props.master.skuData}  
						  addSkuDetails={this.addSkuDetails}
						  updateSkuDetails={this.updateSkuDetails}
						  downloadSkuDetails={this.downloadSkuDetails}
						  downloadSkuUrl={this.props.master.downloadSkuUrl}
						  downloadSkuTemp={this.downloadSkuTemp}
						  downloadSkuTempUrl={this.props.master.downloadSkuTempUrl}
						  deleteSkuDetails={this.deleteSkuDetails}
						  uploadSkuDetails={this.uploadSkuDetails}

						//   Driver Master
						getDriverDetails={this.getDriverDetails}
						addDriverDetails={this.addDriverDetails}
						updateDriverDetails={this.updateDriverDetails}
						deleteDriverDetails={this.deleteDriverDetails}
						downloadDriverDetails={this.downloadDriverDetails}
						downloadDriverUrl={this.props.master.downloadDriverUrl}
						uploadDriverDetails={this.uploadDriverDetails}
						downloadDriverTemp={this.downloadDriverTemp}
						driverTempUrl={this.props.master.downloadTempUrl}
						  
						  /> }

						  {
							this.state.selectedMainTab == 11 && <MasterDropDown />
						  }
				</MainComponent>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		master: state.master,
		home: state.home,
		loader : state.loader,
		msgpopup : state.msgpopup,
		transporterList : state.companyAccounts.transporterList,
		plantsDepots : state.plantsDepots,
	};
};
  
export default connect(mapStateToProps)(Master);