import React, { Component } from 'react';
import "./add-company.less";
import Button from "../button-classic/button-classic";
import { getEmail, isEmpty } from "../../../utils/common";
import InputText from "../../common/components/input-text/input-text";
import { legthValidation, validationCheck, generatePassword } from "../../../utils/validate";
import Select from 'react-select';
import { options } from 'winston-daily-rotate-file';

class AddCompany extends Component {
	constructor(props) {
		super(props);
		this.state = {
			company_name: "",
			email: "",
			password: "",
			contact: "",
			pan_no: "",
			admin_name: "",
			company_type: 0,
			service_type: [],
			category_type: [],
			error: null,
			iserror: '',
			errormessage: '',
			is_multimode: false,
			is_warehouse: false,
			is_exim: false,
			modeOfTransport: []
		}
	}
	onChangeHandler = (value, id, isType) => {
		const result = validationCheck(value, this.state[id], isType);
		const errMsg = legthValidation(id, value);
		this.setState({ [id]: result, errormessage: errMsg });
	}

	onBlurChange = (e) => {
		const errMsg = legthValidation(e.target.id, e.target.value);
		if (errMsg && e.target.value) {
			this.setState({
				iserror: e.target.id,
				errormessage: errMsg
			})
		} else if (this.state.iserror) {
			this.setState({ iserror: '' });
		}
	}

	passwordGenerate = () => {
		let pwd = generatePassword();
		this.setState({ password: pwd });
	}

	clickCustomeCheckBoxCompanyType = (id) => {
		this.setState({
			company_type: id,
			category_type: []
		})
	}
	clickCustomeCheckBoxCategory = (id) => {
		// let newCategory = [];
		let category_type = JSON.parse(JSON.stringify(this.state.category_type));
		if (!this.findCategoryActive(id)) {
			// for (let i = 0; i < category_type.length; i++) {
			// 	if (category_type[i].company_type != id) {
			// 		newCategory.push(category_type[i])
			// 	}
			// }
			category_type.push(categories.find(category => category.company_type === id) || {})
		}  else {
			category_type.splice(category_type.findIndex(category => category.company_type === id), 1);
		}
		// else {
		// 	newCategory = category_type;
		// 	let temp = {
		// 		id,
		// 		name: categories[id].name
		// 	}
		// 	newCategory.push(temp)
		// }
		this.setState({
			category_type
		})
	}
	findCategoryActive = (id) => {
		const category_type = this.state.category_type.slice();
		// for (let i = 0; i < category_type.length; i++) {
		// 	if (category_type[i].company_type == id)
		// 		return true;
		// }
		if(category_type.find(category => category.company_type === id)) {
			return true;
		}
		return false;
	}
	clickCustomeCheckBoxService = (id) => {
		let newService = [];
		let service_type = this.state.service_type.slice();
		if (this.findServiceActive(id)) {
			for (let i = 0; i < service_type.length; i++) {
				if (service_type[i].id != id) {
					newService.push(service_type[i])
				}
			}
		} else {
			newService = service_type;
			let temp = {
				id,
				name: services[id].name
			}
			newService.push(temp)
		}
		this.setState({
			service_type: newService
		})
	}
	findServiceActive = (id) => {
		const service_type = this.state.service_type.slice();
		for (let i = 0; i < service_type.length; i++) {
			if (service_type[i].id == id)
				return true;
		}
		return false;
	}
	addCompanySubmit = (e) => {
		e.preventDefault();
		let error = null;
		let data = Object.assign({}, this.state);
		data.master_type = 3;
		data.user_email = getEmail();
		data.new_flag = true;
		if (data.service_type.length == 0 && !(this.state.is_multimode || this.state.is_warehouse || this.state.is_exim)) {
			error = "Please select at least one interested service"
		}
		if (data.company_type == 2 && data.category_type.length == 0) {
			error = "Please select at least one relevant category"
		}
		if (data.company_type == 0) {
			error = "Please select a company type"
		}
		Object.keys(data).forEach((item) => {
			if (item != "company_type" && item != "service_type" && item != "category_type" && item != "iserror" && item != "errormessage" && item != "is_multimode" && item != "is_warehouse" && item != "modeOfTransport" && item != "is_exim") {
				if (isEmpty(data[item])) {
					error = "Incomplete details"
				}
			}
		})
		this.setState({
			error
		})
		if (!error) {
			delete data.error;
			delete data.iserror;
			delete data.errormessage;
			if (data.company_type == 1)
				delete data.category_type;
			if(data.category_type) {
				data['additional_service_type'] = JSON.parse(JSON.stringify(data.category_type));
				data.category_type = (data.category_type.filter(category => category.id) || []).map(val =>({ name: val.name, id: val.id }));
			}
			this.props.submit(data);
		}
	}
	render() {
		const { props, state } = this;
		return (
			<div className="add-company-wrap">
				<div onClick={props.close} className="overlay"></div>
				<div className="modal-popup">
					<div className="heading">
						Add New Company
                    </div>
					<form className="form-section input-text-align-top" autoComplete="nopes">
						<InputText placeholder="Company Name" id={"company_name"} label="Company Name" value={state.company_name} changeHandler={this.onChangeHandler} />
						<InputText placeholder="PAN No." id={"pan_no"} label="PAN No." value={state.pan_no} changeHandler={this.onChangeHandler} length={'10'} isValidate={'alphanumeric'} additionalProps={{ onBlur: this.onBlurChange }} iserror={state.iserror} errormessage={state.errormessage} />
						<InputText placeholder="Admin User Name" id={"admin_name"} label="Admin User Name" value={state.admin_name} changeHandler={this.onChangeHandler} />
						<InputText placeholder="Work Email" id={"email"} label="Work Email" value={state.email} changeHandler={this.onChangeHandler} additionalProps={{ onBlur: this.onBlurChange }} iserror={state.iserror} errormessage={state.errormessage} />
						<InputText placeholder="Mobile No." id={"contact"} label="Mobile No." value={state.contact} changeHandler={this.onChangeHandler} length={'10'} isValidate={'numeric'} additionalProps={{ onBlur: this.onBlurChange }} iserror={state.iserror} errormessage={state.errormessage} />
						<InputText placeholder="Password" id={"password"} label="Password" value={state.password} changeHandler={this.onChangeHandler} isGenerate={'true'} generate={this.passwordGenerate} />
					</form>
					<div className="select-relevant-category-section">
						<div className="header">
							Select Company Type
                        </div>
						{this.props.enableList && this.props.enableList.indexOf('1') > -1 && <div className="section-item-company">
							<CustomeCheckBox id={1} toggle={this.clickCustomeCheckBoxCompanyType} selected={(state.company_type == 1 ? true : false)} />
							<div className="title"> Seeker</div>
						</div>
					    }
						{this.props.enableList && this.props.enableList.indexOf('2') > -1 && <div className="section-item-company">
							<CustomeCheckBox id={2} toggle={this.clickCustomeCheckBoxCompanyType} selected={(state.company_type == 2 ? true : false)} />
							<div className="title"> Provider</div>
						</div>
						}
						{this.props.enableList && this.props.enableList.indexOf('3') > -1 && <div className="section-item-company">
							<CustomeCheckBox id={3} toggle={this.clickCustomeCheckBoxCompanyType} selected={(state.company_type == 3 ? true : false)} />
							<div className="title"> 3PL(Third-party Logistics)</div>
						</div>
						}
						{this.props.enableList && this.props.enableList.indexOf('4') > -1 && <div className="section-item-company">
							<CustomeCheckBox id={4} toggle={this.clickCustomeCheckBoxCompanyType} selected={(state.company_type == 4 ? true : false)} />
							<div className="title"> Fleet Owner & Broker</div>
						</div>
						}
						{this.props.enableList && this.props.enableList.indexOf('5') > -1 && <div className="section-item-company">
							<CustomeCheckBox id={5} toggle={this.clickCustomeCheckBoxCompanyType} selected={(state.company_type == 5 ? true : false)} />
							<div className="title"> Fleet Owner</div>
						</div>
						}
						{this.props.enableList && this.props.enableList.indexOf('6') > -1 && <div className="section-item-company">
							<CustomeCheckBox id={6} toggle={this.clickCustomeCheckBoxCompanyType} selected={(state.company_type == 6 ? true : false)} />
							<div className="title"> Broker</div>
						</div>
						}
						{this.props.enableList && this.props.enableList.indexOf('7') > -1 && <div className="section-item-company">
							<CustomeCheckBox id={7} toggle={this.clickCustomeCheckBoxCompanyType} selected={(state.company_type == 7 ? true : false)} />
							<div className="title"> Fleet Owner & Transporter</div>
						</div>
						}
						{this.props.enableList && this.props.enableList.indexOf('9') > -1 && <div className="section-item-company">
							<CustomeCheckBox id={9} toggle={this.clickCustomeCheckBoxCompanyType} selected={(state.company_type == 9 ? true : false)} />
							<div className="title"> Surveyors</div>
						</div>
						}
						{this.props.enableList && this.props.enableList.indexOf('10') > -1 && <div className="section-item-company">
							<CustomeCheckBox id={10} toggle={this.clickCustomeCheckBoxCompanyType} selected={(state.company_type == 10 ? true : false)} />
							<div className="title"> Lashers</div>
						</div>
						}
						{this.props.enableList && this.props.enableList.indexOf('21') > -1 && <div className="section-item-company">
							<CustomeCheckBox id={21} toggle={this.clickCustomeCheckBoxCompanyType} selected={(state.company_type == 21 ? true : false)} />
							<div className="title"> Handling Agent</div>
						</div>
						}
						{this.props.enableList && this.props.enableList.indexOf('22') > -1 && <div className="section-item-company">
							<CustomeCheckBox id={22} toggle={this.clickCustomeCheckBoxCompanyType} selected={(state.company_type == 22 ? true : false)} />
							<div className="title"> Shipping Liners</div>
						</div>
						}
						{this.props.enableList && this.props.enableList.indexOf('11') > -1 && <div className="section-item-company">
							<CustomeCheckBox id={11} toggle={this.clickCustomeCheckBoxCompanyType} selected={(state.company_type == 11 ? true : false)} />
							<div className="title">Fleet Owner and Company</div>
						</div>
						}
						{this.props.enableList && this.props.enableList.indexOf('8') > -1 && <div className="section-item-company">
							<CustomeCheckBox id={8} toggle={this.clickCustomeCheckBoxCompanyType} selected={(state.company_type == 8 ? true : false)} />
							<div className="title">Multi Service</div>
						</div>
						}
						{this.props.enableList && this.props.enableList.indexOf('24') > -1 && <div className="section-item-company">
							<CustomeCheckBox id={24} toggle={this.clickCustomeCheckBoxCompanyType} selected={(state.company_type == 24 ? true : false)} />
							<div className="title">Buyer</div>
						</div>
						}
						{this.props.enableList && this.props.enableList.indexOf('14') > -1 && <div className="section-item-company">
							<CustomeCheckBox id={14} toggle={this.clickCustomeCheckBoxCompanyType} selected={(state.company_type == 14 ? true : false)} />
							<div className="title">CFS</div>
						</div>
						}
						{this.props.enableList && this.props.enableList.indexOf('13') > -1 && <div className="section-item-company">
							<CustomeCheckBox id={13} toggle={this.clickCustomeCheckBoxCompanyType} selected={(state.company_type == 13 ? true : false)} />
							<div className="title">CHA</div>
						</div>
						}
						{this.props.enableList && this.props.enableList.indexOf('27') > -1 && <div className="section-item-company">
							<CustomeCheckBox id={27} toggle={this.clickCustomeCheckBoxCompanyType} selected={(state.company_type == 27 ? true : false)} />
							<div className="title">Freight Forwarder</div>
						</div>
						}
						{/* {this.props.enableList && this.props.enableList.indexOf('23') > -1 && <div className="section-item-company">
							<CustomeCheckBox id={23} toggle={this.clickCustomeCheckBoxCompanyType} selected={(state.company_type == 23 ? true : false)} />
							<div className="title">Warehouse</div>
						</div>
						} */}
					</div>
					{[2, 8, 23].includes(state.company_type) && <div className="select-relevant-category-section sub-category">
						<div className="header">
							Select The Category
                        </div>
						{((state.company_type === 8 ? (categories || []).filter(category => category.company_type !== 2) : ( state.company_type === 23 ? warehouseServices : categories)) || []).map(category => {
							return (
								<div className="section-item">
									<CustomeCheckBox id={category.company_type} toggle={this.clickCustomeCheckBoxCategory} selected={this.findCategoryActive(category.company_type)} />
									<div className="title"> {category.name}</div>
								</div>
							)
						})
						}
						{/* <div className="section-item">
							<CustomeCheckBox id={categories[5].id} toggle={this.clickCustomeCheckBoxCategory} selected={this.findCategoryActive(categories[5].id)} />
							<div className="title"> {categories[5].name}</div>
						</div>
						<div className="section-item">
							<CustomeCheckBox id={categories[3].id} toggle={this.clickCustomeCheckBoxCategory} selected={this.findCategoryActive(categories[3].id)} />
							<div className="title"> {categories[3].name}</div>
						</div>
						<div className="section-item">
							<CustomeCheckBox id={categories[4].id} toggle={this.clickCustomeCheckBoxCategory} selected={this.findCategoryActive(categories[4].id)} />
							<div className="title"> {categories[4].name}</div>
						</div>
						<div className="section-item">
							<CustomeCheckBox id={categories[1].id} toggle={this.clickCustomeCheckBoxCategory} selected={this.findCategoryActive(categories[1].id)} />
							<div className="title"> {categories[1].name}</div>
						</div>
						<div className="section-item">
							<CustomeCheckBox id={categories[2].id} toggle={this.clickCustomeCheckBoxCategory} selected={this.findCategoryActive(categories[2].id)} />
							<div className="title"> {categories[2].name}</div> 
						</div> */}
					</div>}
					<div className="select-relevant-category-section  sub-services">
						<div className="header">
							Select Interested Services
                        </div>
						<div className="section-item all">
							<CustomeCheckBox id={services[5].id} toggle={this.clickCustomeCheckBoxService} selected={this.findServiceActive(services[5].id)} />
							<div className="title"> {services[5].name}</div>
						</div>
						<div className="section-item width-120">
							<CustomeCheckBox id={services[3].id} toggle={this.clickCustomeCheckBoxService} selected={this.findServiceActive(services[3].id)} />
							<div className="title"> {services[3].name}</div>
						</div>
						<div className="section-item">
							<CustomeCheckBox id={services[2].id} toggle={this.clickCustomeCheckBoxService} selected={this.findServiceActive(services[2].id)} />
							<div className="title"> {services[2].name}</div>
						</div>
						<div className="section-item">
							<CustomeCheckBox id={services[1].id} toggle={this.clickCustomeCheckBoxService} selected={this.findServiceActive(services[1].id)} />
							<div className="title"> {services[1].name}</div>
						</div>
						<div className="section-item">
							<CustomeCheckBox id={services[4].id} toggle={this.clickCustomeCheckBoxService} selected={this.findServiceActive(services[4].id)} />
							<div className="title"> {services[4].name}</div>
						</div>
						<div className="section-item">
							<CustomeCheckBox toggle={() => this.setState({ is_multimode: !this.state.is_multimode })} selected={this.state.is_multimode} />
							<div className="title"> {'Multi Mode'}</div>
						</div>
						<div className="section-item">
							<CustomeCheckBox toggle={() => this.setState({ is_warehouse: !this.state.is_warehouse })} selected={this.state.is_warehouse} />
							<div className="title"> {'Warehouse'}</div>
						</div>
						<div className="section-item">
							<CustomeCheckBox toggle={() => this.setState({ is_exim: !this.state.is_exim })} selected={this.state.is_exim} />
							<div className="title"> {'EXIM'}</div>
						</div>
					</div>
					{state.company_type == 2 && <div>
						<div className="header">
							Mode Of Transport
                        </div>
						<Select 
							isMulti
							values={state.modeOfTransport}
							options={props.modeOfTransportList}
							getOptionLabel={(Option) => Option.name}
							getOptionValue={(Option) => Option.id}
							onChange={(options) => this.setState({ modeOfTransport: options })}
						/>
					</div>}
					<div>
						{state.error && <div className="error-section">{state.error}</div>}
						<div className="submit-section">
							<div className="button-section">
								<Button value="Save" click={this.addCompanySubmit} />
							</div>
						<div className="button-section">
								<Button value="Cancel" click={props.close} />
							</div>
						</div>
						
					</div>
				</div>
			</div>
		);
	}
}



const CustomeCheckBox = (props) => {
	return (
		<div className={props.selected ? "custome-checkbox-wrap selected" : "custome-checkbox-wrap"} onClick={() => props.toggle(props.id)}>
			{props.selected && <div className="inner-circle"></div>}
		</div>
	)
}

// const categories = [
// 	{
// 		name: "Transporter",
// 		id: 1, 
// 	},
// 	{
// 		name: "Fleet Owner & Transporter",
// 		id: 2
// 	},
// 	{
// 		name: "Fleet Owner",
// 		id: 3
// 	},
// 	{
// 		name: "Broker",
// 		id: 4
// 	},
// 	{
// 		name: "Fleet owner & broker",
// 		id: 5
// 	}
// ]

const categories = [
{
	name: "Transporter",
	id: 1,
	company_type: 2
},
{
	name: "3 PL(Third - party Logistics)",
	company_type: 3
},
{
	name: "Fleet Owner & Broker",
	company_type: 4
},
{
	name: "Fleet Owner",
	company_type: 5,
	id: 3
},
{
	name: "Broker",
	company_type: 6,
	id: 4
},
{
	name: "Fleet Owner & Transporter",
	company_type: 7,
	id: 2
},
{
	name: "Surveyors",
	company_type: 9
},
{
	name: "Lashers",
	company_type: 10
},
{
	name: "Handling Agent",
	company_type: 21
},
{
	name: "Shipping Liners",
	company_type: 22,
},
{
	name: "Fleet Owner and Company",
	company_type: 11,
	id: 5
}, {
	name: "CHA",
	company_type: 13
}, {
	name: "CFS",
	company_type: 14
}, {
	name: 'Freight Forwarder',
	company_type: 27
}]

const services = {
	1: {
		name: "Full Truck Load",
		id: 1
	},
	2: {
		name: "LCL Express Cargo",
		id: 2
	},
	3: {
		name: "Courier",
		id: 3
	},
	4: {
		name: "Container Transportation",
		id: 4
	},
	5: {
		name: "All",
		id: 5
	}
}



export default AddCompany;