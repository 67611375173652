import React, { Component, Fragment } from 'react';
import "./add-indents.less";
import InputText from "../../../../common/components/input-text/input-text";
import Select from 'react-select';
import validate from "../../../../../utils/validate";
import backIcon from "../../../../../assets/images/left-arrow.svg";
//   ../assets/images/left-arrow.svg
import Delete from "../../../../../assets/images/delete.svg";
import Add from "../../../../../assets/images/add.svg";
import Dropup from "../../../../../assets/images/drop-up-arrow.svg";
import SelectBranches from "../../../contracts_management/select-branches/select-branches";
import { CARGILL_COMPANY_IDS, PANTALOONS_COMPANY_IDS, isSapDeepakPhenolics, isAWL, getEmail } from '../../../../../utils/common';
import { withRouter } from 'react-router';

const boundTypeOptions = [
    { value: 'inbound', label: 'Inbound', id : 1 },
    { value: 'outbound', label: 'Outbound', id : 2 }
];
// const serviceCategoryOptions = [
//     { value: 'FTL', label: 'FTL', id : 1 },
//     { value: 'LCL', label: 'LCL', id : 2 },
//     { value: 'Courier', label: 'Courier', id : 3 },
//     { value: 'Container', label: 'Container', id : 4 }
// ];

const modeOptions=(companyId) =>{
  const  options  = [
    {value: 'road', label: 'Road',  id : 1},
    {value: 'full / mini rake', label: 'Full / Mini Rake', id : 2},
    {value: 'Rail Container', label: 'Rail Container', id : 3},
    {value: 'Partial Rake', label: 'Partial Rake', id : 9 },
    {value: 'Sea Container', label: 'Sea Container', id : 8 }
    ]
    if(isSapDeepakPhenolics(companyId)){
        options.push({ value: 'By Hand', label: 'By Hand', id : 7 })
    }
    if(!isAWL(companyId)){
        options.push({ value: 'Courier (Non Doc)', label: 'Courier (Non Doc)', id : 4 })
        options.push({ value: 'Semi-Rake', label: 'Semi-Rake', id : 5 })
        options.push({ value: 'Multipart-Rake', label: 'Multipart-Rake', id : 6 })
    }
   return options
 }

const packingTypes = [
    { value: 'consumer pack (cp)', label: 'Consumer Pack (CP)', id : 1 },
    { value: 'loose pack/bulk pack', label: 'Loose Pack/Bulk Pack', id : 2 },
    { value: 'others - engineering spares', label: 'Others - Engineering Spares', id : 3 },
    { value: 'others - packing/chemicals', label: 'Others - Packing/Chemicals', id : 4 },
    { value: 'internal movement', label: 'Internal Movement', id : 5 },
    {
        value: 'NON-PO',
        label: 'NON-PO',
        id : 6
    },
    {
        value: 'Planner',
        label: 'Planner',
        id : 7
    },
    {
        value: 'Bonded Sale',
        label: 'Bonded Sale',
        id : 8
    },
    {
        value: 'Bio Diesel',
        label: 'Bio Diesel',
        id : 9
    }
];

const uomOptions = [
    { value: 'numbers', label: 'Numbers', id : 1 },
    { value: 'weight', label: 'Weight', id : 2 }
];

const indentTypeOptions = [
    { value: 'inhousecargo', label: 'In-house Cargo', id : 1 },
    { value: 'externalcargocontrolled', label: 'External cargo controlled', id : 2 },
    { value: 'externalcargononcontrolled', label: 'External cargo non-controlled', id : 3 },
    { value: 'inhouseexternal', label: 'In-house & External', id : 4 },
    { value: 'multimodel', label: 'Multi Modal', id: 5 }
]
class Addindents extends Component {
    constructor(props) {
        super(props);
        this.state = {
            flowName: "",
            mobile: "",
            email: "",
            indentsname: "",
            password: "",
            roles: [],
            departments: [],
            boundType: null,
            indentType: null,
            serviceCategory: null,
            items: null,
            move: null,
            count: 0,
            selectedItem: null,
            addActions: false,
            selectedSubplant: null,
            advancedOptions: false,
            service_mode: null,
            packingType: null,
            uom: null,
            applyToOtherBranches: false,
            showSubMoves: [],
            showDestSubMoves: [],
            moveView: [
                {
                    move: {label: "", value: ""},
                    action_ids: [],
                    user_ids: [],
                    department_ids: [],
                    checklist_ids: [],
                    subplant_id: "",
                    field_ids: [],
                    next_moves: [],
                    sub_moves: [],
                    reasons: []
                }
            ],
            destMoveView: [
                {
                    move: {label: "", value: ""},
                    action_ids: [],
                    user_ids: [],
                    department_ids: [],
                    checklist_ids: [],
                    subplant_id: "",
                    field_ids: [],
                    next_moves: [],
                    sub_moves: [],
                    reasons: []
                }
            ],
            submoveView: [
                {
                    submove: {label: "", value: ""},
                    action_ids: [],
                    user_ids: [],
                    department_ids: [],
                    checklist_ids: [],
                    submove_name: "",
                    next_moves: [],
                    submove_id: 0,
                    reasons: []
                }
            ],
            serviceCategoryOptions : [
                { value: 'FTL', label: 'FTL', id : 1 },
                { value: 'LCL', label: 'LCL', id : 2 },
                { value: 'Courier', label: 'Courier', id : 3 },
                { value: 'Container', label: 'Container', id : 4 }
            ]
        }
    }
    handleChangBoundType = (boundType) => {
        this.setState({ boundType });
    }
    handleChangeIndentType = (indentType) => {
        this.setState({ indentType });
    }
    handleChangeServiceCategory = (serviceCategory) => {
        this.setState({ serviceCategory });
    }
    handleChangeItems = (selectedItem) => {
        this.setState({ selectedItem });
    }
    handleChangeSubplants = (selectedSubplant) => {
        this.setState({ selectedSubplant });
    }
    handleChangeMode = (selectedMode) => {
        this.setState({ service_mode: selectedMode });
    }
    handleChangePackagingType = (selectedPackagingType) => {
        this.setState({ packingType: selectedPackagingType });
    }
    handleChangeUom = (selectedUom) => {
        this.setState({ uom: selectedUom });
    }
    handleChangeMove = (move) => {
        this.setState({ move });
    }
    // handleChangeAction = (event) => {
    //     this.setState({ action });
    // }
    handleChangeChecklist = (checklist) => {
        this.setState({ checklist });
    }
    handleChangeAction = (user) => {
        this.setState({ user });
    }
    addIndents = () => {
    }
    handleSelectChange = (id, selectedOption) => {
        this.setState({ [id]: selectedOption }, () => {
            // console.log(this.state.userList);
            // console.log(this.state.checkList);
        });
    };
    addActiontoggle = () => {
        this.setState({
            addActions: !this.state.addActions
        })
    }
    componentDidMount() {
        const { branch_id, company_id } = this.props;
        const data = {
            branchId: branch_id,
            companyId: company_id
        }
        const company_data = {
            companyId: company_id
        }
        this.props.getItems(data);
        this.props.getMoves(data);
        this.props.getSubMoves(company_data);
        this.props.getActions(data);
        this.props.getUsers(data);
        this.props.getChecklists(data);
        this.props.getSubPlants(data);
        this.props.getFields(data);
        this.props.getDepartmentUserDetails(data);
        this.props.getServiceModes();
        this.props.getIndentingReasons(company_data);
    }
    // componentDidUpdate(prevProps) {
    //     console.log('prev');
    //     const {flowId} = this.props;
    //     if (prevProps.subPlants !== this.props.subPlants) {
    //         if(flowId){
    //             this.populateFlowDetails();
    //         }
    //     }
    // }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { flowId ,match} = this.props;
        const companyId = match.params.id
        const { isPaste, serviceCategoryOptions } = this.state;

        if(PANTALOONS_COMPANY_IDS.includes(nextProps.company_id)){
            let temp = serviceCategoryOptions.filter((d) => d.id !== 4)

            if(!temp.find((d)=>d.id == 5)){
                temp.push({value: 'Milkrun', label: 'Milkrun', id: 5 })
            }
            if(!temp.find((d)=>d.id == 6)){
                temp.push({ value: 'Dedicated', label: 'Dedicated', id: 6 })
            }
            this.setState({serviceCategoryOptions : temp})
        }

        if (nextProps.indentingFlowDetails && Object.keys(nextProps.indentingFlowDetails).length > 0) {
            if(flowId || isPaste){
                //this.populateFlowDetails(nextProps.subPlants, nextProps.indentingFlowDetail);
                const { indentingFlowDetails,subPlants } = nextProps;
                let moveView        =  indentingFlowDetails && indentingFlowDetails.moves && this.rearrangeMoves(indentingFlowDetails.moves);
                let destMoveView    =  indentingFlowDetails && indentingFlowDetails.destinationMoves && this.rearrangeMoves(indentingFlowDetails.destinationMoves);
                // let submoveView = indentingFlowDetails && indentingFlowDetails.moves && indentingFlowDetails.moves.map((sub) => sub.sub_moves);
                if(indentingFlowDetails && (flowId || isPaste)){
                    this.setState({
                        flowName:indentingFlowDetails.name,
                        moveView:moveView,
                        destMoveView:destMoveView,
                        // submoveView:submoveView,
                        boundType:boundTypeOptions.find(type=>type.id==indentingFlowDetails.bound_type),
                        indentType:indentTypeOptions.find(type=>type.id==indentingFlowDetails.indent_type),
                        serviceCategory:serviceCategoryOptions.find(type=>type.id==indentingFlowDetails.service_type),
                        service_mode:modeOptions(companyId).find(type=>type.id==indentingFlowDetails.service_mode),
                        packingType:packingTypes.find(type=>type.id==indentingFlowDetails.packing_type),
                        selectedSubplant:subPlants && subPlants.length > 0 && subPlants.map(indent=>{
                            if(indent.subplant_id==indentingFlowDetails.subplant_id){
                                return {
                                    label: indent.name,
                                    value: indent.subplant_id
                                }
                            }
                        }) || [],
                    });
                }
            }
        }
    }

    componentWillMount() {
        const {flowId} = this.props;
        const data = {
            flowId
        }
        if(flowId){
            this.props.getIndentingFlowDetails(data);
        }
    }
    onChangeHandler = (value, id, isType) => {
        const result = validate.validationCheck(value, this.state[id], isType);
        // const errMsg = validate.legthValidation(id, value);
        this.setState({
            [id]: result,
            // errormessage: errMsg
        })
    }
    rearrangeMoves = (moves) => {
        let moveView = [];
        let action,checklist,user, field_ids, next_moves, submoveViewList, department_ids, reasons;
        var k;
        const { indentingFields } = this.props;
        // let submoveViewList;
        for(var i=0;i<moves.length;i++){
            action = [];
            checklist = [];
            user = [];
            department_ids = [];
            field_ids = [];
            next_moves = [];
            submoveViewList = [];
            reasons = [];
            for(k=0;k<moves[i].actions.length;k++){
                action[k] = {label: moves[i].actions[k].name, value: moves[i].actions[k].action_id}
            }
            for(k=0;k<moves[i].checklists.length;k++){
                checklist[k] = {label: moves[i].checklists[k].name, value:  moves[i].checklists[k].checklist_id}
            }
            for(k=0;k<moves[i].departmentIds.length;k++){
                department_ids[k] = {label: moves[i].departmentIds[k].department_name, value:  moves[i].departmentIds[k].department_id}
            }
            for(k=0;k<moves[i].users.length;k++){
                user[k] = {label: moves[i].users[k].user_name, value:  moves[i].users[k].user_id}
            }
            if(moves[i].field_id && moves[i].field_id.length > 0) {
                for(k=0;k<moves[i].field_id.length;k++){
                    const fieldObj = (indentingFields || []).find(field => field.field_id ===  moves[i].field_id[k]);
                    field_ids[k] = { label : fieldObj && fieldObj.fieldTitle || '', value: fieldObj && fieldObj.field_id || '' }
                }
            }
            if(moves[i].next_moves && moves[i].next_moves.length > 0 ) {
                next_moves = moves[i].next_moves.map(nextMove =>{ return { label : nextMove.name || nextMove.submove_name, value: nextMove.move_id || nextMove.submove_id,isSubMove: !!nextMove.submove_id} });
            }
            if(moves[i].sub_moves) {
                let submoveList = moves[i].sub_moves;
                submoveViewList = this.rearrangeSubMoves(submoveList, moves[i].name);
                // this.setState({submoveView: submoveViewList})
            }
            if(moves[i].reasons && moves[i].reasons.length){
                for(k=0;k<moves[i].reasons.length;k++){
                    reasons[k] = moves[i].reasons[k]
                }
            }
            
            // for(k=0;k<moves[i].fields.length;k++){
            //     user[k] = {label: moves[i].users[k].user_name, value:  moves[i].users[k].user_id}
            // }
            moveView[i] = {
                move: {label: moves[i].name, value:  moves[i].move_id},
                action_ids: action,
                user_ids: user,
                department_ids,
                checklist_ids: checklist,
                subplant_id: "",
                field_ids,
                next_moves,
                sub_moves: submoveViewList,
                reasons
            }
        }
        return moveView;
    }
    rearrangeSubMoves = (moves, moveName) => {
        let submoveView = [];
        const { indentingActions, indentingChecklists, indentingUsers, indentingReasons } = this.props;
        let action,checklist,user, field_ids, next_moves, sub_moves, reasons;
        var k;
        let department_ids = [];
        let user_ids = [];
        for(var i=0;i<moves.length;i++){
            action = [];
            checklist = [];
            user = [];
            next_moves = [];
            reasons = [];
            const tempActions = moves[i].action_ids || moves[i].actions
            if(tempActions){
                for(k=0;k<tempActions.length;k++){
                    (indentingActions || []).forEach((el) => {
                        if(el.action_id == tempActions[k] || el.action_id == tempActions[k].action_id){
                            let actionList = {label: el.name, value:  el.action_id}
                            action.push(actionList)
                        } 
                    }
                )}
            }
            if(moves[i].checklist_ids){
                for(k=0;k<moves[i].checklist_ids.length;k++){
                    (indentingChecklists || []).forEach((el) => {
                        if(el.checklist_id == moves[i].checklist_ids[k]){
                            let checkLists = {label: el.name, value:  el.checklist_id}
                            checklist.push(checkLists)
                        }
                    }
                )}
            }
            if(moves[i].user_ids){
                for(k=0;k<moves[i].user_ids.length;k++){
                    (indentingUsers || []).forEach((el) => {
                        if(el.user_id == moves[i].user_ids[k]){
                            let userList = {label: el.user_name, value:  el.user_id}
                            user.push(userList)
                        }
                    }
                )}
            }
            if(moves[i].reasons){
                for(k=0;k<moves[i].reasons.length;k++){
                    (indentingReasons || []).forEach((el) => {
                        if(el.name == moves[i].reasons[k]){
                            reasons.push(el.name)
                        }
                    }
                )}
            }
            if(moves[i].next_moves && moves[i].next_moves.length > 0 ) {
                next_moves = moves[i].next_moves.map(nextMove =>{ return { label : nextMove.name || nextMove.submove_name, value: nextMove.move_id || nextMove.submove_id } });
            }

            if (Array.isArray(moves[i].departmentIds) && moves[i].departmentIds.length > 0) {
                department_ids = moves[i].departmentIds.map((item)=>{
                    const newEle  = {}
                    newEle.label = item.department_name
                    newEle.value = item.department_id
                    return newEle
                })
            }

            if (Array.isArray(moves[i].users) && moves[i].users.length > 0) {
                user_ids = moves[i].users.map((item)=>{
                    const newEle  = {}
                    newEle.label = item.user_name
                    newEle.value = item.user_id
                    return newEle
                })
            }

            submoveView[i] = {
                submove: {label: moves[i].submove_name, value:  moves[i].submove_id},
                action_ids: action,
                user_ids,
                checklist_ids: checklist,
                next_moves,
                moveName,
                submove_name: moves[i].submove_name,
                submove_id: moves[i].submove_id,
                reasons,
                department_ids
            }
        } 
        return submoveView;
    }
    populateFlowDetails = () => {
        const {indentingFlowDetails,flowId,subPlants,match} = this.props;
        const companyId = match.params.id
        const {serviceCategoryOptions} = this.state;
        let moveView        =  indentingFlowDetails && indentingFlowDetails.moves && this.rearrangeMoves(indentingFlowDetails.moves);
        let destMoveView    =  indentingFlowDetails &&indentingFlowDetails.destinationMoves && this.rearrangeMoves(indentingFlowDetails.destinationMoves);
        // let submoveView = indentingFlowDetails && indentingFlowDetails.moves && indentingFlowDetails.moves.map((sub) => sub.sub_moves);
        if(indentingFlowDetails && flowId){
            this.setState({
                flowName:indentingFlowDetails.name,
                moveView:moveView,
                // submoveView:submoveView,
                destMoveView:destMoveView,
                boundType:boundTypeOptions.find(type=>type.id==indentingFlowDetails.bound_type),
                indentType:indentTypeOptions.find(type=>type.id==indentingFlowDetails.indent_type),
                serviceCategory:serviceCategoryOptions.find(type=>type.id==indentingFlowDetails.service_type),
                service_mode:modeOptions(companyId).find(type=>type.id==indentingFlowDetails.service_mode),
                packingType:packingTypes.find(type=>type.id==indentingFlowDetails.packing_type),
                selectedSubplant:subPlants.map(indent=>{
                    if(indent.subplant_id==indentingFlowDetails.subplant_id){
                        return {
                            label: indent.name,
                            value: indent.subplant_id
                        }
                    }
                }),
            });
        }
    }
    addAction = () => {
        const { userList, checkList, actionName } = this.state;
        const { branch_id, company_id } = this.props;
        const user_ids = userList.map(user => user.value);
        const checklist_ids = checkList.map(checkList => checkList.value)
        const data = {
            name: actionName,
            company_id: company_id,
            checklist_ids: checklist_ids || [],
            user_ids: user_ids || []
        }
        this.props.addAction(data);
    }
    removeMove = (index) => {
        let tempActions = this.state.moveView.slice();
        tempActions.splice(index, 1);
        this.setState({moveView : tempActions});
    }
    removeSubMove = (submoveList, index, moveIndex) => {
        let { moveView } = this.state;
        let tempActions = submoveList.slice();
        tempActions.splice(index, 1);
        moveView[moveIndex].sub_moves = tempActions;
        this.setState({moveView});
    }
    removeDestSubMove = (submoveList, index, moveIndex) => {
        let { destMoveView } = this.state;
        let tempActions = submoveList.slice();
        tempActions.splice(index, 1);
        destMoveView[moveIndex].sub_moves = tempActions;
        this.setState({destMoveView});
    }
    removeDestMove = (index) => {
        let tempActions = this.state.destMoveView.slice();
        tempActions.splice(index, 1);
        this.setState({destMoveView : tempActions});
    }
    handleMovesSelectChange = (move, ind) => {
        let tempMoves = this.state.moveView.slice();
        for(let i = 0; i < tempMoves.length; i++){
            if(move.value == tempMoves[i].value){
                return;
            }
        }
        tempMoves[ind].move = move;
        this.setState({ moveView : tempMoves});
    }
    handleSubMovesSelectChange = (submoveList, submove, index, moveIndex) => {
        let { moveView } = this.state;
        let tempSubMoves = submoveList.slice();
        for(let i = 0; i < tempSubMoves.length; i++){
            if(submove.value == tempSubMoves[i].value){
                return;
            }
        }
        tempSubMoves[index].submove = submove;
        tempSubMoves[index].submove_id = submove.value;
        tempSubMoves[index].submove_name = submove.label;
        moveView[moveIndex].sub_moves = tempSubMoves;
        this.setState({ moveView });
    }

    handleDestSubMovesSelectChange = (submoveList, submove, index, moveIndex) => {
        let { destMoveView } = this.state;
        let tempSubMoves = submoveList.slice();
        for(let i = 0; i < tempSubMoves.length; i++){
            if(submove.value == tempSubMoves[i].value){
                return;
            }
        }
        tempSubMoves[index].submove = submove;
        tempSubMoves[index].submove_id = submove.value;
        tempSubMoves[index].submove_name = submove.label;
        destMoveView[moveIndex].sub_moves = tempSubMoves;
        this.setState({ destMoveView });
    }

    handleDestMovesSelectChange = (move, ind) => {
        let tempMoves = this.state.destMoveView.slice();
        for(let i = 0; i < tempMoves.length; i++){
            if(move.value == tempMoves[i].value){
                return;
            }
        }
        tempMoves[ind].move = move;
        this.setState({ destMoveView : tempMoves});
    }

    handleMovesMultiSelectChange = (move, ind, key) => {
        let tempMoves = this.state.moveView.slice();
        const { depotAndUserDetails } = this.props;
        for(let i = 0; i < tempMoves[ind][key].length; i++){
            if(move.value && move.value == tempMoves[ind][key][i].value){
                return;
            }
        }
        tempMoves[ind][key] = move;
        if(key === 'department_ids' && depotAndUserDetails.length > 0) {
            let users = [];
            depotAndUserDetails.map(el => {
                if((tempMoves[ind][key] || []).find(depart => depart.value === el.department_id)) {
                    const formattedUserObj = (el.users || []).map(user => ({ label: user.user_name, value: user.user_id }));
                    users = [...users, ...formattedUserObj];
                }
            })
            tempMoves[ind]['user_ids'] = users;
        }
        if(key === 'reasons'){
            let reasonList = [];
            (move || []).forEach((res) => {
                reasonList.push(res.label)
            })
            tempMoves[ind][key] = reasonList;
        }
        this.setState({ moveView : tempMoves});
    }

    handleDestSubMovesMultiSelectChange = (submoveList, submove, ind, moveIndex, key) => {
        let { destMoveView } = this.state;
        const { depotAndUserDetails } = this.props;
        const C1 = key !== 'reasons'
        let tempsubMoves = submoveList.slice();
        if(tempsubMoves[ind][key]) {
            for(let i = 0; i < tempsubMoves[ind][key].length; i++){
                if(submove.value == tempsubMoves[ind][key][i].value && C1){
                    return;
                }
            }
        }
        tempsubMoves[ind][key] = submove;
        if(key === 'department_ids' && depotAndUserDetails.length > 0) {
            let users = [];
            depotAndUserDetails.map(el => {
                if((tempsubMoves[ind][key] || []).find(depart => depart.value === el.department_id)) {
                    const formattedUserObj = (el.users || []).map(user => ({ label: user.user_name, value: user.user_id }));
                    users = [...users, ...formattedUserObj];
                }
            })
            tempsubMoves[ind]['user_ids'] = users;
        }

        if(key === 'reasons'){
            let reasonList = [];
            (submove || []).map((res) => {
                reasonList.push(res.label)
            })
            tempsubMoves[ind][key] = reasonList;
        }

        destMoveView[moveIndex].sub_moves = tempsubMoves;
        this.setState({ destMoveView });
    }

    handleSubMovesMultiSelectChange = (submoveList, submove, ind, moveIndex, key) => {
        let { moveView } = this.state;
        const { depotAndUserDetails } = this.props;
        let tempsubMoves = submoveList.slice();
        if(tempsubMoves[ind][key]) {
            for(let i = 0; i < tempsubMoves[ind][key].length; i++){
                if(submove.value && submove.value == tempsubMoves[ind][key][i].value){
                    return;
                }
            }
        }
        tempsubMoves[ind][key] = submove;
        if(key === 'department_ids' && depotAndUserDetails.length > 0) {
            let users = [];
            depotAndUserDetails.map(el => {
                if((tempsubMoves[ind][key] || []).find(depart => depart.value === el.department_id)) {
                    const formattedUserObj = (el.users || []).map(user => ({ label: user.user_name, value: user.user_id }));
                    users = [...users, ...formattedUserObj];
                }
            })
            tempsubMoves[ind]['user_ids'] = users;
        }
        if(key === 'reasons'){
            let reasonList = [];
            (submove || []).forEach((res) => {
                reasonList.push(res.label)
            })
            tempsubMoves[ind][key] = reasonList;
        }
        moveView[moveIndex].sub_moves = tempsubMoves;
        this.setState({ moveView });
    }

    // handleDestSubMovesMultiSelectChange = (submoveList, submove, ind, moveIndex, key) => {
    //     let { destMoveView } = this.state;
    //     const { depotAndUserDetails } = this.props;
    //     let tempsubMoves = submoveList.slice();
    //     if(tempsubMoves[ind][key]) {
    //         for(let i = 0; i < tempsubMoves[ind][key].length; i++){
    //             if(submove.value == tempsubMoves[ind][key][i].value){
    //                 return;
    //             }
    //         }
    //     }
    //     tempsubMoves[ind][key] = submove;
    //     if(key === 'department_ids' && depotAndUserDetails.length > 0) {
    //         let users = [];
    //         depotAndUserDetails.map(el => {
    //             if((tempsubMoves[ind][key] || []).find(depart => depart.value === el.department_id)) {
    //                 const formattedUserObj = (el.users || []).map(user => ({ label: user.user_name, value: user.user_id }));
    //                 users = [...users, ...formattedUserObj];
    //             }
    //         })
    //         tempsubMoves[ind]['user_ids'] = users;
    //     }
    //     destMoveView[moveIndex].sub_moves = tempsubMoves;
    //     this.setState({ destMoveView });
    // }

    handleDestMovesMultiSelectChange = (move, ind, key) => {
        let tempMoves = this.state.destMoveView.slice();
        const { depotAndUserDetails } = this.props;
        const C1 = key !== 'reasons';
        for(let i = 0; i < tempMoves[ind][key].length; i++){
            if(move.value == tempMoves[ind][key][i].value && C1){
                return;
            }
        }
        tempMoves[ind][key] = move;
        if(key === 'department_ids' && depotAndUserDetails.length > 0) {
            let users = [];
            depotAndUserDetails.map(el => {
                if((tempMoves[ind][key] || []).find(depart => depart.value === el.department_id)) {
                    const formattedUserObj = (el.users || []).map(user => ({ label: user.user_name, value: user.user_id }));
                    users = [...users, ...formattedUserObj];
                }
            })
            tempMoves[ind]['user_ids'] = users;
        }

        if(key === 'reasons'){
            let reasonList = [];
            (move || []).map((res) => {
                reasonList.push(res.label)
            })
            tempMoves[ind][key] = reasonList;
        }
        this.setState({ destMoveView : tempMoves});
    }

    combineMoveSubmove = () => {
        const { indentingMoves, indentingSubMoves } = this.props;
        let indentingMovesSubmoves = [];
        for(let i=0; i<indentingMoves.length; i++){
            indentingMovesSubmoves.push(indentingMoves[i]);
        }
        for(let i=0; i<indentingSubMoves.length; i++){
            indentingMovesSubmoves.push(indentingSubMoves[i]);
        }
        return indentingMovesSubmoves;
    }

    populateMoves = () => {
        const { indentingMoves, indentingActions, indentingChecklists, indentingUsers, indentingFields, depotAndUserDetails, indentingSubMoves, indentingReasons } = this.props;
        const { showSubMoves } = this.state;
        console.log('this.state:', this.state)
        return this.state.moveView.map((move, index) => {
            let users = [];
            if(depotAndUserDetails.length > 0) {
                depotAndUserDetails.map(el => {
                    if((move.department_ids || []).find(depart => depart.value === el.department_id)) 
                     users = [...users, ...el.users];
                })
            }
            console.log(this.state.moveView)
            return (
             <div className="moves" key = {index}>
                <span className = "sub-head">Select Source Moves</span>
                <div className="moves-select-container">
                {indentingMoves && indentingMoves.length ? <div className="select-box">
                        <Select 
                            value={move.move} 
                            onChange={(move) =>this.handleMovesSelectChange(move,index)}
                            options={indentingMoves && indentingMoves.length && (indentingMoves || []).map((move, index) => {
                                return {
                                    label: move.name,
                                    value: move.move_id
                                }
                            })} 
                            placeholder="Select Move"
                            // isMulti  
                        /> 
                    </div> : "" }
                </div>
                <div className="moves-select-container">
                {indentingActions && indentingActions.length  ? <div className="select-box">
                    <Select 
                            value={move.action_ids} 
                            onChange={(move) =>this.handleMovesMultiSelectChange(move,index, "action_ids")}
                            options={indentingActions && indentingActions.length && indentingActions.map((move, index) => {
                                return {
                                    label: move.name,
                                    value: move.action_id
                                }
                            })} 
                            placeholder="Select Actions"
                            isMulti  
                        /> 
                    </div> : ""
                    }
                    {indentingChecklists && indentingChecklists.length ? <div className="select-box">
                         <Select 
                            value={move.checklist_ids} 
                            onChange={(move) =>this.handleMovesMultiSelectChange(move,index, "checklist_ids")}
                            options={indentingChecklists && indentingChecklists.length && indentingChecklists.map((move, index) => {
                                return {
                                    label: move.name,
                                    value: move.checklist_id
                                }
                            })} 
                            placeholder="Select Checklist"
                            isMulti  
                        /> 
                    </div> : ""}
                    <div className="select-box">
                        <Select 
                            value={move.department_ids} 
                            onChange={(move) =>this.handleMovesMultiSelectChange(move,index, "department_ids")}
                            options={depotAndUserDetails && depotAndUserDetails.length && depotAndUserDetails.map((move, index) => {
                                return {
                                    label: move.department_name,
                                    value: move.department_id
                                }
                            }) || []} 
                            placeholder="Select Departments"
                            isMulti  
                        /> 
                    </div>
                    <div className="select-box mt-10 padding-left-0">
                        <Select 
                            value={move.user_ids} 
                            onChange={(move) =>this.handleMovesMultiSelectChange(move,index, "user_ids")}
                            options={users && users.length && users.map((move, index) => {
                                return {
                                    label: move.user_name,
                                    value: move.user_id
                                }
                            }) || []} 
                            placeholder="Select Users"
                            isMulti  
                        /> 
                    </div>
                    {indentingReasons && indentingReasons.length ? <div className="select-box">
                         <Select 
                            value={(move.reasons || []).map(reason => {return {label: reason, value: reason}})} 
                            onChange={(reason) =>this.handleMovesMultiSelectChange(reason,index, "reasons")}
                            options={indentingReasons && indentingReasons.length && indentingReasons.map((obj, index) => {
                                return {
                                    label: obj.name,
                                    value: obj.name
                                }
                            })} 
                            placeholder="Select Reasons"
                            isMulti  
                        /> 
                    </div> : ""}
                    {indentingMoves && indentingMoves.length ? <div className="select-box mt-10">
                        <Select 
                            value={move.next_moves} 
                            onChange={(move) =>this.handleMovesMultiSelectChange(move,index, "next_moves")}
                            options={indentingMoves && indentingMoves.length && [...indentingMoves || [], ...indentingSubMoves || []].map((move, index) => {
                                return {
                                    label: move.submove_name || move.name,
                                    value: move.submove_id || move.move_id
                                }
                            })} 
                            placeholder="Select Next Moves"
                            isMulti  
                        /> 
                    </div> : "" }
                    {indentingMoves && indentingMoves.length &&  (move.sub_moves.length) > 0  ?
                    <Fragment>
                    <div className='submoves'>Sub Moves
                    <div className={showSubMoves.includes(move.move.label) ? "arrow-icon rotate" : "arrow-icon"} style={{ backgroundImage: `url(${Dropup})` }} onClick={() => {
                        const { showSubMoves } = this.state;
                        const sectionIndex = showSubMoves.findIndex(el => el === move.move.label)
                        if(sectionIndex === -1){
                            showSubMoves.push(move.move.label)
                        }
                        else{
                            showSubMoves.splice(sectionIndex,1)
                        }
                        this.setState({showSubMoves})}}></div>
                    </div>
                    {showSubMoves.includes(move.move.label) ? this.populateSubMoves(move.sub_moves, move.move.label, index) : ''}
                    </Fragment>
                    : <button className = "add-button add-more-moves mt-10" onClick = {() => this.addAnotherSubMove(move.sub_moves, 0, index)}>Add More Sub Moves</button>}

                    {indentingFields && indentingFields.length && [3, 7,6].indexOf(move.move.value) > -1  ? <div className="select-box mt-10">
                        <Select 
                            value={move.field_ids} 
                            onChange={(move) =>this.handleMovesMultiSelectChange(move,index, "field_ids")}
                            options={indentingFields && indentingFields.length && indentingFields.map((move, index) => {
                                return {
                                    label: move.fieldTitle,
                                    value: move.field_id
                                }
                            })} 
                            placeholder="Select Fields"
                            isMulti  
                        /> 
                    </div> : ""}
                </div>
                {/* <button className = "add-button add-more-moves" onClick = {() => this.addAnotherSubMove(index)}>Add More Sub Moves</button> */}
                 {/* <Select className = "select" value={move} onChange={(move) =>this.handleMovesSelectChange(move,index)} options={this.state.actions} /> */}
                 {(index < this.state.moveView.length - 1) && <div className = "delete-icon remove-button" style={{backgroundImage: `url(${Add})`, right: '30px'}} onClick={() => this.addAnotherMove(index)}></div>}
                 <div className = "delete-icon remove-button" style={{backgroundImage: `url(${Delete})`}} onClick={() => this.removeMove(index)}></div>
             </div>
             )
         })
     }

     populateDestinationMoves = () => {
        const { indentingMoves, indentingActions, indentingChecklists, indentingUsers, indentingFields, depotAndUserDetails, indentingSubMoves, indentingReasons } = this.props;
        const {showDestSubMoves,destMoveView} = this.state
        const isDestSubMoveSection = true

        return this.state.destMoveView.map((move, index) => {
            let users = [];
            if(depotAndUserDetails.length > 0) {
                depotAndUserDetails.map(el => {
                    if(move.department_ids.find(depart => depart.value === el.department_id)) 
                     users = [...users, ...el.users];
                })
            }
             return (
             <div className="moves" key = {index}>
                <span className = "sub-head">Select Destination Moves</span>
                <div className="moves-select-container">
                {indentingMoves && indentingMoves.length ? <div className="select-box">
                        <Select 
                            value={move.move}
                            onChange={(move) =>this.handleDestMovesSelectChange(move,index)}
                            options={indentingMoves && indentingMoves.length && indentingMoves.map((move, index) => {
                                return {
                                    label: move.name,
                                    value: move.move_id
                                }
                            })} 
                            placeholder="Select Move"
                            // isMulti  
                        /> 
                    </div> : "" }
                </div>
                <div className="moves-select-container">
                {indentingActions && indentingActions.length  ? <div className="select-box">
                    <Select 
                            value={move.action_ids} 
                            onChange={(move) =>this.handleDestMovesMultiSelectChange(move,index, "action_ids")}
                            options={indentingActions && indentingActions.length && indentingActions.map((move, index) => {
                                return {
                                    label: move.name,
                                    value: move.action_id
                                }
                            })} 
                            placeholder="Select Actions"
                            isMulti  
                        /> 
                    </div> : ""
                    }
                    {indentingChecklists && indentingChecklists.length ? <div className="select-box">
                         <Select 
                            value={move.checklist_ids} 
                            onChange={(move) =>this.handleDestMovesMultiSelectChange(move,index, "checklist_ids")}
                            options={indentingChecklists && indentingChecklists.length && indentingChecklists.map((move, index) => {
                                return {
                                    label: move.name,
                                    value: move.checklist_id
                                }
                            })} 
                            placeholder="Select Checklist"
                            isMulti  
                        /> 
                    </div> : ""}
                    <div className="select-box">
                        <Select 
                            value={move.department_ids} 
                            onChange={(move) => this.handleDestMovesMultiSelectChange(move,index, "department_ids")}
                            options={depotAndUserDetails && depotAndUserDetails.length && depotAndUserDetails.map((move, index) => {
                                return {
                                    label: move.department_name,
                                    value: move.department_id
                                }
                            }) || []} 
                            placeholder="Select Departments"
                            isMulti  
                        /> 
                    </div>
                    <div className="select-box mt-10 padding-left-0">
                        <Select 
                            value={move.user_ids} 
                            onChange={(move) =>this.handleDestMovesMultiSelectChange(move,index, "user_ids")}
                            options={users && users.length && users.map((move, index) => {
                                return {
                                    label: move.user_name,
                                    value: move.user_id
                                }
                            }) || []} 
                            placeholder="Select Users"
                            isMulti  
                        /> 
                    </div>
                    {indentingReasons && indentingReasons.length ? <div className="select-box">
                         <Select 
                            value={(move.reasons || []).map(reason => {return {label: reason, value: reason}})}  
                            onChange={(reason) =>this.handleDestMovesMultiSelectChange(reason,index, "reasons")}
                            options={indentingReasons && indentingReasons.length && indentingReasons.map((obj, index) => {
                                return {
                                    label: obj.name,
                                    value: obj.name
                                }
                            })} 
                            placeholder="Select Reasons"
                            isMulti  
                        /> 
                    </div> : ""}

                    {indentingMoves && indentingMoves.length ? <div className="select-box mt-10">
                        <Select 
                            value={move.next_moves}
                            onChange={(move) =>this.handleDestMovesMultiSelectChange(move,index, 'next_moves')}
                            options={indentingMoves && indentingMoves.length && [...indentingMoves || [], ...indentingSubMoves || []].map((move, index) => {
                                return {
                                    label: move.submove_name || move.name,
                                    value: move.submove_id || move.move_id
                                }
                            })} 
                            placeholder="Select Next Moves"
                            isMulti  
                        /> 
                    </div> : "" }


                         {indentingMoves && indentingMoves.length && ((move.sub_moves).length) > 0  ?
                             <Fragment>
                                 <div className='submoves'>Sub Moves
                                     <div className={showDestSubMoves.includes(move.move.label) ? "arrow-icon rotate" : "arrow-icon"} style={{ backgroundImage: `url(${Dropup})` }} 
                                         onClick={() => {
                                             const { showDestSubMoves } = this.state;
                                             const sectionIndex = showDestSubMoves.findIndex(el => el === move.move.label)
                                             if (sectionIndex === -1) {
                                                 showDestSubMoves.push(move.move.label)
                                             }
                                             else {
                                                 showDestSubMoves.splice(sectionIndex, 1)
                                             }
                                             this.setState({ showDestSubMoves })
                                         }}
                                     >

                                     </div>
                                 </div>
                                 {showDestSubMoves.includes(move.move.label) ? this.populateSubMoves(move.sub_moves, move.move.label, index,isDestSubMoveSection) : ''}
                             </Fragment>
                             : <Fragment>

                                 <button className="add-button add-more-moves mt-10"
                                     onClick={
                                         () => this.addDestAnotherSubMove(move.sub_moves, 0, index)
                                     }
                                 >
                                     Add More Sub Moves
                                 </button>
                             </Fragment>}


                    {indentingFields && indentingFields.length && [3, 7].indexOf(move.move.value) > -1 ? <div className="select-box mt-10">
                        <Select 
                            value={move.field_ids} 
                            onChange={(move) =>this.handleDestMovesMultiSelectChange(move,index, "field_ids")}
                            options={indentingFields && indentingFields.length && indentingFields.map((move, index) => {
                                return {
                                    label: move.fieldTitle,
                                    value: move.field_id
                                }
                            })} 
                            placeholder="Select Fields"
                            isMulti  
                        /> 
                    </div> : ""}
                </div>
                 {/* <Select className = "select" value={move} onChange={(move) =>this.handleMovesSelectChange(move,index)} options={this.state.actions} /> */}
                 {(index < this.state.destMoveView.length - 1) && <div className = "delete-icon remove-button" style={{backgroundImage: `url(${Add})`, right: '30px'}} onClick={() => this.addDestAnotherMove(index)}></div>}
                 <div className = "delete-icon remove-button" style={{backgroundImage: `url(${Delete})`}} onClick={() => this.removeDestMove(index)}></div>
             </div>
             )
         })
     }

     populateSubMoves = (submoveList, moveName, moveIndex,isDestSubMoveSection=false) => {
        const { indentingMoves, indentingActions, indentingChecklists, indentingUsers, indentingFields, indentingSubMoves, depotAndUserDetails, indentingReasons } = this.props;
        const indentingMovesSubmoves = this.combineMoveSubmove();

        return submoveList.map((submove, index) => {
            if(submove.moveName === moveName){
                let users = [];
                if(depotAndUserDetails.length > 0) {
                    depotAndUserDetails.map(el => {
                        if((submove.department_ids || []).find(depart => depart.value === el.department_id)) 
                        users = [...users, ...el.users];
                    })
                }
            return (

             <div className="submoves-bucket" key = {index}>
                <span className = "sub-head">{`Select Sub Moves For ${moveName}`}</span>
                <div className="moves-select-container">
                {indentingSubMoves && indentingSubMoves.length ? <div className="select-box">
                        <Select 
                            value={submove.submove} 

                            onChange={
                                (sub) => {
                                    if(isDestSubMoveSection){
                                        this.handleDestSubMovesSelectChange(submoveList, sub,index, moveIndex)
                                    }else{
                                        this.handleSubMovesSelectChange(submoveList, sub,index, moveIndex)
                                    }
                                }
                            }

                            options={indentingSubMoves && indentingSubMoves.length && indentingSubMoves.map((sub, index) => {
                                return {
                                    label: sub.submove_name,
                                    value: sub.submove_id
                                }
                            })} 
                            placeholder="Select Move"
                            // isMulti  
                        /> 
                    </div> : "" }
                    {/* {submove_name = submove.submove_name, submove.id } */}
                </div>
                <div className="moves-select-container">
                {indentingActions && indentingActions.length  ? <div className="select-box">
                    <Select 
                            value={submove.action_ids} 
                            onChange={
                                (sub) => {
                                    if(isDestSubMoveSection){
                                        this.handleDestSubMovesMultiSelectChange(submoveList, sub, index, moveIndex, "action_ids")
                                    }else{
                                        this.handleSubMovesMultiSelectChange(submoveList, sub, index, moveIndex, "action_ids")
                                    }
                                }
                            }
                            options={indentingActions && indentingActions.length && indentingActions.map((sub, index) => {
                                return {
                                    label: sub.name,
                                    value: sub.action_id
                                }
                            })} 
                            placeholder="Select Actions"
                            isMulti  
                        /> 
                    </div> : ""
                    }
                    {indentingChecklists && indentingChecklists.length ? <div className="select-box">
                         <Select 
                            value={submove.checklist_ids} 
                            onChange={
                                (sub) => {
                                    if(isDestSubMoveSection){
                                        this.handleDestSubMovesMultiSelectChange(submoveList, sub, index, moveIndex, "checklist_ids")
                                    }else{
                                        this.handleSubMovesMultiSelectChange(submoveList, sub, index, moveIndex, "checklist_ids")
                                    }
                                }
                            }
                            options={indentingChecklists && indentingChecklists.length && indentingChecklists.map((sub, index) => {
                                return {
                                    label: sub.name,
                                    value: sub.checklist_id
                                }
                            })} 
                            placeholder="Select Checklist"
                            isMulti  
                        /> 
                    </div> : ""}
                    <div className="select-box">
                        <Select 
                            value={submove.department_ids} 
                            onChange={
                                (sub) => {
                                    if(isDestSubMoveSection){
                                        this.handleDestSubMovesMultiSelectChange(submoveList, sub, index, moveIndex, "department_ids")
                                    }else{
                                        this.handleSubMovesMultiSelectChange(submoveList, sub, index, moveIndex, "department_ids")
                                    }
                                }
                            }
                            options={depotAndUserDetails && depotAndUserDetails.length && depotAndUserDetails.map((sub, index) => {
                                return {
                                    label: sub.department_name,
                                    value: sub.department_id
                                }
                            }) || []} 
                            placeholder="Select Departments"
                            isMulti  
                        /> 
                    </div>
                    <div className="select-box mt-10 padding-left-0">
                        <Select 
                            value={submove.user_ids} 

                            onChange={
                                (sub) => {
                                    if(isDestSubMoveSection){
                                        this.handleDestSubMovesMultiSelectChange(submoveList, sub, index, moveIndex, "user_ids")
                                    }else{
                                        this.handleSubMovesMultiSelectChange(submoveList, sub, index, moveIndex, "user_ids")
                                    }
                                }
                            }

                            options={users && users.length && users.map((sub, index) => {
                                return {
                                    label: sub.user_name,
                                    value: sub.user_id
                                }
                            }) || []} 
                            placeholder="Select Users"
                            isMulti  
                        /> 
                    </div>
                    <div className="select-box mt-10">
                        <Select 
                            // value={submove.reasons} 
                            value={(submove.reasons || []).map(reason => {return {label: reason, value: reason}})}
                            onChange={
                                (sub) => {
                                    if(isDestSubMoveSection){
                                        this.handleDestSubMovesMultiSelectChange(submoveList, sub, index, moveIndex, "reasons")
                                    }else{
                                        this.handleSubMovesMultiSelectChange(submoveList, sub, index, moveIndex, "reasons")
                                    }
                                }
                            }
                            options={indentingReasons && indentingReasons.length && indentingReasons.map((sub, index) => {
                                return {
                                    label: sub.name,
                                    value: sub.name
                                }
                            }) || []} 
                            placeholder="Select Reasons"
                            isMulti  
                        /> 
                    </div>
                    {/* {indentingUsers && indentingUsers.length ? <div className="select-box">
                        <Select 
                            value={submove.user_ids} 
                            onChange={(sub) =>this.handleSubMovesMultiSelectChange(submoveList, sub, index, moveIndex, "user_ids")}
                            options={indentingUsers && indentingUsers.length && indentingUsers.map((sub, index) => {
                                return {
                                    label: sub.user_name,
                                    value: sub.user_id
                                }
                            })} 
                            placeholder="Select Users"
                            isMulti  
                        /> 
                    </div> : ""} */}
                    {indentingMovesSubmoves && indentingMovesSubmoves.length ? <div className="select-box mt-10">
                        <Select 
                            value={submove.next_moves} 

                            onChange={
                                (sub) => {
                                    if(isDestSubMoveSection){
                                        this.handleDestSubMovesMultiSelectChange(submoveList, sub,index, moveIndex, "next_moves")
                                    }else{
                                        this.handleSubMovesMultiSelectChange(submoveList, sub,index, moveIndex, "next_moves")
                                    }
                                }
                            }
                            options={indentingMovesSubmoves && indentingMovesSubmoves.length && indentingMovesSubmoves.map((sub, index) => {
                                return {
                                    label: sub.submove_name ? sub.submove_name : sub.name,
                                    value: sub.submove_id ? sub.submove_id : sub.move_id
                                }
                            })} 
                            placeholder="Select Next Moves"
                            isMulti  
                        /> 
                    </div> : "" }
                    {/* {indentingFields && indentingFields.length && [3, 7].indexOf(move.move.value) > -1  ? <div className="select-box mt-10">
                        <Select 
                            value={move.field_ids} 
                            onChange={(move) =>this.handleMovesMultiSelectChange(move,index, "field_ids")}
                            options={indentingFields && indentingFields.length && indentingFields.map((move, index) => {
                                return {
                                    label: move.fieldTitle,
                                    value: move.field_id
                                }
                            })} 
                            placeholder="Select Fields"
                            isMulti  
                        /> 
                    </div> : ""} */}
                </div>
                 {/* <Select className = "select" value={move} onChange={(move) =>this.handleMovesSelectChange(move,index)} options={this.state.actions} /> */}
                    <div
                        className="delete-icon remove-button"
                        style={{ backgroundImage: `url(${Add})`, right: '30px' }}
                        onClick={
                            () => {
                                if(isDestSubMoveSection){
                                    this.addDestAnotherSubMove(submoveList, index, moveIndex)
                                }else{
                                    this.addAnotherSubMove(submoveList, index, moveIndex)
                                }
                            }
                        }
                    >
                    </div>
                    <div
                        className="delete-icon remove-button"
                        style={{ backgroundImage: `url(${Delete})` }}
                        onClick={
                            () => {
                                if(isDestSubMoveSection){
                                    this.removeDestSubMove(submoveList, index, moveIndex)
                                }else{
                                    this.removeSubMove(submoveList, index, moveIndex)
                                }
                            }
                        }
                    >
                    </div>
             </div>
             
             )}
         })
     }



    addAnotherMove = (index) => {
        let tempMoves = this.state.moveView.slice();
        const baseObj = {
            move: {label: "", value: ""},
            action_ids: [],
            user_ids: [],
            department_ids: [],
            checklist_ids: [],
            field_ids: [],
            next_moves: [],
            sub_moves: [],
            reasons: []
        }
        if(index || index == 0) {
            tempMoves.splice(index + 1, 0, baseObj);
        } else {
            tempMoves.push(baseObj);
        }
        this.setState({ moveView : tempMoves});
    }
    addAnotherSubMove = (submoveList, index, moveIndex) => {
        let tempSubMoves = submoveList.slice();
        let { moveView } = this.state;
        const baseObj = {
            submove: {label: "", value: ""},
            action_ids: [],
            user_ids: [],
            department_ids: [],
            checklist_ids: [],
            submove_name: "",
            next_moves: [],
            reasons: [],
            submove_id: 0,
            moveName: moveView[moveIndex].move.label,

        }
        if(index || index == 0) {
            tempSubMoves.splice(index + 1, 0, baseObj);
        } else {
            tempSubMoves.push(baseObj);
        }
        moveView[moveIndex].sub_moves = tempSubMoves;
        this.setState({ moveView });
    }
    addDestAnotherSubMove = (submoveList =[], index, moveIndex) => {
        let tempSubMoves = submoveList.slice();
        let { destMoveView } = this.state;
        const baseObj = {
            submove: {label: "", value: ""},
            action_ids: [],
            user_ids: [],
            department_ids: [],
            checklist_ids: [],
            submove_name: "",
            next_moves: [],
            reasons: [],
            submove_id: 0,
            moveName: destMoveView[moveIndex].move.label
        }
        if(index || index == 0) {
            tempSubMoves.splice(index + 1, 0, baseObj);
        } else {
            tempSubMoves.push(baseObj);
        }
        destMoveView[moveIndex].sub_moves = tempSubMoves;
        this.setState({ destMoveView });
    }
    addDestAnotherMove = (index) => {
        let tempMoves = this.state.destMoveView.slice();
        const baseObj = {
            move: {label: "", value: ""},
            action_ids: [],
            user_ids: [],
            department_ids: [],
            checklist_ids: [],
            field_ids: [],
            next_moves: [],
            sub_moves: [],
            reasons: []
        }
        if(index || index == 0) {
            tempMoves.splice(index + 1, 0, baseObj);
        } else {
            tempMoves.push(baseObj);
        }
        this.setState({ destMoveView : tempMoves});
    }
    submitMoves = () => {
        let tempMoves = this.state.moveView.slice();
        const { flowName, boundType, serviceCategory, selectedItem, selectedSubplant, packingType, uom, service_mode, indentType, serviceCategoryOptions } = this.state;
        const { branch_id, company_id, flowId, indentingMoves, indentingSubMoves } = this.props;
        const moves = [];
        const subMoveIds = (indentingSubMoves || []).map(id => { return id.submove_id})
        // const submoves = [];
        for (var i = 0; i < tempMoves.length; i++) {
            const submoves = [];
            for( var k = 0; k < tempMoves[i].sub_moves.length; k++) {
                     const submove = {
                        action_ids: tempMoves[i].sub_moves[k].action_ids.map(action => {return action.value}),
                        checklist_ids: tempMoves[i].sub_moves[k].checklist_ids.map(checkList => {return checkList.value}),
                        department_ids: tempMoves[i].department_ids.map(department => {return department.value}),
                        user_ids: tempMoves[i].sub_moves[k].user_ids.map(user => {return user.value}),
                        // next_moves: tempMoves[i].sub_moves[k].next_moves.map(nextMove => {return {move_id: nextMove.value, name: nextMove.label} }) || [],
                        reasons: tempMoves[i].sub_moves[k].reasons || [],
                        submove_id: tempMoves[i].sub_moves[k].submove_id,
                        submove_name: tempMoves[i].sub_moves[k].submove_name
                    }
                    if(tempMoves[i].sub_moves[k].next_moves && tempMoves[i].sub_moves[k].next_moves.length){
                        submove.next_moves = tempMoves[i].sub_moves[k].next_moves.map(nextMove => 
                            {
                                if(subMoveIds.includes(nextMove.value)){
                                    return {submove_id: nextMove.value, submove_name: nextMove.label}
                                } else {
                                    return {move_id: nextMove.value, name: nextMove.label}
                                }
                            }
                        ) || []
                    }
                    
                    if((((submove.action_ids || []).length > 0) || ((submove.checklist_ids || []).length > 0) || ((submove.user_ids || []).length > 0) || ((submove.next_moves || []).length > 0) || submove.submove_id || submove.submove_name)){
                        submoves.push(submove);
                    }
                }
            const move = {
                move_id : tempMoves[i].move.value,
                action_ids: tempMoves[i].action_ids.map(action => {return action.value}),
                checklist_ids: tempMoves[i].checklist_ids.map(checkList => {return checkList.value}),
                department_ids: tempMoves[i].department_ids.map(department => {return department.value}),
                // reasons: tempMoves[i].reasons || [],
                user_ids: tempMoves[i].user_ids.map(user => {return user.value}),
                reasons: tempMoves[i].reasons || [],
                field_ids: tempMoves[i].field_ids && tempMoves[i].field_ids.map(field => {return field.value}) || [],
                next_moves: tempMoves[i].next_moves && tempMoves[i].next_moves.map(nextMove => {return {...(!!nextMove.isSubMove?{submove_id: nextMove.value}:{move_id: nextMove.value}) ,...(!!nextMove.isSubMove?{submove_name: nextMove.label}:{name: nextMove.label})} }) || [],
                // sub_moves: (submoves.length > 0) ? submoves : undefined
                // sub_moves: tempMoves[i].sub_moves && tempMoves[i].sub_moves.map(subMove => {return {move_id: subMove.submove_id, name: subMove.submove_name} }) || []
            }
            if(submoves.length > 0){
                move.sub_moves = submoves
            }
            moves.push(move);
        }
        const destMoves = [];
        let destTempMoves = this.state.destMoveView.slice();

        const getDestSubMoves = (sub_moves = []) => {

            const updatedSubMoves = sub_moves.map((ele) => {
                 const newEle = {}

                 newEle.action_ids = (ele.action_ids ||[]).map((ele)=>{
                    return ele.value
                 })
                 newEle.checklist_ids = (ele.checklist_ids ||[]).map((ele)=>{
                    return ele.value
                 })
                 newEle.department_ids = (ele.department_ids ||[]).map((ele)=>{
                    return ele.value
                 })
                 newEle.user_ids = (ele.user_ids ||[]).map((ele)=>{
                    return ele.value
                 })
                 newEle.reasons = (ele.reasons ||[]).map((ele)=>{
                    return ele.value
                 })
                 newEle.submove_id = ele.submove_id 
                 newEle.submove_name = ele.submove_name 
                 newEle.next_moves = ele.next_moves 

                 return newEle
            })
            return updatedSubMoves
        }


        for (var i = 0; i < destTempMoves.length; i++) {
            const move = {
                move_id : destTempMoves[i].move.value,
                action_ids: destTempMoves[i].action_ids.map(action => {return action.value}),
                checklist_ids: destTempMoves[i].checklist_ids.map(checkList => {return checkList.value}),
                department_ids: destTempMoves[i].department_ids.map(department => {return department.value}),
                user_ids: destTempMoves[i].user_ids.map(user => {return user.value}),
                field_ids: destTempMoves[i].field_ids && destTempMoves[i].field_ids.map(field => {return field.value}) || [],
                next_moves: destTempMoves[i].next_moves && destTempMoves[i].next_moves.map(nextMove => {return {move_id: nextMove.value, name: nextMove.label}}) || [],
                sub_moves: getDestSubMoves(destTempMoves[i].sub_moves),
                reasons: destTempMoves[i].reasons
            }
            destMoves.push(move);
        }
        let data = {
            name : flowName,
            bound_type: boundType && boundTypeOptions.find(o => o.value === boundType.value).id,
            service_type: serviceCategory && serviceCategoryOptions.find(o => o.value === serviceCategory.value).id,
            item_id: selectedItem && selectedItem.value,
            subplant_id: selectedSubplant && selectedSubplant.value,
            branch_id: branch_id,
            company_id: company_id,
            moves: moves,
            destination_moves: destMoves,
            packing_type: packingType && packingType.id,
            uom: uom && uom.id,
            service_mode: service_mode && service_mode.id,
            indent_type: indentType && indentType.id
        }
        if(flowId){
            data['master_type'] = 7;
            data['user_email'] = getEmail();
            data.flow_id = flowId;
        }
        this.setState({ isPaste: false }, () => {
            this.props.submitMoves(data);
        });
    }

    pasteFlow = () => {
        const flowId = localStorage.getItem('flowId');
        const data = {
            flowId
        }
        if(flowId){
            this.setState({ isPaste: true}, () => {
                this.props.getIndentingFlowDetails(data);
            });
        }
    }

    toggleSubMoves = () => {
        this.setState({
            showSubMoves: !this.state.showSubMoves
        })
    }

    toggleAdvancedOptions = () => {
        this.setState({
            advancedOptions: !this.state.advancedOptions
        })
    }

    clickCustomeCheckBoxService = () => {
        this.setState({
           applyToOtherBranches: !this.state.applyToOtherBranches
        })
     }

    render() {
        const { indentingItems, subPlants, indentingUsers, indentingChecklists, indentingMoves, indentingActions,match } = this.props;
        const companyId = match.params.id
        const {advancedOptions, showSubMoves, serviceCategoryOptions} = this.state;
        return (
            <div className="add-user-wrap add-indents-warp">
            <div className = "go-back-prev-screen" onClick={() => { this.props.toggleAdd() }} style = {{backgroundImage: `url(${backIcon})`}}></div>
            {!this.props.flowId && <button className = "add-button add-more-moves" onClick = {this.pasteFlow}>Paste</button>}
                {/* <div className = "overlay"></div> */}

                {/* <div id = "modal" className = "modal-popup"> */}
                {/* <div className = "pop-heading">
                        Add Indents
                    </div> */}
                <div className="content">
                    <label className="error">{this.state.error}</label>
                    <InputText placeholder="Name Of The Flow" id={"flowName"} label="Name Of The Flow" value={this.state.flowName} changeHandler={this.onChangeHandler} />
                    <div className="select-container">
                        <div className="select-holder">
                            <span className="sub-head">Bound Type</span>
                            <Select value={this.state.boundType} onChange={this.handleChangBoundType} options={CARGILL_COMPANY_IDS.includes(this.props.company_id) ? [...boundTypeOptions, { value: 'external', label: 'External', id: 3 }] : boundTypeOptions} />
                        </div>
                        <div className="select-holder">
                            <span className="sub-head">Service Category</span>
                            <Select value={this.state.serviceCategory} onChange={this.handleChangeServiceCategory} options={serviceCategoryOptions} />
                        </div>
                        { subPlants && subPlants.length ? <div className="select-holder">
                            <span className="sub-head">Sub Plant</span>
                            <Select value={this.state.selectedSubplant} onChange={this.handleChangeSubplants} 
                                options={subPlants && subPlants.length && subPlants.map((indent, index) => {
                                    return {
                                        label: indent.name,
                                        value: indent.subplant_id
                                    }
                                })} 
                            /> 
                        </div> : ""}
                    </div>
                    <div className="select-container">
                        <div className="select-holder">
                            <span className="sub-head">Indent Type</span>
                            <Select value={this.state.indentType} onChange={this.handleChangeIndentType} options={CARGILL_COMPANY_IDS.includes(this.props.company_id) ? [...indentTypeOptions, { value: 'externaltoexternal', label: 'External to External', id: 7 }] : indentTypeOptions} />
                        </div>   
                        <div className="select-holder">
                            <span className="sub-head">Mode</span>
                            <Select value={this.state.service_mode} onChange={this.handleChangeMode} options={modeOptions(companyId)} />
                        </div>   
                        <div className="select-holder">
                            <span className="sub-head">Packing Type</span>
                            <Select value={this.state.packingType} onChange={this.handleChangePackagingType} options={packingTypes} />
                        </div> 
                    </div>
                    {
                        advancedOptions ? 
                        <div className="select-container select-container-advanced">
                        {/* <div className="select-holder">
                            <span className="sub-head">Mode</span>
                            <Select value={this.state.mode} onChange={this.handleChangeMode} options={modeOptions} />
                        </div>
                        <div className="select-holder">
                            <span className="sub-head">Packing Type</span>
                            <Select value={this.state.packingType} onChange={this.handleChangePackagingType} options={packingTypes} />
                        </div> */}
                        <div className="select-holder">
                            <span className="sub-head">UOM</span>
                            <Select value={this.state.uom} onChange={this.handleChangeUom} options={uomOptions} />
                        </div>
                        <div className="select-holder">
                            <span className="sub-head">Item</span>
                            <Select value={this.state.selectedItem} onChange={this.handleChangeItems} options={indentingItems && indentingItems.length && indentingItems.map((indent, index) => {
                                    return {
                                        label: indent.name,
                                        value: indent.item_id
                                    }
                                }) || []} 
                            />
                        </div>
                        </div> : ""
                    }
                    <button className="advanced-button add-button" onClick = {this.toggleAdvancedOptions}>Advanced</button>
                    <div className = "select-container-moves">
                            {this.populateMoves()}
                            <button className = "add-button add-more-moves" onClick = {() => this.addAnotherMove()}>Add More Source Moves</button>
                            {this.populateDestinationMoves()}
                            <button className = "add-button add-more-moves" onClick = {() => this.addDestAnotherMove()}>Add More Destination Moves</button>
                    </div>
                    
                    {/* <input class="button-classic" type="button" value="+ Add Action" onClick={this.addActiontoggle} /> */}
                    {/* {
                        this.state.addActions ?
                            <div>
                                <div className="overlay"></div>
                                <div id="modal" className="modal-popup">
                                    <div className="pop-heading">
                                        Add Actions
                                    </div>
                                    <div className="content">
                                        <p className="small-font">Select Actions data</p>
                                        <div className="select-container">
                                            <InputText placeholder="Name of the Action" id={"actionName"} label="Name of the Action" value={this.state.actionName} changeHandler={this.onChangeHandler} />
                                            <div className="select-holder"><span className="sub-head">User List</span>
                                            <Select 
                                                    value={this.state.userList} 
                                                    onChange={this.handleSelectChange.bind(
                                                        this,
                                                        "userList"
                                                    )} 
                                                    options={indentingUsers && indentingUsers.length && indentingUsers.map((indent, index) => {
                                                        return {
                                                            label: indent,
                                                            value: indent
                                                        }
                                                    })} 
                                                    isMulti 
                                            />
                                            </div>
                                            <pre>        </pre>
                                            <div className="select-holder"><span className="sub-head">Check List</span>
                                            <Select 
                                                value={this.state.checkList} 
                                                onChange={this.handleSelectChange.bind(
                                                    this,
                                                    "checkList"
                                                )} 
                                                options={indentingChecklists && indentingChecklists.length && indentingChecklists.map((indent, index) => {
                                                return {
                                                    label: indent.name,
                                                    value: indent.checklist_id
                                                }
                                                })} 
                                                isMulti 
                                            />
                                            </div>
                                            <pre>        </pre>

                                        </div>
                                        <div className="submit-section">
                                            <button onClick={() => { this.addActiontoggle() }} className="add-button discard">Cancel</button>
                                            <button onClick={this.addAction} className="add-button">Save</button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            :
                            ''
                    } */}
                    <div className="submit-section">
                        <button onClick={() => { this.props.toggleAdd() }} className="add-button discard">Cancel</button>
                        <button className="add-button" onClick={this.submitMoves}>Save</button>
                    </div>
                    <div className="apply-settings">
                        <CustomCheckBox toggle={this.clickCustomeCheckBoxService} selected={this.state.applyToOtherBranches} />
                        <div className="label">Apply These Settings To Other Plants/Depots As Well</div>
                    </div>
                </div>
                {/* </div> */}
                {this.state.applyToOtherBranches && <SelectBranches company_id={this.props.company_id} setting_id={this.props.flowId} toggle={this.clickCustomeCheckBoxService} applyBranches={this.props.applyBranches} branchList={this.props.branchList} getBranches={this.props.getBranches} isFlow={true}/>}
            </div>
        );
    }
}

const CustomCheckBox = (props) => {
    return (
       <div className={props.selected ? "custome-checkbox-wrap selected" : "custome-checkbox-wrap"} onClick={() => props.toggle()}>
          {props.selected && <div className="inner-circle"></div>}
       </div>
    )
 }

export default withRouter(Addindents);