import React, { Fragment } from "react";
import Delete from "../../../assets/images/delete.svg";
import Edit from "../../../assets/images/edit.svg";
import moment from "moment";
import { BsClockFill } from "react-icons/bs";
import { RiRadioButtonLine } from "react-icons/ri";
import { IoIosAlert } from "react-icons/io";
const BannerMessagesListTableSection = (props) => {
  const {
    bannerMessagesList,
    handleRowHover,
    handleRowLeave,
    hoveredRow,
    getModuleNames,
    editMessage,
    deleteMessage,
    gmtOffset
  } = props;

  const renderMessageStatus = (row) => {
  const now = moment();
  const startTime = moment.utc(row.start_time).valueOf();
  const endTime = moment.utc(row.end_time).valueOf();

  const scheduled = now.isBefore(startTime);
  const live = now.isBetween(startTime, endTime);
  const expired = now.isAfter(endTime);
  const forceStopped = !row.is_active;
    if(forceStopped){
      return (
        <div
          style={{ color: "#FF0000" }}
          className="flex flex-items-middle"
        >
          <IoIosAlert />
          &nbsp;Stopped
        </div>
      )
    }
    if (scheduled) {
      return (
        <div
          style={{ color: "#FF9901" }}
          className="flex flex-items-middle"
        >
          <BsClockFill />
          &nbsp;Scheduled
        </div>
      )
    }
    if (live) {
      return (
        <div
          style={{ color: "#00cc00" }}
          className="flex flex-items-middle"
        >
          <RiRadioButtonLine />
          &nbsp;Live
        </div>
      )
    }
    if (expired) {
      return (
        <div
        style={{ color: "#7a7a52" }}
        className="flex flex-items-middle"
      >
        <RiRadioButtonLine />
        &nbsp;Expired
      </div>
      )
    }
    return null
  }

  return (
    <Fragment>
      <div className="flex items-center justify-between">
        <div className="table-container121">
          <table className="enlarging-table121">
            <thead>
              <tr>
                <th className="blue-header121">Status</th>
                <th className="blue-header121">Message</th>
                <th className="blue-header121">Start Time</th>
                <th className="blue-header121">End Time</th>
                <th className="blue-header121">Modules</th>
                <th className="blue-header121">Actions</th>
              </tr>
            </thead>
            <tbody>
              {(bannerMessagesList || []).map((row, index) => (
                <tr
                  key={index}
                  className={index === hoveredRow ? "enlarged-row121" : ""}
                  onMouseEnter={() => handleRowHover(index)}
                  onMouseLeave={handleRowLeave}
                >
                  <td>
                    <div
                      style={{
                        // fontWeight: "900",
                        fontSize: "18px",
                      }}
                      className=" wt-100p"
                    >
                      {renderMessageStatus(row)}

                    </div>
                  </td>
                  <td>{row.message}</td>
                  <td>
                    {moment.utc(row.start_time).local().format("DD/MM/YYYY (HH:mm a)")}
                  </td>
                  <td>
                  {moment.utc(row.end_time).local().format("DD/MM/YYYY (HH:mm a)")}
                  </td>
                  <td>{getModuleNames(row.module_ids)}</td>
                  <td>
                    <div
                      className={"action-icon121"}
                      style={{ backgroundImage: `url(${Edit})` }}
                      onClick={() => editMessage(row)}
                    />
                    <div
                      className={"action-icon121"}
                      style={{ backgroundImage: `url(${Delete})` }}
                      onClick={() => deleteMessage(row)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Fragment>
  );
};

export default BannerMessagesListTableSection;
