import React, { Component, Fragment } from 'react';
import './transporters_mapping.less';
import Back from '../../../assets/images/back.svg';
import Forward from '../../../assets/images/forward.svg';
import Delete from '../../../assets/images/delete.svg';
import Search from '../../common/components/search-text/search-text';


class TransportersList extends Component {
    constructor() {
        super();
        this.state = {
            transporters : [],
            limit: 20,
            offset: 0,
            search: '',
            isSuccess: true,
            isMultimode: false,
            isExim: false
        }
    }

    componentWillMount(){
        this.props.getCompanyTransporters(this.state.isMultimode, this.state.isWarehouse, this.state.isExim);
        if(this.props.subHeading === 'Branch Transporters') {
            this.props.getBranchTransporters();
        }
    }

    onSelectTransporter = (value) => {
        const { transporters } = this.state;
        const index = transporters.findIndex(trans => {
            return trans.id === value.id;
        });
        if( index > -1 ) {
            transporters.splice(index, 1);
        } else {
            transporters.push({
                id: value.id,
                company_name: value.company_name
            });
        }
        this.setState({ transporters, isSuccess: true });
    }

    addTransporters = (companyType) => {
        const { transporters, isMultimode, isWarehouse, isExim } = this.state;
        const data = {
            transporters,
            is_multimode: isMultimode,
            is_warehouse: isWarehouse,
            is_exim: isExim
        }
        this.props.addCompanyTransporters(data, companyType);
        this.setState({ isSuccess: false });
    }

    onClickPagination = (type, companyTypeTo) => {
        let { offset, isMultimode, isWarehouse, isExim } = this.state;
        if(type === 'back' && offset > 0) {
            offset -= 20;
            if(this.props.getTransporters) {
                this.props.getTransporters(offset, this.state.search, companyTypeTo, isMultimode, isWarehouse, isExim);
            }
            this.setState({ offset, isSuccess: true });
        } else if(type === 'forward'){
            offset += 20;
            this.setState({ offset, isSuccess: true });
            if(this.props.getTransporters) {
                this.props.getTransporters(offset, this.state.search, companyTypeTo, isMultimode, isWarehouse, isExim);
            }
        }
    }

    changeHandler = (value) => {
        this.setState({ search: value, offset: 0 }, () => {
            if(this.props.getTransporters) {                             
                this.props.getTransporters(this.state.offset, this.state.search, 0, this.state.isMultimode, this.state.isWarehouse, this.state.isExim);
            }
        });                 
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.isSuccess && !this.state.isSuccess) {
            this.setState({ transporters: [] });
        }
    }

    render() {
        const { transporterList, companyTransporters, heading, subHeading, company_type } = this.props;
        const { transporters, search, isMultimode, isWarehouse, isExim } = this.state;

        return (
            <Fragment>
                <div className="transporter-mapping">
                    <div className="section-item-company ftl">
                        <div className={(!isMultimode && !isWarehouse) ? "custome-checkbox-wrap selected" : "custome-checkbox-wrap"} onClick={() => this.setState({ isMultimode: false , isWarehouse: false, isExim: false}, () => {
                            this.props.getCompanyTransporters(this.state.isMultimode, this.state.isWarehouse, this.state.isExim);
                            if(this.props.getTransporters) {
                                this.props.getTransporters(this.state.offset, this.state.search, 0, this.state.isMultimode, this.state.isWarehouse, this.state.isExim);
                            }
                        })}>
                                {(!isMultimode && !isWarehouse && !isExim) && <div className="inner-circle"></div>}
                        </div>
                        <div className="title">FTL</div>
                    </div>
                    <div className="section-item-company">
                        <div className={(isMultimode && !isWarehouse && !isExim) ? "custome-checkbox-wrap selected" : "custome-checkbox-wrap"} onClick={() => this.setState({ isMultimode: true, isWarehouse: false, isExim: false}, () => {
                            this.props.getCompanyTransporters(this.state.isMultimode);
                            if(this.props.getTransporters) {
                                this.props.getTransporters(this.state.offset, this.state.search, 0, this.state.isMultimode, this.state.isWarehouse,  this.state.isExim);
                            }
                            })}>
                                {(isMultimode && !isWarehouse && !isExim) && <div className="inner-circle"></div>}
                        </div>
                        <div className="title">Multi Mode</div>
                    </div>
                    <div className="section-item-company">
                        <div className={(!isMultimode && isWarehouse && !isExim) ? "custome-checkbox-wrap selected" : "custome-checkbox-wrap"} onClick={() => this.setState({ isWarehouse: true, isMultimode: false, isExim: false}, () => {
                            this.props.getCompanyTransporters(this.state.isMultimode, this.state.isWarehouse, this.state.isExim);
                            if(this.props.getTransporters) {
                                this.props.getTransporters(this.state.offset, this.state.search, 0, this.state.isMultimode, this.state.isWarehouse, this.state.isExim);
                            }                            
                        })}>
                                {(!isMultimode && isWarehouse) && <div className="inner-circle"></div>}
                        </div>
                        <div className="title">Warehouse</div>
                    </div>
                    <div className="section-item-company">
                        <div className={(!isMultimode && !isWarehouse && isExim) ? "custome-checkbox-wrap selected" : "custome-checkbox-wrap"} onClick={() => this.setState({ isWarehouse: false, isMultimode: false, isExim: true}, () => {
                            this.props.getCompanyTransporters(this.state.isMultimode, this.state.isWarehouse, this.state.isExim);
                            if(this.props.getTransporters) {
                                this.props.getTransporters(this.state.offset, this.state.search, 0, this.state.isMultimode, this.state.isWarehouse, this.state.isExim);
                            }                            
                        })}>
                                {(!isMultimode && !isWarehouse && isExim) && <div className="inner-circle"></div>}
                        </div>
                        <div className="title">EXIM</div>
                    </div>
                </div>
            {(!company_type || company_type === 1) && <div className={'transporter-list'}>
                <div className={'col-2 mr-10'}>
                    <div className={'header'}>
                        <div>{heading}</div>
                        {subHeading !== 'Branch Transporters' &&  <div>
                        <span className={'delete-icon'} style={{backgroundImage: `url(${Back})`}} onClick={() => this.onClickPagination('back', 2)}></span>
                        <span className={'delete-icon'} style={{backgroundImage: `url(${Forward})`}} onClick={() => this.onClickPagination('forward', 2)} ></span>
                        </div>}
                        <div className ="add-button" onClick={() => this.addTransporters(2)}>ADD</div>
                    </div>
                    <div>
                        {subHeading !== 'Branch Transporters' && <Search value = {search} placeholder = "Search Transporter..." changeHandler = {this.changeHandler}/>}
                        {transporterList && transporterList.length > 0 && transporterList.map((trans, index) => {
                        return ((!trans.company_type || trans.company_type === 2)&& <div className={'lists'} key={`trans-${index}`}>
                            <CustomCheckBox selected={transporters.findIndex(transport => { return transport.id === trans.id }) > -1 ? true : false} toggle={this.onSelectTransporter} id={trans}/>
                            <div className={'ml-10'}>{trans.company_name}</div>
                        </div>)})}
                    </div>
                </div>
                <div className={'col-2 mr-10'}>
                    <div className={'header'}>{subHeading}</div>
                    {companyTransporters && companyTransporters.length > 0 && companyTransporters.map((compyTrans, index) => {
                        return(
                            (!compyTrans.company_type || compyTrans.company_type === 2)&& <div className={'lists space-btw'} key={`compTrans-${index}`}>
                                <div>{compyTrans.company_name}</div>
                                <div className={'delete-icon'} style={{backgroundImage:`url(${Delete})`}} onClick={() => this.props.onDeleteTransporter(compyTrans.id, 2, isMultimode, isWarehouse, isExim)}></div>
                            </div>
                        )
                    }) }
                </div>
            </div>
            }
            {!isExim && <>
            <div className={'transporter-list'}>
                <div className={'col-2 mr-10'}>
                    <div className={'header'}>
                        <div>{'3PL'}</div>
                        {subHeading !== 'Branch Transporters' &&  <div>
                        <span className={'delete-icon'} style={{backgroundImage: `url(${Back})`}} onClick={() => this.onClickPagination('back', 3)}></span>
                        <span className={'delete-icon'} style={{backgroundImage: `url(${Forward})`}} onClick={() => this.onClickPagination('forward', 3)} ></span>
                        </div>}
                        <div className ="add-button" onClick={() => this.addTransporters(3)}>ADD</div>
                    </div>
                    <div>
                        {subHeading !== 'Branch Transporters' && <Search value = {search} placeholder = "Search 3PL..." changeHandler = {this.changeHandler}/>}
                        {transporterList && transporterList.length > 0 && transporterList.map((trans, index) => {
                        return ( trans.company_type === 3 && <div className={'lists'} key={`trans-${index}`}>
                            <CustomCheckBox selected={transporters.findIndex(transport => { return transport.id === trans.id}) > -1 ? true : false} toggle={this.onSelectTransporter} id={trans}/>
                            <div className={'ml-10'}>{trans.company_name}</div>
                        </div>)})}
                    </div>
                </div>
                <div className={'col-2 mr-10'}>
                    <div className={'header'}>{'My 3PL'}</div>
                    {companyTransporters && companyTransporters.length > 0 && companyTransporters.map((compyTrans, index) => {
                        return(
                            compyTrans.company_type === 3 && <div className={'lists space-btw'} key={`compTrans-${index}`}>
                                <div>{compyTrans.company_name}</div>
                                <div className={'delete-icon'} style={{backgroundImage:`url(${Delete})`}} onClick={() => this.props.onDeleteTransporter(compyTrans.id,3, isMultimode, isWarehouse, isExim)}></div>
                            </div>
                        )
                    }) }
                </div>
            </div>
            <div className={'transporter-list'}>
                <div className={'col-2 mr-10'}>
                    <div className={'header'}>
                        <div>{'Fleet Owner And Broker'}</div>
                        {subHeading !== 'Branch Transporters' &&  <div>
                        <span className={'delete-icon'} style={{backgroundImage: `url(${Back})`}} onClick={() => this.onClickPagination('back')}></span>
                        <span className={'delete-icon'} style={{backgroundImage: `url(${Forward})`}} onClick={() => this.onClickPagination('forward')} ></span>
                        </div>}
                        <div className ="add-button" onClick={() => this.addTransporters(4)}>ADD</div>
                    </div>
                    <div>
                        {subHeading !== 'Branch Transporters' && <Search value = {search} placeholder = "Search Fleet Owner And Broker..." changeHandler = {this.changeHandler}/>}
                        {transporterList && transporterList.length > 0 && transporterList.map((trans, index) => {
                        return ( trans.company_type === 4 && <div className={'lists'} key={`trans-${index}`}>
                            <CustomCheckBox selected={transporters.findIndex(transport => { return transport.id === trans.id}) > -1 ? true : false} toggle={this.onSelectTransporter} id={trans}/>
                            <div className={'ml-10'}>{trans.company_name}</div>
                        </div>)})}
                    </div>
                </div>
                <div className={'col-2 mr-10'}>
                    <div className={'header'}>{'My Fleet Owner And Broker'}</div>
                    {companyTransporters && companyTransporters.length > 0 && companyTransporters.map((compyTrans, index) => {
                        return(
                            compyTrans.company_type === 4 && <div className={'lists space-btw'} key={`compTrans-${index}`}>
                                <div>{compyTrans.company_name}</div>
                                <div className={'delete-icon'} style={{backgroundImage:`url(${Delete})`}} onClick={() => this.props.onDeleteTransporter(compyTrans.id, 4, isMultimode, isWarehouse, isExim)}></div>
                            </div>
                        )
                    }) }
                </div>
            </div>
            <div className={'transporter-list'}>
                <div className={'col-2 mr-10'}>
                    <div className={'header'}>
                        <div>{'Fleet Owner'}</div>
                        {subHeading !== 'Branch Transporters' &&  <div>
                        <span className={'delete-icon'} style={{backgroundImage: `url(${Back})`}} onClick={() => this.onClickPagination('back')}></span>
                        <span className={'delete-icon'} style={{backgroundImage: `url(${Forward})`}} onClick={() => this.onClickPagination('forward')} ></span>
                        </div>}
                        <div className ="add-button" onClick={() => this.addTransporters(5)}>ADD</div>
                    </div>
                    <div>
                        {subHeading !== 'Branch Transporters' && <Search value = {search} placeholder = "Search Fleet Owner..." changeHandler = {this.changeHandler}/>}
                        {transporterList && transporterList.length > 0 && transporterList.map((trans, index) => {
                        return ( trans.company_type === 5 && <div className={'lists'} key={`trans-${index}`}>
                            <CustomCheckBox selected={transporters.findIndex(transport => { return transport.id === trans.id}) > -1 ? true : false} toggle={this.onSelectTransporter} id={trans}/>
                            <div className={'ml-10'}>{trans.company_name}</div>
                        </div>)})}
                    </div>
                </div>
                <div className={'col-2 mr-10'}>
                    <div className={'header'}>{'My Fleet Owner'}</div>
                    {companyTransporters && companyTransporters.length > 0 && companyTransporters.map((compyTrans, index) => {
                        return(
                            compyTrans.company_type === 5 && <div className={'lists space-btw'} key={`compTrans-${index}`}>
                                <div>{compyTrans.company_name}</div>
                                <div className={'delete-icon'} style={{backgroundImage:`url(${Delete})`}} onClick={() => this.props.onDeleteTransporter(compyTrans.id, 5, isMultimode, isWarehouse, isExim)}></div>
                            </div>
                        )
                    }) }
                </div>
            </div>
                        <div className={'transporter-list'}>
                <div className={'col-2 mr-10'}>
                    <div className={'header'}>
                        <div>{'Broker'}</div>
                        {subHeading !== 'Branch Transporters' &&  <div>
                        <span className={'delete-icon'} style={{backgroundImage: `url(${Back})`}} onClick={() => this.onClickPagination('back')}></span>
                        <span className={'delete-icon'} style={{backgroundImage: `url(${Forward})`}} onClick={() => this.onClickPagination('forward')} ></span>
                        </div>}
                        <div className ="add-button" onClick={() => this.addTransporters(6)}>ADD</div>
                    </div>
                    <div>
                        {subHeading !== 'Branch Transporters' && <Search value = {search} placeholder = "Search Broker..." changeHandler = {this.changeHandler}/>}
                        {transporterList && transporterList.length > 0 && transporterList.map((trans, index) => {
                        return ( trans.company_type === 6 && <div className={'lists'} key={`trans-${index}`}>
                            <CustomCheckBox selected={transporters.findIndex(transport => { return transport.id === trans.id}) > -1 ? true : false} toggle={this.onSelectTransporter} id={trans}/>
                            <div className={'ml-10'}>{trans.company_name}</div>
                        </div>)})}
                    </div>
                </div>
                <div className={'col-2 mr-10'}>
                    <div className={'header'}>{'My Broker'}</div>
                    {companyTransporters && companyTransporters.length > 0 && companyTransporters.map((compyTrans, index) => {
                        return(
                            compyTrans.company_type === 6 && <div className={'lists space-btw'} key={`compTrans-${index}`}>
                                <div>{compyTrans.company_name}</div>
                                <div className={'delete-icon'} style={{backgroundImage:`url(${Delete})`}} onClick={() => this.props.onDeleteTransporter(compyTrans.id, 6, isMultimode, isWarehouse, isExim)}></div>
                            </div>
                        )
                    }) }
                </div>
            </div>
                        <div className={'transporter-list'}>
                <div className={'col-2 mr-10'}>
                    <div className={'header'}>
                        <div>{'Fleet Owner And Transporter'}</div>
                        {subHeading !== 'Branch Transporters' &&  <div>
                        <span className={'delete-icon'} style={{backgroundImage: `url(${Back})`}} onClick={() => this.onClickPagination('back')}></span>
                        <span className={'delete-icon'} style={{backgroundImage: `url(${Forward})`}} onClick={() => this.onClickPagination('forward')} ></span>
                        </div>}
                        <div className ="add-button" onClick={() => this.addTransporters(7)}>ADD</div>
                    </div>
                    <div>
                        {subHeading !== 'Branch Transporters' && <Search value = {search} placeholder = "Search Fleet Owner And Transporter..." changeHandler = {this.changeHandler}/>}
                        {transporterList && transporterList.length > 0 && transporterList.map((trans, index) => {
                        return ( trans.company_type === 7 && <div className={'lists'} key={`trans-${index}`}>
                            <CustomCheckBox selected={transporters.findIndex(transport => { return transport.id === trans.id}) > -1 ? true : false} toggle={this.onSelectTransporter} id={trans}/>
                            <div className={'ml-10'}>{trans.company_name}</div>
                        </div>)})}
                    </div>
                </div>
                <div className={'col-2 mr-10'}>
                    <div className={'header'}>{'My Fleet Owner And Transporter'}</div>
                    {companyTransporters && companyTransporters.length > 0 && companyTransporters.map((compyTrans, index) => {
                        return(
                            compyTrans.company_type === 7 && <div className={'lists space-btw'} key={`compTrans-${index}`}>
                                <div>{compyTrans.company_name}</div>
                                <div className={'delete-icon'} style={{backgroundImage:`url(${Delete})`}} onClick={() => this.props.onDeleteTransporter(compyTrans.id, 7, isMultimode, isWarehouse, isExim)}></div>
                            </div>
                        )
                    }) }
                </div>
            </div>
                        {/* <div className={'transporter-list'}>
                <div className={'col-2 mr-10'}>
                    <div className={'header'}>
                        <div>{'Aggregators'}</div>
                        {subHeading !== 'Branch Transporters' &&  <div>
                        <span className={'delete-icon'} style={{backgroundImage: `url(${Back})`}} onClick={() => this.onClickPagination('back')}></span>
                        <span className={'delete-icon'} style={{backgroundImage: `url(${Forward})`}} onClick={() => this.onClickPagination('forward')} ></span>
                        </div>}
                        <div className ="add-button" onClick={() => this.addTransporters(8)}>ADD</div>
                    </div>
                    <div>
                        {subHeading !== 'Branch Transporters' && <Search value = {search} placeholder = "Search Aggregators..." changeHandler = {this.changeHandler}/>}
                        {transporterList && transporterList.length > 0 && transporterList.map((trans, index) => {
                        return ( trans.company_type === 8 && <div className={'lists'} key={`trans-${index}`}>
                            <CustomCheckBox selected={transporters.findIndex(transport => { return transport.id === trans.id}) > -1 ? true : false} toggle={this.onSelectTransporter} id={trans}/>
                            <div className={'ml-10'}>{trans.company_name}</div>
                        </div>)})}
                    </div>
                </div>
                <div className={'col-2 mr-10'}>
                    <div className={'header'}>{'My Aggregators'}</div>
                    {companyTransporters && companyTransporters.length > 0 && companyTransporters.map((compyTrans, index) => {
                        return(
                            compyTrans.company_type === 8 && <div className={'lists space-btw'} key={`compTrans-${index}`}>
                                <div>{compyTrans.company_name}</div>
                                <div className={'delete-icon'} style={{backgroundImage:`url(${Delete})`}} onClick={() => this.props.onDeleteTransporter(compyTrans.id, 8)}></div>
                            </div>
                        )
                    }) }
                </div>
            </div> */}
                        <div className={'transporter-list'}>
                <div className={'col-2 mr-10'}>
                    <div className={'header'}>
                        <div>{'Surveyor'}</div>
                        {subHeading !== 'Branch Transporters' &&  <div>
                        <span className={'delete-icon'} style={{backgroundImage: `url(${Back})`}} onClick={() => this.onClickPagination('back')}></span>
                        <span className={'delete-icon'} style={{backgroundImage: `url(${Forward})`}} onClick={() => this.onClickPagination('forward')} ></span>
                        </div>}
                        <div className ="add-button" onClick={() => this.addTransporters(9)}>ADD</div>
                    </div>
                    <div>
                        {subHeading !== 'Branch Transporters' && <Search value = {search} placeholder = "Search Surveyors..." changeHandler = {this.changeHandler}/>}
                        {transporterList && transporterList.length > 0 && transporterList.map((trans, index) => {
                        return ( trans.company_type === 9 && <div className={'lists'} key={`trans-${index}`}>
                            <CustomCheckBox selected={transporters.findIndex(transport => { return transport.id === trans.id}) > -1 ? true : false} toggle={this.onSelectTransporter} id={trans}/>
                            <div className={'ml-10'}>{trans.company_name}</div>
                        </div>)})}
                    </div>
                </div>
                <div className={'col-2 mr-10'}>
                    <div className={'header'}>{'My Surveyor'}</div>
                    {companyTransporters && companyTransporters.length > 0 && companyTransporters.map((compyTrans, index) => {
                        return(
                            compyTrans.company_type === 9 && <div className={'lists space-btw'} key={`compTrans-${index}`}>
                                <div>{compyTrans.company_name}</div>
                                <div className={'delete-icon'} style={{backgroundImage:`url(${Delete})`}} onClick={() => this.props.onDeleteTransporter(compyTrans.id, 9, isMultimode, isWarehouse, isExim)}></div>
                            </div>
                        )
                    }) }
                </div>
            </div>
                        <div className={'transporter-list'}>
                <div className={'col-2 mr-10'}>
                    <div className={'header'}>
                        <div>{'Lashers'}</div>
                        {subHeading !== 'Branch Transporters' &&  <div>
                        <span className={'delete-icon'} style={{backgroundImage: `url(${Back})`}} onClick={() => this.onClickPagination('back')}></span>
                        <span className={'delete-icon'} style={{backgroundImage: `url(${Forward})`}} onClick={() => this.onClickPagination('forward')} ></span>
                        </div>}
                        <div className ="add-button" onClick={() => this.addTransporters(10)}>ADD</div>
                    </div>
                    <div>
                        {subHeading !== 'Branch Transporters' && <Search value = {search} placeholder = "Search Lashers..." changeHandler = {this.changeHandler}/>}
                        {transporterList && transporterList.length > 0 && transporterList.map((trans, index) => {
                        return ( trans.company_type === 10 && <div className={'lists'} key={`trans-${index}`}>
                            <CustomCheckBox selected={transporters.findIndex(transport => { return transport.id === trans.id}) > -1 ? true : false} toggle={this.onSelectTransporter} id={trans}/>
                            <div className={'ml-10'}>{trans.company_name}</div>
                        </div>)})}
                    </div>
                </div>
                <div className={'col-2 mr-10'}>
                    <div className={'header'}>{'My Lashers'}</div>
                    {companyTransporters && companyTransporters.length > 0 && companyTransporters.map((compyTrans, index) => {
                        return(
                            compyTrans.company_type === 10 && <div className={'lists space-btw'} key={`compTrans-${index}`}>
                                <div>{compyTrans.company_name}</div>
                                <div className={'delete-icon'} style={{backgroundImage:`url(${Delete})`}} onClick={() => this.props.onDeleteTransporter(compyTrans.id, 10, isMultimode, isWarehouse, isExim)}></div>
                            </div>
                        )
                    }) }
                </div>
            </div>

            <div className={'transporter-list'}>
                <div className={'col-2 mr-10'}>
                    <div className={'header'}>
                        <div>{'Handling Agent'}</div>
                        {subHeading !== 'Branch Transporters' &&  <div>
                        <span className={'delete-icon'} style={{backgroundImage: `url(${Back})`}} onClick={() => this.onClickPagination('back')}></span>
                        <span className={'delete-icon'} style={{backgroundImage: `url(${Forward})`}} onClick={() => this.onClickPagination('forward')} ></span>
                        </div>}
                        <div className ="add-button" onClick={() => this.addTransporters(21)}>ADD</div>
                    </div>
                    <div>
                        {subHeading !== 'Branch Transporters' && <Search value = {search} placeholder = "Search Handling Agent..." changeHandler = {this.changeHandler}/>}
                        {transporterList && transporterList.length > 0 && transporterList.map((trans, index) => {
                        return ( trans.company_type === 21 && <div className={'lists'} key={`trans-${index}`}>
                            <CustomCheckBox selected={transporters.findIndex(transport => { return transport.id === trans.id}) > -1 ? true : false} toggle={this.onSelectTransporter} id={trans}/>
                            <div className={'ml-10'}>{trans.company_name}</div>
                        </div>)})}
                    </div>
                </div>
                <div className={'col-2 mr-10'}>
                    <div className={'header'}>{'My Handling Agent'}</div>
                    {companyTransporters && companyTransporters.length > 0 && companyTransporters.map((compyTrans, index) => {
                        return(
                            compyTrans.company_type === 21 && <div className={'lists space-btw'} key={`compTrans-${index}`}>
                                <div>{compyTrans.company_name}</div>
                                
                                <div className={'delete-icon'} style={{backgroundImage:`url(${Delete})`}} onClick={() => this.props.onDeleteTransporter(compyTrans.id, 21, isMultimode, isWarehouse, isExim)}></div>
                            </div>
                        )
                    }) }
                </div>
            </div>

            <div className={'transporter-list'}>
                <div className={'col-2 mr-10'}>
                    <div className={'header'}>
                        <div>{'Shipping Liners'}</div>
                        {subHeading !== 'Branch Transporters' &&  <div>
                        <span className={'delete-icon'} style={{backgroundImage: `url(${Back})`}} onClick={() => this.onClickPagination('back')}></span>
                        <span className={'delete-icon'} style={{backgroundImage: `url(${Forward})`}} onClick={() => this.onClickPagination('forward')} ></span>
                        </div>}
                        <div className ="add-button" onClick={() => this.addTransporters(22)}>ADD</div>
                    </div>
                    <div>
                        {subHeading !== 'Branch Transporters' && <Search value = {search} placeholder = "Search Shipping Liners..." changeHandler = {this.changeHandler}/>}
                        {transporterList && transporterList.length > 0 && transporterList.map((trans, index) => {
                        return ( trans.company_type === 22 && <div className={'lists'} key={`trans-${index}`}>
                            <CustomCheckBox selected={transporters.findIndex(transport => { return transport.id === trans.id}) > -1 ? true : false} toggle={this.onSelectTransporter} id={trans}/>
                            <div className={'ml-10'}>{trans.company_name}</div>
                        </div>)})}
                    </div>
                </div>
                <div className={'col-2 mr-10'}>
                    <div className={'header'}>{'MY Shipping Liners'}</div>
                    {companyTransporters && companyTransporters.length > 0 && companyTransporters.map((compyTrans, index) => {
                        return(
                            compyTrans.company_type === 22 && <div className={'lists space-btw'} key={`compTrans-${index}`}>
                                <div>{compyTrans.company_name}</div>
                                <div className={'delete-icon'} style={{backgroundImage:`url(${Delete})`}} onClick={() => this.props.onDeleteTransporter(compyTrans.id, 22, isMultimode, isWarehouse, isExim)}></div>
                            </div>
                        )
                    }) }
                </div>
            </div>
            <div className={'transporter-list'}>
                <div className={'col-2 mr-10'}>
                    <div className={'header'}>
                        <div>{'Buyers'}</div>
                        {subHeading !== 'Branch Transporters' &&  <div>
                        <span className={'delete-icon'} style={{backgroundImage: `url(${Back})`}} onClick={() => this.onClickPagination('back')}></span>
                        <span className={'delete-icon'} style={{backgroundImage: `url(${Forward})`}} onClick={() => this.onClickPagination('forward')} ></span>
                        </div>}
                        <div className ="add-button" onClick={() => this.addTransporters(24)}>ADD</div>
                    </div>
                    <div>
                        {subHeading !== 'Branch Transporters' && <Search value = {search} placeholder = "Search Buyers..." changeHandler = {this.changeHandler}/>}
                        {transporterList && transporterList.length > 0 && transporterList.map((trans, index) => {
                        return ( trans.company_type === 24 && <div className={'lists'} key={`trans-${index}`}>
                            <CustomCheckBox selected={transporters.findIndex(transport => { return transport.id === trans.id}) > -1 ? true : false} toggle={this.onSelectTransporter} id={trans}/>
                            <div className={'ml-10'}>{trans.company_name}</div>
                        </div>)})}
                    </div>
                </div>
                <div className={'col-2 mr-10'}>
                    <div className={'header'}>{'Buyers'}</div>
                    {companyTransporters && companyTransporters.length > 0 && companyTransporters.map((compyTrans, index) => {
                        return(
                            compyTrans.company_type === 24 && <div className={'lists space-btw'} key={`compTrans-${index}`}>
                                <div>{compyTrans.company_name}</div>
                                <div className={'delete-icon'} style={{backgroundImage:`url(${Delete})`}} onClick={() => this.props.onDeleteTransporter(compyTrans.id, 24, isMultimode, isWarehouse, isExim)}></div>
                            </div>
                        )
                    }) }
                </div>
            </div>
            </>}
            <div className={'transporter-list'}>
                <div className={'col-2 mr-10'}>
                    <div className={'header'}>
                        <div>{'CHA'}</div>
                        {subHeading !== 'Branch Transporters' &&  <div>
                        <span className={'delete-icon'} style={{backgroundImage: `url(${Back})`}} onClick={() => this.onClickPagination('back')}></span>
                        <span className={'delete-icon'} style={{backgroundImage: `url(${Forward})`}} onClick={() => this.onClickPagination('forward')} ></span>
                        </div>}
                        <div className ="add-button" onClick={() => this.addTransporters(13)}>ADD</div>
                    </div>
                    <div>
                        {subHeading !== 'Branch Transporters' && <Search value = {search} placeholder = "Search Buyers..." changeHandler = {this.changeHandler}/>}
                        {transporterList && transporterList.length > 0 && transporterList.map((trans, index) => {
                        return ( trans.company_type === 13 && <div className={'lists'} key={`trans-${index}`}>
                            <CustomCheckBox selected={transporters.findIndex(transport => { return transport.id === trans.id}) > -1 ? true : false} toggle={this.onSelectTransporter} id={trans}/>
                            <div className={'ml-10'}>{trans.company_name}</div>
                        </div>)})}
                    </div>
                </div>
                <div className={'col-2 mr-10'}>
                    <div className={'header'}>{'CHA'}</div>
                    {companyTransporters && companyTransporters.length > 0 && companyTransporters.map((compyTrans, index) => {
                        return(
                            compyTrans.company_type === 13 && <div className={'lists space-btw'} key={`compTrans-${index}`}>
                                <div>{compyTrans.company_name}</div>
                                <div className={'delete-icon'} style={{backgroundImage:`url(${Delete})`}} onClick={() => this.props.onDeleteTransporter(compyTrans.id, 13, isMultimode, isWarehouse, isExim)}></div>
                            </div>
                        )
                    }) }
                </div>
            </div>
            <div className={'transporter-list'}>
                <div className={'col-2 mr-10'}>
                    <div className={'header'}>
                        <div>{'CFS'}</div>
                        {subHeading !== 'Branch Transporters' &&  <div>
                        <span className={'delete-icon'} style={{backgroundImage: `url(${Back})`}} onClick={() => this.onClickPagination('back')}></span>
                        <span className={'delete-icon'} style={{backgroundImage: `url(${Forward})`}} onClick={() => this.onClickPagination('forward')} ></span>
                        </div>}
                        <div className ="add-button" onClick={() => this.addTransporters(14)}>ADD</div>
                    </div>
                    <div>
                        {subHeading !== 'Branch Transporters' && <Search value = {search} placeholder = "Search Buyers..." changeHandler = {this.changeHandler}/>}
                        {transporterList && transporterList.length > 0 && transporterList.map((trans, index) => {
                        return ( trans.company_type === 14 && <div className={'lists'} key={`trans-${index}`}>
                            <CustomCheckBox selected={transporters.findIndex(transport => { return transport.id === trans.id}) > -1 ? true : false} toggle={this.onSelectTransporter} id={trans}/>
                            <div className={'ml-10'}>{trans.company_name}</div>
                        </div>)})}
                    </div>
                </div>
                <div className={'col-2 mr-10'}>
                    <div className={'header'}>{'CFS'}</div>
                    {companyTransporters && companyTransporters.length > 0 && companyTransporters.map((compyTrans, index) => {
                        return(
                            compyTrans.company_type === 14 && <div className={'lists space-btw'} key={`compTrans-${index}`}>
                                <div>{compyTrans.company_name}</div>
                                <div className={'delete-icon'} style={{backgroundImage:`url(${Delete})`}} onClick={() => this.props.onDeleteTransporter(compyTrans.id, 14, isMultimode, isWarehouse, isExim)}></div>
                            </div>
                        )
                    }) }
                </div>
            </div>
            <div className={'transporter-list'}>
                <div className={'col-2 mr-10'}>
                    <div className={'header'}>
                        <div>{'Freight Forwarder'}</div>
                        {subHeading !== 'Branch Transporters' &&  <div>
                        <span className={'delete-icon'} style={{backgroundImage: `url(${Back})`}} onClick={() => this.onClickPagination('back')}></span>
                        <span className={'delete-icon'} style={{backgroundImage: `url(${Forward})`}} onClick={() => this.onClickPagination('forward')} ></span>
                        </div>}
                        <div className ="add-button" onClick={() => this.addTransporters(27)}>ADD</div>
                    </div>
                    <div>
                        {subHeading !== 'Branch Transporters' && <Search value = {search} placeholder = "Search Buyers..." changeHandler = {this.changeHandler}/>}
                        {transporterList && transporterList.length > 0 && transporterList.map((trans, index) => {
                        return ( trans.company_type === 27 && <div className={'lists'} key={`trans-${index}`}>
                            <CustomCheckBox selected={transporters.findIndex(transport => { return transport.id === trans.id}) > -1 ? true : false} toggle={this.onSelectTransporter} id={trans}/>
                            <div className={'ml-10'}>{trans.company_name}</div>
                        </div>)})}
                    </div>
                </div>
                <div className={'col-2 mr-10'}>
                    <div className={'header'}>{'Freight Forwarder'}</div>
                    {companyTransporters && companyTransporters.length > 0 && companyTransporters.map((compyTrans, index) => {
                        return(
                            compyTrans.company_type === 27 && <div className={'lists space-btw'} key={`compTrans-${index}`}>
                                <div>{compyTrans.company_name}</div>
                                <div className={'delete-icon'} style={{backgroundImage:`url(${Delete})`}} onClick={() => this.props.onDeleteTransporter(compyTrans.id, 27, isMultimode, isWarehouse, isExim)}></div>
                            </div>
                        )
                    }) }
                </div>
            </div>
            </Fragment>
        )
    }
}

const CustomCheckBox = (props) =>{
    return(
        <div className = {props.selected ? "custome-checkbox-wrap selected" : "custome-checkbox-wrap"} onClick = {()=>props.toggle(props.id)}>
            {props.selected && <div className = "inner-circle"></div>}
        </div>
    )
}

export default TransportersList;