import React, { Fragment, PureComponent } from "react";
import Spacer from "../../components/common/Spacer";
import NavBarSection from "../AuditScreen/NavBarSection";
import LoaderComponent from "../../common/components/loader/loader";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "./banner.scss";
import BannerHeaderSection from "./bannerHeader";
import ACTIONS from "../../common/action_constant";
import BannerMessagesListTableSection from "./bannerMessagesListTable";
import AddEditBannerPopup from "./addEditBannerMessage";
import Button from "../../home/button-classic/button-classic";
import moment from "moment";
const moduleNames = {
  1: "Contracting",
  2: "Indenting",
  3: "VIMS",
  4: "Accounts",
  6: "Admin",
};
const gmtOffset = 5.5 * 60 * 60 * 1000;
const getModuleNames = (moduleIds) => {
  return moduleIds.map((moduleId) => moduleNames[moduleId]).join(", ");
};

class BannerSettings extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      bannerMessagesList: [],
      hoveredRow: null,
      isOpen: false,
    };
  }
  componentDidMount = () => {
    this.handleGetBannerList();
  };
  handleOnClickBackBtn = () => {
    const { history } = this.props;
    history.goBack();
  };
  handleGetBannerList = () => {
    const { dispatch, match } = this.props;
    // const {} = this.state;
    const queryParams = {
      limit: 50,
      offset: 0,
    };
    const onSuccess = (data = []) => {
      this.setState((prevState) => {
        const updatedState = { ...prevState };
        updatedState.bannerMessagesList = data;
        return updatedState;
      });
    };
    dispatch({
      type: ACTIONS.BANNER_SETTING.GET_BANNER_LIST,
      queryParams,
      onSuccess,
    });
  };
  editMessage = (data) => {
    this.setState({
      editData: data,
      isOpen: true,
    });
  };

  deleteMessage = (data) => {
    const { dispatch } = this.props;
    dispatch({
      type: ACTIONS.BANNER_SETTING.DELETE_BANNER_MESSAGE,
      queryString: data.msg_id,
      onSuccess: () => {
        this.handleGetBannerList();
      },
    });
  };
  handleRowHover = (index) => {
    this.setState({ hoveredRow: index });
  };

  handleRowLeave = () => {
    this.setState({ hoveredRow: null });
  };
  toggleAddEditPopup = () => {
    const { isOpen } = this.state;
    this.setState({ isOpen: !isOpen, editData: '' });
  };

  onSave = (data) => {
    console.log('data:', data)
    const {isOpen} = this.state;
    const { dispatch, company_id } = this.props;
    
    const payload={
      "end_time": moment.utc(data.end_time).valueOf(),
      "is_active": data.is_active,
      "message": data.message,
      "module_ids": data.module_ids,
      "start_time": moment.utc(data.start_time).valueOf(),
      "type": 2
    }
    dispatch({
      type: ACTIONS.BANNER_SETTING.ADD_BANNER_MESSAGE,
      payload,

      onSuccess: () => {
        this.setState({ isOpen: !isOpen},()=>{
          this.handleGetBannerList()
        });
      },
    });
  };
  
  onUpdate = (data, msg_id) => {
    const {isOpen}=this.state;
    const { dispatch } = this.props;
    const queryString  = msg_id;
    data.start_time = moment.utc(data.start_time).valueOf();
    data.end_time = moment.utc(data.end_time).valueOf();
    const payload = data;
    dispatch({
      type: ACTIONS.BANNER_SETTING.UPDATE_BANNER_MESSAGE,
      queryString,
      payload,
      onSuccess: () => {
        this.setState(
          { isOpen: !isOpen, editData: "" },()=>{
            this.handleGetBannerList()
          }
        );
      },
    });
  };

  render() {
    const { loader } = this.props;
    const { bannerMessagesList, editData, isOpen } = this.state;
    return (
      <Fragment>
        {!!loader.loaderT1 && <LoaderComponent />}
        <NavBarSection />
        <Spacer spacing={"40px"} />
        <div className="pageWrapperT121">
          <BannerHeaderSection
            handleOnClickBackBtn={this.handleOnClickBackBtn}
          />
          <Spacer spacing={"20px"} />
          <div className="flex justify-end ml--20">
            <Button
              value={"+ Create Message"}
              click={this.toggleAddEditPopup}
            />
          </div>

          <BannerMessagesListTableSection
            bannerMessagesList={bannerMessagesList}
            gmtOffset={gmtOffset}
            handleRowHover={this.handleRowHover}
            handleRowLeave={this.handleRowLeave}
            hoveredRow={this.state.hoveredRow}
            getModuleNames={getModuleNames}
            editMessage={this.editMessage}
            deleteMessage={this.deleteMessage}
          />
          {isOpen && (
            <AddEditBannerPopup
              editData={editData}
              gmtOffset={gmtOffset}
              toggleAddEditPopup={this.toggleAddEditPopup}
              onSave={this.onSave}
              onUpdate={this.onUpdate}
            />
          )}
        </div>
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  console.log("state:", state);
  return {
    loader: state.loader,
  };
};

export default connect(mapStateToProps)(withRouter(BannerSettings));
