import React, { Component, Fragment } from "react";
import Search from "../../common/components/search-text/search-text";
import Select from "react-select";
import Button from "../../home/button-classic/button-classic";
// import Delete from '../../../assets/images/delete.svg';
import Edit from "../../../assets/images/edit.svg";
import Delete from "../../../assets/images/delete.svg";
import { connect } from "react-redux";
import ACTIONS from "../../common/action_constant";
import AddRouteCode from "./add_route_code";
import RouteStagespopup from "./routestges";
import { toPascalCase } from "../../../utils/validate";
import Download from "../../../assets/images/direct-download.svg";
import { EMAMI_COMPANY_IDS, TTBIL_COMPANY_IDS } from "../../../utils/common";

const ROUTE_CODE_TYPE = [
  { value: 1, label: "Coastal" },
  { value: 2, label: "Rail" },
];

const MODE_TYPE = [
  {
    value: "Single Mode",
    id: 1,
  },
  {
    value: "Multi Mode",
    id: 2,
  },
];

class RouteCode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      offset: 0,
      selectedMode: 1,
      routeCodeType: 1,
      destination_list: ["ABC", "WER", "ERE"],
      isroutestagesOpen: false,
    };
  }

  componentWillMount() {
    this.getRouteCode();
    this.getState();
    this.getDplBranchList();
  }

  getRouteCode() {
    const { dispatch, company_id } = this.props;
    const { search, offset, routeCodeType, selectedMode } = this.state;
    const isAdaniWilmar = ["999", "872"].includes(company_id);
    const isEmami = EMAMI_COMPANY_IDS.includes(company_id);
    const param = {
      offset,
      limit: 10,
      companyId: company_id,
    };
    if (search) {
      param.search = search;
    }
    if (["737"].includes(company_id)) {
      param.routeCodeType = routeCodeType;
    }
    if (this.isMultimode(isAdaniWilmar, isEmami) && selectedMode == 2) {
      param.is_multimodal = true;
    }
    dispatch({
      type: ACTIONS.HOME.GET_ROUTE_CODE,
      param,
    });
  }
  getState = () => {
    const { dispatch } = this.props;
    dispatch({
      type: ACTIONS.MASTER.LOCATION_NETWORK.GET_STATE,
      data: { company_id: this.props.company_id },
    });
  };

  getDplBranchList = () => {
    const { dispatch } = this.props;
    dispatch({
      type: ACTIONS.MASTER.LOCATION_NETWORK.GET_DPL_BRANCH_LIST_DETAILS,
      data: { company_id: this.props.company_id },
    });
  };
  changeHandler = (value) => {
    this.setState({ search: value });
  };

  toggleAdd = () => {
    const { isOpen } = this.state;
    this.setState({ isOpen: !isOpen, editData: "" });
  };
  toggleRoutestagepopup = (route) => {
    const { isroutestagesOpen } = this.state;
    this.setState({ editData: route, isroutestagesOpen: !isroutestagesOpen });
  };

  onEditRoute = (route) => {
    this.setState({ editData: route, isOpen: true });
  };

  onSaveRoute = (data) => {
    const { search, offset, selectedMode } = this.state;
    const { dispatch, company_id } = this.props;
    const isAdaniWilmar = ["999", "872"].includes(company_id);
    const isEmami = EMAMI_COMPANY_IDS.includes(company_id);
    const param = {
      offset,
      limit: 10,
      companyId: company_id,
    };
    if (search) {
      param.search = search;
    }
    if (["737"].includes(company_id)) {
      param.routeCodeType = routeCodeType;
    }
    if (this.isMultimode(isAdaniWilmar, isEmami) && selectedMode == 2) {
      param.is_multimodal = true;
    }
    dispatch({
      type: ACTIONS.HOME.ADD_ROUTE_CODE,
      data,
      param,
      onSuccess: () => {
        this.setState({ isOpen: false });
      },
    });
  };

  onSearch = () => {
    this.setState({ offset: 0 }, this.getRouteCode);
  };

  onUpdateRoute = (data) => {
    const { search, offset, routeCodeType, selectedMode } = this.state;
    const { dispatch, company_id } = this.props;
    const isAdaniWilmar = ["999", "872"].includes(company_id);
    const isEmami = EMAMI_COMPANY_IDS.includes(company_id);
    const param = {
      offset,
      limit: 10,
      companyId: company_id,
    };
    if (search) {
      param.search = search;
    }
    if (["737"].includes(company_id)) {
      param.routeCodeType = routeCodeType;
    }
    if (this.isMultimode(isAdaniWilmar, isEmami) && selectedMode == 2) {
      param.is_multimodal = true;
    }
    data.routestages = (data.routestages || []).map((ele) => {
      const newEle = {
        ...(ele || {}),
        legIndicator: +ele.legIndicator,
      };
      return newEle;
    });

    dispatch({
      type: ACTIONS.HOME.UPDATE_ROUTE_CODE,
      data,
      param,
      onSuccess: () => {
        this.setState({ isOpen: false, editData: "" });
      },
    });
  };

  previous = () => {
    if (this.state.offset > 0) {
      this.setState(
        {
          offset: this.state.offset - 10,
        },
        this.getRouteCode,
      );
    }
  };
  next = () => {
    this.setState(
      {
        offset: this.state.offset + 10,
      },
      this.getRouteCode,
    );
  };

  clearFilterOnCancel = () => {
    this.setState({ search: "", offset: 0 }, this.getRouteCode);
  };

  downloadSapRouteCode = () => {
    const { dispatch, company_id } = this.props;
    const { routeCodeType, selectedMode } = this.state;
    const param = { companyId: company_id };
    const isAdaniWilmar = ["999", "872"].includes(company_id);
    const isEmami = EMAMI_COMPANY_IDS.includes(company_id);
    if (["737"].includes(company_id)) {
      param.routeCodeType = routeCodeType;
    }
    if (this.isMultimode(isAdaniWilmar, isEmami) && selectedMode == 2) {
      param.is_multimodal = true;
    }
    dispatch({
      type: ACTIONS.HOME.DOWNLOAD_ROUTE_CODE,
      param,
      onSuccess: (data) => {
        this.setState({
          downloadLink: data.link,
        });
        this.downloadBtn.click();
      },
    });
  };

  downloadSapRouteCodeDetails = () => {
    const { dispatch, company_id } = this.props;
    const param = { companyId: company_id };
    const isAdaniWilmar = ["999", "872"].includes(company_id);
    const isEmami = EMAMI_COMPANY_IDS.includes(company_id);
    if (["737"].includes(company_id)) {
      param.routeCodeType = this.state.routeCodeType;
    }
    if (
      this.isMultimode(isAdaniWilmar, isEmami) &&
      this.state.selectedMode == 2
    ) {
      param.is_multimodal = true;
    }
    dispatch({
      type: ACTIONS.HOME.DOWNLOAD_ROUTE_CODE_DETAILS,
      param,
      onSuccess: (data) => {
        this.setState({
          downloadLink: data[0] ? data[0].link : data.link,
        });
        this.downloadBtn.click();
      },
    });
  };

  uploadSapRouteCodeDetails = (e) => {
    let formData = new FormData();
    const { dispatch, company_id } = this.props;
    const { routeCodeType } = this.state;
    const isAdaniWilmar = ["999", "872"].includes(company_id);
    const isEmami = EMAMI_COMPANY_IDS.includes(company_id);
    formData.append("file", e.target.files[0]);
    formData.append("companyId", company_id);
    formData.append("type", 1);
    formData.append("routeCodeType", routeCodeType);
    dispatch({
      type: ACTIONS.HOME.UPLOADLOAD_ROUTE_CODE,
      data: formData,
      is_multimodal:
        this.isMultimode(isAdaniWilmar, isEmami) && this.state.selectedMode == 2
          ? true
          : false,
      routeCodeType: ["737"].includes(company_id) ? routeCodeType : undefined,
      onSuccess: (data) => {
        if (data) this.getRouteCode();
      },
    });
  };

  onDeleteSapCodeDetails = (route) => {
    const { dispatch, company_id } = this.props;
    const data = {
      company_id: company_id,
      route_id: route.route_id,
    };
    if (route.route_code_type) {
      data["route_code_type"] = route.route_code_type;
    }
    dispatch({
      type: ACTIONS.HOME.DELETE_ROUTE_CODE,
      data,
      onSuccess: (data) => {
        this.getRouteCode();
      },
    });
  };
  isMultimode = (isAdaniWilmar, isEmami) => {
    if (isAdaniWilmar) {
      return true;
    }
    if (isEmami) {
      return true;
    }
    return false;
  };

  render() {
    const { routeCodeList, company_id } = this.props;
    const isRIL = ["737"].includes(company_id);
    const isAdaniWilmar = ["999", "872"].includes(company_id);
    const isEmami = EMAMI_COMPANY_IDS.includes(company_id);
    return (
      <div className={"location-network"}>
        {this.isMultimode(isAdaniWilmar, isEmami) && (
          <div className="mode-navigation-holder">
            {MODE_TYPE.map((mode, index) => {
              return (
                <div
                  key={index}
                  className={
                    this.state.selectedMode == mode.id
                      ? "selected-option options"
                      : "options"
                  }
                  onClick={() =>
                    this.setState({ selectedMode: mode.id }, this.getRouteCode)
                  }
                >
                  {mode.value}
                </div>
              );
            })}
          </div>
        )}
        <div>
          <Search
            id="search"
            value={this.state.search}
            placeholder="Search... source, destination, routecode"
            changeHandler={this.changeHandler}
            click={this.onSearch}
            canClear={this.state.search !== ""}
            handleClearFilter={this.clearFilterOnCancel}
          />
          {isRIL && (
            <Select
              className="select"
              options={ROUTE_CODE_TYPE}
              value={ROUTE_CODE_TYPE.filter(
                (val) => val.value == this.state.routeCodeType,
              )}
              onChange={(option) => {
                let { routeCodeType } = this.state;
                routeCodeType = option.value;
                this.setState({ routeCodeType }, this.getRouteCode);
              }}
              placeholder="Service Mode"
            />
          )}
          <Button value={"+ Route Code"} click={this.toggleAdd} />
          <button
            className="button-classic"
            onClick={this.downloadSapRouteCode}
          >
            {" "}
            Download Template{" "}
          </button>
          <a
            className="download-btn"
            style={{ display: "none" }}
            ref={(input) => (this.downloadBtn = input)}
            href={this.state.downloadLink}
            download
          />
          <button
            className="button-classic"
            onClick={() => {
              this.uploadExcel.click();
            }}
          >
            {" "}
            Upload{" "}
          </button>
          <div
            className={"download-icon"}
            style={{ backgroundImage: `url(${Download})` }}
            onClick={this.downloadSapRouteCodeDetails}
          />
          <a
            className="download-btn"
            style={{ display: "none" }}
            ref={(input) => (this.downloadBtn = input)}
            href={this.state.downloadLink}
            download
          />
          <input
            type="file"
            style={{ display: "none" }}
            ref={(e) => {
              this.uploadExcel = e;
            }}
            onChange={this.uploadSapRouteCodeDetails}
            onClick={(e) => {
              e.target.value = null;
            }}
          />
        </div>

        <div className={"route-chain-details-section"}>
          <div className={"list-section header"}>
            <div className={"tabel-header"} style={{ width: "220px" }}>
              {isRIL ? "Plant Name" : "Source"}
            </div>
            <div className={"tabel-header "} style={{ width: "220px" }}>
              Destination
            </div>
            <div className={"tabel-header"}>Route Code</div>
            {isAdaniWilmar && (
              <Fragment>
                <div className={"tabel-header"}>Route Stages</div>
              </Fragment>
            )}
            {!isRIL && this.state.selectedMode == 1 && (
              <div className={"tabel-header"} style={{ width: "120px" }}>
                Distance in Km
              </div>
            )}
            {TTBIL_COMPANY_IDS.includes(company_id) && (
              <div className={"tabel-header mr-20"} style={{ width: "80px" }}>
                TAT
              </div>
            )}
            <div className={"tabel-header"} style={{ width: "40px" }}>
              Actions
            </div>
          </div>

          {routeCodeList &&
            routeCodeList.length > 0 &&
            routeCodeList.map((route, index) => {
              return (
                <div className={"list-section content"} key={`route_${index}`}>
                  <div className={"tabel-header"} style={{ width: "220px" }}>
                    {toPascalCase(isRIL ? route.plant_name : route.source)}
                  </div>
                  {TTBIL_COMPANY_IDS.includes(company_id) &&
                  route.destination_list &&
                  route.destination_list.length > 0 ? (
                    <Fragment>
                      <div
                        className={"tabel-header"}
                        style={{ width: "220px" }}
                      >
                        {route.destination_list &&
                          route.destination_list.length > 0 &&
                          route.destination_list.map((multiDest, index) => {
                            return (
                              <span>
                                {index < 3 &&
                                  (index == route.destination_list.length - 1
                                    ? multiDest
                                    : index <= 1
                                      ? `${multiDest},`
                                      : `${multiDest}...(${route.destination_list.length})`)}
                              </span>
                            );
                          })}{" "}
                      </div>
                    </Fragment>
                  ) : (
                    <div className={"tabel-header"} style={{ width: "220px" }}>
                      {toPascalCase(route.destination)}
                    </div>
                  )}
                  <div className={"tabel-header"}>
                    {isRIL ? route.route_code : route.sap_code}
                  </div>
                  {isAdaniWilmar && (
                    <Fragment>
                      <div className={"tabel-header"}>
                        <button
                          className="button-classic"
                          onClick={() => this.toggleRoutestagepopup(route)}
                        >
                          Route Stages
                        </button>
                      </div>
                    </Fragment>
                  )}
                  {!isRIL && this.state.selectedMode == 1 && (
                    <div className={"tabel-header"} style={{ width: "120px" }}>
                      {route.distance}
                    </div>
                  )}
                  {TTBIL_COMPANY_IDS.includes(company_id) && (
                    <div
                      className={"tabel-header mr-20"}
                      style={{ width: "80px" }}
                    >
                      {(route.tat || "-") + " " + (route.tat_uom || "-")}
                    </div>
                  )}
                  <div className={"tabel-header"} style={{ width: "40px" }}>
                    <div
                      className={"delete-icon"}
                      style={{ backgroundImage: `url(${Edit})` }}
                      onClick={() => this.onEditRoute(route)}
                    ></div>
                  </div>
                  <div className={"tabel-header"} style={{ width: "80px" }}>
                    <div
                      className={"delete-icon"}
                      style={{ backgroundImage: `url(${Delete})` }}
                      onClick={() => this.onDeleteSapCodeDetails(route)}
                    />
                  </div>
                </div>
              );
            })}
        </div>
        <div className="submit-section-pagination">
          <div className="next">
            <Button value="Next" click={this.next} />
          </div>
          <div className="pre">
            <Button value="Previous" click={this.previous} />
          </div>
        </div>
        {this.state.isOpen && (
          <AddRouteCode
            modeType={this.state.selectedMode}
            toggleAdd={this.toggleAdd}
            onSaveRoute={this.onSaveRoute}
            company_id={this.props.company_id}
            editData={this.state.editData}
            onUpdateRoute={this.onUpdateRoute}
            stateListData={this.props.stateListData}
            DplBranchListData={this.props.DplBranchListData}
            districtList={this.props.districtList}
            TodistrictList={this.props.TodistrictList}
            isMultimode={this.isMultimode(isAdaniWilmar,isEmami)}
          />
        )}
        {this.state.isroutestagesOpen && (
          <RouteStagespopup
            modeType={this.state.selectedMode}
            toggleRoutestagepopup={this.toggleRoutestagepopup}
            onSaveRoute={this.onSaveRoute}
            company_id={this.props.company_id}
            editData={this.state.editData}
            onUpdateRoute={this.onUpdateRoute}
            stateListData={this.props.stateListData}
            districtList={this.props.districtList}
            TodistrictList={this.props.TodistrictList}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    routeCodeList: state.home.routeCodes || [],
    stateListData: state.master.stateList || [],
    DplBranchListData: state.master.DplBranchListDetails || [],
    districtList: state.home.districtList || [],
    TodistrictList: state.home.TodistrictList || [],
  };
};

export default connect(mapStateToProps)(RouteCode);
